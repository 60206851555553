import React, { memo } from 'react';

import Container from 'components/GlobalContainer';
import DocumentDetails from './DocumentsDetailsContainer';

const Documents = memo(() => {
  return (
    <Container isWhiteBackground>
      <DocumentDetails />
    </Container>
  );
});

Documents.displayName = 'Documents';

export default Documents;
