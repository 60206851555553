import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { APPLICATION_TYPES } from 'lib/constants/applicationTypes';

import Document from 'views/documents/components/Document';

import { isCanadianSelector } from 'state/account/selectors';

import PanelHeader from 'components/PanelHeader';

import { formatDate } from 'lib/Formatters';

import { MaterialIcon } from 'styles/Icons';
import { Body, Chip, Panel, IconDisc, IconColor, IconSize } from 'styles/CommonStyles';
import Theme from 'styles/Theme';

const Padding = styled.div`
  padding-top: 2rem;
`;

const getApplicationStyle = (status) => {
  let applicationTranslation;
  let background;
  let color;
  switch (status) {
    case 'approved':
      applicationTranslation = 'applications.status.approved';
      background = Theme.colors.upliftTeal;
      color = Theme.colors.white;
      break;
    case 'aa':
      applicationTranslation = 'applications.status.adverse_action';
      background = Theme.colors.warning;
      color = Theme.colors.black;
      break;
    case 'cfn':
      applicationTranslation = 'applications.status.credit_freeze';
      background = '#8C5CF1';
      color = Theme.colors.white;
      break;
    case 'fan':
      applicationTranslation = 'applications.status.id_verification';
      background = '#E86B47';
      color = Theme.colors.white;
      break;
    default:
      applicationTranslation = 'applications.status.unknown';
      background = Theme.colors.backgroundGrey;
      color = Theme.colors.grey;
  }

  return { applicationTranslation, background, color };
};

const ApplicationsDetailsView = memo(({ applicationDetails }) => {
  const { applicationId, applicationDate, applicationDocuments, applicationType } = applicationDetails;
  const { t } = useTranslation();

  const isCanadian = useSelector(isCanadianSelector);

  const applicationDocs = isCanadian ? applicationDocuments.filter((doc) => doc.documentType !== 'csd') : applicationDocuments;

  const { applicationTranslation, background, color } = getApplicationStyle(applicationDetails.applicationStatus);

  return (
    <Panel>
      <PanelHeader
        panelId="account"
        glyph={
          <IconDisc>
            <MaterialIcon name="assignment" theme="outlined" color={IconColor} size={IconSize} />
          </IconDisc>
        }
        labelLeft={
          applicationType && applicationType === APPLICATION_TYPES.PREQUALIFICATION
            ? t('applications.header.prequalification_date')
            : t('applications.header.application_date')
        }
        contentLeft={formatDate(applicationDate)}
        labelRight={
          applicationType && applicationType === APPLICATION_TYPES.PREQUALIFICATION
            ? t('applications.header.prequalification_id')
            : t('applications.header.application_id')
        }
        contentRight={
          <>
            {applicationId}
            <Chip data-testid="application-status-chip" background={background} color={color}>
              {t(applicationTranslation)}
            </Chip>
          </>
        }
      />

      <Body>
        <Padding>
          <>
            {applicationDocs.map((doc) => {
              return <Document key={doc.title} id={applicationId} document={doc} location="applications" />;
            })}
          </>
        </Padding>
      </Body>
    </Panel>
  );
});

ApplicationsDetailsView.displayName = 'ApplicationsDetailsView';

ApplicationsDetailsView.propTypes = {
  applicationDetails: PropTypes.shape({
    applicationDocuments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    applicationId: PropTypes.string,
    applicationDate: PropTypes.string,
    applicationStatus: PropTypes.string,
    applicationType: PropTypes.string,
  }).isRequired,
};

ApplicationsDetailsView.getApplicationStyle = getApplicationStyle;

export default ApplicationsDetailsView;
