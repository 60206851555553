import React, { useState, useCallback, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { isCanadianSelector } from 'state/account/selectors';

import theme from 'styles/Theme';
import { MaterialIcon } from 'styles/Icons';
import Loader from 'components/Loader';
import { Card, ModalHeader, ModalBody, ModalFooter, Overlay } from 'styles/ModalStyles';
import { Button, ButtonOutline, ButtonHyperLink, IconDisc, IconSize } from 'styles/CommonStyles';
import ModalDocuments from './ModalDocuments';

const StyledButton = styled.button`
  background: #f3f3f3;
  border: none;
  color: #777;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #555555;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  color: #555555;
`;

const TitleContent = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  color: #555555;
`;

const Document = styled(Message)`
  color: black;
`;

const centered = css`
  justify-content: center;
`;

const ModalFooter2 = styled(ModalFooter)`
  ${(props) => props.centered && centered}

  ${Button} {
    color: ${(props) => props.theme.colors.upliftWhite};
    display: block;

    &:disabled {
      background-color: ${(props) => props.theme.colors.upgradeGreen};
      border-color: ${(props) => props.theme.colors.upgradeGreen};
    }
  }

  ${ButtonOutline} {
    background-color: ${(props) => props.theme.colors.upliftWhite};

    &:hover {
      background-color: ${(props) => props.theme.colors.upgradeActiveButton};
      color: ${(props) => props.theme.colors.white};
    }
  }

  button {
    width: 100%;

    & + button {
      margin-left: 1rem;
    }
  }
`;

const StyledLoader = styled(Loader)`
  width: 100%;
`;

const defaultTitleIconDiscStyle = css`
  background-color: ${(props) => props.theme.colors.backgroundLightBlue};
`;

const warningTitleIconDiscStyle = css`
  background-color: ${(props) => props.theme.colors.warningYellow};
`;

const TitleIconDisc = styled(IconDisc)`
  margin-right: 1rem;

  ${(props) => props.variant === 'default' && defaultTitleIconDiscStyle}
  ${(props) => props.variant === 'warning' && warningTitleIconDiscStyle}
`;

const TitleIcon = ({ iconName, variant }) => {
  let iconColor = theme.colors.secondaryMediumBlue;
  if (variant === 'warning') {
    iconColor = '#ffd119';
  }
  return (
    <TitleIconDisc variant={variant}>
      <MaterialIcon color={iconColor} name={iconName} size={IconSize} />
    </TitleIconDisc>
  );
};

TitleIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'warning']),
};

TitleIcon.defaultProps = {
  variant: 'default',
};

// prettier-ignore
const CommonModal = memo(
  ({
    submitEvent1,
    submitEvent2,
    button1,
    button2,
    button1Disabled,
    button2Disabled,
    button1Type,
    customButton,
    onClose,
    isDefaultPayment,
    isLoading,
    title,
    titleBody,
    titleIcon,
    titleIconVariant,
    body1,
    docTrans,
    body2,
    bodyBreak,
    documentTitle,
    postVerifyUrl,
    width,
    showFooter,
    showHeader,
  }) => { // NOSONAR
    const { t } = useTranslation();

    const [showDocument, setShowDocument] = useState(false);

    const isCanadian = useSelector(isCanadianSelector);

    const toggleShowDocument = useCallback(() => {
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: `${showDocument ? 'hide' : 'show'}-modal-document`,
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.value = showDocument ? 0 : 1;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      setShowDocument(!showDocument);
    }, [showDocument]);

    const paymentMethodsDocumentTitle = (() => {
      if (isDefaultPayment === 'ach') {
        return isCanadian ? `${t('documents.ach_CA')}` : `${t('documents.ach')}`;
      }
      return isCanadian ? `${t('documents.dcd_CA')}` : `${t('documents.dcd')}`;
    })();

    const documentTranslation = documentTitle || paymentMethodsDocumentTitle;

    return (
      <Overlay>
        <Card width={width} >
          <>
            {!showDocument ? (
              <>
                {showHeader && <ModalHeader>
                  <Title data-testid="title">
                    {title ? (
                      <>
                        <TitleContent>
                          {titleIcon && <TitleIcon iconName={titleIcon} variant={titleIconVariant} />}
                          <b>{title}</b>
                        </TitleContent>
                        <StyledButton data-testid="close-button" onClick={onClose}>
                          <MaterialIcon name="close" />
                        </StyledButton>
                      </>
                    ) : (
                      <>{titleBody}</>
                    )}
                  </Title>
                </ModalHeader>}
                <ModalBody>
                  <Message data-testid="message">
                    {body1}
                    {docTrans && (
                      <Trans i18nKey={docTrans} documentTranslation={documentTranslation}>
                        message
                        <ButtonHyperLink data-testid="toggle-document" onClick={toggleShowDocument}>
                          {{ documentTranslation }}
                        </ButtonHyperLink>
                        .
                      </Trans>
                    )}
                    {(body1 && body2) && <br />}
                    {body2 && (
                      <>
                        {!bodyBreak && <br />}
                        {body2}
                      </>
                    )}
                  </Message>
                </ModalBody>
               {showFooter && <ModalFooter2 centered={isLoading}>
                  {!isLoading ? (
                    <>
                      {button2 && (
                        <ButtonOutline data-testid="modal-button-2" disabled={button2Disabled} type="" onClick={submitEvent2}>
                          {button2}
                        </ButtonOutline>
                      )}
                      {button1 && (
                        <Button data-testid="modal-button-1" disabled={button1Disabled} type={button1Type} onClick={submitEvent1}>
                          {button1}
                        </Button>
                      )}
                      {customButton}
                    </>
                  ) : (
                    <StyledLoader />
                  )}
                </ModalFooter2>}
              </>
            ) : (
              <Document>
                <ModalBody>
                  <ModalDocuments
                    isDefaultPayment={isDefaultPayment}
                    toggleShowDocument={toggleShowDocument}
                    postVerifyUrl={postVerifyUrl}
                  />
                </ModalBody>
                <ModalFooter2 centered={isLoading}>
                  {!isLoading ? (
                    <ButtonOutline data-testid="close-button" onClick={toggleShowDocument}>
                      {t('common.buttons.close')}
                    </ButtonOutline>
                  ) : (
                    <StyledLoader />
                  )}
                </ModalFooter2>
              </Document>
            )}
          </>
        </Card>
      </Overlay>
    );
  }
);

CommonModal.displayName = 'CommonModal';

CommonModal.propTypes = {
  submitEvent1: PropTypes.func,
  submitEvent2: PropTypes.func,
  customButton: PropTypes.shape({ typeOf: PropTypes.element }),
  button1: PropTypes.string,
  button2: PropTypes.string,
  button1Disabled: PropTypes.bool,
  button2Disabled: PropTypes.bool,
  button1Type: PropTypes.string,
  onClose: PropTypes.func,
  isDefaultPayment: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({ type: PropTypes.symbol })]),
  titleBody: PropTypes.shape({ typeOf: PropTypes.symbol }),
  titleIcon: PropTypes.string,
  titleIconVariant: PropTypes.oneOf(['default', 'warning']),
  body1: PropTypes.node,
  docTrans: PropTypes.string,
  body2: PropTypes.node,
  bodyBreak: PropTypes.bool,
  documentTitle: PropTypes.string,
  postVerifyUrl: PropTypes.string,
  width: PropTypes.string,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
};

CommonModal.defaultProps = {
  submitEvent1: null,
  submitEvent2: null,
  button1: null,
  button2: null,
  button1Disabled: false,
  button2Disabled: false,
  button1Type: '',
  customButton: null,
  onClose: null,
  isDefaultPayment: null,
  isLoading: false,
  title: null,
  titleBody: null,
  titleIcon: null,
  titleIconVariant: 'default',
  body1: null,
  docTrans: null,
  body2: null,
  bodyBreak: false,
  documentTitle: null,
  postVerifyUrl: null,
  width: undefined,
  showFooter: true,
  showHeader: true,
};

export default CommonModal;
