import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Alert } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';
import { loansSelector } from 'state/loans/selectors';
import { paymentMethodsSelector } from 'state/paymentMethods/selectors';
import { formatLastFourDigits, formatMaskedCreditCardNumberWithBullets } from 'lib/Formatters';

const AddMarginTop = styled.div`
  margin-top: 1rem;
`;

export const AlertMessage = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
  font-size: 0.9rem;
  font-weight: 500;
`;

const AssociationsAlertHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 600;
`;

const AssociationsAlertMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 0.9rem;
`;

const AssociationsList = styled.ul`
  list-style-position: inside;
  margin-bottom: 0;
  margin-left: 0.5rem;
`;

export const iconSize = '1.5rem';

// Vairants: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']
const StyledAlert = memo((props) => {
  const { children, variant, showAlert, setShowAlert } = props;

  const onClose = useCallback(() => {
    setShowAlert(false);
  }, [setShowAlert]);

  if (showAlert) {
    return (
      <AddMarginTop>
        <Alert variant={variant} onClose={onClose} dismissible>
          {children}
        </Alert>
      </AddMarginTop>
    );
  }

  return null;
});

StyledAlert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.string.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
};

export const PaymentMethodSuccessAlert = (props) => {
  const { showAlert, setShowAlert, maskedAccountNumber, defaultPaymentMethodType } = props;
  const shortenedAccountNumber = formatLastFourDigits(maskedAccountNumber);
  return (
    <StyledAlert variant="success" showAlert={showAlert} setShowAlert={setShowAlert}>
      <AlertMessage>
        <MaterialIcon name="check_circle_outline" size={iconSize} color={theme.colors.successGreen} />
        {defaultPaymentMethodType === 'ach' ? (
          <Trans i18nKey="account.payment_methods.details.results.add_payment_ach_success">
            You have successfully added Bank Account {{ maskedAccountNumber: shortenedAccountNumber }}
          </Trans>
        ) : (
          <Trans i18nKey="account.payment_methods.details.results.add_payment_card_success">
            You have successfully added Card {{ maskedAccountNumber: shortenedAccountNumber }}
          </Trans>
        )}
      </AlertMessage>
    </StyledAlert>
  );
};

PaymentMethodSuccessAlert.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  maskedAccountNumber: PropTypes.string.isRequired,
  defaultPaymentMethodType: PropTypes.string.isRequired,
};

export const PaymentMethodFailureAlert = (props) => {
  const { t } = useTranslation();

  const { showAlert, setShowAlert } = props;
  return (
    <StyledAlert variant="danger" showAlert={showAlert} setShowAlert={setShowAlert}>
      <AlertMessage>
        <MaterialIcon name="error_outline" size={iconSize} color={theme.colors.error} />
        &nbsp;{t('account.payment_methods.details.results.add_payment_error')}
      </AlertMessage>
    </StyledAlert>
  );
};

PaymentMethodFailureAlert.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
};

export const PaymentMethodAssociationsAlert = ({ associations, showAlert, setShowAlert, variant }) => {
  const { t } = useTranslation();

  const loans = useSelector(loansSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  const getLoanData = (association) => {
    const matchingLoan = loans.find((loan) => loan.loanId === association.loanId);
    const merchantName = matchingLoan?.merchantInfo?.name;
    const { loanId } = matchingLoan;
    return {
      loanId,
      merchantName,
    };
  };

  const getPaymentMethodData = (association) => {
    const matchingPaymentMethod = paymentMethods.find((pm) => pm.paymentMethodId === association.paymentMethodId);
    const { accountNumberMask, cardNumberMask, paymentMethodType } = matchingPaymentMethod;
    const formattedNumber =
      paymentMethodType === 'ach'
        ? formatMaskedCreditCardNumberWithBullets(accountNumberMask)
        : formatLastFourDigits(cardNumberMask);
    const translationKey =
      paymentMethodType === 'ach'
        ? 'account.payment_methods.details.bank_account_with_number'
        : 'account.payment_methods.details.card_with_number';
    return {
      paymentMethodName: t(translationKey, { number: formattedNumber }),
    };
  };

  const associationListItems = associations.map((association) => {
    const { loanId, merchantName } = getLoanData(association);
    return (
      <li key={`loan-association-item-${association.loanId}`}>
        <Trans
          i18nKey={
            association.sessionId
              ? 'account.payment_methods.details.results.loan_association_item_autopay'
              : 'account.payment_methods.details.results.loan_association_item'
          }
        >
          {/* eslint-disable-next-line */}
          {{merchantName}} - Loan <NavLink to={`/loans#${loanId}`}>{{loanId}}</NavLink> 
        </Trans>
      </li>
    );
  });

  const { paymentMethodName } = getPaymentMethodData(associations[0]);
  let translationKey;
  if (variant === 'success') {
    translationKey =
      associations?.length === 1
        ? 'account.payment_methods.details.results.loan_association_success_title'
        : 'account.payment_methods.details.results.loan_association_success_title_plural';
  } else {
    translationKey =
      associations?.length === 1
        ? 'account.payment_methods.details.results.loan_association_failure_title'
        : 'account.payment_methods.details.results.loan_association_failure_title_plural';
  }

  return (
    <StyledAlert variant={variant} showAlert={showAlert} setShowAlert={setShowAlert}>
      <AssociationsAlertMessage>
        <AssociationsAlertHeader>
          <MaterialIcon
            name={variant === 'success' ? 'check_circle_outline' : 'error_outline'}
            size={iconSize}
            color={variant === 'success' ? theme.colors.successGreen : theme.colors.dangerRed}
          />
          <span>{t(translationKey, { paymentMethodName })}</span>
        </AssociationsAlertHeader>
        <AssociationsList>{associationListItems}</AssociationsList>
      </AssociationsAlertMessage>
    </StyledAlert>
  );
};

PaymentMethodAssociationsAlert.propTypes = {
  associations: PropTypes.arrayOf(PropTypes.object).isRequired,
  setShowAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['danger', 'success']),
};

PaymentMethodAssociationsAlert.defaultProps = {
  variant: 'success',
};

StyledAlert.displayName = 'Alert';

export default StyledAlert;
