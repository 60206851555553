import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useAutoFocus from 'hooks/useAutoFocus';

import { ErrorMessage, FormInput, Input } from 'styles/AuthStyles';

const SignInDob = memo((props) => {
  const { showError, dob, onDobChange } = props;
  const { t } = useTranslation();
  const inputRef = useAutoFocus();

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('authentication.errors.dob')}</ErrorMessage>}
        <Input
          ref={inputRef}
          type="tel"
          name="dob"
          aria-label="date of birth"
          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
          maxLength={10}
          placeholder={t('authentication.sign_in.placeholders.dob')}
          onChange={onDobChange}
          value={dob}
          required
        />
      </FormInput>
    </>
  );
});

SignInDob.displayName = 'SignInDob';

SignInDob.propTypes = {
  dob: PropTypes.string,
  onDobChange: PropTypes.func.isRequired,
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

SignInDob.defaultProps = {
  dob: null,
  showError: false,
};

export default SignInDob;
