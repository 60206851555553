import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { documentsSelector } from 'state/documents/selectors';
import { applicationDocumentsSelector } from 'state/applications/selectors';
import { loanDocumentsSelector } from 'state/loans/selectors';

import DocumentDetailsView from './DocumentDetailsView';

const DocumentsDetailsContainer = memo(() => {
  const params = useParams();
  const { documentType, applicationId, loanId } = params;

  let selector;
  let returnTo;
  if (applicationId) {
    selector = applicationDocumentsSelector;
    returnTo = 'applications';
  } else if (loanId) {
    selector = loanDocumentsSelector;
    returnTo = 'loans';
  } else {
    selector = documentsSelector;
    returnTo = 'account';
  }

  const documents = useSelector((state) => selector(state, applicationId || loanId));

  const docUrl = useMemo(() => {
    const doc = documents && documents.find((document) => document.documentType === documentType);
    return doc && `${doc.url}`;
  }, [documentType, documents]);

  return <DocumentDetailsView returnTo={returnTo} docUrl={docUrl} />;
});

export default DocumentsDetailsContainer;
