import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { isCanadianSelector, getRegion } from 'state/account/selectors';

import { MaterialIcon } from 'styles/Icons';
import { StyledDocument } from 'styles/CommonStyles';

const iconColor = '#156CED';
const iconSize = '1.2rem';
const iconStyle = {
  marginRight: '0.2rem',
};

const Document = memo(({ id, document, location }) => {
  const { documentType } = document;
  const { t } = useTranslation();

  const isCanadian = useSelector(isCanadianSelector);
  const region = useSelector(getRegion);
  const canadianDocs = ['dcd', 'ach', 'cpa', 'til', 'icc', 'icc_ON_NS'];

  const getDocumentTranslationKey = () => {
    let docType = documentType;

    if (docType === 'icc') {
      const adverseActionTranslationProvinces = ['NS', 'ON'];
      if (isCanadian && adverseActionTranslationProvinces.includes(region)) {
        // Ontario and Nova Scotia have different titles for icc docs
        docType += '_ON_NS';
      }
    }
    if (isCanadian && canadianDocs.indexOf(docType) !== -1) {
      docType += '_CA';
    }

    return docType;
  };

  return (
    <StyledDocument>
      <MaterialIcon name="insert_drive_file" size={iconSize} color={iconColor} style={iconStyle} />
      <NavLink to={`${location}/${id}/document/${documentType}#${documentType}`}>
        {t(`documents.${getDocumentTranslationKey()}`)}
      </NavLink>
    </StyledDocument>
  );
});

Document.displayName = 'Document';

Document.propTypes = {
  id: PropTypes.string.isRequired,
  document: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.string.isRequired,
};

export default Document;
