import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

// Components:
import { noOp } from 'lib/utils';
import AddPaymentMethodButton from 'views/account/wallet/components/AddPaymentMethodButton';
import BaseWallet from 'views/account/wallet/components/BaseWallet';

const EmptyWallet = memo((props) => {
  const { addCardPayment, dismissAllAlerts, setShowAlertFail, setShowAlertSuccess, loan, setShowPaymentAssociationModal } = props;
  const { t } = useTranslation();

  return (
    <>
      <BaseWallet
        button={
          <AddPaymentMethodButton
            addCardPayment={addCardPayment}
            buttonText={t('account.payment_methods.modal.add_payment_method')}
            dismissAllAlerts={dismissAllAlerts}
            setShowAlertFail={setShowAlertFail}
            setShowAlertSuccess={setShowAlertSuccess}
            loan={loan}
            setShowPaymentAssociationModal={setShowPaymentAssociationModal}
          />
        }
        title={t('account.payment_methods.wallet.empty.empty_header')}
      />
    </>
  );
});

EmptyWallet.displayName = 'EmptyWallet';

EmptyWallet.propTypes = {
  addCardPayment: PropTypes.func,
  dismissAllAlerts: PropTypes.func,
  setShowAlertFail: PropTypes.func,
  setShowAlertSuccess: PropTypes.func,
  loan: PropTypes.shape({
    autopay: PropTypes.bool,
    loanId: PropTypes.string,
    merchantInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
    paymentToken: PropTypes.string,
  }),
  setShowPaymentAssociationModal: PropTypes.func,
};

EmptyWallet.defaultProps = {
  addCardPayment: null,
  dismissAllAlerts: null,
  setShowAlertFail: null,
  setShowAlertSuccess: null,
  loan: null,
  setShowPaymentAssociationModal: noOp,
};

export default EmptyWallet;
