import React, { useCallback, useEffect, useRef, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import OutboundLink from 'analytics/components/OutboundLink';
import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { postHelpMessage, saveHelpDraft } from 'state/help/actions';
import { helpDraftSelector } from 'state/help/selectors';

import { Button, ErrorMessage } from 'styles/CommonStyles';
import { StyledTextArea } from 'styles/FormStyles';
import SubjectSelector from 'components/SubjectSelector';

const Message = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SupportTab = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    flex-direction: row;
  }
`;

const SupportText = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;

  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.textBlack};
  margin: 0.25rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
  }
`;

const HeaderLabel = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.textBlack};
  margin-bottom: 0.25rem;
  margin-right: auto;
  margin-left: 2rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.atto}) {
    margin-left: 0rem;
    margin-right: auto;
    text-align: left;
  }
`;

const HelpForm = memo((props) => {
  const { setMessage } = props;
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { message: useSelector(helpDraftSelector) },
  });
  const dispatch = useDispatch();

  const { t } = useTranslation();
  // TODO: potential changes with how redirects work with flexpay
  // const currentLanguage = i18n.language;

  // let faqUrl;
  // if (currentLanguage === 'en') {
  //   faqUrl = 'en/help';
  // } else if (currentLanguage === 'fr') {
  //   faqUrl = 'fr/help';
  // } else {
  //   faqUrl = 'frequently-asked-questions';
  // }

  const formRef = useRef();

  const onSubmit = useCallback(
    (data) => {
      dispatch(postHelpMessage(data.subject, data.message));
      setMessage(true);
    },
    [dispatch, setMessage]
  );

  const [value, setSubject] = useState({
    selectedOption: [],
  });

  useEffect(
    () => () => {
      dispatch(saveHelpDraft(formRef.current.elements.message.value));
    },
    [dispatch]
  );

  useEffect(() => {
    register({ name: 'subject' }, { required: true });
  }, [register]);

  const onSubjectMessage = () => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'help-text-message-area',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <StyledContainer>
        <SupportTab>
          <SupportText>
            <div data-testid="email-us">{t('help.contact.email_us')}&nbsp;</div>
            <Trans i18nKey="help.contact.email">
              a
              <OutboundLink eventLabel="uplift-support-email" to="mailto:customercare@upgrade.com">
                customercare@upgrade.com
              </OutboundLink>
            </Trans>
          </SupportText>
          <SupportText>
            <div data-testid="call-us">{t('help.contact.call_us')}&nbsp;</div>
            <Trans i18nKey="help.contact.phone">
              a
              <OutboundLink eventLabel="uplift-support-phone" to={t('help.contact.tel_link')}>
                1 (844) 257-5400
              </OutboundLink>
            </Trans>
          </SupportText>
          <SupportText data-testid="view-more">
            <Trans i18nKey="faqs.searchpage.view_more">
              Questions? View more
              <OutboundLink
                eventLabel="uplift-faqs"
                target="_blank"
                rel="noopener"
                to="https://www.upgrade.com/flex-pay/frequently-asked-questions/"
              >
                FAQs
              </OutboundLink>
            </Trans>
          </SupportText>
        </SupportTab>
        <Message>
          <HeaderLabel data-testid="subject-message" id="subject-message">
            {t('help.subject.subject_message')}
          </HeaderLabel>
          <SubjectSelector setValue={setValue} value={value.selectedOption} setSubject={setSubject} />
          <HeaderLabel data-testid="send-message" id="message">
            {t('help.message.send_message')}
          </HeaderLabel>
          <StyledTextArea
            onClick={onSubjectMessage}
            data-testid="text-message-area"
            error={errors.message}
            name="message"
            type="text"
            aria-labelledby="message"
            ref={register({ required: 'help.message.message_required' })}
          />
          {errors.message && <ErrorMessage data-testid="error-message">{t(errors.message.message)}</ErrorMessage>}
          <div className="d-flex justify-content-center mt-3">
            <Button data-testid="send-button" className="text-uppercase" type="submit">
              {t('help.message.send')}
            </Button>
          </div>
        </Message>
      </StyledContainer>
    </form>
  );
});

HelpForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
};

export default HelpForm;
