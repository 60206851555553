import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const secureFormsPaymentMethodsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.paymentMethodEdit:
      return {
        ...action.payload,
      };
    case ACTIONS.paymentMethodSetup:
      return {
        ...state,
        setup: true,
      };
    case ACTIONS.paymentMethodBinLookup:
      return {
        ...state,
        bls: {
          ...state.bls,
          loading: true,
        },
      };
    case ACTIONS.paymentMethodBinLookupResult:
      return {
        ...state,
        bls: action.payload,
      };
    case ACTIONS.paymentMethodSetCardType:
      return {
        ...state,
        cardType: action.payload,
        bls: { loading: true },
      };
    case ACTIONS.paymentMethodSetCardValid:
      return {
        ...state,
        cardValid: action.payload,
      };
    case ACTIONS.paymentMethodSubmit:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case ACTIONS.paymentMethodSubmitSuccess:
      return {
        successId: action.payload.id,
      };
    case ACTIONS.paymentMethodSubmitFail:
      return {
        ...state,
        submitting: false,
        error: httpError(action),
      };
    case ACTIONS.associatePaymentMethodsBatchDone:
      return {
        ...state,
        id: undefined,
      };
    case ACTIONS.paymentMethodCancel:
      return {};
    case ACTIONS.getPaymentMethodsSuccess:
      if (action.meta.previousAction.meta.origin !== 'secureFormsPaymentMethods.auth_ok') {
        return {};
      }
      return state;
    default:
      return state;
  }
};

export default secureFormsPaymentMethodsReducer;
