import React, { memo } from 'react';

import Container from 'components/GlobalContainer';
import ApplicationsDetails from './ApplicationsDetailsContainer';

const Applications = memo(() => {
  return (
    <Container>
      <ApplicationsDetails />
    </Container>
  );
});

Applications.displayName = 'Applications';

export default Applications;
