import { ACTIONS } from 'state/actionTypes';

export const getDocuments = (accountId) => (dispatch) => {
  dispatch({
    type: ACTIONS.getDocuments,
    payload: {
      request: {
        method: 'get',
        url: `/bp/v1/account/${accountId}/documents`,
      },
    },
  });
};

export default getDocuments;
