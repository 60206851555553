import { ACTIONS } from 'state/actionTypes';

// prettier-ignore
const handleSignUpError = (state, { error, payload }) => { // NOSONAR
  const { code, message } = error;
  if (code === 'MissingAttributes') {
    if (message === "country") {
      return { countryRequired: true, phoneError: false };
    }
    if (message === "date_of_birth") {
      return { dobRequired: true, countryError: false };
    }
    if (message === "error") {
      return { dupError: true, error };
    }
    if (message === "ssn") {
      return { ssnRequired: true, dobError: false };
    }
  } else if (code === 'PhoneValidationError') {
    return { phoneError: true };
  } else if (code === 'FormValidationError') {
    return payload;
  }

  return { error, countryError: false, dobError: false, ssnError: false };
};

// prettier-ignore
export const authenticationReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.signUpStartOver:
      return {};
      case ACTIONS.ensureUser: 
        return {
          ...state,
          loading: true,
          user: null,
        };
      case ACTIONS.ensureUserFail:
        return {
          ...state,
          loading: false,
          user: null,
          error: action.error,
        };
    case ACTIONS.signUp:
    case ACTIONS.signIn:
      return {
        ...state,
        loading: true,
        username: action.payload.username,
      };
    case ACTIONS.signUpFail:
      return {
        ...state,
        loading: false,
        user: null,
        ...handleSignUpError(state, action),
      };
    case ACTIONS.signInChallenge:
      return {
        ...state,
        loading: false,
        challenge: true,
        user: action.payload,
      };
    case ACTIONS.loginDismissErrors:
      return {
        ...state,
        error: null,
        [action.payload.name]: false,
      };
    case ACTIONS.signInChallengeAnswerReset:
      return {
        ...state,
        error: null,
      };
    case ACTIONS.signInChallengeAnswer:
      return {
        ...state,
        loading: true,
        challengeAnswerCount: (state.challengeAnswerCount || 0) + 1,
      };
    case ACTIONS.signInChallengeAnswerSuccess:
      return {
        user: action.payload,
      };
    case ACTIONS.signInChallengeAnswerFail:
      return {
        ...state,
        loading: false,
        error: action.payload ? action.payload : action.error,
      };
    case ACTIONS.signInSuccess:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case ACTIONS.signInFail:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.error,
      };
    case ACTIONS.logoutInitial:
      return {
        ...state,
        user: undefined,
      };
    case ACTIONS.logout:
      return state;
    case ACTIONS.logoutSuccess:
      sessionStorage.removeItem('dismissAutoPayBanner');
      sessionStorage.removeItem('dismissBigUpliftBanner');
      sessionStorage.removeItem('dismissRefundBanner');
      sessionStorage.removeItem('dismissDownPaymentModal');
      localStorage.removeItem('account_id_hash');
      localStorage.removeItem('assumedAccountId');
    // eslint-disable-next-line no-fallthrough
    case ACTIONS.logoutInitialSuccess:
      return {
        ...state,
        user: {},
      };
    case ACTIONS.logoutFail:
      return state;
    default:
      return state;
  }
};

export default authenticationReducer;
