// IE Compatibily:
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
// eslint-disable-next-line no-unused-vars
import fetch from 'isomorphic-unfetch'; // NOSONAR

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.css';
// import * as serviceWorker from './serviceWorker'
import * as serviceWorker from './serviceWorker.workbox';

import './styles/fonts.css';

import './i18n'; // import i18n (needs to be bundled ;))

import AuthApp from './AuthApp';

import './telemetry';

// import { configure } from './AwsAmplify'
// configure()

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, { debug: false });

ReactDOM.render(<AuthApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
