import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { noOp } from 'lib/utils';
import { MiniPanel } from 'styles/PaymentMethodStyles';
import PaymentMethod from 'views/account/wallet/components/PaymentMethod';

const StyledRoot = styled.div`
  position: relative;
`;

const StyledRadioInput = styled.input`
  position: absolute;
  top: 0;
  opacity: 0;

  &:checked + label ${MiniPanel} {
    background-color: ${(props) => props.theme.colors.upliftLightestTeal};
    border: 1px solid ${(props) => props.theme.colors.upliftTealBorder};
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;

  &:hover ${MiniPanel} {
    border: 1px solid ${(props) => props.theme.colors.upliftTealBorder};
  }
`;

const PaymentMethodOption = ({ name, onClick, paymentMethod, selected }) => {
  const radioId = `payment-method-${paymentMethod.paymentMethodId}`;

  return (
    <StyledRoot>
      <StyledRadioInput
        id={radioId}
        checked={selected}
        onChange={(event) => {
          onClick(event.target.value);
        }}
        type="radio"
        name={name}
        value={paymentMethod.paymentMethodId}
      />
      <StyledLabel htmlFor={radioId}>
        <PaymentMethod compact hideAutoPay hideMenu method={paymentMethod} />
      </StyledLabel>
    </StyledRoot>
  );
};

PaymentMethodOption.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  paymentMethod: PropTypes.shape({
    paymentMethodId: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool,
};

PaymentMethodOption.defaultProps = {
  onClick: noOp,
  name: 'paymentMethod',
  selected: undefined,
};

export default PaymentMethodOption;
