import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const HeaderLabel = styled.label`
  text-transform: uppercase;
  color: #bbb;

  font-size: 0.65rem;
  font-weight: 300;
  text-align: left;
  padding-right: 4px;
  height: 11px;

  margin-bottom: 0.25rem;
`;

export const HeaderContent = styled.div`
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
`;

const Header = styled.div`
  flex-flow: column wrap;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  & > .material-icons {
    margin: 0 14px 0 0;
  }
`;

const HeaderMargin = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 0 1rem;*/
`;

const HeaderRight = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }

  /* flex-grow: 1; */

  & ${HeaderContent} {
    text-align: left;
    padding-left: 4rem;
    align-self: flex-start;
    @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
      text-align: right;
      padding-right: 4px;
    }
  }

  & ${HeaderLabel} {
    text-align: left;
    padding-left: 4rem;
    @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
      display: block;
      text-align: right;
      padding-left: 4px;
    }
  }
`;

const IconDisk = styled.div`
  margin-right: 0.5rem;
`;

const PanelHeader = memo(
  ({ glyph, panelId = 'panelHeader', labelLeft, contentLeft, labelRight, contentRight, footerRight, showHeaderRight }) => {
    const leftId = `${panelId}-left`;
    const rightId = `${panelId}-right`;

    return (
      <Header>
        <HeaderLeft>
          <IconDisk data-testid="icon-disk">{glyph}</IconDisk>
          <HeaderMargin>
            <HeaderLabel data-testid="label-left" id={leftId}>
              {labelLeft}
            </HeaderLabel>
            <HeaderContent data-testid="content-left" aria-labelledby={leftId}>
              <b>{contentLeft}</b>
            </HeaderContent>
          </HeaderMargin>
        </HeaderLeft>
        {showHeaderRight && (
          <HeaderRight>
            <HeaderLabel data-testid="label-right" id={rightId}>
              {labelRight}
            </HeaderLabel>
            <HeaderContent data-testid="content-right" aria-labelledby={rightId}>
              {contentRight}
            </HeaderContent>
            {footerRight}
          </HeaderRight>
        )}
      </Header>
    );
  }
);

PanelHeader.displayName = 'PanelHeader';

PanelHeader.propTypes = {
  glyph: PropTypes.node.isRequired,
  panelId: PropTypes.string,
  labelLeft: PropTypes.node,
  contentLeft: PropTypes.node,
  labelRight: PropTypes.node,
  contentRight: PropTypes.node,
  footerRight: PropTypes.node,
  showHeaderRight: PropTypes.bool,
};

PanelHeader.defaultProps = {
  panelId: 'panelHeader',
  labelLeft: null,
  contentLeft: null,
  labelRight: null,
  contentRight: null,
  footerRight: null,
  showHeaderRight: true,
};

export default PanelHeader;
