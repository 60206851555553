/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from 'state/auth/actions';

import { StyledLink } from 'styles/CommonStyles';

const SignOut = (props) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(logout());
    },
    [dispatch]
  );

  return <StyledLink {...props} onClick={onClick} />;
};

export default SignOut;
