import styled from 'styled-components';

import { Table } from './CommonStyles';

export const Fill = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;

export const Overlay = styled(Fill)`
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  position: fixed;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  width: ${(props) => props.width || '420px'};
  min-height: 200px;
  background: #f3f3f3;
  border-radius: 6px;
  border: 1px solid rgba(18, 18, 18, 0.5);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.67);
  overflow: auto;
  max-height: 100vh;
  max-width: fit-content;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    max-height: 90vh;
    max-width: 100%;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;

  padding: 0rem 2rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;
`;

export const StyledTable = styled(Table)`
  border-radius: 15px !important;
`;

export const StyledTD = styled.td`
  background-color: #ededed;
`;

export const StyledTDText = styled.td`
  color: #555555;
  font-weight: bold;
`;

export const upliftTeal = '#2ea59a';
