import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

export * from './delayPayment/reducer';
export * from './makePayment/reducer';

const initialState = {
  count: localStorage.loansCount ? JSON.parse(localStorage.loansCount) : 0,
};

const postDelayPaymentSuccess = (state, action) => {
  const { loanId, newPaymentDate } = action.meta.previousAction.meta;

  if (action.payload.data.status === 'delay/success') {
    return {
      ...state,
      data: state.data.map((loan) => {
        if (loan.loanId === loanId) {
          const nextPayment = [];
          return {
            ...loan,
            paymentHistory: loan.paymentHistory
              .filter((payment) => {
                if (payment.status === 'Next Payment') {
                  nextPayment.push({
                    ...payment,
                    date: newPaymentDate,
                  });
                  return false;
                }
                if (payment.status === 'Overdue') {
                  nextPayment.push({
                    ...payment,
                    status: 'Next Payment',
                    date: newPaymentDate,
                  });
                  return false;
                }
                return true;
              })
              .concat(nextPayment),
          };
        }
        return loan;
      }),
    };
  }

  return state;
};

const addAutoPayDocument = (state, action) => {
  return {
    ...state,
    data: state.data?.map((loan) => {
      return action.loanId === loan.loanId
        ? {
            ...loan,
            loanDocuments: [action.document].concat(loan.loanDocuments),
          }
        : loan;
    }),
  };
};

const enableAutoPay = (state, action) => {
  return {
    ...state,
    data: state.data?.map((loan) => {
      return action.loanId === loan.loanId ? { ...loan, autopay: true } : loan;
    }),
  };
};

const disableAutoPay = (state, action) => {
  return {
    ...state,
    data: state.data?.map((loan) => {
      return action.loanId === loan.loanId ? { ...loan, autopay: false } : loan;
    }),
  };
};

const updatePaymentMethod = (state, action) => {
  return {
    ...state,
    data: state.data?.map((loan) => {
      return action.loanId === loan.loanId ? { ...loan, paymentToken: action.paymentMethodId } : loan;
    }),
  };
};

// prettier-ignore
export const loansReducer = (state = initialState, action) => {  // NOSONAR
  switch (action.type) {
    case ACTIONS.getLoans:
      return {
        loading: true,
        data: state.data,
        count: state.count,
      };
    case ACTIONS.getLoansSuccess:
      localStorage.loansCount = JSON.stringify(action.payload.data.loans.length);
      return {
        data: action.payload.data.loans,
        count: action.payload.data.loans.length,
      };
    case ACTIONS.getLoansFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.postDelayPaymentSuccess:
      return postDelayPaymentSuccess(state, action);
    case ACTIONS.logoutSuccess:
      localStorage.removeItem('loansCount');
      return {
        ...state,
        count: 0,
      };
    case ACTIONS.addLoanAutoPayDocument:
      return addAutoPayDocument(state, action);
    case ACTIONS.disableLoanAutoPay:
      return disableAutoPay(state, action);
    case ACTIONS.enableLoanAutoPay:
      return enableAutoPay(state, action);
    case ACTIONS.updateLoanPaymentMethod:
      return updatePaymentMethod(state, action);
    default:
      return state;
  }
};
