import styled from 'styled-components';
import { Form } from 'styles/CommonStyles';

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.errorMessage};
  font-size: 1rem;
  font-weight: 700; /* 400 or 700 */

  margin: 5px auto;
  text-align: center;

  max-width: 300px;
`;

export const AuthForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  min-width: 300px;
`;

export const FormInput = styled.div`
  margin: 1rem 0;
`;

export const FormInputs = styled.div`
  margin-bottom: 10px;
`;

export const Input = styled(Form.Control)`
  text-align: center;
`;

export const Label = styled.div`
  font-family: 'Rubik';
  font-size: 2.5rem;
  font-weight: 600;
  padding: 1.5rem 0;
`;

export const SignInButton = styled.button`
  background-color: ${(props) => props.theme.colors.upgradeGreen};
  color: ${(props) => props.theme.colors.upliftWhite};

  border-style: none;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;

  padding: 8px 20px;

  text-transform: uppercase;

  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:active {
    background: ${(props) => props.theme.colors.upgradeGreen};
  }

  &:hover {
    background: ${(props) => props.theme.colors.upgradeGreen};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

export const Panel = styled.div`
  background-color: ${(props) => (props.admin ? props.theme.colors.backgroundAdmin : props.theme.colors.upliftWhite)};
  border: 1px solid transparent;
  border-radius: 10px 0px 10px 100px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  min-height: 520px;
  padding: 2rem 3rem;

  width: 375px;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    width: 400px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 475px;
  }
`;

export const TransitionPanel = styled.div`
  padding: 1rem 0;
`;
