/* eslint-disable no-console */
import { Amplify } from 'aws-amplify';
import amplifyConfigurationAlpha from './AmplifyConfigurationAlpha';
import amplifyConfigurationBeta from './AmplifyConfigurationBeta';

let configured;
export const configure = (type = 'detect') => {
  if (!configured) {
    if (type === 'alpha') {
      configured = 'alpha';
    } else if (type === 'beta') {
      configured = 'beta';
    } else {
      configured = localStorage.getItem('adminAuth') === 'true' ? 'beta' : 'alpha';
    }

    if (configured === 'alpha') {
      localStorage.removeItem('adminAuth');
    } else {
      localStorage.setItem('adminAuth', 'true');
    }

    console.log('Amplify.configure: ', configured);
    Amplify.configure(configured === 'alpha' ? amplifyConfigurationAlpha : amplifyConfigurationBeta);
  } else if (type !== 'detect' && configured !== type) {
    console.warn('awsAmplify.js: should not be in this else stmt: ', type, configured);
  }
};

export const signOutUrl = () => {
  if (configured === 'beta') {
    return '/ops';
  }
  return '/signin';
};
