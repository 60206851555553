/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import useForm, { FormContext } from 'react-hook-form';
import styled from 'styled-components';

import OutboundLink from 'analytics/components/OutboundLink';

import { resetAccountUpdate, resetAccountUpdateVerify } from 'state/updateAccount/actions';
import { accountUpdateInitializeSelector, accountUpdateVerifySelector } from 'state/updateAccount/selectors';

import { Alert, AlertMessage, Button, ButtonOutline, Form, IconWrapper } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import { Card, Overlay, ModalBody, ModalFooter, ModalHeader } from 'styles/ModalStyles';
import theme from 'styles/Theme';

import Loader from 'components/Loader';

import VerifyCode from './VerifyCode';

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledModalFooter = styled(ModalFooter)`
  justify-content: flex-end;

  button + button {
    margin-left: 1rem;
  }
`;

const StyledTitle = styled.h5`
  color: ${(props) => props.theme.colors.upliftLightBlack};
  font-weight: bold;
`;

const EditAccountModal = ({ children, onClose, onSave, onVerify, show, setShow, editType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resendCode, setResendCode] = useState(false);

  const { data: initializeData, error: initializeError, loading: initializeLoading } = useSelector(accountUpdateInitializeSelector);
  const { error: verifyError, loading: verifyLoading } = useSelector(accountUpdateVerifySelector);

  const error = initializeError || verifyError;
  const loading = initializeLoading || verifyLoading;

  const submittedRef = useRef(null);

  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const verifyFormMethods = useForm();
  const { handleSubmit: handleVerifySubmit } = verifyFormMethods;

  useEffect(() => {
    dispatch(resetAccountUpdate());
  }, [dispatch]);

  let errorMessage = t('account.edit_modal.error_generic');
  if (error) {
    if (initializeError?.status === 409) {
      errorMessage = (
        <Trans i18nKey="account.edit_modal.error_phone_in_use">
          This phone number is already in use. Please try a different number or contact customer support at
          <OutboundLink eventLabel="uplift-support-phone" to={t('account.details.tel_link')}>
            (844) 257-5400
          </OutboundLink>
          for further assistance.
        </Trans>
      );
    } else if (verifyError) {
      if (verifyError?.status === 400) {
        errorMessage = t('authentication.verify.invalid_code');
      } else if (verifyError?.status === 403) {
        errorMessage = (
          <Trans i18nKey="account.edit_modal.error_attempts_exceeded">
            Attempts exceeded. Please call customer support at
            <OutboundLink eventLabel="uplift-support-phone" to={t('account.details.tel_link')}>
              (844) 257-5400
            </OutboundLink>
            for further assistance.
          </Trans>
        );
      }
    }
  }

  const doSave = (data) => {
    submittedRef.current = data;
    onSave(data);
  };

  const handleResend = async () => {
    await dispatch(resetAccountUpdateVerify());
    onSave(submittedRef.current);
    setResendCode(true);
  };

  const editTypeTitleMap = {
    phone: t('account.edit_modal.edit_mobile_number'),
  };

  const editTypeUpdateMap = {
    phone: t('account.edit_modal.updating_mobile_number'),
  };

  let displayTitle;
  if (!initializeData?.sessionId) {
    if (initializeLoading) {
      displayTitle = editTypeUpdateMap[editType] || t('account.edit_modal.title');
    } else {
      displayTitle = editTypeTitleMap[editType] || t('account.edit_modal.title');
    }
  } else {
    displayTitle = t('account.edit_modal.title_verify');
  }

  return (
    <Overlay>
      <Card>
        <ModalHeader>
          <StyledHeaderContent>
            <StyledTitle>{displayTitle}</StyledTitle>
            <IconWrapper onClick={onClose}>
              <MaterialIcon color={theme.colors.upliftLightBlack} name="close" />
            </IconWrapper>
          </StyledHeaderContent>
        </ModalHeader>

        {error && show && (
          <Alert
            data-testid="error"
            variant="danger"
            onClose={() => setShow(false)}
            dismissible={verifyError?.status !== 403}
            style={{ width: '85%' }}
          >
            {errorMessage}
          </Alert>
        )}

        {!error && !loading && initializeData && resendCode && show && (
          <Alert data-testid="success" variant="success" onClose={() => setShow(false)} dismissible style={{ width: '85%' }}>
            {t('authentication.verify.new_code')}
          </Alert>
        )}

        {!error && !loading && editType === 'phone' && (
          <Alert variant="info" style={{ width: '85%' }}>
            <AlertMessage>
              <MaterialIcon name="info" size="24px" color={theme.colors.infoAccent} />
              {t('account.edit_modal.protect_mobile_number')}
            </AlertMessage>
          </Alert>
        )}
        {initializeLoading || verifyLoading ? (
          <Loader style={{ paddingTop: '1rem' }} />
        ) : (
          <>
            {!initializeData?.sessionId && (
              <FormContext {...formMethods}>
                <StyledForm key="edit" onSubmit={handleSubmit(doSave)}>
                  <ModalBody>{children}</ModalBody>
                  <StyledModalFooter>
                    <ButtonOutline disabled={loading} onClick={onClose} type="button">
                      {t('common.buttons.cancel')}
                    </ButtonOutline>
                    <Button disabled={loading} type="submit">
                      {t('common.buttons.update')}
                    </Button>
                  </StyledModalFooter>
                </StyledForm>
              </FormContext>
            )}
            {initializeData?.sessionId && (
              <FormContext {...verifyFormMethods}>
                <StyledForm key="verify" onSubmit={handleVerifySubmit(onVerify)}>
                  {verifyError?.status !== 403 && (
                    <>
                      <ModalBody>
                        <VerifyCode loading={loading} onResendCode={handleResend} />
                      </ModalBody>
                      <StyledModalFooter>
                        <ButtonOutline disabled={loading} onClick={onClose} type="button">
                          {t('common.buttons.cancel')}
                        </ButtonOutline>
                        <Button disabled={loading || verifyError?.status === 403} type="submit">
                          {t('authentication.verify.verify')}
                        </Button>
                      </StyledModalFooter>
                    </>
                  )}
                </StyledForm>
              </FormContext>
            )}
          </>
        )}
      </Card>
    </Overlay>
  );
};

EditAccountModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  show: PropTypes.bool,
  setShow: PropTypes.func.isRequired,
  editType: PropTypes.string.isRequired,
};

EditAccountModal.defaultProps = {
  show: true,
};

export default EditAccountModal;
