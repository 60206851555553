import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGetAccount } from 'hooks/useGetAccount';
import { useGetDocuments } from 'hooks/useGetDocuments';
import { useGetApplications } from 'hooks/useGetApplications';
import { useGetLoans } from 'hooks/useGetLoans';
import { useGetPaymentMethods } from 'hooks/useGetPaymentMethods';
// import { useGetBoostInfo } from 'hooks/useGetBoostInfo';

import { assumedAccountIdSelector } from 'state/auth/selectors';
import { accountIdSelector } from 'state/account/selectors';

const GlobalDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accountId = useSelector(accountIdSelector);
  const assumedAccountId = useSelector(assumedAccountIdSelector);

  useGetAccount(assumedAccountId, dispatch);
  useGetDocuments(accountId, dispatch);
  useGetApplications(accountId, dispatch);
  useGetLoans(accountId, dispatch);
  useGetPaymentMethods(accountId, dispatch);

  // Disable Boost in BP until Virtual card is released
  // useGetBoostInfo(accountId, dispatch);

  return React.Children.toArray(children);
};

GlobalDataProvider.displayName = 'GlobalDataProvider';

export default GlobalDataProvider;
