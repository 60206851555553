/* eslint-disable no-unused-vars */
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const StyledSubjectSelector = styled.div`
  min-width: 200px;
  margin: 0 0 2rem 0;
  width: 305px;
  max-width: 100%;
  @media (max-width: 350px) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e2f2ff' : '#ffffff',
    color: '#555555',
    padding: '0.5rem 1rem',
    margin: '0',
    cursor: 'pointer',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    color: '#fff',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#555555',
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  indicatorSeparator: () => ({}),
};

const SubjectSelector = ({ setValue, setSubject }) => {
  const { t } = useTranslation();

  const changeSubject = (event) => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'help-subject-options',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setValue('subject', event.value);
    setSubject(event.value);
  };

  const options = [
    { value: t('help.options.adverse_action'), label: t('help.options.adverse_action') },
    { value: t('help.options.automatic_payments'), label: t('help.options.automatic_payments') },
    { value: t('help.options.trouble_making_a_payment'), label: t('help.options.trouble_making_a_payment') },
    { value: t('help.options.payment_method'), label: t('help.options.payment_method') },
    { value: t('help.options.refund_status'), label: t('help.options.refund_status') },
    { value: t('help.options.update_profile'), label: t('help.options.update_profile') },
    { value: t('help.options.identity_theft'), label: t('help.options.identity_theft') },
    { value: t('help.options.bankruptcy'), label: t('help.options.bankruptcy') },
    { value: t('help.options.trip_cancelled'), label: t('help.options.trip_cancelled') },
    { value: t('help.options.help_with_delaying_payment'), label: t('help.options.help_with_delaying_payment') },
    { value: t('help.options.hardship_assistance'), label: t('help.options.hardship_assistance') },
    { value: t('help.options.other'), label: t('help.options.other') },
  ];

  return (
    <StyledSubjectSelector data-testid="subject-selector">
      <Select
        name="subjectSelect"
        styles={customStyles}
        options={options}
        onChange={changeSubject}
        menuPosition="fixed"
        isSearchable={false}
      />
    </StyledSubjectSelector>
  );
};

SubjectSelector.propTypes = {
  setValue: PropTypes.func.isRequired,
  setSubject: PropTypes.func.isRequired,
};

export default SubjectSelector;
