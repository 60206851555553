import React from 'react';
import PropTypes from 'prop-types';

export const MaterialIcon = ({ className, name, theme, size, color, style }) => {
  const iconName = theme ? `material-icons-${theme}` : 'material-icons';

  return (
    <i
      data-testid={`${name}-icon`}
      className={`${className} ${iconName}`}
      style={{ fontSize: size, color, maxWidth: size, maxHeight: size, ...style }}
    >
      {name}
    </i>
  );
};

MaterialIcon.displayName = 'MaterialIcon';

MaterialIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  theme: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

MaterialIcon.defaultProps = {
  className: null,
  theme: null,
  size: null,
  color: null,
  style: null,
};

export default MaterialIcon;
