/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Body = styled.div`
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin: 0.5rem 3rem 0 6rem;
    padding: 0.5rem 1.5rem;
  }
`;

export const PaymentHistoryBody = styled.div`
  border: 1px solid ${(props) => (props.isBorder ? '#eee' : 'transparent')};
  border-radius: 2px;
  color: ${(props) => props.theme.colors.upliftLightBlack};

  &:hover {
    border: 1px solid ${(props) => (props.hideDropdownStyles ? 'transparent' : '#eee')};
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin: 0.5rem 2rem 0 2.5rem;
    padding: 0.5rem 1.5rem;
  }
`;
