import { ACTIONS } from 'state/actionTypes';

import trackEvent from './trackEvent';

// eslint-disable-next-line no-unused-vars
const googleAnalyticsMiddleware = (store) => (next) => (action) => {
  const actionParts = action.type.split('/');
  const category = actionParts[0];
  const label = actionParts[1];

  if (action.type === ACTIONS.postMakePaymentSuccess) {
    const event = {
      category,
      action: action.type,
      label,
      value: action.meta.previousAction.payload.request.data.amount / 100,
    };

    trackEvent(event);
  } else {
    const event = {
      category,
      action: action.type,
      label,
      value: 1,
    };

    trackEvent(event);
  }

  return next(action);
};

export default googleAnalyticsMiddleware;
