import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const documentsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.getDocuments:
      return {
        loading: true,
      };
    case ACTIONS.getDocumentsSuccess:
      return {
        data: action.payload.data.accountDocuments,
      };
    case ACTIONS.getDocumentsFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

export default documentsReducer;
