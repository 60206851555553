import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { noOp } from 'lib/utils';

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  color: ${(props) => props.theme.colors.textBlack};
  font-size: 0.9rem;

  a {
    text-decoration: underline;
  }
`;

const AchEncouragementList = ({ loans, onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledList>
      {loans.map((loan) => (
        <StyledListItem key={`ach-encouragement-list-item-${loan.loanId}`}>
          {t('loans.header.loan_id')}
          &nbsp;
          <a href={`/loans#${loan.loanId}`} onClick={() => onClick(loan.loanId)}>
            {loan.loanId}
          </a>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

AchEncouragementList.propTypes = {
  loans: PropTypes.arrayOf(
    PropTypes.shape({
      loanId: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

AchEncouragementList.defaultProps = {
  loans: [],
  onClick: noOp,
};

export default AchEncouragementList;
