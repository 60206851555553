import { createSelector } from 'reselect';

export * from './delayPayment/selectors';
export * from './makePayment/selectors';

export const hasLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.count > 0
);

export const loansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data
);

export const loansLoadingSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.loading
);

export const loanSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => data && data.find((value) => value.loanId === loanId)
);

export const failedDownpaymentLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data?.filter((x) => x.isDownPaymentMissing === true)
);

export const autopayDisabledLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data?.filter((x) => x.autopay === false)
);

export const associatedPaymentMethodLoanSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, paymentId) => state.loansReducer && state.loansReducer.data,
  (state, paymentId) => paymentId,
  (data, paymentId) => {
    const associatedPaymentMethods = data?.filter((loan) => loan.paymentToken === paymentId);

    if (associatedPaymentMethods?.length > 0) {
      return associatedPaymentMethods;
    }

    return [];
  }
);

export const loanNextPaymentDateSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => {
    const loan = data && data.find((value) => value.loanId === loanId);
    if (loan) {
      const nextPayment = loan.paymentHistory.find((value) => value.status === 'Next Payment');
      if (nextPayment) {
        return nextPayment.date;
      }
    }
    return false;
  }
);

export const loanDocumentsSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => data && data.find((value) => value.loanId === loanId).loanDocuments
);

export const loansClosedSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  (data) => data && data.filter((value) => value.state === 'CLOSED').length === data.length
);

export const loansHasPendingRefundSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  // data => data && data.find(value => value.pendingRefund === true).pendingRefund
  (data) => {
    const loan = data && data.find((value) => value.pendingRefund === true);
    if (loan) {
      return true;
    }
    return false;
  }
);

export const loansWithoutPaymentMethodsSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  (data) => data?.filter((loan) => loan?.state?.toUpperCase() === 'ACTIVE' && !loan?.paymentToken)
);
