import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import history from 'History.js'; // eslint-disable-line import/extensions
import { showPaymentMethodFail, showPaymentMethodSuccess } from 'state/updatePaymentMethods/actions';
import { postVerifyLoadingSelector, postPersistLoadingSelector } from 'state/updatePaymentMethods/selectors';

import AchEncouragementList from 'views/loans/components/AchEncouragementList';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import Plaid from 'views/account/wallet/components/Plaid';
import { buttonStyle, IconDisc } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';

const StyledDescription = styled.div`
  font-size: 1rem;
  line-height: 1.19rem;
  padding-bottom: 1rem;
`;

const StyledDisc = styled(IconDisc)`
  background-color: ${(props) => props.theme.colors.backgroundTeal};
`;

const StyledRow = styled.div`
  display: inline-flex;
  padding-bottom: 1rem;
`;

const StyledBody = styled.div`
  margin-left: 1rem;
`;

const StyledPlaid = styled.div`
  ${buttonStyle}
  color: ${(props) => props.theme.colors.upliftWhite};
  padding: 0.375rem 0.75rem;
  text-align: center;
`;

const ICON_SIZE = '1.5rem';

const AchEncouragementModal = ({ onClose, isMissingAch, loans }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const verifyLoading = useSelector(postVerifyLoadingSelector);
  const persistLoading = useSelector(postPersistLoadingSelector);

  const dismissAllAlerts = useCallback(() => {
    dispatch(showPaymentMethodFail(false));
    dispatch(showPaymentMethodSuccess(false));
  }, [dispatch]);

  const setShowModal = useCallback(
    (isShow) => {
      if (!isShow) {
        onClose();
      }
    },
    [onClose]
  );

  const setShowFail = useCallback(
    (isShow) => {
      dispatch(showPaymentMethodFail(isShow));
      if (isShow) {
        history.push('/account');
      }
    },
    [dispatch]
  );

  const setShowSuccess = useCallback(
    (isShow) => {
      dispatch(showPaymentMethodSuccess(isShow));
      if (isShow) {
        history.push('/account');
      }
    },
    [dispatch]
  );

  return (
    <CommonModal
      body1={
        <>
          <StyledDescription data-testid="modal-header">{t('loans.ach_encouragement_modal.current_using_card')}</StyledDescription>
          <StyledRow>
            <StyledDisc>
              <MaterialIcon name="account_balance" size={ICON_SIZE} color={theme.colors.upliftTeal} />
            </StyledDisc>
            <StyledBody data-testid="more-reliable-payments">
              <strong>{t('loans.ach_encouragement_modal.more_reliable')} -</strong>&nbsp;
              {t('loans.ach_encouragement_modal.electronic_payments')}
            </StyledBody>
          </StyledRow>
          <StyledRow>
            <StyledDisc>
              <MaterialIcon name="history" size={ICON_SIZE} color={theme.colors.upliftTeal} />
            </StyledDisc>
            <StyledBody data-testid="no-expiration-dates">
              <strong>{t('loans.ach_encouragement_modal.set_it_and_forget')} -</strong>&nbsp;
              {t('loans.ach_encouragement_modal.expiration_dates')}
            </StyledBody>
          </StyledRow>
        </>
      }
      body2={
        !isMissingAch && (
          <>
            <StyledDescription>{t('loans.ach_encouragement_modal.list_info')}</StyledDescription>
            <AchEncouragementList loans={loans} onClick={onClose} />
          </>
        )
      }
      customButton={
        isMissingAch ? (
          <Plaid
            dismissAllAlerts={dismissAllAlerts}
            setShowAddPaymentMethodModal={setShowModal}
            setShowAlertFail={setShowFail}
            setShowAlertSuccess={setShowSuccess}
          >
            <StyledPlaid>{t('account.payment_methods.modal.add_bank_account.add_bank_account_title')}</StyledPlaid>
          </Plaid>
        ) : (
          undefined
        )
      }
      button2={t('common.buttons.close')}
      onClose={onClose}
      submitEvent2={onClose}
      isLoading={verifyLoading || persistLoading}
      title={t('loans.ach_encouragement_modal.switch_to_pay')}
    />
  );
};

AchEncouragementModal.propTypes = {
  isMissingAch: PropTypes.bool,
  loans: PropTypes.arrayOf(
    PropTypes.shape({
      loanId: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
};

AchEncouragementModal.defaultProps = {
  isMissingAch: false,
  loans: [],
  onClose: undefined,
};

export default AchEncouragementModal;
