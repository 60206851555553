// AWS AMPLIFY CONFIGURATION
const AmplifyConfigurationBeta = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_ADMIN_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_ADMIN_AWS_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_ADMIN_AWS_COGNITO_DOMAIN,
      redirectSignIn: process.env.REACT_APP_ADMIN_AWS_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_ADMIN_AWS_REDIRECT_SIGN_OUT,
      responseType: process.env.REACT_APP_ADMIN_AWS_RESPONSE_TYPE,
    },
  },
};

export default AmplifyConfigurationBeta;
