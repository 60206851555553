import React, { memo } from 'react';

import Container from 'components/GlobalContainer';
import StatementDetailsView from './StatementDetailsView';

const Statements = memo(() => {
  return (
    <Container isWhiteBackground>
      <StatementDetailsView />
    </Container>
  );
});

Statements.displayName = 'Statements';

export default Statements;
