import { createSelector } from 'reselect';

export const helpFormErrorSelector = createSelector(
  (state) => state.helpReducer,
  (state) => state.error
);

export const helpFormLoadingSelector = createSelector(
  (state) => state.helpReducer,
  (state) => state.loading
);

export const helpDraftSelector = createSelector(
  (state) => state.helpReducer,
  (state) => state.draft
);

export const helpFormDataSelector = createSelector(
  (state) => state.helpReducer,
  (state) => state.data
);
