import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Label2, StyledInput } from 'styles/CommonStyles';

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledInputField = styled.div`
  flex: 1;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const EditEmailForm = ({ defaultValue, disabled }) => {
  const formMethods = useFormContext();
  const { errors, register } = formMethods;
  const { t } = useTranslation();
  return (
    <StyledFormGroup>
      <Label2 htmlFor="accountEmail">{t('common.label.email')}</Label2>
      <StyledRow>
        <StyledInputField>
          <StyledInput
            defaultValue={defaultValue}
            disabled={disabled}
            error={!!errors.email}
            id="accountEmail"
            name="email"
            placeholder={t('common.label.email')}
            ref={register({
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          {errors.email && <ErrorMessage>{t('account.edit_modal.invalid_email')}</ErrorMessage>}
        </StyledInputField>
      </StyledRow>
    </StyledFormGroup>
  );
};

EditEmailForm.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};

EditEmailForm.defaultProps = {
  defaultValue: '',
  disabled: false,
};

export default EditEmailForm;
