import CryptoJS from 'crypto-js';

import { ACTIONS } from 'state/actionTypes';

import { accountIdSelector, countrySelector } from 'state/account/selectors';
import { getPaymentMethods } from 'state/paymentMethods/actions';
import { getLoans } from 'state/loans/actions';

/* SECURE FORMS CALLS */
export const postTokenize = () => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  return dispatch({
    type: ACTIONS.postTokenize,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/updatePaymentMethod/creditCard/tokenize`,
      },
    },
  });
};

export const postAuthorize = (tokenizeId) => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  return dispatch({
    type: ACTIONS.postAuthorize,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/updatePaymentMethod/creditCard/authorize`,
        data: {
          tokenizeId,
        },
      },
    },
  });
};

/* PLAID CALLS */
export const postPlaidLinkToken = (refresh = false) => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  // Prevent fetching new token if there is already a valid token in local storage
  if (!refresh) {
    const storedEncodedPlaidLinkToken = localStorage.getItem('plaidLinkToken');
    if (storedEncodedPlaidLinkToken && storedEncodedPlaidLinkToken.startsWith('link')) {
      return dispatch({
        type: ACTIONS.postPlaidLinkTokenStore,
        payload: storedEncodedPlaidLinkToken,
      });
    }
    if (storedEncodedPlaidLinkToken) {
      // decode Plaid Link Token
      const words = CryptoJS.enc.Base64.parse(storedEncodedPlaidLinkToken);
      const decodedPlaidLinkToken = CryptoJS.enc.Utf8.stringify(words);

      return dispatch({
        type: ACTIONS.postPlaidLinkTokenStore,
        payload: decodedPlaidLinkToken,
      });
    }
  } else {
    delete localStorage.plaidLinkToken;
  }

  return dispatch({
    type: ACTIONS.postPlaidLinkToken,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/plaidLinkToken`,
      },
    },
  });
};

export const postVerify = (token, accountReference) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);
  const country = countrySelector(state);

  return dispatch({
    type: ACTIONS.postVerify,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v2/account/${accountId}/updatePaymentMethod/ach/verify`,
        data: {
          plaidToken: token,
          plaidAccountRef: accountReference,
          country,
        },
      },
    },
  });
};

export const postPersist = (verifyId) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const result = await dispatch({
    type: ACTIONS.postPersist,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v2/account/${accountId}/updatePaymentMethod/ach/persist`,
        data: {
          verifyId,
        },
      },
    },
  });

  dispatch(getPaymentMethods(accountId));
  dispatch(getLoans(accountId));

  return result;
};

export const showPaymentMethodFail = (isShowFail) => ({
  type: ACTIONS.setShowPaymentMethodFail,
  isShowFail,
});

export const showPaymentMethodSuccess = (isShowSuccess) => ({
  type: ACTIONS.setShowPaymentMethodSuccess,
  isShowSuccess,
});

export const showPaymentMethodAssociationSuccess = (isShow) => ({
  type: ACTIONS.setShowPaymentMethodAssociationSuccess,
  isShow,
});

export const showPaymentMethodAssociationFailure = (isShow) => ({
  type: ACTIONS.setShowPaymentMethodAssociationFailure,
  isShow,
});
