import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Accordion } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

const Root = styled.div`
  ${Accordion} {
    padding: 16px 0;
  }

  ${Accordion}:first-child {
    padding-top: 0;
  }

  ${Accordion}:last-child {
    padding-bottom: 0;
  }

  ${Accordion} + ${Accordion} {
    border-top: 1px solid ${(props) => props.theme.colors.borderGrey};
  }
`;

const MerchantAccordionToggle = styled(Accordion.Toggle)`
  background: none;
  border: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MerchantLoansList = styled.ul`
  margin: 8px 0;
  padding: 0;
  list-style: none;

  li {
    padding: 8px 0;
  }

  li:last-child {
    padding-bottom: 0;
  }
`;

const IndividualMerchantAccordion = ({ merchantName, loans }) => {
  const [isExpanded, setExpanded] = useState(false);
  const panelId = `payment-methods-loans-list-for-${merchantName}`;
  return (
    <Accordion>
      <MerchantAccordionToggle
        aria-expanded={isExpanded}
        aria-controls={panelId}
        className={MerchantAccordionToggle.c}
        onClick={() => setExpanded(!isExpanded)}
        eventKey={merchantName}
      >
        <div>{merchantName}</div>
        <MaterialIcon name={isExpanded ? 'expand_less' : 'expand_more'} size="1.7em" />
      </MerchantAccordionToggle>
      <Accordion.Collapse id={panelId} eventKey={merchantName}>
        <MerchantLoansList>
          {loans.map((loanId) => (
            <li key={loanId}>
              <NavLink to={{ pathname: '/loans', hash: `#{loanId}` }}>{loanId}</NavLink>
            </li>
          ))}
        </MerchantLoansList>
      </Accordion.Collapse>
    </Accordion>
  );
};

IndividualMerchantAccordion.propTypes = {
  merchantName: PropTypes.string,
  loans: PropTypes.arrayOf(PropTypes.string),
};
IndividualMerchantAccordion.defaultProps = {
  merchantName: null,
  loans: [],
};

const MerchantLoansAccordions = ({ merchantLoansMap }) => {
  const merchants = Object.keys(merchantLoansMap).sort();
  return (
    <Root>
      {merchants.map((merchant) => {
        const merchantLoans = merchantLoansMap[merchant];
        return <IndividualMerchantAccordion key={merchant} merchantName={merchant} loans={merchantLoans} />;
      })}
    </Root>
  );
};

MerchantLoansAccordions.propTypes = {
  merchantLoansMap: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default MerchantLoansAccordions;
