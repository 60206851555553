import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { LOAN_STATUS_CODES } from 'lib/constants/loanStatuses';
import { paymentMethodsSelector } from 'state/paymentMethods/selectors';

import DelayPaymentForm from 'views/loans/payments/components/DelayPaymentForm';
import DelayPaymentStatus from 'views/loans/payments/components/DelayPaymentStatus';

import { Body } from 'styles/CommonStyles';

import MakePaymentDisabled from './components/MakePaymentDisabled';
import MakeOrSchedulePaymentStatus from './components/MakeOrSchedulePaymentStatus';
import DelayPaymentButton from './components/DelayPaymentButton';

import MakePaymentOrScheduleButton from './components/MakePaymentOrScheduleButton';
import MakePaymentForm from './MakePaymentForm';

const StyledBody = styled(Body)`
  display: flex;
  justify-content: center;
`;

const DelayPaymentWrapper = styled.div`
  margin-top: 1rem;
`;

const Maintenance = styled.div`
  color: ${(props) => props.theme.colors.errorMessage};
  font-size: 0.9rem;
`;

const renderPaymentFormState = (loanDetails, paymentFormState, setPaymentFormState, isSchedulePayment, isDisabled) => {
  if (paymentFormState === 'initial') {
    return (
      <StyledBody>
        <MakePaymentOrScheduleButton
          disabled={isDisabled}
          loanDetails={loanDetails}
          setPaymentFormState={setPaymentFormState}
          isSchedulePayment={isSchedulePayment}
        />
      </StyledBody>
    );
  }
  if (paymentFormState === 'form') {
    return (
      <MakePaymentForm loanDetails={loanDetails} setPaymentFormState={setPaymentFormState} isSchedulePayment={isSchedulePayment} />
    );
  }
  return <MakeOrSchedulePaymentStatus setPaymentFormState={setPaymentFormState} isSchedulePayment={isSchedulePayment} />;
};

const renderDelayPaymentState = (loanDetails, delayPaymentState, setDelayPaymentState, isDelayPaymentAllowed) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  if (delayPaymentState === 'initial') {
    return (
      <StyledBody>
        <DelayPaymentButton
          delayPaymentState={delayPaymentState}
          setDelayPaymentState={setDelayPaymentState}
          isDelayPaymentAllowed={isDelayPaymentAllowed}
          isPaymentDueToday={moment(loanDetails.originalDueDate).isSame(today)}
        />
      </StyledBody>
    );
  }
  if (delayPaymentState === 'form') {
    return (
      <>
        <StyledBody>
          <DelayPaymentButton
            delayPaymentState={delayPaymentState}
            setDelayPaymentState={setDelayPaymentState}
            isDelayPaymentAllowed={isDelayPaymentAllowed}
            isPaymentDueToday={moment(loanDetails.originalDueDate).isSame(today)}
          />
        </StyledBody>
        <StyledBody>
          <DelayPaymentForm loanDetails={loanDetails} setDelayPaymentState={setDelayPaymentState} />
        </StyledBody>
      </>
    );
  }
  return <DelayPaymentStatus setDelayPaymentState={setDelayPaymentState} isDelayPaymentAllowed={isDelayPaymentAllowed} />;
};

// prettier-ignore
const renderPayment = (
  loanDetails,
  autoPay,
  paymentFormState,
  setPaymentFormState,
  delayPaymentState,
  setDelayPaymentState,
  isSchedulePayment,
  isDelayPaymentAllowed,
  isDisabled,
) => { // NOSONAR

  return (
    <>
      <div>{renderPaymentFormState(loanDetails, paymentFormState, setPaymentFormState, isSchedulePayment, isDisabled)}</div>

      {autoPay && (loanDetails.loanStatusCode !== LOAN_STATUS_CODES.PENDING) && (
        <DelayPaymentWrapper>
          <div>{renderDelayPaymentState(loanDetails, delayPaymentState, setDelayPaymentState, isDelayPaymentAllowed)}</div>
        </DelayPaymentWrapper>
      )}
    </>
  );
};

// prettier-ignore
const PaymentView = memo(({ loanDetails }) => { // NOSONAR
  const { t } = useTranslation();

  const { autopay, state, lastScheduleDate, isDelayPaymentAllowed } = loanDetails;
  const [paymentFormState, setPaymentFormState] = useState('initial');
  const [delayPaymentState, setDelayPaymentState] = useState('initial');

  const isSchedulePayment = !!(!autopay && lastScheduleDate);

  const paymentMethods = useSelector(paymentMethodsSelector);
  const { paymentToken } = loanDetails;
  const hasPaymentMethod = !!(paymentToken && paymentMethods?.find((method) => method.paymentMethodId === paymentToken));
  const isPending = loanDetails.loanStatusCode === LOAN_STATUS_CODES.PENDING;

  if (process.env.REACT_APP_NLS_MAINTENANCE_FLAG === 'true') {
    return (
      <Body>
        <Maintenance data-testid="nls-maintenance">{t('loans.payment.nls_maintenance')}</Maintenance>
      </Body>
    );
  }

  if (state.toUpperCase() === 'ACTIVE') {
    return renderPayment(
      loanDetails,
      autopay,
      paymentFormState,
      setPaymentFormState,
      delayPaymentState,
      setDelayPaymentState,
      isSchedulePayment,
      isDelayPaymentAllowed,
      !hasPaymentMethod || isPending,
    );
  }
  
  if (state.toUpperCase() === 'PROCESSING') {
    return <MakePaymentDisabled />;
  }

  return null;
});

PaymentView.displayName = 'PaymentView';

PaymentView.propTypes = {
  loanDetails: PropTypes.shape({
    autopay: PropTypes.bool,
    lastScheduleDate: PropTypes.string,
    loanMaturityDate: PropTypes.string,
    loanStatusCode: PropTypes.string,
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    paymentToken: PropTypes.string,
    state: PropTypes.string,
    isDelayPaymentAllowed: PropTypes.bool,
  }).isRequired,
};

export default PaymentView;
