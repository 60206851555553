import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import { IconDisc, IconSize } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

const MiniPanel = styled.div`
  display: block;
  border: 1px solid ${(props) => props.theme.colors.borderGrey};
  border-radius: 8px;

  margin: 2rem 0;
  padding: 2.5rem 1.5rem;
`;

const MiniPanelBody = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const WalletTitle = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;

  letter-spacing: 0.25px;
`;

const WalletText = styled.div`
  margin: 0 10px;
`;

const WalletMessage = styled.div`
  font-size: 0.9rem;
  font-style: normal;
  font-weight: normal;
`;

const GreyedIconDisc = styled(IconDisc)`
  background-color: ${(props) => props.theme.colors.borderLightGrey};
`;

const MarginDiv = styled.div`
  margin-top: 2rem;
  margin-left: 3.5rem;
`;

const IconColor = '#b2b7bf';

const BaseWallet = memo((props) => {
  const { button, title } = props;
  const { t } = useTranslation();

  return (
    <>
      <MiniPanel>
        <MiniPanelBody>
          <GreyedIconDisc>
            <MaterialIcon name="account_balance" color={IconColor} size={IconSize} />
          </GreyedIconDisc>
          <WalletText>
            <WalletTitle>{title || t('account.payment_methods.wallet.empty.empty_header')}</WalletTitle>
            <WalletMessage>{t('account.payment_methods.wallet.empty.empty_body')}</WalletMessage>
          </WalletText>
        </MiniPanelBody>

        <MarginDiv>{button}</MarginDiv>
      </MiniPanel>
    </>
  );
});

BaseWallet.displayName = 'BaseWallet';

BaseWallet.propTypes = {
  button: PropTypes.element,
  title: PropTypes.string,
};

BaseWallet.defaultProps = {
  button: null,
  title: null,
};

export default BaseWallet;
