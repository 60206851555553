import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Actions
import { resetSetLoanPaymentMethod } from 'state/loans/setPaymentMethod/actions';
import { setPaymentMethodPrefillLoans } from 'state/paymentMethods/actions';
// Selectors
import { countrySelector } from 'state/account/selectors';
import { postPersistLoadingSelector, postVerifyLoadingSelector } from 'state/updatePaymentMethods/selectors';

// Components:
import CommonModal from 'views/account/paymentMethods/CommonModal';
import BigButton from 'views/account/wallet/components/BigButton';
import Plaid from 'views/account/wallet/components/Plaid';
import SelectPaymentMethodModal from 'views/account/wallet/components/SelectPaymentMethodModal';

// Styles
import { Alert, AlertMessage, Button, ButtonQuiet, LabelledSeparator } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import Theme from 'styles/Theme';

// Utils
import { noOp } from 'lib/utils';

const ButtonLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const IconLayout = styled.div`
  padding-top: 5px;
  padding-right: 10px;
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledAddCardSection = styled.div`
  display: flex;
  justify-content: center;
`;

const IconColor = 'white';
const IconSize = '1rem';

const AddPaymentMethodButton = memo((props) => {
  const {
    addCardPayment,
    buttonText,
    dismissAllAlerts,
    hideButtonIcon,
    loan,
    onClick,
    paymentMethods,
    selectPaymentMethod,
    setShowAlertFail,
    setShowAlertSuccess,
    setShowAutoPay,
    updatePaymentMethod,
    setShowPaymentAssociationModal,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const verifyLoading = useSelector(postVerifyLoadingSelector);
  const persistLoading = useSelector(postPersistLoadingSelector);
  const country = useSelector(countrySelector);

  const isLoading = verifyLoading || persistLoading;

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showSelectPaymentMethodModal, setShowSelectPaymentMethodModal] = useState(false);

  useEffect(() => {
    if (location.pathname === '/account/plaid') {
      setShowAddPaymentMethodModal(true);
    }
  }, [location.pathname]);

  const openAddPaymentMethodModal = useCallback(
    (event) => {
      event.preventDefault();

      onClick();

      if (!paymentMethods.length) {
        setShowAddPaymentMethodModal(true);
      } else {
        dispatch(resetSetLoanPaymentMethod());
        setShowSelectPaymentMethodModal(true);
      }
    },
    [dispatch, onClick, paymentMethods]
  );

  const openNewPaymentMethodModal = useCallback(() => {
    setShowSelectPaymentMethodModal(false);
    setShowAddPaymentMethodModal(true);
  }, []);

  const closeAddPaymentMethodModal = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(setPaymentMethodPrefillLoans([]));
      setShowAddPaymentMethodModal(false);
    },
    [dispatch]
  );

  const closeSelectPaymentMethodModal = useCallback((event) => {
    event.preventDefault();

    setShowSelectPaymentMethodModal(false);
  }, []);

  const addCard = useCallback(
    (event) => {
      event.preventDefault();

      if (loan) {
        dispatch(setPaymentMethodPrefillLoans([loan.loanId]));
      }

      dismissAllAlerts();

      // Close the addPaymentMethod Modal
      setShowAddPaymentMethodModal(false);

      addCardPayment(event, 'id');
    },
    [dispatch, addCardPayment, dismissAllAlerts, loan]
  );

  const showAlertFail = useCallback(() => {
    setShowSelectPaymentMethodModal(false);
    setShowAlertFail(true, 'add-ach');
  }, [setShowAlertFail]);

  const showAutoPay = useCallback(() => {
    setShowSelectPaymentMethodModal(false);
    setShowAutoPay(true);
  }, [setShowAutoPay]);

  const setShowManageLoansModal = useCallback(() => {
    setShowPaymentAssociationModal(true);
    if (loan) {
      dispatch(setPaymentMethodPrefillLoans([loan.loanId]));
    }
  }, [setShowPaymentAssociationModal, dispatch, loan]);

  const loanPaymentToken = loan?.paymentToken;
  const handlePaymentMethodUpdated = useCallback(
    (newPaymentMethod) => {
      setShowSelectPaymentMethodModal(false);
      setShowAlertSuccess(true);
      setShowAutoPay(true);

      const { paymentMethodId } = newPaymentMethod;
      if (paymentMethodId !== loanPaymentToken) {
        updatePaymentMethod(paymentMethodId);
      }
    },
    [loanPaymentToken, setShowAlertSuccess, setShowAutoPay, updatePaymentMethod]
  );

  return (
    <>
      <Button data-testid="add-payment-method-button" onClick={openAddPaymentMethodModal}>
        <ButtonLayout>
          {!hideButtonIcon && (
            <IconLayout>
              <MaterialIcon name="add_circle_outline" theme="outlined" color={IconColor} size={IconSize} />
            </IconLayout>
          )}
          <div>{buttonText}</div>
        </ButtonLayout>
      </Button>

      {showAddPaymentMethodModal && (
        <CommonModal
          title={t('account.payment_methods.modal.add_payment_method')}
          body1={
            country !== 'MX' && (
              <StyledOptionsContainer>
                <Plaid
                  dismissAllAlerts={dismissAllAlerts}
                  setShowAddPaymentMethodModal={setShowAddPaymentMethodModal}
                  setShowAlertFail={setShowAlertFail}
                  setShowAlertSuccess={setShowAlertSuccess}
                  setShowManageLoansModal={setShowManageLoansModal}
                >
                  <BigButton
                    iconName="account_balance"
                    message={t('account.payment_methods.modal.add_bank_account.add_bank_account_message')}
                    recommended
                    title={t('account.payment_methods.modal.add_bank_account.add_bank_account_title')}
                  />
                </Plaid>
                <Alert variant="info" style={{ marginBottom: 0 }}>
                  <AlertMessage>
                    <MaterialIcon name="info" size="24px" color={Theme.colors.infoAccent} />
                    {t('account.payment_methods.modal.bank_account_benefits')}
                  </AlertMessage>
                </Alert>
                <LabelledSeparator>{t('common.label.or')}</LabelledSeparator>
                <StyledAddCardSection>
                  <ButtonQuiet disabled={isLoading} onClick={addCard}>
                    <MaterialIcon color={Theme.colors.upliftRealLightBlack} name="credit_card" size="24px" theme="outlined" />
                    <span>{t('account.payment_methods.modal.add_card')}</span>
                  </ButtonQuiet>
                </StyledAddCardSection>
              </StyledOptionsContainer>
            )
          }
          isLoading={isLoading}
          onClose={closeAddPaymentMethodModal}
          width="540px"
        />
      )}

      {showSelectPaymentMethodModal && (
        <SelectPaymentMethodModal
          loan={loan}
          onAddNewPaymentMethod={openNewPaymentMethodModal}
          onAutoPayInitializeFail={showAlertFail}
          onAutoPayInitializeSuccess={showAutoPay}
          onClose={closeSelectPaymentMethodModal}
          onPaymentMethodUpdateFail={showAlertFail}
          onPaymentMethodUpdateSuccess={handlePaymentMethodUpdated}
          onSelectPaymentMethod={selectPaymentMethod}
          paymentMethods={paymentMethods}
        />
      )}
    </>
  );
});

AddPaymentMethodButton.displayName = 'AddPaymentMethodButton';

AddPaymentMethodButton.propTypes = {
  addCardPayment: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  dismissAllAlerts: PropTypes.func,
  hideButtonIcon: PropTypes.bool,
  loan: PropTypes.shape({
    autopay: PropTypes.bool,
    loanId: PropTypes.string,
    merchantInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
    paymentToken: PropTypes.string,
  }),
  onClick: PropTypes.func,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  selectPaymentMethod: PropTypes.func,
  setShowAlertFail: PropTypes.func,
  setShowAlertSuccess: PropTypes.func,
  setShowAutoPay: PropTypes.func,
  updatePaymentMethod: PropTypes.func,
  setShowPaymentAssociationModal: PropTypes.func,
};

AddPaymentMethodButton.defaultProps = {
  addCardPayment: null,
  hideButtonIcon: false,
  dismissAllAlerts: null,
  loan: null,
  onClick: noOp,
  paymentMethods: [],
  selectPaymentMethod: noOp,
  setShowAlertFail: null,
  setShowAlertSuccess: null,
  setShowAutoPay: noOp,
  updatePaymentMethod: noOp,
  setShowPaymentAssociationModal: noOp,
};

export default AddPaymentMethodButton;
