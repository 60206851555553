import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';
import { toKebabCase } from 'lib/utils';

import { useTimeoutState } from 'hooks/useTimeout';
import { ButtonHyperLink, ErrorMessage, Label2, StyledInput } from 'styles/CommonStyles';

const StyledResendContainer = styled.div`
  margin-top: 0.3rem;
`;

const VerifyCode = ({ loading, onResendCode }) => {
  const { t } = useTranslation();
  const { errors, register } = useFormContext();
  const [showResend, restartShowResend] = useTimeoutState(5000, false);

  const handleResend = useCallback(() => {
    restartShowResend();
    onResendCode();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'resend-code',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  }, [onResendCode, restartShowResend]);

  return (
    <>
      <Label2 htmlFor="accountVerifyCode">{t('authentication.verify.sent_code')}</Label2>
      <StyledInput
        error={!!errors.verifyCode}
        id="accountVerifyCode"
        name="verifyCode"
        placeholder={t('authentication.verify.placeholder')}
        ref={register({ required: true, pattern: /[0-9]{5,6}/ })}
        type="tel"
        maxLength={14}
      />
      {errors.verifyCode && <ErrorMessage>{t('authentication.errors.invalid_code')}</ErrorMessage>}
      <StyledResendContainer>
        {showResend && (
          <ButtonHyperLink disabled={loading} onClick={handleResend}>
            {t('authentication.verify.resend_code')}
          </ButtonHyperLink>
        )}
      </StyledResendContainer>
    </>
  );
};

VerifyCode.propTypes = {
  loading: PropTypes.bool,
  onResendCode: PropTypes.func,
};

VerifyCode.defaultProps = {
  loading: false,
  onResendCode: () => {},
};

export default VerifyCode;
