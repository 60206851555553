import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import theme from 'styles/Theme';

import { IconWrapper } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import Loader from 'components/Loader';
import PaymentMethodAssociationFlow from './PaymentMethodAssociationFlow';
import CommonModal from './CommonModal';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const PaymentMethodAssociationFlowModal = ({ onComplete, onError, paymentMethod, onClose }) => {
  const handleOnComplete = (successes, failures) => {
    onComplete(successes, failures);
    onClose();
  };

  return (
    <CommonModal
      style={{ justifyContent: 'center', flexDirection: 'column' }}
      showFooter={false}
      showHeader={false}
      body1={
        <>
          <StyledButtonWrapper>
            <IconWrapper data-testid="close-modal" onClick={onClose}>
              <MaterialIcon color={theme.colors.upliftLightBlack} name="close" />
            </IconWrapper>
          </StyledButtonWrapper>
          {!paymentMethod && <Loader />}
          {paymentMethod && (
            <PaymentMethodAssociationFlow
              paymentMethod={paymentMethod}
              onComplete={handleOnComplete}
              onCancel={onClose}
              onError={onError}
              isModalView
            />
          )}
        </>
      }
    />
  );
};

PaymentMethodAssociationFlowModal.propTypes = {
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  paymentMethod: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardNumberMask: PropTypes.string,
    paymentMethodId: PropTypes.string,
    paymentMethodType: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

PaymentMethodAssociationFlowModal.defaultProps = {
  onError: undefined,
  onComplete: undefined,
  paymentMethod: undefined,
};

export default PaymentMethodAssociationFlowModal;
