import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MaterialIcon } from 'styles/Icons';
import Theme from 'styles/Theme';
import { Panel } from 'styles/CommonStyles';
import { helpFormErrorSelector, helpFormLoadingSelector, helpFormDataSelector } from 'state/help/selectors';
import Loader from 'components/Loader';

import HelpCards from './HelpCards';
import AccordionCards from './AccordionCards';
import Search from './Search';
import HelpForm from './HelpForm';

const ContactType = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: auto;
  place-content: center;
  color: ${(props) => props.theme.colors.upliftLightBlack};
  font-size: 1rem;
`;

const MessageSent = styled.div`
  text-align: center;
  min-width: 20.06rem;
  padding: 10rem 0;
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.headerGrey};
  line-height: 1.75rem;
  text-align: center;
  padding: 5rem 0 1rem 0;
`;

const iconColor = Theme.colors.upliftLightGrey;
const iconSize = '1.2rem';
const iconStyle = {
  marginRight: '0.5rem',
};

const HelpDetailsView = memo(({ toggleSearchView, showResults }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(false);
  const displayErrorMessage = useSelector(helpFormErrorSelector);
  const displayLoading = useSelector(helpFormLoadingSelector);
  const displayResponseMessage = useSelector(helpFormDataSelector);

  const FAQS = [
    {
      category: t('faqs.adding_bank_account.adding_bank_account'),
      question: t('faqs.adding_bank_account.question_1'),
      answers: [t('faqs.adding_bank_account.answer_1')],
      subAnswers: [
        t('faqs.adding_bank_account.sub_answer_1a'),
        t('faqs.adding_bank_account.sub_answer_1b'),
        t('faqs.adding_bank_account.sub_answer_1c'),
      ],
    },
    {
      category: t('faqs.adding_bank_account.adding_bank_account'),
      question: t('faqs.adding_bank_account.question_2'),
      answers: [t('faqs.adding_bank_account.answer_2')],
    },
    {
      category: t('faqs.adding_bank_account.adding_bank_account'),
      question: t('faqs.adding_bank_account.question_3'),
      answers: [t('faqs.adding_bank_account.answer_3')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_4'),
      answers: [t('faqs.payment_information.answer_4')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_5'),
      answers: [t('faqs.payment_information.answer_5a'), t('faqs.payment_information.answer_5b')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_6'),
      answers: [t('faqs.payment_information.answer_6')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_7'),
      answers: [t('faqs.payment_information.answer_7')],
      subAnswers: [t('faqs.payment_information.sub_answer_7a'), t('faqs.payment_information.sub_answer_7b')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_8'),
      answers: [t('faqs.payment_information.answer_8')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_9'),
      answers: [t('faqs.payment_information.answer_9a'), t('faqs.payment_information.answer_9b')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_10'),
      answers: [t('faqs.payment_information.answer_10a')],
      subAnswers: [t('faqs.payment_information.sub_answer_10b')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_11'),
      answers: [t('faqs.payment_information.answer_11')],
    },
    {
      category: t('faqs.payment_information.payment_information'),
      question: t('faqs.payment_information.question_12'),
      answers: [t('faqs.payment_information.answer_12')],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_13'),
      answers: [t('faqs.account_information.answer_13')],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_14'),
      answers: [t('faqs.account_information.answer_14')],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_15'),
      answers: [t('faqs.account_information.answer_15')],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_16'),
      answers: [t('faqs.account_information.answer_16', { formsUrl: process.env.REACT_APP_FORMS_PORTAL_URL })],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_17'),
      answers: [t('faqs.account_information.answer_17', { formsUrl: process.env.REACT_APP_FORMS_PORTAL_URL })],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_18'),
      answers: [t('faqs.account_information.answer_18', { formsUrl: process.env.REACT_APP_FORMS_PORTAL_URL })],
    },
    {
      category: t('faqs.account_information.account_information'),
      question: t('faqs.account_information.question_19'),
      answers: [t('faqs.account_information.answer_19', { formsUrl: process.env.REACT_APP_FORMS_PORTAL_URL })],
    },
  ];

  const addingBankAccount = FAQS.filter((x) => x.category === `${t('faqs.adding_bank_account.adding_bank_account')}`).map((x) => {
    return { item: x };
  });
  const paymentInformation = FAQS.filter((x) => x.category === `${t('faqs.payment_information.payment_information')}`).map((x) => {
    return { item: x };
  });
  const accountInformation = FAQS.filter((x) => x.category === `${t('faqs.account_information.account_information')}`).map((x) => {
    return { item: x };
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setMessage(false);
    }, 10000);

    return () => clearTimeout(timerId);
  });

  let helpForm;
  if (!message) {
    helpForm = <HelpForm setMessage={setMessage} />;
  } else if (displayLoading) {
    helpForm = (
      <MessageSent>
        <ContactType>
          <Loader />
        </ContactType>
      </MessageSent>
    );
  } else if (displayErrorMessage || (displayResponseMessage && displayResponseMessage.message === 'Help message sending failed!')) {
    helpForm = (
      <MessageSent>
        <ContactType>
          <MaterialIcon
            data-testid-="error-icon"
            name="error_outline"
            theme="outlined"
            size={iconSize}
            color={iconColor}
            style={iconStyle}
          />
          <div>{t('help.message.message_fail')}</div>
        </ContactType>
      </MessageSent>
    );
  } else {
    helpForm = (
      <MessageSent>
        <ContactType>
          <MaterialIcon name="done" theme="outlined" size={iconSize} color={Theme.upliftTealActive} style={iconStyle} />
          <div>{t('help.message.message_sent')}</div>
        </ContactType>
      </MessageSent>
    );
  }

  const [activeTab, setActiveTab] = useState('payment-information');

  return (
    <Panel>
      <StyledHeader data-testid="hello-how-can-we-help">{t('faqs.searchpage.hello')}</StyledHeader>
      <Search faqs={FAQS} toggleSearchView={toggleSearchView} showResults={showResults} />
      <HelpCards activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'payment-information' ? <AccordionCards loadedResults={paymentInformation} isResultsPage={false} /> : <div />}
      {activeTab === 'adding-bank-account' ? <AccordionCards loadedResults={addingBankAccount} isResultsPage={false} /> : <div />}
      {activeTab === 'account-information' ? <AccordionCards loadedResults={accountInformation} isResultsPage={false} /> : <div />}
      {activeTab === 'customer-support' ? <div>{helpForm}</div> : ''}
    </Panel>
  );
});

HelpDetailsView.propTypes = {
  toggleSearchView: PropTypes.func.isRequired,
  showResults: PropTypes.func.isRequired,
};

export default HelpDetailsView;
