import { httpError } from 'lib/utils';
import { ACTIONS } from 'state/actionTypes';

export const putLoanPaymentMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.putLoanPaymentMethodReset:
      return {
        loading: false,
      };
    case ACTIONS.putLoanPaymentMethod:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.putLoanPaymentMethodFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.putLoanPaymentMethodSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default putLoanPaymentMethodReducer;
