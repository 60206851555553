import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import CommonModal from 'views/account/paymentMethods/CommonModal';

import { Button } from 'styles/CommonStyles';

const MakePaymentModal = memo(({ showPaymentModal, onClose, maskedAccountNumber, paymentAmount, formState }) => {
  const { t } = useTranslation();

  if (!showPaymentModal) {
    return null;
  }

  return (
    <>
      <CommonModal
        title={t('loans.payment.form.modal.please_confirm')}
        body1={
          <div>
            <Trans
              i18nKey="loans.payment.form.modal.by_pressing_amt_of"
              maskedAccountNumber={maskedAccountNumber}
              paymentAmount={paymentAmount}
            >
              By pressing accept, you authorize Uplift to debit your account ending in <b>{{ maskedAccountNumber }}</b> for the
              amount of <b>${{ paymentAmount }}</b>.
            </Trans>
          </div>
        }
        customButton={
          <Button data-testid="modal-button-1" type="submit" className="btn-block text-uppercase" disabled={formState.isSubmitting}>
            {t('common.buttons.accept')}
          </Button>
        }
        submitEvent2={onClose}
        button2={t('common.buttons.cancel')}
        onClose={onClose}
      />
    </>
  );
});

MakePaymentModal.displayName = 'MakePaymentModal';

MakePaymentModal.propTypes = {
  formState: PropTypes.shape({ dirty: PropTypes.bool, isSubmitting: PropTypes.bool }).isRequired,
  maskedAccountNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  paymentAmount: PropTypes.string,
  showPaymentModal: PropTypes.bool.isRequired,
};

/* Did not know how to make paymentAmount required since it was complaining it was undefined. */
MakePaymentModal.defaultProps = {
  paymentAmount: null,
};

export default MakePaymentModal;
