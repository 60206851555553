import { Auth } from 'aws-amplify';

import { ACTIONS } from 'state/actionTypes';

// eslint-disable-next-line no-unused-vars
export const adminSignIn = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.adminSignIn,
  });

  Auth.federatedSignIn();
};

export default adminSignIn;
