import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LOAN_STATUS_CODES } from 'lib/constants/loanStatuses';

import { HeaderContent, HeaderLabel } from 'components/PanelHeader';

const StyledStatus = styled.div`
  margin-top: 10px;
`;

const Status = styled(HeaderContent)`
  font-weight: 600;
  text-transform: uppercase;
  margin: 0.2rem 0;
  padding-left: 4px !important;
`;

const LoanStatus = memo(({ loanStatusCode, state }) => {
  const { t } = useTranslation();

  let status;
  let background;
  let color;

  if (loanStatusCode === LOAN_STATUS_CODES.FORGIVEN) {
    return null;
  }

  if (
    loanStatusCode === LOAN_STATUS_CODES.CANCELLED ||
    loanStatusCode === LOAN_STATUS_CODES.CANCELLED_UNWIND ||
    loanStatusCode === LOAN_STATUS_CODES.CANCELLED_REFUND
  ) {
    status = 'cancelled';
    background = '#e2e3e5';
    color = '#41464b';
  } else if (
    loanStatusCode === LOAN_STATUS_CODES.PAID_OFF ||
    (loanStatusCode === LOAN_STATUS_CODES.PAID_OFF_MERCHANT && state === 'CLOSED')
  ) {
    status = 'paid_in_full';
    background = '#effaf3';
    color = '#17ad4e';
  } else if (loanStatusCode === LOAN_STATUS_CODES.CHARGE_OFF || loanStatusCode === LOAN_STATUS_CODES.GL_CHARGE_OFF) {
    status = 'charged_off';
    background = '#fff1ed';
    color = '#ee0606';
  } else if (loanStatusCode === LOAN_STATUS_CODES.SOLD_DEBT) {
    status = 'sold_debt';
    background = '#fff6d1';
    color = '#664D08';
  } else {
    // loanStatusCode === LOAN_STATUS_CODES.NON_ACCRUAL
    return null;
  }

  return (
    <StyledStatus>
      <HeaderLabel data-testid="status" className="mb-1">
        {t('loans.status.status')}
      </HeaderLabel>
      <Status data-testid="status-value" className="badge rounded-pill" style={{ background: `${background}`, color: `${color}` }}>
        {t(`loans.status.${status}`)}
      </Status>
    </StyledStatus>
  );
});

LoanStatus.displayName = 'LoanStatus';

LoanStatus.propTypes = {
  loanStatusCode: PropTypes.oneOf(Object.values(LOAN_STATUS_CODES)),
  state: PropTypes.string,
};

LoanStatus.defaultProps = {
  loanStatusCode: null,
  state: null,
};

export default LoanStatus;
