import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import CommonPopover from 'components/popovers/CommonPopover';
import { ExpandButton } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledExpandButton = styled(ExpandButton)`
  padding-right: 4px;
  ${(props) => props.disabled && 'margin-right: 0.5rem;'}
`;

const StyledIcon = styled(MaterialIcon)`
  vertical-align: middle;
`;

const HardshipButton = ({ isExpanded, isShowExceeded, isHardshipAllowed, onClick }) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick(!isExpanded);
    }
  };

  if (process.env.REACT_APP_HARDSHIP_ENABLED_FLAG === 'false') {
    return <></>;
  }

  return (
    <StyledRoot>
      <StyledExpandButton disabled={!isHardshipAllowed} onClick={handleClick} type="button">
        {isExpanded ? <StyledIcon name="expand_less" /> : <StyledIcon name="expand_more" />}
        {t('loans.hardship.enroll_hardship_program')}
      </StyledExpandButton>
      {!isHardshipAllowed && (
        <CommonPopover
          role="tooltip"
          type="enroll-hardship"
          placement="top"
          color={themeContext.colors.hyperlinkBlue}
          icon="info_outline"
          textcontent={isShowExceeded ? t('loans.hardship.enrollments_exceeded') : t('loans.hardship.not_eligible')}
        />
      )}
    </StyledRoot>
  );
};

HardshipButton.propTypes = {
  isExpanded: PropTypes.bool,
  isShowExceeded: PropTypes.bool,
  isHardshipAllowed: PropTypes.bool,
  onClick: PropTypes.func,
};

HardshipButton.defaultProps = {
  isExpanded: false,
  isShowExceeded: false,
  isHardshipAllowed: false,
  onClick: () => {},
};

export default HardshipButton;
