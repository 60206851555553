/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import secureforms from 'secureForms';

import { ACTIONS } from 'state/actionTypes';

import { postAuthorize, postTokenize } from 'state/updatePaymentMethods/actions';
import { getPaymentMethods } from 'state/paymentMethods/actions';
import { getLoans } from 'state/loans/actions';

import { accountIdSelector } from 'state/account/selectors';
import { loansSelector } from 'state/loans/selectors';
import { paymentMethodsSelector } from 'state/paymentMethods/selectors';
import { postAuthorizeErrorSelector } from 'state/updatePaymentMethods/selectors';

export const paymentConfig = {
  forms: {
    number: {
      selector: '#card_info_number',
      title: 'Secure card number input',
    },
    ccv: {
      selector: '#card_info_ccv',
      title: 'Secure card C C V input',
    },
    expiration: {
      selector: '#card_info_expiration',
      title: 'Secure card expiration input',
    },
    zip: {
      selector: '#billing_zip',
      title: 'Secure billing zipcode input',
    },
  },
  tmx: {
    domain: process.env.REACT_APP_THREAT_METRIX_SECURE_FORMS_DOMAIN,
    org_id: process.env.REACT_APP_THREAT_METRIX_SECURE_FORMS_ORG_ID,
  },
  track_tmx_script_appended: (map) => {
    console.log('track_tmx_script_appended: dummy function ', map);
  },
  track_tmx_script_loaded: (success, map) => {
    console.log('track_tmx_script_loaded: dummy function ', success, map);
  },
  upcode: process.env.REACT_APP_BORROWER_PORTAL_UPCODE,
  auth_ok: () => {
    console.log('auth_ok: dummy function ');
  },
  auth_fail: (response) => {
    console.log('auth_fail: dummy function ', response);
  },
  set_card_type: (type) => {
    console.log('set_card_type: dummy function', type);
  },
  track_bin_xhr: () => {
    console.log('track_bin_xhr: dummy function');
  },
  track_bin_xhr_result: (_, valid, code) => {
    console.log('track_bin_xhr_result: dummy function');
  },
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const paymentMethodEdit = (id) => async (dispatch, getState) => {
  await dispatch(postTokenize());
  dispatch({ type: ACTIONS.paymentMethodEdit, payload: { id } });
};

export const paymentMethodSetup = (tokenize, setShowAlertSuccess, country) => (dispatch, getState) => {
  const updatedPaymentConfig = {
    ...paymentConfig,
    country,
    auth_ok: async () => {
      try {
        console.log('auth_ok: onSubmit: authorize:');
        await sleep(3000);

        const state = getState();
        const loans = loansSelector(state);
        const accountId = accountIdSelector(state);
        await dispatch(postAuthorize(tokenize.tokenizeId));

        // postAuthorize result
        const authorizeResponseError = postAuthorizeErrorSelector(getState());
        if (authorizeResponseError) {
          dispatch({ type: ACTIONS.paymentMethodSubmitFail, authorizeResponseError });
          return;
        }

        // Reset Secure-forms form
        // (defn ^:export reset []
        secureforms.reset();

        await dispatch(getPaymentMethods(accountId, { origin: 'secureFormsPaymentMethods.auth_ok' }));
        dispatch(getLoans(accountId));

        const newPaymentId = paymentMethodsSelector(getState()).find((paymentMethod) => paymentMethod.paymentMethodType === 'card')
          .paymentMethodId;
        dispatch({ type: ACTIONS.paymentMethodSubmitSuccess, payload: { id: newPaymentId } });

        if (loans.filter((loan) => loan.state?.toUpperCase() === 'ACTIVE').length > 0) {
          await dispatch(paymentMethodEdit('manage-payments'));
        }
        setShowAlertSuccess(true);
      } catch (error) {
        dispatch({ type: ACTIONS.paymentMethodSubmitFail, error });
      }
    },
    auth_fail: (response) => {
      console.log('auth_fail: ', response);
      dispatch({ type: ACTIONS.paymentMethodSubmitFail, error: response || 'Secure Form Failure' });
    },
    track_bin_xhr: () => {
      dispatch({ type: ACTIONS.paymentMethodBinLookup, payload: {} });
    },
    track_bin_xhr_result: (_, valid, code) => {
      dispatch({ type: ACTIONS.paymentMethodBinLookupResult, payload: { valid, code } });
    },
    set_card_type: (type) => {
      console.log('set_card_type: actual function', type);
      // prettier-ignore
      dispatch((_, getState) => // NOSONAR
        setTimeout(() => {
          const state = getState();
          const isCardValid = secureforms.isValid('card', 'number');
          const { cardType, cardValid } = state.secureFormsPaymentMethodsReducer;
          if (cardType !== type) {
            dispatch({
              type: ACTIONS.paymentMethodSetCardType,
              payload: type,
            });
          }
          if (cardValid !== isCardValid) {
            dispatch({
              type: ACTIONS.paymentMethodSetCardValid,
              payload: isCardValid,
            });
          }
        }, 0)
      );
    },
  };

  // (defn ^:export setup [payment-type handoff-id handoff-url config-object]
  const setup = secureforms.setup('card', tokenize.handoffId, tokenize.handoffUrl, updatedPaymentConfig);
  console.log('paymentMethodSetup: setup: ', setup);
  dispatch({ type: ACTIONS.paymentMethodSetup });
};

export const paymentMethodSubmit = (data) => {
  // (defn ^:export pay [payment-type order-data]
  const pay = secureforms.pay('card', data);
  console.log('paymentMethodSubmit: onSubmit: pay ', pay);
  return { type: ACTIONS.paymentMethodSubmit, payload: data };
};

export const paymentMethodCancel = () => {
  return { type: ACTIONS.paymentMethodCancel };
};
