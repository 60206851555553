import React, { memo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { isCanadian, onLanguageChange } from 'lib/utils';

import OutboundLink from 'analytics/components/OutboundLink';

const Footer = styled.div`
  color: #333333;
  font-size: 0.9rem;
  text-align: center;
  & > a {
    color: #2ea59a;
  }
`;

const HR = styled.hr`
  margin: 2rem 0;
  border-top: 1px solid #eeeeee;
  width: 95%;
`;

// const languageParams = { 'en-US': '', en: '/en', fr: '/fr', es: '' };

const SignInFooter = memo(() => {
  const { t, i18n } = useTranslation();

  const isCanada = isCanadian(i18n.language);

  useEffect(() => {
    if (i18n.language === 'en-CA') {
      i18n.changeLanguage('en', onLanguageChange);
    }
  });

  // const upgradeUrl = (path) => {
  //   return languageParams[i18n.language]
  //     ? `https://www.upgrade.com/flex-pay${languageParams[i18n.language]}/${path}`
  //     : `https://www.upgrade.com/flex-pay/${path}`;
  // };
  const upgradeUrl = (path) => {
    return `https://www.upgrade.com/flex-pay/${path}`;
  };

  return (
    <>
      {isCanada ? (
        <>
          <HR />
          <Footer>
            <div>{t('authentication.footer.copyright_CA')}</div>
            <div>
              <Trans i18nKey="authentication.footer.navigation">
                a
                <OutboundLink eventLabel="terms-of-use" to={upgradeUrl('terms')} target="_blank" rel="noopener">
                  Terms of Use
                </OutboundLink>
                |
                <OutboundLink eventLabel="privacy-policy" to={upgradeUrl('privacy')} target="_blank" rel="noopener">
                  Privacy Policy
                </OutboundLink>
              </Trans>
            </div>
          </Footer>
        </>
      ) : (
        <>
          <HR />
          <Footer>
            <div>{t('authentication.footer.copyright')}</div>
            <div>
              <Trans i18nKey="authentication.footer.navigation">
                a
                <OutboundLink eventLabel="terms-of-use" to={upgradeUrl('terms')} target="_blank" rel="noopener">
                  Terms of Use
                </OutboundLink>
                |
                <OutboundLink eventLabel="privacy-policy" to={upgradeUrl('privacy')} target="_blank" rel="noopener">
                  Privacy Policy
                </OutboundLink>
              </Trans>
            </div>
          </Footer>
        </>
      )}
    </>
  );
});

export default SignInFooter;
