import { ACTIONS } from 'state/actionTypes';

export const postHelpMessage = (subject, message) => (dispatch) => {
  dispatch({
    type: ACTIONS.postHelpMessage,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/help`,
        data: {
          subject,
          message,
        },
      },
    },
  });
};

export const saveHelpDraft = (message) => ({ type: ACTIONS.saveHelpDraft, payload: message });
