import { createSelector } from 'reselect';

export const postAuthorizeDataSelector = createSelector(
  (state) => state.postAuthorizeReducer,
  (postAuthorizeReducer) => postAuthorizeReducer.data
);

export const postAuthorizeErrorSelector = createSelector(
  (state) => state.postAuthorizeReducer && state.postAuthorizeReducer.error,
  (error) => error
);

export const postPlaidLinkTokenSelector = createSelector(
  (state) =>
    state.postPlaidLinkTokenReducer && state.postPlaidLinkTokenReducer.data && state.postPlaidLinkTokenReducer.data.linkToken,
  (linkToken) => linkToken
);

export const postPersistSelector = createSelector(
  (state) => state.postPersistReducer,
  (postPersistReducer) => postPersistReducer
);

export const postPersistErrorSelector = createSelector(
  (state) => state.postPersistReducer && state.postPersistReducer.error,
  (error) => error
);

export const postVerifyLoadingSelector = createSelector(
  (state) => state.postVerifyReducer,
  (postVerifyReducer) => postVerifyReducer.loading
);

export const postPersistLoadingSelector = createSelector(
  (state) => state.postPersistReducer,
  (postPersistReducer) => postPersistReducer.loading
);

export const showPaymentMethodFailAlertSelector = createSelector(
  (state) => state.paymentMethodsAlertsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.isShowFail
);

export const showPaymentMethodSuccessAlertSelector = createSelector(
  (state) => state.paymentMethodsAlertsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.isShowSuccess
);

export const showPaymentMethodAssociationSuccessAlertSelector = createSelector(
  (state) => state.paymentMethodsAlertsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.isShowAssociationSuccess
);

export const showPaymentMethodAssociationFailureAlertSelector = createSelector(
  (state) => state.paymentMethodsAlertsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.isShowAssociationFailure
);
