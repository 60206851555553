import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const autoPayInitializeReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postLoanAutoPayInitializeReset:
      return {};
    case ACTIONS.postLoanAutoPayInitialize:
      return {
        initializeAutoPay: false,
        isLoading: true,
      };
    case ACTIONS.postLoanAutoPayInitializeSuccess:
      return {
        initializeAutoPay: true,
        data: action.payload.data,
        isLoading: false,
      };
    case ACTIONS.postLoanAutoPayInitializeFail:
      return {
        ...state,
        initializeAutoPay: false,
        isLoading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

export const autoPayInitializeBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.postLoanAutoPayInitializeBatch:
      return { isLoading: true };
    case ACTIONS.postLoanAutoPayInitializeBatchDone:
      return {
        ...action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

// prettier-ignore
export const autoPayPersistReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postLoanAutoPayPersistReset:
      return {};
    case ACTIONS.postLoanAutoPayPersist:
      return {
        updatedAutoPay: false,
        isLoading: true,
      };
    case ACTIONS.postLoanAutoPayPersistSuccess:
      return {
        updatedAutoPay: true,
        data: action.payload.data,
        isLoading: false,
      };
    case ACTIONS.postLoanAutoPayPersistFail:
      return {
        ...state,
        updatedAutoPay: false,
        error: httpError(action),
        isLoading: false,
      };
    default:
      return state;
  }
};

// prettier-ignore
export const setAutoPayModalStateReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) { // NOSONAR
    case ACTIONS.setAutoPayModalState:
      return {
        autoPayModalState: action.payload.data.modalState,
        autoPayModalLoan: action.payload.data.loan,
      };
    default:
      return state;
  }
};

// prettier-ignore
export const autoPayAlertsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.setShowAutoPayFail:
      return {
        ...state,
        loanId: action.loanId,
        isShowFail: action.isShowFail,
      };
    case ACTIONS.setShowAutoPaySuccess:
      return {
        ...state,
        loanId: action.loanId,
        isShowSuccess: action.isShowSuccess,
      };
    default:
      return state;
  }
};
