import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useAutoFocus from 'hooks/useAutoFocus';

import { ErrorMessage, FormInput, Input } from 'styles/AuthStyles';

const AccountField = memo((props) => {
  const { showError, onAccountChange } = props;
  const { t } = useTranslation();
  const inputRef = useAutoFocus();

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('admin.errors.invalid_account')}</ErrorMessage>}
        <Input
          ref={inputRef}
          type="text"
          name="accound_id"
          aria-label="account number"
          // pattern="[0-9]{5,6}"
          // maxLength={6}
          placeholder={t('admin.account_id.placeholder')}
          onChange={onAccountChange}
          required
        />
      </FormInput>
    </>
  );
});

AccountField.displayName = 'AccountField';

AccountField.propTypes = {
  onAccountChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};

AccountField.defaultProps = {
  showError: false,
};

export default AccountField;
