/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useCallback, memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useForm from 'react-hook-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import {
  convertAmountInCentsToDollars,
  convertAmountInCentsToDollarsString,
  convertAmountInDollarsToCents,
  toKebabCase,
} from 'lib/utils';

import CustomDatePicker from 'components/calendar/CustomDatePicker';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import CommonPopover from 'components/popovers/CommonPopover';

import { postMakePayment } from 'state/loans/makePayment/actions';
import { postSchedulePaymentSchedule } from 'state/loans/makeScheduledPayments/actions';

import { paymentMethodsSelector } from 'state/paymentMethods/selectors';

import {
  formatMoneyDecimalFromDollars,
  formatMoneyDecimalFromCents,
  formatDecimalPlainFromCents,
  formatMaskedCreditCardNumber,
} from 'lib/Formatters';

import { MaterialIcon } from 'styles/Icons';
import { Alert, Body, Button, Data, Detail, Details } from 'styles/CommonStyles';
import { StyledTable, StyledTD, StyledTDText, upliftTeal } from 'styles/ModalStyles';
import MakePaymentModal from './modals/MakePaymentModal';

const PaymentPanel = styled.div`
  display: flex;
`;

const StyledData = styled(Data)``;

const StyledDetails = styled(Details)`
  padding: 0 0 0 1rem;
`;

const StyledMaskedCC = styled.div``;

const StyledPaymentForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const InputGroupAddon = styled.span`
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: ${(props) => (props.error ? '#a94442' : '#555555')};
  text-align: center;
  background-color: ${(props) => (props.error ? '#f2dede' : '#eeeeee')};
  border: 1px solid ${(props) => (props.error ? '#a94442' : '#ccc')};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const PaymentAmountInput = styled.input`
  width: 75px;
  padding: 5px 12px;
  font-size: 14px;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${(props) => (props.error ? '#a94442' : '#ccc')};
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  text-align: right;

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${(props) => (props.error ? 'rgba(220, 53, 69, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
    border-color: ${(props) => (props.error ? '#843534' : '')};
  }
`;

export const ErrorMessage = styled.div`
  color: #a94442;
  font-size: 0.6rem;
  margin: 5px 0px;
`;

const SchedulePaymentMessage = styled.div`
  color: #555555;
  font-size: 0.9rem;
  padding-bottom: 1rem;
`;

const StyledAlert = styled(Alert)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.borderLightGrey};
  padding: 0.7rem;
`;

const StyledInputLabel = styled.label`
  margin-bottom: 0;
`;

// prettier-ignore
const MakePaymentForm = memo(({ loanDetails, setPaymentFormState, isSchedulePayment }) => { // NOSONAR
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    currentPayoffBalance,
    lastScheduleDate,
    loanId,
    loanMaturityDate,
    paymentDueDay,
    paymentHistory,
    paymentToken,
    isDownPaymentMissing,
  } = loanDetails;

  const { handleSubmit, register, triggerValidation, getValues, formState, errors } = useForm({
    mode: 'onChange',
  });

  const formattedCurrentPayoffBalance = convertAmountInCentsToDollars(currentPayoffBalance);

  const [payoffBalance, setPayoffBalance] = useState(formattedCurrentPayoffBalance);
  const [validationColor, setValidationColor] = useState('');

  useEffect(() => {
    if (payoffBalance === 0) {
      setValidationColor('green');
    }
  }, [payoffBalance]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showScheduledPaymentModal, setShowScheduledPaymentModal] = useState(false);

  const paymentMethods = useSelector(paymentMethodsSelector);
  const selectedPaymentMethod = paymentMethods?.find((method) => method.paymentMethodId === paymentToken);
  const maskedAccountNumber = formatMaskedCreditCardNumber(selectedPaymentMethod?.accountNumberMask || selectedPaymentMethod?.cardNumberMask);

  const paymentDate = moment.localeData().ordinal(paymentDueDay);
  const paymentDateEndOfMonth = [29, 30, 31].includes(paymentDueDay);

  const todaysDate = new Date(moment());

  const [selectedDueDate, setSelectedDueDate] = useState(todaysDate); // Wed May 13 2020 00:00:00 GMT-0400 (Eastern Daylight Time)

  const selectedDueDateFormat = moment(selectedDueDate).format('YYYY-MM-DD'); // "2020-05-27"
  const todaysDateFormat = moment(todaysDate).format('YYYY-MM-DD'); // "2020-05-27"

  const makePaymentToday = selectedDueDateFormat === todaysDateFormat;

  const { paymentAmount } = getValues();

  const overdueBalance = paymentHistory.reduce((total, x) => {
    if (x.status === 'Overdue') {
      // eslint-disable-next-line no-param-reassign
      total += x.amount;
    }
    return total;
  }, 0);

  const nextPaymentOrOverdue =
    paymentHistory.find((x) => x.status === 'Next Payment') || paymentHistory.find((x) => x.status === 'Overdue') || '';
  const nextPaymentOrOverdueDate = nextPaymentOrOverdue.date
    ? moment(nextPaymentOrOverdue.date).format('ll')
    : `${t('common.label.not_found')}`;

  const makePaymentButtonText = () => {
    if (isDownPaymentMissing) {
      return t('account.initial_payment.make_initial_payment');
    }
    if (!makePaymentToday) {
      return t('loans.payment.schedule_payment.schedule_payment');
    }
    return t('loans.payment.make_payment');
  };

  const makePayment = useCallback(
    (data, event) => {
      setPaymentFormState('result');

      const { paymentAmount } = data; // NOSONAR

      // cevents
      let metadata = {};
      metadata.loanId = loanId;
      metadata.currentPayoffBalance = currentPayoffBalance;
      metadata.formattedCurrentPayoffBalance = formattedCurrentPayoffBalance;
      metadata.payoffBalance = payoffBalance;
      metadata.paymentAmount = paymentAmount;
      metadata.makePaymentToday = makePaymentToday;
      metadata.isDownPaymentMissing = isDownPaymentMissing;
      metadata.selectedDueDateFormat = selectedDueDateFormat;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw('user', 'click', 'make-payment', metadata);

      if (makePaymentToday) {
        dispatch(postMakePayment(loanId, paymentAmount, isDownPaymentMissing));
      } else {
        dispatch(postSchedulePaymentSchedule(loanId, paymentAmount, selectedDueDateFormat));
      }
    },
    [
      currentPayoffBalance,
      dispatch,
      formattedCurrentPayoffBalance,
      isDownPaymentMissing,
      loanId,
      makePaymentToday,
      payoffBalance,
      selectedDueDateFormat,
      setPaymentFormState,
    ]
  );

  const updatePayoffBalance = useCallback(
    (event) => {
      event.preventDefault();

      const updatedBalanceInCents = currentPayoffBalance - convertAmountInDollarsToCents(event.target.value);
      const updatedBalanceInDollars = convertAmountInCentsToDollars(updatedBalanceInCents);

      // cevents
      let metadata = {};
      metadata.value = event.target.value;
      metadata.loanId = loanId;
      metadata.formattedCurrentPayoffBalance = formattedCurrentPayoffBalance;
      metadata.currentPayoffBalance = currentPayoffBalance;
      metadata.updatedBalanceInCents = updatedBalanceInCents;
      metadata.updatedBalanceInDollars = updatedBalanceInDollars;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw('user', 'keypress', 'update-payoff-balance', metadata);

      if (Number.isNaN(updatedBalanceInCents)) {
        setPayoffBalance(formattedCurrentPayoffBalance);
        setValidationColor('');
      } else if (updatedBalanceInCents === 0) {
        setPayoffBalance(updatedBalanceInDollars);
        setValidationColor('green');
      } else if (updatedBalanceInCents < 0 || updatedBalanceInCents > currentPayoffBalance) {
        setPayoffBalance(updatedBalanceInDollars);
        setValidationColor('red');
      } else {
        setPayoffBalance(updatedBalanceInDollars);
        setValidationColor('');
      }
    },
    [currentPayoffBalance, formattedCurrentPayoffBalance, loanId]
  );

  const confirmPayment = useCallback(
    () => { // NOSONAR
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'open-make-payment-modal',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      if (makePaymentToday) {
        setShowPaymentModal(true);
      } else {
        setShowScheduledPaymentModal(true);
      }
    },
    [makePaymentToday]
  );

  const closeModal = useCallback((event) => {
    event.preventDefault();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'close-make-payment-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setShowPaymentModal(false);

    setShowScheduledPaymentModal(false);
  }, []);

  const onSubmit = useCallback((data, event) => {
    // There are multiple submit buttons in the form.
    // Upon first submission, display modal to confirm payment.
    // Once the modal is showing and user confirms, send payment request.
    if (showPaymentModal || showScheduledPaymentModal) {
      makePayment(data);
    } else {
      confirmPayment(event);
    }
  }, [confirmPayment, makePayment, showPaymentModal, showScheduledPaymentModal]);

  const paymentAmountInputId = `${loanId}-payment-amount`;
  const paymentDateInputId = `${loanId}-payment-date`;

  return (
    <>
      <Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledAlert variant="primary">
            <SchedulePaymentMessage>
              <div data-testid="schedule-payment-confirm">
                {paymentDateEndOfMonth ? (
                  <Trans i18nKey="loans.payment.schedule_payment.schedule_payment_confirm2" paymentDate={paymentDate}>
                    Your payment is due on the <b>{{ paymentDate }}</b> of each month (or the last day of the month for shorter
                    months). You are responsible for the daily interest that accrues if you make a payment past its due date.
                  </Trans>
                ) : (
                  <Trans i18nKey="loans.payment.schedule_payment.schedule_payment_confirm" paymentDate={paymentDate}>
                    Your payment is due on the <b>{{ paymentDate }}</b> of each month. You are responsible for the daily interest
                    that accrues if you schedule a payment past its due date.
                  </Trans>
                )}
              </div>
            </SchedulePaymentMessage>

            <StyledDetails>
              <Detail>
                <div data-testid="payoff-balance">{t('loans.payment.form.payoff_balance')}</div>
                {makePaymentToday ? (
                  <StyledData data-testid="payoff-balance-value" style={{ color: validationColor }}>
                    {formatMoneyDecimalFromDollars(payoffBalance)}
                  </StyledData>
                ) : (
                  <StyledData data-testid="payoff-balance-value">
                    {formatMoneyDecimalFromDollars(formattedCurrentPayoffBalance)}
                  </StyledData>
                )}
              </Detail>

              {overdueBalance > 0 && (
                <Detail>
                  <div>{t('loans.payment.form.overdue_balance')}</div>
                  <StyledData>{formatMoneyDecimalFromCents(overdueBalance)}</StyledData>
                </Detail>
              )}

              <Detail>
                <div className="d-flex flex-column">
                  <div data-testid="payment-method">{t('loans.payment.form.payment_method')}</div>
                </div>
                <StyledMaskedCC data-testid="ending-in">
                  {t('account.payment_methods.details.end_in')}
                  <b>{maskedAccountNumber}</b>
                </StyledMaskedCC>
              </Detail>

              <Detail>
                {isDownPaymentMissing ? (
                  <>
                    <div className="d-flex align-items-center">
                      <div data-testid="payment-amount" className="mr-1">
                        <StyledInputLabel htmlFor={paymentAmountInputId}>
                          {t('account.initial_payment.initial_payment_amount')}
                        </StyledInputLabel>
                      </div>
                      <CommonPopover
                        type="downpay-missing"
                        placement="top"
                        color="#555555"
                        icon="help_outline"
                        textcontent={t('account.initial_payment.initial_payment_tooltip')}
                      />
                    </div>

                    <StyledPaymentForm>
                      <div>
                        <InputGroupAddon error={errors.paymentAmount}>$</InputGroupAddon>
                        <PaymentAmountInput
                          id={paymentAmountInputId}
                          style={{ backgroundColor: '#eeeeee' }}
                          disabled
                          data-testid="payment-amount-value"
                          error={errors.paymentAmount}
                          type="number"
                          step="0.01"
                          name="paymentAmount"
                          placeholder="0.00"
                          onChange={updatePayoffBalance}
                          value={convertAmountInCentsToDollarsString(loanDetails?.downPayment.amount)}
                          ref={register({
                            required: 'loans.payment.form.validation.invalid_amount',
                            pattern: { value: /^[0-9]*(\.[0-9]{1,2})?$/i, message: 'loans.payment.form.validation.invalid_amount' }, // NOSONAR
                            validate: {
                              min: (value) => parseFloat(value) > 0 || 'loans.payment.form.validation.invalid_amount',
                              max: (value) => parseFloat(value) <= payoffBalance || 'loans.payment.form.validation.invalid_amount',
                            },
                          })}
                        />
                      </div>
                      {errors.paymentAmount ? <ErrorMessage>{t(errors.paymentAmount.message)}</ErrorMessage> : null}
                    </StyledPaymentForm>
                  </>
                ) : (
                  <>
                    <div data-testid="payment-amount">
                      <StyledInputLabel htmlFor={paymentAmountInputId}>
                        {t('loans.payment.form.payment_amount')}
                      </StyledInputLabel>
                    </div>
                    <StyledPaymentForm>
                      <div>
                        <InputGroupAddon error={errors.paymentAmount}>$</InputGroupAddon>
                        <PaymentAmountInput
                          id={paymentAmountInputId}
                          data-testid="payment-amount-value"
                          error={errors.paymentAmount}
                          type="number"
                          step="0.01"
                          name="paymentAmount"
                          placeholder="0.00"
                          onChange={updatePayoffBalance}
                          ref={register({
                            required: 'loans.payment.form.validation.invalid_amount',
                            pattern: { value: /^[0-9]*(\.[0-9]{1,2})?$/i, message: 'loans.payment.form.validation.invalid_amount' }, // NOSONAR
                            validate: {
                              min: (value) => parseFloat(value) > 0 || 'loans.payment.form.validation.invalid_amount',
                              max: (value) => parseFloat(value) <= formattedCurrentPayoffBalance || 'loans.payment.form.validation.invalid_amount',
                            },
                          })}
                        />
                      </div>
                      {errors.paymentAmount ? <ErrorMessage>{t(errors.paymentAmount.message)}</ErrorMessage> : null}
                    </StyledPaymentForm>
                  </>
                )}
              </Detail>

              {isSchedulePayment && !isDownPaymentMissing && (
                <Detail>
                  <div data-testid="date">
                    <StyledInputLabel htmlFor={paymentDateInputId}>{t('loans.payment.schedule_payment.date')}</StyledInputLabel>
                  </div>
                  <CustomDatePicker
                    id={paymentDateInputId}
                    selectedDueDate={selectedDueDate}
                    setSelectedDueDate={setSelectedDueDate}
                    minDate={todaysDate}
                    loanMaturity={new Date(moment(loanMaturityDate))}
                    monthlyDueDay={paymentDueDay}
                    lastScheduleDate={lastScheduleDate}
                  />
                </Detail>
              )}
            </StyledDetails>
          </StyledAlert>

          <PaymentPanel>
            <Button
              className="btn-block"
              disabled={formState.isSubmitting}
              type="submit"
            >
              {makePaymentButtonText()}
            </Button>
          </PaymentPanel>
          <MakePaymentModal
            showPaymentModal={showPaymentModal}
            onClose={closeModal}
            maskedAccountNumber={maskedAccountNumber}
            paymentAmount={paymentAmount}
            formState={formState}
          />
          {showScheduledPaymentModal && (
            <CommonModal
              title={t('loans.payment.schedule_payment.modal.review_payments')}
              body1={
                <>
                  <StyledTable responsive bordered>
                    <tbody>
                      <tr>
                        <StyledTD>
                          <div className="d-flex">
                            <MaterialIcon name="update" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                            <div>{t('loans.payment.schedule_payment.modal.monthly_due_date')}</div>
                          </div>
                        </StyledTD>
                        <StyledTDText>{nextPaymentOrOverdueDate}</StyledTDText>
                      </tr>
                      <tr>
                        <StyledTD>
                          <div className="d-flex">
                            <MaterialIcon name="today" theme="outlined" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                            <div>{t('loans.payment.schedule_payment.modal.scheduled_date')}</div>
                          </div>
                        </StyledTD>
                        <StyledTDText>{moment(selectedDueDate).format('ll')}</StyledTDText>
                      </tr>
                      <tr>
                        <StyledTD>
                          <div className="d-flex">
                            <MaterialIcon
                              name="monetization_on"
                              theme="outlined"
                              color={upliftTeal}
                              style={{ marginRight: '0.2rem' }}
                            />
                            <div>{t('loans.payment.schedule_payment.modal.scheduled_amount')}</div>
                          </div>
                        </StyledTD>
                        <StyledTDText>${formatDecimalPlainFromCents(convertAmountInDollarsToCents(paymentAmount))}</StyledTDText>
                      </tr>
                    </tbody>
                  </StyledTable>
                  {t('loans.payment.schedule_payment.modal.schedule_confirm')}
                </>
              }
              button1Type="submit"
              submitEvent2={closeModal}
              button1={t('loans.payment.schedule_payment.status.confirm_and_finish')}
              button2={t('common.buttons.cancel')}
              onClose={closeModal}
            />
          )}
        </form>
      </Body>
    </>
  );
});

MakePaymentForm.displayName = 'MakePaymentForm';

MakePaymentForm.propTypes = {
  loanDetails: PropTypes.shape({
    currentPayoffBalance: PropTypes.number,
    lastScheduleDate: PropTypes.string,
    loanId: PropTypes.string,
    loanMaturityDate: PropTypes.string,
    paymentDueDay: PropTypes.number,
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    paymentToken: PropTypes.string,
    downPayment: PropTypes.shape({ amount: PropTypes.number }),
    isDownPaymentMissing: PropTypes.bool,
  }).isRequired,
  setPaymentFormState: PropTypes.func.isRequired,
  isSchedulePayment: PropTypes.bool,
};

MakePaymentForm.defaultProps = {
  isSchedulePayment: null,
};

export default MakePaymentForm;
