import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Body } from 'styles/CommonStyles';

const ProcessingPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ProcessingQuestion = styled.div`
  color: #333;
  font-size: 0.9rem;

  margin: 0.5rem 0;
`;

const ProcessingAnswer = styled.div`
  color: #333;
  font-size: 0.75rem;
`;

const MakePaymentDisabled = memo(() => {
  const { t } = useTranslation();

  return (
    <Body>
      <ProcessingPanel>
        <ProcessingQuestion data-testid="why-cant-pay">{t('loans.payment.why_cant_pay')}</ProcessingQuestion>
        <ProcessingAnswer data-testid="processing-nls">{t('loans.payment.processing_nls')}</ProcessingAnswer>
      </ProcessingPanel>
    </Body>
  );
});

export default MakePaymentDisabled;
