import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { associatePaymentMethodBatchLoadingSelector } from 'state/paymentMethods/selectors';
import { autoPayInitializeBatchLoadingSelector, autoPayInitializeBatchSuccessesSelector } from 'state/autoPay/selectors';
import { disableLoanAutoPay, enableLoanAutoPay, postLoanAutoPayInitializeBatch } from 'state/autoPay/actions';
import { loansSelector } from 'state/loans/selectors';
import { paymentMethodCancel } from 'state/secureFormsPaymentMethods/actions';

import { associatePaymentMethodWithLoans, setPaymentMethodPrefillLoans } from 'state/paymentMethods/actions';
import { switchPaymentMethodForLoan } from 'state/loans/setPaymentMethod/actions';
import { showPaymentMethodSuccess } from 'state/updatePaymentMethods/actions';
import { showPaymentMethodSuccessAlertSelector } from 'state/updatePaymentMethods/selectors';
import AutoPayAgreements from '../wallet/components/AutoPayAgreements';
import LoanSummarySelection from '../wallet/components/LoanSummarySelection';

export const STEPS = {
  LOAN_SELECTION: 0,
  AUTOPAY_AGREEMENTS: 1,
};

const StyledRoot = styled.div`
  max-width: 480px;
  margin: 1.5rem auto;
  ${(props) => props.isModalView && 'margin-top: 0;'}
`;

const getMerchantInfo = (loanId, loans) => {
  const matchingLoan = loans.find((loan) => loan.loanId === loanId);
  return matchingLoan ? matchingLoan.merchantInfo : null;
};

const PaymentMethodAssociationFlow = ({ onCancel, onComplete, onError, paymentMethod, isModalView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEPS.LOAN_SELECTION);
  const [selectedLoans, setSelectedLoans] = useState([]);

  const loans = useSelector(loansSelector);
  const autoPayInitializeLoading = useSelector(autoPayInitializeBatchLoadingSelector);
  const autoPayInitializeSuccesses = useSelector(autoPayInitializeBatchSuccessesSelector);
  const associatePaymentMethodLoading = useSelector(associatePaymentMethodBatchLoadingSelector);
  const showAlertSuccess = useSelector(showPaymentMethodSuccessAlertSelector);

  const setPaymentMethodSuccessAlert = (isShow) => dispatch(showPaymentMethodSuccess(isShow));

  /**
   * Called to finalize the Loan and Payment Method association. For Autopay loans, this involves
   * persisting the Autopay session (meaning the user agreed to the document). For non-Autopay loans,
   * they will simply call the loan-update endpoint to set the new payment token.
   * @param {Array<{ loanId: string; sessionId: string;}>} autopayInitialized Array of loans that have been initialized for Autopay
   * @param {Array<{ loanId: string; autopayEnabled: boolean; }>} selection Array of loans that were selected
   */
  const handleFinalize = async (autopayInitialized, selection) => {
    const finalizedLoans = selection.map((selected) => {
      const autopay = autopayInitialized.find((autopayInitializedLoan) => autopayInitializedLoan.loanId === selected.loanId);
      const finalized = {
        ...selected,
        paymentMethodId: paymentMethod.paymentMethodId,
      };
      if (autopay) {
        finalized.sessionId = autopay.sessionId;
      }
      return finalized;
    });
    const finalizedResult = await dispatch(associatePaymentMethodWithLoans(finalizedLoans));
    const { successes, failures } = finalizedResult.payload;

    dispatch(setPaymentMethodPrefillLoans([]));

    successes.forEach((success) => {
      dispatch(switchPaymentMethodForLoan(success.loanId, paymentMethod.paymentMethodId, success.autopayEnabled));
      const autopayAction = success.autopayEnabled ? enableLoanAutoPay(success.loanId) : disableLoanAutoPay(success.loanId);
      dispatch(autopayAction);
    });

    if (onError) {
      onError(failures, successes, selection, STEPS.AUTOPAY_AGREEMENTS);
    }

    if (onComplete) {
      onComplete(successes, failures);
    }
  };

  /**
   * Deal with the loans the user selected to associate to the payment method.
   * @param {Array<{ loanId: string; autopayEnabled: boolean }>} selection Array of selected loans
   */
  const handleLoansSelection = async (selection) => {
    setSelectedLoans(selection);
    const autopayLoanIds = selection.filter((selected) => selected.autopayEnabled).map((selected) => selected.loanId);
    if (autopayLoanIds?.length) {
      const initializeResult = await dispatch(postLoanAutoPayInitializeBatch(autopayLoanIds, paymentMethod.paymentMethodId));
      const { successes, failures } = initializeResult.payload;
      if (successes?.length) {
        setStep(STEPS.AUTOPAY_AGREEMENTS);
      } else if (selection.length > autopayLoanIds.length) {
        // Failed to initialize all the Autopay loans. Finalize any non-Autopay loans
        handleFinalize([], selection);
      }

      if (onError && failures?.length) {
        onError(successes, failures, selection, STEPS.LOAN_SELECTION);
      }
    } else {
      // No Autopay-enabled loans, so just skip to finalizing
      handleFinalize([], selection);
    }
  };

  const handleAgreementsCancel = () => {
    setStep(STEPS.LOAN_SELECTION);
  };

  const isLoading = autoPayInitializeLoading || associatePaymentMethodLoading;

  const handleSkip = () => {
    dispatch(paymentMethodCancel());
    dispatch(setPaymentMethodPrefillLoans([]));
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <StyledRoot isModalView={isModalView}>
      {step === STEPS.LOAN_SELECTION && (
        <LoanSummarySelection
          defaultPaymentMethodType={paymentMethod.paymentMethodType}
          loading={isLoading}
          maskedAccountNumber={paymentMethod.accountNumberMask || paymentMethod.cardNumberMask}
          onSkip={handleSkip}
          onSubmit={handleLoansSelection}
          showAlertSuccess={showAlertSuccess}
          setShowAlertSuccess={setPaymentMethodSuccessAlert}
          isModalView={isModalView}
        />
      )}
      {step === STEPS.AUTOPAY_AGREEMENTS && (
        <AutoPayAgreements
          autopayDocuments={autoPayInitializeSuccesses.map((autoPay) => {
            return {
              loanId: autoPay.loanId,
              documentUrl: autoPay.documentUrl,
              merchantInfo: getMerchantInfo(autoPay.loanId, loans),
              sessionId: autoPay.sessionId,
            };
          })}
          cancelLabel={t('common.buttons.cancel')}
          loading={isLoading}
          nextLabel={t('common.buttons.agree_and_continue')}
          onCancel={handleAgreementsCancel}
          onComplete={(autopayDocs) => handleFinalize(autopayDocs, selectedLoans)}
          isModalView={isModalView}
        />
      )}
    </StyledRoot>
  );
};

PaymentMethodAssociationFlow.propTypes = {
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  paymentMethod: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardNumberMask: PropTypes.string,
    paymentMethodId: PropTypes.string,
    paymentMethodType: PropTypes.string,
  }).isRequired,
  isModalView: PropTypes.bool,
};

PaymentMethodAssociationFlow.defaultProps = {
  onCancel: undefined,
  onComplete: undefined,
  onError: undefined,
  isModalView: false,
};

export default PaymentMethodAssociationFlow;
