import { Auth } from 'aws-amplify';
import { logout } from 'state/auth/actions';

export const awsRequestInterceptor = async (requestConfig) => {
  const updatedRequest = requestConfig;
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  if (user) {
    const accessToken = user?.signInUserSession?.idToken?.jwtToken;
    if (accessToken) {
      updatedRequest.headers.Authorization = accessToken;
    }
  }
  return updatedRequest;
};

export const awsResponseInterceptor = (error, dispatch) => {
  if (error === 'not authenticated') {
    // This occurs when the session cannot be refreshed, likely due to refresh token expiry.
    // Force the user to log out at this point.
    dispatch(logout());
  }
  return Promise.reject(error);
};
