// eslint-disable-next-line import/prefer-default-export
export const AIRPORTS = {
  CKY: {
    label: 'Conakry (CKY)',
    city: 'Conakry',
    country: 'Guinea',
  },
  CTU: {
    label: 'Chengdu (CTU)',
    city: 'Chengdu',
    country: 'China',
  },
  BEG: {
    label: 'Beograd (BEG)',
    city: 'Belgrade',
    country: 'Serbia',
  },
  TNE: {
    label: 'Tanegashima Airport (TNE)',
    city: 'Tanegashima',
    country: 'Japan',
  },
  VBY: {
    label: 'Visby (VBY)',
    city: 'Visby',
    country: 'Sweden',
  },
  SSJ: {
    label: 'Stokka (SSJ)',
    city: 'Sandnessjoen',
    country: 'Norway',
  },
  HYR: {
    label: 'Hayward Mncpl (HYR)',
    city: 'Hayward',
    country: 'United States',
  },
  FTE: {
    label: 'El Calafate (FTE)',
    city: 'El Calafate',
    country: 'Argentina',
  },
  CHS: {
    label: 'Charleston (CHS)',
    city: 'Charleston',
    country: 'United States',
  },
  LAU: {
    label: 'Lamu (LAU)',
    city: 'Lamu',
    country: 'Kenya',
  },
  CTW: {
    label: 'Cottonwood Mncpl (CTW)',
    city: 'Cottonwood',
    country: 'United States',
  },
  PDT: {
    label: 'Pendleton Airport (PDT)',
    city: 'Pendleton',
    country: 'United States',
  },
  AUA: {
    label: 'Reina Beatrix Airport (AUA)',
    city: 'Oranjestad',
    country: 'Aruba',
  },
  ARH: {
    label: 'Arkhangelsk (ARH)',
    city: 'Arkhangelsk',
    country: 'Russia',
  },
  TTE: {
    label: 'Babullah Airport (TTE)',
    city: 'Ternate',
    country: 'Indonesia',
  },
  CHY: {
    label: 'Choiseul Bay Airport (CHY)',
    city: 'Choiseul Bay',
    country: 'Solomon Islands',
  },
  TGO: {
    label: 'Tongliao Airport (TGO)',
    city: 'Tongliao',
    country: 'China',
  },
  BTI: {
    label: 'Barter Island Airport (BTI)',
    city: 'Barter Island',
    country: 'United States',
  },
  UEE: {
    label: 'Queenstown (UEE)',
    city: 'Queenstown',
    country: 'Australia',
  },
  CTG: {
    label: 'Rafael Nunez (CTG)',
    city: 'Cartagena',
    country: 'Colombia',
  },
  XFN: {
    label: 'Xiangfan (XFN)',
    city: 'Xiangfan',
    country: 'China',
  },
  OKD: {
    label: 'Okadama (OKD)',
    city: 'Sapporo',
    country: 'Japan',
  },
  MAA: {
    label: 'Chennai (MAA)',
    city: 'Chennai',
    country: 'India',
  },
  AUX: {
    label: 'Araguaina Airport (AUX)',
    city: 'Araguaina',
    country: 'Brazil',
  },
  SIP: {
    label: 'Simferopol Airport (SIP)',
    city: 'Simferopol',
    country: 'Ukraine',
  },
  URO: {
    label: 'Boos (URO)',
    city: 'Rouen',
    country: 'France',
  },
  IGA: {
    label: 'Inagua Airport (IGA)',
    city: 'Inagua',
    country: 'Bahamas',
  },
  RHE: {
    label: 'Reims-Champagne (RHE)',
    city: 'Reims',
    country: 'France',
  },
  VIE: {
    label: 'Vienna International Airport (VIE)',
    city: 'Vienna',
    country: 'Austria',
  },
  ADK: {
    label: 'Adak Airport (ADK)',
    city: 'Adak',
    country: 'United States',
  },
  VCT: {
    label: 'County-Foster Airport (VCT)',
    city: 'Victoria',
    country: 'United States',
  },
  ASA: {
    label: 'Assab Airport (ASA)',
    city: 'Assab',
    country: 'Eritrea',
  },
  ONH: {
    label: 'Oneonta Mncpl (ONH)',
    city: 'Oneonta',
    country: 'United States',
  },
  KPC: {
    label: 'Port Clarence Airport (KPC)',
    city: 'Port Clarence',
    country: 'United States',
  },
  YGO: {
    label: 'Gods Narrows Airport (YGO)',
    city: 'Gods Narrows',
    country: 'Canada',
  },
  UIP: {
    label: 'Pluguffan (UIP)',
    city: 'Quimper',
    country: 'France',
  },
  COR: {
    label: 'Pajas Blancas (COR)',
    city: 'Cordoba',
    country: 'Argentina',
  },
  ELH: {
    label: 'North Eleuthera International Airport (ELH)',
    city: 'North Eleuthera',
    country: 'Bahamas',
  },
  NVT: {
    label: 'Navegantes Airport (NVT)',
    city: 'Navegantes',
    country: 'Brazil',
  },
  MHG: {
    label: 'Neuostheim (MHG)',
    city: 'Mannheim',
    country: 'Germany',
  },
  IRP: {
    label: 'Matari (IRP)',
    city: 'Isiro',
    country: 'Democratic Republic of Congo',
  },
  ELM: {
    label: 'Elmira Regional Airport (ELM)',
    city: 'Elmira',
    country: 'United States',
  },
  DAR: {
    label: 'Dar es Salaam Intl (DAR)',
    city: 'Dar es Salaam',
    country: 'Tanzania',
  },
  PJB: {
    label: 'Payson Mncpl (PJB)',
    city: 'Payson',
    country: 'United States',
  },
  AWD: {
    label: 'Aniwa Airport (AWD)',
    city: 'Aniwa',
    country: 'Vanuatu',
  },
  HHQ: {
    label: 'Hua Hin (HHQ)',
    city: 'Hua Hin',
    country: 'Thailand',
  },
  MAF: {
    label: 'Odessa Regional Airport (MAF)',
    city: 'Midland',
    country: 'United States',
  },
  KAO: {
    label: 'Kuusamo (KAO)',
    city: 'Kuusamo',
    country: 'Finland',
  },
  TPS: {
    label: 'Birgi (TPS)',
    city: 'Trapani',
    country: 'Italy',
  },
  CDV: {
    label: 'Mudhole Smith Airport (CDV)',
    city: 'Cordova',
    country: 'United States',
  },
  ARM: {
    label: 'Armidale (ARM)',
    city: 'Armidale',
    country: 'Australia',
  },
  CFR: {
    label: 'Carpiquet (CFR)',
    city: 'Caen',
    country: 'France',
  },
  NUI: {
    label: 'Nuiqsut Airport (NUI)',
    city: 'Nuiqsut',
    country: 'United States',
  },
  SGD: {
    label: 'Sonderborg (SGD)',
    city: 'Sonderborg',
    country: 'Denmark',
  },
  SKN: {
    label: 'Skagen (SKN)',
    city: 'Stokmarknes',
    country: 'Norway',
  },
  ECA: {
    label: 'East Tawas Mncpl (ECA)',
    city: 'East Tawas',
    country: 'United States',
  },
  CVT: {
    label: 'Baginton (CVT)',
    city: 'Coventry',
    country: 'United Kingdom',
  },
  PJM: {
    label: 'Puerto Jimenez (PJM)',
    city: 'Puerto Jimenez',
    country: 'Costa Rica',
  },
  FLS: {
    label: 'Flinders Island Airport (FLS)',
    city: 'Flinders Island',
    country: 'Australia',
  },
  IMP: {
    label: 'Imperatriz Airport (IMP)',
    city: 'Imperatriz',
    country: 'Brazil',
  },
  ROV: {
    label: 'Rostov (ROV)',
    city: 'Rostov',
    country: 'Russia',
  },
  BUC: {
    label: 'Burketown Airport (BUC)',
    city: 'Burketown',
    country: 'Australia',
  },
  FSP: {
    label: 'St-Pierre (FSP)',
    city: 'St-Pierre',
    country: 'Dominica',
  },
  MTY: {
    label: 'Gen Mariano Escobedo Airport (MTY)',
    city: 'Monterrey',
    country: 'Mexico',
  },
  PIX: {
    label: 'Pico Island Airport (PIX)',
    city: 'Pico Island (Azores)',
    country: 'Portugal',
  },
  ANG: {
    label: 'Brie Champniers (ANG)',
    city: 'Angouleme',
    country: 'France',
  },
  GAX: {
    label: 'Gamba Airport (GAX)',
    city: 'Gamba',
    country: 'Gabon',
  },
  SOP: {
    label: 'Southern Pines (SOP)',
    city: 'Pinehurst',
    country: 'United States',
  },
  TME: {
    label: 'Tame Airport (TME)',
    city: 'Tame',
    country: 'Colombia',
  },
  UYN: {
    label: 'Yulin Airport (UYN)',
    city: 'Yulin',
    country: 'China',
  },
  MGS: {
    label: 'Mangaia Island Airport (MGS)',
    city: 'Mangaia Island',
    country: 'Cook Islands',
  },
  SHW: {
    label: 'Sharurah Airport (SHW)',
    city: 'Sharurah',
    country: 'Saudi Arabia',
  },
  PIF: {
    label: 'Pingtung (PIF)',
    city: 'Pingtung',
    country: 'Taiwan',
  },
  CDC: {
    label: 'Cedar City Airport (CDC)',
    city: 'Cedar City',
    country: 'United States',
  },
  MXM: {
    label: 'Morombe Airport (MXM)',
    city: 'Morombe',
    country: 'Madagascar',
  },
  KLV: {
    label: 'Karlovy Vary (KLV)',
    city: 'Karlovy Vary',
    country: 'Czech Republic',
  },
  GAL: {
    label: 'Galena (GAL)',
    city: 'Galena',
    country: 'United States',
  },
  JKG: {
    label: 'Axamo (JKG)',
    city: 'Jonkoping',
    country: 'Sweden',
  },
  YKA: {
    label: 'Kamloops Airport (YKA)',
    city: 'Kamloops',
    country: 'Canada',
  },
  YLH: {
    label: 'Lansdowne House Airport (YLH)',
    city: 'Lansdowne House',
    country: 'Canada',
  },
  MWI: {
    label: 'Maramoni Airport (MWI)',
    city: 'Maramoni',
    country: 'Papua New Guinea',
  },
  CBT: {
    label: 'Catumbela Airport (CBT)',
    city: 'Catumbela',
    country: 'Angola',
  },
  UIO: {
    label: 'Mariscal Sucre Airport (UIO)',
    city: 'Quito',
    country: 'Ecuador',
  },
  ZNZ: {
    label: 'Zanzibar Kisauni (ZNZ)',
    city: 'Zanzibar',
    country: 'Tanzania',
  },
  YQK: {
    label: 'Kenora (YQK)',
    city: 'Kenora',
    country: 'Canada',
  },
  ELI: {
    label: 'Elim Airport (ELI)',
    city: 'Elim',
    country: 'United States',
  },
  WGA: {
    label: 'Forest Hill (WGA)',
    city: 'Wagga Wagga',
    country: 'Australia',
  },
  LTI: {
    label: 'Altai Airport (LTI)',
    city: 'Altai',
    country: 'Mongolia',
  },
  AUF: {
    label: 'Auxerre Branches (AUF)',
    city: 'Auxerre',
    country: 'France',
  },
  EIN: {
    label: 'Eindhoven (EIN)',
    city: 'Eindhoven',
    country: 'Netherlands',
  },
  URS: {
    label: 'Kursk Airport (URS)',
    city: 'Kursk',
    country: 'Russia',
  },
  YCM: {
    label: 'St Catharines Mncpl (YCM)',
    city: 'St Catharines',
    country: 'Canada',
  },
  BID: {
    label: 'Block Island State (BID)',
    city: 'Block Island',
    country: 'United States',
  },
  SVX: {
    label: 'Koltsovo (SVX)',
    city: 'Ekaterinburg',
    country: 'Russia',
  },
  CZH: {
    label: 'Corozal Mncpl (CZH)',
    city: 'Corozal',
    country: 'Belize',
  },
  AKS: {
    label: "Gwaunaru'u Airport (AKS)",
    city: 'Auki',
    country: 'Solomon Islands',
  },
  WWD: {
    label: 'Cape May County (WWD)',
    city: 'Cape May',
    country: 'United States',
  },
  DRO: {
    label: 'La Plata Airport (DRO)',
    city: 'Durango',
    country: 'United States',
  },
  HSL: {
    label: 'Huslia Airport (HSL)',
    city: 'Huslia',
    country: 'United States',
  },
  BGD: {
    label: 'Hutchinson County (BGD)',
    city: 'Borger',
    country: 'United States',
  },
  WWY: {
    label: 'West Wyalong (WWY)',
    city: 'West Wyalong',
    country: 'Australia',
  },
  OSB: {
    label: 'Osage Beach (OSB)',
    city: 'Osage Beach',
    country: 'United States',
  },
  DTH: {
    label: 'Death Valley (DTH)',
    city: 'Death Valley',
    country: 'United States',
  },
  GOH: {
    label: 'Nuuk (GOH)',
    city: 'Nuuk',
    country: 'Greenland',
  },
  EKO: {
    label: 'Elko (EKO)',
    city: 'Elko',
    country: 'United States',
  },
  APN: {
    label: 'Alpena County Regional Airport (APN)',
    city: 'Alpena',
    country: 'United States',
  },
  RGI: {
    label: 'Rangiroa (RGI)',
    city: 'Rangiroa',
    country: 'French Polynesia and Tahiti',
  },
  ALH: {
    label: 'Albany (ALH)',
    city: 'Albany',
    country: 'Australia',
  },
  YNG: {
    label: 'Youngstown Mncpl (YNG)',
    city: 'Youngstown',
    country: 'United States',
  },
  MWZ: {
    label: 'Mwanza Airport (MWZ)',
    city: 'Mwanza',
    country: 'Tanzania',
  },
  TRW: {
    label: 'Bonriki Airport (TRW)',
    city: 'Tarawa',
    country: 'Kiribati',
  },
  DMM: {
    label: 'King Fahad Intl (DMM)',
    city: 'Dammam',
    country: 'Saudi Arabia',
  },
  YGJ: {
    label: 'Yonago (YGJ)',
    city: 'Yonago',
    country: 'Japan',
  },
  JOG: {
    label: 'Adisutjipto (JOG)',
    city: 'Yogyakarta',
    country: 'Indonesia',
  },
  VLS: {
    label: 'Valesdir Airport (VLS)',
    city: 'Valesdir',
    country: 'Vanuatu',
  },
  CIJ: {
    label: 'E Beltram Airport (CIJ)',
    city: 'Cobija',
    country: 'Bolivia',
  },
  KDU: {
    label: 'Skardu (KDU)',
    city: 'Skardu',
    country: 'Pakistan',
  },
  BVA: {
    label: 'Tille (BVA)',
    city: 'Paris',
    country: 'France',
  },
  BXR: {
    label: 'Bam Airport (BXR)',
    city: 'Bam',
    country: 'Iran',
  },
  GOU: {
    label: 'Garoua Airport (GOU)',
    city: 'Garoua',
    country: 'Cameroon',
  },
  YLD: {
    label: 'Chapleau Mncpl (YLD)',
    city: 'Chapleau',
    country: 'Canada',
  },
  PAD: {
    label: 'Paderborn (PAD)',
    city: 'Paderborn',
    country: 'Germany',
  },
  JDH: {
    label: 'Jodhpur (JDH)',
    city: 'Jodhpur',
    country: 'India',
  },
  LAW: {
    label: 'Lawton Municipal Airport (LAW)',
    city: 'Lawton',
    country: 'United States',
  },
  GDO: {
    label: 'Vare Maria Airport (GDO)',
    city: 'Guasdualito',
    country: 'Venezuela',
  },
  YIO: {
    label: 'Pond Inlet (YIO)',
    city: 'Pond Inlet',
    country: 'Canada',
  },
  EUA: {
    label: 'Kaufana Airport (EUA)',
    city: 'Eua',
    country: 'Tonga',
  },
  GDN: {
    label: 'Rebiechowo (GDN)',
    city: 'Gdansk',
    country: 'Poland',
  },
  RMQ: {
    label: 'ChingChuanKang Airport (RMQ)',
    city: 'Taichung',
    country: 'China',
  },
  LNZ: {
    label: 'Hoersching (LNZ)',
    city: 'Linz',
    country: 'Austria',
  },
  HAN: {
    label: 'Noibai (HAN)',
    city: 'Hanoi',
    country: 'Vietnam',
  },
  FJR: {
    label: 'Fujairah Intl (FJR)',
    city: 'Al-Fujairah',
    country: 'United Arab Emirates',
  },
  WTE: {
    label: 'Wotje Airport (WTE)',
    city: 'Wotje',
    country: 'Marshall Islands',
  },
  NSK: {
    label: "Noril'sk Airport (NSK)",
    city: "Noril'sk",
    country: 'Russia',
  },
  AKP: {
    label: 'Anaktuvuk Airport (AKP)',
    city: 'Anaktuvuk',
    country: 'United States',
  },
  CFE: {
    label: 'Aulnat (CFE)',
    city: 'Clermont-Ferrand',
    country: 'France',
  },
  JUI: {
    label: 'Juist (JUI)',
    city: 'Juist',
    country: 'Germany',
  },
  YPM: {
    label: 'Pikangikum Airport (YPM)',
    city: 'Pikangikum',
    country: 'Canada',
  },
  MNT: {
    label: 'Minto Airport (MNT)',
    city: 'Minto',
    country: 'United States',
  },
  RIS: {
    label: 'Rishiri Airport (RIS)',
    city: 'Rishiri',
    country: 'Japan',
  },
  MYG: {
    label: 'Mayaguana Airport (MYG)',
    city: 'Mayaguana',
    country: 'Bahamas',
  },
  YYT: {
    label: 'St Johns Airport (YYT)',
    city: "St John's",
    country: 'Canada',
  },
  KWF: {
    label: 'Waterfall Sea Plane Base (KWF)',
    city: 'Waterfall',
    country: 'United States',
  },
  JOT: {
    label: 'Joliet Mncpl (JOT)',
    city: 'Joliet',
    country: 'United States',
  },
  HAQ: {
    label: 'Hanimaadhoo Airport (HAQ)',
    city: 'Hanimaadhoo',
    country: 'Republic of Maldives',
  },
  PTU: {
    label: 'Platinum Airport (PTU)',
    city: 'Platinum',
    country: 'United States',
  },
  SKP: {
    label: 'Skopje (SKP)',
    city: 'Skopje',
    country: 'Macedonia',
  },
  KNX: {
    label: 'Kununurra (KNX)',
    city: 'Kununurra',
    country: 'Australia',
  },
  POX: {
    label: 'Paris Cergy Pontoise (POX)',
    city: 'Pontoise',
    country: 'France',
  },
  AKB: {
    label: 'Atka Airport (AKB)',
    city: 'Atka',
    country: 'United States',
  },
  TRE: {
    label: 'Tiree Airport (TRE)',
    city: 'Tiree',
    country: 'Inner Hebrides',
  },
  TMP: {
    label: 'Tampere-Pirkkala (TMP)',
    city: 'Tampere',
    country: 'Finland',
  },
  FKB: {
    label: 'Soellingen (FKB)',
    city: 'Karlsruhe',
    country: 'Germany',
  },
  RVK: {
    label: 'Roervik (RVK)',
    city: 'Roervik',
    country: 'Norway',
  },
  BDJ: {
    label: 'Sjamsudin Noor Airport (BDJ)',
    city: 'Banjarmasin',
    country: 'Indonesia',
  },
  VTZ: {
    label: 'Vishakhapatnam (VTZ)',
    city: 'Vishakhapatnam',
    country: 'India',
  },
  LIK: {
    label: 'Likiep Airport (LIK)',
    city: 'Likiep',
    country: 'Marshall Islands',
  },
  YBE: {
    label: 'Uranium City (YBE)',
    city: 'Uranium City',
    country: 'Canada',
  },
  SXR: {
    label: 'Srinagar (SXR)',
    city: 'Srinagar',
    country: 'India',
  },
  MBS: {
    label: 'Tri City Airport (MBS)',
    city: 'Saginaw',
    country: 'United States',
  },
  KEP: {
    label: 'Nepalganj Airport (KEP)',
    city: 'Nepalganj',
    country: 'Nepal',
  },
  ORS: {
    label: 'Waterport (ORS)',
    city: 'Orpheus Island',
    country: 'Australia',
  },
  YWP: {
    label: 'Webequie Airport (YWP)',
    city: 'Webequie',
    country: 'Canada',
  },
  JZH: {
    label: 'Jiu Zhai Huang Long Airport (JZH)',
    city: 'Song Pan',
    country: 'China',
  },
  HGH: {
    label: 'Hangzhou (HGH)',
    city: 'Hangzhou',
    country: 'China',
  },
  FHU: {
    label: 'Fort Huachuca/Sierra Vista Municipal Airport (FHU)',
    city: 'Fort Huachuca/Sierra Vista',
    country: 'United States',
  },
  RAJ: {
    label: 'Rajkot (RAJ)',
    city: 'Rajkot',
    country: 'India',
  },
  CND: {
    label: 'Kogalniceanu (CND)',
    city: 'Constanta',
    country: 'Romania',
  },
  SCC: {
    label: 'Deadhorse Airport (SCC)',
    city: 'Prudhoe Bay',
    country: 'United States',
  },
  OGX: {
    label: 'Ain Beida Airport (OGX)',
    city: 'Ouargla',
    country: 'Algeria',
  },
  HUF: {
    label: 'Hulman Fld (HUF)',
    city: 'Terre Haute',
    country: 'United States',
  },
  MAR: {
    label: 'La Chinita Airport (MAR)',
    city: 'Maracaibo',
    country: 'Venezuela',
  },
  PTY: {
    label: 'Tocumen International Airport (PTY)',
    city: 'Panama City',
    country: 'Panama',
  },
  POS: {
    label: 'Piarco Airport (POS)',
    city: 'Port of Spain',
    country: 'Trinidad and Tobago',
  },
  PNZ: {
    label: 'Petrolina Internacional Airport (PNZ)',
    city: 'Petrolina',
    country: 'Brazil',
  },
  HBE: {
    label: 'Borg El Arab (HBE)',
    city: 'Alexandria',
    country: 'United States',
  },
  LFM: {
    label: 'Lamerd Airport (LFM)',
    city: 'Lamerd',
    country: 'Iran',
  },
  RSU: {
    label: 'Yeosu Airport (RSU)',
    city: 'Yeosu',
    country: 'South Korea',
  },
  JHW: {
    label: 'Chautauqua Cty (JHW)',
    city: 'Jamestown',
    country: 'NY',
  },
  ERN: {
    label: 'Eirunepe Airport (ERN)',
    city: 'Eirunepe',
    country: 'Brazil',
  },
  TJA: {
    label: 'Tarija/Oriel Lea (TJA)',
    city: 'Tarija',
    country: 'Bolivia',
  },
  RVV: {
    label: 'Raivavae Airport (RVV)',
    city: 'Rairua',
    country: 'French Polynesia and Tahiti',
  },
  GHB: {
    label: "Governor's Harbour (GHB)",
    city: "Governor's Harbour",
    country: 'Bahamas',
  },
  OYS: {
    label: 'Yosemite Natl Park (OYS)',
    city: 'Yosemite Natl Pk',
    country: 'United States',
  },
  XMH: {
    label: 'Manihi (XMH)',
    city: 'Manihi',
    country: 'French Polynesia and Tahiti',
  },
  KPS: {
    label: 'Kempsey (KPS)',
    city: 'Kempsey',
    country: 'Australia',
  },
  EOS: {
    label: 'Neosho Mncpl (EOS)',
    city: 'Neosho',
    country: 'United States',
  },
  TEP: {
    label: 'Teptep Airport (TEP)',
    city: 'Teptep',
    country: 'Papua New Guinea',
  },
  FLO: {
    label: 'Florence Airport (FLO)',
    city: 'Florence',
    country: 'United States',
  },
  SAC: {
    label: 'All Airports (SAC)',
    city: 'Sacramento',
    country: 'United States',
  },
  SYR: {
    label: 'Syracuse (SYR)',
    city: 'Syracuse',
    country: 'United States',
  },
  TIP: {
    label: 'Tripoli Intl (TIP)',
    city: 'Tripoli',
    country: 'Libya',
  },
  GLT: {
    label: 'Gladstone (GLT)',
    city: 'Gladstone',
    country: 'Australia',
  },
  BIR: {
    label: 'Biratnagar Airport (BIR)',
    city: 'Biratnagar',
    country: 'Nepal',
  },
  AZN: {
    label: 'Andizhan Airport (AZN)',
    city: 'Andizhan',
    country: 'Uzbekistan',
  },
  TAM: {
    label: 'Tampico (TAM)',
    city: 'Tampico',
    country: 'Mexico',
  },
  TFM: {
    label: 'Telefomin Airport (TFM)',
    city: 'Telefomin',
    country: 'Papua New Guinea',
  },
  SMN: {
    label: 'Salmon (SMN)',
    city: 'Salmon',
    country: 'United States',
  },
  VOZ: {
    label: 'Voronezh Airport (VOZ)',
    city: 'Voronezh',
    country: 'Russia',
  },
  DNZ: {
    label: 'Cardak (DNZ)',
    city: 'Denizli',
    country: 'Turkey',
  },
  PMV: {
    label: 'Gen Santiago Marino (PMV)',
    city: 'Porlamar',
    country: 'Venezuela',
  },
  BYN: {
    label: 'Bayankhongor Airport (BYN)',
    city: 'Bayankhongor',
    country: 'Mongolia',
  },
  OGA: {
    label: 'Searle Fld (OGA)',
    city: 'Ogallala',
    country: 'United States',
  },
  QPW: {
    label: 'Heliport (QPW)',
    city: 'Kangaatsiaq',
    country: 'Greenland',
  },
  TAH: {
    label: 'Tanna (TAH)',
    city: 'Tanna',
    country: 'Vanuatu',
  },
  TOL: {
    label: 'Express Airport (TOL)',
    city: 'Toledo',
    country: 'United States',
  },
  WAA: {
    label: 'Wales Airport (WAA)',
    city: 'Wales',
    country: 'United States',
  },
  MHV: {
    label: 'Mojave (MHV)',
    city: 'Mojave',
    country: 'United States',
  },
  ZAL: {
    label: 'Pichoy (ZAL)',
    city: 'Valdivia',
    country: 'Chile and Easter Island',
  },
  BGI: {
    label: 'Grantley Adams International Airport (BGI)',
    city: 'Bridgetown',
    country: 'Barbados',
  },
  BBN: {
    label: 'Bario Airport (BBN)',
    city: 'Bario',
    country: 'Malaysia',
  },
  LBL: {
    label: 'Liberal Municipal Airport (LBL)',
    city: 'Liberal',
    country: 'United States',
  },
  POF: {
    label: 'Earl Flds Memorial (POF)',
    city: 'Poplar Bluff',
    country: 'United States',
  },
  TMR: {
    label: 'Aguemar Airport (TMR)',
    city: 'Tamenghest',
    country: 'Algeria',
  },
  ESM: {
    label: 'Esmeraldas Airport (ESM)',
    city: 'Esmeraldas',
    country: 'Ecuador',
  },
  LEW: {
    label: 'Auburn (LEW)',
    city: 'Lewiston',
    country: 'United States',
  },
  TBP: {
    label: 'Tumbes Airport (TBP)',
    city: 'Tumbes',
    country: 'Peru',
  },
  BSH: {
    label: 'Brighton Airport (BSH)',
    city: 'Brighton',
    country: 'United Kingdom',
  },
  LIM: {
    label: 'J Chavez International Airport (LIM)',
    city: 'Lima',
    country: 'Peru',
  },
  YWG: {
    label: 'Winnipeg (YWG)',
    city: 'Winnipeg',
    country: 'Canada',
  },
  VPZ: {
    label: 'Porter Cty (VPZ)',
    city: 'Valparaiso',
    country: 'United States',
  },
  UDR: {
    label: 'Dabok (UDR)',
    city: 'Udaipur',
    country: 'India',
  },
  MOZ: {
    label: 'Temae (MOZ)',
    city: 'Moorea',
    country: 'French Polynesia and Tahiti',
  },
  ISO: {
    label: 'Kinston (ISO)',
    city: 'Kinston',
    country: 'United States',
  },
  PVR: {
    label: 'Ordaz (PVR)',
    city: 'Puerto Vallarta',
    country: 'Mexico',
  },
  TCB: {
    label: 'Treasure Cay (TCB)',
    city: 'Treasure Cay',
    country: 'Bahamas',
  },
  KDV: {
    label: 'Kandavu Airport (KDV)',
    city: 'Kandavu',
    country: 'Fiji',
  },
  UGA: {
    label: 'Bulgan Airport (UGA)',
    city: 'Bulgan',
    country: 'Mongolia',
  },
  CXB: {
    label: 'Coxs Bazar Airport (CXB)',
    city: 'Coxs Bazar',
    country: 'Bangladesh',
  },
  SPC: {
    label: 'La Palma (SPC)',
    city: 'Santa Cruz de la Palma',
    country: 'Spain',
  },
  HSC: {
    label: 'Shaoguan (HSC)',
    city: 'Shaoguan',
    country: 'China',
  },
  XIC: {
    label: 'Xichang (XIC)',
    city: 'Xichang',
    country: 'China',
  },
  LPM: {
    label: 'Lamap Airport (LPM)',
    city: 'Lamap',
    country: 'Vanuatu',
  },
  SMO: {
    label: 'Santa Monica Mncpl (SMO)',
    city: 'Santa Monica',
    country: 'United States',
  },
  LCR: {
    label: 'La Chorrera Airport (LCR)',
    city: 'La Chorrera',
    country: 'Colombia',
  },
  KMS: {
    label: 'Kumasi Airport (KMS)',
    city: 'Kumasi',
    country: 'Ghana',
  },
  EBA: {
    label: 'Marina Di Campo (EBA)',
    city: 'Elba Island',
    country: 'Italy',
  },
  YHU: {
    label: 'St Hubert (YHU)',
    city: 'Montreal',
    country: 'Canada',
  },
  LHA: {
    label: 'Lahr (LHA)',
    city: 'Lahr',
    country: 'Germany',
  },
  ABC: {
    label: 'Los Llanos (ABC)',
    city: 'Albacete',
    country: 'Spain',
  },
  SDK: {
    label: 'Sandakan (SDK)',
    city: 'Sandakan',
    country: 'Malaysia',
  },
  DFW: {
    label: 'Ft Worth (DFW)',
    city: 'Dallas',
    country: 'United States',
  },
  XGR: {
    label: 'Kangiqsualujjuaq Airport (XGR)',
    city: 'Kangiqsualujjuaq',
    country: 'Canada',
  },
  LPL: {
    label: 'John Lennon (LPL)',
    city: 'Liverpool',
    country: 'United Kingdom',
  },
  GRR: {
    label: 'Grand Rapids (GRR)',
    city: 'Grand Rapids',
    country: 'United States',
  },
  SFM: {
    label: 'Sanford (SFM)',
    city: 'Sanford',
    country: 'United States',
  },
  NCU: {
    label: 'Nukus Airport (NCU)',
    city: 'Nukus',
    country: 'Uzbekistan',
  },
  RAS: {
    label: 'Rasht Airport (RAS)',
    city: 'Rasht',
    country: 'Iran',
  },
  MKO: {
    label: 'Davis Field (MKO)',
    city: 'Muskogee',
    country: 'United States',
  },
  RVE: {
    label: 'Saravena Airport (RVE)',
    city: 'Saravena',
    country: 'Colombia',
  },
  RJL: {
    label: 'Logrono (RJL)',
    city: 'Logrono',
    country: 'Spain',
  },
  YIK: {
    label: 'Ivujivik Airport (YIK)',
    city: 'Ivujivik',
    country: 'Canada',
  },
  LPS: {
    label: 'Lopez Island Mncpl (LPS)',
    city: 'Lopez Island',
    country: 'United States',
  },
  QFI: {
    label: 'Heliport (QFI)',
    city: 'Iginniarfik',
    country: 'Greenland',
  },
  EGV: {
    label: 'Eagle River Union (EGV)',
    city: 'Eagle River',
    country: 'United States',
  },
  MAO: {
    label: 'Eduardo Gomes Intl (MAO)',
    city: 'Manaus',
    country: 'Brazil',
  },
  RNI: {
    label: 'Corn Island Airport (RNI)',
    city: 'Corn Island',
    country: 'Nicaragua',
  },
  EBJ: {
    label: 'Esbjerg (EBJ)',
    city: 'Esbjerg',
    country: 'Denmark',
  },
  URC: {
    label: 'Urumqi (URC)',
    city: 'Urumqi',
    country: 'China',
  },
  EMA: {
    label: 'East Midlands (EMA)',
    city: 'Nottingham',
    country: 'NGM',
  },
  BTT: {
    label: 'Bettles Airport (BTT)',
    city: 'Bettles',
    country: 'United States',
  },
  QGR: {
    label: 'Off-Line Point (QGR)',
    city: 'Kangerluk',
    country: 'Greenland',
  },
  BJS: {
    label: 'All Airports (BJS)',
    city: 'Beijing',
    country: 'China',
  },
  POL: {
    label: 'Pemba Airport (POL)',
    city: 'Pemba',
    country: 'Mozambique',
  },
  SYK: {
    label: 'Stykkisholmur (SYK)',
    city: 'Stykkisholmur',
    country: 'Iceland',
  },
  EAS: {
    label: 'San Sebastian (EAS)',
    city: 'San Sebastian',
    country: 'Spain',
  },
  BKI: {
    label: 'Kota Kinabalu (BKI)',
    city: 'Kota Kinabalu',
    country: 'Malaysia',
  },
  SOY: {
    label: 'Stronsay Airport (SOY)',
    city: 'Stronsay',
    country: 'United Kingdom',
  },
  MRY: {
    label: 'Monterey Peninsula Airport (MRY)',
    city: 'Monterey',
    country: 'United States',
  },
  LEU: {
    label: 'Aeroport De La Seu (LEU)',
    city: 'Seo de Urgel',
    country: 'Spain',
  },
  FRH: {
    label: 'French Lick Mncpl (FRH)',
    city: 'French Lick',
    country: 'United States',
  },
  LBI: {
    label: 'Le Sequestre (LBI)',
    city: 'Albi',
    country: 'France',
  },
  ODS: {
    label: 'Central (ODS)',
    city: 'Odessa',
    country: 'Ukraine',
  },
  JAV: {
    label: 'Ilulissat (JAV)',
    city: 'Ilulissat',
    country: 'Greenland',
  },
  BMU: {
    label: 'Bima Airport (BMU)',
    city: 'Bima',
    country: 'Indonesia',
  },
  ROM: {
    label: 'All Airports (ROM)',
    city: 'Rome',
    country: 'Italy',
  },
  RCE: {
    label: 'Roche Harbor Mncpl (RCE)',
    city: 'Roche Harbor',
    country: 'United States',
  },
  HEA: {
    label: 'Herat Airport (HEA)',
    city: 'Herat',
    country: 'Afghanistan',
  },
  PLP: {
    label: 'La Palma Airport (PLP)',
    city: 'La Palma',
    country: 'Panama',
  },
  OTP: {
    label: 'Otopeni Intl (OTP)',
    city: 'Bucharest',
    country: 'Romania',
  },
  KVR: {
    label: 'Kavalerovo Airport (KVR)',
    city: 'Kavalerovo',
    country: 'Russia',
  },
  GBT: {
    label: 'Gorgon Airport (GBT)',
    city: 'Gorgon',
    country: 'Iran',
  },
  YCU: {
    label: 'Yun Cheng Airport (YCU)',
    city: 'Yun Cheng',
    country: 'China',
  },
  MYR: {
    label: 'Myrtle Beach Air Force Base (MYR)',
    city: 'Myrtle Beach',
    country: 'United States',
  },
  WTL: {
    label: 'Tuntutuliak Airport (WTL)',
    city: 'Tuntutuliak',
    country: 'United States',
  },
  PGF: {
    label: 'Rivesaltes (PGF)',
    city: 'Perpignan',
    country: 'France',
  },
  GBD: {
    label: 'Great Bend Municipal Airport (GBD)',
    city: 'Great Bend',
    country: 'United States',
  },
  GEG: {
    label: 'Spokane (GEG)',
    city: 'Spokane',
    country: 'United States',
  },
  RAM: {
    label: 'Ramingining Airport (RAM)',
    city: 'Ramingining',
    country: 'Australia',
  },
  CFS: {
    label: 'Coffs Harbour (CFS)',
    city: 'Coffs Harbour',
    country: 'Australia',
  },
  JOI: {
    label: 'Cubatao (JOI)',
    city: 'Joinville',
    country: 'Brazil',
  },
  STL: {
    label: 'St Louis (STL)',
    city: 'St Louis',
    country: 'United States',
  },
  IGR: {
    label: 'Iguazu Intl (IGR)',
    city: 'Iguazu',
    country: 'Argentina',
  },
  YZV: {
    label: 'Sept-Iles Airport (YZV)',
    city: 'Sept-Iles',
    country: 'Canada',
  },
  MCZ: {
    label: 'Palmares (MCZ)',
    city: 'Maceio',
    country: 'Brazil',
  },
  KYA: {
    label: 'Konya (KYA)',
    city: 'Konya',
    country: 'Turkey',
  },
  VSE: {
    label: 'Viseu (VSE)',
    city: 'Viseu',
    country: 'Portugal',
  },
  VSG: {
    label: 'Lugansk Airport (VSG)',
    city: 'Lugansk',
    country: 'Ukraine',
  },
  IMF: {
    label: 'Imphal Municipal Airport (IMF)',
    city: 'Imphal',
    country: 'India',
  },
  GYD: {
    label: 'Heydar Aliyev Intl (GYD)',
    city: 'Baku',
    country: 'Azerbaijan',
  },
  ONT: {
    label: 'Ontario (ONT)',
    city: 'Ontario',
    country: 'United States',
  },
  BHI: {
    label: 'Comandante Airport (BHI)',
    city: 'Bahia Blanca',
    country: 'Argentina',
  },
  BQK: {
    label: 'Glynco Jetport (BQK)',
    city: 'Brunswick',
    country: 'United States',
  },
  YPQ: {
    label: 'Peterborough Mncpl (YPQ)',
    city: 'Peterborough',
    country: 'Canada',
  },
  ZZV: {
    label: 'Zanesville Mncpl (ZZV)',
    city: 'Zanesville',
    country: 'United States',
  },
  CNM: {
    label: 'Carlsbad Cavern City (CNM)',
    city: 'Carlsbad',
    country: 'NM',
  },
  BEL: {
    label: 'Val de Cans (BEL)',
    city: 'Belem',
    country: 'Brazil',
  },
  NUU: {
    label: 'Nakuru (NUU)',
    city: 'Nakuru',
    country: 'Kenya',
  },
  PRH: {
    label: 'Phrae Airport (PRH)',
    city: 'Phrae',
    country: 'Thailand',
  },
  CCK: {
    label: 'Cocos Islands Airport (CCK)',
    city: 'Cocos (Keeling) Islands',
    country: 'Australia',
  },
  ZUM: {
    label: 'Churchill Falls Airport (ZUM)',
    city: 'Churchill Falls',
    country: 'Canada',
  },
  ALS: {
    label: 'San Luis Valley Regional Airport (ALS)',
    city: 'Alamosa',
    country: 'United States',
  },
  GTW: {
    label: 'Holesov (GTW)',
    city: 'Zlin',
    country: 'Czech Republic',
  },
  AAR: {
    label: 'Tirstrup (AAR)',
    city: 'Aarhus',
    country: 'Denmark',
  },
  KHY: {
    label: 'Khoy Airport (KHY)',
    city: 'Khoy',
    country: 'Iran',
  },
  YHD: {
    label: 'Dryden Mncpl (YHD)',
    city: 'Dryden',
    country: 'Canada',
  },
  JIM: {
    label: 'Jimma Airport (JIM)',
    city: 'Jimma',
    country: 'Ethiopia',
  },
  MGW: {
    label: 'Morgantown Mncpl (MGW)',
    city: 'Morgantown',
    country: 'United States',
  },
  PSP: {
    label: 'Palm Springs (PSP)',
    city: 'Palm Springs',
    country: 'United States',
  },
  RMA: {
    label: 'Roma (RMA)',
    city: 'Roma',
    country: 'Australia',
  },
  SMX: {
    label: 'Santa Maria Public  Airport (SMX)',
    city: 'Santa Maria',
    country: 'United States',
  },
  MIW: {
    label: 'Marshalltown Mncpl (MIW)',
    city: 'Marshalltown',
    country: 'United States',
  },
  DTW: {
    label: 'Detroit Metro (DTW)',
    city: 'Detroit',
    country: 'United States',
  },
  LEV: {
    label: 'Levuka Airfield (LEV)',
    city: 'Bureta',
    country: 'Fiji',
  },
  DUE: {
    label: 'Dundo Airport (DUE)',
    city: 'Dundo',
    country: 'Angola',
  },
  HRG: {
    label: 'Hurghada (HRG)',
    city: 'Hurghada',
    country: 'Egypt',
  },
  TYF: {
    label: 'Torsby Airport (TYF)',
    city: 'Torsby',
    country: 'Sweden',
  },
  ADJ: {
    label: 'Amman Civil-Marka (ADJ)',
    city: 'Amman',
    country: 'Jordan',
  },
  SCN: {
    label: 'Ensheim (SCN)',
    city: 'Saarbruecken',
    country: 'Germany',
  },
  SMF: {
    label: 'Sacramento (SMF)',
    city: 'Sacramento',
    country: 'United States',
  },
  SLL: {
    label: 'Salalah (SLL)',
    city: 'Salalah',
    country: 'Oman',
  },
  YLJ: {
    label: 'Meadow Lake Airport (YLJ)',
    city: 'Meadow Lake',
    country: 'Canada',
  },
  SCO: {
    label: 'Aktau (SCO)',
    city: 'Aktau',
    country: 'Kazakhstan',
  },
  NKN: {
    label: 'Nankina Airport (NKN)',
    city: 'Nankina',
    country: 'Papua New Guinea',
  },
  JEG: {
    label: 'Aasiaat (JEG)',
    city: 'Aasiaat',
    country: 'Greenland',
  },
  YXT: {
    label: 'Terrace Airport (YXT)',
    city: 'Terrace',
    country: 'Canada',
  },
  ATW: {
    label: 'Outagamie County Airport (ATW)',
    city: 'Appleton',
    country: 'United States',
  },
  MFR: {
    label: 'Jackson County Airport (MFR)',
    city: 'Medford',
    country: 'United States',
  },
  AOI: {
    label: 'Falconara (AOI)',
    city: 'Ancona',
    country: 'Italy',
  },
  PLZ: {
    label: 'Port Elizabeth (PLZ)',
    city: 'Port Elizabeth',
    country: 'South Africa',
  },
  SHJ: {
    label: 'Sharjah (SHJ)',
    city: 'Sharjah',
    country: 'United Arab Emirates',
  },
  MWP: {
    label: 'Mountain Airport (MWP)',
    city: 'Mountain',
    country: 'Nepal',
  },
  GBR: {
    label: 'Great Barrington (GBR)',
    city: 'Great Barrington',
    country: 'United States',
  },
  SBM: {
    label: 'Sheboygan County Mem (SBM)',
    city: 'Sheboygan',
    country: 'United States',
  },
  HFA: {
    label: 'Haifa (HFA)',
    city: 'Haifa',
    country: 'Israel',
  },
  LWS: {
    label: 'Nez Perce County Regional Airport (LWS)',
    city: 'Lewiston',
    country: 'ID',
  },
  ILM: {
    label: 'New Hanover County Airport (ILM)',
    city: 'Wilmington',
    country: 'United States',
  },
  KPN: {
    label: 'Kipnuk Sea Plane Base (KPN)',
    city: 'Kipnuk',
    country: 'United States',
  },
  MNI: {
    label: "Gerald's (MNI)",
    city: 'Olveston',
    country: 'Montserrat',
  },
  AJU: {
    label: 'Santa Maria (AJU)',
    city: 'Aracaju',
    country: 'Brazil',
  },
  ENU: {
    label: 'Enugu (ENU)',
    city: 'Enugu',
    country: 'Nigeria',
  },
  BDS: {
    label: 'Papola Casale (BDS)',
    city: 'Brindisi',
    country: 'Italy',
  },
  LIR: {
    label: 'Daniel Oduber Quiros (LIR)',
    city: 'Liberia',
    country: 'Costa Rica',
  },
  AYT: {
    label: 'Antalya (AYT)',
    city: 'Antalya',
    country: 'Turkey',
  },
  THR: {
    label: 'Mehrabad (THR)',
    city: 'Tehran',
    country: 'Iran',
  },
  TMC: {
    label: 'Tambolaka Airport (TMC)',
    city: 'Tambolaka',
    country: 'Indonesia',
  },
  GCN: {
    label: 'Grand Canyon (GCN)',
    city: 'Grand Canyon',
    country: 'United States',
  },
  SLH: {
    label: 'Sola Airport (SLH)',
    city: 'Sola',
    country: 'Vanuatu',
  },
  ADT: {
    label: 'Ada Mncpl (ADT)',
    city: 'Ada',
    country: 'United States',
  },
  SYO: {
    label: 'Shonai Airport (SYO)',
    city: 'Shonai',
    country: 'Japan',
  },
  MJV: {
    label: 'San Javier (MJV)',
    city: 'Murcia',
    country: 'Spain',
  },
  RPR: {
    label: 'Raipur Airport (RPR)',
    city: 'Raipur',
    country: 'India',
  },
  TRK: {
    label: 'Tarakan Airport (TRK)',
    city: 'Tarakan',
    country: 'Indonesia',
  },
  NRD: {
    label: 'Norderney (NRD)',
    city: 'Norderney',
    country: 'Germany',
  },
  NRA: {
    label: 'Narrandera (NRA)',
    city: 'Narrandera',
    country: 'Australia',
  },
  HEL: {
    label: 'Helsinki-Vantaa Airport (HEL)',
    city: 'Helsinki',
    country: 'Finland',
  },
  FEG: {
    label: 'Fergana Airport (FEG)',
    city: 'Fergana',
    country: 'Uzbekistan',
  },
  HIJ: {
    label: 'Hiroshima (HIJ)',
    city: 'Hiroshima',
    country: 'Japan',
  },
  QOW: {
    label: 'Off-Line Point (QOW)',
    city: 'Owerri',
    country: 'Nigeria',
  },
  YNA: {
    label: 'Natashquan Airport (YNA)',
    city: 'Natashquan',
    country: 'Canada',
  },
  MBX: {
    label: 'Maribor (MBX)',
    city: 'Maribor',
    country: 'Slovenia',
  },
  FAV: {
    label: 'Fakarava Airport (FAV)',
    city: 'Fakarava',
    country: 'French Polynesia and Tahiti',
  },
  KSZ: {
    label: 'Kotlas Airport (KSZ)',
    city: 'Kotlas',
    country: 'Russia',
  },
  SZA: {
    label: 'Soyo Airport (SZA)',
    city: 'Soyo',
    country: 'Angola',
  },
  KGA: {
    label: 'Kananga Airport (KGA)',
    city: 'Kananga',
    country: 'Democratic Republic of Congo',
  },
  EPS: {
    label: 'El Portillo (EPS)',
    city: 'Samana',
    country: 'Dominican Republic',
  },
  VAA: {
    label: 'Vaasa (VAA)',
    city: 'Vaasa',
    country: 'Finland',
  },
  YHI: {
    label: 'Holman Airport (YHI)',
    city: 'Holman',
    country: 'Canada',
  },
  GAD: {
    label: 'Gadsden Mncpl (GAD)',
    city: 'Gadsden',
    country: 'United States',
  },
  YQT: {
    label: 'Thunder Bay Airport (YQT)',
    city: 'Thunder Bay',
    country: 'Canada',
  },
  GNI: {
    label: 'Green Island Airport (GNI)',
    city: 'Green Island',
    country: 'Taiwan',
  },
  HFN: {
    label: 'Hornafjordur (HFN)',
    city: 'Hofn',
    country: 'Iceland',
  },
  DOM: {
    label: 'Melville Hall Airport (DOM)',
    city: 'Roseau',
    country: 'Dominica',
  },
  HYF: {
    label: 'Hayfields Airport (HYF)',
    city: 'Hayfields',
    country: 'Papua New Guinea',
  },
  UTP: {
    label: 'Utapao Airport (UTP)',
    city: 'Utapao',
    country: 'Thailand',
  },
  GWL: {
    label: 'Gwalior (GWL)',
    city: 'Gwalior',
    country: 'India',
  },
  YYG: {
    label: 'Charlottetown Airport (YYG)',
    city: 'Charlottetown',
    country: 'Canada',
  },
  AVA: {
    label: 'An Shun/Huang Guo Shu Airport (AVA)',
    city: 'An Shun',
    country: 'China',
  },
  OTZ: {
    label: 'Kotzebue Airport (OTZ)',
    city: 'Kotzebue',
    country: 'United States',
  },
  MNG: {
    label: 'Maningrida Airport (MNG)',
    city: 'Maningrida',
    country: 'Australia',
  },
  RUT: {
    label: 'Rutland State (RUT)',
    city: 'Rutland',
    country: 'United States',
  },
  SJW: {
    label: 'Shijiazhuang (SJW)',
    city: 'Shijiazhuang',
    country: 'China',
  },
  BEF: {
    label: 'Bluefields Airport (BEF)',
    city: 'Bluefields',
    country: 'Nicaragua',
  },
  ILL: {
    label: 'Willmar (ILL)',
    city: 'Willmar',
    country: 'United States',
  },
  KCM: {
    label: 'Kahramanmaras Airport (KCM)',
    city: 'Kahramanmaras',
    country: 'Turkey',
  },
  FKS: {
    label: 'Fukushima (FKS)',
    city: 'Fukushima',
    country: 'Japan',
  },
  MJZ: {
    label: 'Mirnyj Airport (MJZ)',
    city: 'Mirnyj',
    country: 'Russia',
  },
  IXS: {
    label: 'Kumbhirgram Airport (IXS)',
    city: 'Silchar',
    country: 'India',
  },
  BOM: {
    label: 'Chhatrapati Shivaji (BOM)',
    city: 'Mumbai',
    country: 'India',
  },
  ADD: {
    label: 'Bole (ADD)',
    city: 'Addis Ababa',
    country: 'Ethiopia',
  },
  ZGU: {
    label: 'Gaua Airport (ZGU)',
    city: 'Gaua',
    country: 'Vanuatu',
  },
  CYP: {
    label: 'Calbayog Airport (CYP)',
    city: 'Calbayog',
    country: 'Philippines',
  },
  HVD: {
    label: 'Khovd Airport (HVD)',
    city: 'Khovd',
    country: 'Mongolia',
  },
  SAQ: {
    label: 'San Andros Airport (SAQ)',
    city: 'San Andros',
    country: 'Bahamas',
  },
  CBP: {
    label: 'Coimbra (CBP)',
    city: 'Coimbra',
    country: 'Portugal',
  },
  QSF: {
    label: 'Setif Airport (QSF)',
    city: 'Setif',
    country: 'Algeria',
  },
  LCH: {
    label: 'Lake Charles Municipal Airport (LCH)',
    city: 'Lake Charles',
    country: 'United States',
  },
  IBA: {
    label: 'Ibadan (IBA)',
    city: 'Ibadan',
    country: 'Nigeria',
  },
  PNA: {
    label: 'Pamplona-Noain (PNA)',
    city: 'Pamplona',
    country: 'Spain',
  },
  AXD: {
    label: 'Demokritos (AXD)',
    city: 'Alexandroupolis',
    country: 'Greece',
  },
  BJB: {
    label: 'Bojnord Airport (BJB)',
    city: 'Bojnord',
    country: 'Iran',
  },
  LXG: {
    label: 'Luang Namtha Airport (LXG)',
    city: 'Luang Namtha',
    country: 'Laos',
  },
  CUU: {
    label: 'Chihuahua (CUU)',
    city: 'Chihuahua',
    country: 'Mexico',
  },
  SGY: {
    label: 'Skagway (SGY)',
    city: 'Skagway',
    country: 'United States',
  },
  PSG: {
    label: 'Petersburg Municipal Airport (PSG)',
    city: 'Petersburg',
    country: 'AK',
  },
  XBE: {
    label: 'Bearskin Lake Airport (XBE)',
    city: 'Bearskin Lake',
    country: 'Canada',
  },
  ZLO: {
    label: 'Manzanillo Airport (ZLO)',
    city: 'Manzanillo',
    country: 'Mexico',
  },
  CTC: {
    label: 'Choya (CTC)',
    city: 'Catamarca',
    country: 'Argentina',
  },
  VPN: {
    label: 'Vopnafjordur (VPN)',
    city: 'Vopnafjordur',
    country: 'Iceland',
  },
  LYR: {
    label: 'Svalbard (LYR)',
    city: 'Longyearbyen',
    country: 'Norway',
  },
  ELE: {
    label: 'El Real Airport (ELE)',
    city: 'El Real',
    country: 'Panama',
  },
  UMD: {
    label: 'Uummannaq (UMD)',
    city: 'Uummannaq',
    country: 'Greenland',
  },
  BTU: {
    label: 'Bintulu (BTU)',
    city: 'Bintulu',
    country: 'Malaysia',
  },
  LYC: {
    label: 'Lycksele (LYC)',
    city: 'Lycksele',
    country: 'Sweden',
  },
  KUY: {
    label: 'Kamusi Airport (KUY)',
    city: 'Kamusi',
    country: 'Papua New Guinea',
  },
  NOV: {
    label: 'Huambo Airport (NOV)',
    city: 'Huambo',
    country: 'Angola',
  },
  YPA: {
    label: 'Prince Albert (YPA)',
    city: 'Prince Albert',
    country: 'Canada',
  },
  PDG: {
    label: 'Tabing (PDG)',
    city: 'Padang',
    country: 'Indonesia',
  },
  FNL: {
    label: 'Fort Collins/Loveland (FNL)',
    city: 'Ft Collins',
    country: 'United States',
  },
  BHV: {
    label: 'Bahawalpur Airport (BHV)',
    city: 'Bahawalpur',
    country: 'Pakistan',
  },
  OLB: {
    label: 'Costa Smeralda (OLB)',
    city: 'Olbia',
    country: 'Italy',
  },
  TKA: {
    label: 'Talkeetna Mncpl (TKA)',
    city: 'Talkeetna',
    country: 'United States',
  },
  BSB: {
    label: 'Brasilia Intl (BSB)',
    city: 'Brasilia',
    country: 'Brazil',
  },
  VRA: {
    label: 'Juan Gualberto Gomez (VRA)',
    city: 'Varadero',
    country: 'Cuba',
  },
  EEN: {
    label: 'Dillant-Hopkins (EEN)',
    city: 'Keene',
    country: 'United States',
  },
  LCA: {
    label: 'Larnaca Intl (LCA)',
    city: 'Larnaca',
    country: 'Cyprus',
  },
  RRO: {
    label: 'Sorrento (RRO)',
    city: 'Sorrento',
    country: 'Italy',
  },
  MMK: {
    label: 'Murmansk (MMK)',
    city: 'Murmansk',
    country: 'Russia',
  },
  BZG: {
    label: 'Bydgoszcz (BZG)',
    city: 'Bydgoszcz',
    country: 'Poland',
  },
  LPY: {
    label: 'Loudes (LPY)',
    city: 'Le Puy-en-Velay',
    country: 'France',
  },
  SNW: {
    label: 'Thandwe (SNW)',
    city: 'Thandwe',
    country: 'Myanmar (Burma)',
  },
  IXC: {
    label: 'Chandigarh (IXC)',
    city: 'Chandigarh',
    country: 'India',
  },
  BQL: {
    label: 'Boulia Airport (BQL)',
    city: 'Boulia',
    country: 'Australia',
  },
  PZO: {
    label: 'Puerto Ordaz (PZO)',
    city: 'Puerto Ordaz',
    country: 'Venezuela',
  },
  TMW: {
    label: 'Tamworth (TMW)',
    city: 'Tamworth',
    country: 'Australia',
  },
  FOR: {
    label: 'Pinto Martins (FOR)',
    city: 'Fortaleza',
    country: 'Brazil',
  },
  PSA: {
    label: 'Galileo Galilei (PSA)',
    city: 'Pisa',
    country: 'Italy',
  },
  JSU: {
    label: 'Maniitsoq (JSU)',
    city: 'Maniitsoq',
    country: 'Greenland',
  },
  SLE: {
    label: 'McNary Fld (SLE)',
    city: 'Salem',
    country: 'United States',
  },
  YTR: {
    label: 'Trenton Air Base (YTR)',
    city: 'Trenton',
    country: 'Canada',
  },
  SEL: {
    label: 'All Airports (SEL)',
    city: 'Seoul',
    country: 'South Korea',
  },
  RAO: {
    label: 'Leite Lopes (RAO)',
    city: 'Ribeirao Preto',
    country: 'Brazil',
  },
  CEY: {
    label: 'Murray Calloway Cty (CEY)',
    city: 'Murray',
    country: 'United States',
  },
  TGT: {
    label: 'Tanga (TGT)',
    city: 'Tanga',
    country: 'Tanzania',
  },
  YNZ: {
    label: 'Yancheng Airport (YNZ)',
    city: 'Yancheng',
    country: 'China',
  },
  KMV: {
    label: 'Kalemyo Airport (KMV)',
    city: 'Kalemyo',
    country: 'Myanmar (Burma)',
  },
  KXF: {
    label: 'Koro Airport (KXF)',
    city: 'Koro',
    country: 'Fiji',
  },
  ALC: {
    label: 'Alicante (ALC)',
    city: 'Alicante',
    country: 'Spain',
  },
  REC: {
    label: 'Guararapes Intl (REC)',
    city: 'Recife',
    country: 'Brazil',
  },
  AZS: {
    label: 'Samana Intl (AZS)',
    city: 'Samana',
    country: 'Dominican Republic',
  },
  OMS: {
    label: 'Omsk Airport (OMS)',
    city: 'Omsk',
    country: 'Russia',
  },
  KWO: {
    label: 'Kawito Airport (KWO)',
    city: 'Kawito',
    country: 'Papua New Guinea',
  },
  MED: {
    label: 'Mohammad Abdulaziz (MED)',
    city: 'Madinah',
    country: 'Saudi Arabia',
  },
  JUM: {
    label: 'Jumla Airport (JUM)',
    city: 'Jumla',
    country: 'Nepal',
  },
  LVI: {
    label: 'Livingstone (LVI)',
    city: 'Livingstone',
    country: 'Zambia',
  },
  VDZ: {
    label: 'Valdez (VDZ)',
    city: 'Valdez',
    country: 'United States',
  },
  MID: {
    label: 'Rejon Airport (MID)',
    city: 'Merida',
    country: 'Mexico',
  },
  CER: {
    label: 'Maupertus (CER)',
    city: 'Cherbourg',
    country: 'France',
  },
  MFD: {
    label: 'Lahm Mncpl (MFD)',
    city: 'Mansfield',
    country: 'United States',
  },
  SDX: {
    label: 'Sedona (SDX)',
    city: 'Sedona',
    country: 'United States',
  },
  TRF: {
    label: 'Sandefjord (TRF)',
    city: 'Oslo',
    country: 'Norway',
  },
  MRO: {
    label: 'Masterton (MRO)',
    city: 'Masterton',
    country: 'New Zealand',
  },
  YFS: {
    label: 'Fort Simpson (YFS)',
    city: 'Fort Simpson',
    country: 'Canada',
  },
  MHP: {
    label: 'Minsk International 1 Airport (MHP)',
    city: 'Minsk',
    country: 'Belarus',
  },
  UKK: {
    label: 'Ust-Kamenogorsk (UKK)',
    city: 'Ust-Kamenogorsk',
    country: 'Kazakhstan',
  },
  TPL: {
    label: 'Draughon-Miller (TPL)',
    city: 'Temple',
    country: 'United States',
  },
  YXK: {
    label: 'Rimouski Mncpl (YXK)',
    city: 'Rimouski',
    country: 'Canada',
  },
  TSB: {
    label: 'Tsumeb (TSB)',
    city: 'Tsumeb',
    country: 'Namibia',
  },
  REN: {
    label: 'Orenburg Airport (REN)',
    city: 'Orenburg',
    country: 'Russia',
  },
  HHA: {
    label: 'Changsha Huanghua Airport (HHA)',
    city: 'Huanghua',
    country: 'China',
  },
  ECN: {
    label: 'Ercan Airport (ECN)',
    city: 'Ercan',
    country: 'Cyprus',
  },
  TOH: {
    label: 'Torres Airstrip (TOH)',
    city: 'Torres',
    country: 'Vanuatu',
  },
  MZT: {
    label: 'Gen Rafael Buelna Airport (MZT)',
    city: 'Mazatlan',
    country: 'Mexico',
  },
  RDU: {
    label: 'Raleigh (RDU)',
    city: 'Raleigh',
    country: 'United States',
  },
  BXS: {
    label: 'Borrego Springs (BXS)',
    city: 'Borrego Springs',
    country: 'United States',
  },
  KOY: {
    label: 'Olga Bay Sea Plane Base (KOY)',
    city: 'Olga Bay',
    country: 'United States',
  },
  WAE: {
    label: 'Wadi Ad Dawasir Airport (WAE)',
    city: 'Wadi Ad Dawasir',
    country: 'Saudi Arabia',
  },
  PYE: {
    label: 'Penrhyn Island Airport (PYE)',
    city: 'Penrhyn Island',
    country: 'Cook Islands',
  },
  CNX: {
    label: 'Chiang Mai Intl (CNX)',
    city: 'Chiang Mai',
    country: 'Thailand',
  },
  ILY: {
    label: 'Glenegedale Airport (ILY)',
    city: 'Islay',
    country: 'United Kingdom',
  },
  DAC: {
    label: 'Zia Intl (DAC)',
    city: 'Dhaka',
    country: 'Bangladesh',
  },
  SKD: {
    label: 'Samarkand (SKD)',
    city: 'Samarkand',
    country: 'Uzbekistan',
  },
  YAC: {
    label: 'Cat Lake Airport (YAC)',
    city: 'Cat Lake',
    country: 'Canada',
  },
  SJJ: {
    label: 'Butmir (SJJ)',
    city: 'Sarajevo',
    country: 'Bosnia and Herzegovina',
  },
  GRM: {
    label: 'Grand Marais (GRM)',
    city: 'Grand Marais',
    country: 'United States',
  },
  ZCO: {
    label: 'Temuco (ZCO)',
    city: 'Temuco',
    country: 'Chile and Easter Island',
  },
  VGZ: {
    label: 'Villagarzon Airport (VGZ)',
    city: 'Villagarzon',
    country: 'Colombia',
  },
  LDK: {
    label: 'Hovby (LDK)',
    city: 'Lidkoping',
    country: 'Sweden',
  },
  RSD: {
    label: 'South Eleuthera Airport (RSD)',
    city: 'Rock Sound',
    country: 'Bahamas',
  },
  ISG: {
    label: 'Ishigaki (ISG)',
    city: 'Ishigaki',
    country: 'Japan',
  },
  RIW: {
    label: 'Riverton Regional Airport (RIW)',
    city: 'Riverton',
    country: 'United States',
  },
  SLU: {
    label: 'Vigie Field (SLU)',
    city: 'Castries',
    country: 'St Lucia',
  },
  MEH: {
    label: 'Mehamn Mncpl (MEH)',
    city: 'Mehamn',
    country: 'Norway',
  },
  YKX: {
    label: 'Kirkland Lake (YKX)',
    city: 'Kirkland Lake',
    country: 'Canada',
  },
  KKN: {
    label: 'Hoeybuktmoen (KKN)',
    city: 'Kirkenes',
    country: 'Norway',
  },
  LBV: {
    label: 'Libreville (LBV)',
    city: 'Libreville',
    country: 'Gabon',
  },
  KIX: {
    label: 'Kansai International Airport (KIX)',
    city: 'Osaka',
    country: 'Japan',
  },
  QCU: {
    label: 'Heliport (QCU)',
    city: 'Akunnaaq',
    country: 'Greenland',
  },
  AQI: {
    label: 'Qaisumah Airport (AQI)',
    city: 'Qaisumah',
    country: 'Saudi Arabia',
  },
  MOI: {
    label: 'Mitiaro Island Airport (MOI)',
    city: 'Mitiaro Island',
    country: 'Cook Islands',
  },
  YVA: {
    label: 'Iconi Airport (YVA)',
    city: 'Moroni',
    country: 'Comoros',
  },
  OSZ: {
    label: 'Koszalin Airport (OSZ)',
    city: 'Koszalin',
    country: 'Poland',
  },
  YBD: {
    label: 'New Westminster (YBD)',
    city: 'New Westminster',
    country: 'Canada',
  },
  IBE: {
    label: 'Ibague (IBE)',
    city: 'Ibague',
    country: 'Colombia',
  },
  EGM: {
    label: 'Sege Airport (EGM)',
    city: 'Sege',
    country: 'Solomon Islands',
  },
  POR: {
    label: 'Pori (POR)',
    city: 'Pori',
    country: 'Finland',
  },
  TWU: {
    label: 'Tawau Airport (TWU)',
    city: 'Tawau',
    country: 'Malaysia',
  },
  JAC: {
    label: 'Jackson Hole (JAC)',
    city: 'Jackson',
    country: 'WY',
  },
  PKY: {
    label: 'Palangkaraya Airport (PKY)',
    city: 'Palangkaraya',
    country: 'Indonesia',
  },
  BOU: {
    label: 'Bourges (BOU)',
    city: 'Bourges',
    country: 'France',
  },
  TJQ: {
    label: 'Bulutumbang Airport (TJQ)',
    city: 'Tanjung Pandan',
    country: 'Indonesia',
  },
  CFB: {
    label: 'Cabo Frio (CFB)',
    city: 'Cabo Frio',
    country: 'Brazil',
  },
  CKV: {
    label: 'Outlaw Fld (CKV)',
    city: 'Clarksville',
    country: 'United States',
  },
  JUV: {
    label: 'Upernavik Heliport (JUV)',
    city: 'Upernavik',
    country: 'Greenland',
  },
  OLA: {
    label: 'Orland Airport (OLA)',
    city: 'Orland',
    country: 'Norway',
  },
  LCI: {
    label: 'Laconia Mncpl (LCI)',
    city: 'Laconia',
    country: 'United States',
  },
  AKL: {
    label: 'Auckland International Airport (AKL)',
    city: 'Auckland',
    country: 'New Zealand',
  },
  EYP: {
    label: 'El Yopal Airport (EYP)',
    city: 'El Yopal',
    country: 'Colombia',
  },
  HMV: {
    label: 'Hemavan (HMV)',
    city: 'Hemavan',
    country: 'Sweden',
  },
  SCU: {
    label: 'Antonio Maceo (SCU)',
    city: 'Santiago de Cuba',
    country: 'Cuba',
  },
  SYU: {
    label: 'Warraber Island Airport (SYU)',
    city: 'Sue Island',
    country: 'Australia',
  },
  LDY: {
    label: 'Eglinton (LDY)',
    city: 'Londonderry',
    country: 'United Kingdom',
  },
  LVO: {
    label: 'Laverton Airport (LVO)',
    city: 'Laverton',
    country: 'Australia',
  },
  MAB: {
    label: 'Maraba Airport (MAB)',
    city: 'Maraba',
    country: 'Brazil',
  },
  NCY: {
    label: 'Annecy-Meythet (NCY)',
    city: 'Annecy',
    country: 'France',
  },
  SYM: {
    label: 'Simao Airport (SYM)',
    city: 'Simao',
    country: 'China',
  },
  GLK: {
    label: 'Galcaio Airport (GLK)',
    city: 'Galcaio',
    country: 'Somalia',
  },
  ARV: {
    label: 'Noble F Lee (ARV)',
    city: 'Minocqua',
    country: 'United States',
  },
  BAU: {
    label: 'Bauru (BAU)',
    city: 'Bauru',
    country: 'Brazil',
  },
  VHY: {
    label: 'Charmeil (VHY)',
    city: 'Vichy',
    country: 'France',
  },
  TKG: {
    label: 'Branti (TKG)',
    city: 'Bandar Lampung',
    country: 'Indonesia',
  },
  HRO: {
    label: 'Boone Cty (HRO)',
    city: 'Harrison',
    country: 'United States',
  },
  JSR: {
    label: 'Jessore Airport (JSR)',
    city: 'Jessore',
    country: 'Bangladesh',
  },
  LBB: {
    label: 'Lubbock (LBB)',
    city: 'Lubbock',
    country: 'United States',
  },
  KOK: {
    label: 'Kruunupyy (KOK)',
    city: 'Kokkola',
    country: 'Finland',
  },
  LRS: {
    label: 'Leros (LRS)',
    city: 'Leros',
    country: 'Greece',
  },
  SKH: {
    label: 'Surkhet Airport (SKH)',
    city: 'Surkhet',
    country: 'Nepal',
  },
  HHN: {
    label: 'Hahn (HHN)',
    city: 'Frankfurt',
    country: 'Germany',
  },
  LHR: {
    label: 'Heathrow (LHR)',
    city: 'London',
    country: 'United Kingdom',
  },
  KOJ: {
    label: 'Kagoshima (KOJ)',
    city: 'Kagoshima',
    country: 'Japan',
  },
  HLD: {
    label: 'Hailar Airport (HLD)',
    city: 'Hailar',
    country: 'China',
  },
  KIV: {
    label: 'Chisinau (KIV)',
    city: 'Chisinau',
    country: 'Moldova',
  },
  PKA: {
    label: 'Napaskiak Sea Plane Base (PKA)',
    city: 'Napaskiak',
    country: 'United States',
  },
  MLH: {
    label: 'Mulhouse EuroAirport (MLH)',
    city: 'Mulhouse',
    country: 'France',
  },
  BXX: {
    label: 'Borama Airport (BXX)',
    city: 'Borama',
    country: 'Somalia',
  },
  HGD: {
    label: 'Hughenden Airport (HGD)',
    city: 'Hughenden',
    country: 'Australia',
  },
  YND: {
    label: 'Gatineau Mncpl (YND)',
    city: 'Gatineau',
    country: 'Canada',
  },
  VPY: {
    label: 'Chimoio Airport (VPY)',
    city: 'Chimoio',
    country: 'Mozambique',
  },
  AAE: {
    label: 'Les Salines Airport (AAE)',
    city: 'Annaba',
    country: 'Algeria',
  },
  AWZ: {
    label: 'Ahwaz Airport (AWZ)',
    city: 'Ahwaz',
    country: 'Iran',
  },
  YYC: {
    label: 'Calgary (YYC)',
    city: 'Calgary',
    country: 'Canada',
  },
  HEH: {
    label: 'Heho Airport (HEH)',
    city: 'Heho',
    country: 'Myanmar (Burma)',
  },
  LOS: {
    label: 'Murtala Muhammed (LOS)',
    city: 'Lagos',
    country: 'Nigeria',
  },
  ROW: {
    label: 'Roswell Industrial (ROW)',
    city: 'Roswell',
    country: 'United States',
  },
  ONG: {
    label: 'Mornington Airport (ONG)',
    city: 'Mornington',
    country: 'Australia',
  },
  KRP: {
    label: 'Karup Airport (KRP)',
    city: 'Karup',
    country: 'Denmark',
  },
  CWB: {
    label: 'Afonso Pena (CWB)',
    city: 'Curitiba',
    country: 'Brazil',
  },
  RZE: {
    label: 'Jasionka (RZE)',
    city: 'Rzeszow',
    country: 'Poland',
  },
  TBZ: {
    label: 'Tabriz Airport (TBZ)',
    city: 'Tabriz',
    country: 'Iran',
  },
  HQM: {
    label: 'Bowerman (HQM)',
    city: 'Hoquiam',
    country: 'United States',
  },
  KOI: {
    label: 'Kirkwall (KOI)',
    city: 'Kirkwall',
    country: 'United Kingdom',
  },
  IDA: {
    label: 'Fanning Field (IDA)',
    city: 'Idaho Falls',
    country: 'United States',
  },
  AJF: {
    label: 'Jouf Airport (AJF)',
    city: 'Jouf',
    country: 'Saudi Arabia',
  },
  KSC: {
    label: 'Barca (KSC)',
    city: 'Kosice',
    country: 'Slovakia',
  },
  SEB: {
    label: 'Sebha Airport (SEB)',
    city: 'Sebha',
    country: 'Libya',
  },
  YUB: {
    label: 'Tuktoyaktuk (YUB)',
    city: 'Tuktoyaktuk',
    country: 'Canada',
  },
  KLH: {
    label: 'Kolhapur Airport (KLH)',
    city: 'Kolhapur',
    country: 'India',
  },
  MLW: {
    label: 'Sprigg Payne (MLW)',
    city: 'Monrovia',
    country: 'Liberia',
  },
  SMQ: {
    label: 'Sampit Airport (SMQ)',
    city: 'Sampit',
    country: 'Indonesia',
  },
  TVC: {
    label: 'Traverse City Airport (TVC)',
    city: 'Traverse City',
    country: 'United States',
  },
  LOD: {
    label: 'Longana Airport (LOD)',
    city: 'Longana',
    country: 'Vanuatu',
  },
  MKG: {
    label: 'Muskegon Airport (MKG)',
    city: 'Muskegon',
    country: 'United States',
  },
  LCG: {
    label: 'La Coruna (LCG)',
    city: 'La Coruna',
    country: 'Spain',
  },
  YVC: {
    label: 'La Ronge (YVC)',
    city: 'La Ronge',
    country: 'Canada',
  },
  DEN: {
    label: 'Denver (DEN)',
    city: 'Denver',
    country: 'United States',
  },
  HOE: {
    label: 'Houeisay Airport (HOE)',
    city: 'Houeisay',
    country: 'Laos',
  },
  MJN: {
    label: 'Amborovy Airport (MJN)',
    city: 'Majunga',
    country: 'Madagascar',
  },
  GZT: {
    label: 'Gaziantep (GZT)',
    city: 'Gaziantep',
    country: 'Turkey',
  },
  ISM: {
    label: 'Kissimmee Mncpl (ISM)',
    city: 'Kissimmee',
    country: 'United States',
  },
  KRI: {
    label: 'Kikori Airport (KRI)',
    city: 'Kikori',
    country: 'Papua New Guinea',
  },
  RCO: {
    label: 'Rochefort (RCO)',
    city: 'Rochefort',
    country: 'France',
  },
  YYQ: {
    label: 'Churchill (YYQ)',
    city: 'Churchill',
    country: 'Canada',
  },
  URJ: {
    label: 'Uraj Airport (URJ)',
    city: 'Uraj',
    country: 'Russia',
  },
  SNO: {
    label: 'Sakon Nakhon Airport (SNO)',
    city: 'Sakon Nakhon',
    country: 'Thailand',
  },
  RGN: {
    label: 'Mingaladon (RGN)',
    city: 'Yangon',
    country: 'Myanmar (Burma)',
  },
  CTL: {
    label: 'Charleville Airport (CTL)',
    city: 'Charleville',
    country: 'Australia',
  },
  KWE: {
    label: 'Guiyang (KWE)',
    city: 'Guiyang',
    country: 'China',
  },
  EFL: {
    label: 'Argostoli (EFL)',
    city: 'Kefalonia',
    country: 'Greece',
  },
  ODW: {
    label: 'Oak Harbor (ODW)',
    city: 'Oak Harbor',
    country: 'United States',
  },
  YEV: {
    label: 'Inuvik (YEV)',
    city: 'Inuvik',
    country: 'Canada',
  },
  ASR: {
    label: 'Erkilet (ASR)',
    city: 'Kayseri',
    country: 'Turkey',
  },
  LZO: {
    label: 'Luzhou Airport (LZO)',
    city: 'Luzhou',
    country: 'China',
  },
  PNU: {
    label: 'Panguitch Mncpl (PNU)',
    city: 'Panguitch',
    country: 'United States',
  },
  POU: {
    label: 'Dutchess Cty (POU)',
    city: 'Poughkeepsie',
    country: 'United States',
  },
  PNL: {
    label: 'Pantelleria (PNL)',
    city: 'Pantelleria',
    country: 'Italy',
  },
  TIM: {
    label: 'Timika (TIM)',
    city: 'Tembagapura',
    country: 'Indonesia',
  },
  NNX: {
    label: 'Nunukan Airport (NNX)',
    city: 'Nunukan',
    country: 'Indonesia',
  },
  SMS: {
    label: 'Sainte Marie Airport (SMS)',
    city: 'Sainte Marie',
    country: 'Madagascar',
  },
  BGA: {
    label: 'Palo Negro (BGA)',
    city: 'Bucaramanga',
    country: 'Colombia',
  },
  CGH: {
    label: 'Congonhas (CGH)',
    city: 'Sao Paulo',
    country: 'Brazil',
  },
  ZGI: {
    label: 'Gods River Airport (ZGI)',
    city: 'Gods River',
    country: 'Canada',
  },
  MBZ: {
    label: 'Maues Airport (MBZ)',
    city: 'Maues',
    country: 'Brazil',
  },
  ZMT: {
    label: 'Masset Airport (ZMT)',
    city: 'Masset',
    country: 'Canada',
  },
  PCQ: {
    label: 'Bounneua Airport (PCQ)',
    city: 'Phongsaly',
    country: 'Laos',
  },
  TOF: {
    label: 'Tomsk Airport (TOF)',
    city: 'Tomsk',
    country: 'Russia',
  },
  OTS: {
    label: 'Anacortes (OTS)',
    city: 'Anacortes',
    country: 'United States',
  },
  EOZ: {
    label: 'Elorza Airport (EOZ)',
    city: 'Elorza',
    country: 'Venezuela',
  },
  MKE: {
    label: 'Milwaukee (MKE)',
    city: 'Milwaukee',
    country: 'United States',
  },
  CZE: {
    label: 'Coro (CZE)',
    city: 'Coro',
    country: 'Venezuela',
  },
  MVS: {
    label: 'Mucuri Airport (MVS)',
    city: 'Mucuri',
    country: 'Brazil',
  },
  MDE: {
    label: 'Jose Marie Cordova Airport (MDE)',
    city: 'Medellin',
    country: 'Colombia',
  },
  BJR: {
    label: 'Bahar Dar Airport (BJR)',
    city: 'Bahar Dar',
    country: 'Ethiopia',
  },
  PAZ: {
    label: 'Tajin (PAZ)',
    city: 'Poza Rica',
    country: 'Mexico',
  },
  RYK: {
    label: 'Rahim Yar Khan Airport (RYK)',
    city: 'Rahim Yar Khan',
    country: 'Pakistan',
  },
  WEI: {
    label: 'Weipa Airport (WEI)',
    city: 'Weipa',
    country: 'Australia',
  },
  ORV: {
    label: 'Curtis Memorial Airport (ORV)',
    city: 'Noorvik',
    country: 'United States',
  },
  ALM: {
    label: 'Alamogordo Mncpl (ALM)',
    city: 'Alamogordo',
    country: 'United States',
  },
  YOP: {
    label: 'Rainbow Lake Mncpl (YOP)',
    city: 'Rainbow Lake',
    country: 'Canada',
  },
  RBB: {
    label: 'Borba Airport (RBB)',
    city: 'Borba',
    country: 'Brazil',
  },
  CGQ: {
    label: 'Changchun (CGQ)',
    city: 'Changchun',
    country: 'China',
  },
  ULZ: {
    label: 'Uliastai Airport (ULZ)',
    city: 'Uliastai',
    country: 'Mongolia',
  },
  TAI: {
    label: 'Al Janad Airport (TAI)',
    city: 'Taiz',
    country: 'Yemen',
  },
  CNP: {
    label: 'Neerlerit Inaat Airport (CNP)',
    city: 'Neerlerit Inaat',
    country: 'Greenland',
  },
  CXI: {
    label: 'Christmas Island (CXI)',
    city: 'Christmas Island',
    country: 'Kiribati',
  },
  KMG: {
    label: 'Kunming (KMG)',
    city: 'Kunming',
    country: 'China',
  },
  SLR: {
    label: 'Sulphur Springs (SLR)',
    city: 'Sulphur Springs',
    country: 'United States',
  },
  BKE: {
    label: 'Baker City Mncpl (BKE)',
    city: 'Baker City',
    country: 'United States',
  },
  UTT: {
    label: 'Umtata (UTT)',
    city: 'Umtata',
    country: 'South Africa',
  },
  AVK: {
    label: 'Arvaikheer Airport (AVK)',
    city: 'Arvaikheer',
    country: 'Mongolia',
  },
  YOC: {
    label: 'Old Crow Airport (YOC)',
    city: 'Old Crow',
    country: 'Canada',
  },
  NSI: {
    label: 'Nsimalen (NSI)',
    city: 'Yaounde',
    country: 'Cameroon',
  },
  MTV: {
    label: 'Mota Lava Airport (MTV)',
    city: 'Mota Lava',
    country: 'Vanuatu',
  },
  PPW: {
    label: 'Papa Westray Airport (PPW)',
    city: 'Papa Westray',
    country: 'United Kingdom',
  },
  SJY: {
    label: 'Ilmajoki (SJY)',
    city: 'Seinajoki',
    country: 'Finland',
  },
  BLG: {
    label: 'Belaga Airport (BLG)',
    city: 'Belaga',
    country: 'Malaysia',
  },
  PUC: {
    label: 'Carbon Cty (PUC)',
    city: 'Price',
    country: 'United States',
  },
  TCA: {
    label: 'Tennant Creek (TCA)',
    city: 'Tennant Creek',
    country: 'Australia',
  },
  SNA: {
    label: 'Orange/Santa Ana (SNA)',
    city: 'Santa Ana',
    country: 'United States',
  },
  LCY: {
    label: 'London City Airport (LCY)',
    city: 'London',
    country: 'United Kingdom',
  },
  YGW: {
    label: 'Kuujjuarapik Airport (YGW)',
    city: 'Kuujjuarapik',
    country: 'Canada',
  },
  JAB: {
    label: 'Jabiru (JAB)',
    city: 'Jabiru',
    country: 'Australia',
  },
  PBP: {
    label: 'Punta Islita (PBP)',
    city: 'Punta Islita',
    country: 'Costa Rica',
  },
  NYE: {
    label: 'Nyeri (NYE)',
    city: 'Nyeri',
    country: 'Kenya',
  },
  WAH: {
    label: 'Harry Stern (WAH)',
    city: 'Wahpeton',
    country: 'United States',
  },
  CSK: {
    label: 'Cap Skirring Airport (CSK)',
    city: 'Cap Skirring',
    country: 'Senegal',
  },
  RNA: {
    label: 'Ulawa Airport (RNA)',
    city: 'Arona',
    country: 'Solomon Islands',
  },
  CUK: {
    label: 'Caye Caulker (CUK)',
    city: 'Caye Caulker',
    country: 'Belize',
  },
  ROS: {
    label: 'Fisherton (ROS)',
    city: 'Rosario',
    country: 'Argentina',
  },
  JST: {
    label: 'Cambria Cty (JST)',
    city: 'Johnstown',
    country: 'United States',
  },
  MLM: {
    label: 'Morelia Airport (MLM)',
    city: 'Morelia',
    country: 'Mexico',
  },
  ADU: {
    label: 'Ardabil Airport (ADU)',
    city: 'Ardabil',
    country: 'Iran',
  },
  CLO: {
    label: 'Alfonso B Aragon Airport (CLO)',
    city: 'Cali',
    country: 'Colombia',
  },
  KKX: {
    label: 'Kikaiga Shima Airport (KKX)',
    city: 'Kikaiga Shima',
    country: 'Japan',
  },
  TLY: {
    label: 'Plastun Airport (TLY)',
    city: 'Plastun',
    country: 'Russia',
  },
  EAE: {
    label: 'Emae Airport (EAE)',
    city: 'Emae',
    country: 'Vanuatu',
  },
  HJJ: {
    label: 'Zhi Jiang Airport (HJJ)',
    city: 'Zhi Jiang',
    country: 'China',
  },
  DAT: {
    label: 'Datong (DAT)',
    city: 'Datong',
    country: 'China',
  },
  PNR: {
    label: 'Pointe Noire (PNR)',
    city: 'Pointe Noire',
    country: 'Republic of Congo',
  },
  JKT: {
    label: 'All Airports (JKT)',
    city: 'Jakarta',
    country: 'Indonesia',
  },
  MDC: {
    label: 'Samratulangi (MDC)',
    city: 'Manado',
    country: 'Indonesia',
  },
  ABR: {
    label: 'Aberdeen Municipal Airport (ABR)',
    city: 'Aberdeen',
    country: 'United States',
  },
  SPS: {
    label: 'Sheppard Air Force Base (SPS)',
    city: 'Wichita Falls',
    country: 'United States',
  },
  VVZ: {
    label: 'Illizi Airport (VVZ)',
    city: 'Illizi',
    country: 'Algeria',
  },
  LTK: {
    label: 'Latakia/Hmelmin (LTK)',
    city: 'Latakia',
    country: 'Syria',
  },
  BHB: {
    label: 'Bar Harbor (BHB)',
    city: 'Bar Harbor',
    country: 'United States',
  },
  ANU: {
    label: 'VC Bird International Airport (ANU)',
    city: 'Antigua',
    country: 'Antigua and Barbuda',
  },
  KDO: {
    label: 'Kadhdhoo Airport (KDO)',
    city: 'Kadhdhoo',
    country: 'Republic of Maldives',
  },
  FLN: {
    label: 'Hercilio Luz (FLN)',
    city: 'Florianopolis',
    country: 'Brazil',
  },
  RVN: {
    label: 'Rovaniemi (RVN)',
    city: 'Rovaniemi',
    country: 'Finland',
  },
  SLZ: {
    label: 'Mal Cunha Machado (SLZ)',
    city: 'Sao Luiz',
    country: 'Brazil',
  },
  AYS: {
    label: 'Waycross-Ware County (AYS)',
    city: 'Waycross',
    country: 'United States',
  },
  VTU: {
    label: 'Las Tunas Airport (VTU)',
    city: 'Las Tunas',
    country: 'Cuba',
  },
  SYZ: {
    label: 'Shiraz Airport (SYZ)',
    city: 'Shiraz',
    country: 'Iran',
  },
  YEK: {
    label: 'Arviat Mncpl (YEK)',
    city: 'Arviat',
    country: 'Canada',
  },
  NQU: {
    label: 'Nuqui Airport (NQU)',
    city: 'Nuqui',
    country: 'Colombia',
  },
  CZS: {
    label: 'Campo Internacional Airport (CZS)',
    city: 'Cruzeiro Do Sul',
    country: 'Brazil',
  },
  KGS: {
    label: 'Kos (KGS)',
    city: 'Kos',
    country: 'Greece',
  },
  BLT: {
    label: 'Blackwater Airport (BLT)',
    city: 'Blackwater',
    country: 'Australia',
  },
  GLF: {
    label: 'Golfito (GLF)',
    city: 'Golfito',
    country: 'Costa Rica',
  },
  HPH: {
    label: 'Haiphong (HPH)',
    city: 'Haiphong',
    country: 'Vietnam',
  },
  NEV: {
    label: 'Newcastle Airport (NEV)',
    city: 'Nevis',
    country: 'St Kitts and Nevis',
  },
  CEI: {
    label: 'Chiang Rai (CEI)',
    city: 'Chiang Rai',
    country: 'Thailand',
  },
  TLA: {
    label: 'Teller Airport (TLA)',
    city: 'Teller',
    country: 'United States',
  },
  CAY: {
    label: 'Rochambeau (CAY)',
    city: 'Cayenne',
    country: 'French Guiana',
  },
  SZV: {
    label: 'Suzhou (SZV)',
    city: 'Suzhou',
    country: 'China',
  },
  AJR: {
    label: 'Arvidsjaur (AJR)',
    city: 'Arvidsjaur',
    country: 'Sweden',
  },
  ULA: {
    label: 'San Julian Airport (ULA)',
    city: 'San Julian',
    country: 'Argentina',
  },
  WJU: {
    label: 'Wonju Airport (WJU)',
    city: 'Wonju',
    country: 'South Korea',
  },
  CFN: {
    label: 'Donegal (CFN)',
    city: 'Donegal',
    country: 'Ireland',
  },
  TUI: {
    label: 'Turaif Airport (TUI)',
    city: 'Turaif',
    country: 'Saudi Arabia',
  },
  BLD: {
    label: 'Boulder City Airport (BLD)',
    city: 'Boulder City',
    country: 'United States',
  },
  USQ: {
    label: 'Usak Airport (USQ)',
    city: 'Usak',
    country: 'Turkey',
  },
  PAP: {
    label: 'Mais Gate Airport (PAP)',
    city: 'Port au Prince',
    country: 'Haiti',
  },
  FMA: {
    label: 'El Pucu Airport (FMA)',
    city: 'Formosa',
    country: 'Argentina',
  },
  NOZ: {
    label: 'Novokuznetsk Airport (NOZ)',
    city: 'Novokuznetsk',
    country: 'Russia',
  },
  BRQ: {
    label: 'Turany (BRQ)',
    city: 'Brno',
    country: 'Czech Republic',
  },
  BLA: {
    label: 'Gen J A Anzoategui Airport (BLA)',
    city: 'Barcelona',
    country: 'Venezuela',
  },
  LRV: {
    label: 'Los Roques (LRV)',
    city: 'Los Roques Islands',
    country: 'Venezuela',
  },
  TIA: {
    label: 'Rinas (TIA)',
    city: 'Tirana',
    country: 'Albania',
  },
  KMI: {
    label: 'Miyazaki (KMI)',
    city: 'Miyazaki',
    country: 'Japan',
  },
  ABK: {
    label: 'Kabri Dar Airport (ABK)',
    city: 'Kabri Dar',
    country: 'Ethiopia',
  },
  VNX: {
    label: 'Vilanculos Airport (VNX)',
    city: 'Vilanculos',
    country: 'Mozambique',
  },
  YFB: {
    label: 'Frobisher/Iqaluit (YFB)',
    city: 'Iqaluit',
    country: 'Canada',
  },
  HPA: {
    label: 'Salote Pilolevu Airport (HPA)',
    city: "Ha'Apai",
    country: 'Tonga',
  },
  PND: {
    label: 'Punta Gorda (PND)',
    city: 'Punta Gorda',
    country: 'Belize',
  },
  GJA: {
    label: 'Guanaja Airport (GJA)',
    city: 'Guanaja Island',
    country: 'Honduras',
  },
  XMN: {
    label: 'Xiamen Intl (XMN)',
    city: 'Xiamen',
    country: 'China',
  },
  LCE: {
    label: 'Goloson Intl (LCE)',
    city: 'La Ceiba',
    country: 'Honduras',
  },
  BBA: {
    label: 'Teniente Vidal Airport (BBA)',
    city: 'Balmaceda',
    country: 'Chile and Easter Island',
  },
  KMY: {
    label: 'Moser Bay Airport (KMY)',
    city: 'Moser Bay',
    country: 'United States',
  },
  ESU: {
    label: 'Essaouira (ESU)',
    city: 'Essaouira',
    country: 'Morocco',
  },
  MCO: {
    label: 'Orlando (MCO)',
    city: 'Orlando',
    country: 'United States',
  },
  YAT: {
    label: 'Attawapiskat Airport (YAT)',
    city: 'Attawapiskat',
    country: 'Canada',
  },
  KBR: {
    label: 'Pengkalan Chepa (KBR)',
    city: 'Kota Bharu',
    country: 'Malaysia',
  },
  ZAG: {
    label: 'Pleso (ZAG)',
    city: 'Zagreb',
    country: 'Croatia',
  },
  CGP: {
    label: 'Patenga (CGP)',
    city: 'Chittagong',
    country: 'Bangladesh',
  },
  ZAZ: {
    label: 'Zaragoza (ZAZ)',
    city: 'Zaragoza',
    country: 'Spain',
  },
  DAB: {
    label: 'Daytona Beach Regional Airport (DAB)',
    city: 'Daytona Beach',
    country: 'United States',
  },
  JHB: {
    label: 'Sultan Ismail Intl (JHB)',
    city: 'Johor Bahru',
    country: 'Malaysia',
  },
  TTS: {
    label: 'Tsaratanana Airport (TTS)',
    city: 'Tsaratanana',
    country: 'Madagascar',
  },
  BLR: {
    label: 'Hindustan (BLR)',
    city: 'Bangalore',
    country: 'India',
  },
  IFJ: {
    label: 'Isafjordur (IFJ)',
    city: 'Isafjordur',
    country: 'Iceland',
  },
  KMJ: {
    label: 'Kumamoto (KMJ)',
    city: 'Kumamoto',
    country: 'Japan',
  },
  GZM: {
    label: 'Gozo_Heliport (GZM)',
    city: 'Victoria',
    country: 'Malta',
  },
  PKD: {
    label: 'Park Rapids Mncpl (PKD)',
    city: 'Park Rapids',
    country: 'United States',
  },
  ULY: {
    label: 'Ulyanovsk Airport (ULY)',
    city: 'Ulyanovsk',
    country: 'Russia',
  },
  MHD: {
    label: 'Mashad Airport (MHD)',
    city: 'Mashad',
    country: 'Iran',
  },
  MMB: {
    label: 'Memanbetsu Airport (MMB)',
    city: 'Memanbetsu',
    country: 'Japan',
  },
  TYZ: {
    label: 'Taylor Mncpl (TYZ)',
    city: 'Taylor',
    country: 'United States',
  },
  ICT: {
    label: 'Wichita (ICT)',
    city: 'Wichita',
    country: 'United States',
  },
  JMU: {
    label: 'Jiamusi Airport (JMU)',
    city: 'Jiamusi',
    country: 'China',
  },
  PWT: {
    label: 'Bremerton Natl (PWT)',
    city: 'Bremerton',
    country: 'United States',
  },
  AGM: {
    label: 'Tasiilaq Airport (AGM)',
    city: 'Tasiilaq',
    country: 'Greenland',
  },
  BDG: {
    label: 'Blanding Mncpl (BDG)',
    city: 'Blanding',
    country: 'United States',
  },
  VIL: {
    label: 'Dakhla Airport (VIL)',
    city: 'Dakhla',
    country: 'Morocco',
  },
  AUR: {
    label: 'Tronquieres (AUR)',
    city: 'Aurillac',
    country: 'France',
  },
  TKF: {
    label: 'Truckee Mncpl (TKF)',
    city: 'Truckee',
    country: 'United States',
  },
  YBT: {
    label: 'Brochet Airport (YBT)',
    city: 'Brochet',
    country: 'Canada',
  },
  TUF: {
    label: 'St Symphorien (TUF)',
    city: 'Tours',
    country: 'France',
  },
  IMT: {
    label: 'Ford Airport (IMT)',
    city: 'Iron Mountain',
    country: 'United States',
  },
  TPQ: {
    label: 'Tepic Mncpl (TPQ)',
    city: 'Tepic',
    country: 'Mexico',
  },
  MSS: {
    label: 'Richard Fld (MSS)',
    city: 'Massena',
    country: 'United States',
  },
  BII: {
    label: 'Enyu Airfield (BII)',
    city: 'Bikini Atoll',
    country: 'Marshall Islands',
  },
  PEG: {
    label: 'Sant Egidio (PEG)',
    city: 'Perugia',
    country: 'Italy',
  },
  TYR: {
    label: 'Pounds Field (TYR)',
    city: 'Tyler',
    country: 'United States',
  },
  CKS: {
    label: 'Carajas Airport (CKS)',
    city: 'Carajas',
    country: 'Brazil',
  },
  MML: {
    label: 'Mnpl-Ryan Fld (MML)',
    city: 'Marshall',
    country: 'United States',
  },
  YDN: {
    label: 'Dauphin Mncpl (YDN)',
    city: 'Dauphin',
    country: 'Canada',
  },
  DEA: {
    label: 'Dera Ghazi Khan Airport (DEA)',
    city: 'Dera Ghazi Khan',
    country: 'Pakistan',
  },
  LWT: {
    label: 'Lewistown Mncpl (LWT)',
    city: 'Lewistown',
    country: 'United States',
  },
  SVU: {
    label: 'Savusavu (SVU)',
    city: 'Savusavu',
    country: 'Fiji',
  },
  DWB: {
    label: 'Soalala Airport (DWB)',
    city: 'Soalala',
    country: 'Madagascar',
  },
  MHK: {
    label: 'Manhattan Regional Airport (MHK)',
    city: 'Manhattan',
    country: 'United States',
  },
  TML: {
    label: 'Tamale Airport (TML)',
    city: 'Tamale',
    country: 'Ghana',
  },
  KUF: {
    label: 'Samara (KUF)',
    city: 'Samara',
    country: 'Russia',
  },
  UTK: {
    label: 'Utirik Island Airport (UTK)',
    city: 'Utirik Island',
    country: 'Marshall Islands',
  },
  YCD: {
    label: 'Nanaimo Airport (YCD)',
    city: 'Nanaimo',
    country: 'Canada',
  },
  XCH: {
    label: 'Christmas Island Airport (XCH)',
    city: 'Christmas Island',
    country: 'Christmas Island',
  },
  KER: {
    label: 'Kerman Airport (KER)',
    city: 'Kerman',
    country: 'Iran',
  },
  NKI: {
    label: 'Naukiti Airport (NKI)',
    city: 'Naukiti',
    country: 'United States',
  },
  GWY: {
    label: 'Carnmore (GWY)',
    city: 'Galway',
    country: 'Ireland',
  },
  PNI: {
    label: 'Pohnpei (PNI)',
    city: 'Kolonia',
    country: 'Micronesia',
  },
  JNN: {
    label: 'Nanortalik Airport (JNN)',
    city: 'Nanortalik',
    country: 'Greenland',
  },
  NQT: {
    label: 'Nottingham (NQT)',
    city: 'Nottingham',
    country: 'United Kingdom',
  },
  AHO: {
    label: 'Fertilia (AHO)',
    city: 'Alghero',
    country: 'Italy',
  },
  BFQ: {
    label: 'Bahia Pinas Airport (BFQ)',
    city: 'Pinas Bay',
    country: 'Panama',
  },
  AMH: {
    label: 'Arba Mintch Airport (AMH)',
    city: 'Arba Mintch',
    country: 'Ethiopia',
  },
  GTO: {
    label: 'Tolotio Airport (GTO)',
    city: 'Gorontalo',
    country: 'Indonesia',
  },
  MCN: {
    label: 'Lewis B Wilson Airport (MCN)',
    city: 'Macon',
    country: 'United States',
  },
  PKB: {
    label: 'Wood Cty (PKB)',
    city: 'Parkersburg',
    country: 'United States',
  },
  SXP: {
    label: 'Sheldon Sea Plane Base (SXP)',
    city: 'Sheldon Point',
    country: 'United States',
  },
  HUM: {
    label: 'Terrebonne (HUM)',
    city: 'Houma',
    country: 'United States',
  },
  LXS: {
    label: 'Limnos (LXS)',
    city: 'Limnos',
    country: 'Greece',
  },
  PVO: {
    label: 'Portoviejo Airport (PVO)',
    city: 'Portoviejo',
    country: 'Ecuador',
  },
  CGM: {
    label: 'Mambajao Airport (CGM)',
    city: 'Camiguin',
    country: 'Philippines',
  },
  AFZ: {
    label: 'Sabzevar Airport (AFZ)',
    city: 'Sabzevar',
    country: 'Iran',
  },
  LHW: {
    label: 'Lanzhou (LHW)',
    city: 'Lanzhou',
    country: 'China',
  },
  ADM: {
    label: 'Ardmore Mncpl (ADM)',
    city: 'Ardmore',
    country: 'United States',
  },
  IXB: {
    label: 'Bagdogra Airport (IXB)',
    city: 'Bagdogra',
    country: 'India',
  },
  VCP: {
    label: 'Viracopos (VCP)',
    city: 'Sao Paulo',
    country: 'Brazil',
  },
  KRY: {
    label: 'Karamay (KRY)',
    city: 'Karamay',
    country: 'China',
  },
  IIL: {
    label: 'Ilaam Airport (IIL)',
    city: 'Ilaam',
    country: 'Iran',
  },
  GDV: {
    label: 'Dawson Community (GDV)',
    city: 'Glendive',
    country: 'United States',
  },
  SSI: {
    label: 'McKinnon (SSI)',
    city: 'Brunswick',
    country: 'United States',
  },
  YYZ: {
    label: 'Pearson (YYZ)',
    city: 'Toronto',
    country: 'Canada',
  },
  LGW: {
    label: 'Gatwick (LGW)',
    city: 'London',
    country: 'United Kingdom',
  },
  JMK: {
    label: 'Mikonos (JMK)',
    city: 'Mykonos',
    country: 'Greece',
  },
  SXE: {
    label: 'Sale (SXE)',
    city: 'Sale',
    country: 'Australia',
  },
  IOM: {
    label: 'Ronaldsway (IOM)',
    city: 'Isle of Man',
    country: 'United Kingdom',
  },
  JAT: {
    label: 'Jabot Airport (JAT)',
    city: 'Jabot',
    country: 'Marshall Islands',
  },
  OSH: {
    label: 'Wittman Fld (OSH)',
    city: 'Oshkosh',
    country: 'United States',
  },
  SGN: {
    label: 'ho chi minh city (SGN)',
    city: 'Ho Chi Minh City',
    country: 'Vietnam',
  },
  BCB: {
    label: 'Virginia Tech (BCB)',
    city: 'Blacksburg',
    country: 'United States',
  },
  YCS: {
    label: 'Chesterfield Inlet Airport (YCS)',
    city: 'Chesterfield Inlet',
    country: 'Canada',
  },
  DLU: {
    label: 'Dali (DLU)',
    city: 'Dali',
    country: 'China',
  },
  GEX: {
    label: 'Geelong (GEX)',
    city: 'Geelong',
    country: 'Australia',
  },
  LBS: {
    label: 'Labasa (LBS)',
    city: 'Labasa',
    country: 'Fiji',
  },
  GWD: {
    label: 'Gwadar Airport (GWD)',
    city: 'Gwadar',
    country: 'Pakistan',
  },
  ZQN: {
    label: 'Frankton (ZQN)',
    city: 'Queenstown',
    country: 'New Zealand',
  },
  ADF: {
    label: 'Adiyaman Airport (ADF)',
    city: 'Adiyaman',
    country: 'Turkey',
  },
  YIW: {
    label: 'Yiwu (YIW)',
    city: 'Yiwu',
    country: 'China',
  },
  MFI: {
    label: 'Marshfield Mncpl (MFI)',
    city: 'Marshfield',
    country: 'United States',
  },
  JEF: {
    label: 'Jefferson City Mem (JEF)',
    city: 'Jefferson City',
    country: 'United States',
  },
  SPD: {
    label: 'Saidpur Airport (SPD)',
    city: 'Saidpur',
    country: 'Bangladesh',
  },
  ROO: {
    label: 'Rondonopolis (ROO)',
    city: 'Rondonopolis',
    country: 'Brazil',
  },
  WSZ: {
    label: 'Westport (WSZ)',
    city: 'Westport',
    country: 'New Zealand',
  },
  HYA: {
    label: 'Barnstable Airport (HYA)',
    city: 'Hyannis',
    country: 'United States',
  },
  BTP: {
    label: 'Graham Field (BTP)',
    city: 'Butler',
    country: 'United States',
  },
  GJL: {
    label: 'Jijel Airport (GJL)',
    city: 'Jijel',
    country: 'Algeria',
  },
  WLS: {
    label: 'Wallis Island Airport (WLS)',
    city: 'Wallis Island',
    country: 'Wallis and Futuna',
  },
  YVM: {
    label: 'Broughton Island Airport (YVM)',
    city: 'Broughton Island',
    country: 'Canada',
  },
  LDU: {
    label: 'Lahad Datu Airport (LDU)',
    city: 'Lahad Datu',
    country: 'Malaysia',
  },
  ABZ: {
    label: 'Dyce (ABZ)',
    city: 'Aberdeen',
    country: 'United Kingdom',
  },
  RRS: {
    label: 'Roros (RRS)',
    city: 'Roros',
    country: 'Norway',
  },
  BWT: {
    label: 'Burnie Wynyard (BWT)',
    city: 'Burnie',
    country: 'Australia',
  },
  DIB: {
    label: 'Chabua Airport (DIB)',
    city: 'Dibrugarh',
    country: 'India',
  },
  PAS: {
    label: 'Paros (PAS)',
    city: 'Paros',
    country: 'Greece',
  },
  CCJ: {
    label: 'Kozhikode (CCJ)',
    city: 'Kozhikode',
    country: 'India',
  },
  PVG: {
    label: 'Pu Dong Airport (PVG)',
    city: 'Shanghai',
    country: 'China',
  },
  HMA: {
    label: 'Khanty-Mansiysk (HMA)',
    city: 'Khanty-Mansiysk',
    country: 'Russia',
  },
  RNS: {
    label: 'St Jacques (RNS)',
    city: 'Rennes',
    country: 'France',
  },
  BSL: {
    label: 'EuroAirport Swiss (BSL)',
    city: 'Basel/Mulhouse',
    country: 'Switzerland',
  },
  RZR: {
    label: 'Ramsar Airport (RZR)',
    city: 'Ramsar',
    country: 'Iran',
  },
  BLV: {
    label: 'Scott AFB/MidAmerica (BLV)',
    city: 'Belleville',
    country: 'United States',
  },
  TXK: {
    label: 'Texarkana Municipal Airport (TXK)',
    city: 'Texarkana',
    country: 'United States',
  },
  ATZ: {
    label: 'Assiut Airport (ATZ)',
    city: 'Assiut',
    country: 'Egypt',
  },
  SCE: {
    label: 'University Park Airport (SCE)',
    city: 'State College',
    country: 'United States',
  },
  LAE: {
    label: 'Nadzab (LAE)',
    city: 'Lae',
    country: 'Papua New Guinea',
  },
  THS: {
    label: 'Sukhothai Airport (THS)',
    city: 'Sukhothai',
    country: 'Thailand',
  },
  IDO: {
    label: 'Santa Isabel do Morro Airport (IDO)',
    city: 'Santa Isabel do Morro',
    country: 'Brazil',
  },
  MJE: {
    label: 'Majkin Airport (MJE)',
    city: 'Majkin',
    country: 'Marshall Islands',
  },
  LOM: {
    label: 'Francisco PVy R (LOM)',
    city: 'Lagos de Moreno',
    country: 'Mexico',
  },
  YYR: {
    label: 'Goose Bay Airport (YYR)',
    city: 'Goose Bay',
    country: 'Canada',
  },
  BJI: {
    label: 'Bemidji Mncpl (BJI)',
    city: 'Bemidji',
    country: 'United States',
  },
  MNX: {
    label: 'Manicore Airport (MNX)',
    city: 'Manicore',
    country: 'Brazil',
  },
  TCG: {
    label: 'Tacheng Airport (TCG)',
    city: 'Tacheng',
    country: 'China',
  },
  HES: {
    label: 'State (HES)',
    city: 'Hermiston',
    country: 'United States',
  },
  VOL: {
    label: 'Volos (VOL)',
    city: 'Volos',
    country: 'Greece',
  },
  KTA: {
    label: 'Karratha (KTA)',
    city: 'Karratha',
    country: 'Australia',
  },
  BEH: {
    label: 'Ross Field (BEH)',
    city: 'Benton Harbor',
    country: 'United States',
  },
  ATD: {
    label: 'Atoifi Airport (ATD)',
    city: 'Atoifi',
    country: 'Solomon Islands',
  },
  SJT: {
    label: 'Mathis Field (SJT)',
    city: 'San Angelo',
    country: 'United States',
  },
  KOC: {
    label: 'Koumac Airport (KOC)',
    city: 'Koumac',
    country: 'New Caledonia',
  },
  MXV: {
    label: 'Moron Airport (MXV)',
    city: 'Moron',
    country: 'Mongolia',
  },
  PDS: {
    label: 'Piedras Negras Mncpl (PDS)',
    city: 'Piedras Negras',
    country: 'Mexico',
  },
  CUN: {
    label: 'Cancun Airport (CUN)',
    city: 'Cancun',
    country: 'Mexico',
  },
  OKC: {
    label: 'Oklahoma City (OKC)',
    city: 'Oklahoma City',
    country: 'United States',
  },
  PNX: {
    label: 'Grayson County (PNX)',
    city: 'Sherman',
    country: 'United States',
  },
  WVK: {
    label: 'Manakara Airport (WVK)',
    city: 'Manakara',
    country: 'Madagascar',
  },
  VAF: {
    label: 'Chabeuil (VAF)',
    city: 'Valence',
    country: 'France',
  },
  BUE: {
    label: 'All Airports (BUE)',
    city: 'Buenos Aires',
    country: 'Argentina',
  },
  OMO: {
    label: 'Mostar Airport (OMO)',
    city: 'Mostar',
    country: 'Bosnia and Herzegovina',
  },
  GLO: {
    label: 'Gloucestershire (GLO)',
    city: 'Gloucester',
    country: 'United Kingdom',
  },
  KIW: {
    label: 'Southdowns Airport (KIW)',
    city: 'Kitwe',
    country: 'Zambia',
  },
  CED: {
    label: 'Ceduna (CED)',
    city: 'Ceduna',
    country: 'Australia',
  },
  WNR: {
    label: 'Windorah Airport (WNR)',
    city: 'Windorah',
    country: 'Australia',
  },
  BPT: {
    label: 'Jefferson County Airport (BPT)',
    city: 'Beaumont',
    country: 'United States',
  },
  TCL: {
    label: 'Van De Graaf (TCL)',
    city: 'Tuscaloosa',
    country: 'United States',
  },
  GMA: {
    label: 'Gemena Airport (GMA)',
    city: 'Gemena',
    country: 'Democratic Republic of Congo',
  },
  AMM: {
    label: 'Queen Alia Intl (AMM)',
    city: 'Amman',
    country: 'Jordan',
  },
  ULB: {
    label: 'Ulei Airport (ULB)',
    city: 'Ulei',
    country: 'Vanuatu',
  },
  OZZ: {
    label: 'Ouarzazate (OZZ)',
    city: 'Ouarzazate',
    country: 'Morocco',
  },
  PIN: {
    label: 'Parintins Airport (PIN)',
    city: 'Parintins',
    country: 'Brazil',
  },
  RYG: {
    label: 'Moss Airport (RYG)',
    city: 'Rygge',
    country: 'Norway',
  },
  XBR: {
    label: 'Brockville (XBR)',
    city: 'Brockville',
    country: 'Canada',
  },
  GUC: {
    label: 'Gunnison Airport (GUC)',
    city: 'Gunnison',
    country: 'United States',
  },
  GBG: {
    label: 'Galesburg Mncpl (GBG)',
    city: 'Galesburg',
    country: 'United States',
  },
  VYS: {
    label: 'IL Valley Regl (VYS)',
    city: 'Peru',
    country: 'United States',
  },
  CPV: {
    label: 'Joao Suassuna Airport (CPV)',
    city: 'Campina Grande',
    country: 'Brazil',
  },
  YSL: {
    label: 'Edmunston Airport (YSL)',
    city: 'St Leonard',
    country: 'Canada',
  },
  KLR: {
    label: 'Kalmar (KLR)',
    city: 'Kalmar',
    country: 'Sweden',
  },
  NTN: {
    label: 'Normanton Airport (NTN)',
    city: 'Normanton',
    country: 'Australia',
  },
  LPI: {
    label: 'Linkoping/Saab (LPI)',
    city: 'Linkoping',
    country: 'Sweden',
  },
  DBA: {
    label: 'Dalbandin Airport (DBA)',
    city: 'Dalbandin',
    country: 'Pakistan',
  },
  MOV: {
    label: 'Moranbah Airport (MOV)',
    city: 'Moranbah',
    country: 'Australia',
  },
  SDE: {
    label: 'Santiago del Estero (SDE)',
    city: 'Santiago del Estero',
    country: 'Argentina',
  },
  PHG: {
    label: 'Port Harcourt City (PHG)',
    city: 'Port Harcourt',
    country: 'Nigeria',
  },
  GUA: {
    label: 'La Aurora Airport (GUA)',
    city: 'Guatemala City',
    country: 'Guatemala',
  },
  NAQ: {
    label: 'Qaanaaq Airport (NAQ)',
    city: 'Qaanaaq',
    country: 'Greenland',
  },
  VRK: {
    label: 'Varkaus (VRK)',
    city: 'Varkaus',
    country: 'Finland',
  },
  FRO: {
    label: 'Flora (FRO)',
    city: 'Floro',
    country: 'Norway',
  },
  YXP: {
    label: 'Pangnirtung Airport (YXP)',
    city: 'Pangnirtung',
    country: 'Canada',
  },
  YXE: {
    label: 'Saskatoon Airport (YXE)',
    city: 'Saskatoon',
    country: 'Canada',
  },
  HAD: {
    label: 'Halmstad (HAD)',
    city: 'Halmstad',
    country: 'Sweden',
  },
  KHV: {
    label: 'Novyy (KHV)',
    city: 'Khabarovsk',
    country: 'Russia',
  },
  MPW: {
    label: 'Mariupol Airport (MPW)',
    city: 'Mariupol',
    country: 'Ukraine',
  },
  STC: {
    label: 'St Cloud Municipal Airport (STC)',
    city: 'St Cloud',
    country: 'United States',
  },
  ASJ: {
    label: 'Amami O Shima Airport (ASJ)',
    city: 'Amami O Shima',
    country: 'Japan',
  },
  FRS: {
    label: 'Santa Elena (FRS)',
    city: 'Flores',
    country: 'Guatemala',
  },
  GAU: {
    label: 'Borjhar (GAU)',
    city: 'Guwahati',
    country: 'India',
  },
  UUD: {
    label: 'Ulan-Ude Airport (UUD)',
    city: 'Ulan-Ude',
    country: 'Russia',
  },
  NHV: {
    label: 'Nuku Hiva Airport (NHV)',
    city: 'Nuku Hiva',
    country: 'French Polynesia and Tahiti',
  },
  ACA: {
    label: 'Alvarez International Airport (ACA)',
    city: 'Acapulco',
    country: 'Mexico',
  },
  GWT: {
    label: 'Westerland-Sylt (GWT)',
    city: 'Westerland',
    country: 'Germany',
  },
  GAM: {
    label: 'Gambell Airport (GAM)',
    city: 'Gambell',
    country: 'United States',
  },
  JIK: {
    label: 'Ikaria Airport (JIK)',
    city: 'Ikaria Island',
    country: 'Greece',
  },
  FRG: {
    label: 'Republic (FRG)',
    city: 'Farmingdale',
    country: 'United States',
  },
  MVL: {
    label: 'Morrisville/Stowe (MVL)',
    city: 'Stowe',
    country: 'United States',
  },
  FTW: {
    label: 'Meacham  Field (FTW)',
    city: 'Ft Worth',
    country: 'United States',
  },
  HLN: {
    label: 'Helena Airport (HLN)',
    city: 'Helena',
    country: 'United States',
  },
  ELS: {
    label: 'East London (ELS)',
    city: 'East London',
    country: 'South Africa',
  },
  ERF: {
    label: 'Erfurt (ERF)',
    city: 'Erfurt',
    country: 'Germany',
  },
  MAU: {
    label: 'Maupiti Airport (MAU)',
    city: 'Maupiti',
    country: 'French Polynesia and Tahiti',
  },
  YQB: {
    label: 'Quebec City Airport (YQB)',
    city: 'Quebec City',
    country: 'Canada',
  },
  LBT: {
    label: 'Lumberton Mncpl (LBT)',
    city: 'Lumberton',
    country: 'United States',
  },
  SJL: {
    label: 'Da Cachoeira Airport (SJL)',
    city: 'Sao Gabriel',
    country: 'Brazil',
  },
  ALF: {
    label: 'Alta (ALF)',
    city: 'Alta',
    country: 'Norway',
  },
  PHE: {
    label: 'Port Hedland (PHE)',
    city: 'Port Hedland',
    country: 'Australia',
  },
  IXZ: {
    label: 'Port Blair (IXZ)',
    city: 'Port Blair',
    country: 'India',
  },
  PJG: {
    label: 'Panjgur Airport (PJG)',
    city: 'Panjgur',
    country: 'Pakistan',
  },
  KNW: {
    label: 'New Stuyahok Airport (KNW)',
    city: 'New Stuyahok',
    country: 'United States',
  },
  LNV: {
    label: 'Lihir Island Airport (LNV)',
    city: 'Lihir Island',
    country: 'Papua New Guinea',
  },
  GPI: {
    label: 'Guapi Airport (GPI)',
    city: 'Guapi',
    country: 'Colombia',
  },
  RGL: {
    label: 'Rio Gallegos Internacional Airport (RGL)',
    city: 'Rio Gallegos',
    country: 'Argentina',
  },
  PMQ: {
    label: 'Perito Moreno Airport (PMQ)',
    city: 'Perito Moreno',
    country: 'Argentina',
  },
  SWJ: {
    label: 'South West Bay Airport (SWJ)',
    city: 'South West Bay',
    country: 'Vanuatu',
  },
  BLK: {
    label: 'Blackpool (BLK)',
    city: 'Blackpool',
    country: 'United Kingdom',
  },
  ASU: {
    label: 'Silvio Pettirossi (ASU)',
    city: 'Asuncion',
    country: 'Paraguay',
  },
  FSD: {
    label: 'Sioux Falls Regional Airport (Jo Foss Field) (FSD)',
    city: 'Sioux Falls',
    country: 'United States',
  },
  ZAD: {
    label: 'Zadar (ZAD)',
    city: 'Zadar',
    country: 'Croatia',
  },
  MSO: {
    label: 'Johnson-Bell Field (MSO)',
    city: 'Missoula',
    country: 'United States',
  },
  LOH: {
    label: 'Loja Airport (LOH)',
    city: 'Loja',
    country: 'Ecuador',
  },
  WUX: {
    label: 'Wuxi (WUX)',
    city: 'Wuxi',
    country: 'China',
  },
  FKL: {
    label: 'Chess-Lamberton (FKL)',
    city: 'Franklin',
    country: 'United States',
  },
  CMF: {
    label: 'Chambery (CMF)',
    city: 'Chambery',
    country: 'France',
  },
  YXJ: {
    label: 'Fort St John Airport (YXJ)',
    city: 'Ft St John',
    country: 'Canada',
  },
  YQX: {
    label: 'Gander Airport (YQX)',
    city: 'Gander',
    country: 'Canada',
  },
  MSN: {
    label: 'Madison (MSN)',
    city: 'Madison',
    country: 'United States',
  },
  GTR: {
    label: 'Golden Triangle Regional Airport (GTR)',
    city: 'Columbus',
    country: 'MS',
  },
  NPT: {
    label: 'State (NPT)',
    city: 'Newport',
    country: 'OR',
  },
  KUK: {
    label: 'Kasigluk Airport (KUK)',
    city: 'Kasigluk',
    country: 'United States',
  },
  JCK: {
    label: 'Julia Creek Airport (JCK)',
    city: 'Julia Creek',
    country: 'Australia',
  },
  FLG: {
    label: 'Pulliam Field (FLG)',
    city: 'Flagstaff',
    country: 'United States',
  },
  BMI: {
    label: 'Bloomington-Normal Airport (BMI)',
    city: 'Bloomington',
    country: 'IL',
  },
  ALO: {
    label: 'Waterloo Airport (ALO)',
    city: 'Waterloo',
    country: 'United States',
  },
  AGS: {
    label: 'Bush Field (AGS)',
    city: 'Augusta',
    country: 'GA',
  },
  AZR: {
    label: 'Adrar Airport (AZR)',
    city: 'Adrar',
    country: 'Algeria',
  },
  CTE: {
    label: 'Carti Airport (CTE)',
    city: 'Carti',
    country: 'Panama',
  },
  SSM: {
    label: 'County (SSM)',
    city: 'Sault Ste Marie',
    country: 'United States',
  },
  NOS: {
    label: 'Fascene Airport (NOS)',
    city: 'Nossi-be',
    country: 'Madagascar',
  },
  JIB: {
    label: 'Ambouli (JIB)',
    city: 'Djibouti',
    country: 'Djibouti',
  },
  SOQ: {
    label: 'Jefman (SOQ)',
    city: 'Sorong',
    country: 'Indonesia',
  },
  CEZ: {
    label: 'Cortez Municipal Airport (CEZ)',
    city: 'Cortez',
    country: 'United States',
  },
  CJL: {
    label: 'Chitral Airport (CJL)',
    city: 'Chitral',
    country: 'Pakistan',
  },
  SOG: {
    label: 'Haukasen (SOG)',
    city: 'Sogndal',
    country: 'Norway',
  },
  SVI: {
    label: 'San Vicente Airport (SVI)',
    city: 'San Vicente',
    country: 'Colombia',
  },
  LMN: {
    label: 'Limbang Airport (LMN)',
    city: 'Limbang',
    country: 'Malaysia',
  },
  LWN: {
    label: 'Leninakan Airport (LWN)',
    city: 'Gyoumri',
    country: 'Armenia',
  },
  TOE: {
    label: 'Tozeur (TOE)',
    city: 'Tozeur',
    country: 'Tunisia',
  },
  RCH: {
    label: 'Riohacha Airport (RCH)',
    city: 'Riohacha',
    country: 'Colombia',
  },
  FPR: {
    label: 'Saint Lucie Cty (FPR)',
    city: 'Fort Pierce',
    country: 'United States',
  },
  MBW: {
    label: 'Moorabbin Airport (MBW)',
    city: 'Moorabbin',
    country: 'Australia',
  },
  BGF: {
    label: 'Bangui (BGF)',
    city: 'Bangui',
    country: 'Central African Republic',
  },
  KUN: {
    label: 'Kaunas (KUN)',
    city: 'Kaunas',
    country: 'Lithuania',
  },
  YVB: {
    label: 'Bonaventure Mncpl (YVB)',
    city: 'Bonaventure',
    country: 'Canada',
  },
  MMY: {
    label: 'Hirara Airport (MMY)',
    city: 'Miyako',
    country: 'Japan',
  },
  KYU: {
    label: 'Koyukuk Airport (KYU)',
    city: 'Koyukuk',
    country: 'United States',
  },
  HAK: {
    label: 'Haikou (HAK)',
    city: 'Haikou',
    country: 'China',
  },
  BNU: {
    label: 'Blumenau (BNU)',
    city: 'Blumenau',
    country: 'Brazil',
  },
  LUH: {
    label: 'Sahnewal (LUH)',
    city: 'Ludhiana',
    country: 'India',
  },
  FTU: {
    label: 'Marillac Airport (FTU)',
    city: 'Fort Dauphin',
    country: 'Madagascar',
  },
  SGK: {
    label: 'Sangapi Airport (SGK)',
    city: 'Sangapi',
    country: 'Papua New Guinea',
  },
  PHO: {
    label: 'Point Hope Airport (PHO)',
    city: 'Point Hope',
    country: 'United States',
  },
  TET: {
    label: 'Matundo Airport (TET)',
    city: 'Tete',
    country: 'Mozambique',
  },
  DTL: {
    label: 'Detroit Lakes (DTL)',
    city: 'Detroit Lakes',
    country: 'United States',
  },
  WTK: {
    label: 'Noatak Airport (WTK)',
    city: 'Noatak',
    country: 'United States',
  },
  CRW: {
    label: 'Yeager Airport (CRW)',
    city: 'Charleston',
    country: 'United States',
  },
  VIT: {
    label: 'Vitoria (VIT)',
    city: 'Vitoria',
    country: 'Spain',
  },
  MPP: {
    label: 'Mulatupo Airport (MPP)',
    city: 'Mulatupo',
    country: 'Panama',
  },
  BSK: {
    label: 'Biskra Airport (BSK)',
    city: 'Biskra',
    country: 'Algeria',
  },
  CRI: {
    label: 'Crooked Island Airport (CRI)',
    city: 'Crooked Island',
    country: 'Bahamas',
  },
  PHW: {
    label: 'Phalaborwa (PHW)',
    city: 'Phalaborwa',
    country: 'South Africa',
  },
  AXS: {
    label: 'Altus Mncpl (AXS)',
    city: 'Altus',
    country: 'United States',
  },
  YFE: {
    label: 'Forestville Mncpl (YFE)',
    city: 'Forestville',
    country: 'Canada',
  },
  BVX: {
    label: 'Batesville Mncpl (BVX)',
    city: 'Batesville',
    country: 'United States',
  },
  MMG: {
    label: 'Mount Magnet Airport (MMG)',
    city: 'Mount Magnet',
    country: 'Australia',
  },
  PMY: {
    label: 'El Tehuelche Airport (PMY)',
    city: 'Puerto Madryn',
    country: 'Argentina',
  },
  JGN: {
    label: 'Jiayuguan Airport (JGN)',
    city: 'Jiayuguan',
    country: 'China',
  },
  SRQ: {
    label: 'Bradenton Airport (SRQ)',
    city: 'Sarasota',
    country: 'United States',
  },
  BTR: {
    label: 'Ryan Airport (BTR)',
    city: 'Baton Rouge',
    country: 'United States',
  },
  OPO: {
    label: 'Dr Fran Carneiro (OPO)',
    city: 'Porto',
    country: 'Portugal',
  },
  CJN: {
    label: 'Gillespie Field (CJN)',
    city: 'El Cajon',
    country: 'United States',
  },
  JRO: {
    label: 'Kilimanjaro (JRO)',
    city: 'Kilimanjaro',
    country: 'Tanzania',
  },
  MTF: {
    label: 'Mizan Teferi Airport (MTF)',
    city: 'Mizan Teferi',
    country: 'Ethiopia',
  },
  TEZ: {
    label: 'Salonibari Airport (TEZ)',
    city: 'Tezpur',
    country: 'India',
  },
  ITH: {
    label: 'Tompkins County Airport (ITH)',
    city: 'Ithaca',
    country: 'United States',
  },
  SLA: {
    label: 'Gen Belgrano (SLA)',
    city: 'Salta',
    country: 'Argentina',
  },
  KOS: {
    label: 'Sihanoukville Airport (KOS)',
    city: 'Sihanoukville',
    country: 'Cambodia',
  },
  AUL: {
    label: 'Aur Island Airport (AUL)',
    city: 'Aur Island',
    country: 'Marshall Islands',
  },
  MHE: {
    label: 'Mitchell (MHE)',
    city: 'Mitchell',
    country: 'United States',
  },
  SZZ: {
    label: 'Goleniow (SZZ)',
    city: 'Szczecin',
    country: 'Poland',
  },
  SBY: {
    label: 'Salisbury (SBY)',
    city: 'Salisbury',
    country: 'United States',
  },
  OBU: {
    label: 'Kobuk/Wien Airport (OBU)',
    city: 'Kobuk',
    country: 'United States',
  },
  PQS: {
    label: 'Pilot Station Airport (PQS)',
    city: 'Pilot Station',
    country: 'United States',
  },
  MOG: {
    label: 'Mong Hsat Airport (MOG)',
    city: 'Mong Hsat',
    country: 'Myanmar (Burma)',
  },
  GOI: {
    label: 'Dabolim (GOI)',
    city: 'Goa',
    country: 'India',
  },
  WNP: {
    label: 'Naga Airport (WNP)',
    city: 'Naga',
    country: 'Philippines',
  },
  ROL: {
    label: 'Roosevelt Mncpl (ROL)',
    city: 'Roosevelt',
    country: 'United States',
  },
  HEZ: {
    label: 'Hardy-Anders (HEZ)',
    city: 'Natchez',
    country: 'United States',
  },
  TRU: {
    label: 'Trujillo (TRU)',
    city: 'Trujillo',
    country: 'Peru',
  },
  PUY: {
    label: 'Pula (PUY)',
    city: 'Pula',
    country: 'Croatia',
  },
  ORY: {
    label: 'Orly Field (ORY)',
    city: 'Paris',
    country: 'France',
  },
  SWI: {
    label: 'Swindon Mncpl (SWI)',
    city: 'Swindon',
    country: 'United Kingdom',
  },
  CAI: {
    label: 'Cairo Intl (CAI)',
    city: 'Cairo',
    country: 'Egypt',
  },
  BOO: {
    label: 'Bodo (BOO)',
    city: 'Bodo',
    country: 'Norway',
  },
  LML: {
    label: 'Lae Airport (LML)',
    city: 'Lae',
    country: 'Marshall Islands',
  },
  KOT: {
    label: 'Kotlik Airport (KOT)',
    city: 'Kotlik',
    country: 'United States',
  },
  KHG: {
    label: 'Kashi Airport (KHG)',
    city: 'Kashi',
    country: 'China',
  },
  NAA: {
    label: 'Narrabri (NAA)',
    city: 'Narrabri',
    country: 'Australia',
  },
  KUV: {
    label: 'Gunsan Airport (KUV)',
    city: 'Gunsan',
    country: 'South Korea',
  },
  SDU: {
    label: 'Santos Dumont (SDU)',
    city: 'Rio de Janeiro',
    country: 'Brazil',
  },
  AAL: {
    label: 'Aalborg (AAL)',
    city: 'Aalborg',
    country: 'Denmark',
  },
  AES: {
    label: 'Vigra (AES)',
    city: 'Aalesund',
    country: 'Norway',
  },
  GRU: {
    label: 'Guarulhos International Airport (GRU)',
    city: 'Sao Paulo',
    country: 'Brazil',
  },
  ENF: {
    label: 'Enontekio (ENF)',
    city: 'Enontekio',
    country: 'Finland',
  },
  VPE: {
    label: 'Ongiva Airport (VPE)',
    city: 'Ongiva',
    country: 'Angola',
  },
  SSR: {
    label: 'Sara Airport (SSR)',
    city: 'Sara',
    country: 'Vanuatu',
  },
  ESC: {
    label: 'Delta County Airport (ESC)',
    city: 'Escanaba',
    country: 'United States',
  },
  MAH: {
    label: 'Menorca (MAH)',
    city: 'Menorca',
    country: 'Spain',
  },
  HTI: {
    label: 'Hamilton Is (HTI)',
    city: 'Hamilton Island',
    country: 'Australia',
  },
  DLM: {
    label: 'Dalaman (DLM)',
    city: 'Dalaman',
    country: 'Turkey',
  },
  YEG: {
    label: 'Edmonton (YEG)',
    city: 'Edmonton',
    country: 'Canada',
  },
  ALP: {
    label: 'Nejrab (ALP)',
    city: 'Aleppo',
    country: 'Syria',
  },
  HAC: {
    label: 'Hachijo Jima Airport (HAC)',
    city: 'Hachijo Jima',
    country: 'Japan',
  },
  BFF: {
    label: 'Western Nebraska Regional Airport (BFF)',
    city: 'Scottsbluff',
    country: 'United States',
  },
  CHR: {
    label: 'Chateauroux (CHR)',
    city: 'Chateauroux',
    country: 'France',
  },
  TJH: {
    label: 'Tajima Airport (TJH)',
    city: 'Toyooka',
    country: 'Japan',
  },
  THL: {
    label: 'Tachilek Airport (THL)',
    city: 'Tachilek',
    country: 'Myanmar (Burma)',
  },
  ROG: {
    label: 'Rogers Mncpl (ROG)',
    city: 'Rogers',
    country: 'United States',
  },
  CGD: {
    label: 'Changde (CGD)',
    city: 'Changde',
    country: 'China',
  },
  GAO: {
    label: 'Los Canos Airport (GAO)',
    city: 'Guantanamo',
    country: 'Cuba',
  },
  EPL: {
    label: 'Mirecourt (EPL)',
    city: 'Epinal',
    country: 'France',
  },
  ADQ: {
    label: 'Kodiak Airport (ADQ)',
    city: 'Kodiak',
    country: 'United States',
  },
  KIS: {
    label: 'Kisumu (KIS)',
    city: 'Kisumu',
    country: 'Kenya',
  },
  FNI: {
    label: 'Garons (FNI)',
    city: 'Nimes',
    country: 'France',
  },
  RSI: {
    label: 'Rio Sidra Airport (RSI)',
    city: 'Rio Sidra',
    country: 'Panama',
  },
  LIL: {
    label: 'Lesquin (LIL)',
    city: 'Lille',
    country: 'France',
  },
  EBU: {
    label: 'Boutheon (EBU)',
    city: 'St Etienne',
    country: 'France',
  },
  LVA: {
    label: 'Laval (LVA)',
    city: 'Laval',
    country: 'France',
  },
  MVB: {
    label: 'Franceville/Mvengue (MVB)',
    city: 'Franceville',
    country: 'Gabon',
  },
  NDR: {
    label: 'Nador Airport (NDR)',
    city: 'Nador',
    country: 'Morocco',
  },
  MAK: {
    label: 'Malakal Airport (MAK)',
    city: 'Malakal',
    country: 'Sudan',
  },
  RGA: {
    label: 'Rio Grande Airport (RGA)',
    city: 'Rio Grande',
    country: 'Argentina',
  },
  EOK: {
    label: 'Keokuk Mncpl (EOK)',
    city: 'Keokuk',
    country: 'United States',
  },
  OTV: {
    label: 'Ontong Java Airport (OTV)',
    city: 'Ontong Java',
    country: 'Solomon Islands',
  },
  OCH: {
    label: 'Nacogdoches Mncpl (OCH)',
    city: 'Nacogdoches',
    country: 'United States',
  },
  SDV: {
    label: 'Sde Dov (SDV)',
    city: 'Tel Aviv',
    country: 'Israel',
  },
  YLE: {
    label: 'Wha Ti Airport (YLE)',
    city: 'Wha Ti/Lac La Martre',
    country: 'Canada',
  },
  KIH: {
    label: 'Kish Island Airport (KIH)',
    city: 'Kish Island',
    country: 'Iran',
  },
  BDU: {
    label: 'Bardufoss (BDU)',
    city: 'Bardufoss',
    country: 'Norway',
  },
  RSH: {
    label: 'Russian Sea Plane Base (RSH)',
    city: 'Russian Mission',
    country: 'United States',
  },
  SZC: {
    label: 'Guanacaste (SZC)',
    city: 'Santa Cruz',
    country: 'Costa Rica',
  },
  PRN: {
    label: 'Pristina (PRN)',
    city: 'Pristina',
    country: 'Kosovo',
  },
  HLZ: {
    label: 'Hamilton (HLZ)',
    city: 'Hamilton',
    country: 'New Zealand',
  },
  BAZ: {
    label: 'Barbelos Airport (BAZ)',
    city: 'Barbelos',
    country: 'Brazil',
  },
  GWO: {
    label: 'Leflore (GWO)',
    city: 'Greenwood',
    country: 'United States',
  },
  YQG: {
    label: 'Windsor Airport (YQG)',
    city: 'Windsor',
    country: 'Canada',
  },
  OPS: {
    label: 'Sinop Airport (OPS)',
    city: 'Sinop',
    country: 'Brazil',
  },
  MEM: {
    label: 'Memphis (MEM)',
    city: 'Memphis',
    country: 'United States',
  },
  ECP: {
    label: 'Panama City Beach (ECP)',
    city: 'Panama City Beach',
    country: 'United States',
  },
  ELD: {
    label: 'Goodwin Fld (ELD)',
    city: 'El Dorado',
    country: 'United States',
  },
  RBL: {
    label: 'Red Bluff Mncpl (RBL)',
    city: 'Red Bluff',
    country: 'United States',
  },
  FYU: {
    label: 'Fort Yukon Airport (FYU)',
    city: 'Fort Yukon',
    country: 'United States',
  },
  TVF: {
    label: 'Thief River Falls (TVF)',
    city: 'Thief River Falls',
    country: 'United States',
  },
  QJE: {
    label: 'Heliport (QJE)',
    city: 'Kitsissuarsuit',
    country: 'Greenland',
  },
  GRK: {
    label: 'Killeen (GRK)',
    city: 'Killeen',
    country: 'United States',
  },
  TNK: {
    label: 'Tununak Airport (TNK)',
    city: 'Tununak',
    country: 'United States',
  },
  SNR: {
    label: 'Montoir (SNR)',
    city: 'St Nazaire',
    country: 'France',
  },
  SQO: {
    label: 'Gunnarn Airport (SQO)',
    city: 'Storuman',
    country: 'Sweden',
  },
  TMS: {
    label: 'Sao Tome Is (TMS)',
    city: 'Sao Tome Is',
    country: 'Sao Tome and Principe',
  },
  NZH: {
    label: 'Manzhouli Airport (NZH)',
    city: 'Manzhouli',
    country: 'China',
  },
  CSQ: {
    label: 'Creston Mncpl (CSQ)',
    city: 'Creston',
    country: 'United States',
  },
  MMX: {
    label: 'Sturup (MMX)',
    city: 'Malmo',
    country: 'Sweden',
  },
  BCP: {
    label: 'Bambu Airport (BCP)',
    city: 'Bambu',
    country: 'Papua New Guinea',
  },
  DEC: {
    label: 'Decatur (DEC)',
    city: 'Decatur',
    country: 'United States',
  },
  CPC: {
    label: 'Chapelco (CPC)',
    city: 'San Martin de los Andes',
    country: 'Argentina',
  },
  SHH: {
    label: 'Shishmaref Airport (SHH)',
    city: 'Shishmaref',
    country: 'United States',
  },
  DSM: {
    label: 'Des Moines Airport (DSM)',
    city: 'Des Moines',
    country: 'United States',
  },
  ZPM: {
    label: 'Regensburg Railway Service (ZPM)',
    city: 'Regensburg',
    country: 'Germany',
  },
  LNO: {
    label: 'Leonora Airport (LNO)',
    city: 'Leonora',
    country: 'Australia',
  },
  FRE: {
    label: 'Fera Island Airport (FRE)',
    city: 'Fera Island',
    country: 'Solomon Islands',
  },
  LBY: {
    label: 'Montoir (LBY)',
    city: 'La Baule',
    country: 'France',
  },
  ABV: {
    label: 'Abuja International (ABV)',
    city: 'Abuja',
    country: 'Nigeria',
  },
  JIU: {
    label: 'Jiujiang (JIU)',
    city: 'Jiujiang',
    country: 'China',
  },
  GTE: {
    label: 'Alyangula Airport (GTE)',
    city: 'Groote Island',
    country: 'Australia',
  },
  PLD: {
    label: 'Playa Samara (PLD)',
    city: 'Playa Samara',
    country: 'Costa Rica',
  },
  YKG: {
    label: 'Kangirsuk Airport (YKG)',
    city: 'Kangirsuk',
    country: 'Canada',
  },
  BTH: {
    label: 'Hang Nadim (BTH)',
    city: 'Batam',
    country: 'Indonesia',
  },
  YAA: {
    label: 'Anahim Lake Airport (YAA)',
    city: 'Anahim Lake',
    country: 'Canada',
  },
  CSN: {
    label: 'Carson City Mncpl (CSN)',
    city: 'Carson City',
    country: 'United States',
  },
  LKB: {
    label: 'Lakeba Airport (LKB)',
    city: 'Lakeba',
    country: 'Fiji',
  },
  PSD: {
    label: 'Port Said (PSD)',
    city: 'Port Said',
    country: 'Egypt',
  },
  BDO: {
    label: 'Husein Sastranegara (BDO)',
    city: 'Bandung',
    country: 'Indonesia',
  },
  SRY: {
    label: 'Dashte Naz Airport (SRY)',
    city: 'Sary',
    country: 'Iran',
  },
  MUX: {
    label: 'Multan (MUX)',
    city: 'Multan',
    country: 'Pakistan',
  },
  QGQ: {
    label: 'Attu Heliport (QGQ)',
    city: 'Attu',
    country: 'Greenland',
  },
  CRA: {
    label: 'Craiova (CRA)',
    city: 'Craiova',
    country: 'Romania',
  },
  SAT: {
    label: 'San Antonio (SAT)',
    city: 'San Antonio',
    country: 'United States',
  },
  MRX: {
    label: 'Mahshahr Airport (MRX)',
    city: 'Bandar Mahshahr',
    country: 'Iran',
  },
  EOI: {
    label: 'Eday Airport (EOI)',
    city: 'Eday',
    country: 'United Kingdom',
  },
  SRN: {
    label: 'Strahan (SRN)',
    city: 'Strahan',
    country: 'Australia',
  },
  RAE: {
    label: 'Arar Airport (RAE)',
    city: 'Arar',
    country: 'Saudi Arabia',
  },
  ABY: {
    label: 'Albany (ABY)',
    city: 'Albany',
    country: 'GA',
  },
  MYE: {
    label: 'Miyake Jima Airport (MYE)',
    city: 'Miyake Jima',
    country: 'Japan',
  },
  YKN: {
    label: 'Chan Gurney (YKN)',
    city: 'Yankton',
    country: 'United States',
  },
  YSK: {
    label: 'Sanikiluaq (YSK)',
    city: 'Sanikiluaq',
    country: 'Canada',
  },
  IQM: {
    label: 'Qiemo Airport (IQM)',
    city: 'Qiemo',
    country: 'China',
  },
  GIL: {
    label: 'Gilgit (GIL)',
    city: 'Gilgit',
    country: 'Pakistan',
  },
  FAI: {
    label: 'Fairbanks (FAI)',
    city: 'Fairbanks',
    country: 'United States',
  },
  LBF: {
    label: 'North Platte Regional Airport Lee Bird Field (LBF)',
    city: 'North Platte',
    country: 'United States',
  },
  GMB: {
    label: 'Gambela Airport (GMB)',
    city: 'Gambela',
    country: 'Ethiopia',
  },
  BHM: {
    label: 'Birmingham (BHM)',
    city: 'Birmingham',
    country: 'United States',
  },
  GOR: {
    label: 'Gore Airport (GOR)',
    city: 'Gore',
    country: 'Ethiopia',
  },
  YZR: {
    label: 'Sarnia Airport (YZR)',
    city: 'Sarnia',
    country: 'Canada',
  },
  BRX: {
    label: 'Maria Montez Intl (BRX)',
    city: 'Barahona',
    country: 'Dominican Republic',
  },
  YWS: {
    label: 'Whistler Mncpl (YWS)',
    city: 'Whistler',
    country: 'Canada',
  },
  YYJ: {
    label: 'Victoria (YYJ)',
    city: 'Victoria',
    country: 'Canada',
  },
  SUV: {
    label: 'Nausori (SUV)',
    city: 'Suva',
    country: 'Fiji',
  },
  WIC: {
    label: 'Wick (WIC)',
    city: 'Wick',
    country: 'United Kingdom',
  },
  AMS: {
    label: 'Amsterdam-Schiphol Airport (AMS)',
    city: 'Amsterdam',
    country: 'Netherlands',
  },
  YWJ: {
    label: 'Deline Airport (YWJ)',
    city: 'Deline',
    country: 'Canada',
  },
  BEN: {
    label: 'Benina (BEN)',
    city: 'Benghazi',
    country: 'Libya',
  },
  RBG: {
    label: 'Roseburg Mncpl (RBG)',
    city: 'Roseburg',
    country: 'United States',
  },
  DYU: {
    label: 'Dushanbe (DYU)',
    city: 'Dushanbe',
    country: 'Tajikistan',
  },
  PIK: {
    label: 'Prestwick (PIK)',
    city: 'Glasgow',
    country: 'United Kingdom',
  },
  MRS: {
    label: 'Marseille Airport (MRS)',
    city: 'Marseille',
    country: 'France',
  },
  TOG: {
    label: 'Togiak Village Airport (TOG)',
    city: 'Togiak Village',
    country: 'United States',
  },
  CEK: {
    label: 'Chelyabinsk (CEK)',
    city: 'Chelyabinsk',
    country: 'Russia',
  },
  GER: {
    label: 'Rafael Cabrera Airport (GER)',
    city: 'Nueva Gerona',
    country: 'Cuba',
  },
  ASD: {
    label: 'Andros (ASD)',
    city: 'Andros Town',
    country: 'Bahamas',
  },
  MAN: {
    label: 'Manchester International Airport (MAN)',
    city: 'Manchester',
    country: 'United Kingdom',
  },
  SMK: {
    label: 'St Michael Airport (SMK)',
    city: 'Saint Michael',
    country: 'United States',
  },
  AIU: {
    label: 'Atiu Island Airport (AIU)',
    city: 'Atiu Island',
    country: 'Cook Islands',
  },
  LWO: {
    label: 'Lvov (LWO)',
    city: 'Lviv',
    country: 'Ukraine',
  },
  GIC: {
    label: 'Boigu Island Airport (GIC)',
    city: 'Boigu Island',
    country: 'Australia',
  },
  PSU: {
    label: 'Putussibau Airport (PSU)',
    city: 'Putussibau',
    country: 'Indonesia',
  },
  KDM: {
    label: 'Kaadedhdhoo Airport (KDM)',
    city: 'Kaadedhdhoo',
    country: 'Republic of Maldives',
  },
  UOX: {
    label: 'University/Oxford (UOX)',
    city: 'University',
    country: 'United States',
  },
  KRN: {
    label: 'Kiruna (KRN)',
    city: 'Kiruna',
    country: 'Sweden',
  },
  UIN: {
    label: 'Quincy Municipal Airport (UIN)',
    city: 'Quincy',
    country: 'United States',
  },
  FUL: {
    label: 'Fullerton (FUL)',
    city: 'Fullerton',
    country: 'United States',
  },
  ARC: {
    label: 'Arctic Village Airport (ARC)',
    city: 'Arctic Village',
    country: 'United States',
  },
  UPG: {
    label: 'Hasanuddin (UPG)',
    city: 'Ujung Pandang',
    country: 'Indonesia',
  },
  GPT: {
    label: 'Gulfport/Biloxi Airport (GPT)',
    city: 'Gulfport',
    country: 'United States',
  },
  PGD: {
    label: 'Charlotte Cty (PGD)',
    city: 'Punta Gorda',
    country: 'United States',
  },
  TUO: {
    label: 'Taupo (TUO)',
    city: 'Taupo',
    country: 'New Zealand',
  },
  JVA: {
    label: 'Ankavandra Airport (JVA)',
    city: 'Ankavandra',
    country: 'Madagascar',
  },
  SPN: {
    label: 'Saipan Intl (SPN)',
    city: 'Saipan',
    country: 'Northern Mariana Islands',
  },
  NUS: {
    label: 'Norsup Airport (NUS)',
    city: 'Norsup',
    country: 'Vanuatu',
  },
  DEZ: {
    label: 'Al Jafrah (DEZ)',
    city: 'Deir Ezzor',
    country: 'Syria',
  },
  RWP: {
    label: 'Off-Line Point (RWP)',
    city: 'Rawalpindi',
    country: 'Pakistan',
  },
  ROU: {
    label: 'Rousse (ROU)',
    city: 'Rousse',
    country: 'Bulgaria',
  },
  SAY: {
    label: 'Siena (SAY)',
    city: 'Siena',
    country: 'Italy',
  },
  TSD: {
    label: 'Tshipise (TSD)',
    city: 'Tshipise',
    country: 'South Africa',
  },
  WEH: {
    label: 'Weihai (WEH)',
    city: 'Weihai',
    country: 'China',
  },
  ESH: {
    label: 'Shoreham Airport (ESH)',
    city: 'Shoreham By Sea',
    country: 'United Kingdom',
  },
  GEV: {
    label: 'Gallivare (GEV)',
    city: 'Gallivare',
    country: 'Sweden',
  },
  LAN: {
    label: 'Capital City Airport (LAN)',
    city: 'Lansing',
    country: 'United States',
  },
  LJU: {
    label: 'Brnik (LJU)',
    city: 'Ljubljana',
    country: 'Slovenia',
  },
  SJK: {
    label: 'Sao Jose Dos Campos (SJK)',
    city: 'Sao Jose dos Campos',
    country: 'Brazil',
  },
  JUB: {
    label: 'Juba Airport (JUB)',
    city: 'Juba',
    country: 'Sudan',
  },
  ROB: {
    label: 'Roberts Intl (ROB)',
    city: 'Monrovia',
    country: 'Liberia',
  },
  CID: {
    label: 'Cedar Rapids (CID)',
    city: 'Cedar Rapids',
    country: 'United States',
  },
  GKL: {
    label: 'Great Keppel Is (GKL)',
    city: 'Great Keppel Island',
    country: 'Australia',
  },
  GHE: {
    label: 'Garachine Airport (GHE)',
    city: 'Garachine',
    country: 'Panama',
  },
  YVT: {
    label: 'Buffalo Narrows Airport (YVT)',
    city: 'Buffalo Narrows',
    country: 'Canada',
  },
  SZK: {
    label: 'Skukuza (SZK)',
    city: 'Skukuza',
    country: 'South Africa',
  },
  STS: {
    label: 'Sonoma County Airport (STS)',
    city: 'Santa Rosa',
    country: 'United States',
  },
  ONP: {
    label: 'Newport Mncpl (ONP)',
    city: 'Newport',
    country: 'United States',
  },
  GVX: {
    label: 'Sandviken (GVX)',
    city: 'Gavle',
    country: 'Sweden',
  },
  UTN: {
    label: 'Upington (UTN)',
    city: 'Upington',
    country: 'South Africa',
  },
  ESE: {
    label: 'El Cipresese (ESE)',
    city: 'Ensenada',
    country: 'Mexico',
  },
  MCU: {
    label: 'Gueret (Lepaud) (MCU)',
    city: 'Montlucon',
    country: 'France',
  },
  RMT: {
    label: 'Rimatara Airport (RMT)',
    city: 'Rimatara',
    country: 'French Polynesia and Tahiti',
  },
  TOP: {
    label: 'Philip Billard Mncpl (TOP)',
    city: 'Topeka',
    country: 'United States',
  },
  IXM: {
    label: 'Madurai (IXM)',
    city: 'Madurai',
    country: 'India',
  },
  AXX: {
    label: 'Angel Fire Mncpl (AXX)',
    city: 'Angel Fire',
    country: 'United States',
  },
  KUA: {
    label: 'Kuantan (KUA)',
    city: 'Kuantan',
    country: 'Malaysia',
  },
  RIB: {
    label: 'Gen Buech Airport (RIB)',
    city: 'Riberalta',
    country: 'Bolivia',
  },
  KYK: {
    label: 'Karluk Airport (KYK)',
    city: 'Karluk',
    country: 'United States',
  },
  PFB: {
    label: 'Passo Fundo Airport (PFB)',
    city: 'Passo Fundo',
    country: 'Brazil',
  },
  TRC: {
    label: 'Torreon (TRC)',
    city: 'Torreon',
    country: 'Mexico',
  },
  RLD: {
    label: 'Richland Mncpl (RLD)',
    city: 'Richland',
    country: 'United States',
  },
  WKA: {
    label: 'Wanaka (WKA)',
    city: 'Wanaka',
    country: 'New Zealand',
  },
  COQ: {
    label: 'Choibalsan Airport (COQ)',
    city: 'Choibalsan',
    country: 'Mongolia',
  },
  BBI: {
    label: 'Bhubaneswar (BBI)',
    city: 'Bhubaneswar',
    country: 'India',
  },
  UNG: {
    label: 'Kiunga Airport (UNG)',
    city: 'Kiunga',
    country: 'Papua New Guinea',
  },
  YCH: {
    label: 'Miramichi (YCH)',
    city: 'Miramichi',
    country: 'Canada',
  },
  BNY: {
    label: 'Bellona Airport (BNY)',
    city: 'Bellona',
    country: 'Solomon Islands',
  },
  WNH: {
    label: 'Wenshan Puzhehei (WNH)',
    city: 'Wenshan',
    country: 'China',
  },
  ORB: {
    label: 'Orebro-Bofors (ORB)',
    city: 'Orebro',
    country: 'Sweden',
  },
  EKX: {
    label: 'Elizabethtown (EKX)',
    city: 'Elizabethtown',
    country: 'United States',
  },
  NOG: {
    label: 'Nogales Airport (NOG)',
    city: 'Nogales',
    country: 'Mexico',
  },
  BUF: {
    label: 'Buffalo (BUF)',
    city: 'Buffalo',
    country: 'United States',
  },
  NRL: {
    label: 'North Ronaldsay Airport (NRL)',
    city: 'North Ronaldsay',
    country: 'United Kingdom',
  },
  SBK: {
    label: 'Tremuson St Brieuc (SBK)',
    city: 'St Brieuc',
    country: 'France',
  },
  AIC: {
    label: 'Airok Airport (AIC)',
    city: 'Airok',
    country: 'Marshall Islands',
  },
  PUD: {
    label: 'Puerto Deseado Airport (PUD)',
    city: 'Puerto Deseado',
    country: 'Argentina',
  },
  LEL: {
    label: 'Lake Evella Airport (LEL)',
    city: 'Lake Evella',
    country: 'Australia',
  },
  SRG: {
    label: 'Achmad Uani (SRG)',
    city: 'Semarang',
    country: 'Indonesia',
  },
  TWF: {
    label: 'Twin Falls Regl (TWF)',
    city: 'Twin Falls',
    country: 'United States',
  },
  AOJ: {
    label: 'Aomori (AOJ)',
    city: 'Aomori',
    country: 'Japan',
  },
  OSK: {
    label: 'Oskarshamn (OSK)',
    city: 'Oskarshamn',
    country: 'Sweden',
  },
  BER: {
    label: 'All Airports (BER)',
    city: 'Berlin',
    country: 'Germany',
  },
  FUT: {
    label: 'Futuna Island Airport (FUT)',
    city: 'Futuna Island',
    country: 'Wallis and Futuna',
  },
  RLG: {
    label: 'Rostock (RLG)',
    city: 'Rostock',
    country: 'Germany',
  },
  GOA: {
    label: 'Cristoforo Colombo (GOA)',
    city: 'Genoa',
    country: 'Italy',
  },
  SON: {
    label: 'Pekoa (SON)',
    city: 'Espiritu Santo',
    country: 'Vanuatu',
  },
  KSY: {
    label: 'Kars Airport (KSY)',
    city: 'Kars',
    country: 'Turkey',
  },
  CHA: {
    label: 'Lovell Field (CHA)',
    city: 'Chattanooga',
    country: 'United States',
  },
  CLL: {
    label: 'Easterwood Field (CLL)',
    city: 'College Station',
    country: 'United States',
  },
  VDE: {
    label: 'Hierro (VDE)',
    city: 'Valverde',
    country: 'Spain',
  },
  SSY: {
    label: "M'Banza Congo Airport (SSY)",
    city: "M'Banza Congo",
    country: 'Angola',
  },
  KBT: {
    label: 'Kaben Airport (KBT)',
    city: 'Kaben',
    country: 'Marshall Islands',
  },
  MNJ: {
    label: 'Mananjary Airport (MNJ)',
    city: 'Mananjary',
    country: 'Madagascar',
  },
  HUT: {
    label: 'Hutchinson Mncpl (HUT)',
    city: 'Hutchinson',
    country: 'United States',
  },
  NOB: {
    label: 'Nosara Beach Airport (NOB)',
    city: 'Nosara Beach',
    country: 'Costa Rica',
  },
  GCM: {
    label: 'Owen Roberts International Airport (GCM)',
    city: 'Grand Cayman Island',
    country: 'Cayman Islands',
  },
  YRS: {
    label: 'Red Sucker Lake Airport (YRS)',
    city: 'Red Sucker Lake',
    country: 'Canada',
  },
  ELC: {
    label: 'Elcho Island Airport (ELC)',
    city: 'Elcho Island',
    country: 'Australia',
  },
  YVP: {
    label: 'Kuujjuaq Airport (YVP)',
    city: 'Kuujjuaq',
    country: 'Canada',
  },
  HST: {
    label: 'Homestead Mncpl (HST)',
    city: 'Homestead',
    country: 'United States',
  },
  KRR: {
    label: 'Krasnodar (KRR)',
    city: 'Krasnodar',
    country: 'Russia',
  },
  CGO: {
    label: 'Zhengzhou (CGO)',
    city: 'Zhengzhou',
    country: 'China',
  },
  MIE: {
    label: 'Delaware Cty (MIE)',
    city: 'Muncie',
    country: 'United States',
  },
  IOW: {
    label: 'Iowa City Mncpl (IOW)',
    city: 'Iowa City',
    country: 'United States',
  },
  YSP: {
    label: 'Marathon Mncpl (YSP)',
    city: 'Marathon',
    country: 'Canada',
  },
  VAR: {
    label: 'Varna (VAR)',
    city: 'Varna',
    country: 'Bulgaria',
  },
  SFO: {
    label: 'SFO (SFO)',
    city: 'San Francisco',
    country: 'United States',
  },
  TIU: {
    label: 'Timaru (TIU)',
    city: 'Timaru',
    country: 'New Zealand',
  },
  KPB: {
    label: 'Point Baker Sea Plane Base (KPB)',
    city: 'Point Baker',
    country: 'United States',
  },
  YPO: {
    label: 'Peawanuk Airport (YPO)',
    city: 'Peawanuk',
    country: 'Canada',
  },
  ACI: {
    label: 'The Blaye Airport (ACI)',
    city: 'Alderney',
    country: 'United Kingdom',
  },
  WDG: {
    label: 'Woodring Mnpl (WDG)',
    city: 'Enid',
    country: 'United States',
  },
  JGS: {
    label: 'Ji An/Jing Gang Shan Airport (JGS)',
    city: "Ji'An",
    country: 'China',
  },
  OTD: {
    label: 'Contadora (OTD)',
    city: 'Contadora Island',
    country: 'Panama',
  },
  MGB: {
    label: 'Mt Gambier (MGB)',
    city: 'Mount Gambier',
    country: 'Australia',
  },
  YFH: {
    label: 'Fort Hope Airport (YFH)',
    city: 'Fort Hope',
    country: 'Canada',
  },
  KGI: {
    label: 'Kalgoorlie (KGI)',
    city: 'Kalgoorlie',
    country: 'Australia',
  },
  ULD: {
    label: 'Ulundi (ULD)',
    city: 'Ulundi',
    country: 'South Africa',
  },
  YRB: {
    label: 'Resolute Bay (YRB)',
    city: 'Resolute',
    country: 'Canada',
  },
  KIO: {
    label: 'Kili Island Airport (KIO)',
    city: 'Kili Island',
    country: 'Marshall Islands',
  },
  TIH: {
    label: 'Tikehau (TIH)',
    city: 'Tikehau',
    country: 'French Polynesia and Tahiti',
  },
  PUU: {
    label: 'Puerto Asis Airport (PUU)',
    city: 'Puerto Asis',
    country: 'Colombia',
  },
  SGF: {
    label: 'Springfield-Branson Regional Airport (SGF)',
    city: 'Springfield',
    country: 'MO',
  },
  GFN: {
    label: 'Grafton (GFN)',
    city: 'Grafton',
    country: 'Australia',
  },
  TGN: {
    label: 'La Trobe (TGN)',
    city: 'Traralgon',
    country: 'Australia',
  },
  UME: {
    label: 'Umea (UME)',
    city: 'Umea',
    country: 'Sweden',
  },
  ELV: {
    label: 'Elfin Cove Sea Plane Base (ELV)',
    city: 'Elfin Cove',
    country: 'United States',
  },
  EJH: {
    label: 'Wedjh Airport (EJH)',
    city: 'Wedjh',
    country: 'Saudi Arabia',
  },
  RKV: {
    label: 'Reykjavik Domestic (RKV)',
    city: 'Reykjavik',
    country: 'Iceland',
  },
  IIS: {
    label: 'Nissan Island Airport (IIS)',
    city: 'Nissan Island',
    country: 'Papua New Guinea',
  },
  KWP: {
    label: 'Village Sea Plane Base (KWP)',
    city: 'West Point',
    country: 'United States',
  },
  EGC: {
    label: 'Roumaniere (EGC)',
    city: 'Bergerac',
    country: 'France',
  },
  UNK: {
    label: 'Unalakleet Airport (UNK)',
    city: 'Unalakleet',
    country: 'United States',
  },
  KPO: {
    label: 'Pohang (KPO)',
    city: "P'ohang",
    country: 'South Korea',
  },
  CBQ: {
    label: 'Calabar Airport (CBQ)',
    city: 'Calabar',
    country: 'Nigeria',
  },
  IGU: {
    label: 'Cataratas (IGU)',
    city: 'Iguassu Falls',
    country: 'Brazil',
  },
  RMI: {
    label: 'Miramare (RMI)',
    city: 'Rimini',
    country: 'Italy',
  },
  ARX: {
    label: 'Asbury Park (ARX)',
    city: 'Asbury Park',
    country: 'United States',
  },
  YFA: {
    label: 'Fort Albany Airport (YFA)',
    city: 'Fort Albany',
    country: 'Canada',
  },
  NUL: {
    label: 'Nulato Airport (NUL)',
    city: 'Nulato',
    country: 'United States',
  },
  PSM: {
    label: 'Pease International (PSM)',
    city: 'Portsmouth',
    country: 'United States',
  },
  XSC: {
    label: 'South Caicos Mncpl (XSC)',
    city: 'South Caicos',
    country: 'Turks and Caicos',
  },
  XMS: {
    label: 'Macas Airport (XMS)',
    city: 'Macas',
    country: 'Ecuador',
  },
  SUN: {
    label: 'Sun Valley Airport (SUN)',
    city: 'Sun Valley',
    country: 'United States',
  },
  MGM: {
    label: 'Dannelly Field (MGM)',
    city: 'Montgomery',
    country: 'United States',
  },
  BVH: {
    label: 'Vilhena Airport (BVH)',
    city: 'Vilhena',
    country: 'Brazil',
  },
  IXI: {
    label: 'Lilabari Airport (IXI)',
    city: 'Lilabari',
    country: 'India',
  },
  CAP: {
    label: 'Cap Haitien Airport (CAP)',
    city: 'Cap Haitien',
    country: 'Haiti',
  },
  YCR: {
    label: 'Cross Lake Airport (YCR)',
    city: 'Cross Lake',
    country: 'Canada',
  },
  KEL: {
    label: 'Holtenau (KEL)',
    city: 'Kiel',
    country: 'Germany',
  },
  FOD: {
    label: 'Fort Dodge Mncpl (FOD)',
    city: 'Fort Dodge',
    country: 'United States',
  },
  ZLT: {
    label: 'La Tabatiere Airport (ZLT)',
    city: 'La Tabatiere',
    country: 'Canada',
  },
  JOE: {
    label: 'Joensuu (JOE)',
    city: 'Joensuu',
    country: 'Finland',
  },
  TGJ: {
    label: 'Tiga Airport (TGJ)',
    city: 'Tiga',
    country: 'New Caledonia',
  },
  NYN: {
    label: 'Nyngan (NYN)',
    city: 'Nyngan',
    country: 'Australia',
  },
  GRB: {
    label: 'Green Bay (GRB)',
    city: 'Green Bay',
    country: 'United States',
  },
  ABT: {
    label: 'Al-Aqiq Airport (ABT)',
    city: 'Al-Baha',
    country: 'Saudi Arabia',
  },
  GNV: {
    label: 'J R Alison Municipal Airport (GNV)',
    city: 'Gainesville',
    country: 'United States',
  },
  WWT: {
    label: 'Newtok Airport (WWT)',
    city: 'Newtok',
    country: 'United States',
  },
  SVQ: {
    label: 'Seville San Pablo (SVQ)',
    city: 'Seville',
    country: 'Spain',
  },
  YGK: {
    label: 'Kingston Airport (YGK)',
    city: 'Kingston',
    country: 'Canada',
  },
  MBE: {
    label: 'Monbetsu Airport (MBE)',
    city: 'Monbetsu',
    country: 'Japan',
  },
  HOR: {
    label: 'Horta (HOR)',
    city: 'Horta',
    country: 'Portugal',
  },
  QWU: {
    label: 'Railway Station (QWU)',
    city: 'Wuerzburg',
    country: 'Germany',
  },
  DRG: {
    label: 'Deering Airport (DRG)',
    city: 'Deering',
    country: 'United States',
  },
  HUN: {
    label: 'Hualien (HUN)',
    city: 'Hualien',
    country: 'Taiwan',
  },
  AID: {
    label: 'Anderson Mncpl (AID)',
    city: 'Anderson',
    country: 'United States',
  },
  DUD: {
    label: 'Dunedin (DUD)',
    city: 'Dunedin',
    country: 'New Zealand',
  },
  ABX: {
    label: 'Albury (ABX)',
    city: 'Albury',
    country: 'Australia',
  },
  AZA: {
    label: 'Phoenix-Mesa Gateway Airport (AZA)',
    city: 'Mesa',
    country: 'United States',
  },
  MRA: {
    label: 'Misurata Airport (MRA)',
    city: 'Misurata',
    country: 'Libya',
  },
  LBD: {
    label: 'Khudzhand Airport (LBD)',
    city: 'Khudzhand',
    country: 'Tajikistan',
  },
  LUG: {
    label: 'Lugano (LUG)',
    city: 'Lugano',
    country: 'Switzerland',
  },
  BKK: {
    label: 'Bangkok International Airport (BKK)',
    city: 'Bangkok',
    country: 'Thailand',
  },
  GRS: {
    label: 'Baccarini (GRS)',
    city: 'Grosseto',
    country: 'Italy',
  },
  CWT: {
    label: 'Cowra (CWT)',
    city: 'Cowra',
    country: 'Australia',
  },
  KEM: {
    label: 'Kemi (KEM)',
    city: 'Kemi',
    country: 'Finland',
  },
  CSH: {
    label: 'Solovetsky Airport (CSH)',
    city: 'Solovetsky',
    country: 'Russia',
  },
  YYE: {
    label: 'Fort Nelson Airport (YYE)',
    city: 'Ft Nelson',
    country: 'Canada',
  },
  SMI: {
    label: 'Samos (SMI)',
    city: 'Samos Island',
    country: 'Greece',
  },
  ANX: {
    label: 'Andenes Airport (ANX)',
    city: 'Andenes',
    country: 'Norway',
  },
  PSO: {
    label: 'Cano (PSO)',
    city: 'Pasto',
    country: 'Colombia',
  },
  CGI: {
    label: 'Cape Girardeau Mncpl (CGI)',
    city: 'Cape Girardeau',
    country: 'United States',
  },
  PMF: {
    label: 'Parma (PMF)',
    city: 'Milan',
    country: 'Italy',
  },
  DIJ: {
    label: 'Dijon/Longvic (DIJ)',
    city: 'Dijon',
    country: 'France',
  },
  VIX: {
    label: 'Eurico Sales (VIX)',
    city: 'Vitoria',
    country: 'Brazil',
  },
  LAS: {
    label: 'Las Vegas (LAS)',
    city: 'Las Vegas',
    country: 'United States',
  },
  SGC: {
    label: 'Surgut (SGC)',
    city: 'Surgut',
    country: 'Russia',
  },
  YQR: {
    label: 'Regina Airport (YQR)',
    city: 'Regina',
    country: 'Canada',
  },
  LGI: {
    label: 'Deadmans Cay Airport (LGI)',
    city: 'Deadmans Cay',
    country: 'Bahamas',
  },
  MVA: {
    label: 'Myvatn (MVA)',
    city: 'Myvatn',
    country: 'Iceland',
  },
  TDW: {
    label: 'Tradewind (TDW)',
    city: 'Amarillo',
    country: 'United States',
  },
  GYN: {
    label: 'Santa Genoveva (GYN)',
    city: 'Goiania',
    country: 'Brazil',
  },
  BWU: {
    label: 'Bankstown (BWU)',
    city: 'Bankstown',
    country: 'Australia',
  },
  KGX: {
    label: 'Grayling Airport (KGX)',
    city: 'Grayling',
    country: 'United States',
  },
  JSY: {
    label: 'Syros Island Airport (JSY)',
    city: 'Syros Island',
    country: 'Greece',
  },
  YVQ: {
    label: 'Norman Wells (YVQ)',
    city: 'Norman Wells',
    country: 'Canada',
  },
  BLI: {
    label: 'Bellingham Airport (BLI)',
    city: 'Bellingham',
    country: 'United States',
  },
  OND: {
    label: 'Ondangwa Airport (OND)',
    city: 'Ondangwa',
    country: 'Namibia',
  },
  RDV: {
    label: 'Red Devil Airport (RDV)',
    city: 'Red Devil',
    country: 'United States',
  },
  WWK: {
    label: 'Boram Airport (WWK)',
    city: 'Wewak',
    country: 'Papua New Guinea',
  },
  DYR: {
    label: 'Anadyr Airport (DYR)',
    city: 'Anadyr',
    country: 'Russia',
  },
  LNE: {
    label: 'Lonorore Airport (LNE)',
    city: 'Lonorore',
    country: 'Vanuatu',
  },
  DPL: {
    label: 'Dipolog Airport (DPL)',
    city: 'Dipolog',
    country: 'Philippines',
  },
  MGT: {
    label: 'Milingimbi Airport (MGT)',
    city: 'Milingimbi',
    country: 'Australia',
  },
  UES: {
    label: 'Waukesha County (UES)',
    city: 'Waukesha',
    country: 'United States',
  },
  AVV: {
    label: 'Avalon (AVV)',
    city: 'Melbourne',
    country: 'Australia',
  },
  CUC: {
    label: 'Camilo Daza (CUC)',
    city: 'Cucuta',
    country: 'Colombia',
  },
  OSR: {
    label: 'Mosnov (OSR)',
    city: 'Ostrava',
    country: 'Czech Republic',
  },
  PXU: {
    label: 'Pleiku Airport (PXU)',
    city: 'Pleiku',
    country: 'Vietnam',
  },
  AFS: {
    label: 'Zarafshan Airport (AFS)',
    city: 'Zarafshan',
    country: 'Uzbekistan',
  },
  CSG: {
    label: 'Metropolitan Area (CSG)',
    city: 'Columbus',
    country: 'GA',
  },
  MCV: {
    label: 'Mcarthur River Airport (MCV)',
    city: 'Mcarthur River',
    country: 'Australia',
  },
  PKN: {
    label: 'Pangkalanbuun Airport (PKN)',
    city: 'Pangkalanbuun',
    country: 'Indonesia',
  },
  SQV: {
    label: 'Sequim Valley (SQV)',
    city: 'Sequim',
    country: 'United States',
  },
  RIX: {
    label: 'Riga Airport (RIX)',
    city: 'Riga',
    country: 'Latvia',
  },
  BDB: {
    label: 'Bundaberg (BDB)',
    city: 'Bundaberg',
    country: 'Australia',
  },
  YAO: {
    label: 'Yaounde (YAO)',
    city: 'Yaounde',
    country: 'Cameroon',
  },
  SLI: {
    label: 'Solwezi Airport (SLI)',
    city: 'Solwezi',
    country: 'Zambia',
  },
  ARN: {
    label: 'Arlanda Airport (ARN)',
    city: 'Stockholm',
    country: 'Sweden',
  },
  ALW: {
    label: 'Walla Walla Airport (ALW)',
    city: 'Walla Walla',
    country: 'United States',
  },
  SNN: {
    label: 'Shannon Airport (SNN)',
    city: 'Shannon',
    country: 'Ireland',
  },
  RIL: {
    label: 'GarField Cty (RIL)',
    city: 'Rifle',
    country: 'United States',
  },
  MRK: {
    label: 'Marco Island (MRK)',
    city: 'Marco Island',
    country: 'United States',
  },
  BEW: {
    label: 'Beira Airport (BEW)',
    city: 'Beira',
    country: 'Mozambique',
  },
  KXA: {
    label: 'Kasaan Sea Plane Base (KXA)',
    city: 'Kasaan',
    country: 'United States',
  },
  ELQ: {
    label: 'Gassim Airport (ELQ)',
    city: 'Gassim',
    country: 'Saudi Arabia',
  },
  ILG: {
    label: 'Greater Wilmington Airport (ILG)',
    city: 'Wilmington',
    country: 'United States',
  },
  KOA: {
    label: 'Keahole Airport (KOA)',
    city: 'Kona',
    country: 'United States',
  },
  KTE: {
    label: 'Kerteh (KTE)',
    city: 'Kerteh',
    country: 'Malaysia',
  },
  BMW: {
    label: 'Bordj Badji Mokhtar Airport (BMW)',
    city: 'Bordj Badji Mokhtar',
    country: 'Algeria',
  },
  KCA: {
    label: 'Kuqa Airport (KCA)',
    city: 'Kuqa',
    country: 'China',
  },
  CPO: {
    label: 'Desierto De Atacama Airport (CPO)',
    city: 'Copiapo',
    country: 'Chile and Easter Island',
  },
  XQP: {
    label: 'Puerto Quepos Mncpl (XQP)',
    city: 'Puerto Quepos',
    country: 'Costa Rica',
  },
  OKA: {
    label: 'Naha Fld (OKA)',
    city: 'Naha',
    country: 'Japan',
  },
  INL: {
    label: 'International Falls (INL)',
    city: 'International Falls',
    country: 'United States',
  },
  YSN: {
    label: 'Salmon Arm Mncpl (YSN)',
    city: 'Salmon Arm',
    country: 'Canada',
  },
  MYI: {
    label: 'Murray Island Airport (MYI)',
    city: 'Murray Island',
    country: 'Australia',
  },
  MCI: {
    label: 'Kansas City (MCI)',
    city: 'Kansas City',
    country: 'United States',
  },
  SMR: {
    label: 'Simon Bolivar (SMR)',
    city: 'Santa Marta',
    country: 'Colombia',
  },
  NOJ: {
    label: 'Nojabrxsk Airport (NOJ)',
    city: 'Nojabrxsk',
    country: 'Russia',
  },
  SLP: {
    label: 'San Luis Potosi Airport (SLP)',
    city: 'San Luis Potosi',
    country: 'Mexico',
  },
  LIH: {
    label: 'Kauai Island Airport (LIH)',
    city: 'Lihue',
    country: 'United States',
  },
  GSE: {
    label: 'Saeve Airport (GSE)',
    city: 'Gothenburg',
    country: 'Sweden',
  },
  CEE: {
    label: 'Cherepovets Airport (CEE)',
    city: 'Cherepovets',
    country: 'Russia',
  },
  KRL: {
    label: 'Korla Airport (KRL)',
    city: 'Korla',
    country: 'China',
  },
  NGD: {
    label: 'Anegada Island Mncpl (NGD)',
    city: 'Anegada Island',
    country: 'British Virgin Islands',
  },
  YTS: {
    label: 'Timmins Airport (YTS)',
    city: 'Timmins',
    country: 'Canada',
  },
  MUB: {
    label: 'Maun (MUB)',
    city: 'Maun',
    country: 'Botswana',
  },
  CKX: {
    label: 'Chicken Airport (CKX)',
    city: 'Chicken',
    country: 'United States',
  },
  SVD: {
    label: 'E T Joshua (SVD)',
    city: 'Kingstown',
    country: 'St Vincent and the Grenadines',
  },
  VAV: {
    label: "Lupepau'u Airport (VAV)",
    city: "Vava'u",
    country: 'Tonga',
  },
  DNR: {
    label: 'Pleurtuit (DNR)',
    city: 'Dinard',
    country: 'France',
  },
  IND: {
    label: 'Indianapolis (IND)',
    city: 'Indianapolis',
    country: 'United States',
  },
  JCU: {
    label: 'Ceuta Heliport (JCU)',
    city: 'Ceuta',
    country: 'Spain',
  },
  SNU: {
    label: 'Santa Clara (SNU)',
    city: 'Santa Clara',
    country: 'Cuba',
  },
  FOC: {
    label: 'Fuzhou (FOC)',
    city: 'Fuzhou',
    country: 'China',
  },
  CFU: {
    label: 'I Kapodistrias (CFU)',
    city: 'Kerkyra',
    country: 'Greece',
  },
  ANS: {
    label: 'Andahuaylas Airport (ANS)',
    city: 'Andahuaylas',
    country: 'Peru',
  },
  TUG: {
    label: 'Tuguegarao Airport (TUG)',
    city: 'Tuguegarao',
    country: 'Philippines',
  },
  TXL: {
    label: 'Tegel Airport (TXL)',
    city: 'Berlin',
    country: 'Germany',
  },
  MXN: {
    label: 'Morlaix-Ploujean (MXN)',
    city: 'Morlaix',
    country: 'France',
  },
  RSW: {
    label: 'Ft Myers (RSW)',
    city: 'Ft Myers',
    country: 'FL',
  },
  BPS: {
    label: 'Porto Seguro Mncpl (BPS)',
    city: 'Porto Seguro',
    country: 'Brazil',
  },
  SOF: {
    label: 'Vrazhdebna (SOF)',
    city: 'Sofia',
    country: 'Bulgaria',
  },
  BJV: {
    label: 'Milas (BJV)',
    city: 'Bodrum',
    country: 'Turkey',
  },
  TXN: {
    label: 'Tunxi (TXN)',
    city: 'Tunxi',
    country: 'China',
  },
  HBA: {
    label: 'Hobart International (HBA)',
    city: 'Hobart',
    country: 'Australia',
  },
  JYV: {
    label: 'Jyvaskyla (JYV)',
    city: 'Jyvaskyla',
    country: 'Finland',
  },
  HVB: {
    label: 'Hervey Bay (HVB)',
    city: 'Hervey Bay',
    country: 'Australia',
  },
  GND: {
    label: 'Point Saline International Airport (GND)',
    city: "St George's",
    country: 'Grenada',
  },
  LRM: {
    label: 'La Romana Airport (LRM)',
    city: 'La Romana',
    country: 'Dominican Republic',
  },
  NPL: {
    label: 'New Plymouth (NPL)',
    city: 'New Plymouth',
    country: 'New Zealand',
  },
  GFF: {
    label: 'Griffith (GFF)',
    city: 'Griffith',
    country: 'Australia',
  },
  PRI: {
    label: 'Praslin Island (PRI)',
    city: 'Praslin Island',
    country: 'Seychelles',
  },
  SCV: {
    label: 'Salcea (SCV)',
    city: 'Suceava',
    country: 'Romania',
  },
  SBN: {
    label: 'St Joseph County Airport (SBN)',
    city: 'South Bend',
    country: 'United States',
  },
  TPE: {
    label: 'Chiang Kai Shek Airport (TPE)',
    city: 'Taipei',
    country: 'Taiwan',
  },
  ILE: {
    label: 'Killeen Municipal Airport (ILE)',
    city: 'Killeen',
    country: 'United States',
  },
  ZEL: {
    label: 'Bella Bella Airport (ZEL)',
    city: 'Bella Bella',
    country: 'Canada',
  },
  XCN: {
    label: 'Coron (XCN)',
    city: 'Coron',
    country: 'Philippines',
  },
  ZEM: {
    label: 'East Main Airport (ZEM)',
    city: 'East Main',
    country: 'Canada',
  },
  KSA: {
    label: 'Kosrae Airport (KSA)',
    city: 'Kosrae',
    country: 'Caroline Islands',
  },
  LSB: {
    label: 'Lordsburg (LSB)',
    city: 'Lordsburg',
    country: 'United States',
  },
  DIE: {
    label: 'Antsiranana/Arrachart Airport (DIE)',
    city: 'Antsiranana',
    country: 'Madagascar',
  },
  PMR: {
    label: 'Palmerston North (PMR)',
    city: 'Palmerston North',
    country: 'New Zealand',
  },
  YKQ: {
    label: 'Waskaganish Airport (YKQ)',
    city: 'Waskaganish',
    country: 'Canada',
  },
  AKX: {
    label: 'Aktyubinsk Airport (AKX)',
    city: 'Aktyubinsk',
    country: 'Kazakhstan',
  },
  ULG: {
    label: 'Ulgit Airport (ULG)',
    city: 'Ulgit',
    country: 'Mongolia',
  },
  OUA: {
    label: 'Ouagadougou (OUA)',
    city: 'Ouagadougou',
    country: 'Burkina Faso',
  },
  MGA: {
    label: 'Augusto C Sandino Airport (MGA)',
    city: 'Managua',
    country: 'Nicaragua',
  },
  TIS: {
    label: 'Thursday Island Airport (TIS)',
    city: 'Thursday Island',
    country: 'Australia',
  },
  KBL: {
    label: 'Khwaja Rawash (KBL)',
    city: 'Kabul',
    country: 'Afghanistan',
  },
  AEY: {
    label: 'Akureyri (AEY)',
    city: 'Akureyri',
    country: 'Iceland',
  },
  RXS: {
    label: 'Roxas City Airport (RXS)',
    city: 'Roxas City',
    country: 'Philippines',
  },
  UUS: {
    label: 'Yuzhno-Sakhalinsk (UUS)',
    city: 'Yuzhno-Sakhalinsk',
    country: 'Russia',
  },
  ZCL: {
    label: 'La Calera Airport (ZCL)',
    city: 'Zacatecas',
    country: 'Mexico',
  },
  SPR: {
    label: 'San Pedro (SPR)',
    city: 'San Pedro',
    country: 'Belize',
  },
  WHT: {
    label: 'Wharton Mncpl (WHT)',
    city: 'Wharton',
    country: 'United States',
  },
  AVX: {
    label: 'Avalon Bay (AVX)',
    city: 'Catalina Island',
    country: 'United States',
  },
  WAW: {
    label: 'Frederic Chopin (WAW)',
    city: 'Warsaw',
    country: 'Poland',
  },
  HLF: {
    label: 'Hultsfred (HLF)',
    city: 'Hultsfred',
    country: 'Sweden',
  },
  YPW: {
    label: 'Powell River (YPW)',
    city: 'Powell River',
    country: 'Canada',
  },
  LXV: {
    label: 'Lake County (LXV)',
    city: 'Leadville',
    country: 'United States',
  },
  RMF: {
    label: 'Marsa Alam (RMF)',
    city: 'Marsa Alam',
    country: 'Egypt',
  },
  LRR: {
    label: 'Lar Airport (LRR)',
    city: 'Lar',
    country: 'Iran',
  },
  CRP: {
    label: 'Corpus Christi (CRP)',
    city: 'Corpus Christi',
    country: 'United States',
  },
  PBH: {
    label: 'Paro (PBH)',
    city: 'Paro',
    country: 'Bhutan',
  },
  HKY: {
    label: 'Hickory Airport (HKY)',
    city: 'Hickory',
    country: 'United States',
  },
  NKG: {
    label: 'Nanjing (NKG)',
    city: 'Nanjing',
    country: 'China',
  },
  BIE: {
    label: 'Beatrice Municipal (BIE)',
    city: 'Beatrice',
    country: 'United States',
  },
  BJX: {
    label: 'Del Bajio Airport (BJX)',
    city: 'Leon',
    country: 'Mexico',
  },
  ZTM: {
    label: 'Shamattawa Airport (ZTM)',
    city: 'Shamattawa',
    country: 'Canada',
  },
  BDT: {
    label: 'Gbadolite Airport (BDT)',
    city: 'Gbadolite',
    country: 'Democratic Republic of Congo',
  },
  YYY: {
    label: 'Mont Joli Airport (YYY)',
    city: 'Mont-Joli',
    country: 'Canada',
  },
  FEZ: {
    label: 'Sais (FEZ)',
    city: 'Fez',
    country: 'Morocco',
  },
  POP: {
    label: 'La Union Airport (POP)',
    city: 'Puerto Plata',
    country: 'Dominican Republic',
  },
  YSB: {
    label: 'Sudbury Airport (YSB)',
    city: 'Sudbury',
    country: 'Canada',
  },
  VXC: {
    label: 'Lichinga Airport (VXC)',
    city: 'Lichinga',
    country: 'Mozambique',
  },
  YWH: {
    label: 'Inner Harbour (YWH)',
    city: 'Victoria',
    country: 'Canada',
  },
  EXT: {
    label: 'Exeter (EXT)',
    city: 'Exeter',
    country: 'United Kingdom',
  },
  ULN: {
    label: 'Buyant Uhaa (ULN)',
    city: 'Ulaanbaatar',
    country: 'Mongolia',
  },
  RZZ: {
    label: 'Halifax County (RZZ)',
    city: 'Roanoke Rapids',
    country: 'United States',
  },
  GDX: {
    label: 'Magadan Airport (GDX)',
    city: 'Magadan',
    country: 'Russia',
  },
  MEO: {
    label: 'Dare Cty Regl (MEO)',
    city: 'Manteo',
    country: 'United States',
  },
  WMK: {
    label: 'Meyers Chuck Sea Plane Base (WMK)',
    city: 'Meyers Chuck',
    country: 'United States',
  },
  OCC: {
    label: 'Coca (OCC)',
    city: 'Coca',
    country: 'Ecuador',
  },
  YAM: {
    label: 'Sault Ste Marie Airport (YAM)',
    city: 'Sault Ste Marie',
    country: 'Canada',
  },
  GCI: {
    label: 'Guernsey (GCI)',
    city: 'Guernsey',
    country: 'United Kingdom',
  },
  KRK: {
    label: 'John Paul II Balice (KRK)',
    city: 'Krakow',
    country: 'Poland',
  },
  XUZ: {
    label: 'Xuzhou (XUZ)',
    city: 'Xuzhou',
    country: 'China',
  },
  BKC: {
    label: 'Buckland Airport (BKC)',
    city: 'Buckland',
    country: 'United States',
  },
  MOT: {
    label: 'Minot International Airport (MOT)',
    city: 'Minot',
    country: 'United States',
  },
  MXZ: {
    label: 'Mei Xian Airport (MXZ)',
    city: 'Mei Xian',
    country: 'China',
  },
  LRE: {
    label: 'Longreach (LRE)',
    city: 'Longreach',
    country: 'Australia',
  },
  ARI: {
    label: 'Chacalluta (ARI)',
    city: 'Arica',
    country: 'Chile and Easter Island',
  },
  YFC: {
    label: 'Fredericton Airport (YFC)',
    city: 'Fredericton',
    country: 'Canada',
  },
  NTQ: {
    label: 'Noto Airport (NTQ)',
    city: 'Wajima',
    country: 'Japan',
  },
  RAP: {
    label: 'Rapid City Regional Airport (RAP)',
    city: 'Rapid City',
    country: 'United States',
  },
  BZV: {
    label: 'Maya Maya (BZV)',
    city: 'Brazzaville',
    country: 'Republic of Congo',
  },
  DRE: {
    label: 'Drummond Island Mncp (DRE)',
    city: 'Drummond Island',
    country: 'United States',
  },
  DOH: {
    label: 'Doha Intl (DOH)',
    city: 'Doha',
    country: 'Qatar',
  },
  BCA: {
    label: 'Baracua (BCA)',
    city: 'Baracoa',
    country: 'Cuba',
  },
  WYS: {
    label: 'Yellowstone (WYS)',
    city: 'West Yellowstone',
    country: 'United States',
  },
  PPG: {
    label: 'Pago Pago (PPG)',
    city: 'Pago Pago',
    country: 'American Samoa',
  },
  MFK: {
    label: 'Matsu Airport (MFK)',
    city: 'Matsu',
    country: 'Taiwan',
  },
  NIB: {
    label: 'Nikolai Airport (NIB)',
    city: 'Nikolai',
    country: 'United States',
  },
  GOO: {
    label: 'Goondiwindi (GOO)',
    city: 'Goondiwindi',
    country: 'Australia',
  },
  PFO: {
    label: 'Paphos Intl (PFO)',
    city: 'Paphos',
    country: 'Cyprus',
  },
  NST: {
    label: 'Nakhon Si Thammarat Airport (NST)',
    city: 'Nakhon Si Thammarat',
    country: 'Thailand',
  },
  FNC: {
    label: 'Funchal (FNC)',
    city: 'Funchal',
    country: 'Portugal',
  },
  JHS: {
    label: 'Sisimiut (JHS)',
    city: 'Sisimiut',
    country: 'Greenland',
  },
  MLY: {
    label: 'Manley Hot Springs (MLY)',
    city: 'Manley Hot Springs',
    country: 'United States',
  },
  SXL: {
    label: 'Sligo/Collooney (SXL)',
    city: 'Sligo',
    country: 'Ireland',
  },
  PBF: {
    label: 'Grider Field (PBF)',
    city: 'Pine Bluff',
    country: 'United States',
  },
  PCM: {
    label: 'Playa del Carmen (PCM)',
    city: 'Playa del Carmen',
    country: 'Mexico',
  },
  KYZ: {
    label: 'Kyzyl Airport (KYZ)',
    city: 'Kyzyl',
    country: 'Russia',
  },
  YZU: {
    label: 'Whitecourt (YZU)',
    city: 'Whitecourt',
    country: 'Canada',
  },
  KNH: {
    label: 'Shang-Yi Airport (KNH)',
    city: 'Kinmen',
    country: 'Taiwan',
  },
  LSY: {
    label: 'Lismore (LSY)',
    city: 'Lismore',
    country: 'Australia',
  },
  SHL: {
    label: 'Shillong Airport (SHL)',
    city: 'Shillong',
    country: 'India',
  },
  KRB: {
    label: 'Karumba Airport (KRB)',
    city: 'Karumba',
    country: 'Australia',
  },
  YOH: {
    label: 'Oxford House Airport (YOH)',
    city: 'Oxford House',
    country: 'Canada',
  },
  BHU: {
    label: 'Bhavnagar Airport (BHU)',
    city: 'Bhavnagar',
    country: 'India',
  },
  MEE: {
    label: 'Mare Airport (MEE)',
    city: 'Mare',
    country: 'New Caledonia',
  },
  LST: {
    label: 'Launceston (LST)',
    city: 'Launceston',
    country: 'Australia',
  },
  FBM: {
    label: 'Luano (FBM)',
    city: 'Lubumbashi',
    country: 'Democratic Republic of Congo',
  },
  KSO: {
    label: 'Aristoteles (KSO)',
    city: 'Kastoria',
    country: 'Greece',
  },
  LOZ: {
    label: 'Corbin-London (LOZ)',
    city: 'London',
    country: 'United States',
  },
  TSA: {
    label: 'Sung Shan (TSA)',
    city: 'Taipei',
    country: 'Taiwan',
  },
  ART: {
    label: 'Watertown (ART)',
    city: 'Watertown',
    country: 'United States',
  },
  DRT: {
    label: 'Del Rio Intl (DRT)',
    city: 'Del Rio',
    country: 'United States',
  },
  IXR: {
    label: 'Ranchi (IXR)',
    city: 'Ranchi',
    country: 'India',
  },
  RSA: {
    label: 'Santa Rosa Airport (RSA)',
    city: 'Santa Rosa',
    country: 'Argentina',
  },
  JDO: {
    label: 'Regional Do Cariri Airport (JDO)',
    city: 'Juazeiro Do Norte',
    country: 'Brazil',
  },
  ASE: {
    label: 'Aspen Airport (ASE)',
    city: 'Aspen',
    country: 'United States',
  },
  FNB: {
    label: 'Neubrandenburg (FNB)',
    city: 'Neubrandenburg',
    country: 'Germany',
  },
  REU: {
    label: 'Reus (REU)',
    city: 'Reus',
    country: 'Spain',
  },
  ABA: {
    label: 'Abakan Airport (ABA)',
    city: 'Abakan',
    country: 'Russia',
  },
  PKU: {
    label: 'Simpang Tiga (PKU)',
    city: 'Pekanbaru',
    country: 'Indonesia',
  },
  UNN: {
    label: 'Ranong (UNN)',
    city: 'Ranong',
    country: 'Thailand',
  },
  ENV: {
    label: 'Wendover Mncpl (ENV)',
    city: 'Wendover',
    country: 'United States',
  },
  GDQ: {
    label: 'Gondar (GDQ)',
    city: 'Gondar',
    country: 'Ethiopia',
  },
  TKE: {
    label: 'Tenakee Springs Sea Plane Base (TKE)',
    city: 'Tenakee Springs',
    country: 'United States',
  },
  ADB: {
    label: 'Adnan Menderes (ADB)',
    city: 'Izmir',
    country: 'Turkey',
  },
  ELZ: {
    label: 'Wellsville Mncpl (ELZ)',
    city: 'Wellsville',
    country: 'United States',
  },
  JNX: {
    label: 'Naxos (JNX)',
    city: 'Naxos',
    country: 'Greece',
  },
  LVD: {
    label: 'Lime Village Airport (LVD)',
    city: 'Lime Village',
    country: 'United States',
  },
  MOU: {
    label: 'Mountain Village Airport (MOU)',
    city: 'Mountain Village',
    country: 'United States',
  },
  MLN: {
    label: 'Melilla Airport (MLN)',
    city: 'Melilla',
    country: 'Spain',
  },
  KUH: {
    label: 'Kushiro (KUH)',
    city: 'Kushiro',
    country: 'Japan',
  },
  MFA: {
    label: 'Mafia (MFA)',
    city: 'Mafia Island',
    country: 'Tanzania',
  },
  OMR: {
    label: 'Oradea (OMR)',
    city: 'Oradea',
    country: 'Romania',
  },
  RAI: {
    label: 'Francisco Mendes (RAI)',
    city: 'Praia',
    country: 'Cape Verde',
  },
  ZOS: {
    label: 'Osorno (ZOS)',
    city: 'Osorno',
    country: 'Chile and Easter Island',
  },
  MSQ: {
    label: 'Minsk 2 Intl (MSQ)',
    city: 'Minsk',
    country: 'Belarus',
  },
  FFM: {
    label: 'Fergus Falls Mncpl (FFM)',
    city: 'Fergus Falls',
    country: 'United States',
  },
  VLN: {
    label: 'Valencia Airport (VLN)',
    city: 'Valencia',
    country: 'Venezuela',
  },
  CIP: {
    label: 'Chipata Airport (CIP)',
    city: 'Chipata',
    country: 'Zambia',
  },
  NEC: {
    label: 'Necochea Airport (NEC)',
    city: 'Necochea',
    country: 'Argentina',
  },
  UIB: {
    label: 'Quibdo Airport (UIB)',
    city: 'Quibdo',
    country: 'Colombia',
  },
  NDB: {
    label: 'Nouadhibou Airport (NDB)',
    city: 'Nouadhibou',
    country: 'Mauritania',
  },
  LYS: {
    label: 'Lyon Satolas Airport (LYS)',
    city: 'Lyon',
    country: 'France',
  },
  KSU: {
    label: 'Kvernberget (KSU)',
    city: 'Kristiansund',
    country: 'Norway',
  },
  YRV: {
    label: 'Revelstoke (YRV)',
    city: 'Revelstoke',
    country: 'Canada',
  },
  KAX: {
    label: 'Kalbarri (KAX)',
    city: 'Kalbarri',
    country: 'Australia',
  },
  UBJ: {
    label: 'Ube (UBJ)',
    city: 'Ube',
    country: 'Japan',
  },
  BRI: {
    label: 'Palese (BRI)',
    city: 'Bari',
    country: 'Italy',
  },
  YXN: {
    label: 'Whale Cove Airport (YXN)',
    city: 'Whale Cove',
    country: 'Canada',
  },
  ABL: {
    label: 'Ambler Airport (ABL)',
    city: 'Ambler',
    country: 'United States',
  },
  VTE: {
    label: 'Wattay (VTE)',
    city: 'Vientiane',
    country: 'Laos',
  },
  MAG: {
    label: 'Madang (MAG)',
    city: 'Madang',
    country: 'Papua New Guinea',
  },
  PVU: {
    label: 'Provo Mncpl (PVU)',
    city: 'Provo',
    country: 'United States',
  },
  HLM: {
    label: 'Park Township (HLM)',
    city: 'Holland',
    country: 'United States',
  },
  YNL: {
    label: 'Points North Landing Airport (YNL)',
    city: 'Points North Landing',
    country: 'Canada',
  },
  OLF: {
    label: 'Wolf Point Intl (OLF)',
    city: 'Wolf Point',
    country: 'United States',
  },
  ILN: {
    label: 'Clinton Field (ILN)',
    city: 'Wilmington',
    country: 'United States',
  },
  MIJ: {
    label: 'Mili Island Airport (MIJ)',
    city: 'Mili Island',
    country: 'Marshall Islands',
  },
  YEH: {
    label: 'Asaloyeh Airport (YEH)',
    city: 'Asaloyeh',
    country: 'Iran',
  },
  TCP: {
    label: 'Taba Intl (TCP)',
    city: 'Taba',
    country: 'Egypt',
  },
  IKT: {
    label: 'Irkutsk (IKT)',
    city: 'Irkutsk',
    country: 'Russia',
  },
  IXL: {
    label: 'Leh Airport (IXL)',
    city: 'Leh',
    country: 'India',
  },
  SLX: {
    label: 'Salt Cay Mncpl (SLX)',
    city: 'Salt Cay',
    country: 'Turks and Caicos',
  },
  MGZ: {
    label: 'Myeik Airport (MGZ)',
    city: 'Myeik',
    country: 'Myanmar (Burma)',
  },
  VRB: {
    label: 'Vero Beach Mncpl (VRB)',
    city: 'Vero Beach',
    country: 'United States',
  },
  BET: {
    label: 'Bethel Airport (BET)',
    city: 'Bethel',
    country: 'United States',
  },
  SVJ: {
    label: 'Helle (SVJ)',
    city: 'Svolvaer',
    country: 'Norway',
  },
  KMA: {
    label: 'Kerema Airport (KMA)',
    city: 'Kerema',
    country: 'Papua New Guinea',
  },
  SRP: {
    label: 'Stord (SRP)',
    city: 'Stord',
    country: 'Norway',
  },
  HUH: {
    label: 'Huahine (HUH)',
    city: 'Huahine',
    country: 'French Polynesia and Tahiti',
  },
  AXM: {
    label: 'El Eden Airport (AXM)',
    city: 'Armenia',
    country: 'Colombia',
  },
  MKM: {
    label: 'Mukah Airport (MKM)',
    city: 'Mukah',
    country: 'Malaysia',
  },
  CAX: {
    label: 'Carlisle (CAX)',
    city: 'Carlisle',
    country: 'United Kingdom',
  },
  MMJ: {
    label: 'Matsumoto (MMJ)',
    city: 'Matsumoto',
    country: 'Japan',
  },
  OKJ: {
    label: 'Okayama (OKJ)',
    city: 'Okayama',
    country: 'Japan',
  },
  LRH: {
    label: 'Laleu (LRH)',
    city: 'La Rochelle',
    country: 'France',
  },
  GIG: {
    label: 'Internacional Airport (GIG)',
    city: 'Rio de Janeiro',
    country: 'Brazil',
  },
  YFX: {
    label: 'Fox Harbour Airport (YFX)',
    city: 'Fox Harbour',
    country: 'Canada',
  },
  GUF: {
    label: 'Jack Edwards (GUF)',
    city: 'Gulf Shores',
    country: 'United States',
  },
  BLQ: {
    label: 'Guglielmo Marconi (BLQ)',
    city: 'Bologna',
    country: 'Italy',
  },
  NAG: {
    label: 'Sonegaon Airport (NAG)',
    city: 'Nagpur',
    country: 'India',
  },
  HMO: {
    label: 'Gen Pesqueira Garcia Airport (HMO)',
    city: 'Hermosillo',
    country: 'Mexico',
  },
  LXR: {
    label: 'Luxor (LXR)',
    city: 'Luxor',
    country: 'Egypt',
  },
  PFQ: {
    label: 'Parsabad Airport (PFQ)',
    city: 'Parsabad',
    country: 'Iran',
  },
  YSO: {
    label: 'Postville Airport (YSO)',
    city: 'Postville',
    country: 'Canada',
  },
  CKG: {
    label: 'Chongqing (CKG)',
    city: 'Chongqing',
    country: 'China',
  },
  MPM: {
    label: 'Maputo Intl (MPM)',
    city: 'Maputo',
    country: 'Mozambique',
  },
  ISP: {
    label: 'Long Island MacArthur (ISP)',
    city: 'Islip',
    country: 'United States',
  },
  CNC: {
    label: 'Coconut Island Airport (CNC)',
    city: 'Coconut Island',
    country: 'Australia',
  },
  CVQ: {
    label: 'Carnarvon Airport (CVQ)',
    city: 'Carnarvon',
    country: 'Australia',
  },
  HFE: {
    label: 'Hefei (HFE)',
    city: 'Hefei',
    country: 'China',
  },
  MPH: {
    label: 'Malay Airport (MPH)',
    city: 'Caticlan',
    country: 'Philippines',
  },
  YFJ: {
    label: 'Snare Lake Airport (YFJ)',
    city: 'Snare Lake',
    country: 'Canada',
  },
  LTQ: {
    label: 'Le Touquet (LTQ)',
    city: 'Le Touquet',
    country: 'France',
  },
  INV: {
    label: 'Inverness (INV)',
    city: 'Inverness',
    country: 'United Kingdom',
  },
  KOP: {
    label: 'Nakhon Phanom Airport (KOP)',
    city: 'Nakhon Phanom',
    country: 'Thailand',
  },
  CUP: {
    label: 'Carupano Airport (CUP)',
    city: 'Carupano',
    country: 'Venezuela',
  },
  TNZ: {
    label: 'Tosontsengel Airport (TNZ)',
    city: 'Tosontsengel',
    country: 'Mongolia',
  },
  HLP: {
    label: 'Halim Perdana Kusuma (HLP)',
    city: 'Jakarta',
    country: 'Indonesia',
  },
  TOV: {
    label: 'West End SPB (TOV)',
    city: 'Tortola',
    country: 'British Virgin Islands',
  },
  YSM: {
    label: 'Fort Smith Airport (YSM)',
    city: 'Ft Smith',
    country: 'Canada',
  },
  TCQ: {
    label: 'Tacna Airport (TCQ)',
    city: 'Tacna',
    country: 'Peru',
  },
  PDA: {
    label: 'Puerto Inirida Airport (PDA)',
    city: 'Puerto Inirida',
    country: 'Colombia',
  },
  DRB: {
    label: 'Derby (DRB)',
    city: 'Derby',
    country: 'Australia',
  },
  ANB: {
    label: 'Anniston Cty (ANB)',
    city: 'Anniston',
    country: 'United States',
  },
  LOV: {
    label: 'Monclova (LOV)',
    city: 'Monclova',
    country: 'Mexico',
  },
  LUR: {
    label: 'Cape Lisburne Airport (LUR)',
    city: 'Cape Lisburne',
    country: 'United States',
  },
  ALV: {
    label: 'Andorra La Vella (ALV)',
    city: 'Andorra La Vella',
    country: 'Andorra',
  },
  LAR: {
    label: 'Laramie Regional Airport (LAR)',
    city: 'Laramie',
    country: 'United States',
  },
  TJM: {
    label: 'Tyumen (TJM)',
    city: 'Tyumen',
    country: 'Russia',
  },
  VIY: {
    label: 'Villa Coublay (VIY)',
    city: 'Velizy-Villacoublay',
    country: 'France',
  },
  SYI: {
    label: 'Bomar Field (SYI)',
    city: 'Shelbyville',
    country: 'United States',
  },
  MYW: {
    label: 'Mtwara (MYW)',
    city: 'Mtwara',
    country: 'Tanzania',
  },
  HYG: {
    label: 'Hydaburg Sea Plane Base (HYG)',
    city: 'Hydaburg',
    country: 'United States',
  },
  LDN: {
    label: 'Lamidanda Airport (LDN)',
    city: 'Lamidanda',
    country: 'Nepal',
  },
  BRE: {
    label: 'Bremen (BRE)',
    city: 'Bremen',
    country: 'Germany',
  },
  EWB: {
    label: 'New Bedford Mncpl (EWB)',
    city: 'New Bedford',
    country: 'United States',
  },
  CLY: {
    label: 'Ste Catherine (CLY)',
    city: 'Calvi',
    country: 'France',
  },
  POG: {
    label: 'Port Gentil (POG)',
    city: 'Port Gentil',
    country: 'Gabon',
  },
  MLU: {
    label: 'Monroe Municipal Airport (MLU)',
    city: 'Monroe',
    country: 'United States',
  },
  SHP: {
    label: 'Qinhuangdao (SHP)',
    city: 'Qinhuangdao',
    country: 'China',
  },
  WDH: {
    label: 'Hosea Kutako Intl (WDH)',
    city: 'Windhoek',
    country: 'Namibia',
  },
  SCZ: {
    label: 'Santa Cruz Island Airport (SCZ)',
    city: 'Santa Cruz Island',
    country: 'Solomon Islands',
  },
  YHY: {
    label: 'Hay River (YHY)',
    city: 'Hay River',
    country: 'Canada',
  },
  TLH: {
    label: 'Tallahassee Municipal Airport (TLH)',
    city: 'Tallahassee',
    country: 'United States',
  },
  ATK: {
    label: 'Atqasuk Airport (ATK)',
    city: 'Atqasuk',
    country: 'United States',
  },
  CRK: {
    label: 'Clark Field (CRK)',
    city: 'Luzon Island',
    country: 'Philippines',
  },
  TBS: {
    label: 'Novo Alexeyevka (TBS)',
    city: 'Tbilisi',
    country: 'Georgia',
  },
  CRX: {
    label: 'Roscoe Turner Field (CRX)',
    city: 'Corinth',
    country: 'United States',
  },
  KAL: {
    label: 'Kaltag Airport (KAL)',
    city: 'Kaltag',
    country: 'United States',
  },
  HTN: {
    label: 'Hotan Airport (HTN)',
    city: 'Hotan',
    country: 'China',
  },
  ZQW: {
    label: 'Zweibruecken Off-Line Point (ZQW)',
    city: 'Saarbruecken',
    country: 'Germany',
  },
  MSR: {
    label: 'Mus Airport (MSR)',
    city: 'Mus',
    country: 'Turkey',
  },
  SHY: {
    label: 'Shinyanga Airport (SHY)',
    city: 'Shinyanga',
    country: 'Tanzania',
  },
  YVR: {
    label: 'Vancouver (YVR)',
    city: 'Vancouver',
    country: 'Canada',
  },
  LPA: {
    label: 'Gran Canaria (LPA)',
    city: 'Las Palmas',
    country: 'Spain',
  },
  VNC: {
    label: 'Venice Mncpl (VNC)',
    city: 'Venice',
    country: 'United States',
  },
  CQF: {
    label: 'Calais (CQF)',
    city: 'Calais',
    country: 'France',
  },
  SUR: {
    label: 'Summer Beaver Airport (SUR)',
    city: 'Summer Beaver',
    country: 'Canada',
  },
  ASV: {
    label: 'Amboseli (ASV)',
    city: 'Amboseli',
    country: 'Kenya',
  },
  ANW: {
    label: 'Ainsworth (ANW)',
    city: 'Ainsworth',
    country: 'United States',
  },
  BGR: {
    label: 'Bangor International Airport (BGR)',
    city: 'Bangor',
    country: 'United States',
  },
  MEG: {
    label: 'Malange Airport (MEG)',
    city: 'Malange',
    country: 'Angola',
  },
  ZNE: {
    label: 'Newman (ZNE)',
    city: 'Newman',
    country: 'Australia',
  },
  RAK: {
    label: 'Menara (RAK)',
    city: 'Marrakech',
    country: 'Morocco',
  },
  GPS: {
    label: 'Baltra Airport (GPS)',
    city: 'Galapagos Islands',
    country: 'Ecuador',
  },
  JKL: {
    label: 'Kalymnos Airport (JKL)',
    city: 'Kalymnos Island',
    country: 'Greece',
  },
  ANM: {
    label: 'Antsirabato (ANM)',
    city: 'Antalaha',
    country: 'Madagascar',
  },
  MEV: {
    label: 'Douglas County (MEV)',
    city: 'Minden',
    country: 'United States',
  },
  BVE: {
    label: 'Laroche (BVE)',
    city: 'Brive-la-Gaillarde',
    country: 'France',
  },
  HOQ: {
    label: 'Hof-Pirk (HOQ)',
    city: 'Hof',
    country: 'Germany',
  },
  KTB: {
    label: 'Thorne Bay Airport (KTB)',
    city: 'Thorne Bay',
    country: 'United States',
  },
  YTJ: {
    label: 'Terrace Bay (YTJ)',
    city: 'Terrace Bay',
    country: 'Canada',
  },
  BJM: {
    label: 'Bujumbura Intl (BJM)',
    city: 'Bujumbura',
    country: 'Burundi',
  },
  BTV: {
    label: 'Burlington (BTV)',
    city: 'Burlington',
    country: 'VT',
  },
  TIJ: {
    label: 'Rodriguez (TIJ)',
    city: 'Tijuana',
    country: 'Mexico',
  },
  YPS: {
    label: 'Port Hawkesbury (YPS)',
    city: 'Port Hawkesbury',
    country: 'Canada',
  },
  FSC: {
    label: 'Sud Corse (FSC)',
    city: 'Figari',
    country: 'France',
  },
  BSO: {
    label: 'Basco Airport (BSO)',
    city: 'Basco',
    country: 'Philippines',
  },
  HAM: {
    label: 'Hamburg (HAM)',
    city: 'Hamburg',
    country: 'Germany',
  },
  MSV: {
    label: 'Sullivan Cty Intl (MSV)',
    city: 'Monticello',
    country: 'United States',
  },
  PQM: {
    label: 'Palenque Mncpl (PQM)',
    city: 'Palenque',
    country: 'Mexico',
  },
  YNT: {
    label: 'Laishan (YNT)',
    city: 'Yantai',
    country: 'China',
  },
  YAG: {
    label: 'Fort Frances Mncpl (YAG)',
    city: 'Fort Frances',
    country: 'Canada',
  },
  FRW: {
    label: 'Francistown (FRW)',
    city: 'Francistown',
    country: 'Botswana',
  },
  NCS: {
    label: 'Newcastle (NCS)',
    city: 'Newcastle',
    country: 'South Africa',
  },
  TIZ: {
    label: 'Tari Airport (TIZ)',
    city: 'Tari',
    country: 'Papua New Guinea',
  },
  MRD: {
    label: 'Alberto Carnevalli (MRD)',
    city: 'Merida',
    country: 'Venezuela',
  },
  YYU: {
    label: 'Kapuskasing (YYU)',
    city: 'Kapuskasing',
    country: 'Canada',
  },
  GCC: {
    label: 'Gillette-Campbell County Airport (GCC)',
    city: 'Gillette',
    country: 'United States',
  },
  GYS: {
    label: 'Guang Yuan Airport (GYS)',
    city: 'Guang Yuan',
    country: 'China',
  },
  RUH: {
    label: 'King Khaled Intl (RUH)',
    city: 'Riyadh',
    country: 'Saudi Arabia',
  },
  DND: {
    label: 'Dundee (DND)',
    city: 'Dundee',
    country: 'United Kingdom',
  },
  AAH: {
    label: 'Maastricht (AAH)',
    city: 'Aachen',
    country: 'Germany',
  },
  HVN: {
    label: 'New Haven Airport (HVN)',
    city: 'New Haven',
    country: 'United States',
  },
  LER: {
    label: 'Leinster Airport (LER)',
    city: 'Leinster',
    country: 'Australia',
  },
  ROP: {
    label: 'Rota (ROP)',
    city: 'Rota',
    country: 'Northern Mariana Islands',
  },
  HDN: {
    label: 'Hayden (HDN)',
    city: 'Hayden',
    country: 'United States',
  },
  TUN: {
    label: 'Carthage (TUN)',
    city: 'Tunis',
    country: 'Tunisia',
  },
  SJZ: {
    label: 'Sao Jorge Island Airport (SJZ)',
    city: 'Sao Jorge Island',
    country: 'Portugal',
  },
  WSG: {
    label: 'Washington County (WSG)',
    city: 'Washington',
    country: 'United States',
  },
  TXG: {
    label: 'Taichung (TXG)',
    city: 'Taichung',
    country: 'Taiwan',
  },
  WUN: {
    label: 'Wiluna Airport (WUN)',
    city: 'Wiluna',
    country: 'Australia',
  },
  GHA: {
    label: 'Noumerate (GHA)',
    city: 'Ghardaia',
    country: 'Algeria',
  },
  PNH: {
    label: 'Pochentong (PNH)',
    city: 'Phnom Penh',
    country: 'Cambodia',
  },
  KBC: {
    label: 'Birch Creek Airport (KBC)',
    city: 'Birch Creek',
    country: 'United States',
  },
  AJA: {
    label: 'Campo Dell Oro (AJA)',
    city: 'Ajaccio',
    country: 'France',
  },
  YCK: {
    label: 'Colville Lake Airport (YCK)',
    city: 'Colville Lake',
    country: 'Canada',
  },
  GUH: {
    label: 'Gunnedah (GUH)',
    city: 'Gunnedah',
    country: 'Australia',
  },
  PNK: {
    label: 'Supadio Airport (PNK)',
    city: 'Pontianak',
    country: 'Indonesia',
  },
  TZN: {
    label: 'Congo Town (TZN)',
    city: 'South Andros',
    country: 'Bahamas',
  },
  RIG: {
    label: 'Rio Grande (RIG)',
    city: 'Rio Grande',
    country: 'Brazil',
  },
  HSM: {
    label: 'Horsham (HSM)',
    city: 'Horsham',
    country: 'Australia',
  },
  THO: {
    label: 'Thorshofn Airport (THO)',
    city: 'Thorshofn',
    country: 'Iceland',
  },
  LIN: {
    label: 'Linate (LIN)',
    city: 'Milan',
    country: 'Italy',
  },
  TUD: {
    label: 'Tambacounda Airport (TUD)',
    city: 'Tambacounda',
    country: 'Senegal',
  },
  ADA: {
    label: 'Adana (ADA)',
    city: 'Adana',
    country: 'Turkey',
  },
  LOE: {
    label: 'Loei Airport (LOE)',
    city: 'Loei',
    country: 'Thailand',
  },
  YZT: {
    label: 'Port Hardy (YZT)',
    city: 'Port Hardy',
    country: 'Canada',
  },
  PZU: {
    label: 'Port Sudan Airport (PZU)',
    city: 'Port Sudan',
    country: 'Sudan',
  },
  FAJ: {
    label: 'Puerto Real Seaplane (FAJ)',
    city: 'Fajardo',
    country: 'Puerto Rico',
  },
  KBU: {
    label: 'Kotabaru Airport (KBU)',
    city: 'Kotabaru',
    country: 'Indonesia',
  },
  MAZ: {
    label: 'Eugenio M De Hostos Airport (MAZ)',
    city: 'Mayaguez',
    country: 'Puerto Rico',
  },
  CUQ: {
    label: 'Coen Airport (CUQ)',
    city: 'Coen',
    country: 'Australia',
  },
  SPU: {
    label: 'Split (SPU)',
    city: 'Split',
    country: 'Croatia',
  },
  LMI: {
    label: 'Lumi Airport (LMI)',
    city: 'Lumi',
    country: 'Papua New Guinea',
  },
  CBG: {
    label: 'Cambridge (CBG)',
    city: 'Cambridge',
    country: 'United States',
  },
  CHO: {
    label: 'Albemarle Airport (CHO)',
    city: 'Charlottesville',
    country: 'United States',
  },
  JTR: {
    label: 'Santorini (JTR)',
    city: 'Thira',
    country: 'Greece',
  },
  AUO: {
    label: 'Auburn-Opelika (AUO)',
    city: 'Auburn',
    country: 'United States',
  },
  HAS: {
    label: 'Hail Airport (HAS)',
    city: 'Hail',
    country: 'Saudi Arabia',
  },
  IBL: {
    label: 'Indigo Bay Lodge (IBL)',
    city: 'Indigo Bay Lodge',
    country: 'Mozambique',
  },
  YGT: {
    label: 'Igloolik Mncpl (YGT)',
    city: 'Igloolik',
    country: 'Canada',
  },
  TOS: {
    label: 'Tromso/Langnes (TOS)',
    city: 'Tromso',
    country: 'Norway',
  },
  BKS: {
    label: 'Padangkemiling Airport (BKS)',
    city: 'Bengkulu',
    country: 'Indonesia',
  },
  LLA: {
    label: 'Kallax (LLA)',
    city: 'Lulea',
    country: 'Sweden',
  },
  LNS: {
    label: 'Lancaster Airport (LNS)',
    city: 'Lancaster',
    country: 'United States',
  },
  CTM: {
    label: 'Chetumal (CTM)',
    city: 'Chetumal',
    country: 'Mexico',
  },
  LFT: {
    label: 'Lafayette Regional Airport (LFT)',
    city: 'Lafayette',
    country: 'LA',
  },
  AKU: {
    label: 'Aksu Airport (AKU)',
    city: 'Aksu',
    country: 'China',
  },
  VDC: {
    label: 'Vitoria Da Conquista Airport (VDC)',
    city: 'Vitoria da Conquista',
    country: 'Brazil',
  },
  PUV: {
    label: 'Poum (PUV)',
    city: 'Poum',
    country: 'New Caledonia',
  },
  SPK: {
    label: 'All Airports (SPK)',
    city: 'Sapporo',
    country: 'Japan',
  },
  MST: {
    label: 'Maastricht (MST)',
    city: 'Maastricht',
    country: 'Netherlands',
  },
  ROK: {
    label: 'Rockhampton (ROK)',
    city: 'Rockhampton',
    country: 'Australia',
  },
  MJB: {
    label: 'Mejit Island Airport (MJB)',
    city: 'Mejit Island',
    country: 'Marshall Islands',
  },
  JED: {
    label: 'King Abdulaziz Intl (JED)',
    city: 'Jeddah',
    country: 'Saudi Arabia',
  },
  TST: {
    label: 'Trang (TST)',
    city: 'Trang',
    country: 'Thailand',
  },
  HON: {
    label: 'Howes (HON)',
    city: 'Huron',
    country: 'United States',
  },
  BLH: {
    label: 'Blythe (BLH)',
    city: 'Blythe',
    country: 'United States',
  },
  KMC: {
    label: 'King Khalid Military City Airport (KMC)',
    city: 'King Khalid Military City',
    country: 'Saudi Arabia',
  },
  BCN: {
    label: 'Barcelona Airport (BCN)',
    city: 'Barcelona',
    country: 'Spain',
  },
  LKH: {
    label: 'Long Akah Airport (LKH)',
    city: 'Long Akah',
    country: 'Malaysia',
  },
  REX: {
    label: 'Gen Lucio Blanco (REX)',
    city: 'Reynosa',
    country: 'Mexico',
  },
  GMZ: {
    label: 'La Gomera (GMZ)',
    city: 'San Sebastian La Gomera',
    country: 'Spain',
  },
  LGO: {
    label: 'Langeoog (LGO)',
    city: 'Langeoog',
    country: 'Germany',
  },
  IAN: {
    label: 'Bob Barker Memorial Airport (IAN)',
    city: 'Kiana',
    country: 'United States',
  },
  PTK: {
    label: 'Oakland County (PTK)',
    city: 'Pontiac',
    country: 'United States',
  },
  CVJ: {
    label: 'Cuernavaca (CVJ)',
    city: 'Cuernavaca',
    country: 'Mexico',
  },
  CHK: {
    label: 'Chickasha Mncpl (CHK)',
    city: 'Chickasha',
    country: 'United States',
  },
  AFL: {
    label: 'Alta Floresta Airport (AFL)',
    city: 'Alta Floresta',
    country: 'Brazil',
  },
  SME: {
    label: 'Somerset (SME)',
    city: 'Somerset',
    country: 'United States',
  },
  KWI: {
    label: 'Kuwait Intl (KWI)',
    city: 'Kuwait',
    country: 'Kuwait',
  },
  TRS: {
    label: 'Dei Legionari (TRS)',
    city: 'Trieste',
    country: 'Italy',
  },
  APL: {
    label: 'Nampula Airport (APL)',
    city: 'Nampula',
    country: 'Mozambique',
  },
  EPR: {
    label: 'Esperance (EPR)',
    city: 'Esperance',
    country: 'Australia',
  },
  AXA: {
    label: 'Wallblake Airport (AXA)',
    city: 'Anguilla',
    country: 'Anguilla',
  },
  WHK: {
    label: 'Whakatane Airport (WHK)',
    city: 'Whakatane',
    country: 'New Zealand',
  },
  SDP: {
    label: 'Sand Point Municipal Airport (SDP)',
    city: 'Sand Point',
    country: 'United States',
  },
  LLW: {
    label: 'Lilongwe Intl (LLW)',
    city: 'Lilongwe',
    country: 'Malawi',
  },
  WLH: {
    label: 'Walaha Airport (WLH)',
    city: 'Walaha',
    country: 'Vanuatu',
  },
  BEY: {
    label: 'Beirut Intl (BEY)',
    city: 'Beirut',
    country: 'Lebanon',
  },
  ZEU: {
    label: 'Railway Station (ZEU)',
    city: 'Goettingen',
    country: 'Germany',
  },
  ZBL: {
    label: 'Biloela Airport (ZBL)',
    city: 'Biloela',
    country: 'Australia',
  },
  ZAM: {
    label: 'Zamboanga (ZAM)',
    city: 'Zamboanga',
    country: 'Philippines',
  },
  DPE: {
    label: 'Dieppe (DPE)',
    city: 'Dieppe',
    country: 'France',
  },
  MVP: {
    label: 'Mitu Airport (MVP)',
    city: 'Mitu',
    country: 'Colombia',
  },
  ENC: {
    label: 'Essey (ENC)',
    city: 'Nancy',
    country: 'France',
  },
  ZUH: {
    label: 'Zhuhai (ZUH)',
    city: 'Zhuhai',
    country: 'China',
  },
  VLC: {
    label: 'Valencia (VLC)',
    city: 'Valencia',
    country: 'Spain',
  },
  PKC: {
    label: 'Petropavlovsk-Kamcha (PKC)',
    city: 'Petropavlovsk-Kamchatsky',
    country: 'Russia',
  },
  KOE: {
    label: 'Eltari Airport (KOE)',
    city: 'Kupang',
    country: 'Indonesia',
  },
  MAD: {
    label: 'Barajas Airport (MAD)',
    city: 'Madrid',
    country: 'Spain',
  },
  CAN: {
    label: 'Baiyun (CAN)',
    city: 'Guangzhou',
    country: 'China',
  },
  BMP: {
    label: 'Brampton Is (BMP)',
    city: 'Brampton Island',
    country: 'Australia',
  },
  ZBM: {
    label: 'Regional (ZBM)',
    city: 'Bromont',
    country: 'Canada',
  },
  DJG: {
    label: 'Inedbirenne Airport (DJG)',
    city: 'Djanet',
    country: 'Algeria',
  },
  JDZ: {
    label: 'Jingdezhen Airport (JDZ)',
    city: 'Jingdezhen',
    country: 'China',
  },
  MOQ: {
    label: 'Morondava Airport (MOQ)',
    city: 'Morondava',
    country: 'Madagascar',
  },
  GRJ: {
    label: 'George (GRJ)',
    city: 'George',
    country: 'South Africa',
  },
  NRT: {
    label: 'Narita (NRT)',
    city: 'Tokyo',
    country: 'Japan',
  },
  PYH: {
    label: 'Puerto Ayacucho Airport (PYH)',
    city: 'Puerto Ayacucho',
    country: 'Venezuela',
  },
  HIL: {
    label: 'Shillavo Airport (HIL)',
    city: 'Shillavo',
    country: 'Ethiopia',
  },
  SHM: {
    label: 'Shirahama Airport (SHM)',
    city: 'Shirahama',
    country: 'Japan',
  },
  SHT: {
    label: 'Shepparton (SHT)',
    city: 'Shepparton',
    country: 'Australia',
  },
  EDA: {
    label: 'Edna Bay Airport (EDA)',
    city: 'Edna Bay',
    country: 'United States',
  },
  LFW: {
    label: 'Lome (LFW)',
    city: 'Lome',
    country: 'Togo',
  },
  CIU: {
    label: 'Chippewa Cty (CIU)',
    city: 'Sault Ste Marie',
    country: 'United States',
  },
  BFS: {
    label: 'Belfast Intl (BFS)',
    city: 'Belfast',
    country: 'United Kingdom',
  },
  KHI: {
    label: 'Quaid-E-Azam Intl (KHI)',
    city: 'Karachi',
    country: 'Pakistan',
  },
  FDE: {
    label: 'Bringeland (FDE)',
    city: 'Forde',
    country: 'Norway',
  },
  BUS: {
    label: 'Batumi Airport (BUS)',
    city: 'Batumi',
    country: 'Georgia',
  },
  CAS: {
    label: 'Anfa (CAS)',
    city: 'Casablanca',
    country: 'Morocco',
  },
  YYL: {
    label: 'Lynn Lake (YYL)',
    city: 'Lynn Lake',
    country: 'Canada',
  },
  IUE: {
    label: 'Hanan Airport (IUE)',
    city: 'Niue Island',
    country: 'Niue',
  },
  TNP: {
    label: 'Twentynine Palms (TNP)',
    city: 'Twentynine Palms',
    country: 'United States',
  },
  PDL: {
    label: 'Nordela (PDL)',
    city: 'Ponta Delgada',
    country: 'Portugal',
  },
  COJ: {
    label: 'Coonabarabran (COJ)',
    city: 'Coonabarabran',
    country: 'Australia',
  },
  DIU: {
    label: 'Diu Airport (DIU)',
    city: 'Diu',
    country: 'India',
  },
  LTT: {
    label: 'La Mole (LTT)',
    city: 'St Tropez',
    country: 'France',
  },
  SYY: {
    label: 'Stornoway (SYY)',
    city: 'Stornoway',
    country: 'United Kingdom',
  },
  SRV: {
    label: 'Stony River Airport (SRV)',
    city: 'Stony River',
    country: 'United States',
  },
  UTA: {
    label: 'Mutare (UTA)',
    city: 'Mutare',
    country: 'Zimbabwe',
  },
  BCW: {
    label: 'Benguera Island Airport (BCW)',
    city: 'Benguera Island',
    country: 'Mozambique',
  },
  SXB: {
    label: 'Entzheim Airport (SXB)',
    city: 'Strasbourg',
    country: 'France',
  },
  KHM: {
    label: 'Khamtis Airport (KHM)',
    city: 'Khamtis',
    country: 'Myanmar (Burma)',
  },
  PSF: {
    label: 'Pittsfield Mncpl (PSF)',
    city: 'Pittsfield',
    country: 'United States',
  },
  QRY: {
    label: 'Ikerasaarsuk Heliport (QRY)',
    city: 'Ikerasaarsuk',
    country: 'Greenland',
  },
  HDM: {
    label: 'Hamadan Airport (HDM)',
    city: 'Hamadan',
    country: 'Iran',
  },
  BAL: {
    label: 'Batman Airport (BAL)',
    city: 'Batman',
    country: 'Turkey',
  },
  IZM: {
    label: 'All Airports (IZM)',
    city: 'Izmir',
    country: 'Turkey',
  },
  MQL: {
    label: 'Mildura (MQL)',
    city: 'Mildura',
    country: 'Australia',
  },
  BHX: {
    label: 'Birmingham International Airport (BHX)',
    city: 'Birmingham',
    country: 'United Kingdom',
  },
  APW: {
    label: 'Faleolo (APW)',
    city: 'Apia',
    country: 'Samoa',
  },
  YBC: {
    label: 'Baie Comeau Airport (YBC)',
    city: 'Baie-Comeau',
    country: 'Canada',
  },
  TNO: {
    label: 'Tamarindo (TNO)',
    city: 'Tamarindo',
    country: 'Costa Rica',
  },
  EVN: {
    label: 'Yerevan (EVN)',
    city: 'Yerevan',
    country: 'Armenia',
  },
  SIA: {
    label: 'Xiguan (SIA)',
    city: "Xi'an",
    country: 'China',
  },
  RET: {
    label: 'Stolport Airport (RET)',
    city: 'Rost',
    country: 'Norway',
  },
  ZAH: {
    label: 'Zahedan Airport (ZAH)',
    city: 'Zahedan',
    country: 'Iran',
  },
  XTL: {
    label: 'Tadoule Lake Airport (XTL)',
    city: 'Tadoule Lake',
    country: 'Canada',
  },
  JLR: {
    label: 'Jabalpur Airport (JLR)',
    city: 'Jabalpur',
    country: 'India',
  },
  UCT: {
    label: 'Ukhta Airport (UCT)',
    city: 'Ukhta',
    country: 'Russia',
  },
  BJF: {
    label: 'Batsfjord Airport (BJF)',
    city: 'Batsfjord',
    country: 'Norway',
  },
  CVN: {
    label: 'Clovis Mncpl (CVN)',
    city: 'Clovis',
    country: 'United States',
  },
  HIN: {
    label: 'Sacheon Airport (HIN)',
    city: 'Jinju',
    country: 'South Korea',
  },
  MTO: {
    label: 'Coles Cty Memorial (MTO)',
    city: 'Mattoon',
    country: 'United States',
  },
  SUB: {
    label: 'Juanda (SUB)',
    city: 'Surabaya',
    country: 'Indonesia',
  },
  HYS: {
    label: 'Hays Regional Airport (HYS)',
    city: 'Hays',
    country: 'United States',
  },
  THE: {
    label: 'Teresina (THE)',
    city: 'Teresina',
    country: 'Brazil',
  },
  LSA: {
    label: 'Losuia Airport (LSA)',
    city: 'Losuia',
    country: 'Papua New Guinea',
  },
  WYB: {
    label: 'Yes Bay Sea Plane Base (WYB)',
    city: 'Yes Bay',
    country: 'United States',
  },
  PKZ: {
    label: 'Pakse Airport (PKZ)',
    city: 'Pakse',
    country: 'Laos',
  },
  HUL: {
    label: 'Houlton Intl (HUL)',
    city: 'Houlton',
    country: 'United States',
  },
  UNA: {
    label: 'Una Airport (UNA)',
    city: 'Una',
    country: 'Brazil',
  },
  KKI: {
    label: 'Akiachak Sea Plane Base (KKI)',
    city: 'Akiachak',
    country: 'United States',
  },
  AHU: {
    label: 'Charif Al Idrissi (AHU)',
    city: 'Al Hoceima',
    country: 'Morocco',
  },
  FMN: {
    label: 'Farmington Municipal Airport (FMN)',
    city: 'Farmington',
    country: 'United States',
  },
  AVI: {
    label: 'Maximo Gomez (AVI)',
    city: 'Ciego de Avila',
    country: 'Cuba',
  },
  CYO: {
    label: 'Cayo Largo Mncpl (CYO)',
    city: 'Cayo Largo',
    country: 'Cuba',
  },
  RNL: {
    label: 'Rennell Airport (RNL)',
    city: 'Rennell',
    country: 'Solomon Islands',
  },
  CDB: {
    label: 'Cold Bay Airport (CDB)',
    city: 'Cold Bay',
    country: 'United States',
  },
  MME: {
    label: 'Durham Tees Valley (MME)',
    city: 'Teesside',
    country: 'United Kingdom',
  },
  ERI: {
    label: 'Erie International Airport (ERI)',
    city: 'Erie',
    country: 'United States',
  },
  CTA: {
    label: 'Fontanarossa (CTA)',
    city: 'Catania',
    country: 'Italy',
  },
  LDB: {
    label: 'Londrina (LDB)',
    city: 'Londrina',
    country: 'Brazil',
  },
  RMG: {
    label: 'Richard B Russell (RMG)',
    city: 'Rome',
    country: 'United States',
  },
  AOR: {
    label: 'Sultan Abdul Halim (AOR)',
    city: 'Alor Setar',
    country: 'Malaysia',
  },
  YVE: {
    label: 'Vernon Mncpl (YVE)',
    city: 'Vernon',
    country: 'Canada',
  },
  GKA: {
    label: 'Goroka (GKA)',
    city: 'Goroka',
    country: 'Papua New Guinea',
  },
  HSN: {
    label: 'Zhoushan (HSN)',
    city: 'Zhoushan',
    country: 'China',
  },
  PVC: {
    label: 'Provincetown Mncpl (PVC)',
    city: 'Provincetown',
    country: 'United States',
  },
  SYX: {
    label: 'Sanya (SYX)',
    city: 'Sanya',
    country: 'China',
  },
  ABE: {
    label: 'Lehigh Valley International Airport (ABE)',
    city: 'Allentown',
    country: 'United States',
  },
  AGH: {
    label: 'Angelholm (AGH)',
    city: 'Helsingborg',
    country: 'Sweden',
  },
  BQT: {
    label: 'Brest (BQT)',
    city: 'Brest',
    country: 'Belarus',
  },
  DNH: {
    label: 'Dunhuang (DNH)',
    city: 'Dunhuang',
    country: 'China',
  },
  FRK: {
    label: 'Fregate Is (FRK)',
    city: 'Fregate Island',
    country: 'Seychelles',
  },
  HKN: {
    label: 'Hoskins Airport (HKN)',
    city: 'Hoskins',
    country: 'Papua New Guinea',
  },
  TAG: {
    label: 'Tagbilaran Airport (TAG)',
    city: 'Tagbilaran',
    country: 'Philippines',
  },
  WUH: {
    label: 'Wuhan (WUH)',
    city: 'Wuhan',
    country: 'China',
  },
  AKG: {
    label: 'Anguganak Airport (AKG)',
    city: 'Anguganak',
    country: 'Papua New Guinea',
  },
  LYU: {
    label: 'Ely Mncpl (LYU)',
    city: 'Ely',
    country: 'United States',
  },
  TRN: {
    label: 'Citta Di Torino (TRN)',
    city: 'Turin',
    country: 'Italy',
  },
  AMW: {
    label: 'Ames Mncpl (AMW)',
    city: 'Ames',
    country: 'United States',
  },
  BTJ: {
    label: 'Blang Bintang Airport (BTJ)',
    city: 'Banda Aceh',
    country: 'Indonesia',
  },
  TKK: {
    label: 'Truk (TKK)',
    city: 'Weno',
    country: 'Micronesia',
  },
  TAC: {
    label: 'D Z Romualdez Airport (TAC)',
    city: 'Tacloban',
    country: 'Philippines',
  },
  JXN: {
    label: 'Reynolds Mnpl (JXN)',
    city: 'Jackson',
    country: 'United States',
  },
  LGP: {
    label: 'Legaspi (LGP)',
    city: 'Legaspi',
    country: 'Philippines',
  },
  DED: {
    label: 'Jolly Grant (DED)',
    city: 'Dehra Dun',
    country: 'India',
  },
  CZJ: {
    label: 'Corazon De Jesus Airport (CZJ)',
    city: 'Corazon De Jesus',
    country: 'Panama',
  },
  ZPC: {
    label: 'Pucon (ZPC)',
    city: 'Pucon',
    country: 'Chile and Easter Island',
  },
  DVO: {
    label: 'Mati (DVO)',
    city: 'Davao',
    country: 'Philippines',
  },
  MUZ: {
    label: 'Musoma Airport (MUZ)',
    city: 'Musoma',
    country: 'Tanzania',
  },
  PUW: {
    label: 'Moscow Regional Airport (PUW)',
    city: 'Pullman',
    country: 'United States',
  },
  PNS: {
    label: 'Pensacola Regional Airport (PNS)',
    city: 'Pensacola',
    country: 'United States',
  },
  JAQ: {
    label: 'Jacquinot Bay Airport (JAQ)',
    city: 'Jacquinot Bay',
    country: 'Papua New Guinea',
  },
  BRL: {
    label: 'Burlington Airport (BRL)',
    city: 'Burlington',
    country: 'IA',
  },
  BKW: {
    label: 'Beckley (BKW)',
    city: 'Beckley',
    country: 'United States',
  },
  YAP: {
    label: 'Yap (YAP)',
    city: 'Colonia',
    country: 'Micronesia',
  },
  FRA: {
    label: 'Frankfurt International Airport (FRA)',
    city: 'Frankfurt',
    country: 'Germany',
  },
  RNN: {
    label: 'Bornholm (RNN)',
    city: 'Ronne',
    country: 'Denmark',
  },
  REP: {
    label: 'Siem Reap (REP)',
    city: 'Siem Reap',
    country: 'Cambodia',
  },
  MSJ: {
    label: 'Misawa Airport (MSJ)',
    city: 'Misawa',
    country: 'Japan',
  },
  ESN: {
    label: 'Easton Mncpl (ESN)',
    city: 'Easton',
    country: 'United States',
  },
  BKM: {
    label: 'Bakalalan Airport (BKM)',
    city: 'Bakalalan',
    country: 'Malaysia',
  },
  SHR: {
    label: 'Sheridan County Airport (SHR)',
    city: 'Sheridan',
    country: 'United States',
  },
  ITM: {
    label: 'Itami (ITM)',
    city: 'Osaka',
    country: 'Japan',
  },
  MLA: {
    label: 'Malta Intl (MLA)',
    city: 'Valletta',
    country: 'Malta',
  },
  MVZ: {
    label: 'Masvingo (MVZ)',
    city: 'Masvingo',
    country: 'Zimbabwe',
  },
  OOK: {
    label: 'Toksook Bay Airport (OOK)',
    city: 'Toksook Bay',
    country: 'United States',
  },
  CGY: {
    label: 'Lumbia Airport (CGY)',
    city: 'Cagayan De Oro',
    country: 'Philippines',
  },
  MGH: {
    label: 'Margate Airport (MGH)',
    city: 'Margate',
    country: 'South Africa',
  },
  PLX: {
    label: 'Semipalatinsk Airport (PLX)',
    city: 'Semipalatinsk',
    country: 'Kazakhstan',
  },
  CBO: {
    label: 'Awang Airport (CBO)',
    city: 'Cotabato',
    country: 'Philippines',
  },
  GBJ: {
    label: 'Marie Galante (GBJ)',
    city: 'Marie Galante',
    country: 'St Barthelemy',
  },
  ACC: {
    label: 'Kotoka (ACC)',
    city: 'Accra',
    country: 'Ghana',
  },
  EUX: {
    label: 'Roosevelt Fld (EUX)',
    city: 'St Eustatius',
    country: 'Curacao',
  },
  FBL: {
    label: 'Faribault Mncpl (FBL)',
    city: 'Faribault',
    country: 'United States',
  },
  TWB: {
    label: 'Toowoomba (TWB)',
    city: 'Toowoomba',
    country: 'Australia',
  },
  CMR: {
    label: 'Colmar-Houssen (CMR)',
    city: 'Colmar',
    country: 'France',
  },
  CHX: {
    label: 'Changuinola Airport (CHX)',
    city: 'Changuinola',
    country: 'Panama',
  },
  SXM: {
    label: 'Skeldon Airport (SXM)',
    city: 'St Maarten/St Martin',
    country: 'St Maarten/St Martin',
  },
  SLK: {
    label: 'Adirondack (SLK)',
    city: 'Saranac Lake',
    country: 'United States',
  },
  SDN: {
    label: 'Sandane Anda (SDN)',
    city: 'Sandane',
    country: 'Norway',
  },
  AFT: {
    label: 'Afutara Aerodrome (AFT)',
    city: 'Afutara',
    country: 'Solomon Islands',
  },
  QJI: {
    label: 'Heliport (QJI)',
    city: 'Ikamiut',
    country: 'Greenland',
  },
  EVM: {
    label: 'Eveleth-Virginia (EVM)',
    city: 'Eveleth',
    country: 'United States',
  },
  YDA: {
    label: 'Dawson City (YDA)',
    city: 'Dawson City',
    country: 'Canada',
  },
  CKZ: {
    label: 'Canakkale Airport (CKZ)',
    city: 'Canakkale',
    country: 'Turkey',
  },
  KWN: {
    label: 'Kwinhagak Airport (KWN)',
    city: 'Quinhagak',
    country: 'United States',
  },
  TLD: {
    label: 'Limpopo Valley (TLD)',
    city: 'Tuli Block',
    country: 'Botswana',
  },
  SHG: {
    label: 'Shungnak Airport (SHG)',
    city: 'Shungnak',
    country: 'United States',
  },
  ENT: {
    label: 'Enewetak Island Airport (ENT)',
    city: 'Enewetak Island',
    country: 'Marshall Islands',
  },
  OKE: {
    label: 'Okino Erabu Airport (OKE)',
    city: 'Okino Erabu',
    country: 'Japan',
  },
  THA: {
    label: 'William Northern (THA)',
    city: 'Tullahoma',
    country: 'United States',
  },
  ESD: {
    label: 'Orcas Island (ESD)',
    city: 'Eastsound',
    country: 'United States',
  },
  AGU: {
    label: 'Aguascalients Airport (AGU)',
    city: 'Aguascalientes',
    country: 'Mexico',
  },
  IQT: {
    label: 'CF Secada (IQT)',
    city: 'Iquitos',
    country: 'Peru',
  },
  WGO: {
    label: 'Winchester (WGO)',
    city: 'Winchester',
    country: 'United States',
  },
  KLO: {
    label: 'Kalibo (KLO)',
    city: 'Kalibo',
    country: 'Philippines',
  },
  MJA: {
    label: 'Manja Airport (MJA)',
    city: 'Manja',
    country: 'Madagascar',
  },
  PAR: {
    label: 'All Airports (PAR)',
    city: 'Paris',
    country: 'France ',
  },
  SNC: {
    label: 'Salinas Airport (SNC)',
    city: 'Salinas',
    country: 'Ecuador',
  },
  TAV: {
    label: "Ta'u (TAV)",
    city: "Ta'u Is",
    country: 'American Samoa',
  },
  TUA: {
    label: 'Tulcan Airport (TUA)',
    city: 'Tulcan',
    country: 'Ecuador',
  },
  OES: {
    label: 'San Antonio Oeste Airport (OES)',
    city: 'San Antonio Oeste',
    country: 'Argentina',
  },
  URY: {
    label: 'Gurayat Airport (URY)',
    city: 'Gurayat',
    country: 'Saudi Arabia',
  },
  KGE: {
    label: 'Kagau Airport (KGE)',
    city: 'Kagau',
    country: 'Solomon Islands',
  },
  NOT: {
    label: 'Gnoss Field (NOT)',
    city: 'Novato',
    country: 'United States',
  },
  DMB: {
    label: 'Dzhambyl Airport (DMB)',
    city: 'Dzhambyl',
    country: 'Kazakhstan',
  },
  CJU: {
    label: 'Jeju (CJU)',
    city: 'Jeju',
    country: 'South Korea',
  },
  BVC: {
    label: 'Rabil Airport (BVC)',
    city: 'Boa Vista',
    country: 'Cape Verde',
  },
  RIC: {
    label: 'Richmond (RIC)',
    city: 'Richmond',
    country: 'United States',
  },
  SVS: {
    label: 'Stevens Village Airport (SVS)',
    city: 'Stevens Village',
    country: 'United States',
  },
  OSW: {
    label: 'Orsk Airport (OSW)',
    city: 'Orsk',
    country: 'Russia',
  },
  GKT: {
    label: 'Gatlinburg Mncpl (GKT)',
    city: 'Gatlinburg',
    country: 'United States',
  },
  XLB: {
    label: 'Lac Brochet Airport (XLB)',
    city: 'Lac Brochet',
    country: 'Canada',
  },
  YSU: {
    label: 'Summerside Mncpl (YSU)',
    city: 'Summerside',
    country: 'Canada',
  },
  BLE: {
    label: 'Dala (BLE)',
    city: 'Borlange',
    country: 'Sweden',
  },
  COK: {
    label: 'Kochi Intl (COK)',
    city: 'Kochi',
    country: 'India',
  },
  NHA: {
    label: 'Nha-Trang (NHA)',
    city: 'Nha Trang',
    country: 'Vietnam',
  },
  REK: {
    label: 'All Airports (REK)',
    city: 'Reykjavik',
    country: 'Iceland',
  },
  KSF: {
    label: 'Kassel (KSF)',
    city: 'Kassel',
    country: 'Germany',
  },
  IXD: {
    label: 'Bamrauli (IXD)',
    city: 'Allahabad',
    country: 'India',
  },
  RJK: {
    label: 'Rijeka (RJK)',
    city: 'Rijeka',
    country: 'Croatia',
  },
  XIL: {
    label: 'Xilinhot Airport (XIL)',
    city: 'Xilinhot',
    country: 'China',
  },
  KBZ: {
    label: 'Kaikoura (KBZ)',
    city: 'Kaikoura',
    country: 'New Zealand',
  },
  ESB: {
    label: 'Esenboga (ESB)',
    city: 'Ankara',
    country: 'Turkey',
  },
  RNP: {
    label: 'Rongelap Airport (RNP)',
    city: 'Rongelap',
    country: 'Marshall Islands',
  },
  ZBR: {
    label: 'Chah-Bahar Airport (ZBR)',
    city: 'Chah-Bahar',
    country: 'Iran',
  },
  MYQ: {
    label: 'Mysore (MYQ)',
    city: 'Mysore',
    country: 'India',
  },
  SCX: {
    label: 'Salina Cruz Airport (SCX)',
    city: 'Salina Cruz',
    country: 'Mexico',
  },
  SAP: {
    label: 'Ramon Villeda Morales Airport (SAP)',
    city: 'San Pedro Sula',
    country: 'Honduras',
  },
  MOD: {
    label: 'Modesto Municipal Airport (MOD)',
    city: 'Modesto',
    country: 'United States',
  },
  TMA: {
    label: 'Henry Tift Myers (TMA)',
    city: 'Tifton',
    country: 'United States',
  },
  LEX: {
    label: 'Blue Grass Airport (LEX)',
    city: 'Lexington',
    country: 'United States',
  },
  DIN: {
    label: 'Dien Bien Airport (DIN)',
    city: 'Dien Bien Phu',
    country: 'Vietnam',
  },
  HUZ: {
    label: 'Huizhou (HUZ)',
    city: 'Huizhou',
    country: 'China',
  },
  PMD: {
    label: 'Palmdale Regl (PMD)',
    city: 'Palmdale',
    country: 'United States',
  },
  ILO: {
    label: 'Mandurriao (ILO)',
    city: 'Iloilo',
    country: 'Philippines',
  },
  MCX: {
    label: 'Makhachkala Airport (MCX)',
    city: 'Makhachkala',
    country: 'Russia',
  },
  IDR: {
    label: 'Indore (IDR)',
    city: 'Indore',
    country: 'India',
  },
  PLM: {
    label: 'Mahmd Badaruddin II (PLM)',
    city: 'Palembang',
    country: 'Indonesia',
  },
  DGW: {
    label: 'Converse County (DGW)',
    city: 'Douglas',
    country: 'United States',
  },
  VAS: {
    label: 'Sivas Airport (VAS)',
    city: 'Sivas',
    country: 'Turkey',
  },
  LPD: {
    label: 'La Pedrera Airport (LPD)',
    city: 'La Pedrera',
    country: 'Colombia',
  },
  DJE: {
    label: 'Melita (DJE)',
    city: 'Jerba Island',
    country: 'Tunisia',
  },
  YOO: {
    label: 'Oshawa (YOO)',
    city: 'Oshawa',
    country: 'Canada',
  },
  XIY: {
    label: 'Xianyang (XIY)',
    city: "Xi'an",
    country: 'China',
  },
  MNM: {
    label: 'Menominee Cty (MNM)',
    city: 'Menominee',
    country: 'United States',
  },
  HNS: {
    label: 'Haines (HNS)',
    city: 'Haines',
    country: 'United States',
  },
  FKI: {
    label: 'Kisangani Airport (FKI)',
    city: 'Kisangani',
    country: 'Democratic Republic of Congo',
  },
  CEG: {
    label: 'Harwarden (CEG)',
    city: 'Chester',
    country: 'United Kingdom',
  },
  NAN: {
    label: 'Nadi Intl (NAN)',
    city: 'Nadi',
    country: 'Fiji',
  },
  MQN: {
    label: 'Rossvoll (MQN)',
    city: 'Mo-i-Rana',
    country: 'Norway',
  },
  NJC: {
    label: 'Nizhnevartovsk Airport (NJC)',
    city: 'Nizhnevartovsk',
    country: 'Russia',
  },
  CYS: {
    label: 'Cheyenne Airport (CYS)',
    city: 'Cheyenne',
    country: 'United States',
  },
  TCD: {
    label: 'Tarapaca Airport (TCD)',
    city: 'Tarapaca',
    country: 'Colombia',
  },
  BAS: {
    label: 'Balalae Airport (BAS)',
    city: 'Balalae',
    country: 'Solomon Islands',
  },
  MBT: {
    label: 'Masbate Airport (MBT)',
    city: 'Masbate',
    country: 'Philippines',
  },
  PFN: {
    label: 'Bay County Airport (PFN)',
    city: 'Panama City',
    country: 'United States',
  },
  ORK: {
    label: 'Cork International (ORK)',
    city: 'Cork',
    country: 'Ireland',
  },
  CHD: {
    label: 'Williams AFB (CHD)',
    city: 'Chandler',
    country: 'United States',
  },
  YZH: {
    label: 'Slave Lake Mncpl (YZH)',
    city: 'Slave Lake',
    country: 'Canada',
  },
  PVA: {
    label: 'Providencia (PVA)',
    city: 'Providencia',
    country: 'Colombia',
  },
  CZA: {
    label: 'Chichen Itza Mncpl (CZA)',
    city: 'Chichen Itza',
    country: 'Mexico',
  },
  ANE: {
    label: 'Marce (ANE)',
    city: 'Angers',
    country: 'France',
  },
  APF: {
    label: 'Naples Airport (APF)',
    city: 'Naples',
    country: 'United States',
  },
  KIT: {
    label: 'Kithira Airport (KIT)',
    city: 'Kithira',
    country: 'Greece',
  },
  DKI: {
    label: 'Dunk Island (DKI)',
    city: 'Dunk Island',
    country: 'Australia',
  },
  OMH: {
    label: 'Umieh Airport (OMH)',
    city: 'Urmieh',
    country: 'Iran',
  },
  OCF: {
    label: 'Jim Taylor Fld (OCF)',
    city: 'Ocala',
    country: 'United States',
  },
  GON: {
    label: 'Groton-New London (GON)',
    city: 'New London',
    country: 'United States',
  },
  IRA: {
    label: 'Kirakira Airport (IRA)',
    city: 'Kirakira',
    country: 'Solomon Islands',
  },
  YPH: {
    label: 'Inukjuak Airport (YPH)',
    city: 'Inukjuak',
    country: 'Canada',
  },
  YIN: {
    label: 'Yining Airport (YIN)',
    city: 'Yining',
    country: 'China',
  },
  MVD: {
    label: 'Carrasco Airport (MVD)',
    city: 'Montevideo',
    country: 'Uruguay',
  },
  JEJ: {
    label: 'Jeh Airport (JEJ)',
    city: 'Jeh',
    country: 'Marshall Islands',
  },
  HMR: {
    label: 'Hamar (HMR)',
    city: 'Hamar',
    country: 'Norway',
  },
  KKD: {
    label: 'Kokoda Airport (KKD)',
    city: 'Kokoda',
    country: 'Papua New Guinea',
  },
  NYU: {
    label: 'Nyaung-u Airport (NYU)',
    city: 'Nyaung-u',
    country: 'Myanmar (Burma)',
  },
  KQA: {
    label: 'Akutan Airport (KQA)',
    city: 'Akutan',
    country: 'United States',
  },
  KHD: {
    label: 'Khorramabad Airport (KHD)',
    city: 'Khorramabad',
    country: 'Iran',
  },
  KSH: {
    label: 'Kermanshah Airport (KSH)',
    city: 'Kermanshah',
    country: 'Iran',
  },
  JAG: {
    label: 'Jacobabad Airport (JAG)',
    city: 'Jacobabad',
    country: 'Pakistan',
  },
  DBO: {
    label: 'Dubbo (DBO)',
    city: 'Dubbo',
    country: 'Australia',
  },
  WJA: {
    label: 'Woja Airport (WJA)',
    city: 'Woja',
    country: 'Marshall Islands',
  },
  PSZ: {
    label: 'Puerto Suarez Airport (PSZ)',
    city: 'Puerto Suarez',
    country: 'Bolivia',
  },
  BNI: {
    label: 'Benin City Airport (BNI)',
    city: 'Benin City',
    country: 'Nigeria',
  },
  CAK: {
    label: 'Fulton International Airport (CAK)',
    city: 'Akron',
    country: 'United States',
  },
  LZN: {
    label: 'Nangan Airport (Matsu Islands) (LZN)',
    city: 'Nangan',
    country: 'China',
  },
  WST: {
    label: 'Westerly State (WST)',
    city: 'Westerly',
    country: 'United States',
  },
  PIZ: {
    label: 'Dew Station Airport (PIZ)',
    city: 'Point Lay',
    country: 'United States',
  },
  EQS: {
    label: 'Esquel Mncpl (EQS)',
    city: 'Esquel',
    country: 'Argentina',
  },
  MIA: {
    label: 'Miami (MIA)',
    city: 'Miami',
    country: 'United States',
  },
  NSN: {
    label: 'Nelson (NSN)',
    city: 'Nelson',
    country: 'New Zealand',
  },
  IXP: {
    label: 'Pathankot Airport (IXP)',
    city: 'Pathankot',
    country: 'India',
  },
  NAL: {
    label: 'Nalchik Airport (NAL)',
    city: 'Nalchik',
    country: 'Russia',
  },
  EKN: {
    label: 'Elkins (EKN)',
    city: 'Elkins',
    country: 'United States',
  },
  RIK: {
    label: 'Carrillo Mncpl (RIK)',
    city: 'Carrillo',
    country: 'Costa Rica',
  },
  LNK: {
    label: 'Lincoln Municipal Airport (LNK)',
    city: 'Lincoln',
    country: 'United States',
  },
  AYP: {
    label: 'Yanamilla Airport (AYP)',
    city: 'Ayacucho',
    country: 'Peru',
  },
  LUD: {
    label: 'Luderitz (LUD)',
    city: 'Luderitz',
    country: 'Namibia',
  },
  YXL: {
    label: 'Sioux Lookout Mncpl (YXL)',
    city: 'Sioux Lookout',
    country: 'Canada',
  },
  KAT: {
    label: 'Kaitaia (KAT)',
    city: 'Kaitaia',
    country: 'New Zealand',
  },
  HEI: {
    label: 'Heide-Buesum Airport (HEI)',
    city: 'Heide',
    country: 'Germany',
  },
  LET: {
    label: 'Gen AV Cobo Airport (LET)',
    city: 'Leticia',
    country: 'Colombia',
  },
  OIM: {
    label: 'Oshima (OIM)',
    city: 'Oshima Island',
    country: 'Japan',
  },
  PMO: {
    label: 'Punta Raisi (PMO)',
    city: 'Palermo',
    country: 'Italy',
  },
  GYA: {
    label: 'Guayaramerin Airport (GYA)',
    city: 'Guayaramerin',
    country: 'Bolivia',
  },
  GSP: {
    label: 'Greenville-Spartanburg Airport (GSP)',
    city: 'Greenville',
    country: 'SC',
  },
  LUP: {
    label: 'Kalaupapa Airport (LUP)',
    city: 'Kalaupapa',
    country: 'United States',
  },
  LAQ: {
    label: 'La Braq Airport (LAQ)',
    city: 'Beida',
    country: 'Libya',
  },
  ZBF: {
    label: 'Bathurst Airport (ZBF)',
    city: 'Bathurst',
    country: 'Canada',
  },
  HGN: {
    label: 'Mae Hong Son (HGN)',
    city: 'Mae Hong Son',
    country: 'Thailand',
  },
  OCN: {
    label: 'Oceanside (OCN)',
    city: 'Oceanside',
    country: 'United States',
  },
  HSG: {
    label: 'Saga Airport (HSG)',
    city: 'Saga',
    country: 'Japan',
  },
  LWK: {
    label: 'Lerwick/Tingwall (LWK)',
    city: 'Lerwick',
    country: 'United Kingdom',
  },
  IOS: {
    label: 'Ilheus Eduardo Gomes (IOS)',
    city: 'Ilheus',
    country: 'Brazil',
  },
  CVF: {
    label: 'Courchevel (CVF)',
    city: 'Courchevel',
    country: 'France',
  },
  KND: {
    label: 'Kindu Airport (KND)',
    city: 'Kindu',
    country: 'Democratic Republic of Congo',
  },
  SOB: {
    label: 'FlyBalaton Airport (SOB)',
    city: 'Balaton',
    country: 'Hungary',
  },
  CPD: {
    label: 'Coober Pedy (CPD)',
    city: 'Coober Pedy',
    country: 'Australia',
  },
  ROI: {
    label: 'Roi Et Airport (ROI)',
    city: 'Roi Et',
    country: 'Thailand',
  },
  DAX: {
    label: 'Daxian Airport (DAX)',
    city: 'Daxian',
    country: 'China',
  },
  MCB: {
    label: 'Pike County (MCB)',
    city: 'McComb',
    country: 'United States',
  },
  PRG: {
    label: 'Ruzyne (PRG)',
    city: 'Prague',
    country: 'Czech Republic',
  },
  AET: {
    label: 'Allakaket Airport (AET)',
    city: 'Allakaket',
    country: 'United States',
  },
  YSF: {
    label: 'Stony Rapids (YSF)',
    city: 'Stony Rapids',
    country: 'Canada',
  },
  JPR: {
    label: 'Ji-Parana Airport (JPR)',
    city: 'Ji-Parana',
    country: 'Brazil',
  },
  KDL: {
    label: 'Kardla Airport (KDL)',
    city: 'Kardla',
    country: 'Estonia',
  },
  RKW: {
    label: 'Rockwood Mncpl (RKW)',
    city: 'Rockwood',
    country: 'United States',
  },
  BRC: {
    label: 'DeBariloche Intl (BRC)',
    city: 'San Carlos de Bariloche',
    country: 'Argentina',
  },
  MMU: {
    label: 'Morristown Mncpl (MMU)',
    city: 'Morristown',
    country: 'United States',
  },
  SWH: {
    label: 'Swan Hill (SWH)',
    city: 'Swan Hill',
    country: 'Australia',
  },
  DGT: {
    label: 'Dumaguete (DGT)',
    city: 'Dumaguete',
    country: 'Philippines',
  },
  OXR: {
    label: 'Oxnard Airport (OXR)',
    city: 'Oxnard',
    country: 'United States',
  },
  ICI: {
    label: 'Cicia Airport (ICI)',
    city: 'Cicia',
    country: 'Fiji',
  },
  OME: {
    label: 'Nome Airport (OME)',
    city: 'Nome',
    country: 'United States',
  },
  YXX: {
    label: 'Abbotsford Airport (YXX)',
    city: 'Abbotsford',
    country: 'Canada',
  },
  YHP: {
    label: 'Poplar Hill Airport (YHP)',
    city: 'Poplar Hill',
    country: 'Canada',
  },
  SBS: {
    label: 'Steamboat Springs Airport (SBS)',
    city: 'Steamboat Springs',
    country: 'United States',
  },
  RUR: {
    label: 'Rurutu Airport (RUR)',
    city: 'Rurutu',
    country: 'French Polynesia and Tahiti',
  },
  XRR: {
    label: 'Ross River (XRR)',
    city: 'Ross River',
    country: 'Canada',
  },
  TSN: {
    label: 'Tianjin (TSN)',
    city: 'Tianjin',
    country: 'China',
  },
  IXE: {
    label: 'Bajpe (IXE)',
    city: 'Mangalore',
    country: 'India',
  },
  LYX: {
    label: 'Lydd International Airport (LYX)',
    city: 'Lydd',
    country: 'United Kingdom',
  },
  MZI: {
    label: 'Mopti (MZI)',
    city: 'Mopti',
    country: 'Mali',
  },
  JDF: {
    label: 'Francisco De Assis Airport (JDF)',
    city: 'Juiz de Fora',
    country: 'Brazil',
  },
  MYY: {
    label: 'Miri (MYY)',
    city: 'Miri',
    country: 'Malaysia',
  },
  LME: {
    label: 'Arnage (LME)',
    city: 'Le Mans',
    country: 'France',
  },
  TUP: {
    label: 'Lemons Municipal Airport (TUP)',
    city: 'Tupelo',
    country: 'United States',
  },
  BUZ: {
    label: 'Bushehr Airport (BUZ)',
    city: 'Bushehr',
    country: 'Iran',
  },
  JGA: {
    label: 'Govardhanpur (JGA)',
    city: 'Jamnagar',
    country: 'India',
  },
  MGQ: {
    label: 'Mogadishu International Airport (MGQ)',
    city: 'Mogadishu',
    country: 'Somalia',
  },
  MLO: {
    label: 'Milos (MLO)',
    city: 'Milos',
    country: 'Greece',
  },
  FBS: {
    label: 'Friday Harbor SPB (FBS)',
    city: 'Friday Harbor',
    country: 'United States',
  },
  PTH: {
    label: 'Port Heiden Airport (PTH)',
    city: 'Port Heiden',
    country: 'United States',
  },
  EKI: {
    label: 'Elkhart Mncpl (EKI)',
    city: 'Elkhart',
    country: 'United States',
  },
  DYG: {
    label: 'Dayong Airport (DYG)',
    city: 'Dayong',
    country: 'China',
  },
  KSN: {
    label: 'Kostanay Airport (KSN)',
    city: 'Kostanay',
    country: 'Kazakhstan',
  },
  MFM: {
    label: 'Macau Airport (MFM)',
    city: 'Macao',
    country: 'China',
  },
  PMA: {
    label: 'Wawi Airport (PMA)',
    city: 'Pemba',
    country: 'Tanzania',
  },
  SJO: {
    label: 'Juan Santamaria International Airport (SJO)',
    city: 'San Jose',
    country: 'Costa Rica',
  },
  INH: {
    label: 'Inhambane Airport (INH)',
    city: 'Inhambane',
    country: 'Mozambique',
  },
  TUM: {
    label: 'Tumut (TUM)',
    city: 'Tumut',
    country: 'Australia',
  },
  CUL: {
    label: 'Culiacan (CUL)',
    city: 'Culiacan',
    country: 'Mexico',
  },
  LUQ: {
    label: 'San Luis Airport (LUQ)',
    city: 'San Luis',
    country: 'Argentina',
  },
  SIR: {
    label: 'Sion (SIR)',
    city: 'Sion',
    country: 'Switzerland',
  },
  MOW: {
    label: 'All Airports (MOW)',
    city: 'Moscow',
    country: 'Russia',
  },
  NGB: {
    label: 'Ningbo (NGB)',
    city: 'Ningbo',
    country: 'China',
  },
  RDN: {
    label: 'LTS Pulau Redang Airport (RDN)',
    city: 'Redang',
    country: 'Malaysia',
  },
  KNU: {
    label: 'Kanpur (KNU)',
    city: 'Kanpur',
    country: 'India',
  },
  AHS: {
    label: 'Ahuas Airport (AHS)',
    city: 'Ahuas',
    country: 'Honduras',
  },
  SDD: {
    label: 'Lubango Airport (SDD)',
    city: 'Lubango',
    country: 'Angola',
  },
  BWA: {
    label: 'Bhairawa Airport (BWA)',
    city: 'Bhairawa',
    country: 'Nepal',
  },
  RZA: {
    label: 'Santa Cruz Airport (RZA)',
    city: 'Santa Cruz',
    country: 'Argentina',
  },
  FRC: {
    label: 'Franca (FRC)',
    city: 'Franca',
    country: 'Brazil',
  },
  SML: {
    label: 'Estate Airstrip (SML)',
    city: 'Stella Maris',
    country: 'Bahamas',
  },
  PIS: {
    label: 'Biard (PIS)',
    city: 'Poitiers',
    country: 'France',
  },
  IFO: {
    label: 'Ivano-Frankovsk Airport (IFO)',
    city: 'Ivano-Frankovsk',
    country: 'Ukraine',
  },
  BED: {
    label: 'Hanscom Field (BED)',
    city: 'Bedford',
    country: 'United States',
  },
  BHS: {
    label: 'Raglan (BHS)',
    city: 'Bathurst',
    country: 'Australia',
  },
  KZI: {
    label: 'Philippos (KZI)',
    city: 'Kozani',
    country: 'Greece',
  },
  YQM: {
    label: 'Metropolitan Area (YQM)',
    city: 'Moncton',
    country: 'Canada',
  },
  AJI: {
    label: 'Agri Airport (AJI)',
    city: 'Agri',
    country: 'Turkey',
  },
  UAK: {
    label: 'Narsarsuaq (UAK)',
    city: 'Narsarsuaq',
    country: 'Greenland',
  },
  PNC: {
    label: 'Ponca City Mncpl (PNC)',
    city: 'Ponca City',
    country: 'United States',
  },
  HDY: {
    label: 'Hat Yai (HDY)',
    city: 'Hat Yai',
    country: 'Thailand',
  },
  VUP: {
    label: 'Valledupar Airport (VUP)',
    city: 'Valledupar',
    country: 'Colombia',
  },
  AQJ: {
    label: 'King Hussein Intl (AQJ)',
    city: 'Aqaba',
    country: 'Jordan',
  },
  SJP: {
    label: 'Sao Pedro Rio Preto (SJP)',
    city: 'Sao Jose do Rio Preto',
    country: 'Brazil',
  },
  NAZ: {
    label: 'Nana Airport (NAZ)',
    city: 'Star Habour',
    country: 'Solomon Islands',
  },
  ATC: {
    label: 'Arthurs Town (ATC)',
    city: "Arthur's Town",
    country: 'Bahamas',
  },
  EVG: {
    label: 'Sveg Airport (EVG)',
    city: 'Sveg',
    country: 'Sweden',
  },
  OLJ: {
    label: 'West Cost Santo Airport (OLJ)',
    city: 'Olpoi',
    country: 'Vanuatu',
  },
  XNN: {
    label: 'Xining (XNN)',
    city: 'Xining',
    country: 'China',
  },
  YMH: {
    label: "Mary's Harbour Airport (YMH)",
    city: "Mary's Harbour",
    country: 'Canada',
  },
  SMA: {
    label: 'Vila Do Porto (SMA)',
    city: 'Santa Maria',
    country: 'Portugal',
  },
  AMI: {
    label: 'Selaparang (AMI)',
    city: 'Mataram',
    country: 'Indonesia',
  },
  YDF: {
    label: 'Deer Lake Airport (YDF)',
    city: 'Deer Lake',
    country: 'NL',
  },
  EGE: {
    label: 'Vail/Eagle Airport (EGE)',
    city: 'Vail',
    country: 'United States',
  },
  QXB: {
    label: 'RAILWAY STATION (QXB)',
    city: 'Aix-en-Provence',
    country: 'France',
  },
  YOW: {
    label: 'Ottawa (YOW)',
    city: 'Ottawa',
    country: 'Canada',
  },
  CNJ: {
    label: 'Cloncurry Airport (CNJ)',
    city: 'Cloncurry',
    country: 'Australia',
  },
  OGG: {
    label: 'Kahului Airport (OGG)',
    city: 'Kahului',
    country: 'United States',
  },
  AND: {
    label: 'Anderson County (AND)',
    city: 'Anderson',
    country: 'United States',
  },
  GSO: {
    label: 'Greensboro (GSO)',
    city: 'Greensboro',
    country: 'United States',
  },
  PUB: {
    label: 'Pueblo Memorial Airport (PUB)',
    city: 'Pueblo',
    country: 'United States',
  },
  USM: {
    label: 'Koh Samui (USM)',
    city: 'Koh Samui',
    country: 'Thailand',
  },
  BKO: {
    label: 'Bamako (BKO)',
    city: 'Bamako',
    country: 'Mali',
  },
  GGG: {
    label: 'Gregg County Airport (GGG)',
    city: 'Longview',
    country: 'United States',
  },
  ZZU: {
    label: 'Mzuzu (ZZU)',
    city: 'Mzuzu',
    country: 'Malawi',
  },
  DLS: {
    label: 'The Dalles Mncpl (DLS)',
    city: 'The Dalles',
    country: 'United States',
  },
  BVI: {
    label: 'Birdsville Airport (BVI)',
    city: 'Birdsville',
    country: 'Australia',
  },
  RBQ: {
    label: 'Rurrenabaque Airport (RBQ)',
    city: 'Rurrenabaque',
    country: 'Bolivia',
  },
  BDA: {
    label: 'Bermuda International Airport (BDA)',
    city: 'Hamilton',
    country: 'Bermuda',
  },
  TKQ: {
    label: 'Kigoma Airport (TKQ)',
    city: 'Kigoma',
    country: 'Tanzania',
  },
  HNH: {
    label: 'Hoonah Airport (HNH)',
    city: 'Hoonah',
    country: 'United States',
  },
  TFN: {
    label: 'Norte Los Rodeos (TFN)',
    city: 'Tenerife',
    country: 'Spain',
  },
  OSS: {
    label: 'Osh Airport (OSS)',
    city: 'Osh',
    country: 'Kyrgyzstan',
  },
  YMO: {
    label: 'Moosonee Airport (YMO)',
    city: 'Moosonee',
    country: 'Canada',
  },
  TWA: {
    label: 'Twin Hills Airport (TWA)',
    city: 'Twin Hills',
    country: 'United States',
  },
  LMA: {
    label: 'Lake Minchumina Airport (LMA)',
    city: 'Lake Minchumina',
    country: 'United States',
  },
  UDJ: {
    label: 'Uzhgorod (UDJ)',
    city: 'Uzhgorod',
    country: 'Ukraine',
  },
  SWF: {
    label: 'Stewart Airport (SWF)',
    city: 'Newburgh',
    country: 'United States',
  },
  PIR: {
    label: 'Pierre Airport (PIR)',
    city: 'Pierre',
    country: 'United States',
  },
  BTK: {
    label: 'Bratsk Airport (BTK)',
    city: 'Bratsk',
    country: 'Russia',
  },
  SJC: {
    label: 'San Jose (SJC)',
    city: 'San Jose',
    country: 'United States',
  },
  PLS: {
    label: 'Providenciales International Airport (PLS)',
    city: 'Providenciales',
    country: 'Turks and Caicos',
  },
  IFN: {
    label: 'Isfahan (IFN)',
    city: 'Isfahan',
    country: 'Iran',
  },
  LAL: {
    label: 'Lakeland Mncpl (LAL)',
    city: 'Lakeland',
    country: 'United States',
  },
  PUZ: {
    label: 'Puerto Cabezas Airport (PUZ)',
    city: 'Puerto Cabezas',
    country: 'Nicaragua',
  },
  RDG: {
    label: 'Reading Municipal Airport/Spaatz Field (RDG)',
    city: 'Reading',
    country: 'United States',
  },
  KBP: {
    label: 'Borispol (KBP)',
    city: 'Kiev',
    country: 'Ukraine',
  },
  SZB: {
    label: 'Abdul Aziz Shah (SZB)',
    city: 'Kuala Lumpur',
    country: 'Malaysia',
  },
  BFU: {
    label: 'Bengbu (BFU)',
    city: 'Bengbu',
    country: 'China',
  },
  FMM: {
    label: 'Memmingen Airport (FMM)',
    city: 'Memmingen',
    country: 'Germany',
  },
  CPQ: {
    label: 'Campinas Intl (CPQ)',
    city: 'Campinas',
    country: 'Brazil',
  },
  HAH: {
    label: 'Prince Said Ibrahim In Airport (HAH)',
    city: 'Moroni',
    country: 'Comoros',
  },
  GDT: {
    label: 'Grand Turk (GDT)',
    city: 'Grand Turk',
    country: 'Turks and Caicos',
  },
  JSA: {
    label: 'Jaisalmer (JSA)',
    city: 'Jaisalmer',
    country: 'India',
  },
  DDC: {
    label: 'Dodge City Regional Airport (DDC)',
    city: 'Dodge City',
    country: 'United States',
  },
  GAY: {
    label: 'Gaya Airport (GAY)',
    city: 'Gaya',
    country: 'India',
  },
  CHT: {
    label: 'Karewa Airport (CHT)',
    city: 'Chatham Island',
    country: 'New Zealand',
  },
  YBR: {
    label: 'Brandon Mncpl (YBR)',
    city: 'Brandon',
    country: 'Canada',
  },
  SCK: {
    label: 'Stockton Airport (SCK)',
    city: 'Stockton',
    country: 'United States',
  },
  GGW: {
    label: 'Glasgow Intl (GGW)',
    city: 'Glasgow',
    country: 'United States',
  },
  HNA: {
    label: 'Hanamaki (HNA)',
    city: 'Hanamaki',
    country: 'Japan',
  },
  YMM: {
    label: 'Fort McMurray Airport (YMM)',
    city: 'Ft McMurray',
    country: 'Canada',
  },
  MBL: {
    label: 'Manistee Country Blacker Airport (MBL)',
    city: 'Manistee',
    country: 'United States',
  },
  PPL: {
    label: 'Phaplu Airport (PPL)',
    city: 'Phaplu',
    country: 'Nepal',
  },
  YBG: {
    label: 'Bagotville Airport (YBG)',
    city: 'Bagotville',
    country: 'Canada',
  },
  RCM: {
    label: 'Richmond Airport (RCM)',
    city: 'Richmond',
    country: 'Australia',
  },
  WAS: {
    label: 'All Airports (WAS)',
    city: 'Washington',
    country: 'DC',
  },
  YCO: {
    label: 'Kugluktuk (YCO)',
    city: 'Kugluktuk Coppermine',
    country: 'Canada',
  },
  MIL: {
    label: 'All Airports (MIL)',
    city: 'Milan',
    country: 'Italy',
  },
  AEL: {
    label: 'Albert Lea (AEL)',
    city: 'Albert Lea',
    country: 'United States',
  },
  HUD: {
    label: 'Humboldt (HUD)',
    city: 'Humboldt',
    country: 'United States',
  },
  GIB: {
    label: 'North Front (GIB)',
    city: 'Gibraltar',
    country: 'Gibraltar',
  },
  TMM: {
    label: 'Tamatave Airport (TMM)',
    city: 'Tamatave',
    country: 'Madagascar',
  },
  TBG: {
    label: 'Tabubil Airport (TBG)',
    city: 'Tabubil',
    country: 'Papua New Guinea',
  },
  SLQ: {
    label: 'Sleetmute Airport (SLQ)',
    city: 'Sleetmute',
    country: 'United States',
  },
  UAS: {
    label: 'Samburu Airport (UAS)',
    city: 'Samburu',
    country: 'Kenya',
  },
  ORE: {
    label: 'Orleans (ORE)',
    city: 'Orleans',
    country: 'France',
  },
  VRC: {
    label: 'Virac Airport (VRC)',
    city: 'Virac',
    country: 'Philippines',
  },
  AUK: {
    label: 'Alakanuk Airport (AUK)',
    city: 'Alakanuk',
    country: 'United States',
  },
  BGO: {
    label: 'Flesland (BGO)',
    city: 'Bergen',
    country: 'Norway',
  },
  LPU: {
    label: 'Long Apung Airport (LPU)',
    city: 'Long Apung',
    country: 'Indonesia',
  },
  DNV: {
    label: 'Vermilion Cty (DNV)',
    city: 'Danville',
    country: 'United States',
  },
  DRS: {
    label: 'Dresden (DRS)',
    city: 'Dresden',
    country: 'Germany',
  },
  CLQ: {
    label: 'Colima Mncpl (CLQ)',
    city: 'Colima',
    country: 'Mexico',
  },
  PWQ: {
    label: 'Pavlodar Airport (PWQ)',
    city: 'Pavlodar',
    country: 'Kazakhstan',
  },
  KPR: {
    label: 'Port Williams Sea Plane Base (KPR)',
    city: 'Port Williams',
    country: 'United States',
  },
  INZ: {
    label: 'In Salah Airport (INZ)',
    city: 'In Salah',
    country: 'Algeria',
  },
  PNP: {
    label: 'Girua Airport (PNP)',
    city: 'Popondetta',
    country: 'Papua New Guinea',
  },
  SLD: {
    label: 'Sliac Airport (SLD)',
    city: 'Sliac',
    country: 'Slovakia',
  },
  ENI: {
    label: 'El Nido Airport (ENI)',
    city: 'El Nido',
    country: 'Philippines',
  },
  RAR: {
    label: 'Rarotonga (RAR)',
    city: 'Rarotonga',
    country: 'Cook Islands',
  },
  TAB: {
    label: 'Crown Point (TAB)',
    city: 'Scarborough',
    country: 'Trinidad and Tobago',
  },
  MQM: {
    label: 'Mardin Airport (MQM)',
    city: 'Mardin',
    country: 'Turkey',
  },
  AHN: {
    label: 'Athens Airport (AHN)',
    city: 'Athens',
    country: 'GA',
  },
  VLV: {
    label: 'Carvajal Airport (VLV)',
    city: 'Valera',
    country: 'Venezuela',
  },
  KAW: {
    label: 'Kawthaung Airport (KAW)',
    city: 'Kawthaung',
    country: 'Myanmar (Burma)',
  },
  LEJ: {
    label: 'Leipzig/Halle (LEJ)',
    city: 'Leipzig',
    country: 'Germany',
  },
  MCH: {
    label: 'Gen M Serrano (MCH)',
    city: 'Machala',
    country: 'Ecuador',
  },
  MKY: {
    label: 'Mackay (MKY)',
    city: 'Mackay',
    country: 'Australia',
  },
  YPJ: {
    label: 'Aupaluk Airport (YPJ)',
    city: 'Aupaluk',
    country: 'Canada',
  },
  TBT: {
    label: 'Tabatinga Internacional Airport (TBT)',
    city: 'Tabatinga',
    country: 'Brazil',
  },
  RUI: {
    label: 'Ruidoso Mncpl (RUI)',
    city: 'Ruidoso',
    country: 'United States',
  },
  MKQ: {
    label: 'Mopah Airport (MKQ)',
    city: 'Merauke',
    country: 'Indonesia',
  },
  CCV: {
    label: 'Craig Cove Airport (CCV)',
    city: 'Craig Cove',
    country: 'Vanuatu',
  },
  FNA: {
    label: 'Lungi Intl (FNA)',
    city: 'Freetown',
    country: 'Sierra Leone',
  },
  CXP: {
    label: 'Tunggul Wulung Airport (CXP)',
    city: 'Cilacap',
    country: 'Indonesia',
  },
  CIH: {
    label: 'Changzhi Airport (CIH)',
    city: 'Changzhi',
    country: 'China',
  },
  HOU: {
    label: 'Hobby (HOU)',
    city: 'Houston',
    country: 'United States',
  },
  YBI: {
    label: 'Black Tickle Airport (YBI)',
    city: 'Black Tickle',
    country: 'Canada',
  },
  EVW: {
    label: 'Evanston Mncpl (EVW)',
    city: 'Evanston',
    country: 'United States',
  },
  KHS: {
    label: 'Khasab (KHS)',
    city: 'Khasab',
    country: 'Oman',
  },
  MAM: {
    label: 'Matamoros Intl (MAM)',
    city: 'Matamoros',
    country: 'Mexico',
  },
  NDG: {
    label: 'Qiqihar (NDG)',
    city: 'Qiqihar',
    country: 'China',
  },
  KYD: {
    label: 'Orchid Island Airport (KYD)',
    city: 'Orchid Island',
    country: 'Taiwan',
  },
  LWY: {
    label: 'Lawas Airport (LWY)',
    city: 'Lawas',
    country: 'Malaysia',
  },
  TSR: {
    label: 'Timisoara (TSR)',
    city: 'Timisoara',
    country: 'Romania',
  },
  YLW: {
    label: 'Kelowna Airport (YLW)',
    city: 'Kelowna',
    country: 'Canada',
  },
  CJB: {
    label: 'Peelamedu (CJB)',
    city: 'Coimbatore',
    country: 'India',
  },
  KIF: {
    label: 'Kingfisher Lake Airport (KIF)',
    city: 'Kingfisher Lake',
    country: 'Canada',
  },
  GUW: {
    label: 'Atyrau Airport (GUW)',
    city: 'Atyrau',
    country: 'Kazakhstan',
  },
  YWL: {
    label: 'Williams Lake (YWL)',
    city: 'Williams Lake',
    country: 'Canada',
  },
  SVO: {
    label: 'Sheremetyevo Airport (SVO)',
    city: 'Moscow',
    country: 'Russia',
  },
  LBU: {
    label: 'Labuan (LBU)',
    city: 'Labuan',
    country: 'Malaysia',
  },
  MWH: {
    label: 'Grant Cty (MWH)',
    city: 'Moses Lake',
    country: 'United States',
  },
  BHE: {
    label: 'Blenheim (BHE)',
    city: 'Blenheim',
    country: 'New Zealand',
  },
  GGE: {
    label: 'Georgetown County (GGE)',
    city: 'Georgetown',
    country: 'United States',
  },
  LWB: {
    label: 'Greenbrier (LWB)',
    city: 'Greenbrier',
    country: 'United States',
  },
  VNS: {
    label: 'Varanasi Babatpur (VNS)',
    city: 'Varanasi',
    country: 'India',
  },
  NLF: {
    label: 'Darnley Island Airport (NLF)',
    city: 'Darnley Island',
    country: 'Australia',
  },
  IEG: {
    label: 'Babimost Airport (IEG)',
    city: 'Zielona Gora',
    country: 'Poland',
  },
  VIG: {
    label: 'El Vigia Airport (VIG)',
    city: 'El Vigia',
    country: 'Venezuela',
  },
  NWI: {
    label: 'Norwich (NWI)',
    city: 'Norwich',
    country: 'United Kingdom',
  },
  RAH: {
    label: 'Rafha Airport (RAH)',
    city: 'Rafha',
    country: 'Saudi Arabia',
  },
  TJK: {
    label: 'Tokat (TJK)',
    city: 'Tokat',
    country: 'Turkey',
  },
  SFE: {
    label: 'San Fernando Airport (SFE)',
    city: 'San Fernando',
    country: 'Philippines',
  },
  PDP: {
    label: 'Punta del Este (PDP)',
    city: 'Punta del Este',
    country: 'Uruguay',
  },
  CCZ: {
    label: 'Chub Cay (CCZ)',
    city: 'Chub Cay',
    country: 'Bahamas',
  },
  UST: {
    label: 'St Augustine (UST)',
    city: 'St Augustine',
    country: 'United States',
  },
  PPT: {
    label: 'Tahiti',
    city: 'Papeete',
    country: 'French Polynesia and Tahiti',
  },
  JHY: {
    label: 'Hyatt Regency-H/P (JHY)',
    city: 'Cambridge',
    country: 'United States',
  },
  DEL: {
    label: 'Indira Gandhi International Airport (DEL)',
    city: 'New Delhi',
    country: 'India',
  },
  EGO: {
    label: 'Belgorod Airport (EGO)',
    city: 'Belgorod',
    country: 'Russia',
  },
  VAO: {
    label: 'Suavanao Airstrip (VAO)',
    city: 'Suavanao',
    country: 'Solomon Islands',
  },
  LGG: {
    label: 'Bierset (LGG)',
    city: 'Liege',
    country: 'Belgium',
  },
  FMI: {
    label: 'Kalemie Airport (FMI)',
    city: 'Kalemie',
    country: 'Democratic Republic of Congo',
  },
  BAY: {
    label: 'Baia Mare (BAY)',
    city: 'Baia Mare',
    country: 'Romania',
  },
  KIJ: {
    label: 'Niigata (KIJ)',
    city: 'Niigata',
    country: 'Japan',
  },
  DDG: {
    label: 'Dandong (DDG)',
    city: 'Dandong',
    country: 'China',
  },
  AYQ: {
    label: 'Ayers Rock Connellan (AYQ)',
    city: 'Ayers Rock',
    country: 'Australia',
  },
  KXK: {
    label: 'Komsomolsk Na Amure (KXK)',
    city: 'Komsomolsk Na Amure',
    country: 'Russia',
  },
  KOW: {
    label: 'Ganzhou Airport (KOW)',
    city: 'Ganzhou',
    country: 'China',
  },
  TLN: {
    label: 'Hyeres (TLN)',
    city: 'Toulon',
    country: 'France',
  },
  POJ: {
    label: 'Patos De Minas Airport (POJ)',
    city: 'Patos De Minas',
    country: 'Brazil',
  },
  YQF: {
    label: 'Red Deer Industrial (YQF)',
    city: 'Red Deer',
    country: 'Canada',
  },
  GXY: {
    label: 'Weld County (GXY)',
    city: 'Greeley',
    country: 'United States',
  },
  IAH: {
    label: 'Bush (IAH)',
    city: 'Houston',
    country: 'United States',
  },
  MDK: {
    label: 'Mbandaka Airport (MDK)',
    city: 'Mbandaka',
    country: 'Democratic Republic of Congo',
  },
  MKR: {
    label: 'Meekatharra Airport (MKR)',
    city: 'Meekatharra',
    country: 'Australia',
  },
  TPA: {
    label: 'Tampa (TPA)',
    city: 'Tampa',
    country: 'United States',
  },
  MOF: {
    label: 'Waioti Airport (MOF)',
    city: 'Maumere',
    country: 'Indonesia',
  },
  MQS: {
    label: 'Mustique (MQS)',
    city: 'Mustique Island',
    country: 'St Vincent and the Grenadines',
  },
  YSJ: {
    label: 'Saint John Airport (YSJ)',
    city: 'Saint John',
    country: 'Canada',
  },
  CWI: {
    label: 'Clinton Mncpl (CWI)',
    city: 'Clinton',
    country: 'United States',
  },
  AVU: {
    label: 'Avu Avu Airport (AVU)',
    city: 'Avu Avu',
    country: 'Solomon Islands',
  },
  BIM: {
    label: 'Bimini International Airport (BIM)',
    city: 'Bimini',
    country: 'Bahamas',
  },
  MRU: {
    label: 'SSR Intl (MRU)',
    city: 'Mauritius',
    country: 'Mauritius',
  },
  BDQ: {
    label: 'Vadodara (BDQ)',
    city: 'Vadodara',
    country: 'India',
  },
  BRV: {
    label: 'Bremerhaven (BRV)',
    city: 'Bremerhaven',
    country: 'Germany',
  },
  PSE: {
    label: 'Mercedita (PSE)',
    city: 'Ponce',
    country: 'Puerto Rico',
  },
  VHM: {
    label: 'Vilhelmina Airport (VHM)',
    city: 'Vilhelmina',
    country: 'Sweden',
  },
  JUZ: {
    label: 'Juzhou (JUZ)',
    city: 'Zhejiang',
    country: 'China',
  },
  BMK: {
    label: 'Borkum (BMK)',
    city: 'Borkum',
    country: 'Germany',
  },
  SCM: {
    label: 'Scammon Bay Sea Plane Base (SCM)',
    city: 'Scammon Bay',
    country: 'United States',
  },
  PBU: {
    label: 'Putao Airport (PBU)',
    city: 'Putao',
    country: 'Myanmar (Burma)',
  },
  CAG: {
    label: 'Elmas (CAG)',
    city: 'Cagliari',
    country: 'Italy',
  },
  NAY: {
    label: 'Nanyuan (NAY)',
    city: 'Beijing',
    country: 'China',
  },
  YIV: {
    label: 'Island Lake/Garden Hill Airport (YIV)',
    city: 'Island Lake/Garden Hill',
    country: 'Canada',
  },
  EYW: {
    label: 'Key West International Airport (EYW)',
    city: 'Key West',
    country: 'United States',
  },
  WSY: {
    label: 'Whitsunday (WSY)',
    city: 'Airlie Beach',
    country: 'Australia',
  },
  MWQ: {
    label: 'Magwe Airport (MWQ)',
    city: 'Magwe',
    country: 'Myanmar (Burma)',
  },
  BZB: {
    label: 'Bazaruto Island Airport (BZB)',
    city: 'Bazaruto Island',
    country: 'Mozambique',
  },
  SKB: {
    label: 'Golden Rock Airport (SKB)',
    city: 'St Kitts',
    country: 'St Kitts and Nevis',
  },
  FDK: {
    label: 'Frederick Mncpl (FDK)',
    city: 'Frederick',
    country: 'United States',
  },
  TED: {
    label: 'Thisted (TED)',
    city: 'Thisted',
    country: 'Denmark',
  },
  KKH: {
    label: 'Kongiganak Airport (KKH)',
    city: 'Kongiganak',
    country: 'United States',
  },
  MIG: {
    label: 'Mian Yang Airport (MIG)',
    city: 'Mian Yang',
    country: 'China',
  },
  BME: {
    label: 'Broome (BME)',
    city: 'Broome',
    country: 'Australia',
  },
  ATP: {
    label: 'Aitape (ATP)',
    city: 'Aitape',
    country: 'Papua New Guinea',
  },
  PBO: {
    label: 'Paraburdoo Airport (PBO)',
    city: 'Paraburdoo',
    country: 'Australia',
  },
  YGV: {
    label: 'Havre St Pierre Airport (YGV)',
    city: 'Havre St Pierre',
    country: 'Canada',
  },
  WMN: {
    label: 'Maroantsetra Airport (WMN)',
    city: 'Maroantsetra',
    country: 'Madagascar',
  },
  RYN: {
    label: 'Medis (RYN)',
    city: 'Royan',
    country: 'France',
  },
  BPY: {
    label: 'Besalampy Airport (BPY)',
    city: 'Besalampy',
    country: 'Madagascar',
  },
  YAK: {
    label: 'Yakutat Airport (YAK)',
    city: 'Yakutat',
    country: 'United States',
  },
  UAH: {
    label: 'Ua Huka Airport (UAH)',
    city: 'Ua Huka',
    country: 'French Polynesia and Tahiti',
  },
  TRO: {
    label: 'Taree (TRO)',
    city: 'Taree',
    country: 'Australia',
  },
  ZAT: {
    label: 'Zhaotong Airport (ZAT)',
    city: 'Zhaotong',
    country: 'China',
  },
  KKB: {
    label: 'Kitui Bay Sea Plane Base (KKB)',
    city: 'Kitui Bay',
    country: 'United States',
  },
  TEK: {
    label: 'Tatitlek Airport (TEK)',
    city: 'Tatitlek',
    country: 'United States',
  },
  LRA: {
    label: 'Larisa (LRA)',
    city: 'Larisa',
    country: 'Greece',
  },
  MBJ: {
    label: 'Sangster International Airport (MBJ)',
    city: 'Montego Bay',
    country: 'Jamaica',
  },
  RML: {
    label: 'Ratmalana (RML)',
    city: 'Colombo',
    country: 'Sri Lanka',
  },
  IKO: {
    label: 'Nikolski Air Force Station (IKO)',
    city: 'Nikolski',
    country: 'United States',
  },
  HEK: {
    label: 'Heihe Airport (HEK)',
    city: 'Heihe',
    country: 'China',
  },
  LYH: {
    label: 'Lynchburg (LYH)',
    city: 'Lynchburg',
    country: 'United States',
  },
  AAQ: {
    label: 'Anapa (AAQ)',
    city: 'Anapa',
    country: 'Russia',
  },
  COO: {
    label: 'Cotonou (COO)',
    city: 'Cotonou',
    country: 'Benin',
  },
  GDE: {
    label: 'Gode/Iddidole Airport (GDE)',
    city: 'Gode/Iddidole',
    country: 'Ethiopia',
  },
  GFL: {
    label: 'Warren Cty (GFL)',
    city: 'Glens Falls',
    country: 'United States',
  },
  KEF: {
    label: 'Keflavik International Airport (KEF)',
    city: 'Reykjavik',
    country: 'Iceland',
  },
  FMO: {
    label: 'Muenster (FMO)',
    city: 'Muenster',
    country: 'Germany',
  },
  BAQ: {
    label: 'E Cortissoz Airport (BAQ)',
    city: 'Barranquilla',
    country: 'Colombia',
  },
  CWC: {
    label: 'Chernovtsy (CWC)',
    city: 'Chernovtsy',
    country: 'Ukraine',
  },
  ABM: {
    label: 'Bamaga Airport (ABM)',
    city: 'Bamaga',
    country: 'Australia',
  },
  FAY: {
    label: 'Fayetteville (FAY)',
    city: 'Fayetteville',
    country: 'United States',
  },
  LXA: {
    label: 'Lhasa (LXA)',
    city: 'Lhasa',
    country: 'China',
  },
  MCY: {
    label: 'Maroochydore (MCY)',
    city: 'Maroochydore',
    country: 'Australia',
  },
  SUG: {
    label: 'Surigao Airport (SUG)',
    city: 'Surigao',
    country: 'Philippines',
  },
  NCN: {
    label: 'New Chenega Airport (NCN)',
    city: 'New Chenega',
    country: 'United States',
  },
  HTG: {
    label: 'Hatanga Airport (HTG)',
    city: 'Hatanga',
    country: 'Russia',
  },
  RAL: {
    label: 'Riverside Mncpl (RAL)',
    city: 'Riverside',
    country: 'United States',
  },
  YLP: {
    label: 'Mingan (YLP)',
    city: 'Mingan',
    country: 'Canada',
  },
  GVA: {
    label: 'Geneve-Cointrin Airport (GVA)',
    city: 'Geneva',
    country: 'Switzerland',
  },
  ASO: {
    label: 'Asosa Airport (ASO)',
    city: 'Asosa',
    country: 'Ethiopia',
  },
  KIR: {
    label: 'Kerry Cty (KIR)',
    city: 'Killarney',
    country: 'Ireland',
  },
  LBW: {
    label: 'Long Bawan Airport (LBW)',
    city: 'Long Bawan',
    country: 'Indonesia',
  },
  YZE: {
    label: 'Gore Bay Mncpl (YZE)',
    city: 'Gore Bay',
    country: 'Canada',
  },
  OKK: {
    label: 'Kokomo Mncpl (OKK)',
    city: 'Kokomo',
    country: 'United States',
  },
  MCW: {
    label: 'Mason City Mncpl (MCW)',
    city: 'Mason City',
    country: 'United States',
  },
  BRU: {
    label: 'National Airport (BRU)',
    city: 'Brussels',
    country: 'Belgium',
  },
  BZO: {
    label: 'Bolzano/Bozen (BZO)',
    city: 'Bolzano',
    country: 'Italy',
  },
  PDX: {
    label: 'Portland (PDX)',
    city: 'Portland',
    country: 'OR',
  },
  CGK: {
    label: 'Soekarno-Hatta Intl (CGK)',
    city: 'Jakarta',
    country: 'Indonesia',
  },
  ISU: {
    label: 'Sulaymaniyah International Airport (ISU)',
    city: 'Sulaymaniyah',
    country: 'Iraq',
  },
  ARB: {
    label: 'Ann Arbor (ARB)',
    city: 'Ann Arbor',
    country: 'United States',
  },
  BQB: {
    label: 'Busselton (BQB)',
    city: 'Busselton',
    country: 'Australia',
  },
  HZG: {
    label: 'Hanzhong Airport (HZG)',
    city: 'Hanzhong',
    country: 'China',
  },
  YQZ: {
    label: 'Quesnel (YQZ)',
    city: 'Quesnel',
    country: 'Canada',
  },
  ONX: {
    label: 'Colon (ONX)',
    city: 'Colon',
    country: 'Panama',
  },
  USN: {
    label: 'Ulsan (USN)',
    city: 'Ulsan',
    country: 'South Korea',
  },
  YQQ: {
    label: 'Comox Airport (YQQ)',
    city: 'Comox',
    country: 'Canada',
  },
  ROC: {
    label: 'Rochester (ROC)',
    city: 'Rochester',
    country: 'United States',
  },
  BDH: {
    label: 'Bandar Lengeh Airport (BDH)',
    city: 'Bandar Lengeh',
    country: 'Iran',
  },
  KZB: {
    label: 'Zachar Bay Sea Plane Base (KZB)',
    city: 'Zachar Bay',
    country: 'United States',
  },
  UCY: {
    label: 'Everette-Stewart (UCY)',
    city: 'Union City',
    country: 'United States',
  },
  DOY: {
    label: 'Dongying Airport (DOY)',
    city: 'Dongying',
    country: 'China',
  },
  PFJ: {
    label: 'Patreksfjordur (PFJ)',
    city: 'Patreksfjordur',
    country: 'Iceland',
  },
  ISA: {
    label: 'Mt Isa (ISA)',
    city: 'Mount Isa',
    country: 'Australia',
  },
  BVG: {
    label: 'Berlevag Airport (BVG)',
    city: 'Berlevag',
    country: 'Norway',
  },
  MKW: {
    label: 'Rendani Airport (MKW)',
    city: 'Manokwari',
    country: 'Indonesia',
  },
  YGX: {
    label: 'Gillam Airport (YGX)',
    city: 'Gillam',
    country: 'Canada',
  },
  WSX: {
    label: 'Westsound Airport (WSX)',
    city: 'Westsound',
    country: 'United States',
  },
  ADZ: {
    label: 'San Andres (ADZ)',
    city: 'San Andres Island',
    country: 'Colombia',
  },
  VGO: {
    label: 'Vigo (VGO)',
    city: 'Vigo',
    country: 'Spain',
  },
  BHQ: {
    label: 'Broken Hill (BHQ)',
    city: 'Broken Hill',
    country: 'Australia',
  },
  OLP: {
    label: 'Olympic Dam Airport (OLP)',
    city: 'Olympic Dam',
    country: 'Australia',
  },
  ZSA: {
    label: 'San Salvador (ZSA)',
    city: 'San Salvador Island',
    country: 'Bahamas',
  },
  CXL: {
    label: 'Calexico Intl (CXL)',
    city: 'Calexico',
    country: 'United States',
  },
  TRG: {
    label: 'Tauranga (TRG)',
    city: 'Tauranga',
    country: 'New Zealand',
  },
  COS: {
    label: 'Colorado Springs (COS)',
    city: 'Colorado Springs',
    country: 'United States',
  },
  PBD: {
    label: 'Porbandar Airport (PBD)',
    city: 'Porbandar',
    country: 'India',
  },
  KJA: {
    label: 'Krasnojarsk (KJA)',
    city: 'Krasnojarsk',
    country: 'Russia',
  },
  GGS: {
    label: 'Gobernador Gregores Airport (GGS)',
    city: 'Gobernador Gregores',
    country: 'Argentina',
  },
  PGA: {
    label: 'Page Municipal Airport (PGA)',
    city: 'Page',
    country: 'United States',
  },
  DAV: {
    label: 'Enrique Malek (DAV)',
    city: 'David',
    country: 'Panama',
  },
  TUK: {
    label: 'Turbat Airport (TUK)',
    city: 'Turbat',
    country: 'Pakistan',
  },
  SBD: {
    label: 'San Bernardino Intl (SBD)',
    city: 'San Bernardino',
    country: 'United States',
  },
  SDJ: {
    label: 'Sendai (SDJ)',
    city: 'Sendai',
    country: 'Japan',
  },
  ANF: {
    label: 'Cerro Moreno (ANF)',
    city: 'Antofagasta',
    country: 'Chile and Easter Island',
  },
  LGL: {
    label: 'Long Lellang Airport (LGL)',
    city: 'Long Lellang',
    country: 'Malaysia',
  },
  HCR: {
    label: 'Holy Cross Airport (HCR)',
    city: 'Holy Cross',
    country: 'United States',
  },
  NKC: {
    label: 'Nouakchott (NKC)',
    city: 'Nouakchott',
    country: 'Mauritania',
  },
  ANR: {
    label: 'Deurne (ANR)',
    city: 'Antwerp',
    country: 'Belgium',
  },
  DXR: {
    label: 'Danbury Mncpl (DXR)',
    city: 'Danbury',
    country: 'United States',
  },
  UKI: {
    label: 'Ukiah Mncpl (UKI)',
    city: 'Ukiah',
    country: 'United States',
  },
  AAC: {
    label: 'Al Arish (AAC)',
    city: 'El Arish',
    country: 'Egypt',
  },
  LDG: {
    label: 'Leshukonskoye Airport (LDG)',
    city: 'Leshukonskoye',
    country: 'Russia',
  },
  PIU: {
    label: 'Piura Airport (PIU)',
    city: 'Piura',
    country: 'Peru',
  },
  TEN: {
    label: 'Tongren Airport (TEN)',
    city: 'Tongren',
    country: 'China',
  },
  YZP: {
    label: 'Sandspit Airport (YZP)',
    city: 'Sandspit',
    country: 'Canada',
  },
  TDD: {
    label: 'Trinidad Airport (TDD)',
    city: 'Trinidad',
    country: 'Bolivia',
  },
  KEW: {
    label: 'Keewaywin Airport (KEW)',
    city: 'Keewaywin',
    country: 'Canada',
  },
  GSH: {
    label: 'Goshen (GSH)',
    city: 'Goshen',
    country: 'United States',
  },
  WRG: {
    label: 'Wrangell Sea Plane Base (WRG)',
    city: 'Wrangell',
    country: 'United States',
  },
  PIE: {
    label: 'St Petersburg (PIE)',
    city: 'St Petersburg',
    country: 'United States',
  },
  CJA: {
    label: 'Cajamarca Airport (CJA)',
    city: 'Cajamarca',
    country: 'Peru',
  },
  TMU: {
    label: 'Tambor (TMU)',
    city: 'Tambor',
    country: 'Costa Rica',
  },
  KTU: {
    label: 'Kota (KTU)',
    city: 'Kota',
    country: 'India',
  },
  DMD: {
    label: 'Doomadgee Airport (DMD)',
    city: 'Doomadgee',
    country: 'Australia',
  },
  PCL: {
    label: 'Capitan Rolden Airport (PCL)',
    city: 'Pucallpa',
    country: 'Peru',
  },
  CGB: {
    label: 'Marechal Rondon (CGB)',
    city: 'Cuiaba',
    country: 'Brazil',
  },
  MXC: {
    label: 'Monticello/San Juan (MXC)',
    city: 'Monticello',
    country: 'United States',
  },
  SWS: {
    label: 'Fairwood Common (SWS)',
    city: 'Swansea',
    country: 'United Kingdom',
  },
  KRT: {
    label: 'Civil (KRT)',
    city: 'Khartoum',
    country: 'Sudan',
  },
  SLM: {
    label: 'Matacan (SLM)',
    city: 'Salamanca',
    country: 'Spain',
  },
  LIW: {
    label: 'Loikaw Airport (LIW)',
    city: 'Loikaw',
    country: 'Myanmar (Burma)',
  },
  CEN: {
    label: 'Ciudad Obregon (CEN)',
    city: 'Ciudad Obregon',
    country: 'Mexico',
  },
  OWB: {
    label: 'Owensboro-Daviess (OWB)',
    city: 'Owensboro',
    country: 'United States',
  },
  SWX: {
    label: 'Shakawe (SWX)',
    city: 'Shakawe',
    country: 'Botswana',
  },
  KWL: {
    label: 'Guilin (KWL)',
    city: 'Guilin',
    country: 'China',
  },
  SHO: {
    label: 'Seolak (SHO)',
    city: 'Sokcho',
    country: 'South Korea',
  },
  IRC: {
    label: 'Circle City Airport (IRC)',
    city: 'Circle',
    country: 'United States',
  },
  MSP: {
    label: 'Minneapolis/St Paul (MSP)',
    city: 'Minneapolis',
    country: 'United States',
  },
  CME: {
    label: 'Ciudad Del Carmen (CME)',
    city: 'Ciudad Del Carmen',
    country: 'Mexico',
  },
  EME: {
    label: 'Emden (EME)',
    city: 'Emden',
    country: 'Germany',
  },
  LYG: {
    label: 'Lianyungang (LYG)',
    city: 'Lianyungang',
    country: 'China',
  },
  NQY: {
    label: 'Newquay Civil (NQY)',
    city: 'Newquay',
    country: 'United Kingdom',
  },
  ORN: {
    label: 'Es Senia (ORN)',
    city: 'Oran',
    country: 'Algeria',
  },
  YEA: {
    label: 'All Airports (YEA)',
    city: 'Edmonton',
    country: 'Canada',
  },
  SOU: {
    label: 'Southampton (SOU)',
    city: 'Southampton',
    country: 'United Kingdom',
  },
  NDY: {
    label: 'Sanday Airport (NDY)',
    city: 'Sanday',
    country: 'United Kingdom',
  },
  SKL: {
    label: 'Broadford (SKL)',
    city: 'Broadford',
    country: 'United Kingdom',
  },
  MLC: {
    label: 'McAlester (MLC)',
    city: 'McAlester',
    country: 'United States',
  },
  MYA: {
    label: 'Moruya Airport (MYA)',
    city: 'Moruya',
    country: 'Australia',
  },
  YFO: {
    label: 'Flin Flon (YFO)',
    city: 'Flin Flon',
    country: 'Canada',
  },
  PVD: {
    label: 'Providence (PVD)',
    city: 'Providence',
    country: 'United States',
  },
  NNY: {
    label: 'Nanyang Airport (NNY)',
    city: 'Nanyang',
    country: 'China',
  },
  TFS: {
    label: 'Sur Reina Sofia (TFS)',
    city: 'Tenerife',
    country: 'Spain',
  },
  MAV: {
    label: 'Maloelap Airport (MAV)',
    city: 'Maloelap',
    country: 'Marshall Islands',
  },
  RRT: {
    label: 'Warroad Intl (RRT)',
    city: 'Warroad',
    country: 'United States',
  },
  HDF: {
    label: 'Heringsdorf (HDF)',
    city: 'Heringsdorf',
    country: 'Germany',
  },
  CLM: {
    label: 'Fairchild International Airport (CLM)',
    city: 'Port Angeles',
    country: 'United States',
  },
  HUV: {
    label: 'Hudiksvall (HUV)',
    city: 'Hudiksvall',
    country: 'Sweden',
  },
  LRT: {
    label: 'Lann Bihoue (LRT)',
    city: 'Lorient',
    country: 'France',
  },
  TPP: {
    label: 'Tarapoto (TPP)',
    city: 'Tarapoto',
    country: 'Peru',
  },
  DUS: {
    label: 'Dusseldorf Airport (DUS)',
    city: 'Dusseldorf',
    country: 'Germany',
  },
  MKZ: {
    label: 'Batu Berendum (MKZ)',
    city: 'Malacca',
    country: 'Malaysia',
  },
  HPN: {
    label: 'Westchester County (HPN)',
    city: 'White Plains',
    country: 'United States',
  },
  KCQ: {
    label: 'Chignik Lake Airport (KCQ)',
    city: 'Chignik',
    country: 'United States',
  },
  CZN: {
    label: 'Chisana Field (CZN)',
    city: 'Chisana',
    country: 'United States',
  },
  RLA: {
    label: 'National (RLA)',
    city: 'Rolla',
    country: 'United States',
  },
  KLU: {
    label: 'Klagenfurt (KLU)',
    city: 'Klagenfurt',
    country: 'Austria',
  },
  AKV: {
    label: 'Akulivik Airport (AKV)',
    city: 'Akulivik',
    country: 'Canada',
  },
  UTU: {
    label: 'Ustupo Airport (UTU)',
    city: 'Ustupo',
    country: 'Panama',
  },
  DOU: {
    label: 'Dourados Airport (DOU)',
    city: 'Dourados',
    country: 'Brazil',
  },
  YUY: {
    label: 'Rouyn (YUY)',
    city: 'Rouyn',
    country: 'Canada',
  },
  EXI: {
    label: 'Excursion Inlet Sea Plane Base (EXI)',
    city: 'Excursion Inlet',
    country: 'United States',
  },
  OVB: {
    label: 'Tolmachevo (OVB)',
    city: 'Novosibirsk',
    country: 'Russia',
  },
  STN: {
    label: 'Stansted (STN)',
    city: 'London',
    country: 'United Kingdom',
  },
  AKA: {
    label: 'Ankang Airport (AKA)',
    city: 'Ankang',
    country: 'China',
  },
  SHV: {
    label: 'Shreveport (SHV)',
    city: 'Shreveport',
    country: 'United States',
  },
  ODB: {
    label: 'Cordoba (ODB)',
    city: 'Cordoba',
    country: 'Spain',
  },
  UFA: {
    label: 'Ufa (UFA)',
    city: 'Ufa',
    country: 'Russia',
  },
  BMA: {
    label: 'Bromma Airport (BMA)',
    city: 'Stockholm',
    country: 'Sweden',
  },
  MFJ: {
    label: 'Moala Airport (MFJ)',
    city: 'Moala',
    country: 'Fiji',
  },
  JFR: {
    label: 'Paamiut Airport (JFR)',
    city: 'Paamiut',
    country: 'Greenland',
  },
  PZH: {
    label: 'Zhob Airport (PZH)',
    city: 'Zhob',
    country: 'Pakistan',
  },
  LEA: {
    label: 'Learmonth Airport (LEA)',
    city: 'Learmonth',
    country: 'Australia',
  },
  LPX: {
    label: 'Liepaya (LPX)',
    city: 'Liepaya',
    country: 'Latvia',
  },
  MZV: {
    label: 'Mulu Airport (MZV)',
    city: 'Mulu',
    country: 'Malaysia',
  },
  STG: {
    label: 'St George Island Airport (STG)',
    city: 'Saint George Island',
    country: 'United States',
  },
  YPN: {
    label: 'Port Menier Airport (YPN)',
    city: 'Port Menier',
    country: 'Canada',
  },
  MPK: {
    label: 'Mokpo Airport (MPK)',
    city: 'Mokpo',
    country: 'South Korea',
  },
  WVL: {
    label: 'Robert Lafleur (WVL)',
    city: 'Waterville',
    country: 'United States',
  },
  PPP: {
    label: 'Whitsunday Coast Airport (PPP)',
    city: 'Proserpine',
    country: 'Australia',
  },
  KTW: {
    label: 'Pyrzowice (KTW)',
    city: 'Katowice',
    country: 'Poland',
  },
  BCI: {
    label: 'Barcaldine Airport (BCI)',
    city: 'Barcaldine',
    country: 'Australia',
  },
  AKY: {
    label: 'Civil Airport (AKY)',
    city: 'Sittwe',
    country: 'Myanmar (Burma)',
  },
  JQA: {
    label: 'Qaarsut Airport (JQA)',
    city: 'Qaarsut',
    country: 'Greenland',
  },
  PRA: {
    label: 'Parana (PRA)',
    city: 'Parana',
    country: 'Argentina',
  },
  LTO: {
    label: 'Loreto (LTO)',
    city: 'Loreto',
    country: 'Mexico',
  },
  DCM: {
    label: 'Mazamet (DCM)',
    city: 'Castres',
    country: 'France',
  },
  OSL: {
    label: 'Oslo Airport (OSL)',
    city: 'Oslo',
    country: 'Norway',
  },
  AFA: {
    label: 'San Rafael Airport (AFA)',
    city: 'San Rafael',
    country: 'Argentina',
  },
  UVE: {
    label: 'Ouvea Airport (UVE)',
    city: 'Ouvea',
    country: 'New Caledonia',
  },
  NUB: {
    label: 'Numbulwar Airport (NUB)',
    city: 'Numbulwar',
    country: 'Australia',
  },
  ACT: {
    label: 'Waco Municipal Airport (ACT)',
    city: 'Waco',
    country: 'United States',
  },
  SZT: {
    label: 'San Cristobal Arpt (SZT)',
    city: 'San Cristobal Las Casas',
    country: 'Mexico',
  },
  SHB: {
    label: 'Nakashibetsu Airport (SHB)',
    city: 'Nakashibetsu',
    country: 'Japan',
  },
  LKO: {
    label: 'Amausi (LKO)',
    city: 'Lucknow',
    country: 'India',
  },
  SSA: {
    label: 'Luis E Magalhaes (SSA)',
    city: 'Salvador',
    country: 'Brazil',
  },
  OAX: {
    label: 'Xoxocotlan Airport (OAX)',
    city: 'Oaxaca',
    country: 'Mexico',
  },
  DIR: {
    label: 'Aba Tenna D Yilma Airport (DIR)',
    city: 'Dire Dawa',
    country: 'Ethiopia',
  },
  MOL: {
    label: 'Aro (MOL)',
    city: 'Molde',
    country: 'Norway',
  },
  PVE: {
    label: 'El Porvenir Airport (PVE)',
    city: 'El Porvenir',
    country: 'Panama',
  },
  MXW: {
    label: 'Mandalgobi Airport (MXW)',
    city: 'Mandalgobi',
    country: 'Mongolia',
  },
  YTH: {
    label: 'Thompson Airport (YTH)',
    city: 'Thompson',
    country: 'Canada',
  },
  TTJ: {
    label: 'Tottori (TTJ)',
    city: 'Tottori',
    country: 'Japan',
  },
  NAK: {
    label: 'Nakhon Ratchasima (NAK)',
    city: 'Nakhon Ratchasima',
    country: 'Thailand',
  },
  YCW: {
    label: 'Chilliwack (YCW)',
    city: 'Chilliwack',
    country: 'Canada',
  },
  VOG: {
    label: 'Volgograd (VOG)',
    city: 'Volgograd',
    country: 'Russia',
  },
  MPN: {
    label: 'Mount Pleasant Airport (MPN)',
    city: 'Mount Pleasant',
    country: 'Falkland Islands (UK)',
  },
  LWC: {
    label: 'Lawrence Mncpl (LWC)',
    city: 'Lawrence',
    country: 'United States',
  },
  AKI: {
    label: 'Akiak Airport (AKI)',
    city: 'Akiak',
    country: 'United States',
  },
  BNX: {
    label: 'Banja Luka Airport (BNX)',
    city: 'Banja Luka',
    country: 'Bosnia and Herzegovina',
  },
  NAW: {
    label: 'Narathiwat Airport (NAW)',
    city: 'Narathiwat',
    country: 'Thailand',
  },
  VLL: {
    label: 'Valladolid (VLL)',
    city: 'Valladolid',
    country: 'Spain',
  },
  TNU: {
    label: 'Newton Mncpl (TNU)',
    city: 'Newton',
    country: 'United States',
  },
  SDL: {
    label: 'Harnosand (SDL)',
    city: 'Sundsvall',
    country: 'Sweden',
  },
  ISC: {
    label: 'St Marys (ISC)',
    city: 'Isles of Scilly',
    country: 'United Kingdom',
  },
  AMY: {
    label: 'Ambatomainty Airport (AMY)',
    city: 'Ambatomainty',
    country: 'Madagascar',
  },
  BWI: {
    label: 'Baltimore (BWI)',
    city: 'Baltimore',
    country: 'United States',
  },
  BEU: {
    label: 'Bedourie Airport (BEU)',
    city: 'Bedourie',
    country: 'Australia',
  },
  SAH: {
    label: "Sana'a Intl (SAH)",
    city: "Sana'a",
    country: 'Yemen',
  },
  FLA: {
    label: 'Capitolio Airport (FLA)',
    city: 'Florencia',
    country: 'Colombia',
  },
  XBJ: {
    label: 'Birjand Airport (XBJ)',
    city: 'Birjand',
    country: 'Iran',
  },
  MEA: {
    label: 'Macae (MEA)',
    city: 'Macae',
    country: 'Brazil',
  },
  GXF: {
    label: 'Seiyun Airport (GXF)',
    city: 'Seiyun',
    country: 'Yemen',
  },
  SOC: {
    label: 'Adi Sumarmo (SOC)',
    city: 'Solo',
    country: 'Indonesia',
  },
  LPB: {
    label: 'El Alto (LPB)',
    city: 'La Paz',
    country: 'Bolivia',
  },
  YPB: {
    label: 'Port Alberni (YPB)',
    city: 'Port Alberni',
    country: 'Canada',
  },
  AVN: {
    label: 'Avignon/Caumont (AVN)',
    city: 'Avignon',
    country: 'France',
  },
  YNP: {
    label: 'Natuashish Airport (YNP)',
    city: 'Natuashish',
    country: 'Canada',
  },
  UGC: {
    label: 'Urgench (UGC)',
    city: 'Urgench',
    country: 'Uzbekistan',
  },
  XKS: {
    label: 'Kasabonika Airport (XKS)',
    city: 'Kasabonika',
    country: 'Canada',
  },
  UVF: {
    label: 'St Lucia (UVF)',
    city: 'Castries',
    country: 'St Lucia',
  },
  MKT: {
    label: 'Mankato Mncpl (MKT)',
    city: 'Mankato',
    country: 'United States',
  },
  OAG: {
    label: 'Springhill (OAG)',
    city: 'Orange',
    country: 'Australia',
  },
  ECH: {
    label: 'Echuca (ECH)',
    city: 'Echuca',
    country: 'Australia',
  },
  BUY: {
    label: 'Bunbury (BUY)',
    city: 'Bunbury',
    country: 'Australia',
  },
  DMU: {
    label: 'Dimapur Airport (DMU)',
    city: 'Dimapur',
    country: 'India',
  },
  YUD: {
    label: 'Umiujag Airport (YUD)',
    city: 'Umiujag',
    country: 'Canada',
  },
  TTT: {
    label: 'Taitung (TTT)',
    city: 'Taitung',
    country: 'Taiwan',
  },
  SFQ: {
    label: 'Sanliurfa (SFQ)',
    city: 'Sanliurfa',
    country: 'Turkey',
  },
  ODY: {
    label: 'Oudomxay Airport (ODY)',
    city: 'Oudomxay',
    country: 'Laos',
  },
  CLT: {
    label: 'Charlotte (CLT)',
    city: 'Charlotte',
    country: 'United States',
  },
  NLA: {
    label: 'Ndola (NLA)',
    city: 'Ndola',
    country: 'Zambia',
  },
  VGA: {
    label: 'Vijayawa (VGA)',
    city: 'Vijayawada',
    country: 'India',
  },
  GYM: {
    label: 'Guaymas (GYM)',
    city: 'Guaymas',
    country: 'Mexico',
  },
  BHK: {
    label: 'Bukhara (BHK)',
    city: 'Bukhara',
    country: 'Uzbekistan',
  },
  AQA: {
    label: 'Araraquara Airport (AQA)',
    city: 'Araraquara',
    country: 'Brazil',
  },
  KET: {
    label: 'Keng Tung Airport (KET)',
    city: 'Keng Tung',
    country: 'Myanmar (Burma)',
  },
  KTM: {
    label: 'Tribhuvan (KTM)',
    city: 'Kathmandu',
    country: 'Nepal',
  },
  ABD: {
    label: 'Abadan Airport (ABD)',
    city: 'Abadan',
    country: 'Iran',
  },
  OHD: {
    label: 'Ohrid (OHD)',
    city: 'Ohrid',
    country: 'Macedonia',
  },
  ULU: {
    label: 'Gulu Airport (ULU)',
    city: 'Gulu',
    country: 'Uganda',
  },
  IXU: {
    label: 'Chikkalthana (IXU)',
    city: 'Aurangabad',
    country: 'India',
  },
  NWA: {
    label: 'Moheli Airport (NWA)',
    city: 'Moheli',
    country: 'Comoros',
  },
  RDZ: {
    label: 'Marcillac (RDZ)',
    city: 'Rodez',
    country: 'France',
  },
  MQT: {
    label: 'Marquette (MQT)',
    city: 'Marquette',
    country: 'United States',
  },
  AZO: {
    label: 'Battle Creek International Airport (AZO)',
    city: 'Kalamazoo',
    country: 'United States',
  },
  TCU: {
    label: 'Thaba Nchu (TCU)',
    city: 'Thaba Nchu',
    country: 'South Africa',
  },
  PUF: {
    label: 'Uzein (PUF)',
    city: 'Pau',
    country: 'France',
  },
  YZZ: {
    label: 'Trail (YZZ)',
    city: 'Trail',
    country: 'Canada',
  },
  YST: {
    label: 'Ste Therese Point Airport (YST)',
    city: 'Sainte Therese Point',
    country: 'Canada',
  },
  MJF: {
    label: 'Kjaerstad (MJF)',
    city: 'Mosjoen',
    country: 'Norway',
  },
  BIQ: {
    label: 'Biarritz/Parme (BIQ)',
    city: 'Biarritz',
    country: 'France',
  },
  DJJ: {
    label: 'Sentani Airport (DJJ)',
    city: 'Jayapura',
    country: 'Indonesia',
  },
  PZI: {
    label: 'Pan Zhi Hua Bao An Ying (PZI)',
    city: 'Pan Zhi Hua',
    country: 'China',
  },
  MQZ: {
    label: 'Margaret River (MQZ)',
    city: 'Margaret River',
    country: 'Australia',
  },
  JTY: {
    label: 'Astypalaia Airport (JTY)',
    city: 'Astypalaia Island',
    country: 'Greece',
  },
  BFN: {
    label: 'Bloemfontein Intl (BFN)',
    city: 'Bloemfontein',
    country: 'South Africa',
  },
  ACH: {
    label: 'Altenrhein Airport (ACH)',
    city: 'Altenrhein',
    country: 'Switzerland',
  },
  GRO: {
    label: 'Costa Brava (GRO)',
    city: 'Gerona',
    country: 'Spain',
  },
  IXA: {
    label: 'Singerbhil Airport (IXA)',
    city: 'Agartala',
    country: 'India',
  },
  YQV: {
    label: 'Yorkton (YQV)',
    city: 'Yorkton',
    country: 'Canada',
  },
  PKW: {
    label: 'Selebi-Phikwe (PKW)',
    city: 'Selebi-Phikwe',
    country: 'Botswana',
  },
  YGZ: {
    label: 'Grise Fiord Airport (YGZ)',
    city: 'Grise Fiord',
    country: 'Canada',
  },
  IWJ: {
    label: 'Iwami Airport (IWJ)',
    city: 'Iwami',
    country: 'Japan',
  },
  UPN: {
    label: 'Uruapan (UPN)',
    city: 'Uruapan',
    country: 'Mexico',
  },
  JSH: {
    label: 'Sitia (JSH)',
    city: 'Sitia',
    country: 'Greece',
  },
  LBP: {
    label: 'Long Banga Airfield (LBP)',
    city: 'Long Banga',
    country: 'Malaysia',
  },
  KVG: {
    label: 'Kavieng Airport (KVG)',
    city: 'Kavieng',
    country: 'Papua New Guinea',
  },
  IWD: {
    label: 'Gogebic and Iron Country Airport (IWD)',
    city: 'Ironwood',
    country: 'United States',
  },
  WMB: {
    label: 'Warrnambool (WMB)',
    city: 'Warrnambool',
    country: 'Australia',
  },
  JJU: {
    label: 'Qaqortoq Heliport (JJU)',
    city: 'Qaqortoq',
    country: 'Greenland',
  },
  RBF: {
    label: 'Big Bear Mncpl (RBF)',
    city: 'Big Bear City',
    country: 'United States',
  },
  OPU: {
    label: 'Balimo Airport (OPU)',
    city: 'Balimo',
    country: 'Papua New Guinea',
  },
  KAJ: {
    label: 'Kajaani (KAJ)',
    city: 'Kajaani',
    country: 'Finland',
  },
  PDV: {
    label: 'Plovdiv (PDV)',
    city: 'Plovdiv',
    country: 'Bulgaria',
  },
  ACP: {
    label: 'Sahand Airport (ACP)',
    city: 'Sahand',
    country: 'Iran',
  },
  ISS: {
    label: 'Wiscasset Mncpl (ISS)',
    city: 'Wiscasset',
    country: 'United States',
  },
  HLW: {
    label: 'Hluhluwe (HLW)',
    city: 'Hluhluwe',
    country: 'South Africa',
  },
  KTN: {
    label: 'Ketchikan International Airport (KTN)',
    city: 'Ketchikan',
    country: 'United States',
  },
  TAY: {
    label: 'Tartu (TAY)',
    city: 'Tartu',
    country: 'Estonia',
  },
  CSL: {
    label: 'Metropolitan (CSL)',
    city: 'San Luis Obispo',
    country: 'United States',
  },
  LAP: {
    label: 'Leon Airport (LAP)',
    city: 'La Paz',
    country: 'Mexico',
  },
  MAS: {
    label: 'Momote Airport (MAS)',
    city: 'Manus Island',
    country: 'Papua New Guinea',
  },
  KTS: {
    label: 'Brevig Mission Airport (KTS)',
    city: 'Teller Mission',
    country: 'United States',
  },
  MON: {
    label: 'Mount Cook (MON)',
    city: 'Mount Cook',
    country: 'New Zealand',
  },
  MOB: {
    label: 'Mobile Municipal Airport (MOB)',
    city: 'Mobile',
    country: 'United States',
  },
  ADE: {
    label: 'Aden Intl (ADE)',
    city: 'Aden',
    country: 'Yemen',
  },
  LGU: {
    label: 'Logan-Cache (LGU)',
    city: 'Logan',
    country: 'United States',
  },
  PSC: {
    label: 'Pasco (PSC)',
    city: 'Pasco',
    country: 'United States',
  },
  KAN: {
    label: 'Aminu Kano International Airport (KAN)',
    city: 'Kano',
    country: 'Nigeria',
  },
  QJY: {
    label: 'Kolobrzeg Bus Service (QJY)',
    city: 'Kolobrzeg',
    country: 'Poland',
  },
  BUD: {
    label: 'Ferihegy (BUD)',
    city: 'Budapest',
    country: 'Hungary',
  },
  HCN: {
    label: 'Hengchun (HCN)',
    city: 'Hengchun',
    country: 'Taiwan',
  },
  PHL: {
    label: 'Philadelphia (PHL)',
    city: 'Philadelphia',
    country: 'United States',
  },
  KGL: {
    label: 'Gregoire Kayibanda (KGL)',
    city: 'Kigali',
    country: 'Rwanda',
  },
  CHI: {
    label: 'All Airports (CHI)',
    city: 'Chicago',
    country: 'United States',
  },
  SYD: {
    label: 'Kingsford Smith Airport (SYD)',
    city: 'Sydney',
    country: 'Australia',
  },
  BTZ: {
    label: 'Bursa (BTZ)',
    city: 'Bursa',
    country: 'Turkey',
  },
  WTA: {
    label: 'Tambohorano Airport (WTA)',
    city: 'Tambohorano',
    country: 'Madagascar',
  },
  CKD: {
    label: 'Crooked Creek Airport (CKD)',
    city: 'Crooked Creek',
    country: 'United States',
  },
  TGR: {
    label: 'Touggourt Airport (TGR)',
    city: 'Touggourt',
    country: 'Algeria',
  },
  KUO: {
    label: 'Kuopio (KUO)',
    city: 'Kuopio',
    country: 'Finland',
  },
  CFG: {
    label: 'Cienfeugos Mncpl (CFG)',
    city: 'Cienfuegos',
    country: 'Cuba',
  },
  PQC: {
    label: 'Duong Dang Airport (PQC)',
    city: 'Phu Quoc',
    country: 'Vietnam',
  },
  XTG: {
    label: 'Thargomindah Airport (XTG)',
    city: 'Thargomindah',
    country: 'Australia',
  },
  ASF: {
    label: 'Astrakhan (ASF)',
    city: 'Astrakhan',
    country: 'Russia',
  },
  BTL: {
    label: 'WK Kellogg Regl (BTL)',
    city: 'Battle Creek',
    country: 'United States',
  },
  MHX: {
    label: 'Manihiki Island Airport (MHX)',
    city: 'Manihiki Island',
    country: 'Cook Islands',
  },
  PXM: {
    label: 'Puerto Escondido (PXM)',
    city: 'Puerto Escondido',
    country: 'Mexico',
  },
  WVB: {
    label: 'Rooikop (WVB)',
    city: 'Walvis Bay',
    country: 'Namibia',
  },
  VLD: {
    label: 'Valdosta Regional Airport (VLD)',
    city: 'Valdosta',
    country: 'United States',
  },
  JER: {
    label: 'Jersey (JER)',
    city: 'Jersey',
    country: 'United Kingdom',
  },
  GAN: {
    label: 'Gan/Seenu Airport (GAN)',
    city: 'Gan Island',
    country: 'Republic of Maldives',
  },
  CGR: {
    label: 'Campo Grande (CGR)',
    city: 'Campo Grande',
    country: 'Brazil',
  },
  BAH: {
    label: 'Bahrain Intl (BAH)',
    city: 'Bahrain',
    country: 'Bahrain',
  },
  PYC: {
    label: 'Playon Chico Airport (PYC)',
    city: 'Playon Chico',
    country: 'Panama',
  },
  OUL: {
    label: 'Oulu (OUL)',
    city: 'Oulu',
    country: 'Finland',
  },
  LPT: {
    label: 'Lampang Airport (LPT)',
    city: 'Lampang',
    country: 'Thailand',
  },
  INN: {
    label: 'Kranebitten (INN)',
    city: 'Innsbruck',
    country: 'Austria',
  },
  BVB: {
    label: 'Boa Vista Airport (BVB)',
    city: 'Boa Vista',
    country: 'Brazil',
  },
  MOO: {
    label: 'Moomba Airport (MOO)',
    city: 'Moomba',
    country: 'Australia',
  },
  LSE: {
    label: 'La Crosse Municipal Airport (LSE)',
    city: 'La Crosse',
    country: 'United States',
  },
  RUS: {
    label: 'Marau Sound Airport (RUS)',
    city: 'Marau Sound',
    country: 'Solomon Islands',
  },
  TSP: {
    label: 'Kern County (TSP)',
    city: 'Tehachapi',
    country: 'United States',
  },
  KEK: {
    label: 'Ekwok Airport (KEK)',
    city: 'Ekwok',
    country: 'United States',
  },
  YUT: {
    label: 'Repulse Bay Airport (YUT)',
    city: 'Repulse Bay',
    country: 'Canada',
  },
  DTD: {
    label: 'Datadawai Airport (DTD)',
    city: 'Datadawai',
    country: 'Indonesia',
  },
  TIN: {
    label: 'Tindouf Airport (TIN)',
    city: 'Tindouf',
    country: 'Algeria',
  },
  UET: {
    label: 'Quetta (UET)',
    city: 'Quetta',
    country: 'Pakistan',
  },
  CUR: {
    label: 'Aeropuerto Hato (CUR)',
    city: 'Willemstad',
    country: 'Curacao',
  },
  HRK: {
    label: 'Kharkov (HRK)',
    city: 'Kharkov',
    country: 'Ukraine',
  },
  PUG: {
    label: 'Port Augusta/Pagas (PUG)',
    city: 'Port Augusta',
    country: 'Australia',
  },
  MTM: {
    label: 'Metlakatla Sea Plane Base (MTM)',
    city: 'Metlakatla',
    country: 'United States',
  },
  BMV: {
    label: 'Phung-Duc Airport (BMV)',
    city: 'Banmethuot',
    country: 'Vietnam',
  },
  CXJ: {
    label: 'Campo dos Bugres (CXJ)',
    city: 'Caxias do Sul',
    country: 'Brazil',
  },
  MEY: {
    label: 'Meghauli Airport (MEY)',
    city: 'Meghauli',
    country: 'Nepal',
  },
  PMW: {
    label: 'Palmas Airport (PMW)',
    city: 'Palmas',
    country: 'Brazil',
  },
  NYK: {
    label: 'Nanyuki (NYK)',
    city: 'Nanyuki',
    country: 'Kenya',
  },
  WKJ: {
    label: 'Wakkanai (WKJ)',
    city: 'Wakkanai',
    country: 'Japan',
  },
  IAS: {
    label: 'Iasi (IAS)',
    city: 'Iasi',
    country: 'Romania',
  },
  ZSE: {
    label: 'St Pierre de la Reunion Airport (ZSE)',
    city: 'St Pierre',
    country: 'Reunion',
  },
  HOT: {
    label: 'Memorial Fld (HOT)',
    city: 'Hot Springs',
    country: 'United States',
  },
  YNC: {
    label: 'Wemindji Airport (YNC)',
    city: 'Wemindji',
    country: 'Canada',
  },
  YNJ: {
    label: 'Yanji (YNJ)',
    city: 'Yanji',
    country: 'China',
  },
  IQQ: {
    label: 'Cavancha (IQQ)',
    city: 'Iquique',
    country: 'Chile and Easter Island',
  },
  HBX: {
    label: 'Hubli Airport (HBX)',
    city: 'Hubli',
    country: 'India',
  },
  MTJ: {
    label: 'Montrose County Airport (MTJ)',
    city: 'Montrose',
    country: 'United States',
  },
  VEY: {
    label: 'Vestmannaeyjar (VEY)',
    city: 'Vestmannaeyjar',
    country: 'Iceland',
  },
  YOG: {
    label: 'Ogoki Airport (YOG)',
    city: 'Ogoki',
    country: 'Canada',
  },
  MUC: {
    label: 'Franz Josef Strauss Airport (MUC)',
    city: 'Munich',
    country: 'Germany',
  },
  KNA: {
    label: 'Vina Del Mar (KNA)',
    city: 'Vina del Mar',
    country: 'Chile and Easter Island',
  },
  ZRH: {
    label: 'Zurich Airport (ZRH)',
    city: 'Zurich',
    country: 'Switzerland',
  },
  BUR: {
    label: 'Burbank (BUR)',
    city: 'Burbank',
    country: 'United States',
  },
  SFA: {
    label: 'Sfax El Maou (SFA)',
    city: 'Sfax',
    country: 'Tunisia',
  },
  KTG: {
    label: 'Ketapang Airport (KTG)',
    city: 'Ketapang',
    country: 'Indonesia',
  },
  KSM: {
    label: "Saint Mary's Airport (KSM)",
    city: "Saint Mary's",
    country: 'United States',
  },
  ANI: {
    label: 'Aniak Airport (ANI)',
    city: 'Aniak',
    country: 'United States',
  },
  MHQ: {
    label: 'Mariehamn (MHQ)',
    city: 'Mariehamn',
    country: 'Finland',
  },
  SKE: {
    label: 'Skien (SKE)',
    city: 'Skien',
    country: 'Norway',
  },
  NLD: {
    label: 'Quetzalcoatl Intl (NLD)',
    city: 'Nuevo Laredo',
    country: 'Mexico',
  },
  TER: {
    label: 'Lajes Airport (TER)',
    city: 'Terceira',
    country: 'Portugal',
  },
  SZG: {
    label: 'WA Mozart (SZG)',
    city: 'Salzburg',
    country: 'Austria',
  },
  AAY: {
    label: 'Al Ghaydah Airport (AAY)',
    city: 'Al Ghaydah',
    country: 'Yemen',
  },
  XRY: {
    label: 'La Parra (XRY)',
    city: 'Jerez de la Frontera',
    country: 'Spain',
  },
  WXN: {
    label: 'Wanxian Airport (WXN)',
    city: 'Wanxian',
    country: 'China',
  },
  OGN: {
    label: 'Yonaguni Airport (OGN)',
    city: 'Yonaguni',
    country: 'Japan',
  },
  MWF: {
    label: 'Maewo Airport (MWF)',
    city: 'Maewo',
    country: 'Vanuatu',
  },
  MLB: {
    label: 'Melbourne International Airport (MLB)',
    city: 'Melbourne',
    country: 'United States',
  },
  ZGS: {
    label: 'Gethsemani Airport (ZGS)',
    city: 'Gethsemani',
    country: 'Canada',
  },
  HKD: {
    label: 'Hakodate (HKD)',
    city: 'Hakodate',
    country: 'Japan',
  },
  DUJ: {
    label: 'Jefferson Cty (DUJ)',
    city: 'Du Bois',
    country: 'United States',
  },
  ORD: {
    label: "O'Hare (ORD)",
    city: 'Chicago',
    country: 'United States',
  },
  VRL: {
    label: 'Vila Real (VRL)',
    city: 'Vila Real',
    country: 'Portugal',
  },
  MSL: {
    label: 'Muscle Shoals Airport (MSL)',
    city: 'Muscle Shoals',
    country: 'United States',
  },
  LTA: {
    label: 'Tzaneen (LTA)',
    city: 'Tzaneen',
    country: 'South Africa',
  },
  VKO: {
    label: 'Vnukovo Airport (VKO)',
    city: 'Moscow',
    country: 'Russia',
  },
  JRH: {
    label: 'Rowriah Airport (JRH)',
    city: 'Jorhat',
    country: 'India',
  },
  IXY: {
    label: 'Kandla Airport (IXY)',
    city: 'Kandla',
    country: 'India',
  },
  RBY: {
    label: 'Ruby Airport (RBY)',
    city: 'Ruby',
    country: 'United States',
  },
  QRO: {
    label: 'Queretaro Mncpl (QRO)',
    city: 'Queretaro',
    country: 'Mexico',
  },
  PPF: {
    label: 'Tri-City (PPF)',
    city: 'Parsons',
    country: 'United States',
  },
  MVT: {
    label: 'Mataiva Airport (MVT)',
    city: 'Mataiva',
    country: 'French Polynesia and Tahiti',
  },
  TOD: {
    label: 'Tioman (TOD)',
    city: 'Tioman',
    country: 'Malaysia',
  },
  KAE: {
    label: 'Kake Sea Plane Base (KAE)',
    city: 'Kake',
    country: 'United States',
  },
  ORF: {
    label: 'Norfolk (ORF)',
    city: 'Norfolk',
    country: 'VA',
  },
  MFU: {
    label: 'Mfuwe (MFU)',
    city: 'Mfuwe',
    country: 'Zambia',
  },
  TAT: {
    label: 'Poprad/Tatry Airport (TAT)',
    city: 'Poprad/Tatry',
    country: 'Slovakia',
  },
  MUE: {
    label: 'Kamuela (MUE)',
    city: 'Kamuela',
    country: 'United States',
  },
  SAX: {
    label: 'Sambu Airport (SAX)',
    city: 'Sambu',
    country: 'Panama',
  },
  BPN: {
    label: 'Sepinggan (BPN)',
    city: 'Balikpapan',
    country: 'Indonesia',
  },
  HND: {
    label: 'Haneda Airport (HND)',
    city: 'Tokyo',
    country: 'Japan',
  },
  DUB: {
    label: 'Dublin Airport (DUB)',
    city: 'Dublin',
    country: 'Ireland',
  },
  MKP: {
    label: 'Makemo Airport (MKP)',
    city: 'Makemo',
    country: 'French Polynesia and Tahiti',
  },
  AUH: {
    label: 'Abu Dhabi Intl (AUH)',
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
  },
  KKU: {
    label: 'Ekuk Airport (KKU)',
    city: 'Ekuk',
    country: 'United States',
  },
  MCM: {
    label: 'H/P De Monte Carlo (MCM)',
    city: 'Monte Carlo',
    country: 'Monaco',
  },
  MOA: {
    label: 'Orestes Acosta Airport (MOA)',
    city: 'Moa',
    country: 'Cuba',
  },
  PUE: {
    label: 'Puerto Obaldia Airport (PUE)',
    city: 'Puerto Obaldia',
    country: 'Panama',
  },
  OTR: {
    label: 'Coto 47 Airport (OTR)',
    city: 'Coto 47',
    country: 'Costa Rica',
  },
  HIR: {
    label: 'Henderson Intl (HIR)',
    city: 'Honiara',
    country: 'Solomon Islands',
  },
  YUX: {
    label: 'Hall Beach Airport (YUX)',
    city: 'Hall Beach',
    country: 'Canada',
  },
  GRQ: {
    label: 'Eelde (GRQ)',
    city: 'Groningen',
    country: 'Netherlands',
  },
  CWL: {
    label: 'Cardiff-Wales (CWL)',
    city: 'Cardiff',
    country: 'United Kingdom',
  },
  NDK: {
    label: 'Namdrik Island Airport (NDK)',
    city: 'Namdrik Island',
    country: 'Marshall Islands',
  },
  MEX: {
    label: 'Juarez International Airport (MEX)',
    city: 'Mexico City',
    country: 'Mexico',
  },
  YZS: {
    label: 'Coral Harbour (YZS)',
    city: 'Coral Harbour',
    country: 'Canada',
  },
  DUG: {
    label: 'Bisbee-Douglas Intl (DUG)',
    city: 'Douglas',
    country: 'United States',
  },
  KGK: {
    label: 'New Koliganek Airport (KGK)',
    city: 'New Koliganek',
    country: 'United States',
  },
  YLR: {
    label: 'Leaf Rapids Mncpl (YLR)',
    city: 'Leaf Rapids',
    country: 'Canada',
  },
  CAF: {
    label: 'Carauari Airport (CAF)',
    city: 'Carauari',
    country: 'Brazil',
  },
  MXH: {
    label: 'Moro Airport (MXH)',
    city: 'Moro',
    country: 'Papua New Guinea',
  },
  ALA: {
    label: 'Almaty (ALA)',
    city: 'Almaty',
    country: 'Kazakhstan',
  },
  ETH: {
    label: 'Elat (ETH)',
    city: 'Eilat',
    country: 'Israel',
  },
  CPE: {
    label: 'Campeche Intl (CPE)',
    city: 'Campeche',
    country: 'Mexico',
  },
  SNP: {
    label: 'Saint Paul Island Airport (SNP)',
    city: 'Saint Paul Island',
    country: 'United States',
  },
  DNK: {
    label: 'Dnepropetrovsk (DNK)',
    city: 'Dnepropetrovsk',
    country: 'Ukraine',
  },
  LIG: {
    label: 'Bellegarde (LIG)',
    city: 'Limoges',
    country: 'France',
  },
  YPD: {
    label: 'Parry Sound Mncpl (YPD)',
    city: 'Parry Sound',
    country: 'Canada',
  },
  OER: {
    label: 'Ornskoldsvik (OER)',
    city: 'Ornskoldsvik',
    country: 'Sweden',
  },
  BEP: {
    label: 'Bellary Airport (BEP)',
    city: 'Bellary',
    country: 'India',
  },
  LEB: {
    label: 'Lebanon (LEB)',
    city: 'Lebanon',
    country: 'United States',
  },
  RES: {
    label: 'Resistencia Airport (RES)',
    city: 'Resistencia',
    country: 'Argentina',
  },
  YSY: {
    label: 'Sachs Harbour Airport (YSY)',
    city: 'Sachs Harbour',
    country: 'Canada',
  },
  HCA: {
    label: 'Big Spring Mncpl (HCA)',
    city: 'Big Spring',
    country: 'United States',
  },
  LZH: {
    label: 'Liuzhou Airport (LZH)',
    city: 'Liuzhou',
    country: 'China',
  },
  ELF: {
    label: 'El Fasher Airport (ELF)',
    city: 'El Fasher',
    country: 'Sudan',
  },
  WBM: {
    label: 'Wapenamanda Airport (WBM)',
    city: 'Wapenamanda',
    country: 'Papua New Guinea',
  },
  KUS: {
    label: 'Kulusuk Airport (KUS)',
    city: 'Kulusuk',
    country: 'Greenland',
  },
  WYA: {
    label: 'Whyalla (WYA)',
    city: 'Whyalla',
    country: 'Australia',
  },
  DEH: {
    label: 'Decorah Mncpl (DEH)',
    city: 'Decorah',
    country: 'United States',
  },
  CJS: {
    label: 'A Gonzalez Intl (CJS)',
    city: 'Ciudad Juarez',
    country: 'Mexico',
  },
  RNE: {
    label: 'Renaison (RNE)',
    city: 'Roanne',
    country: 'France',
  },
  DLI: {
    label: 'Lienkhang (DLI)',
    city: 'Da Lat',
    country: 'Vietnam',
  },
  TTN: {
    label: 'Mercer County Airport (TTN)',
    city: 'Trenton',
    country: 'United States',
  },
  HGR: {
    label: 'Wash County Regional Airport (HGR)',
    city: 'Hagerstown',
    country: 'United States',
  },
  FAB: {
    label: 'Farnborough (FAB)',
    city: 'Farnborough',
    country: 'United Kingdom',
  },
  KVA: {
    label: 'Megas Alexandros (KVA)',
    city: 'Kavala',
    country: 'Greece',
  },
  TGG: {
    label: 'Sultan Mahmood (TGG)',
    city: 'Kuala Terengganu',
    country: 'Malaysia',
  },
  OBO: {
    label: 'Obihiro (OBO)',
    city: 'Obihiro',
    country: 'Japan',
  },
  KWT: {
    label: 'Kwethluk Airport (KWT)',
    city: 'Kwethluk',
    country: 'United States',
  },
  DTT: {
    label: 'All Airports (DTT)',
    city: 'Detroit',
    country: 'United States',
  },
  BTS: {
    label: 'Ivanka (BTS)',
    city: 'Bratislava',
    country: 'Slovakia',
  },
  GOJ: {
    label: 'Nizhniy Novgorod (GOJ)',
    city: 'Nizhniy Novgorod',
    country: 'Russia',
  },
  LNP: {
    label: 'Wise Mncpl (LNP)',
    city: 'Wise',
    country: 'United States',
  },
  BGC: {
    label: 'Braganca (BGC)',
    city: 'Braganca',
    country: 'Portugal',
  },
  JJN: {
    label: 'Jinjiang Airport (JJN)',
    city: 'Jinjiang',
    country: 'China',
  },
  HAV: {
    label: 'Jose Marti Intl (HAV)',
    city: 'Havana',
    country: 'Cuba',
  },
  USH: {
    label: 'Islas Malvinas (USH)',
    city: 'Ushuaia',
    country: 'Argentina',
  },
  RTA: {
    label: 'Rotuma Island Airport (RTA)',
    city: 'Rotuma Island',
    country: 'Fiji',
  },
  SFJ: {
    label: 'Kangerlussuaq (SFJ)',
    city: 'Kangerlussuaq',
    country: 'Greenland',
  },
  MIR: {
    label: 'Habib Bourguiba Intl (MIR)',
    city: 'Monastir',
    country: 'Tunisia',
  },
  HTS: {
    label: 'Tri-State/Milton Airport (HTS)',
    city: 'Huntington',
    country: 'United States',
  },
  YBV: {
    label: 'Berens River Airport (YBV)',
    city: 'Berens River',
    country: 'Canada',
  },
  KLL: {
    label: 'Levelock Airport (KLL)',
    city: 'Levelock',
    country: 'United States',
  },
  INW: {
    label: 'Winslow Mncpl (INW)',
    city: 'Winslow',
    country: 'United States',
  },
  NVA: {
    label: 'La Marguita Airport (NVA)',
    city: 'Neiva',
    country: 'Colombia',
  },
  AGT: {
    label: 'Alejo Garcia Airport (AGT)',
    city: 'Ciudad Del Este',
    country: 'Paraguay',
  },
  BRS: {
    label: 'Bristol Intl (BRS)',
    city: 'Bristol',
    country: 'United Kingdom',
  },
  BJL: {
    label: 'Yundum Intl (BJL)',
    city: 'Banjul',
    country: 'Gambia',
  },
  MEI: {
    label: 'Meridian (MEI)',
    city: 'Meridian',
    country: 'United States',
  },
  TCE: {
    label: 'Tulcea (TCE)',
    city: 'Tulcea',
    country: 'Romania',
  },
  JVL: {
    label: 'Rock Cty (JVL)',
    city: 'Janesville',
    country: 'United States',
  },
  IAM: {
    label: 'In Amenas Airport (IAM)',
    city: 'In Amenas',
    country: 'Algeria',
  },
  RIY: {
    label: 'Riyan Airport (RIY)',
    city: 'Riyan',
    country: 'Yemen',
  },
  PPV: {
    label: 'Port Protection Airport (PPV)',
    city: 'Port Protection',
    country: 'United States',
  },
  PLQ: {
    label: 'Klaipeda/Palanga Int (PLQ)',
    city: 'Palanga',
    country: 'Lithuania',
  },
  YUL: {
    label: 'Montreal (YUL)',
    city: 'Montreal',
    country: 'Canada',
  },
  STM: {
    label: 'Eduardo Gomes Airport (STM)',
    city: 'Santarem',
    country: 'Brazil',
  },
  NSH: {
    label: 'Now Shahr Airport (NSH)',
    city: 'Now Shahr',
    country: 'Iran',
  },
  SNS: {
    label: 'Salinas (SNS)',
    city: 'Salinas',
    country: 'United States',
  },
  MCG: {
    label: 'Mc Grath Airport (MCG)',
    city: 'McGrath',
    country: 'United States',
  },
  DRW: {
    label: 'Darwin (DRW)',
    city: 'Darwin',
    country: 'Australia',
  },
  CMB: {
    label: 'Bandaranaike (CMB)',
    city: 'Colombo',
    country: 'Sri Lanka',
  },
  PMK: {
    label: 'Palm Island Airport (PMK)',
    city: 'Palm Island',
    country: 'Australia',
  },
  TSJ: {
    label: 'Tsushima Airport (TSJ)',
    city: 'Tsushima',
    country: 'Japan',
  },
  RVT: {
    label: 'Ravensthorpe Airport (RVT)',
    city: 'Ravensthorpe',
    country: 'Australia',
  },
  MSA: {
    label: 'Muskrat Dam Airport (MSA)',
    city: 'Muskrat Dam',
    country: 'Canada',
  },
  EDR: {
    label: 'Edward River Airport (EDR)',
    city: 'Edward River',
    country: 'Australia',
  },
  TSF: {
    label: 'Treviso (TSF)',
    city: 'Venice',
    country: 'Italy',
  },
  EVE: {
    label: 'Evenes Airport (EVE)',
    city: 'Harstad-Narvik',
    country: 'Norway',
  },
  NLK: {
    label: 'Norfolk Island Intl (NLK)',
    city: 'Norfolk Island',
    country: 'Norfolk Islands',
  },
  FTA: {
    label: 'Futuna Airport (FTA)',
    city: 'Futuna Island',
    country: 'Vanuatu',
  },
  SVL: {
    label: 'Savonlinna (SVL)',
    city: 'Savonlinna',
    country: 'Finland',
  },
  LRU: {
    label: 'Las Cruces-Crawford (LRU)',
    city: 'Las Cruces',
    country: 'United States',
  },
  KHH: {
    label: 'Kaohsiung Intl (KHH)',
    city: 'Kaohsiung',
    country: 'Taiwan',
  },
  MZL: {
    label: 'Santaguida Airport (MZL)',
    city: 'Manizales',
    country: 'Colombia',
  },
  PQQ: {
    label: 'Port Macquarie (PQQ)',
    city: 'Port Macquarie',
    country: 'Australia',
  },
  TTQ: {
    label: 'Tortuquero (TTQ)',
    city: 'Tortuguero',
    country: 'Costa Rica',
  },
  DLZ: {
    label: 'Dalanzadgad Airport (DLZ)',
    city: 'Dalanzadgad',
    country: 'Mongolia',
  },
  POZ: {
    label: 'Lawica (POZ)',
    city: 'Poznan',
    country: 'Poland',
  },
  AGL: {
    label: 'Wanigela Airport (AGL)',
    city: 'Wanigela',
    country: 'Papua New Guinea',
  },
  WSN: {
    label: 'South Naknek Airport (WSN)',
    city: 'South Naknek',
    country: 'United States',
  },
  BLF: {
    label: 'Mercer County Airport (BLF)',
    city: 'Bluefield',
    country: 'United States',
  },
  PUJ: {
    label: 'Punta Cana Airport (PUJ)',
    city: 'Punta Cana',
    country: 'Dominican Republic',
  },
  WVI: {
    label: 'Watsonville Mcpl (WVI)',
    city: 'Watsonville',
    country: 'United States',
  },
  RKS: {
    label: 'Rock Springs-Sweetwater County Airport (RKS)',
    city: 'Rock Springs',
    country: 'United States',
  },
  GED: {
    label: 'Sussex County (GED)',
    city: 'Georgetown',
    country: 'United States',
  },
  ATN: {
    label: 'Namatanai Airport (ATN)',
    city: 'Namatanai',
    country: 'Papua New Guinea',
  },
  JIL: {
    label: 'Jilin (JIL)',
    city: 'Jilin',
    country: 'China',
  },
  BKZ: {
    label: 'Bukoba Airport (BKZ)',
    city: 'Bukoba',
    country: 'Tanzania',
  },
  KZS: {
    label: 'Kastelorizo Airport (KZS)',
    city: 'Kastelorizo',
    country: 'Greece',
  },
  BRW: {
    label: 'Wiley Post (BRW)',
    city: 'Barrow',
    country: 'United States',
  },
  FEN: {
    label: 'Fernando De Noronha Airport (FEN)',
    city: 'Fernando de Noronha',
    country: 'Brazil',
  },
  WAG: {
    label: 'Wanganui (WAG)',
    city: 'Wanganui',
    country: 'New Zealand',
  },
  YQC: {
    label: 'Quaqtaq Airport (YQC)',
    city: 'Quaqtaq',
    country: 'Canada',
  },
  RTB: {
    label: 'Roatan (RTB)',
    city: 'Roatan',
    country: 'Honduras',
  },
  WIL: {
    label: 'Wilson (WIL)',
    city: 'Nairobi',
    country: 'Kenya',
  },
  SDF: {
    label: 'Louisville (SDF)',
    city: 'Louisville',
    country: 'United States',
  },
  KHE: {
    label: 'Kherson (KHE)',
    city: 'Kherson',
    country: 'Ukraine',
  },
  OXB: {
    label: 'Osvaldo Vieira (OXB)',
    city: 'Bissau',
    country: 'Guinea-Bissau',
  },
  GBE: {
    label: 'Sir Seretse Khama (GBE)',
    city: 'Gaborone',
    country: 'Botswana',
  },
  LJN: {
    label: 'Brazoria County (LJN)',
    city: 'Lake Jackson',
    country: 'United States',
  },
  MNZ: {
    label: 'Manassas/Davis Field (MNZ)',
    city: 'Manassas',
    country: 'United States',
  },
  CMW: {
    label: 'Ignacio Agramonte (CMW)',
    city: 'Camaguey',
    country: 'Cuba',
  },
  BWD: {
    label: 'Brownwood Mncpl (BWD)',
    city: 'Brownwood',
    country: 'United States',
  },
  NAP: {
    label: 'Naples Airport (NAP)',
    city: 'Naples',
    country: 'Italy',
  },
  FRD: {
    label: 'Friday Harbor (FRD)',
    city: 'Friday Harbor',
    country: 'United States',
  },
  YOS: {
    label: 'Billy Bishop Regl (YOS)',
    city: 'Owen Sound',
    country: 'Canada',
  },
  TWT: {
    label: 'Tawitawi Airport (TWT)',
    city: 'Tawitawi',
    country: 'Philippines',
  },
  CGA: {
    label: 'Craig Mncpl (CGA)',
    city: 'Craig',
    country: 'United States',
  },
  LIF: {
    label: 'Lifou Airport (LIF)',
    city: 'Lifou',
    country: 'New Caledonia',
  },
  RIE: {
    label: 'Rice Lake Mncpl (RIE)',
    city: 'Rice Lake',
    country: 'United States',
  },
  YGP: {
    label: 'Metropolitan Area Airport (YGP)',
    city: 'Gaspe',
    country: 'Canada',
  },
  OZH: {
    label: 'Zaporozhye (OZH)',
    city: 'Zaporozhye',
    country: 'Ukraine',
  },
  TLS: {
    label: 'Blagnac Airport (TLS)',
    city: 'Toulouse',
    country: 'France',
  },
  CMN: {
    label: 'Mohamed V (CMN)',
    city: 'Casablanca',
    country: 'Morocco',
  },
  HTO: {
    label: 'East Hampton (HTO)',
    city: 'East Hampton',
    country: 'United States',
  },
  KUU: {
    label: 'Bhuntar (KUU)',
    city: 'Kulu',
    country: 'India',
  },
  RRG: {
    label: 'Rodrigues Island Airport (RRG)',
    city: 'Rodrigues Island',
    country: 'Mauritius',
  },
  GYL: {
    label: 'Argyle Airport (GYL)',
    city: 'Argyle',
    country: 'Australia',
  },
  RMP: {
    label: 'Rampart Airport (RMP)',
    city: 'Rampart',
    country: 'United States',
  },
  YHR: {
    label: 'Chevery Airport (YHR)',
    city: 'Chevery',
    country: 'Canada',
  },
  MJI: {
    label: 'Mitiga, Tripoli Airport (MJI)',
    city: 'Mitiga',
    country: 'Tripoli',
  },
  PIT: {
    label: 'Pittsburgh (PIT)',
    city: 'Pittsburgh',
    country: 'United States',
  },
  AEX: {
    label: 'Alexandria International Airport (AEX)',
    city: 'Alexandria',
    country: 'LA',
  },
  WWP: {
    label: 'Whale Pass Airport (WWP)',
    city: 'Whale Pass',
    country: 'United States',
  },
  PVH: {
    label: 'Belmonte (PVH)',
    city: 'Porto Velho',
    country: 'Brazil',
  },
  CIW: {
    label: 'Canouan Island Mncpl (CIW)',
    city: 'Canouan Island',
    country: 'St Vincent and the Grenadines',
  },
  WRL: {
    label: 'Worland Municipal Airport (WRL)',
    city: 'Worland',
    country: 'United States',
  },
  GMR: {
    label: 'Gambier Island Airport (GMR)',
    city: 'Gambier Island',
    country: 'French Polynesia and Tahiti',
  },
  ABQ: {
    label: 'Albuquerque (ABQ)',
    city: 'Albuquerque',
    country: 'United States',
  },
  NEG: {
    label: 'Negril (NEG)',
    city: 'Negril',
    country: 'Jamaica',
  },
  HCQ: {
    label: 'Halls Creek (HCQ)',
    city: 'Halls Creek',
    country: 'Australia',
  },
  FLR: {
    label: 'Peretola Airport (FLR)',
    city: 'Florence',
    country: 'Italy',
  },
  LGA: {
    label: 'LaGuardia (LGA)',
    city: 'New York City',
    country: 'United States',
  },
  WAI: {
    label: 'Antsohihy Airport (WAI)',
    city: 'Antsohihy',
    country: 'Madagascar',
  },
  CJJ: {
    label: 'Cheongju Airport (CJJ)',
    city: 'Cheongju',
    country: 'South Korea',
  },
  SBP: {
    label: 'County Airport (SBP)',
    city: 'San Luis Obispo',
    country: 'United States',
  },
  UAP: {
    label: 'Ua Pou Airport (UAP)',
    city: 'Ua Pou',
    country: 'Marquesas Island',
  },
  PAE: {
    label: 'Snohomish Cty (PAE)',
    city: 'Everett',
    country: 'United States',
  },
  IRK: {
    label: 'Kirksville Mnpl (IRK)',
    city: 'Kirksville',
    country: 'United States',
  },
  NER: {
    label: 'Neryungri Airport (NER)',
    city: 'Neryungri',
    country: 'Russia',
  },
  ACK: {
    label: 'Nantucket Memorial Airport (ACK)',
    city: 'Nantucket',
    country: 'United States',
  },
  EOH: {
    label: 'Enriq Olaya Herrera (EOH)',
    city: 'Medellin',
    country: 'Colombia',
  },
  OLH: {
    label: 'Old Harbor Sea Plane Base (OLH)',
    city: 'Old Harbor',
    country: 'United States',
  },
  NUP: {
    label: 'Nunapitchuk Airport (NUP)',
    city: 'Nunapitchuk',
    country: 'United States',
  },
  GOP: {
    label: 'Gorakhpur Airport (GOP)',
    city: 'Gorakhpur',
    country: 'India',
  },
  YSR: {
    label: 'Nanisivik Airport (YSR)',
    city: 'Nanisivik',
    country: 'Canada',
  },
  EVV: {
    label: 'Dress Regional Airport (EVV)',
    city: 'Evansville',
    country: 'United States',
  },
  AST: {
    label: 'Astoria (AST)',
    city: 'Astoria',
    country: 'United States',
  },
  ANV: {
    label: 'Anvik Airport (ANV)',
    city: 'Anvik',
    country: 'United States',
  },
  MZG: {
    label: 'Makung Airport (MZG)',
    city: 'Makung',
    country: 'Taiwan',
  },
  ZTH: {
    label: 'Zakinthos (ZTH)',
    city: 'Zakinthos',
    country: 'Greece',
  },
  AVP: {
    label: 'Wilkes-Barre International Airport (AVP)',
    city: 'Wilkes-Barre',
    country: 'United States',
  },
  MEL: {
    label: 'Tullamarine Airport (MEL)',
    city: 'Melbourne',
    country: 'Australia',
  },
  MZM: {
    label: 'Frescaty (MZM)',
    city: 'Metz',
    country: 'France',
  },
  BFV: {
    label: 'Buri Ram Airport (BFV)',
    city: 'Buri Ram',
    country: 'Thailand',
  },
  YRF: {
    label: 'Cartwright Airport (YRF)',
    city: 'Cartwright',
    country: 'Canada',
  },
  ZTB: {
    label: 'Tete-A-La Baleine Airport (ZTB)',
    city: 'Tete-A-La Baleine',
    country: 'Canada',
  },
  SEA: {
    label: 'Seattle (SEA)',
    city: 'Seattle',
    country: 'United States',
  },
  BNN: {
    label: 'Bronnoy (BNN)',
    city: 'Bronnoysund',
    country: 'Norway',
  },
  GCK: {
    label: 'Garden City Regional Airport (GCK)',
    city: 'Garden City',
    country: 'United States',
  },
  ABI: {
    label: 'Abilene Municipal Airport (ABI)',
    city: 'Abilene',
    country: 'United States',
  },
  EPU: {
    label: 'Parnu (EPU)',
    city: 'Parnu',
    country: 'Estonia',
  },
  CBB: {
    label: 'J Wilsterman (CBB)',
    city: 'Cochabamba',
    country: 'Bolivia',
  },
  RJA: {
    label: 'Rajahmundry Airport (RJA)',
    city: 'Rajahmundry',
    country: 'India',
  },
  TIE: {
    label: 'Tippi Airport (TIE)',
    city: 'Tippi',
    country: 'Ethiopia',
  },
  KKC: {
    label: 'Khon Kaen (KKC)',
    city: 'Khon Kaen',
    country: 'Thailand',
  },
  SQA: {
    label: 'Santa Ynez Mncpl (SQA)',
    city: 'Santa Ynez',
    country: 'United States',
  },
  SOM: {
    label: 'El Tigre Airport (SOM)',
    city: 'San Tome',
    country: 'Venezuela',
  },
  CCU: {
    label: 'Subhas Chandra Bose (CCU)',
    city: 'Kolkata',
    country: 'India',
  },
  CVU: {
    label: 'Corvo Island (Azores) Airport (CVU)',
    city: 'Corvo Island (Azores)',
    country: 'Portugal',
  },
  TOA: {
    label: 'Torrance Mncpl (TOA)',
    city: 'Torrance',
    country: 'United States',
  },
  ATQ: {
    label: 'Raja Sansi (ATQ)',
    city: 'Amritsar',
    country: 'India',
  },
  ZIG: {
    label: 'Ziguinchor Airport (ZIG)',
    city: 'Ziguinchor',
    country: 'Senegal',
  },
  LMM: {
    label: 'Federal (LMM)',
    city: 'Los Mochis',
    country: 'Mexico',
  },
  BOC: {
    label: 'Bocas del Toro (BOC)',
    city: 'Bocas del Toro',
    country: 'Panama',
  },
  BAM: {
    label: 'Lander County (BAM)',
    city: 'Battle Mountain',
    country: 'United States',
  },
  PBJ: {
    label: 'Paama Airport (PBJ)',
    city: 'Paama',
    country: 'Vanuatu',
  },
  KKR: {
    label: 'Kaukura Atoll Airport (KKR)',
    city: 'Kaukura Atoll',
    country: 'French Polynesia and Tahiti',
  },
  INT: {
    label: 'Smith Reynolds (INT)',
    city: 'Winston-Salem',
    country: 'United States',
  },
  GZO: {
    label: 'Gizo Airport (GZO)',
    city: 'Gizo',
    country: 'Solomon Islands',
  },
  SAD: {
    label: 'Safford Mncpl (SAD)',
    city: 'Safford',
    country: 'United States',
  },
  STT: {
    label: 'Cyril E King Airport (STT)',
    city: 'St Thomas',
    country: 'US Virgin Islands',
  },
  IPI: {
    label: 'San Luis Airport (IPI)',
    city: 'Ipiales',
    country: 'Colombia',
  },
  BJZ: {
    label: 'Talaveral La Real (BJZ)',
    city: 'Badajoz',
    country: 'Spain',
  },
  BJC: {
    label: 'Jeffco (BJC)',
    city: 'Broomfield',
    country: 'United States',
  },
  OVD: {
    label: 'Oviedo (OVD)',
    city: 'Asturias',
    country: 'Spain',
  },
  YAY: {
    label: 'St Anthony Airport (YAY)',
    city: 'Saint Anthony',
    country: 'Canada',
  },
  DGO: {
    label: 'Guadalupe Victoria (DGO)',
    city: 'Durango',
    country: 'Mexico',
  },
  WNZ: {
    label: 'Wenzhou (WNZ)',
    city: 'Wenzhou',
    country: 'China',
  },
  ULO: {
    label: 'Ulaangom Airport (ULO)',
    city: 'Ulaangom',
    country: 'Mongolia',
  },
  KVD: {
    label: 'Gyandzha Airport (KVD)',
    city: 'Gyandzha',
    country: 'Azerbaijan',
  },
  YIH: {
    label: 'Yichang (YIH)',
    city: 'Yichang',
    country: 'China',
  },
  YLL: {
    label: 'Lloydminster Airport (YLL)',
    city: 'Lloydminster',
    country: 'Canada',
  },
  VVO: {
    label: 'Vladivostok (VVO)',
    city: 'Vladivostok',
    country: 'Russia',
  },
  MJD: {
    label: 'Mohenjo Daro Airport (MJD)',
    city: 'Mohenjo Daro',
    country: 'Pakistan',
  },
  CNY: {
    label: 'Canyonlands Field Airport (CNY)',
    city: 'Moab',
    country: 'United States',
  },
  ADL: {
    label: 'Adelaide (ADL)',
    city: 'Adelaide',
    country: 'Australia',
  },
  FFT: {
    label: 'Capitol City (FFT)',
    city: 'Frankfort',
    country: 'United States',
  },
  GRW: {
    label: 'Graciosa Island Airport (GRW)',
    city: 'Graciosa Island (Azores)',
    country: 'Portugal',
  },
  LSI: {
    label: 'Sumburgh (LSI)',
    city: 'Lerwick',
    country: 'United Kingdom',
  },
  DBV: {
    label: 'Dubrovnik (DBV)',
    city: 'Dubrovnik',
    country: 'Croatia',
  },
  AAN: {
    label: 'Al Ain (AAN)',
    city: 'Al Ain',
    country: 'United Arab Emirates',
  },
  IGG: {
    label: 'Igiugig Airport (IGG)',
    city: 'Igiugig',
    country: 'United States',
  },
  HIS: {
    label: 'Hayman Island Airport (HIS)',
    city: 'Hayman Island',
    country: 'Australia',
  },
  ISW: {
    label: 'Alexander Fld (ISW)',
    city: 'Wisconsin Rapids',
    country: 'United States',
  },
  HLT: {
    label: 'Hamilton (HLT)',
    city: 'Hamilton',
    country: 'Australia',
  },
  LIS: {
    label: 'Lisboa (LIS)',
    city: 'Lisbon',
    country: 'Portugal',
  },
  FNT: {
    label: 'Bishop Airport (FNT)',
    city: 'Flint',
    country: 'United States',
  },
  TCT: {
    label: 'Takotna Airport (TCT)',
    city: 'Takotna',
    country: 'United States',
  },
  KGD: {
    label: 'Khrabrovo (KGD)',
    city: 'Kaliningrad',
    country: 'Russia',
  },
  UBP: {
    label: 'Muang Ubon Airport (UBP)',
    city: 'Ubon Ratchathani',
    country: 'Thailand',
  },
  PCT: {
    label: 'Princeton (PCT)',
    city: 'Princeton',
    country: 'United States',
  },
  HUX: {
    label: 'Huatulco Airport (HUX)',
    city: 'Huatulco',
    country: 'Mexico',
  },
  RBA: {
    label: 'Sale (RBA)',
    city: 'Rabat',
    country: 'Morocco',
  },
  BSX: {
    label: 'Bassein Airport (BSX)',
    city: 'Bassein',
    country: 'Myanmar (Burma)',
  },
  YYF: {
    label: 'Penticton Airport (YYF)',
    city: 'Penticton',
    country: 'Canada',
  },
  URT: {
    label: 'Surat Thani (URT)',
    city: 'Surat Thani',
    country: 'Thailand',
  },
  CCR: {
    label: 'Buchanan Field (CCR)',
    city: 'Concord',
    country: 'United States',
  },
  THN: {
    label: 'Trollhattan (THN)',
    city: 'Trollhattan',
    country: 'Sweden',
  },
  ZRJ: {
    label: 'Round Lake Airport (ZRJ)',
    city: 'Round Lake',
    country: 'Canada',
  },
  RVA: {
    label: 'Farafangana Airport (RVA)',
    city: 'Farafangana',
    country: 'Madagascar',
  },
  LMT: {
    label: 'Kingsley Field (LMT)',
    city: 'Klamath Falls',
    country: 'United States',
  },
  HOI: {
    label: 'Hao Island Airport (HOI)',
    city: 'Hao Island',
    country: 'French Polynesia and Tahiti',
  },
  HJR: {
    label: 'Khajuraho (HJR)',
    city: 'Khajuraho',
    country: 'India',
  },
  KRS: {
    label: 'Kjevik (KRS)',
    city: 'Kristiansand',
    country: 'Norway',
  },
  KID: {
    label: 'Kristianstad (KID)',
    city: 'Kristianstad',
    country: 'Sweden',
  },
  ROT: {
    label: 'Rotorua (ROT)',
    city: 'Rotorua',
    country: 'New Zealand',
  },
  UEL: {
    label: 'Quelimane Airport (UEL)',
    city: 'Quelimane',
    country: 'Mozambique',
  },
  EMK: {
    label: 'Emmonak Airport (EMK)',
    city: 'Emmonak',
    country: 'United States',
  },
  POM: {
    label: 'Jackson Fld (POM)',
    city: 'Port Moresby',
    country: 'Papua New Guinea',
  },
  AUU: {
    label: 'Aurukun Mission Airport (AUU)',
    city: 'Aurukun Mission',
    country: 'Australia',
  },
  PUQ: {
    label: 'Presidente Ibanez (PUQ)',
    city: 'Punta Arenas',
    country: 'Chile and Easter Island',
  },
  OLY: {
    label: 'Olney-Noble (OLY)',
    city: 'Olney',
    country: 'United States',
  },
  TBI: {
    label: 'The Bight Airport (TBI)',
    city: 'The Bight',
    country: 'Bahamas',
  },
  GRD: {
    label: 'County (GRD)',
    city: 'Greenwood',
    country: 'United States',
  },
  VAW: {
    label: 'Vardoe Airport (VAW)',
    city: 'Vardoe',
    country: 'Norway',
  },
  AZD: {
    label: 'Yazd Airport (AZD)',
    city: 'Yazd',
    country: 'Iran',
  },
  ACV: {
    label: 'Arcata Airport (ACV)',
    city: 'Arcata',
    country: 'United States',
  },
  BQN: {
    label: 'Aguadilla (BQN)',
    city: 'Aguadilla',
    country: 'Puerto Rico',
  },
  STR: {
    label: 'Echterdingen Airport (STR)',
    city: 'Stuttgart',
    country: 'Germany',
  },
  DAL: {
    label: 'Love (DAL)',
    city: 'Dallas',
    country: 'United States',
  },
  TBO: {
    label: 'Tabora Airport (TBO)',
    city: 'Tabora',
    country: 'Tanzania',
  },
  BKG: {
    label: 'Branson Airport (BKG)',
    city: 'Branson',
    country: 'United States',
  },
  YKS: {
    label: 'Yakutsk Airport (YKS)',
    city: 'Yakutsk',
    country: 'Russia',
  },
  GEO: {
    label: 'Cheddi Jagan Intl (GEO)',
    city: 'Georgetown',
    country: 'Guyana',
  },
  ZGY: {
    label: 'Kuching Port (ZGY)',
    city: 'Kuching Port',
    country: 'Malaysia',
  },
  ULP: {
    label: 'Quilpie Airport (ULP)',
    city: 'Quilpie',
    country: 'Australia',
  },
  SAF: {
    label: 'Santa Fe Airport (SAF)',
    city: 'Santa Fe',
    country: 'United States',
  },
  IPA: {
    label: 'Ipota Airport (IPA)',
    city: 'Ipota',
    country: 'Vanuatu',
  },
  TIQ: {
    label: 'Tinian (TIQ)',
    city: 'Tinian',
    country: 'Northern Mariana Islands',
  },
  LJG: {
    label: 'Lijiang (LJG)',
    city: 'Lijiang City',
    country: 'China',
  },
  AIZ: {
    label: 'Lee C Fine (AIZ)',
    city: 'Lake Ozark',
    country: 'United States',
  },
  RFP: {
    label: 'Uturoa (RFP)',
    city: 'Raiatea',
    country: 'French Polynesia and Tahiti',
  },
  SLV: {
    label: 'Simla (SLV)',
    city: 'Simla',
    country: 'India',
  },
  MJT: {
    label: 'Mytilene (MJT)',
    city: 'Mitilini',
    country: 'Greece',
  },
  SUJ: {
    label: 'Satu Mare (SUJ)',
    city: 'Satu Mare',
    country: 'Romania',
  },
  TRZ: {
    label: 'Civil (TRZ)',
    city: 'Tiruchirappalli',
    country: 'India',
  },
  LSC: {
    label: 'La Florida (LSC)',
    city: 'La Serena',
    country: 'Chile and Easter Island',
  },
  BNA: {
    label: 'Nashville (BNA)',
    city: 'Nashville',
    country: 'United States',
  },
  STI: {
    label: 'Santiago Municipal Airport (STI)',
    city: 'Santiago',
    country: 'Dominican Republic',
  },
  SFG: {
    label: 'Esperance Airport (SFG)',
    city: 'Saint Martin',
    country: 'Guadeloupe',
  },
  TBN: {
    label: 'Forney Army Air Field (TBN)',
    city: 'Fort Leonard Wood',
    country: 'United States',
  },
  KWK: {
    label: 'Kwigillingok Airport (KWK)',
    city: 'Kwigillingok',
    country: 'United States',
  },
  TIF: {
    label: 'Taif (TIF)',
    city: 'Taif',
    country: 'Saudi Arabia',
  },
  LUN: {
    label: 'Lusaka (LUN)',
    city: 'Lusaka',
    country: 'Zambia',
  },
  MXP: {
    label: 'Malpensa Airport (MXP)',
    city: 'Milan',
    country: 'Italy',
  },
  GYE: {
    label: 'Simon Bolivar Airport (GYE)',
    city: 'Guayaquil',
    country: 'Ecuador',
  },
  LUX: {
    label: 'Luxembourg (LUX)',
    city: 'Luxembourg City',
    country: 'Luxembourg',
  },
  CEB: {
    label: 'Cebu Intl (CEB)',
    city: 'Cebu',
    country: 'Philippines',
  },
  SDI: {
    label: 'Saidor Airport (SDI)',
    city: 'Saidor',
    country: 'Papua New Guinea',
  },
  NCL: {
    label: 'Newcastle (NCL)',
    city: 'Newcastle',
    country: 'United Kingdom',
  },
  WNN: {
    label: 'Wunnummin Lake Airport (WNN)',
    city: 'Wunnummin Lake',
    country: 'Canada',
  },
  HUY: {
    label: 'Humberside Airport (HUY)',
    city: 'Humberside',
    country: 'United Kingdom',
  },
  PSS: {
    label: 'Posadas Airport (PSS)',
    city: 'Posadas',
    country: 'Argentina',
  },
  ISJ: {
    label: 'Isla Mujeres Mncpl (ISJ)',
    city: 'Isla Mujeres',
    country: 'Mexico',
  },
  SLC: {
    label: 'Salt Lake City (SLC)',
    city: 'Salt Lake City',
    country: 'United States',
  },
  PAH: {
    label: 'Barkley Regional Airport (PAH)',
    city: 'Paducah',
    country: 'United States',
  },
  LBA: {
    label: 'Leeds/Bradford (LBA)',
    city: 'Leeds',
    country: 'United Kingdom',
  },
  TLM: {
    label: 'Zenata Airport (TLM)',
    city: 'Tlemcen',
    country: 'Algeria',
  },
  NTY: {
    label: 'Pilansberg (NTY)',
    city: 'Sun City',
    country: 'South Africa',
  },
  PEZ: {
    label: 'Penza Airport (PEZ)',
    city: 'Penza',
    country: 'Russia',
  },
  OUH: {
    label: 'Oudtshoorn (OUH)',
    city: 'Oudtshoorn',
    country: 'South Africa',
  },
  ZPB: {
    label: 'Sachigo Lake Airport (ZPB)',
    city: 'Sachigo Lake',
    country: 'Canada',
  },
  DIL: {
    label: 'Comoro Airport (DIL)',
    city: 'Dili',
    country: 'East Timor',
  },
  TNC: {
    label: 'Tin City Air Force Station (TNC)',
    city: 'Tin City',
    country: 'United States',
  },
  TVA: {
    label: 'Morafenobe Airport (TVA)',
    city: 'Morafenobe',
    country: 'Madagascar',
  },
  RBV: {
    label: 'Ramata Airport (RBV)',
    city: 'Ramata',
    country: 'Solomon Islands',
  },
  TAO: {
    label: 'Qingdao (TAO)',
    city: 'Qingdao',
    country: 'China',
  },
  YLY: {
    label: 'Langley (YLY)',
    city: 'Langley',
    country: 'Canada',
  },
  PEU: {
    label: 'Puerto Lempira Airport (PEU)',
    city: 'Puerto Lempira',
    country: 'Honduras',
  },
  GLH: {
    label: 'Greenville Airport (GLH)',
    city: 'Greenville',
    country: 'MS United States',
  },
  MLE: {
    label: 'Male International Airport (MLE)',
    city: 'Male',
    country: 'Republic of Maldives',
  },
  DAM: {
    label: 'Damascus Intl (DAM)',
    city: 'Damascus',
    country: 'Syria',
  },
  VII: {
    label: 'Vinh City Airport (VII)',
    city: 'Vinh City',
    country: 'Vietnam',
  },
  DJB: {
    label: 'Slt Taha Syarifudin (DJB)',
    city: 'Jambi',
    country: 'Indonesia',
  },
  ILP: {
    label: 'Ile des Pins (ILP)',
    city: 'Ile des Pins',
    country: 'New Caledonia',
  },
  EWN: {
    label: 'Simmons Nott Airport (EWN)',
    city: 'New Bern',
    country: 'United States',
  },
  YMN: {
    label: 'Makkovik Airport (YMN)',
    city: 'Makkovik',
    country: 'Canada',
  },
  TBU: {
    label: "Fua'Amotu Intl (TBU)",
    city: "Nuku'Alofa",
    country: 'Tonga',
  },
  LUK: {
    label: 'Lunken Fld (LUK)',
    city: 'Cincinnati',
    country: 'United States',
  },
  VAI: {
    label: 'Vanimo Airport (VAI)',
    city: 'Vanimo',
    country: 'Papua New Guinea',
  },
  LPK: {
    label: 'Lipetsk Airport (LPK)',
    city: 'Lipetsk',
    country: 'Russia',
  },
  BYU: {
    label: 'Bindlacher Berg (BYU)',
    city: 'Bayreuth',
    country: 'Germany',
  },
  DOP: {
    label: 'Dolpa Airport (DOP)',
    city: 'Dolpa',
    country: 'Nepal',
  },
  MBD: {
    label: 'Mmmabatho Intl (MBD)',
    city: 'Mmabatho',
    country: 'South Africa',
  },
  KGP: {
    label: 'Kogalym International Airport (KGP)',
    city: 'Kogalym',
    country: 'Russia',
  },
  SHC: {
    label: 'Indaselassie Airport (SHC)',
    city: 'Indaselassie',
    country: 'Ethiopia',
  },
  BPX: {
    label: 'Bangda Airport (BPX)',
    city: 'Bangda',
    country: 'China',
  },
  LQM: {
    label: 'Puerto Leguizamo Airport (LQM)',
    city: 'Puerto Leguizamo',
    country: 'Colombia',
  },
  ZIH: {
    label: 'Extapa/Zihuatanejo Internacional Airport (ZIH)',
    city: 'Ixtapa',
    country: 'Mexico',
  },
  BIH: {
    label: 'Bishop (BIH)',
    city: 'Bishop',
    country: 'United States',
  },
  SOV: {
    label: 'Seldovia (SOV)',
    city: 'Seldovia',
    country: 'United States',
  },
  CDW: {
    label: 'Caldwell Wright Airport (CDW)',
    city: 'Caldwell',
    country: 'United States',
  },
  QLS: {
    label: 'Off-Line Point (QLS)',
    city: 'Lausanne',
    country: 'Switzerland',
  },
  BAG: {
    label: 'Loakan (BAG)',
    city: 'Baguio',
    country: 'Philippines',
  },
  MBA: {
    label: 'Moi Intl (MBA)',
    city: 'Mombasa',
    country: 'Kenya',
  },
  HID: {
    label: 'Horn Island Airport (HID)',
    city: 'Horn Island',
    country: 'Australia',
  },
  DIK: {
    label: 'Dickinson Municipal Airport (DIK)',
    city: 'Dickinson',
    country: 'United States',
  },
  QLJ: {
    label: 'Off-Line Point (QLJ)',
    city: 'Lucerne',
    country: 'Switzerland',
  },
  EAR: {
    label: 'Kearney Municipal Airport (EAR)',
    city: 'Kearney',
    country: 'United States',
  },
  PTF: {
    label: 'Malololailai Airport (PTF)',
    city: 'Malololailai Island',
    country: 'Fiji',
  },
  SPP: {
    label: 'Menongue Airport (SPP)',
    city: 'Menongue',
    country: 'Angola',
  },
  FCO: {
    label: 'Fiumicino Airport (FCO)',
    city: 'Rome',
    country: 'Italy',
  },
  CBR: {
    label: 'Canberra (CBR)',
    city: 'Canberra',
    country: 'Australia',
  },
  YPR: {
    label: 'Digby Island Airport (YPR)',
    city: 'Prince Rupert',
    country: 'Canada',
  },
  OSI: {
    label: 'Osijek (OSI)',
    city: 'Osijek',
    country: 'Croatia',
  },
  AMQ: {
    label: 'Pattimura Airport (AMQ)',
    city: 'Ambon',
    country: 'Indonesia',
  },
  TLC: {
    label: 'Toluca (TLC)',
    city: 'Toluca',
    country: 'Mexico',
  },
  OSD: {
    label: 'Froesoe (OSD)',
    city: 'Ostersund',
    country: 'Sweden',
  },
  HKT: {
    label: 'Phuket Intl (HKT)',
    city: 'Phuket',
    country: 'Thailand',
  },
  KKJ: {
    label: 'Kita Kyushu (KKJ)',
    city: 'Kitakyushu',
    country: 'Japan',
  },
  GUL: {
    label: 'Goulburn (GUL)',
    city: 'Goulburn',
    country: 'Australia',
  },
  YMB: {
    label: 'Merritt Mncpl (YMB)',
    city: 'Merritt',
    country: 'Canada',
  },
  MYD: {
    label: 'Malindi (MYD)',
    city: 'Malindi',
    country: 'Kenya',
  },
  TUC: {
    label: 'Benjamin Matienzo (TUC)',
    city: 'San Miguel de Tucuman',
    country: 'Argentina',
  },
  OHO: {
    label: 'Okhotsk Airport (OHO)',
    city: 'Okhotsk',
    country: 'Russia',
  },
  DLC: {
    label: 'Dalian (DLC)',
    city: 'Dalian',
    country: 'China',
  },
  PEE: {
    label: 'Perm (PEE)',
    city: 'Perm',
    country: 'Russia',
  },
  EUG: {
    label: 'Eugene (EUG)',
    city: 'Eugene',
    country: 'United States',
  },
  JFK: {
    label: 'John F Kennedy International (JFK)',
    city: 'New York City',
    country: 'United States',
  },
  VBV: {
    label: 'Vanuabalavu Airport (VBV)',
    city: 'Vanuabalavu',
    country: 'Fiji',
  },
  BHO: {
    label: 'Bhopal (BHO)',
    city: 'Bhopal',
    country: 'India',
  },
  PEM: {
    label: 'Puerto Maldonado (PEM)',
    city: 'Puerto Maldonado',
    country: 'Peru',
  },
  FRU: {
    label: 'Manas (FRU)',
    city: 'Bishkek',
    country: 'Kyrgyzstan',
  },
  SHE: {
    label: 'Shenyang (SHE)',
    city: 'Shenyang',
    country: 'China',
  },
  KNB: {
    label: 'Kanab (KNB)',
    city: 'Kanab',
    country: 'United States',
  },
  EIE: {
    label: 'Eniseysk Airport (EIE)',
    city: 'Eniseysk',
    country: 'Russia',
  },
  PJA: {
    label: 'Pajala Airport (PJA)',
    city: 'Pajala',
    country: 'Sweden',
  },
  YUM: {
    label: 'Yuma International Airport (YUM)',
    city: 'Yuma',
    country: 'United States',
  },
  AAM: {
    label: 'Mala Mala Airport (AAM)',
    city: 'Mala Mala',
    country: 'South Africa',
  },
  YTZ: {
    label: 'City Centre (YTZ)',
    city: 'Toronto',
    country: 'Canada',
  },
  TFI: {
    label: 'Tufi Airport (TFI)',
    city: 'Tufi',
    country: 'Papua New Guinea',
  },
  ALG: {
    label: 'Houari Boumediene (ALG)',
    city: 'Algiers',
    country: 'Algeria',
  },
  BWN: {
    label: 'Brunei Intl (BWN)',
    city: 'Bandar Seri Begawan',
    country: 'Brunei',
  },
  MTT: {
    label: 'Minatitlan Airport (MTT)',
    city: 'Minatitlan',
    country: 'Mexico',
  },
  BIO: {
    label: 'Bilbao (BIO)',
    city: 'Bilbao',
    country: 'Spain',
  },
  BDP: {
    label: 'Bhadrapur Airport (BDP)',
    city: 'Bhadrapur',
    country: 'Nepal',
  },
  CCC: {
    label: 'Cayo Coco Airport (CCC)',
    city: 'Cayo Coco',
    country: 'Cuba',
  },
  NME: {
    label: 'Nightmute Airport (NME)',
    city: 'Nightmute',
    country: 'United States',
  },
  BOB: {
    label: 'Motu-mute (BOB)',
    city: 'Bora-Bora',
    country: 'French Polynesia and Tahiti',
  },
  CAW: {
    label: 'Bartolomeu Lisandro Airport (CAW)',
    city: 'Campos',
    country: 'Brazil',
  },
  PIA: {
    label: 'Greater Peoria Airport (PIA)',
    city: 'Peoria',
    country: 'United States',
  },
  MUA: {
    label: 'Munda Airport (MUA)',
    city: 'Munda',
    country: 'Solomon Islands',
  },
  LKN: {
    label: 'Leknes (LKN)',
    city: 'Leknes',
    country: 'Norway',
  },
  DSA: {
    label: 'Sheffield (DSA)',
    city: 'Doncaster',
    country: 'United Kingdom',
  },
  IPH: {
    label: 'Ipoh (IPH)',
    city: 'Ipoh',
    country: 'Malaysia',
  },
  TIV: {
    label: 'Tivat (TIV)',
    city: 'Tivat',
    country: 'Montenegro',
  },
  PRB: {
    label: 'Paso Robles Mncpl (PRB)',
    city: 'Paso Robles',
    country: 'United States',
  },
  JRS: {
    label: 'Jerusalem (JRS)',
    city: 'Jerusalem',
    country: 'Israel',
  },
  YDP: {
    label: 'Nain Airport (YDP)',
    city: 'Nain',
    country: 'Canada',
  },
  MIF: {
    label: 'Roy Hurd Memorial (MIF)',
    city: 'Monahans',
    country: 'United States',
  },
  YES: {
    label: 'Yasouj Airport (YES)',
    city: 'Yasouj',
    country: 'Iran',
  },
  OKR: {
    label: 'Yorke Island Airport (OKR)',
    city: 'Yorke Island',
    country: 'Australia',
  },
  TBJ: {
    label: 'Tabarka (TBJ)',
    city: 'Tabarka',
    country: 'Tunisia',
  },
  DPS: {
    label: 'Ngurah Rai (DPS)',
    city: 'Denpasar',
    country: 'Indonesia',
  },
  CAC: {
    label: 'Cascavel Mncpl (CAC)',
    city: 'Cascavel',
    country: 'Brazil',
  },
  MIS: {
    label: 'Misima Island Airport (MIS)',
    city: 'Misima Island',
    country: 'Papua New Guinea',
  },
  DUR: {
    label: 'Durban Intl (DUR)',
    city: 'Durban',
    country: 'South Africa',
  },
  YEL: {
    label: 'Elliot Lake (YEL)',
    city: 'Elliot Lake',
    country: 'Canada',
  },
  UEO: {
    label: 'Kume Jima (UEO)',
    city: 'Kume Island',
    country: 'Japan',
  },
  APC: {
    label: 'Napa County (APC)',
    city: 'Napa',
    country: 'United States',
  },
  SFS: {
    label: 'Subic Bay International Airport (SFS)',
    city: 'Subic Bay',
    country: 'Philippines',
  },
  BXU: {
    label: 'Butuan Airport (BXU)',
    city: 'Butuan',
    country: 'Philippines',
  },
  ASB: {
    label: 'Ashgabat (ASB)',
    city: 'Ashgabat',
    country: 'Turkmenistan',
  },
  IVL: {
    label: 'Ivalo (IVL)',
    city: 'Ivalo',
    country: 'Finland',
  },
  OKI: {
    label: 'Oki Island Airport (OKI)',
    city: 'Oki Island',
    country: 'Japan',
  },
  CRV: {
    label: 'Crotone (CRV)',
    city: 'Crotone',
    country: 'Italy',
  },
  BHD: {
    label: 'Belfast City (BHD)',
    city: 'Belfast',
    country: 'United Kingdom',
  },
  YSG: {
    label: 'Lutselke Airport (YSG)',
    city: "Lutselk'e",
    country: 'Canada',
  },
  PER: {
    label: 'Perth International (PER)',
    city: 'Perth',
    country: 'Australia',
  },
  KLW: {
    label: 'Klawock Airport (KLW)',
    city: 'Klawock',
    country: 'United States',
  },
  LLI: {
    label: 'Lalibela Airport (LLI)',
    city: 'Lalibela',
    country: 'Ethiopia',
  },
  NAS: {
    label: 'Nassau International Airport (NAS)',
    city: 'Nassau',
    country: 'Bahamas',
  },
  OTG: {
    label: 'Worthington Mncpl (OTG)',
    city: 'Worthington',
    country: 'United States',
  },
  LAX: {
    label: 'LAX (LAX)',
    city: 'Los Angeles',
    country: 'United States',
  },
  YWB: {
    label: 'Kangiqsujuaq Airport (YWB)',
    city: 'Kangiqsujuaq',
    country: 'Canada',
  },
  WRY: {
    label: 'Westray Airport (WRY)',
    city: 'Westray',
    country: 'United Kingdom',
  },
  MJK: {
    label: 'Shark Bay Airport (MJK)',
    city: 'Monkey Mia',
    country: 'Australia',
  },
  ACE: {
    label: 'Lanzarote (ACE)',
    city: 'Lanzarote',
    country: 'Spain',
  },
  BHR: {
    label: 'Bharatpur Airport (BHR)',
    city: 'Bharatpur',
    country: 'Nepal',
  },
  LRD: {
    label: 'Laredo International Airport (LRD)',
    city: 'Laredo',
    country: 'United States',
  },
  AGA: {
    label: 'Agadir Almassira (AGA)',
    city: 'Agadir',
    country: 'Morocco',
  },
  YJA: {
    label: 'Jasper Mncpl (YJA)',
    city: 'Jasper',
    country: 'Canada',
  },
  RDD: {
    label: 'Redding Airport (RDD)',
    city: 'Redding',
    country: 'United States',
  },
  TNR: {
    label: 'Antananarivo (TNR)',
    city: 'Antananarivo',
    country: 'Madagascar',
  },
  MDQ: {
    label: 'Mar del Plata (MDQ)',
    city: 'Mar del Plata',
    country: 'Argentina',
  },
  TGZ: {
    label: 'Llano San Juan (TGZ)',
    city: 'Tuxtla Gutierrez',
    country: 'Mexico',
  },
  SDZ: {
    label: 'All Airports (SDZ)',
    city: 'Lerwick',
    country: 'United Kingdom',
  },
  YQN: {
    label: 'Nakina Airport (YQN)',
    city: 'Nakina',
    country: 'Canada',
  },
  ISB: {
    label: 'Islamabad Intl (ISB)',
    city: 'Islamabad/Rawalpindi',
    country: 'Pakistan',
  },
  AXB: {
    label: 'Alexandria Bay Mncpl (AXB)',
    city: 'Alexandria Bay',
    country: 'United States',
  },
  LSP: {
    label: 'Josefa Camejo Airport (LSP)',
    city: 'Las Piedras',
    country: 'Venezuela',
  },
  YDC: {
    label: 'Industrial (YDC)',
    city: 'Drayton Valley',
    country: 'Canada',
  },
  NOC: {
    label: 'Ireland West Knock Airport (NOC)',
    city: 'Knock',
    country: 'Ireland',
  },
  VNO: {
    label: 'Vilnius Airport (VNO)',
    city: 'Vilnius',
    country: 'Lithuania',
  },
  CWA: {
    label: 'Central Wisconsin Airport (CWA)',
    city: 'Wausau',
    country: 'United States',
  },
  RMK: {
    label: 'Renmark (RMK)',
    city: 'Renmark',
    country: 'Australia',
  },
  KMP: {
    label: 'JGH Van Der Wath (KMP)',
    city: 'Keetmanshoop',
    country: 'Namibia',
  },
  DIG: {
    label: 'Diqing (DIG)',
    city: 'Diqing',
    country: 'China',
  },
  MXT: {
    label: 'Maintirano Airport (MXT)',
    city: 'Maintirano',
    country: 'Madagascar',
  },
  MZO: {
    label: 'Sierra Maestra Airport (MZO)',
    city: 'Manzanillo',
    country: 'Cuba',
  },
  ACZ: {
    label: 'Zabol Airport (ACZ)',
    city: 'Zabol',
    country: 'Iran',
  },
  NNB: {
    label: 'Santa Ana Airport (NNB)',
    city: 'Santa Ana',
    country: 'Solomon Islands',
  },
  MQP: {
    label: 'Kruger Mpumalanga International Airport (MQP)',
    city: 'Nelspruit',
    country: 'South Africa',
  },
  SZX: {
    label: 'Shenzhen (SZX)',
    city: 'Shenzhen',
    country: 'China',
  },
  SCL: {
    label: 'Arturo Merino Benitez Airport (SCL)',
    city: 'Santiago',
    country: 'Chile and Easter Island',
  },
  EBD: {
    label: 'El Obeid Airport (EBD)',
    city: 'El Obeid',
    country: 'Sudan',
  },
  PCR: {
    label: 'Puerto Carreno Airport (PCR)',
    city: 'Puerto Carreno',
    country: 'Colombia',
  },
  YWM: {
    label: 'Williams Harbour Airport (YWM)',
    city: 'Williams Harbour',
    country: 'Canada',
  },
  CZM: {
    label: 'Cozumel Airport (CZM)',
    city: 'Cozumel',
    country: 'Mexico',
  },
  PGX: {
    label: 'Perigueux (PGX)',
    city: 'Perigueux',
    country: 'France',
  },
  ANK: {
    label: 'Etimesgut (ANK)',
    city: 'Ankara',
    country: 'Turkey',
  },
  AMD: {
    label: 'Ahmedabad (AMD)',
    city: 'Ahmedabad',
    country: 'India',
  },
  OLS: {
    label: 'Nogales Intl (OLS)',
    city: 'Nogales',
    country: 'United States',
  },
  YWK: {
    label: 'Wabush Airport (YWK)',
    city: 'Wabush',
    country: 'Canada',
  },
  STE: {
    label: 'Stevens Point Mncpl (STE)',
    city: 'Stevens Point',
    country: 'United States',
  },
  IAG: {
    label: 'Niagara Falls Intl (IAG)',
    city: 'Niagara Falls',
    country: 'United States',
  },
  CUM: {
    label: 'Cumana (CUM)',
    city: 'Cumana',
    country: 'Venezuela',
  },
  YTO: {
    label: 'All Airports (YTO)',
    city: 'Toronto',
    country: 'Canada',
  },
  JNB: {
    label: 'Johannesburg Intl (JNB)',
    city: 'Johannesburg',
    country: 'South Africa',
  },
  PEI: {
    label: 'Matecana (PEI)',
    city: 'Pereira',
    country: 'Colombia',
  },
  WLK: {
    label: 'Selawik Airport (WLK)',
    city: 'Selawik',
    country: 'United States',
  },
  MDZ: {
    label: 'El Plumerillo (MDZ)',
    city: 'Mendoza',
    country: 'Argentina',
  },
  MDH: {
    label: 'Southern Illinois (MDH)',
    city: 'Carbondale',
    country: 'United States',
  },
  CUD: {
    label: 'Caloundra (CUD)',
    city: 'Caloundra',
    country: 'Australia',
  },
  NUE: {
    label: 'Nuremberg (NUE)',
    city: 'Nuremberg',
    country: 'Germany',
  },
  CAD: {
    label: 'County (CAD)',
    city: 'Cadillac',
    country: 'United States',
  },
  JKH: {
    label: 'Chios (JKH)',
    city: 'Chios',
    country: 'Greece',
  },
  CAE: {
    label: 'Columbia (CAE)',
    city: 'Columbia',
    country: 'SC',
  },
  EMP: {
    label: 'Emporia Mncpl (EMP)',
    city: 'Emporia',
    country: 'United States',
  },
  KSQ: {
    label: 'Karshi Airport (KSQ)',
    city: 'Karshi',
    country: 'Uzbekistan',
  },
  LZY: {
    label: 'Lin Zhi Airport (LZY)',
    city: 'Lin Zhi',
    country: 'China',
  },
  ZFD: {
    label: 'Fond Du Lac Airport (ZFD)',
    city: 'Fond Du Lac',
    country: 'Canada',
  },
  GUR: {
    label: 'Gurney (GUR)',
    city: 'Alotau',
    country: 'Papua New Guinea',
  },
  BGM: {
    label: 'Binghamton Airport (BGM)',
    city: 'Binghamton',
    country: 'United States',
  },
  TCI: {
    label: 'All Airports (TCI)',
    city: 'Tenerife',
    country: 'Spain',
  },
  KCG: {
    label: 'Fisheries Airport (KCG)',
    city: 'Chignik',
    country: 'United States',
  },
  TUR: {
    label: 'Tucurui Airport (TUR)',
    city: 'Tucurui',
    country: 'Brazil',
  },
  CMG: {
    label: 'Corumba Internacional Airport (CMG)',
    city: 'Corumba',
    country: 'Brazil',
  },
  RNJ: {
    label: 'Yoronjima Airport (RNJ)',
    city: 'Yoronjima',
    country: 'Japan',
  },
  LKL: {
    label: 'Banak (LKL)',
    city: 'Lakselv',
    country: 'Norway',
  },
  IXG: {
    label: 'Sambre Airport (IXG)',
    city: 'Belgaum',
    country: 'India',
  },
  LMP: {
    label: 'Lampedusa (LMP)',
    city: 'Lampedusa',
    country: 'Italy',
  },
  YTE: {
    label: 'Cape Dorset Mncpl (YTE)',
    city: 'Cape Dorset',
    country: 'Canada',
  },
  JAI: {
    label: 'Sanganeer (JAI)',
    city: 'Jaipur',
    country: 'India',
  },
  LAK: {
    label: 'Aklavik Airport (LAK)',
    city: 'Aklavik',
    country: 'Canada',
  },
  MHH: {
    label: 'Marsh Harbour International Airport (MHH)',
    city: 'Marsh Harbour',
    country: 'Bahamas',
  },
  HOV: {
    label: 'Hovden (HOV)',
    city: 'Orsta',
    country: 'Norway',
  },
  OOM: {
    label: 'Cooma (OOM)',
    city: 'Cooma',
    country: 'Australia',
  },
  POY: {
    label: 'Powell Mncpl (POY)',
    city: 'Powell',
    country: 'United States',
  },
  UCA: {
    label: 'Oneida Cty (UCA)',
    city: 'Utica',
    country: 'United States',
  },
  EKT: {
    label: 'Eskilstuna (EKT)',
    city: 'Eskilstuna',
    country: 'Sweden',
  },
  DOL: {
    label: 'Saint Gatien (DOL)',
    city: 'Deauville',
    country: 'France',
  },
  VIJ: {
    label: 'Virgin Gorda (VIJ)',
    city: 'Spanish Town',
    country: 'British Virgin Islands',
  },
  GFK: {
    label: 'Grand Forks Airport (GFK)',
    city: 'Grand Forks',
    country: 'United States',
  },
  YCQ: {
    label: 'Chetwynd Mncpl (YCQ)',
    city: 'Chetwynd',
    country: 'Canada',
  },
  EAU: {
    label: 'Eau Claire Airport (EAU)',
    city: 'Eau Claire',
    country: 'United States',
  },
  BHY: {
    label: 'Beihai (BHY)',
    city: 'Beihai',
    country: 'China',
  },
  AIN: {
    label: 'Wainwright Airport (AIN)',
    city: 'Wainwright',
    country: 'United States',
  },
  NRK: {
    label: 'Kungsangen (NRK)',
    city: 'Norrkoping',
    country: 'Sweden',
  },
  EAA: {
    label: 'Eagle Airport (EAA)',
    city: 'Eagle',
    country: 'United States',
  },
  YDO: {
    label: 'Dolbeau Mncpl (YDO)',
    city: 'Dolbeau',
    country: 'Canada',
  },
  CUE: {
    label: 'Mariscal Lamar (CUE)',
    city: 'Cuenca',
    country: 'Ecuador',
  },
  TUL: {
    label: 'Tulsa (TUL)',
    city: 'Tulsa',
    country: 'United States',
  },
  IEV: {
    label: 'Zhulhany (IEV)',
    city: 'Kiev',
    country: 'Ukraine',
  },
  AGF: {
    label: 'Agen La Garenne (AGF)',
    city: 'Agen',
    country: 'France',
  },
  KSJ: {
    label: 'Kasos Island Airport (KSJ)',
    city: 'Kasos Island',
    country: 'Greece',
  },
  VJI: {
    label: 'Virginia Highlands (VJI)',
    city: 'Abingdon',
    country: 'United States',
  },
  GLD: {
    label: 'Renner Fld (GLD)',
    city: 'Goodland',
    country: 'United States',
  },
  ERS: {
    label: 'Eros (ERS)',
    city: 'Windhoek',
    country: 'Namibia',
  },
  EEK: {
    label: 'Eek Airport (EEK)',
    city: 'Eek',
    country: 'United States',
  },
  BTM: {
    label: 'Butte Airport (BTM)',
    city: 'Butte',
    country: 'United States',
  },
  DAD: {
    label: 'Da Nang (DAD)',
    city: 'Da Nang',
    country: 'Vietnam',
  },
  KHN: {
    label: 'Nanchang (KHN)',
    city: 'Nanchang',
    country: 'China',
  },
  PZY: {
    label: 'Piestany (PZY)',
    city: 'Piestany',
    country: 'Slovakia',
  },
  NPE: {
    label: 'Hawkes Bay (NPE)',
    city: 'Napier',
    country: 'New Zealand',
  },
  GIZ: {
    label: 'Jazan Airport (GIZ)',
    city: 'Jazan',
    country: 'Saudi Arabia',
  },
  KAC: {
    label: 'Kameshli Airport (KAC)',
    city: 'Kameshli',
    country: 'Syria',
  },
  MRE: {
    label: 'Mara Lodges Airport (MRE)',
    city: 'Mara Lodges',
    country: 'Kenya',
  },
  ENY: {
    label: "Yan'an Airport (ENY)",
    city: "Yan'an",
    country: 'China',
  },
  VEL: {
    label: 'Vernal Airport (VEL)',
    city: 'Vernal',
    country: 'United States',
  },
  YQI: {
    label: 'Yarmouth Airport (YQI)',
    city: 'Yarmouth',
    country: 'Canada',
  },
  ELP: {
    label: 'El Paso (ELP)',
    city: 'El Paso',
    country: 'United States',
  },
  MPV: {
    label: 'Edward F Knapp St (MPV)',
    city: 'Montpelier',
    country: 'United States',
  },
  TAD: {
    label: 'Las Animas County (TAD)',
    city: 'Trinidad',
    country: 'United States',
  },
  SQL: {
    label: 'San Carlos Mncpl (SQL)',
    city: 'San Carlos',
    country: 'United States',
  },
  SDQ: {
    label: 'Las Americas Airport (SDQ)',
    city: 'Santo Domingo',
    country: 'Dominican Republic',
  },
  YNY: {
    label: 'Yangyang Airport (YNY)',
    city: 'Yangyang',
    country: 'South Korea',
  },
  NOA: {
    label: 'Nowra (NOA)',
    city: 'Nowra',
    country: 'Australia',
  },
  WFI: {
    label: 'Fianarantsoa Airport (WFI)',
    city: 'Fianarantsoa',
    country: 'Madagascar',
  },
  MTL: {
    label: 'Maitland (MTL)',
    city: 'Maitland',
    country: 'Australia',
  },
  BCL: {
    label: 'Barra Colorado Airport (BCL)',
    city: 'Barra Colorado',
    country: 'Costa Rica',
  },
  FRM: {
    label: 'Fairmont Mncpl (FRM)',
    city: 'Fairmont',
    country: 'United States',
  },
  TEX: {
    label: 'Telluride (TEX)',
    city: 'Telluride',
    country: 'United States',
  },
  IAA: {
    label: 'Igarka Airport (IAA)',
    city: 'Igarka',
    country: 'Russia',
  },
  BBO: {
    label: 'Berbera Airport (BBO)',
    city: 'Berbera',
    country: 'Somalia',
  },
  YDG: {
    label: 'Digby (YDG)',
    city: 'Digby',
    country: 'Canada',
  },
  CRD: {
    label: 'Comodoro Rivadavia (CRD)',
    city: 'Comodoro Rivadavia',
    country: 'Argentina',
  },
  PLN: {
    label: 'Emmet County Airport (PLN)',
    city: 'Pellston',
    country: 'United States',
  },
  UBA: {
    label: 'Uberaba (UBA)',
    city: 'Uberaba',
    country: 'Brazil',
  },
  AXU: {
    label: 'Axum Airport (AXU)',
    city: 'Axum',
    country: 'Ethiopia',
  },
  SQG: {
    label: 'Sintang Airport (SQG)',
    city: 'Sintang',
    country: 'Indonesia',
  },
  GEA: {
    label: 'Magenta (GEA)',
    city: 'Noumea',
    country: 'New Caledonia',
  },
  CCP: {
    label: 'Carriel Sur (CCP)',
    city: 'Concepcion',
    country: 'Chile and Easter Island',
  },
  PWM: {
    label: 'Portland (PWM)',
    city: 'Portland',
    country: 'ME',
  },
  MKL: {
    label: 'McKellar Fld (MKL)',
    city: 'Jackson',
    country: 'United States',
  },
  ODE: {
    label: 'Odense (ODE)',
    city: 'Odense',
    country: 'Denmark',
  },
  CIC: {
    label: 'Chico Airport (CIC)',
    city: 'Chico',
    country: 'United States',
  },
  YKF: {
    label: 'Kitchener-Waterloo Regional Airport (YKF)',
    city: 'Waterloo',
    country: 'Canada',
  },
  BZE: {
    label: 'Philip SW Goldson International Airport (BZE)',
    city: 'Belize City',
    country: 'Belize',
  },
  ODN: {
    label: 'Long Seridan Airport (ODN)',
    city: 'Long Seridan',
    country: 'Malaysia',
  },
  KAB: {
    label: 'Kariba (KAB)',
    city: 'Kariba',
    country: 'Zimbabwe',
  },
  PLV: {
    label: 'Poltava Airport (PLV)',
    city: 'Poltava',
    country: 'Ukraine',
  },
  PXO: {
    label: 'Porto Santo (PXO)',
    city: 'Porto Santo',
    country: 'Portugal',
  },
  CES: {
    label: 'Cessnock (CES)',
    city: 'Cessnock',
    country: 'Australia',
  },
  MMA: {
    label: 'All Airports (MMA)',
    city: 'Malmo',
    country: 'Sweden',
  },
  DLL: {
    label: 'Dillon County (DLL)',
    city: 'Dillon',
    country: 'United States',
  },
  TPJ: {
    label: 'Taplejung Airport (TPJ)',
    city: 'Taplejung',
    country: 'Nepal',
  },
  GRI: {
    label: 'Central Nebraska Regional Airport (GRI)',
    city: 'Grand Island',
    country: 'United States',
  },
  HDB: {
    label: 'Heidelberg (HDB)',
    city: 'Heidelberg',
    country: 'Germany',
  },
  NAV: {
    label: 'Nevsehir (NAV)',
    city: 'Nevsehir',
    country: 'Turkey',
  },
  DJN: {
    label: 'Delta Junction Mncpl (DJN)',
    city: 'Delta Junction',
    country: 'United States',
  },
  SCH: {
    label: 'Schenectady Mncpl (SCH)',
    city: 'Schenectady',
    country: 'United States',
  },
  HOM: {
    label: 'Homer (HOM)',
    city: 'Homer',
    country: 'United States',
  },
  LAI: {
    label: 'Servel (LAI)',
    city: 'Lannion',
    country: 'France',
  },
  YPT: {
    label: 'Pender Harbor Mncpl (YPT)',
    city: 'Pender Harbor',
    country: 'Canada',
  },
  PIP: {
    label: 'Pilot Point Airport (PIP)',
    city: 'Pilot Point',
    country: 'United States',
  },
  NMA: {
    label: 'Namangan Airport (NMA)',
    city: 'Namangan',
    country: 'Uzbekistan',
  },
  RAB: {
    label: 'Tokua (RAB)',
    city: 'Rabaul',
    country: 'Papua New Guinea',
  },
  AGP: {
    label: 'Malaga (AGP)',
    city: 'Malaga',
    country: 'Spain',
  },
  NVS: {
    label: 'Fourchambault (NVS)',
    city: 'Nevers',
    country: 'France',
  },
  HUS: {
    label: 'Hughes Municipal Airport (HUS)',
    city: 'Hughes',
    country: 'United States',
  },
  AER: {
    label: 'Adler/Sochi Airport (AER)',
    city: 'Adler/Sochi',
    country: 'Russia',
  },
  CLE: {
    label: 'Cleveland (CLE)',
    city: 'Cleveland',
    country: 'United States',
  },
  CDG: {
    label: 'De Gaulle (CDG)',
    city: 'Paris',
    country: 'France',
  },
  AVL: {
    label: 'Asheville Municipal Airport (AVL)',
    city: 'Asheville',
    country: 'United States',
  },
  IGM: {
    label: 'Kingman Airport (IGM)',
    city: 'Kingman',
    country: 'United States',
  },
  DLY: {
    label: 'Dillons Bay Airport (DLY)',
    city: 'Dillons Bay',
    country: 'Vanuatu',
  },
  GTA: {
    label: 'Gatokae Aerodrom (GTA)',
    city: 'Gatokae',
    country: 'Solomon Islands',
  },
  RKD: {
    label: 'Knox Cty Regl (RKD)',
    city: 'Rockland',
    country: 'United States',
  },
  PIB: {
    label: 'Hattiesburg-Laurel Regional Airport (PIB)',
    city: 'Laurel',
    country: 'MS',
  },
  TLT: {
    label: 'Tuluksak Airport (TLT)',
    city: 'Tuluksak',
    country: 'United States',
  },
  ZYL: {
    label: 'Civil Airport (ZYL)',
    city: 'Sylhet',
    country: 'Bangladesh',
  },
  BOR: {
    label: 'Fontaine (BOR)',
    city: 'Belfort',
    country: 'France',
  },
  AIA: {
    label: 'Alliance Municipal Airport (AIA)',
    city: 'Alliance',
    country: 'United States',
  },
  CQD: {
    label: 'Shahre-Kord Airport (CQD)',
    city: 'Shahre-Kord',
    country: 'Iran',
  },
  BHJ: {
    label: 'Rudra Mata Airport (BHJ)',
    city: 'Bhuj',
    country: 'India',
  },
  LOL: {
    label: 'Derby Field (LOL)',
    city: 'Lovelock',
    country: 'United States',
  },
  KVL: {
    label: 'Kivalina Airport (KVL)',
    city: 'Kivalina',
    country: 'United States',
  },
  BRM: {
    label: 'Barquisimeto (BRM)',
    city: 'Barquisimeto',
    country: 'Venezuela',
  },
  CDK: {
    label: 'Lewis (CDK)',
    city: 'Cedar Key',
    country: 'United States',
  },
  NGI: {
    label: 'Ngau Island Airport (NGI)',
    city: 'Ngau Island',
    country: 'Fiji',
  },
  PLU: {
    label: 'Pampulha (PLU)',
    city: 'Belo Horizonte',
    country: 'Brazil',
  },
  REG: {
    label: 'Tito Menniti (REG)',
    city: 'Reggio di Calabria',
    country: 'Italy',
  },
  HSI: {
    label: 'Hastings Mncpl (HSI)',
    city: 'Hastings',
    country: 'United States',
  },
  SJD: {
    label: 'Los Cabos Airport (SJD)',
    city: 'Los Cabos',
    country: 'Mexico',
  },
  WRE: {
    label: 'Whangarei (WRE)',
    city: 'Whangarei',
    country: 'New Zealand',
  },
  PEW: {
    label: 'Peshawar (PEW)',
    city: 'Peshawar',
    country: 'Pakistan',
  },
  PBM: {
    label: 'Zanderij Intl (PBM)',
    city: 'Paramaribo',
    country: 'Suriname',
  },
  PGK: {
    label: 'Pangkalpinang Airport (PGK)',
    city: 'Pangkalpinang',
    country: 'Indonesia',
  },
  BIK: {
    label: 'Mokmer Airport (BIK)',
    city: 'Biak',
    country: 'Indonesia',
  },
  YTM: {
    label: 'La Macaza (YTM)',
    city: 'Mont-Tremblant',
    country: 'Canada',
  },
  HET: {
    label: 'Hohhot (HET)',
    city: 'Hohhot',
    country: 'China',
  },
  SEP: {
    label: 'Clark Field Mncpl (SEP)',
    city: 'Stephenville',
    country: 'United States',
  },
  MPI: {
    label: 'Mamitupo Airport (MPI)',
    city: 'Mamitupo',
    country: 'Panama',
  },
  CIK: {
    label: 'Chalkyitsik Airport (CIK)',
    city: 'Chalkyitsik',
    country: 'United States',
  },
  HFT: {
    label: 'Hammerfest (HFT)',
    city: 'Hammerfest',
    country: 'Norway',
  },
  KGC: {
    label: 'Kingscote (KGC)',
    city: 'Kingscote',
    country: 'Australia',
  },
  PGV: {
    label: 'Greenville (PGV)',
    city: 'Greenville',
    country: 'NC',
  },
  MIM: {
    label: 'Merimbula (MIM)',
    city: 'Merimbula',
    country: 'Australia',
  },
  RPN: {
    label: 'Rosh-Pina (RPN)',
    city: 'Rosh-Pina',
    country: 'Israel',
  },
  SPZ: {
    label: 'Springdale (SPZ)',
    city: 'Springdale',
    country: 'United States',
  },
  EAT: {
    label: 'Pangborn Field (EAT)',
    city: 'Wenatchee',
    country: 'United States',
  },
  YQU: {
    label: 'Grande Prairie Airport (YQU)',
    city: 'Grande Prairie',
    country: 'Canada',
  },
  YER: {
    label: 'Fort Severn Airport (YER)',
    city: 'Fort Severn',
    country: 'Canada',
  },
  TKP: {
    label: 'Takapoto Airport (TKP)',
    city: 'Takapoto',
    country: 'French Polynesia and Tahiti',
  },
  ENS: {
    label: 'Twente (ENS)',
    city: 'Enschede',
    country: 'Netherlands',
  },
  PEN: {
    label: 'Penang Intl (PEN)',
    city: 'Penang',
    country: 'Malaysia',
  },
  YKL: {
    label: 'Schefferville (YKL)',
    city: 'Schefferville',
    country: 'Canada',
  },
  WHD: {
    label: 'Hyder Sea Plane Base (WHD)',
    city: 'Hyder',
    country: 'United States',
  },
  NCA: {
    label: 'North Caicos Mncpl (NCA)',
    city: 'North Caicos',
    country: 'Turks and Caicos',
  },
  CMU: {
    label: 'Chimbu Airport (CMU)',
    city: 'Kundiawa',
    country: 'Papua New Guinea',
  },
  MYJ: {
    label: 'Matsuyama (MYJ)',
    city: 'Matsuyama',
    country: 'Japan',
  },
  BEJ: {
    label: 'Berau Airport (BEJ)',
    city: 'Berau',
    country: 'Indonesia',
  },
  JHM: {
    label: 'Kapalua West Maui (JHM)',
    city: 'Kapalua',
    country: 'United States',
  },
  RWF: {
    label: 'Redwood Falls Mncpl (RWF)',
    city: 'Redwood Falls',
    country: 'United States',
  },
  KDI: {
    label: 'Wolter Monginsidi Airport (KDI)',
    city: 'Kendari',
    country: 'Indonesia',
  },
  HZK: {
    label: 'Husavik (HZK)',
    city: 'Husavik',
    country: 'Iceland',
  },
  LCX: {
    label: 'Liancheng Airport (LCX)',
    city: 'Longyan',
    country: 'China',
  },
  QUP: {
    label: 'Saqqaq Heliport (QUP)',
    city: 'Saqqaq',
    country: 'Greenland',
  },
  YPC: {
    label: 'Paulatuk Airport (YPC)',
    city: 'Paulatuk',
    country: 'Canada',
  },
  RFD: {
    label: 'Greater Rockford Airport (RFD)',
    city: 'Rockford',
    country: 'United States',
  },
  HTA: {
    label: 'Chita Airport (HTA)',
    city: 'Chita',
    country: 'Russia',
  },
  TAL: {
    label: 'Ralph Calhoun Airport (TAL)',
    city: 'Tanana',
    country: 'United States',
  },
  VRN: {
    label: 'Verona (VRN)',
    city: 'Verona',
    country: 'Italy',
  },
  SUF: {
    label: 'S Eufemia (SUF)',
    city: 'Lamezia Terme',
    country: 'Italy',
  },
  FUN: {
    label: 'Funafuti Atoll Intl (FUN)',
    city: 'Funafuti Atoll',
    country: 'Tuvalu',
  },
  GST: {
    label: 'Gustavus Airport (GST)',
    city: 'Gustavus',
    country: 'United States',
  },
  TSM: {
    label: 'Taos Regional (TSM)',
    city: 'Taos',
    country: 'United States',
  },
  CYF: {
    label: 'Chefornak Sea Plane Base (CYF)',
    city: 'Chefornak',
    country: 'United States',
  },
  KPV: {
    label: 'Perryville Sea Plane Base (KPV)',
    city: 'Perryville',
    country: 'United States',
  },
  BOG: {
    label: 'Eldorado Airport (BOG)',
    city: 'Bogota',
    country: 'Colombia',
  },
  NVK: {
    label: 'Framnes (NVK)',
    city: 'Narvik',
    country: 'Norway',
  },
  RUA: {
    label: 'Arua Airport (RUA)',
    city: 'Arua',
    country: 'Uganda',
  },
  KAD: {
    label: 'Kaduna Airport (KAD)',
    city: 'Kaduna',
    country: 'Nigeria',
  },
  LEY: {
    label: 'Lelystad (LEY)',
    city: 'Lelystad',
    country: 'Netherlands',
  },
  KUD: {
    label: 'Kudat Airport (KUD)',
    city: 'Kudat',
    country: 'Malaysia',
  },
  CIA: {
    label: 'Ciampino (CIA)',
    city: 'Rome',
    country: 'Italy',
  },
  DKR: {
    label: 'Yoff (DKR)',
    city: 'Dakar',
    country: 'Senegal',
  },
  SEN: {
    label: 'Southend Mncpl (SEN)',
    city: 'Southend',
    country: 'United Kingdom',
  },
  SOW: {
    label: 'Show Low (SOW)',
    city: 'Show Low',
    country: 'United States',
  },
  TAR: {
    label: 'MA Grottag (TAR)',
    city: 'Taranto',
    country: 'Italy',
  },
  SUE: {
    label: 'Door County (SUE)',
    city: 'Sturgeon Bay',
    country: 'United States',
  },
  BSD: {
    label: 'Baoshan (BSD)',
    city: 'Baoshan',
    country: 'China',
  },
  PPS: {
    label: 'Puerto Princesa (PPS)',
    city: 'Puerto Princesa',
    country: 'Philippines',
  },
  RST: {
    label: 'Rochester Municipal Airport (RST)',
    city: 'Rochester',
    country: 'United States',
  },
  CDR: {
    label: 'Chadron Municipal Airport (CDR)',
    city: 'Chadron',
    country: 'United States',
  },
  KKE: {
    label: 'Kerikeri (KKE)',
    city: 'Kerikeri',
    country: 'New Zealand',
  },
  YPX: {
    label: 'Puvirnituq Airport (YPX)',
    city: 'Povungnituk',
    country: 'Canada',
  },
  BZL: {
    label: 'Barisal Airport (BZL)',
    city: 'Barisal',
    country: 'Bangladesh',
  },
  TIR: {
    label: 'Tirupati (TIR)',
    city: 'Tirupati',
    country: 'India',
  },
  DLA: {
    label: 'Douala (DLA)',
    city: 'Douala',
    country: 'Cameroon',
  },
  UDI: {
    label: 'Eduardo Gomes (UDI)',
    city: 'Uberlandia',
    country: 'Brazil',
  },
  MDG: {
    label: 'Mudanjiang (MDG)',
    city: 'Mudanjiang',
    country: 'China',
  },
  STK: {
    label: 'Crosson Fld (STK)',
    city: 'Sterling',
    country: 'United States',
  },
  BGY: {
    label: 'Orio Al Serio (BGY)',
    city: 'Milan',
    country: 'Italy',
  },
  SIT: {
    label: 'Sitka Airport (SIT)',
    city: 'Sitka',
    country: 'United States',
  },
  BYK: {
    label: 'Bouake (BYK)',
    city: 'Bouake',
    country: "Cote D'Ivoire",
  },
  PTI: {
    label: 'Port Douglas (PTI)',
    city: 'Port Douglas',
    country: 'Australia',
  },
  ACX: {
    label: 'Xingyi Airport (ACX)',
    city: 'Xingyi',
    country: 'China',
  },
  YJT: {
    label: 'Stephenville Airport (YJT)',
    city: 'Stephenville',
    country: 'Canada',
  },
  YYD: {
    label: 'Smithers Airport (YYD)',
    city: 'Smithers',
    country: 'Canada',
  },
  SAN: {
    label: 'San Diego (SAN)',
    city: 'San Diego',
    country: 'United States',
  },
  ARU: {
    label: 'Aracatuba (ARU)',
    city: 'Aracatuba',
    country: 'Brazil',
  },
  POT: {
    label: 'Ken Jones (POT)',
    city: 'Port Antonio',
    country: 'Jamaica',
  },
  ROR: {
    label: 'Airai (ROR)',
    city: 'Koror',
    country: 'Palau',
  },
  YLC: {
    label: 'Kimmirut (YLC)',
    city: 'Kimmirut Lake Harbour',
    country: 'Canada',
  },
  SKO: {
    label: 'Sokoto Airport (SKO)',
    city: 'Sokoto',
    country: 'Nigeria',
  },
  YKT: {
    label: 'Klemtu Airport (YKT)',
    city: 'Klemtu',
    country: 'Canada',
  },
  BES: {
    label: 'Guipavas (BES)',
    city: 'Brest',
    country: 'France',
  },
  EGX: {
    label: 'Egegik Airport (EGX)',
    city: 'Egegik',
    country: 'United States',
  },
  YBK: {
    label: 'Baker Lake (YBK)',
    city: 'Baker Lake',
    country: 'Canada',
  },
  OSA: {
    label: 'All Airports (OSA)',
    city: 'Osaka',
    country: 'Japan',
  },
  EUN: {
    label: 'Hassan I Airport (EUN)',
    city: 'Laayoune',
    country: 'Morocco',
  },
  LYA: {
    label: 'Luoyang (LYA)',
    city: 'Luoyang',
    country: 'China',
  },
  CZL: {
    label: 'Ain El Bey Airport (CZL)',
    city: 'Constantine',
    country: 'Algeria',
  },
  USK: {
    label: 'Usinsk Airport (USK)',
    city: 'Usinsk',
    country: 'Russia',
  },
  IPN: {
    label: 'Usiminas Airport (IPN)',
    city: 'Ipatinga',
    country: 'Brazil',
  },
  HAA: {
    label: 'Hasvik Airport (HAA)',
    city: 'Hasvik',
    country: 'Norway',
  },
  EGS: {
    label: 'Egilsstadir (EGS)',
    city: 'Egilsstadir',
    country: 'Iceland',
  },
  SIN: {
    label: 'Changi Airport (SIN)',
    city: 'Singapore',
    country: 'Singapore',
  },
  YXU: {
    label: 'Metropolitan Area Airport (YXU)',
    city: 'London',
    country: 'Canada',
  },
  ONL: {
    label: "O'Neill Mncpl (ONL)",
    city: "O'Neill",
    country: 'United States',
  },
  TZX: {
    label: 'Trabzon (TZX)',
    city: 'Trabzon',
    country: 'Turkey',
  },
  YXC: {
    label: 'Cranbrook Airport (YXC)',
    city: 'Cranbrook',
    country: 'Canada',
  },
  ZWL: {
    label: 'Wollaston Lake Airport (ZWL)',
    city: 'Wollaston Lake',
    country: 'Canada',
  },
  DLG: {
    label: 'Dillingham Municipal Airport (DLG)',
    city: 'Dillingham',
    country: 'United States',
  },
  WOL: {
    label: 'Wollongong (WOL)',
    city: 'Wollongong',
    country: 'Australia',
  },
  VCE: {
    label: 'Marco Polo Airport (VCE)',
    city: 'Venice',
    country: 'Italy',
  },
  NUX: {
    label: 'Novyj Urengoj Airport (NUX)',
    city: 'Novyj Urengoj',
    country: 'Russia',
  },
  ORI: {
    label: 'Port Lions Sea Plane Base (ORI)',
    city: 'Port Lions',
    country: 'United States',
  },
  EJA: {
    label: 'Variguies Airport (EJA)',
    city: 'Barrancabermeja',
    country: 'Colombia',
  },
  YZF: {
    label: 'Yellowknife Airport (YZF)',
    city: 'Yellowknife',
    country: 'Canada',
  },
  ASM: {
    label: 'Asmara Intl (ASM)',
    city: 'Asmara',
    country: 'Eritrea',
  },
  BOJ: {
    label: 'Bourgas (BOJ)',
    city: 'Bourgas',
    country: 'Bulgaria',
  },
  JHG: {
    label: 'Gasa Airport (JHG)',
    city: 'Jinghong',
    country: 'China',
  },
  ICN: {
    label: 'Incheon (ICN)',
    city: 'Seoul',
    country: 'South Korea',
  },
  TGH: {
    label: 'Tongoa Airport (TGH)',
    city: 'Tongoa',
    country: 'Vanuatu',
  },
  YDQ: {
    label: 'Dawson Creek Airport (YDQ)',
    city: 'Dawson Creek',
    country: 'Canada',
  },
  APO: {
    label: 'Apartado Airport (APO)',
    city: 'Apartado',
    country: 'Colombia',
  },
  MDT: {
    label: 'Harrisburg (MDT)',
    city: 'Harrisburg',
    country: 'United States',
  },
  YVZ: {
    label: 'Deer Lake Airport (YVZ)',
    city: 'Deer Lake',
    country: 'ON',
  },
  HRB: {
    label: 'Harbin (HRB)',
    city: 'Harbin',
    country: 'China',
  },
  LNJ: {
    label: 'Lincang Airport (LNJ)',
    city: 'Lincang',
    country: 'China',
  },
  DHN: {
    label: 'Dothan Airport (DHN)',
    city: 'Dothan',
    country: 'United States',
  },
  TMJ: {
    label: 'Termez Airport (TMJ)',
    city: 'Termez',
    country: 'Uzbekistan',
  },
  CRM: {
    label: 'National Airport (CRM)',
    city: 'Catarman',
    country: 'Philippines',
  },
  NNG: {
    label: 'Nanning (NNG)',
    city: 'Nanning',
    country: 'China',
  },
  ULM: {
    label: 'New Ulm Mncpl (ULM)',
    city: 'New Ulm',
    country: 'United States',
  },
  CZU: {
    label: 'Corozal Airport (CZU)',
    city: 'Corozal',
    country: 'Colombia',
  },
  MAJ: {
    label: 'Amata Kabua Intl (MAJ)',
    city: 'Majuro',
    country: 'Marshall Islands',
  },
  VQS: {
    label: 'Isla de Vieques (VQS)',
    city: 'Vieques Island',
    country: 'Puerto Rico',
  },
  ETZ: {
    label: 'Metz-Nancy-Lorraine Airport (ETZ)',
    city: 'Metz/Nancy',
    country: 'France',
  },
  QBC: {
    label: 'Bella Coola Airport (QBC)',
    city: 'Bella Coola',
    country: 'Canada',
  },
  LTN: {
    label: 'Luton (LTN)',
    city: 'London',
    country: 'United Kingdom',
  },
  YMT: {
    label: 'Chibougamau Mncpl (YMT)',
    city: 'Chibougamau',
    country: 'Canada',
  },
  PLW: {
    label: 'Mutiara Airport (PLW)',
    city: 'Palu',
    country: 'Indonesia',
  },
  SCY: {
    label: 'San Cristobal Airport (SCY)',
    city: 'San Cristobal',
    country: 'Ecuador',
  },
  NTE: {
    label: 'Nantes Atlantique (NTE)',
    city: 'Nantes',
    country: 'France',
  },
  WOT: {
    label: 'Wonan Airport (WOT)',
    city: 'Wonan',
    country: 'Taiwan',
  },
  HFD: {
    label: 'Hartford-Brainard (HFD)',
    city: 'Hartford',
    country: 'United States',
  },
  INU: {
    label: 'Nauru Island International Airport (INU)',
    city: 'Nauru Island',
    country: 'Nauru',
  },
  HOF: {
    label: 'Alahsa (HOF)',
    city: 'Alahsa',
    country: 'Saudi Arabia',
  },
  SKZ: {
    label: 'Sukkur Airport (SKZ)',
    city: 'Sukkur',
    country: 'Pakistan',
  },
  HKB: {
    label: 'Healy Lake Airport (HKB)',
    city: 'Healy Lake',
    country: 'United States',
  },
  BGW: {
    label: 'Baghdad Intl (BGW)',
    city: 'Baghdad',
    country: 'Iraq',
  },
  KPY: {
    label: 'Port Bailey Sea Plane Base (KPY)',
    city: 'Port Bailey',
    country: 'United States',
  },
  AJL: {
    label: 'Aizawl Airport (AJL)',
    city: 'Aizawl',
    country: 'India',
  },
  PEK: {
    label: 'Capital Airport (PEK)',
    city: 'Beijing',
    country: 'China',
  },
  CMH: {
    label: 'Columbus (CMH)',
    city: 'Columbus',
    country: 'OH',
  },
  BHG: {
    label: 'Brus Laguna Airport (BHG)',
    city: 'Brus Laguna',
    country: 'Honduras',
  },
  TNA: {
    label: 'Jinan (TNA)',
    city: 'Jinan',
    country: 'China',
  },
  ALB: {
    label: 'Albany (ALB)',
    city: 'Albany',
    country: 'NY',
  },
  MDW: {
    label: 'Midway (MDW)',
    city: 'Chicago',
    country: 'United States',
  },
  YCG: {
    label: 'Castlegar Airport (YCG)',
    city: 'Castlegar',
    country: 'Canada',
  },
  ZAC: {
    label: 'York Landing Airport (ZAC)',
    city: 'York Landing',
    country: 'Canada',
  },
  MGF: {
    label: 'Maringa Regional (MGF)',
    city: 'Maringa',
    country: 'Brazil',
  },
  LBR: {
    label: 'Labrea Airport (LBR)',
    city: 'Labrea',
    country: 'Brazil',
  },
  PIH: {
    label: 'Pocatello (PIH)',
    city: 'Pocatello',
    country: 'United States',
  },
  ANC: {
    label: 'Anchorage (ANC)',
    city: 'Anchorage',
    country: 'United States',
  },
  SXQ: {
    label: 'Soldotna (SXQ)',
    city: 'Soldotna',
    country: 'United States',
  },
  GNU: {
    label: 'Goodnews Bay Airport (GNU)',
    city: 'Goodnews Bay',
    country: 'United States',
  },
  AOO: {
    label: 'Blair Cty (AOO)',
    city: 'Altoona',
    country: 'United States',
  },
  DVN: {
    label: 'Davenport Mncpl (DVN)',
    city: 'Davenport',
    country: 'United States',
  },
  HUI: {
    label: 'Hue (HUI)',
    city: 'Hue',
    country: 'Vietnam',
  },
  FLW: {
    label: 'Santa Cruz Airport (FLW)',
    city: 'Flores Island (Azores)',
    country: 'Portugal',
  },
  SPF: {
    label: 'Black Hills (SPF)',
    city: 'Spearfish',
    country: 'United States',
  },
  SGU: {
    label: 'Saint George Municipal Airport (SGU)',
    city: 'St George',
    country: 'United States',
  },
  PZB: {
    label: 'Pietermaritzburg (PZB)',
    city: 'Pietermaritzburg',
    country: 'South Africa',
  },
  BOD: {
    label: 'Bordeaux (BOD)',
    city: 'Bordeaux',
    country: 'France',
  },
  SJE: {
    label: 'San Jose Del Gua Airport (SJE)',
    city: 'San Jose Del Gua',
    country: 'Colombia',
  },
  TEE: {
    label: 'Tebessa Airport (TEE)',
    city: 'Tebessa',
    country: 'Algeria',
  },
  VKT: {
    label: 'Vorkuta Airport (VKT)',
    city: 'Vorkuta',
    country: 'Russia',
  },
  YPE: {
    label: 'Peace River Airport (YPE)',
    city: 'Peace River',
    country: 'Canada',
  },
  SDT: {
    label: 'Saidu Sharif (SDT)',
    city: 'Saidu Sharif',
    country: 'Pakistan',
  },
  KYP: {
    label: 'Kyaukpyu Airport (KYP)',
    city: 'Kyaukpyu',
    country: 'Myanmar (Burma)',
  },
  WAQ: {
    label: 'Antsalova Airport (WAQ)',
    city: 'Antsalova',
    country: 'Madagascar',
  },
  GYY: {
    label: 'Gary Regl (GYY)',
    city: 'Gary',
    country: 'United States',
  },
  TVY: {
    label: 'Dawe Airport (TVY)',
    city: 'Dawe',
    country: 'Myanmar (Burma)',
  },
  MYL: {
    label: 'McCall (MYL)',
    city: 'McCall',
    country: 'United States',
  },
  HLH: {
    label: 'Ulanhot Airport (HLH)',
    city: 'Ulanhot',
    country: 'China',
  },
  JLN: {
    label: 'Joplin Airport (JLN)',
    city: 'Joplin',
    country: 'United States',
  },
  WGT: {
    label: 'Wangaratta (WGT)',
    city: 'Wangaratta',
    country: 'Australia',
  },
  BOS: {
    label: 'Boston (BOS)',
    city: 'Boston',
    country: 'United States',
  },
  RNO: {
    label: 'Reno (RNO)',
    city: 'Reno',
    country: 'United States',
  },
  NYC: {
    label: 'All Airports (NYC)',
    city: 'New York City',
    country: 'United States',
  },
  LDH: {
    label: 'Lord Howe Is (LDH)',
    city: 'Lord Howe Island',
    country: 'Australia',
  },
  GWE: {
    label: 'Gweru (GWE)',
    city: 'Gweru',
    country: 'Zimbabwe',
  },
  XLS: {
    label: 'St Louis Airport (XLS)',
    city: 'Saint Louis',
    country: 'Senegal',
  },
  SIK: {
    label: 'Sikeston Memorial (SIK)',
    city: 'Sikeston',
    country: 'United States',
  },
  COE: {
    label: "Coeur d'Alene (COE)",
    city: "Coeur d'Alene",
    country: 'United States',
  },
  CMX: {
    label: 'Houghton County Airport (CMX)',
    city: 'Hancock',
    country: 'United States',
  },
  ATM: {
    label: 'Altamira Airport (ATM)',
    city: 'Altamira',
    country: 'Brazil',
  },
  MVY: {
    label: "Martha's Vineyard (MVY)",
    city: "Martha's Vineyard",
    country: 'United States',
  },
  LNY: {
    label: "Lana'i Airport (LNY)",
    city: "Lana'i City",
    country: 'United States',
  },
  DEM: {
    label: 'Dembidollo Airport (DEM)',
    city: 'Dembidollo',
    country: 'Ethiopia',
  },
  OMK: {
    label: 'Omak Mncpl (OMK)',
    city: 'Omak',
    country: 'United States',
  },
  BRN: {
    label: 'Belp (BRN)',
    city: 'Berne',
    country: 'Switzerland',
  },
  CBH: {
    label: 'Leger Airport (CBH)',
    city: 'Bechar',
    country: 'Algeria',
  },
  SJF: {
    label: 'Seaplane Ramp (SJF)',
    city: 'Cruz Bay',
    country: 'US Virgin Islands',
  },
  CHL: {
    label: 'Challis Mncpl (CHL)',
    city: 'Challis',
    country: 'United States',
  },
  ASW: {
    label: 'Aswan-Daraw (ASW)',
    city: 'Aswan',
    country: 'Egypt',
  },
  PGM: {
    label: 'Port Graham Airport (PGM)',
    city: 'Port Graham',
    country: 'United States',
  },
  YNE: {
    label: 'Norway House Airport (YNE)',
    city: 'Norway House',
    country: 'Canada',
  },
  RDM: {
    label: 'Roberts Field (RDM)',
    city: 'Redmond',
    country: 'United States',
  },
  JIJ: {
    label: 'Jigiga Airport (JIJ)',
    city: 'Jijiga',
    country: 'Ethiopia',
  },
  BWG: {
    label: 'Warren Cty (BWG)',
    city: 'Bowling Green',
    country: 'United States',
  },
  KLX: {
    label: 'Kalamata (KLX)',
    city: 'Kalamata',
    country: 'Greece',
  },
  NVI: {
    label: 'Navoi Airport (NVI)',
    city: 'Navoi',
    country: 'Uzbekistan',
  },
  TUS: {
    label: 'Tucson (TUS)',
    city: 'Tucson',
    country: 'United States',
  },
  SPI: {
    label: 'Capital Airport (SPI)',
    city: 'Springfield',
    country: 'IL',
  },
  JUJ: {
    label: 'El Cadillal (JUJ)',
    city: 'Jujuy',
    country: 'Argentina',
  },
  KIN: {
    label: 'Norman Manley Airport (KIN)',
    city: 'Kingston',
    country: 'Jamaica',
  },
  LSH: {
    label: 'Lashio Airport (LSH)',
    city: 'Lashio',
    country: 'Myanmar (Burma)',
  },
  CWW: {
    label: 'Corowa (CWW)',
    city: 'Corowa',
    country: 'Australia',
  },
  SGO: {
    label: 'St George Airport (SGO)',
    city: 'St George',
    country: 'Australia',
  },
  AGR: {
    label: 'Kheria (AGR)',
    city: 'Agra',
    country: 'India',
  },
  LHE: {
    label: 'Lahore (LHE)',
    city: 'Lahore',
    country: 'Pakistan',
  },
  JGO: {
    label: 'Qeqertarsuaq Airport (JGO)',
    city: 'Qeqertarsuaq',
    country: 'Greenland',
  },
  YTQ: {
    label: 'Tasiujuaq Airport (YTQ)',
    city: 'Tasiujuaq',
    country: 'Canada',
  },
  STO: {
    label: 'All Airports (STO)',
    city: 'Stockholm',
    country: 'Sweden',
  },
  OSY: {
    label: 'Namsos (OSY)',
    city: 'Namsos',
    country: 'Norway',
  },
  TUB: {
    label: 'Tubuai Island Airport (TUB)',
    city: 'Tubuai Island',
    country: 'French Polynesia and Tahiti',
  },
  OOL: {
    label: 'Gold Coast (OOL)',
    city: 'Coolangatta',
    country: 'Australia',
  },
  MQX: {
    label: 'Makale Airport (MQX)',
    city: 'Makale',
    country: 'Ethiopia',
  },
  BFD: {
    label: 'Bradford-Regl (BFD)',
    city: 'Bradford',
    country: 'United States',
  },
  HGA: {
    label: 'Hargeisa Airport (HGA)',
    city: 'Hargeisa',
    country: 'Somalia',
  },
  EBO: {
    label: 'Ebon Airport (EBO)',
    city: 'Ebon',
    country: 'Marshall Islands',
  },
  NTG: {
    label: 'Nantong (NTG)',
    city: 'Nantong',
    country: 'China',
  },
  RHI: {
    label: 'Oneida County Airport (RHI)',
    city: 'Rhinelander',
    country: 'United States',
  },
  GOM: {
    label: 'Goma Airport (GOM)',
    city: 'Goma',
    country: 'Democratic Republic of Congo',
  },
  TMT: {
    label: 'Trombetas Airport (TMT)',
    city: 'Trombetas',
    country: 'Brazil',
  },
  SDR: {
    label: 'Santander (SDR)',
    city: 'Santander',
    country: 'Spain',
  },
  AGN: {
    label: 'Angoon Airport (AGN)',
    city: 'Angoon',
    country: 'United States',
  },
  PSI: {
    label: 'Pasni Airport (PSI)',
    city: 'Pasni',
    country: 'Pakistan',
  },
  TLE: {
    label: 'Tulear Airport (TLE)',
    city: 'Tulear',
    country: 'Madagascar',
  },
  CYI: {
    label: 'Chiayi (CYI)',
    city: 'Chiayi',
    country: 'Taiwan',
  },
  FSM: {
    label: 'Fort Smith Municipal Airport (FSM)',
    city: 'Ft Smith',
    country: 'United States',
  },
  MQF: {
    label: 'Magnitogorsk Airport (MQF)',
    city: 'Magnitogorsk',
    country: 'Russia',
  },
  MXX: {
    label: 'Mora (MXX)',
    city: 'Mora',
    country: 'Sweden',
  },
  SIF: {
    label: 'Simara Airport (SIF)',
    city: 'Simara',
    country: 'Nepal',
  },
  BOI: {
    label: 'Boise (BOI)',
    city: 'Boise',
    country: 'United States',
  },
  CJC: {
    label: 'El Loa (CJC)',
    city: 'Calama',
    country: 'Chile and Easter Island',
  },
  CSX: {
    label: 'Changsha (CSX)',
    city: 'Changsha',
    country: 'China',
  },
  WHF: {
    label: 'Wadi Halfa Airport (WHF)',
    city: 'Wadi Halfa',
    country: 'Sudan',
  },
  MRZ: {
    label: 'Moree (MRZ)',
    city: 'Moree',
    country: 'Australia',
  },
  KMQ: {
    label: 'Komatsu (KMQ)',
    city: 'Komatsu',
    country: 'Japan',
  },
  TSE: {
    label: 'Astana (TSE)',
    city: 'Astana',
    country: 'Kazakhstan',
  },
  RAF: {
    label: 'Rafaela Airport (RAF)',
    city: 'Rafaela',
    country: 'Argentina',
  },
  AUG: {
    label: 'Daniel Field (AUG)',
    city: 'Augusta',
    country: 'ME',
  },
  RTM: {
    label: 'Rotterdam (RTM)',
    city: 'Rotterdam',
    country: 'Netherlands',
  },
  SBR: {
    label: 'Saibai Island Airport (SBR)',
    city: 'Saibai Island',
    country: 'Australia',
  },
  BEB: {
    label: 'Benbecula Airport (BEB)',
    city: 'Benbecula',
    country: 'United Kingdom',
  },
  RCL: {
    label: 'Redcliffe Airport (RCL)',
    city: 'Redcliffe',
    country: 'Vanuatu',
  },
  GRX: {
    label: 'Granada (GRX)',
    city: 'Granada',
    country: 'Spain',
  },
  KUG: {
    label: 'Kubin Island Airport (KUG)',
    city: 'Kubin Island',
    country: 'Australia',
  },
  VNE: {
    label: 'Vannes (VNE)',
    city: 'Vannes',
    country: 'France',
  },
  FPO: {
    label: 'Freeport International Airport (FPO)',
    city: 'Freeport',
    country: 'Bahamas',
  },
  ATL: {
    label: 'Atlanta (ATL)',
    city: 'Atlanta',
    country: 'United States',
  },
  VCS: {
    label: 'Coong Airport (VCS)',
    city: 'Con Dao',
    country: 'Vietnam',
  },
  GOT: {
    label: 'Landvetter Airport (GOT)',
    city: 'Gothenburg',
    country: 'Sweden',
  },
  USU: {
    label: 'Busuanga Airport (USU)',
    city: 'Busuanga',
    country: 'Philippines',
  },
  RHO: {
    label: 'Diagoras (RHO)',
    city: 'Rhodes Is',
    country: 'Greece',
  },
  KNQ: {
    label: 'Kone Airport (KNQ)',
    city: 'Kone',
    country: 'New Caledonia',
  },
  YXH: {
    label: 'Medicine Hat Airport (YXH)',
    city: 'Medicine Hat',
    country: 'Canada',
  },
  JAX: {
    label: 'Jacksonville (JAX)',
    city: 'Jacksonville',
    country: 'United States',
  },
  PBZ: {
    label: 'Plettenberg (PBZ)',
    city: 'Plettenberg Bay',
    country: 'South Africa',
  },
  BIS: {
    label: 'Bismarck Airport (BIS)',
    city: 'Bismarck',
    country: 'United States',
  },
  KMO: {
    label: 'Manokotak Sea Plane Base (KMO)',
    city: 'Manokotak',
    country: 'United States',
  },
  NYM: {
    label: 'Nadym Airport (NYM)',
    city: 'Nadym',
    country: 'Russia',
  },
  ZEI: {
    label: 'Railway Station (ZEI)',
    city: 'Garmisch-Partenkirchen',
    country: 'Germany',
  },
  PHF: {
    label: 'Newport News/Williamsburg Airport (PHF)',
    city: 'Newport News',
    country: 'United States',
  },
  PVK: {
    label: 'Aktion (PVK)',
    city: 'Preveza',
    country: 'Greece',
  },
  HYL: {
    label: 'Hollis Sea Plane Base (HYL)',
    city: 'Hollis',
    country: 'United States',
  },
  YQL: {
    label: 'Lethbridge Airport (YQL)',
    city: 'Lethbridge',
    country: 'Canada',
  },
  PAT: {
    label: 'Patna (PAT)',
    city: 'Patna',
    country: 'India',
  },
  VAP: {
    label: 'Valparaiso (VAP)',
    city: 'Valparaiso',
    country: 'Chile and Easter Island',
  },
  LLU: {
    label: 'Alluitsup Paa Airport (LLU)',
    city: 'Alluitsup Paa',
    country: 'Greenland',
  },
  AAX: {
    label: 'Araxa (AAX)',
    city: 'Araxa',
    country: 'Brazil',
  },
  SBW: {
    label: 'Sibu (SBW)',
    city: 'Sibu',
    country: 'Malaysia',
  },
  MYU: {
    label: 'Ellis Field (MYU)',
    city: 'Mekoryuk',
    country: 'United States',
  },
  CPR: {
    label: 'Casper Airport (CPR)',
    city: 'Casper',
    country: 'United States',
  },
  MRV: {
    label: 'Mineralnye Vody Airport (MRV)',
    city: 'Mineralnye Vody',
    country: 'Russia',
  },
  AXR: {
    label: 'Arutua Airport (AXR)',
    city: 'Arutua',
    country: 'French Polynesia and Tahiti',
  },
  MII: {
    label: 'Marilia (MII)',
    city: 'Marilia',
    country: 'Brazil',
  },
  POW: {
    label: 'Portoroz (POW)',
    city: 'Portoroz',
    country: 'Slovenia',
  },
  BFL: {
    label: 'Meadows Field (BFL)',
    city: 'Bakersfield',
    country: 'United States',
  },
  HDS: {
    label: 'Hoedspruit (HDS)',
    city: 'Hoedspruit',
    country: 'South Africa',
  },
  PES: {
    label: 'Petrozavodsk (PES)',
    city: 'Petrozavodsk',
    country: 'Russia',
  },
  HVR: {
    label: 'City Cty (HVR)',
    city: 'Havre',
    country: 'United States',
  },
  PEX: {
    label: 'Pechora Airport (PEX)',
    city: 'Pechora',
    country: 'Russia',
  },
  SCW: {
    label: 'Syktyvkar Airport (SCW)',
    city: 'Syktyvkar',
    country: 'Russia',
  },
  TBW: {
    label: 'Tambov Airport (TBW)',
    city: 'Tambov',
    country: 'Russia',
  },
  CPT: {
    label: 'Cape Town Intl (CPT)',
    city: 'Cape Town',
    country: 'South Africa',
  },
  HVG: {
    label: 'Valan (HVG)',
    city: 'Honningsvag',
    country: 'Norway',
  },
  PUS: {
    label: 'Gimhae (PUS)',
    city: 'Busan',
    country: 'South Korea',
  },
  KTR: {
    label: 'Tindal (KTR)',
    city: 'Katherine',
    country: 'Australia',
  },
  FUE: {
    label: 'Fuerteventura (FUE)',
    city: 'Fuerteventura',
    country: 'Spain',
  },
  BRO: {
    label: 'South Padre Island International Airport (BRO)',
    city: 'Brownsville',
    country: 'United States',
  },
  ERC: {
    label: 'Erzincan Airport (ERC)',
    city: 'Erzincan',
    country: 'Turkey',
  },
  HNL: {
    label: 'Honolulu (HNL)',
    city: 'Honolulu',
    country: 'United States',
  },
  MFH: {
    label: 'Mesquite Municipal (MFH)',
    city: 'Mesquite',
    country: 'United States',
  },
  NTL: {
    label: 'Williamtown (NTL)',
    city: 'Newcastle',
    country: 'Australia',
  },
  SWP: {
    label: 'Swakopmund (SWP)',
    city: 'Swakopmund',
    country: 'Namibia',
  },
  YIF: {
    label: 'Pakuashipi Airport (YIF)',
    city: 'Pakuashipi',
    country: 'Canada',
  },
  STJ: {
    label: 'Rosecrans Memorial (STJ)',
    city: 'St Joseph',
    country: 'United States',
  },
  MTH: {
    label: 'Marathon (MTH)',
    city: 'Marathon',
    country: 'United States',
  },
  YKM: {
    label: 'Yakima Air Terminal (YKM)',
    city: 'Yakima',
    country: 'United States',
  },
  LYI: {
    label: 'Linyi Airport (LYI)',
    city: 'Linyi',
    country: 'China',
  },
  MCE: {
    label: 'Merced Municipal Airport (MCE)',
    city: 'Merced',
    country: 'United States',
  },
  LCV: {
    label: 'Lucca (LCV)',
    city: 'Lucca',
    country: 'Italy',
  },
  AOH: {
    label: 'Allen County (AOH)',
    city: 'Lima',
    country: 'United States',
  },
  FMS: {
    label: 'Fort Madison Mncpl (FMS)',
    city: 'Fort Madison',
    country: 'United States',
  },
  VDS: {
    label: 'Vadso (VDS)',
    city: 'Vadso',
    country: 'Norway',
  },
  TSV: {
    label: 'Townsville (TSV)',
    city: 'Townsville',
    country: 'Australia',
  },
  MLG: {
    label: 'Malang Airport (MLG)',
    city: 'Malang',
    country: 'Indonesia',
  },
  TRV: {
    label: 'Trivandrum (TRV)',
    city: 'Thiruvananthapuram',
    country: 'India',
  },
  YNS: {
    label: 'Nemiscau Airport (YNS)',
    city: 'Nemiscau',
    country: 'Canada',
  },
  YNO: {
    label: 'North Spirit Lake Airport (YNO)',
    city: 'North Spirit Lake',
    country: 'Canada',
  },
  BDR: {
    label: 'Sikorsky Memorial (BDR)',
    city: 'Bridgeport',
    country: 'United States',
  },
  LCJ: {
    label: 'Lodz Lublinek Airport (LCJ)',
    city: 'Lodz',
    country: 'Poland',
  },
  RHG: {
    label: 'Ruhengeri (RHG)',
    city: 'Ruhengeri',
    country: 'Rwanda',
  },
  LMC: {
    label: 'Lamacarena Airport (LMC)',
    city: 'Lamacarena',
    country: 'Colombia',
  },
  JGR: {
    label: 'Gronnedal Heliport (JGR)',
    city: 'Gronnedal',
    country: 'Greenland',
  },
  PZE: {
    label: 'Penzance (PZE)',
    city: 'Penzance',
    country: 'United Kingdom',
  },
  COD: {
    label: 'Yellowstone Regional Airport (COD)',
    city: 'Cody',
    country: 'United States',
  },
  IOA: {
    label: 'Ioannina (IOA)',
    city: 'Ioannina',
    country: 'Greece',
  },
  YRA: {
    label: 'Rae Lakes Airport (YRA)',
    city: 'Rae Lakes',
    country: 'Canada',
  },
  HAU: {
    label: 'Haugesund (HAU)',
    city: 'Haugesund',
    country: 'Norway',
  },
  YBP: {
    label: 'Yibin (YBP)',
    city: 'Yibin',
    country: 'China',
  },
  SEZ: {
    label: 'Seychelles Intl (SEZ)',
    city: 'Mahe Island',
    country: 'Seychelles',
  },
  YRL: {
    label: 'Red Lake Airport (YRL)',
    city: 'Red Lake',
    country: 'Canada',
  },
  TGD: {
    label: 'Golubovci (TGD)',
    city: 'Podgorica',
    country: 'Montenegro',
  },
  MNL: {
    label: 'Ninoy Aquino Intl (MNL)',
    city: 'Manila',
    country: 'Philippines',
  },
  DAY: {
    label: 'Dayton (DAY)',
    city: 'Dayton',
    country: 'United States',
  },
  NLE: {
    label: 'Jerry Tyler Memorial (NLE)',
    city: 'Niles',
    country: 'United States',
  },
  CKB: {
    label: 'Benedum Airport (CKB)',
    city: 'Clarksburg',
    country: 'United States',
  },
  XIQ: {
    label: 'Ilimanaq Harbour Airport (XIQ)',
    city: 'Ilimanaq',
    country: 'Greenland',
  },
  LDI: {
    label: 'Kikwetu Airport (LDI)',
    city: 'Lindi',
    country: 'Tanzania',
  },
  FAE: {
    label: 'Vagar Airport (FAE)',
    city: 'Faroe Islands',
    country: 'Denmark',
  },
  ERH: {
    label: 'Errachidia Airport (ERH)',
    city: 'Errachidia',
    country: 'Morocco',
  },
  BYA: {
    label: 'Boundary Airport (BYA)',
    city: 'Boundary',
    country: 'United States',
  },
  STX: {
    label: 'Alexander Hamilton Airport (STX)',
    city: 'St Croix',
    country: 'US Virgin Islands',
  },
  UGB: {
    label: 'Ugashik Bay Airport (UGB)',
    city: 'Pilot Point',
    country: 'United States',
  },
  GLS: {
    label: 'Scholes Fld (GLS)',
    city: 'Galveston',
    country: 'United States',
  },
  SAL: {
    label: 'Comalapa International Airport (SAL)',
    city: 'San Salvador',
    country: 'El Salvador',
  },
  SFN: {
    label: 'Santa Fe Mncpl (SFN)',
    city: 'Santa Fe',
    country: 'Argentina',
  },
  CIZ: {
    label: 'Coari Airport (CIZ)',
    city: 'Coari',
    country: 'Brazil',
  },
  DBB: {
    label: 'Alalamain International Airport (DBB)',
    city: 'Dabaa City',
    country: 'Egypt',
  },
  PHX: {
    label: 'Phoenix (PHX)',
    city: 'Phoenix',
    country: 'United States',
  },
  JNU: {
    label: 'Juneau (JNU)',
    city: 'Juneau',
    country: 'United States',
  },
  GRY: {
    label: 'Grimsey Airport (GRY)',
    city: 'Grimsey',
    country: 'Iceland',
  },
  BZR: {
    label: 'Beziers Vias (BZR)',
    city: 'Beziers',
    country: 'France',
  },
  MPA: {
    label: 'Mpacha Airport (MPA)',
    city: 'Mpacha',
    country: 'Namibia',
  },
  FRL: {
    label: 'Luigi Ridolfi (FRL)',
    city: 'Forli',
    country: 'Italy',
  },
  LEE: {
    label: 'Leesburg (LEE)',
    city: 'Leesburg',
    country: 'United States',
  },
  WMC: {
    label: 'Winnemucca Mncpl (WMC)',
    city: 'Winnemucca',
    country: 'United States',
  },
  VSF: {
    label: 'State (VSF)',
    city: 'Springfield',
    country: 'United States',
  },
  ZKE: {
    label: 'Kaschechewan Airport (ZKE)',
    city: 'Kaschechewan',
    country: 'Canada',
  },
  AUY: {
    label: 'Aneityum Airport (AUY)',
    city: 'Aneityum',
    country: 'Vanuatu',
  },
  BCM: {
    label: 'Bacau (BCM)',
    city: 'Bacau',
    country: 'Romania',
  },
  TLV: {
    label: 'Ben Gurion International Airport (TLV)',
    city: 'Tel Aviv',
    country: 'Israel',
  },
  LPQ: {
    label: 'Luang Prabang (LPQ)',
    city: 'Luang Prabang',
    country: 'Laos',
  },
  KCC: {
    label: 'Coffman Cove Sea Plane Base (KCC)',
    city: 'Coffman Cove',
    country: 'United States',
  },
  SLW: {
    label: 'Saltillo (SLW)',
    city: 'Saltillo',
    country: 'Mexico',
  },
  DCA: {
    label: 'National (DCA)',
    city: 'Washington',
    country: 'DC',
  },
  DOK: {
    label: 'Donetsk (DOK)',
    city: 'Donetsk',
    country: 'Ukraine',
  },
  IFP: {
    label: 'Laughlin/Bullhead (IFP)',
    city: 'Bullhead City',
    country: 'United States',
  },
  BLL: {
    label: 'Billund (BLL)',
    city: 'Billund',
    country: 'Denmark',
  },
  NSO: {
    label: 'Scone (NSO)',
    city: 'Scone',
    country: 'Australia',
  },
  TTR: {
    label: 'Tanatoraja (TTR)',
    city: 'Tana Toraja',
    country: 'Indonesia',
  },
  OEL: {
    label: 'Orel (OEL)',
    city: 'Orel',
    country: 'Russia',
  },
  KVB: {
    label: 'Skovde (KVB)',
    city: 'Skovde',
    country: 'Sweden',
  },
  IPL: {
    label: 'Imperial County Airport (IPL)',
    city: 'Imperial',
    country: 'United States',
  },
  PBI: {
    label: 'West Palm Beach (PBI)',
    city: 'West Palm Beach',
    country: 'United States',
  },
  QFB: {
    label: 'Freiburg (QFB)',
    city: 'Freiburg',
    country: 'Germany',
  },
  BRR: {
    label: 'North Bay (BRR)',
    city: 'Barra',
    country: 'United Kingdom',
  },
  UJE: {
    label: 'Ujae Island Airport (UJE)',
    city: 'Ujae Island',
    country: 'Marshall Islands',
  },
  RSJ: {
    label: 'Rosario Sea Plane Base (RSJ)',
    city: 'Rosario',
    country: 'United States',
  },
  CTD: {
    label: 'Chitre (CTD)',
    city: 'Chitre',
    country: 'Panama',
  },
  GES: {
    label: 'Buayan Airport (GES)',
    city: 'General Santos',
    country: 'Philippines',
  },
  JAN: {
    label: 'Thomson Field (JAN)',
    city: 'Jackson',
    country: 'MS',
  },
  GNE: {
    label: 'Ghent (GNE)',
    city: 'Ghent',
    country: 'Belgium',
  },
  IYK: {
    label: 'Kern County Airport (IYK)',
    city: 'Inyokern',
    country: 'United States',
  },
  VDB: {
    label: 'Valdres (VDB)',
    city: 'Fagernes',
    country: 'Norway',
  },
  CBI: {
    label: 'Cape Barren Island Airport (CBI)',
    city: 'Cape Barren Island',
    country: 'Australia',
  },
  IQN: {
    label: 'Qingyang Airport (IQN)',
    city: 'Qingyang',
    country: 'China',
  },
  JCH: {
    label: 'Qasigiannguit Airport (JCH)',
    city: 'Qasigiannguit',
    country: 'Greenland',
  },
  CEC: {
    label: 'Mc Namara Field (CEC)',
    city: 'Crescent City',
    country: 'United States',
  },
  OTM: {
    label: 'Industrial (OTM)',
    city: 'Ottumwa',
    country: 'United States',
  },
  GXG: {
    label: 'Negage Airport (GXG)',
    city: 'Negage',
    country: 'Angola',
  },
  BSC: {
    label: 'Bahia Solano Airport (BSC)',
    city: 'Bahia Solano',
    country: 'Colombia',
  },
  LGB: {
    label: 'Long Beach (LGB)',
    city: 'Long Beach',
    country: 'United States',
  },
  CMD: {
    label: 'Cootamundra (CMD)',
    city: 'Cootamundra',
    country: 'Australia',
  },
  KTT: {
    label: 'Kittila (KTT)',
    city: 'Kittila',
    country: 'Finland',
  },
  WIN: {
    label: 'Winton Airport (WIN)',
    city: 'Winton',
    country: 'Australia',
  },
  BBU: {
    label: 'Baneasa (BBU)',
    city: 'Bucharest',
    country: 'Romania',
  },
  CNS: {
    label: 'Cairns (CNS)',
    city: 'Cairns',
    country: 'Australia',
  },
  TWD: {
    label: 'Jefferson County (TWD)',
    city: 'Port Townsend',
    country: 'United States',
  },
  AMA: {
    label: 'Amarillo (AMA)',
    city: 'Amarillo',
    country: 'United States',
  },
  CIB: {
    label: 'Airport in the Sky (CIB)',
    city: 'Catalina Island',
    country: 'United States',
  },
  KBJ: {
    label: 'Kings Canyon (KBJ)',
    city: 'Kings Canyon',
    country: 'Australia',
  },
  MOC: {
    label: 'Montes Claros Airport (MOC)',
    city: 'Montes Claros',
    country: 'Brazil',
  },
  RIF: {
    label: 'Richfield Mncpl (RIF)',
    city: 'Richfield',
    country: 'United States',
  },
  SHX: {
    label: 'Shageluk Airport (SHX)',
    city: 'Shageluk',
    country: 'United States',
  },
  LUA: {
    label: 'Lukla Airport (LUA)',
    city: 'Lukla',
    country: 'Nepal',
  },
  MLI: {
    label: 'Moline (MLI)',
    city: 'Moline',
    country: 'United States',
  },
  HSP: {
    label: 'Ingalls Field (HSP)',
    city: 'Hot Springs',
    country: 'United States',
  },
  BNE: {
    label: 'Brisbane Intl (BNE)',
    city: 'Brisbane',
    country: 'Australia',
  },
  NGS: {
    label: 'Nagasaki (NGS)',
    city: 'Nagasaki',
    country: 'Japan',
  },
  FIH: {
    label: "N'Djili (FIH)",
    city: 'Kinshasa',
    country: 'Democratic Republic of Congo',
  },
  BQS: {
    label: 'Blagoveschensk Airport (BQS)',
    city: 'Blagoveschensk',
    country: 'Russia',
  },
  RWI: {
    label: 'Rocky Mount-Wilson (RWI)',
    city: 'Rocky Mount',
    country: 'United States',
  },
  OGD: {
    label: 'Ogden Mncpl (OGD)',
    city: 'Ogden',
    country: 'United States',
  },
  SSX: {
    label: 'Samsun (SSX)',
    city: 'Samsun',
    country: 'Turkey',
  },
  VIS: {
    label: 'Visalia Airport (VIS)',
    city: 'Visalia',
    country: 'United States',
  },
  LIT: {
    label: 'Little Rock (LIT)',
    city: 'Little Rock',
    country: 'United States',
  },
  RWL: {
    label: 'Rawlins (RWL)',
    city: 'Rawlins',
    country: 'United States',
  },
  MGC: {
    label: 'Michigan City (MGC)',
    city: 'Michigan City',
    country: 'United States',
  },
  YZG: {
    label: 'Salluit Airport (YZG)',
    city: 'Salluit',
    country: 'Canada',
  },
  SKU: {
    label: 'Skiros (SKU)',
    city: 'Skiros',
    country: 'Greece',
  },
  CHQ: {
    label: 'Chania-Souda (CHQ)',
    city: 'Chania',
    country: 'Greece',
  },
  CLD: {
    label: 'Carlsbad Airport (CLD)',
    city: 'Carlsbad',
    country: 'CA',
  },
  KOZ: {
    label: 'Ouzinkie Sea Plane Base (KOZ)',
    city: 'Ouzinkie',
    country: 'United States',
  },
  IXJ: {
    label: 'Satwari (IXJ)',
    city: 'Jammu',
    country: 'India',
  },
  FMY: {
    label: 'SW Florida Intl (RSW)',
    city: 'Fort Myers',
    country: 'FL',
  },
  ATH: {
    label: 'Athinai Airport (ATH)',
    city: 'Athens',
    country: 'Greece',
  },
  CTN: {
    label: 'Cooktown Airport (CTN)',
    city: 'Cooktown',
    country: 'Australia',
  },
  ABJ: {
    label: 'FH Boigny (ABJ)',
    city: 'Abidjan',
    country: "Cote D'Ivoire",
  },
  WUS: {
    label: 'Wuyishan (WUS)',
    city: 'Wuyishan',
    country: 'China',
  },
  HOB: {
    label: 'Lea Cty (HOB)',
    city: 'Hobbs',
    country: 'United States',
  },
  SAW: {
    label: 'Sabiha Gokcen (SAW)',
    city: 'Istanbul',
    country: 'Turkey',
  },
  HSV: {
    label: 'Madison County Airport (HSV)',
    city: 'Huntsville',
    country: 'United States',
  },
  OUD: {
    label: 'Les Angades (OUD)',
    city: 'Oujda',
    country: 'Morocco',
  },
  UNI: {
    label: 'Union Is (UNI)',
    city: 'Union Island',
    country: 'St Vincent and the Grenadines',
  },
  OBY: {
    label: 'Ittoqqortoormiit Airport (OBY)',
    city: 'Ittoqqortoormiit',
    country: 'Greenland',
  },
  MUR: {
    label: 'Marudi Airport (MUR)',
    city: 'Marudi',
    country: 'Malaysia',
  },
  MLX: {
    label: 'Malatya Airport (MLX)',
    city: 'Malatya',
    country: 'Turkey',
  },
  OLM: {
    label: 'Olympia (OLM)',
    city: 'Olympia',
    country: 'United States',
  },
  YHA: {
    label: 'Port Hope Simpson Airport (YHA)',
    city: 'Port Hope Simpson',
    country: 'Canada',
  },
  BNK: {
    label: 'Ballina Byron Gtwy (BNK)',
    city: 'Ballina',
    country: 'Australia',
  },
  SPW: {
    label: 'Spencer Mncpl (SPW)',
    city: 'Spencer',
    country: 'United States',
  },
  YBL: {
    label: 'Campbell River Airport (YBL)',
    city: 'Campbell River',
    country: 'Canada',
  },
  CVM: {
    label: 'Ciudad Victoria (CVM)',
    city: 'Ciudad Victoria',
    country: 'Mexico',
  },
  CNQ: {
    label: 'Camba Punta Airport (CNQ)',
    city: 'Corrientes',
    country: 'Argentina',
  },
  ZNQ: {
    label: 'Railway Station (ZNQ)',
    city: 'Ingolstadt',
    country: 'Germany',
  },
  NDJ: {
    label: "N'Djamena (NDJ)",
    city: "N'Djamena",
    country: 'Chad',
  },
  LDE: {
    label: 'Tarbes Ossun Lourdes (LDE)',
    city: 'Lourdes',
    country: 'France',
  },
  SOO: {
    label: 'Soderhamn (SOO)',
    city: 'Soderhamn',
    country: 'Sweden',
  },
  FDF: {
    label: 'Lamentin (FDF)',
    city: 'Fort de France',
    country: 'Martinique',
  },
  ALL: {
    label: 'Albenga Airport (ALL)',
    city: 'Albenga',
    country: 'Italy',
  },
  INI: {
    label: 'Nis Airport (INI)',
    city: 'Nis',
    country: 'Serbia',
  },
  PLH: {
    label: 'Plymouth (PLH)',
    city: 'Plymouth',
    country: 'United Kingdom',
  },
  OCE: {
    label: 'Ocean City (OCE)',
    city: 'Ocean City',
    country: 'United States',
  },
  RUM: {
    label: 'Rumjatar Airport (RUM)',
    city: 'Rumjatar',
    country: 'Nepal',
  },
  LZC: {
    label: 'Lazaro Cardenas Mncp (LZC)',
    city: 'Lazaro Cardenas',
    country: 'Mexico',
  },
  NFO: {
    label: "Mata'aho Airport (NFO)",
    city: "Niuafo'ou",
    country: 'Tonga',
  },
  SKG: {
    label: 'Makedonia (SKG)',
    city: 'Thessaloniki',
    country: 'Greece',
  },
  KWJ: {
    label: 'Gwangju Airport (KWJ)',
    city: 'Gwangju',
    country: 'South Korea',
  },
  GJT: {
    label: 'Walker Field (GJT)',
    city: 'Grand Junction',
    country: 'United States',
  },
  UKU: {
    label: 'Nuku Airport (UKU)',
    city: 'Nuku',
    country: 'Papua New Guinea',
  },
  CCF: {
    label: 'Salvaza (CCF)',
    city: 'Carcassonne',
    country: 'France',
  },
  GMI: {
    label: 'Gasmata Island Airport (GMI)',
    city: 'Gasmata Island',
    country: 'Papua New Guinea',
  },
  HXX: {
    label: 'Hay (HXX)',
    city: 'Hay',
    country: 'Australia',
  },
  JNZ: {
    label: 'Jinzhou (JNZ)',
    city: 'Jinzhou',
    country: 'China',
  },
  TJS: {
    label: 'Tanjung Selor Airport (TJS)',
    city: 'Tanjung Selor',
    country: 'Indonesia',
  },
  MIV: {
    label: 'Millville Mncpl (MIV)',
    city: 'Millville',
    country: 'United States',
  },
  GUB: {
    label: 'Guerrero Negro (GUB)',
    city: 'Guerrero Negro',
    country: 'Mexico',
  },
  BLJ: {
    label: 'Batna Airport (BLJ)',
    city: 'Batna',
    country: 'Algeria',
  },
  NOU: {
    label: 'Tontouta (NOU)',
    city: 'Noumea',
    country: 'New Caledonia',
  },
  ASP: {
    label: 'Alice Spring (ASP)',
    city: 'Alice Springs',
    country: 'Australia',
  },
  SDG: {
    label: 'Sanandaj Airport (SDG)',
    city: 'Sanandaj',
    country: 'Iran',
  },
  SFD: {
    label: 'Las Flecheras Airport (SFD)',
    city: 'San Fernando De Apure',
    country: 'Venezuela',
  },
  CAH: {
    label: 'Ca Mau Airport (CAH)',
    city: 'Ca Mau',
    country: 'Vietnam',
  },
  BMD: {
    label: 'Belo Airport (BMD)',
    city: 'Belo',
    country: 'Madagascar',
  },
  DGA: {
    label: 'Dangriga (DGA)',
    city: 'Dangriga',
    country: 'Belize',
  },
  TUU: {
    label: 'Tabuk Airport (TUU)',
    city: 'Tabuk',
    country: 'Saudi Arabia',
  },
  TMG: {
    label: 'Tomanggong Airport (TMG)',
    city: 'Tomanggong',
    country: 'Malaysia',
  },
  CHU: {
    label: 'Chuathbaluk Airport (CHU)',
    city: 'Chuathbaluk',
    country: 'United States',
  },
  PMZ: {
    label: 'Palmar Sur (PMZ)',
    city: 'Palmar',
    country: 'Costa Rica',
  },
  YME: {
    label: 'Matane Mncpl (YME)',
    city: 'Matane',
    country: 'Canada',
  },
  LEN: {
    label: 'Leon (LEN)',
    city: 'Leon',
    country: 'Spain',
  },
  MYT: {
    label: 'Myitkyina Airport (MYT)',
    city: 'Myitkyina',
    country: 'Myanmar (Burma)',
  },
  CEQ: {
    label: 'Mandelieu (CEQ)',
    city: 'Cannes',
    country: 'France',
  },
  DLD: {
    label: 'Dagali (DLD)',
    city: 'Geilo',
    country: 'Norway',
  },
  NYO: {
    label: 'Skavsta (NYO)',
    city: 'Stockholm',
    country: 'Sweden',
  },
  BBX: {
    label: 'Wings Field (BBX)',
    city: 'Blue Bell',
    country: 'United States',
  },
  SSG: {
    label: 'Santa Isabel Airport (SSG)',
    city: 'Malabo',
    country: 'Equatorial Guinea',
  },
  ZNA: {
    label: 'Harbor (ZNA)',
    city: 'Nanaimo',
    country: 'Canada',
  },
  TFF: {
    label: 'Tefe Airport (TFF)',
    city: 'Tefe',
    country: 'Brazil',
  },
  TBB: {
    label: 'Tuy Hoa Airport (TBB)',
    city: 'Tuy Hoa',
    country: 'Vietnam',
  },
  HSZ: {
    label: 'Hsinchu (HSZ)',
    city: 'Hsinchu',
    country: 'Taiwan',
  },
  LVK: {
    label: 'Livermore (LVK)',
    city: 'Livermore',
    country: 'United States',
  },
  FOB: {
    label: 'Little River-Mendoci (FOB)',
    city: 'Fort Bragg',
    country: 'United States',
  },
  PEC: {
    label: 'Pelican Sea Plane Base (PEC)',
    city: 'Pelican',
    country: 'United States',
  },
  BIL: {
    label: 'Billings Airport (BIL)',
    city: 'Billings',
    country: 'United States',
  },
  CZX: {
    label: 'Changzhou (CZX)',
    city: 'Changzhou',
    country: 'China',
  },
  TVU: {
    label: 'Matei (TVU)',
    city: 'Taveuni Island',
    country: 'Fiji',
  },
  TOY: {
    label: 'Toyama (TOY)',
    city: 'Toyama',
    country: 'Japan',
  },
  TRD: {
    label: 'Vaernes (TRD)',
    city: 'Trondheim',
    country: 'Norway',
  },
  SWA: {
    label: 'Shantou (SWA)',
    city: 'Shantou',
    country: 'China',
  },
  EDI: {
    label: 'Edinburgh (EDI)',
    city: 'Edinburgh',
    country: 'United Kingdom',
  },
  GAI: {
    label: 'Montgomery Cty (GAI)',
    city: 'Gaithersburg',
    country: 'United States',
  },
  EBL: {
    label: 'Erbil International Airport (EBL)',
    city: 'Erbil',
    country: 'Iraq',
  },
  YGL: {
    label: 'La Grande Airport (YGL)',
    city: 'La Grande',
    country: 'Canada',
  },
  GUP: {
    label: 'Senator Clark (GUP)',
    city: 'Gallup',
    country: 'United States',
  },
  TTA: {
    label: 'Tan Tan Airport (TTA)',
    city: 'Tan Tan',
    country: 'Morocco',
  },
  QRW: {
    label: 'Warri Airport (QRW)',
    city: 'Warri',
    country: 'Nigeria',
  },
  KRO: {
    label: 'Kurgan Airport (KRO)',
    city: 'Kurgan',
    country: 'Russia',
  },
  BAX: {
    label: 'Barnaul Airport (BAX)',
    city: 'Barnaul',
    country: 'Russia',
  },
  YCN: {
    label: 'Cochrane Mncpl (YCN)',
    city: 'Cochrane',
    country: 'Canada',
  },
  BMO: {
    label: 'Bhamo Airport (BMO)',
    city: 'Bhamo',
    country: 'Myanmar (Burma)',
  },
  MMH: {
    label: 'Mammoth Lakes (MMH)',
    city: 'Mammoth Lakes',
    country: 'United States',
  },
  OAM: {
    label: 'Oamaru Airport (OAM)',
    city: 'Oamaru',
    country: 'New Zealand',
  },
  TBR: {
    label: 'Statesboro Regl (TBR)',
    city: 'Statesboro',
    country: 'United States',
  },
  INC: {
    label: 'Yinchuan (INC)',
    city: 'Yinchuan',
    country: 'China',
  },
  PBC: {
    label: 'Hermanos Serdan (PBC)',
    city: 'Puebla',
    country: 'Mexico',
  },
  EBB: {
    label: 'Entebbe (EBB)',
    city: 'Entebbe',
    country: 'Uganda',
  },
  DGE: {
    label: 'Mudgee (DGE)',
    city: 'Mudgee',
    country: 'Australia',
  },
  KGF: {
    label: 'Karaganda Airport (KGF)',
    city: 'Karaganda',
    country: 'Kazakhstan',
  },
  PQI: {
    label: 'Presque Isle (PQI)',
    city: 'Presque Isle',
    country: 'United States',
  },
  HER: {
    label: 'N Kazantzakis (HER)',
    city: 'Heraklion',
    country: 'Greece',
  },
  QMK: {
    label: 'Heliport (QMK)',
    city: 'Niaqornaarsuk',
    country: 'Greenland',
  },
  YQH: {
    label: 'Watson Lake (YQH)',
    city: 'Watson Lake',
    country: 'Canada',
  },
  SMV: {
    label: 'St Moritz/Samedan (SMV)',
    city: 'St Moritz',
    country: 'Switzerland',
  },
  EZS: {
    label: 'Elazig Airport (EZS)',
    city: 'Elazig',
    country: 'Turkey',
  },
  IKB: {
    label: 'Wilkes County (IKB)',
    city: 'Wilkesboro',
    country: 'United States',
  },
  AHB: {
    label: 'Abha (AHB)',
    city: 'Abha',
    country: 'Saudi Arabia',
  },
  CDH: {
    label: 'Harrell Fld (CDH)',
    city: 'Camden',
    country: 'United States',
  },
  APV: {
    label: 'Apple Valley (APV)',
    city: 'Apple Valley',
    country: 'United States',
  },
  MUN: {
    label: 'Quiriquire (MUN)',
    city: 'Maturin',
    country: 'Venezuela',
  },
  OST: {
    label: 'Ostend (OST)',
    city: 'Ostend',
    country: 'Belgium',
  },
  ZSJ: {
    label: 'Sandy Lake Airport (ZSJ)',
    city: 'Sandy Lake',
    country: 'Canada',
  },
  ALE: {
    label: 'Alpine Mncpl (ALE)',
    city: 'Alpine',
    country: 'United States',
  },
  BSR: {
    label: 'Basra International Airport (BSR)',
    city: 'Basra',
    country: 'Iraq',
  },
  YCC: {
    label: 'Cornwall Mncpl (YCC)',
    city: 'Cornwall',
    country: 'Canada',
  },
  VMU: {
    label: 'Baimuru Airport (VMU)',
    city: 'Baimuru',
    country: 'Papua New Guinea',
  },
  OYE: {
    label: 'Oyem Airport (OYE)',
    city: 'Oyem',
    country: 'Gabon',
  },
  KKA: {
    label: 'Koyuk Airport (KKA)',
    city: 'Koyuk',
    country: 'United States',
  },
  YHO: {
    label: 'Hopedale Airport (YHO)',
    city: 'Hopedale',
    country: 'Canada',
  },
  DAN: {
    label: 'Danville Mncpl (DAN)',
    city: 'Danville',
    country: 'United States',
  },
  VDI: {
    label: 'Vidalia Mncpl (VDI)',
    city: 'Vidalia',
    country: 'United States',
  },
  AOT: {
    label: 'Aosta (AOT)',
    city: 'Aosta',
    country: 'Italy',
  },
  KBV: {
    label: 'Krabi (KBV)',
    city: 'Krabi',
    country: 'Thailand',
  },
  BUN: {
    label: 'Buenaventura Airport (BUN)',
    city: 'Buenaventura',
    country: 'Colombia',
  },
  IPT: {
    label: 'Williamsport (IPT)',
    city: 'Williamsport',
    country: 'United States',
  },
  YOJ: {
    label: 'Footner Lake (YOJ)',
    city: 'High Level',
    country: 'Canada',
  },
  TNG: {
    label: 'Boukhalef (TNG)',
    city: 'Tangier',
    country: 'Morocco',
  },
  RBW: {
    label: 'Waterboro Mncpl (RBW)',
    city: 'Walterboro',
    country: 'United States',
  },
  WMO: {
    label: 'White Mountain Airport (WMO)',
    city: 'White Mountain',
    country: 'United States',
  },
  BCE: {
    label: 'Bryce Canyon Mncpl (BCE)',
    city: 'Bryce Canyon',
    country: 'United States',
  },
  GAJ: {
    label: 'Junmachi (GAJ)',
    city: 'Yamagata',
    country: 'Japan',
  },
  SAV: {
    label: 'Savannah International Airport (SAV)',
    city: 'Savannah',
    country: 'United States',
  },
  WRO: {
    label: 'Strachowice (WRO)',
    city: 'Wroclaw',
    country: 'Poland',
  },
  TNL: {
    label: 'Ternopol (TNL)',
    city: 'Ternopol',
    country: 'Ukraine',
  },
  FCA: {
    label: 'Glacier National Park Airport (FCA)',
    city: 'Kalispell',
    country: 'United States',
  },
  HIO: {
    label: 'Hillsboro (HIO)',
    city: 'Hillsboro',
    country: 'United States',
  },
  SRI: {
    label: 'Samarinda Airport (SRI)',
    city: 'Samarinda',
    country: 'Indonesia',
  },
  HLA: {
    label: 'Lanseria Airport (HLA)',
    city: 'Lanseria',
    country: 'South Africa',
  },
  PBG: {
    label: 'Plattsburgh International Airport (PBG)',
    city: 'Plattsburgh',
    country: 'United States',
  },
  TOU: {
    label: 'Touho Airport (TOU)',
    city: 'Touho',
    country: 'New Caledonia',
  },
  MDU: {
    label: 'Mendi Airport (MDU)',
    city: 'Mendi',
    country: 'Papua New Guinea',
  },
  NTT: {
    label: 'Kuini Lavenia Airport (NTT)',
    city: 'Niuatoputapu',
    country: 'Tonga',
  },
  BUO: {
    label: 'Burao Airport (BUO)',
    city: 'Burao',
    country: 'Somalia',
  },
  BAV: {
    label: 'Baotou (BAV)',
    city: 'Baotou',
    country: 'China',
  },
  PTJ: {
    label: 'Portland (PTJ)',
    city: 'Portland',
    country: 'Australia',
  },
  ZFM: {
    label: 'Fort Mcpherson Airport (ZFM)',
    city: 'Fort McPherson',
    country: 'Canada',
  },
  ARW: {
    label: 'Arad (ARW)',
    city: 'Arad',
    country: 'Romania',
  },
  DSI: {
    label: 'Destin-Ft Walton Bch (DSI)',
    city: 'Destin',
    country: 'United States',
  },
  DAU: {
    label: 'Daru Airport (DAU)',
    city: 'Daru',
    country: 'Papua New Guinea',
  },
  AUS: {
    label: 'Austin (AUS)',
    city: 'Austin',
    country: 'United States',
  },
  BLZ: {
    label: 'Chileka (BLZ)',
    city: 'Blantyre',
    country: 'Malawi',
  },
  BCO: {
    label: 'Jinka Airport (BCO)',
    city: 'Jinka',
    country: 'Ethiopia',
  },
  EMD: {
    label: 'Emerald Airport (EMD)',
    city: 'Emerald',
    country: 'Australia',
  },
  VDA: {
    label: 'Ovda Airport (VDA)',
    city: 'Ovda',
    country: 'Israel',
  },
  YHZ: {
    label: 'Halifax (YHZ)',
    city: 'Halifax',
    country: 'Canada',
  },
  CFK: {
    label: 'Abou Bakr Belkaid Airport (CFK)',
    city: 'Chlef',
    country: 'Algeria',
  },
  ITO: {
    label: 'Hilo International Airport (ITO)',
    city: 'Hilo',
    country: 'United States',
  },
  YRT: {
    label: 'Rankin Inlet (YRT)',
    city: 'Rankin Inlet',
    country: 'Canada',
  },
  AGX: {
    label: 'Agatti Island Airport (AGX)',
    city: 'Agatti Island',
    country: 'India',
  },
  VLI: {
    label: 'Bauerfield (VLI)',
    city: 'Port Vila',
    country: 'Vanuatu',
  },
  DUT: {
    label: 'Emergency Field (DUT)',
    city: 'Dutch Harbor',
    country: 'United States',
  },
  JDA: {
    label: 'John Day (JDA)',
    city: 'John Day',
    country: 'United States',
  },
  MNF: {
    label: 'Mana Island Airstrip (MNF)',
    city: 'Mana Island',
    country: 'Fiji',
  },
  DXB: {
    label: 'Dubai (DXB)',
    city: 'Dubai',
    country: 'United Arab Emirates',
  },
  VAK: {
    label: 'Chevak Airport (VAK)',
    city: 'Chevak',
    country: 'United States',
  },
  AXP: {
    label: 'Springpoint Airport (AXP)',
    city: 'Spring Point',
    country: 'Bahamas',
  },
  SLN: {
    label: 'Salina Municipal Airport (SLN)',
    city: 'Salina',
    country: 'United States',
  },
  CRL: {
    label: 'Brussels South (CRL)',
    city: 'Brussels',
    country: 'Belgium',
  },
  TLL: {
    label: 'Ulemiste Airport (TLL)',
    city: 'Tallinn',
    country: 'Estonia',
  },
  OTH: {
    label: 'North Bend Airport (OTH)',
    city: 'North Bend',
    country: 'United States',
  },
  SOJ: {
    label: 'Sorkjosen (SOJ)',
    city: 'Sorkjosen',
    country: 'Norway',
  },
  SZF: {
    label: 'Carsamba Airport (SZF)',
    city: 'Samsun',
    country: 'Turkey',
  },
  IBZ: {
    label: 'Ibiza (IBZ)',
    city: 'Ibiza',
    country: 'Spain',
  },
  MBH: {
    label: 'Maryborough (MBH)',
    city: 'Maryborough',
    country: 'Australia',
  },
  XAP: {
    label: 'Chapeco Airport (XAP)',
    city: 'Chapeco',
    country: 'Brazil',
  },
  WBB: {
    label: 'Stebbins Airport (WBB)',
    city: 'Stebbins',
    country: 'United States',
  },
  UYL: {
    label: 'Nyala Airport (UYL)',
    city: 'Nyala',
    country: 'Sudan',
  },
  VIC: {
    label: 'Vicenza (VIC)',
    city: 'Vicenza',
    country: 'Italy',
  },
  JAL: {
    label: 'Jalapa Mncpl (JAL)',
    city: 'Jalapa',
    country: 'Mexico',
  },
  RUN: {
    label: 'Gillot (RUN)',
    city: 'St Denis',
    country: 'Reunion',
  },
  MJL: {
    label: 'Mouila Airport (MJL)',
    city: 'Mouila',
    country: 'Gabon',
  },
  MCD: {
    label: 'Mackinac Island (MCD)',
    city: 'Mackinac Island',
    country: 'United States',
  },
  BUQ: {
    label: 'Bulawayo (BUQ)',
    city: 'Bulawayo',
    country: 'Zimbabwe',
  },
  RBR: {
    label: 'Pres Medici Airport (RBR)',
    city: 'Rio Branco',
    country: 'Brazil',
  },
  RBE: {
    label: 'Ratanakiri Airport (RBE)',
    city: 'Ratanakiri',
    country: 'Cambodia',
  },
  ENE: {
    label: 'Ende Airport (ENE)',
    city: 'Ende',
    country: 'Indonesia',
  },
  OCJ: {
    label: 'Boscobel (OCJ)',
    city: 'Ocho Rios',
    country: 'Jamaica',
  },
  LEQ: {
    label: 'Lands End (LEQ)',
    city: 'Lands End',
    country: 'United Kingdom',
  },
  PLO: {
    label: 'Port Lincoln Airport (PLO)',
    city: 'Port Lincoln',
    country: 'Australia',
  },
  FAN: {
    label: 'Lista (FAN)',
    city: 'Farsund',
    country: 'Norway',
  },
  ARK: {
    label: 'Arusha (ARK)',
    city: 'Arusha',
    country: 'Tanzania',
  },
  YCB: {
    label: 'Cambridge Bay (YCB)',
    city: 'Cambridge Bay',
    country: 'Canada',
  },
  XQU: {
    label: 'Qualicum Airport (XQU)',
    city: 'Qualicum',
    country: 'Canada',
  },
  TKS: {
    label: 'Tokushima (TKS)',
    city: 'Tokushima',
    country: 'Japan',
  },
  HOG: {
    label: 'Frank Pais (HOG)',
    city: 'Holguin',
    country: 'Cuba',
  },
  CUF: {
    label: 'Levaldigi (CUF)',
    city: 'Cuneo',
    country: 'Italy',
  },
  YAZ: {
    label: 'Tofino Mncpl (YAZ)',
    city: 'Tofino',
    country: 'Canada',
  },
  BWK: {
    label: 'Bol (BWK)',
    city: 'Bol',
    country: 'Croatia',
  },
  GMP: {
    label: 'Gimpo (GMP)',
    city: 'Seoul',
    country: 'South Korea',
  },
  IRG: {
    label: 'Lockhart River Airport (IRG)',
    city: 'Lockhart River',
    country: 'Australia',
  },
  ENH: {
    label: 'Enshi (ENH)',
    city: 'Enshi',
    country: 'China',
  },
  VLG: {
    label: 'Villa Gesell Airport (VLG)',
    city: 'Villa Gesell',
    country: 'Argentina',
  },
  UIH: {
    label: 'Qui Nhon Airport (UIH)',
    city: 'Qui Nhon',
    country: 'Vietnam',
  },
  WLG: {
    label: 'Wellington Intl (WLG)',
    city: 'Wellington',
    country: 'New Zealand',
  },
  GGT: {
    label: 'Exuma International Airport (GGT)',
    city: 'George Town',
    country: 'Bahamas',
  },
  NNM: {
    label: 'Naryan-Mar Airport (NNM)',
    city: 'Naryan-Mar',
    country: 'Russia',
  },
  LBE: {
    label: 'Westmoreland Cty (LBE)',
    city: 'Latrobe',
    country: 'United States',
  },
  KTP: {
    label: 'Tinson (KTP)',
    city: 'Kingston',
    country: 'United States',
  },
  RUK: {
    label: 'Rukumkot Airport (RUK)',
    city: 'Rukumkot',
    country: 'Nepal',
  },
  LPP: {
    label: 'Lappeenranta (LPP)',
    city: 'Lappeenranta',
    country: 'Finland',
  },
  MTR: {
    label: 'S Jeronimo Airport (MTR)',
    city: 'Monteria',
    country: 'Colombia',
  },
  KSD: {
    label: 'Karlstad (KSD)',
    city: 'Karlstad',
    country: 'Sweden',
  },
  GDL: {
    label: 'Miguel Hidal Airport (GDL)',
    city: 'Guadalajara',
    country: 'Mexico',
  },
  GIS: {
    label: 'Gisborne (GIS)',
    city: 'Gisborne',
    country: 'New Zealand',
  },
  PPN: {
    label: 'Machangara Airport (PPN)',
    city: 'Popayan',
    country: 'Colombia',
  },
  SWD: {
    label: 'Seward (SWD)',
    city: 'Seward',
    country: 'United States',
  },
  EIS: {
    label: 'Beef Island Airport (EIS)',
    city: 'Tortola',
    country: 'British Virgin Islands',
  },
  RTW: {
    label: 'Saratov (RTW)',
    city: 'Saratov',
    country: 'Russia',
  },
  SVG: {
    label: 'Sola (SVG)',
    city: 'Stavanger',
    country: 'Norway',
  },
  HGU: {
    label: 'Kagamuga (HGU)',
    city: 'Mount Hagen',
    country: 'Papua New Guinea',
  },
  NBO: {
    label: 'Jomo Kenyatta Intl (NBO)',
    city: 'Nairobi',
    country: 'Kenya',
  },
  PPK: {
    label: 'Petropavlovsk Airport (PPK)',
    city: 'Petropavlovsk',
    country: 'Kazakhstan',
  },
  ORH: {
    label: 'Worcester Airport (ORH)',
    city: 'Worcester',
    country: 'United States',
  },
  XKH: {
    label: 'Xieng Khouang Airport (XKH)',
    city: 'Xieng Khouang',
    country: 'Laos',
  },
  CNF: {
    label: 'Tancredo Neves (CNF)',
    city: 'Belo Horizonte',
    country: 'Brazil',
  },
  MAQ: {
    label: 'Mae Sot (MAQ)',
    city: 'Mae Sot',
    country: 'Thailand',
  },
  TYO: {
    label: 'All Airports (TYO)',
    city: 'Tokyo',
    country: 'Japan',
  },
  VRY: {
    label: 'Stolport Airport (VRY)',
    city: 'Vaeroy',
    country: 'Norway',
  },
  RIO: {
    label: 'All Airports (RIO)',
    city: 'Rio de Janeiro',
    country: 'Brazil',
  },
  RJH: {
    label: 'Rajshahi Airport (RJH)',
    city: 'Rajshahi',
    country: 'Bangladesh',
  },
  UTH: {
    label: 'Udon Thani (UTH)',
    city: 'Udon Thani',
    country: 'Thailand',
  },
  STD: {
    label: 'Mayo Guerrero Airport (STD)',
    city: 'Santo Domingo',
    country: 'Venezuela',
  },
  CCS: {
    label: 'Simon Bolivar Airport (CCS)',
    city: 'Caracas',
    country: 'Venezuela',
  },
  MFE: {
    label: 'Miller International Airport (MFE)',
    city: 'McAllen',
    country: 'United States',
  },
  GET: {
    label: 'Geraldton (GET)',
    city: 'Geraldton',
    country: 'Australia',
  },
  PPB: {
    label: 'A De Barros Airport (PPB)',
    city: 'Presidente Prudente',
    country: 'Brazil',
  },
  AUQ: {
    label: 'Atuona (AUQ)',
    city: 'Atuona',
    country: 'French Polynesia and Tahiti',
  },
  PLJ: {
    label: 'Placencia (PLJ)',
    city: 'Placencia',
    country: 'Belize',
  },
  GSM: {
    label: 'Gheshm Airport (GSM)',
    city: 'Gheshm',
    country: 'Iran',
  },
  MES: {
    label: 'Polonia (MES)',
    city: 'Medan',
    country: 'Indonesia',
  },
  PKE: {
    label: 'Parkes (PKE)',
    city: 'Parkes',
    country: 'Australia',
  },
  MTW: {
    label: 'Manitowoc Mncpl (MTW)',
    city: 'Manitowoc',
    country: 'United States',
  },
  KZO: {
    label: 'Kzyl-Orda Airport (KZO)',
    city: 'Kzyl-Orda',
    country: 'Kazakhstan',
  },
  BIA: {
    label: 'Poretta (BIA)',
    city: 'Bastia',
    country: 'France',
  },
  PAC: {
    label: 'Paitilla (PAC)',
    city: 'Panama City',
    country: 'Panama',
  },
  CIX: {
    label: 'Coronel Ruiz (CIX)',
    city: 'Chiclayo',
    country: 'Peru',
  },
  BOY: {
    label: 'Borgo (BOY)',
    city: 'Bobo Dioulasso',
    country: 'Burkina Faso',
  },
  IVC: {
    label: 'Invercargill (IVC)',
    city: 'Invercargill',
    country: 'New Zealand',
  },
  TGM: {
    label: 'Tirgu Mures (TGM)',
    city: 'Tirgu Mures',
    country: 'Romania',
  },
  PKG: {
    label: 'Pangkor Airport (PKG)',
    city: 'Pangkor',
    country: 'Malaysia',
  },
  DBQ: {
    label: 'Dubuque Municipal Airport (DBQ)',
    city: 'Dubuque',
    country: 'United States',
  },
  QII: {
    label: 'Off-Line Point (QII)',
    city: 'Lindau',
    country: 'Germany',
  },
  BHH: {
    label: 'Bisha Airport (BHH)',
    city: 'Bisha',
    country: 'Saudi Arabia',
  },
  OAK: {
    label: 'Oakland (OAK)',
    city: 'Oakland',
    country: 'United States',
  },
  CEM: {
    label: 'Central (CEM)',
    city: 'Central',
    country: 'United States',
  },
  SRJ: {
    label: 'Capitan G Q Guardia Airport (SRJ)',
    city: 'San Borja',
    country: 'Bolivia',
  },
  JSI: {
    label: 'Skiathos (JSI)',
    city: 'Skiathos',
    country: 'Greece',
  },
  FAR: {
    label: 'Hector Field (FAR)',
    city: 'Fargo',
    country: 'United States',
  },
  SXF: {
    label: 'Schoenefeld Airport (SXF)',
    city: 'Berlin',
    country: 'Germany',
  },
  PRC: {
    label: 'Prescott Airport (PRC)',
    city: 'Prescott',
    country: 'United States',
  },
  TYS: {
    label: 'Knoxville (TYS)',
    city: 'Knoxville',
    country: 'United States',
  },
  YTA: {
    label: 'Pembroke (YTA)',
    city: 'Pembroke',
    country: 'Canada',
  },
  VER: {
    label: 'Las Bajadas Airport (VER)',
    city: 'Veracruz',
    country: 'Mexico',
  },
  URE: {
    label: 'Kuressaare Airport (URE)',
    city: 'Kuressaare',
    country: 'Estonia',
  },
  LAM: {
    label: 'Los Alamos (LAM)',
    city: 'Los Alamos',
    country: 'United States',
  },
  ORT: {
    label: 'Northway Airport (ORT)',
    city: 'Northway',
    country: 'United States',
  },
  BFR: {
    label: 'Virgil Grissom Mncpl (BFR)',
    city: 'Bedford',
    country: 'United States',
  },
  ELU: {
    label: 'Guemar Airport (ELU)',
    city: 'El Oued',
    country: 'Algeria',
  },
  WTS: {
    label: 'Tsiroanomandidy Airport (WTS)',
    city: 'Tsiroanomandidy',
    country: 'Madagascar',
  },
  SCQ: {
    label: 'Labacolla (SCQ)',
    city: 'Santiago de Compostela',
    country: 'Spain',
  },
  NAJ: {
    label: 'Nakhichevan Airport (NAJ)',
    city: 'Nakhichevan',
    country: 'Azerbaijan',
  },
  AJN: {
    label: 'Ouani Airport (AJN)',
    city: 'Anjouan',
    country: 'Comoros',
  },
  SUX: {
    label: 'Sioux Gateway Airport (SUX)',
    city: 'Sioux City',
    country: 'United States',
  },
  BRD: {
    label: 'Crow Wing County Airport (BRD)',
    city: 'Brainerd',
    country: 'United States',
  },
  YXY: {
    label: 'Whitehorse Airport (YXY)',
    city: 'Whitehorse',
    country: 'Canada',
  },
  SZD: {
    label: 'Sheffield (SZD)',
    city: 'Sheffield',
    country: 'United Kingdom',
  },
  MXL: {
    label: 'Mexicali Intl (MXL)',
    city: 'Mexicali',
    country: 'Mexico',
  },
  CAL: {
    label: 'Machrihanish Airport (CAL)',
    city: 'Campbeltown',
    country: 'United Kingdom',
  },
  SHA: {
    label: 'Hongqiao (SHA)',
    city: 'Shanghai',
    country: 'China',
  },
  DZN: {
    label: 'Zhezhazgan Airport (DZN)',
    city: 'Zhezkazgan',
    country: 'Kazakhstan',
  },
  WGP: {
    label: 'Waingapu Airport (WGP)',
    city: 'Waingapu',
    country: 'Indonesia',
  },
  MNY: {
    label: 'Mono Airport (MNY)',
    city: 'Mono',
    country: 'Solomon Islands',
  },
  KCL: {
    label: 'Lagoon Airport (KCL)',
    city: 'Chignik',
    country: 'United States',
  },
  IZO: {
    label: 'Izumo Airport (IZO)',
    city: 'Izumo',
    country: 'Japan',
  },
  RNB: {
    label: 'Kallinge (RNB)',
    city: 'Ronneby',
    country: 'Sweden',
  },
  SAO: {
    label: 'All Airports (SAO)',
    city: 'Sao Paulo',
    country: 'Brazil',
  },
  GVR: {
    label: 'Governador Valadares Airport (GVR)',
    city: 'Governador Valadares',
    country: 'Brazil',
  },
  RKT: {
    label: 'Ras Al Khaimah (RKT)',
    city: 'Ras Al Khaimah',
    country: 'United Arab Emirates',
  },
  JQE: {
    label: 'Jaque Airport (JQE)',
    city: 'Jaque',
    country: 'Panama',
  },
  GIF: {
    label: 'Gilbert Field (GIF)',
    city: 'Winter Haven',
    country: 'United States',
  },
  AQP: {
    label: 'Rodriguez Ballon (AQP)',
    city: 'Arequipa',
    country: 'Peru',
  },
  OMD: {
    label: 'Oranjemund Airport (OMD)',
    city: 'Oranjemund',
    country: 'Namibia',
  },
  OIT: {
    label: 'Oita (OIT)',
    city: 'Oita',
    country: 'Japan',
  },
  HAR: {
    label: 'Skyport (HAR)',
    city: 'Harrisburg',
    country: 'United States',
  },
  VAN: {
    label: 'Van (VAN)',
    city: 'Van',
    country: 'Turkey',
  },
  KIM: {
    label: 'Kimberley (KIM)',
    city: 'Kimberley',
    country: 'South Africa',
  },
  ZHA: {
    label: 'Zhangjiang (ZHA)',
    city: 'Zhanjiang',
    country: 'China',
  },
  NGA: {
    label: 'Young (NGA)',
    city: 'Young',
    country: 'Australia',
  },
  SFT: {
    label: 'Skelleftea (SFT)',
    city: 'Skelleftea',
    country: 'Sweden',
  },
  ACY: {
    label: 'Atlantic City (ACY)',
    city: 'Atlantic City',
    country: 'United States',
  },
  YHM: {
    label: 'Hamilton Airport (YHM)',
    city: 'Hamilton',
    country: 'Canada',
  },
  TAP: {
    label: 'Tapachula (TAP)',
    city: 'Tapachula',
    country: 'Mexico',
  },
  PRY: {
    label: 'Pretoria (PRY)',
    city: 'Pretoria',
    country: 'South Africa',
  },
  ATT: {
    label: 'Atmautluak Airport (ATT)',
    city: 'Atmautluak',
    country: 'United States',
  },
  EGP: {
    label: 'Maverick County (EGP)',
    city: 'Eagle Pass',
    country: 'United States',
  },
  DMO: {
    label: 'Sedalia (DMO)',
    city: 'Sedalia',
    country: 'United States',
  },
  LYB: {
    label: 'Little Cayman Mncpl (LYB)',
    city: 'Little Cayman',
    country: 'Cayman Islands',
  },
  ESS: {
    label: 'Essen Airport (ESS)',
    city: 'Essen',
    country: 'Germany',
  },
  IVR: {
    label: 'Inverell (IVR)',
    city: 'Inverell',
    country: 'Australia',
  },
  AKF: {
    label: 'Kufrah Airport (AKF)',
    city: 'Kufrah',
    country: 'Libya',
  },
  YYN: {
    label: 'Swift Current Mncpl (YYN)',
    city: 'Swift Current',
    country: 'Canada',
  },
  CVO: {
    label: 'Corvallis Mncpl (CVO)',
    city: 'Corvallis',
    country: 'United States',
  },
  MSW: {
    label: 'Massawa Airport (MSW)',
    city: 'Massawa',
    country: 'Eritrea',
  },
  XSI: {
    label: 'South Indian Lake Airport (XSI)',
    city: 'South Indian Lake',
    country: 'Canada',
  },
  SFC: {
    label: 'St Francois (SFC)',
    city: 'St Francois',
    country: 'St Barthelemy',
  },
  POA: {
    label: 'Salgado Filho (POA)',
    city: 'Porto Alegre',
    country: 'Brazil',
  },
  SBA: {
    label: 'Santa Barbara (SBA)',
    city: 'Santa Barbara',
    country: 'United States',
  },
  PTP: {
    label: 'Le Raizet Airport (PTP)',
    city: 'Pointe a Pitre',
    country: 'Guadeloupe',
  },
  LBJ: {
    label: 'Mutiara Airport (LBJ)',
    city: 'Labuan Bajo',
    country: 'Indonesia',
  },
  BON: {
    label: 'Flamingo International Airport (BON)',
    city: 'Bonaire',
    country: 'Bonaire',
  },
  YGB: {
    label: 'Gillies Bay Airport (YGB)',
    city: 'Gillies Bay',
    country: 'Canada',
  },
  YYH: {
    label: 'Taloyoak Airport (YYH)',
    city: 'Taloyoak',
    country: 'Canada',
  },
  EJT: {
    label: 'Enijet Airport (EJT)',
    city: 'Mili Atoll',
    country: 'Marshall Islands',
  },
  AUC: {
    label: 'Arauca Airport (AUC)',
    city: 'Arauca',
    country: 'Colombia',
  },
  AKJ: {
    label: 'Asahikawa (AKJ)',
    city: 'Asahikawa',
    country: 'Japan',
  },
  MNU: {
    label: 'Maulmyine Airport (MNU)',
    city: 'Maulmyine',
    country: 'Myanmar (Burma)',
  },
  BCD: {
    label: 'Bacolod Airport (BCD)',
    city: 'Bacolod',
    country: 'Philippines',
  },
  KWA: {
    label: 'Kwajalein Airport (KWA)',
    city: 'Kwajalein',
    country: 'Marshall Islands',
  },
  CIT: {
    label: 'Shimkent Airport (CIT)',
    city: 'Shimkent',
    country: 'Kazakhstan',
  },
  YGH: {
    label: 'Fort Good Hope Airport (YGH)',
    city: 'Fort Good Hope',
    country: 'Canada',
  },
  TOM: {
    label: 'Timbuktu (TOM)',
    city: 'Timbuktu',
    country: 'Mali',
  },
  VCL: {
    label: 'Chulai Airport (VCL)',
    city: 'Tamky-Chulai Airport',
    country: 'Vietnam',
  },
  GPA: {
    label: 'Patras-Araxos (GPA)',
    city: 'Patras',
    country: 'Greece',
  },
  PMI: {
    label: 'Palma Mallorca (PMI)',
    city: 'Palma',
    country: 'Spain',
  },
  GUM: {
    label: 'AB Won Pat Intl (GUM)',
    city: 'Agana',
    country: 'Guam',
  },
  SVH: {
    label: 'Statesville Mncpl (SVH)',
    city: 'Statesville',
    country: 'United States',
  },
  WTO: {
    label: 'Wotho Airport (WTO)',
    city: 'Wotho',
    country: 'Marshall Islands',
  },
  MTS: {
    label: 'Matsapha Intl (MTS)',
    city: 'Manzini',
    country: 'Swaziland',
  },
  STW: {
    label: 'Stavropol (STW)',
    city: 'Stavropol',
    country: 'Russia',
  },
  AUW: {
    label: 'Wausau (AUW)',
    city: 'Wausau',
    country: 'United States',
  },
  COU: {
    label: 'Columbia (COU)',
    city: 'Columbia',
    country: 'MO',
  },
  YTL: {
    label: 'Big Trout Airport (YTL)',
    city: 'Big Trout',
    country: 'Canada',
  },
  KCH: {
    label: 'Kuching (KCH)',
    city: 'Kuching',
    country: 'Malaysia',
  },
  BSJ: {
    label: 'Bairnsdale (BSJ)',
    city: 'Bairnsdale',
    country: 'Australia',
  },
  SSH: {
    label: 'Sharm el Sheikh Intl (SSH)',
    city: 'Sharm el Sheikh',
    country: 'Egypt',
  },
  MDL: {
    label: 'Annisaton (MDL)',
    city: 'Mandalay',
    country: 'Myanmar (Burma)',
  },
  HFS: {
    label: 'Hagfors Airport (HFS)',
    city: 'Hagfors',
    country: 'Sweden',
  },
  BUA: {
    label: 'Buka Airport (BUA)',
    city: 'Buka',
    country: 'Papua New Guinea',
  },
  YHG: {
    label: 'Charlottetown Mncpl (YHG)',
    city: 'Charlottetown',
    country: 'Canada',
  },
  CBE: {
    label: 'Wiley Ford Airport (CBE)',
    city: 'Cumberland',
    country: 'United States',
  },
  TCO: {
    label: 'La Florida Airport (TCO)',
    city: 'Tumaco',
    country: 'Colombia',
  },
  IKA: {
    label: 'Imam Khomeini International Airport (IKA)',
    city: 'Tehran',
    country: 'Iran',
  },
  PTG: {
    label: 'Polokwane (PTG)',
    city: 'Polokwane',
    country: 'South Africa',
  },
  SWC: {
    label: 'Stawell (SWC)',
    city: 'Stawell',
    country: 'Australia',
  },
  CGN: {
    label: 'Koln/Bonn (CGN)',
    city: 'Cologne',
    country: 'Germany',
  },
  JOL: {
    label: 'Jolo Airport (JOL)',
    city: 'Jolo',
    country: 'Philippines',
  },
  LKV: {
    label: 'Lakeview Mncpl (LKV)',
    city: 'Lakeview',
    country: 'United States',
  },
  ITB: {
    label: 'Itaituba Airport (ITB)',
    city: 'Itaituba',
    country: 'Brazil',
  },
  ZKG: {
    label: 'Kegaska Airport (ZKG)',
    city: 'Kegaska',
    country: 'Canada',
  },
  CVG: {
    label: 'Cincinnati (CVG)',
    city: 'Cincinnati',
    country: 'United States',
  },
  MCK: {
    label: 'Mc Cook Municipal Airport (MCK)',
    city: 'McCook',
    country: 'United States',
  },
  IRJ: {
    label: 'La Rioja Mncpl (IRJ)',
    city: 'La Rioja',
    country: 'Argentina',
  },
  BMY: {
    label: 'Belep Island Airport (BMY)',
    city: 'Belep Island',
    country: 'New Caledonia',
  },
  HRE: {
    label: 'Harare (HRE)',
    city: 'Harare',
    country: 'Zimbabwe',
  },
  UKB: {
    label: 'Kobe Airport (UKB)',
    city: 'Osaka',
    country: 'Japan',
  },
  TYN: {
    label: 'Taiyuan (TYN)',
    city: 'Taiyuan',
    country: 'China',
  },
  OYG: {
    label: 'Moyo Airport (OYG)',
    city: 'Moyo',
    country: 'Uganda',
  },
  AHE: {
    label: 'Ahe Airport (AHE)',
    city: 'Ahe',
    country: 'French Polynesia and Tahiti',
  },
  NKM: {
    label: 'Komaki (NKM)',
    city: 'Komaki',
    country: 'Japan',
  },
  URA: {
    label: 'Uralsk Airport (URA)',
    city: 'Uralsk',
    country: 'Kazakhstan',
  },
  MHT: {
    label: 'Manchester (MHT)',
    city: 'Manchester',
    country: 'United States',
  },
  HIB: {
    label: 'Hibbing-Chisholm (HIB)',
    city: 'Hibbing',
    country: 'United States',
  },
  SVB: {
    label: 'Sambava Airport (SVB)',
    city: 'Sambava',
    country: 'Madagascar',
  },
  VVI: {
    label: 'Viru Viru Intl (VVI)',
    city: 'Santa Cruz',
    country: 'Bolivia',
  },
  TEU: {
    label: 'Manapouri (TEU)',
    city: 'Te Anau',
    country: 'New Zealand',
  },
  TCR: {
    label: 'Tuticorin Airport (TCR)',
    city: 'Tuticorin',
    country: 'India',
  },
  HKG: {
    label: 'Hong Kong International Airport (HKG)',
    city: 'Hong Kong',
    country: 'China',
  },
  CLJ: {
    label: 'Cluj (CLJ)',
    city: 'Cluj-Napoca',
    country: 'Romania',
  },
  TAK: {
    label: 'Takamatsu (TAK)',
    city: 'Takamatsu',
    country: 'Japan',
  },
  HYN: {
    label: 'Huangyan Airport (HYN)',
    city: 'Huangyan',
    country: 'China',
  },
  GRZ: {
    label: 'Thalerhof (GRZ)',
    city: 'Graz',
    country: 'Austria',
  },
  CMI: {
    label: 'Willard University Airport (CMI)',
    city: 'Champaign',
    country: 'United States',
  },
  MCT: {
    label: 'Seeb (MCT)',
    city: 'Muscat',
    country: 'Oman',
  },
  BYM: {
    label: 'CM de Cespedes Airport (BYM)',
    city: 'Bayamo',
    country: 'Cuba',
  },
  ABS: {
    label: 'Abu Simbel Airport (ABS)',
    city: 'Abu Simbel',
    country: 'Egypt',
  },
  KEJ: {
    label: 'Kemerovo (KEJ)',
    city: 'Kemerovo',
    country: 'Russia',
  },
  TDX: {
    label: 'Trat (TDX)',
    city: 'Trat',
    country: 'Thailand',
  },
  ESR: {
    label: 'El Salvador Airport (ESR)',
    city: 'El Salvador',
    country: 'Chile and Easter Island',
  },
  TKX: {
    label: 'Takaroa Airport (TKX)',
    city: 'Takaroa',
    country: 'French Polynesia and Tahiti',
  },
  WNA: {
    label: 'Napakiak Sea Plane Base (WNA)',
    city: 'Napakiak',
    country: 'United States',
  },
  IST: {
    label: 'Ataturk Airport (IST)',
    city: 'Istanbul',
    country: 'Turkey',
  },
  ERZ: {
    label: 'Erzurum (ERZ)',
    city: 'Erzurum',
    country: 'Turkey',
  },
  HRL: {
    label: 'Harlingen (HRL)',
    city: 'Harlingen',
    country: 'United States',
  },
  YMQ: {
    label: 'All Airports (YMQ)',
    city: 'Montreal',
    country: 'Canada',
  },
  VXO: {
    label: 'Vaxjo (VXO)',
    city: 'Vaxjo',
    country: 'Sweden',
  },
  ELY: {
    label: 'Yelland Field (ELY)',
    city: 'Ely',
    country: 'United States',
  },
  CMA: {
    label: 'Cunnamulla Airport (CMA)',
    city: 'Cunnamulla',
    country: 'Australia',
  },
  DVL: {
    label: 'Devils Lake Mncpl (DVL)',
    city: 'Devils Lake',
    country: 'United States',
  },
  EZE: {
    label: 'Ministro Pistarini Airport (EZE)',
    city: 'Buenos Aires',
    country: 'Argentina',
  },
  CHC: {
    label: 'Christchurch Intl (CHC)',
    city: 'Christchurch',
    country: 'New Zealand',
  },
  KRF: {
    label: 'Kramfors (KRF)',
    city: 'Kramfors',
    country: 'Sweden',
  },
  VSA: {
    label: 'Villahermosa (VSA)',
    city: 'Villahermosa',
    country: 'Mexico',
  },
  NAO: {
    label: 'Nanchong (NAO)',
    city: 'Nanchong',
    country: 'China',
  },
  LYP: {
    label: 'Faisalabad (LYP)',
    city: 'Faisalabad',
    country: 'Pakistan',
  },
  ORL: {
    label: 'All Airports (ORL)',
    city: 'Orlando',
    country: 'United States',
  },
  MSU: {
    label: 'Moshoeshoe Intl (MSU)',
    city: 'Maseru',
    country: 'Lesotho',
  },
  SAK: {
    label: 'Saudarkrokur (SAK)',
    city: 'Saudarkrokur',
    country: 'Iceland',
  },
  PHS: {
    label: 'Phitsanulok Airport (PHS)',
    city: 'Phitsanulok',
    country: 'Thailand',
  },
  UIT: {
    label: 'Jaluit Airport (UIT)',
    city: 'Jaluit',
    country: 'Marshall Islands',
  },
  VEE: {
    label: 'Venetie Airport (VEE)',
    city: 'Venetie',
    country: 'United States',
  },
  GLI: {
    label: 'Glen Innes (GLI)',
    city: 'Glen Innes',
    country: 'Australia',
  },
  SBZ: {
    label: 'Sibiu (SBZ)',
    city: 'Sibiu',
    country: 'Romania',
  },
  LEI: {
    label: 'Almeria (LEI)',
    city: 'Almeria',
    country: 'Spain',
  },
  LEH: {
    label: 'Octeville (LEH)',
    city: 'Le Havre',
    country: 'France',
  },
  SRE: {
    label: 'Sucre/Padilla (SRE)',
    city: 'Sucre',
    country: 'Bolivia',
  },
  CMJ: {
    label: 'Chi Mei Airport (CMJ)',
    city: 'Chi Mei',
    country: 'Taiwan',
  },
  FRB: {
    label: 'Forbes (FRB)',
    city: 'Forbes',
    country: 'Australia',
  },
  OMA: {
    label: 'Omaha (OMA)',
    city: 'Omaha',
    country: 'United States',
  },
  IAD: {
    label: 'Dulles (IAD)',
    city: 'Washington',
    country: 'DC',
  },
  NMS: {
    label: 'Namsang Airport (NMS)',
    city: 'Namsang',
    country: 'Myanmar (Burma)',
  },
  YYB: {
    label: 'North Bay Airport (YYB)',
    city: 'North Bay',
    country: 'Canada',
  },
  MEC: {
    label: 'Manta Airport (MEC)',
    city: 'Manta',
    country: 'Ecuador',
  },
  IPC: {
    label: 'Mataveri International Airport (IPC)',
    city: 'Easter Island',
    country: 'Chile and Easter Island',
  },
  MSZ: {
    label: 'Namibe Airport (MSZ)',
    city: 'Namibe',
    country: 'Angola',
  },
  ZFN: {
    label: 'Tulita Airport (ZFN)',
    city: 'Tulita/Fort Norman',
    country: 'Canada',
  },
  LNB: {
    label: 'Lamen Bay Airport (LNB)',
    city: 'Lamen Bay',
    country: 'Vanuatu',
  },
  AIT: {
    label: 'Aitutaki (AIT)',
    city: 'Aitutaki',
    country: 'Cook Islands',
  },
  OGZ: {
    label: 'Vladikavkaz Airport (OGZ)',
    city: 'Vladikavkaz',
    country: 'Russia',
  },
  LED: {
    label: 'Pulkovo (LED)',
    city: 'St Petersburg',
    country: 'Russia',
  },
  HPV: {
    label: 'Princeville (HPV)',
    city: 'Lihue',
    country: 'United States',
  },
  PKR: {
    label: 'Pokhara (PKR)',
    city: 'Pokhara',
    country: 'Nepal',
  },
  YQY: {
    label: 'Sydney Airport (YQY)',
    city: 'Sydney',
    country: 'Canada',
  },
  SJI: {
    label: 'McGuire Field (SJI)',
    city: 'San Jose',
    country: 'Philippines',
  },
  RVR: {
    label: 'Green River Mncpl (RVR)',
    city: 'Green River',
    country: 'United States',
  },
  BWE: {
    label: 'Braunschweig (BWE)',
    city: 'Braunschweig',
    country: 'Germany',
  },
  CGZ: {
    label: 'Casa Grande Mncpl (CGZ)',
    city: 'Casa Grande',
    country: 'United States',
  },
  FUK: {
    label: 'Fukuoka (FUK)',
    city: 'Fukuoka',
    country: 'Japan',
  },
  BSA: {
    label: 'Bossaso Airport (BSA)',
    city: 'Bossaso',
    country: 'Somalia',
  },
  COV: {
    label: 'Covilha (COV)',
    city: 'Covilha',
    country: 'Portugal',
  },
  PGS: {
    label: 'Peach Springs Mncpl (PGS)',
    city: 'Peach Springs',
    country: 'United States',
  },
  ONJ: {
    label: 'Odate Noshiro Airport (ONJ)',
    city: 'Odate Noshiro',
    country: 'Japan',
  },
  AAT: {
    label: 'Altay Airport (AAT)',
    city: 'Altay',
    country: 'China',
  },
  VDM: {
    label: 'Viedma Airport (VDM)',
    city: 'Viedma',
    country: 'Argentina',
  },
  YCY: {
    label: 'Clyde River Airport (YCY)',
    city: 'Clyde River',
    country: 'Canada',
  },
  HPB: {
    label: 'Hooper Bay Airport (HPB)',
    city: 'Hooper Bay',
    country: 'United States',
  },
  GVI: {
    label: 'Green River Airport (GVI)',
    city: 'Green River',
    country: 'Papua New Guinea',
  },
  YXS: {
    label: 'Prince George Airport (YXS)',
    city: 'Prince George',
    country: 'Canada',
  },
  CUZ: {
    label: 'Velazco Astete (CUZ)',
    city: 'Cuzco',
    country: 'Peru',
  },
  CRU: {
    label: 'Lauriston (CRU)',
    city: 'Carriacou Island',
    country: 'Grenada',
  },
  ILZ: {
    label: 'Zilina Airport (ILZ)',
    city: 'Zilina',
    country: 'Slovakia',
  },
  CAB: {
    label: 'Cabinda Airport (CAB)',
    city: 'Cabinda',
    country: 'Angola',
  },
  GLV: {
    label: 'Golovin Airport (GLV)',
    city: 'Golovin',
    country: 'United States',
  },
  CAT: {
    label: 'Cat Island Airport (CAT)',
    city: 'Cat Island',
    country: 'Bahamas',
  },
  FUJ: {
    label: 'Fukue Airport (FUJ)',
    city: 'Fukue',
    country: 'Japan',
  },
  AIM: {
    label: 'Ailuk Island Airport (AIM)',
    city: 'Ailuk Island',
    country: 'Marshall Islands',
  },
  ROA: {
    label: 'Roanoke Municipal Airport (ROA)',
    city: 'Roanoke',
    country: 'United States',
  },
  OGS: {
    label: 'Ogdensburg Intl (OGS)',
    city: 'Ogdensburg',
    country: 'United States',
  },
  NQN: {
    label: 'Neuquen Airport (NQN)',
    city: 'Neuquen',
    country: 'Argentina',
  },
  HYD: {
    label: 'Begumpet (HYD)',
    city: 'Hyderabad',
    country: 'India',
  },
  VKG: {
    label: 'Rach Gia Airport (VKG)',
    city: 'Rach Gia',
    country: 'Vietnam',
  },
  SKK: {
    label: 'Shaktoolik Airport (SKK)',
    city: 'Shaktoolik',
    country: 'United States',
  },
  YBX: {
    label: 'Blanc Sablon Airport (YBX)',
    city: 'Blanc Sablon',
    country: 'Canada',
  },
  MKK: {
    label: 'Molokai Airport (MKK)',
    city: "Ho'olehua",
    country: 'United States',
  },
  LAA: {
    label: 'Lamar Mncpl (LAA)',
    city: 'Lamar',
    country: 'United States',
  },
  TPH: {
    label: 'Tonopah (TPH)',
    city: 'Tonopah',
    country: 'United States',
  },
  SVA: {
    label: 'Savoonga Airport (SVA)',
    city: 'Savoonga',
    country: 'United States',
  },
  YRJ: {
    label: 'Roberval Mncpl (YRJ)',
    city: 'Roberval',
    country: 'Canada',
  },
  YQW: {
    label: 'North Battleford (YQW)',
    city: 'North Battleford',
    country: 'Canada',
  },
  DOG: {
    label: 'Dongola Airport (DOG)',
    city: 'Dongola',
    country: 'Sudan',
  },
  PUT: {
    label: 'Puttaprathe Airport (PUT)',
    city: 'Puttaparthi',
    country: 'India',
  },
  VFA: {
    label: 'Victoria Falls (VFA)',
    city: 'Victoria Falls',
    country: 'Zimbabwe',
  },
  NGO: {
    label: 'Central Japan Intl (NGO)',
    city: 'Nagoya',
    country: 'Japan',
  },
  ILI: {
    label: 'Iliamna Airport (ILI)',
    city: 'Iliamna',
    country: 'United States',
  },
  KLG: {
    label: 'Kalskag Municipal Airport (KLG)',
    city: 'Kalskag',
    country: 'United States',
  },
  UBB: {
    label: 'Mabuiag Island Airport (UBB)',
    city: 'Mabuiag Island',
    country: 'Australia',
  },
  NNT: {
    label: 'Nan Airport (NNT)',
    city: 'Nan',
    country: 'Thailand',
  },
  EDO: {
    label: 'Edremit/Korfez Airport (EDO)',
    city: 'Edremit/Korfez',
    country: 'Turkey',
  },
  MDS: {
    label: 'Middle Caicos Airport (MDS)',
    city: 'Middle Caicos',
    country: 'Turks and Caicos',
  },
  RKH: {
    label: 'Bryant Field (RKH)',
    city: 'Rock Hill',
    country: 'United States',
  },
  TKJ: {
    label: 'Tok (TKJ)',
    city: 'Tok',
    country: 'United States',
  },
  AOS: {
    label: 'Amook Airport (AOS)',
    city: 'Amook',
    country: 'United States',
  },
  JYR: {
    label: 'Jiroft Airport (JYR)',
    city: 'Jiroft',
    country: 'Iran',
  },
  GOV: {
    label: 'Nhulunbuy Airport (GOV)',
    city: 'Gove',
    country: 'Australia',
  },
  MPL: {
    label: 'Mediterranee (MPL)',
    city: 'Montpellier',
    country: 'France',
  },
  SYQ: {
    label: 'Tobias Bolanos Intl (SYQ)',
    city: 'San Jose',
    country: 'Costa Rica',
  },
  HOD: {
    label: 'Hodeidah Airport (HOD)',
    city: 'Hodeidah',
    country: 'Yemen',
  },
  JNS: {
    label: 'Narsaq H/P (JNS)',
    city: 'Narsaq',
    country: 'Greenland',
  },
  MJM: {
    label: 'Mbuji Mayi Airport (MJM)',
    city: 'Mbuji Mayi',
    country: 'Democratic Republic of Congo',
  },
  SLY: {
    label: 'Salehard Airport (SLY)',
    city: 'Salekhard',
    country: 'Russia',
  },
  HAJ: {
    label: 'Hanover (HAJ)',
    city: 'Hanover',
    country: 'Germany',
  },
  YNB: {
    label: 'Yanbu (YNB)',
    city: 'Yanbu',
    country: 'Saudi Arabia',
  },
  EAM: {
    label: 'Nejran (EAM)',
    city: 'Najran',
    country: 'Saudi Arabia',
  },
  GOL: {
    label: 'Gold Beach Mncpl (GOL)',
    city: 'Gold Beach',
    country: 'United States',
  },
  HGL: {
    label: 'Helgoland (HGL)',
    city: 'Helgoland',
    country: 'Germany',
  },
  KZN: {
    label: 'Kazan (KZN)',
    city: 'Kazan',
    country: 'Russia',
  },
  GLR: {
    label: 'Otsego County (GLR)',
    city: 'Gaylord',
    country: 'United States',
  },
  YVO: {
    label: 'Val DOr Airport (YVO)',
    city: "Val-d'Or",
    country: 'Canada',
  },
  TAS: {
    label: 'Vostochny (TAS)',
    city: 'Tashkent',
    country: 'Uzbekistan',
  },
  SBH: {
    label: 'St Barthelemy (SBH)',
    city: 'St Barthelemy',
    country: 'Bahamas',
  },
  SRZ: {
    label: 'El Trompillo (SRZ)',
    city: 'Santa Cruz',
    country: 'Bolivia',
  },
  TNN: {
    label: 'Tainan (TNN)',
    city: "T'ainan",
    country: 'Taiwan',
  },
  PHC: {
    label: 'Port Harcourt (PHC)',
    city: 'Port Harcourt',
    country: 'Nigeria',
  },
  LUM: {
    label: 'Mangshi Airport (LUM)',
    city: 'Luxi',
    country: 'China',
  },
  SEF: {
    label: 'Industrial Park (SEF)',
    city: 'Sebring',
    country: 'United States',
  },
  HME: {
    label: 'Oued Irara Airport (HME)',
    city: 'Hassi Messaoud',
    country: 'Algeria',
  },
  CXH: {
    label: 'Coal Harbour (CXH)',
    city: 'Vancouver',
    country: 'Canada',
  },
  AEP: {
    label: 'Jorge Newbery Airport (AEP)',
    city: 'Buenos Aires',
    country: 'Argentina',
  },
  BND: {
    label: 'Bandar Abbas Airport (BND)',
    city: 'Bandar Abbas',
    country: 'Iran',
  },
  TOI: {
    label: 'Troy Mncpl (TOI)',
    city: 'Troy',
    country: 'United States',
  },
  YQD: {
    label: 'The Pas (YQD)',
    city: 'The Pas',
    country: 'Canada',
  },
  ENA: {
    label: 'Kenai Mncpl (ENA)',
    city: 'Kenai',
    country: 'United States',
  },
  AXT: {
    label: 'Akita (AXT)',
    city: 'Akita',
    country: 'Japan',
  },
  JAA: {
    label: 'Jalalabad Airport (JAA)',
    city: 'Jalalabad',
    country: 'Afghanistan',
  },
  BUH: {
    label: 'All Airports (BUH)',
    city: 'Bucharest',
    country: 'Romania',
  },
  WBQ: {
    label: 'Beaver Airport (WBQ)',
    city: 'Beaver',
    country: 'United States',
  },
  DLH: {
    label: 'Duluth International Airport (DLH)',
    city: 'Duluth',
    country: 'United States',
  },
  LON: {
    label: 'All Airports (LON)',
    city: 'London',
    country: 'United Kingdom',
  },
  BNS: {
    label: 'Barinas Airport (BNS)',
    city: 'Barinas',
    country: 'Venezuela',
  },
  HII: {
    label: 'Lake Havasu City (HII)',
    city: 'Lake Havasu City',
    country: 'United States',
  },
  BZN: {
    label: 'Bozeman (BZN)',
    city: 'Bozeman',
    country: 'United States',
  },
  ATY: {
    label: 'Watertown Airport (ATY)',
    city: 'Watertown',
    country: 'United States',
  },
  YRG: {
    label: 'Rigolet Airport (YRG)',
    city: 'Rigolet',
    country: 'Canada',
  },
  CYB: {
    label: 'Gerrard-Smith (CYB)',
    city: 'Cayman Brac',
    country: 'Cayman Islands',
  },
  SHD: {
    label: 'Shenandoah Valley Airport (SHD)',
    city: 'Staunton',
    country: 'United States',
  },
  GEC: {
    label: 'Gecitkale (GEC)',
    city: 'Nicosia-South',
    country: 'Cyprus',
  },
  DIY: {
    label: 'Kaplaner (DIY)',
    city: 'Diyarbakir',
    country: 'Turkey',
  },
  FAT: {
    label: 'Fresno (FAT)',
    city: 'Fresno',
    country: 'United States',
  },
  CYR: {
    label: 'Colonia (CYR)',
    city: 'Colonia',
    country: 'Uruguay',
  },
  FAO: {
    label: 'Faro (FAO)',
    city: 'Faro',
    country: 'Portugal',
  },
  YTF: {
    label: 'Alma Mncpl (YTF)',
    city: 'Alma',
    country: 'Canada',
  },
  GLA: {
    label: 'Glasgow International Airport (GLA)',
    city: 'Glasgow',
    country: 'United Kingdom',
  },
  FDH: {
    label: 'Friedrichshafen (FDH)',
    city: 'Friedrichshafen',
    country: 'Germany',
  },
  FLL: {
    label: 'Ft Lauderdale (FLL)',
    city: 'Fort Lauderdale',
    country: 'United States',
  },
  RJN: {
    label: 'Rafsanjan Airport (RJN)',
    city: 'Rafsanjan',
    country: 'Iran',
  },
  LIO: {
    label: 'Limon (LIO)',
    city: 'Limon',
    country: 'Costa Rica',
  },
  ZGF: {
    label: 'Grand Forks Airport (ZGF)',
    city: 'Grand Forks',
    country: 'Canada',
  },
  MCP: {
    label: 'Macapa Internacional Airport (MCP)',
    city: 'Macapa',
    country: 'Brazil',
  },
  GOQ: {
    label: 'Golmud Airport (GOQ)',
    city: 'Golmud',
    country: 'China',
  },
  MKU: {
    label: 'Makokou Airport (MKU)',
    city: 'Makokou',
    country: 'Gabon',
  },
  RDB: {
    label: 'Red Dog Airport (RDB)',
    city: 'Red Dog',
    country: 'United States',
  },
  WKR: {
    label: "Walker's Cay Mncpl (WKR)",
    city: "Walker's Cay",
    country: 'Bahamas',
  },
  MUK: {
    label: 'Mauke Island Airport (MUK)',
    city: 'Mauke Island',
    country: 'Cook Islands',
  },
  TAE: {
    label: 'Daegu (TAE)',
    city: 'Daegu',
    country: 'South Korea',
  },
  HNM: {
    label: 'Hana (HNM)',
    city: 'Hana',
    country: 'United States',
  },
  SJU: {
    label: 'San Juan (SJU)',
    city: 'San Juan',
    country: 'Puerto Rico',
  },
  KOU: {
    label: 'Koulamoutou Airport (KOU)',
    city: 'Koulamoutou',
    country: 'Gabon',
  },
  DZA: {
    label: 'Dzaoudzi Airport (DZA)',
    city: 'Mayotte',
    country: 'France',
  },
  EAL: {
    label: 'Elenak Airport (EAL)',
    city: 'Kwajalein Atoll',
    country: 'Marshall Islands',
  },
  YXZ: {
    label: 'Wawa Mncpl (YXZ)',
    city: 'Wawa',
    country: 'Canada',
  },
  AOC: {
    label: 'Altenburg (AOC)',
    city: 'Altenburg',
    country: 'Germany',
  },
  AKN: {
    label: 'King Salmon Airport (AKN)',
    city: 'King Salmon',
    country: 'United States',
  },
  DPO: {
    label: 'Devonport (DPO)',
    city: 'Devonport',
    country: 'Australia',
  },
  PNQ: {
    label: 'Lohegaon (PNQ)',
    city: 'Pune',
    country: 'India',
  },
  PMC: {
    label: 'Tepual (PMC)',
    city: 'Puerto Montt',
    country: 'Chile and Easter Island',
  },
  LGK: {
    label: 'Langkawi (LGK)',
    city: 'Langkawi',
    country: 'Malaysia',
  },
  KNS: {
    label: 'King Island Airport (KNS)',
    city: 'King Island',
    country: 'Australia',
  },
  SDY: {
    label: 'Sidney/Richland (SDY)',
    city: 'Sidney',
    country: 'United States',
  },
  CPH: {
    label: 'Copenhagen Airport (CPH)',
    city: 'Copenhagen',
    country: 'Denmark',
  },
  DTM: {
    label: 'Dortmund Wickede (DTM)',
    city: 'Dortmund',
    country: 'Germany',
  },
  NCE: {
    label: 'Cote DAzur Airport (NCE)',
    city: 'Nice',
    country: 'France',
  },
  HHH: {
    label: 'Hilton Head (HHH)',
    city: 'Hilton Head',
    country: 'United States',
  },
  DME: {
    label: 'Domodedovo Airport (DME)',
    city: 'Moscow',
    country: 'Russia',
  },
  FWA: {
    label: 'Fort Wayne Municipal Airport/Baer Field (FWA)',
    city: 'Ft Wayne',
    country: 'United States',
  },
  YSC: {
    label: 'Sherbrooke (YSC)',
    city: 'Sherbrooke',
    country: 'Canada',
  },
  CPX: {
    label: 'Culebra (CPX)',
    city: 'Culebra Island',
    country: 'Puerto Rico',
  },
  UII: {
    label: 'Utila Airport (UII)',
    city: 'Utila',
    country: 'Honduras',
  },
  CBL: {
    label: 'Ciudad Bolivar Airport (CBL)',
    city: 'Ciudad Bolivar',
    country: 'Venezuela',
  },
  CTS: {
    label: 'Chitose (CTS)',
    city: 'Sapporo',
    country: 'Japan',
  },
  KCZ: {
    label: 'Kochi (KCZ)',
    city: 'Kochi',
    country: 'Japan',
  },
  OXF: {
    label: 'Oxford (OXF)',
    city: 'Oxford',
    country: 'United Kingdom',
  },
  LSS: {
    label: 'Terre de Haut (LSS)',
    city: 'Terre de Haut',
    country: 'St Barthelemy',
  },
  LAO: {
    label: 'Laoag (LAO)',
    city: 'Laoag',
    country: 'Philippines',
  },
  TKN: {
    label: 'Tokunoshima Airport (TKN)',
    city: 'Tokunoshima',
    country: 'Japan',
  },
  JBR: {
    label: 'Jonesboro Mncpl (JBR)',
    city: 'Jonesboro',
    country: 'United States',
  },
  BDL: {
    label: 'Hartford (BDL)',
    city: 'Hartford',
    country: 'United States',
  },
  NIM: {
    label: 'Niamey (NIM)',
    city: 'Niamey',
    country: 'Niger',
  },
  KWM: {
    label: 'Kowanyama Airport (KWM)',
    city: 'Kowanyama',
    country: 'Australia',
  },
  MIK: {
    label: 'Mikkeli (MIK)',
    city: 'Mikkeli',
    country: 'Finland',
  },
  PAG: {
    label: 'Pagadian Airport (PAG)',
    city: 'Pagadian',
    country: 'Philippines',
  },
  XMY: {
    label: 'Yam Island Airport (XMY)',
    city: 'Yam Island',
    country: 'Australia',
  },
  BOH: {
    label: 'Bournemouth (BOH)',
    city: 'Bournemouth',
    country: 'United Kingdom',
  },
  REL: {
    label: 'Trelew Airport (REL)',
    city: 'Trelew',
    country: 'Argentina',
  },
  WEF: {
    label: 'Weifang (WEF)',
    city: 'Weifang',
    country: 'China',
  },
  ISN: {
    label: 'Sloulin Field International Airport (ISN)',
    city: 'Williston',
    country: 'United States',
  },
  LAD: {
    label: '4 De Fevereiro (LAD)',
    city: 'Luanda',
    country: 'Angola',
  },
  YXD: {
    label: 'Edmonton City Centre (YXD)',
    city: 'Edmonton',
    country: 'Canada',
  },
  HKK: {
    label: 'Hokitika (HKK)',
    city: 'Hokitika',
    country: 'New Zealand',
  },
  SAB: {
    label: 'J Yrausquin (SAB)',
    city: 'Saba',
    country: 'Curacao',
  },
  WLD: {
    label: 'Strother Field (WLD)',
    city: 'Winfield',
    country: 'United States',
  },
  TGU: {
    label: 'Toncontin Airport (TGU)',
    city: 'Tegucigalpa',
    country: 'Honduras',
  },
  UAQ: {
    label: 'San Juan Airport (UAQ)',
    city: 'San Juan',
    country: 'Argentina',
  },
  MWA: {
    label: 'Williamson Cty (MWA)',
    city: 'Marion',
    country: 'United States',
  },
  RCB: {
    label: 'Richards Bay (RCB)',
    city: 'Richards Bay',
    country: 'South Africa',
  },
  ACR: {
    label: 'Araracuara Airport (ACR)',
    city: 'Araracuara',
    country: 'Colombia',
  },
  MBU: {
    label: 'Mbambanakira Airport (MBU)',
    city: 'Mbambanakira',
    country: 'Solomon Islands',
  },
  OKY: {
    label: 'Oakey Airport (OKY)',
    city: 'Oakey',
    country: 'Australia',
  },
  JMS: {
    label: 'Jamestown Mncpl (JMS)',
    city: 'Jamestown',
    country: 'MD',
  },
  BDD: {
    label: 'Badu Island Airport (BDD)',
    city: 'Badu Island',
    country: 'Australia',
  },
  JPA: {
    label: 'Castro Pinto (JPA)',
    city: 'Joao Pessoa',
    country: 'Brazil',
  },
  EWR: {
    label: 'Newark (EWR)',
    city: 'Newark',
    country: 'United States',
  },
  JUL: {
    label: 'Juliaca (JUL)',
    city: 'Juliaca',
    country: 'Peru',
  },
  DUQ: {
    label: 'Quamichan Lake (DUQ)',
    city: 'Duncan',
    country: 'Canada',
  },
  IMK: {
    label: 'Simikot Airport (IMK)',
    city: 'Simikot',
    country: 'Nepal',
  },
  AOK: {
    label: 'Karpathos Airport (AOK)',
    city: 'Karpathos',
    country: 'Greece',
  },
  FOG: {
    label: 'Gino Lisa (FOG)',
    city: 'Foggia',
    country: 'Italy',
  },
  NAT: {
    label: 'Augusto Severo (NAT)',
    city: 'Natal',
    country: 'Brazil',
  },
  MLS: {
    label: 'Miles City Mncpl (MLS)',
    city: 'Miles City',
    country: 'United States',
  },
  BBK: {
    label: 'Kasane (BBK)',
    city: 'Kasane',
    country: 'Botswana',
  },
  YAX: {
    label: 'Angling Lake Airport (YAX)',
    city: 'Angling Lake',
    country: 'Canada',
  },
  UGN: {
    label: 'Waukegan Regl (UGN)',
    city: 'Waukegan',
    country: 'United States',
  },
  DWD: {
    label: 'Dawadmi Airport (DWD)',
    city: 'Dawadmi',
    country: 'Saudi Arabia',
  },
  UTM: {
    label: 'Tunica (UTM)',
    city: 'Tunica',
    country: 'United States',
  },
  BHZ: {
    label: 'All Airports (BHZ)',
    city: 'Belo Horizonte',
    country: 'Brazil',
  },
  YPL: {
    label: 'Provincial (YPL)',
    city: 'Pickle Lake',
    country: 'Canada',
  },
  ZBY: {
    label: 'Sayaboury Airport (ZBY)',
    city: 'Sayaboury',
    country: 'Laos',
  },
  AEB: {
    label: 'Baise Airport (AEB)',
    city: 'Baise',
    country: 'China',
  },
  LGQ: {
    label: 'Lago Agrio Airport (LGQ)',
    city: 'Lago Agrio',
    country: 'Ecuador',
  },
  KWZ: {
    label: 'Kolwezi Airport (KWZ)',
    city: 'Kolwezi',
    country: 'Democratic Republic of Congo',
  },
  GTF: {
    label: 'Great Falls International Airport (GTF)',
    city: 'Great Falls',
    country: 'United States',
  },
  YKU: {
    label: 'Chisasibi Airport (YKU)',
    city: 'Chisasibi',
    country: 'Canada',
  },
  RGS: {
    label: 'Villafria (RGS)',
    city: 'Burgos',
    country: 'Spain',
  },
  SCT: {
    label: 'Socotra Airport (SCT)',
    city: 'Socotra',
    country: 'Yemen',
  },
  BEI: {
    label: 'Beica Airport (BEI)',
    city: 'Beica',
    country: 'Ethiopia',
  },
  MGR: {
    label: 'Thomasville (MGR)',
    city: 'Moultrie',
    country: 'United States',
  },
  WUA: {
    label: 'Wu Hai Airport (WUA)',
    city: 'Wu Hai',
    country: 'China',
  },
  MSY: {
    label: 'New Orleans (MSY)',
    city: 'New Orleans',
    country: 'United States',
  },
  ASX: {
    label: 'Ashland (ASX)',
    city: 'Ashland',
    country: 'United States',
  },
  YGR: {
    label: 'Iles De La Madeleine Airport (YGR)',
    city: 'Iles de la Madeleine',
    country: 'Canada',
  },
  CIF: {
    label: 'Chifeng Airport (CIF)',
    city: 'Chifeng',
    country: 'China',
  },
  WAT: {
    label: 'Waterford (WAT)',
    city: 'Waterford',
    country: 'Ireland',
  },
  TKU: {
    label: 'Turku (TKU)',
    city: 'Turku',
    country: 'Finland',
  },
  HMT: {
    label: 'Hemet-Ryan (HMT)',
    city: 'Hemet',
    country: 'United States',
  },
  KUL: {
    label: 'Kuala Lumpur Intl (KUL)',
    city: 'Kuala Lumpur',
    country: 'Malaysia',
  },
  LAY: {
    label: 'Ladysmith (LAY)',
    city: 'Ladysmith',
    country: 'South Africa',
  },
  PSR: {
    label: 'Liberi (PSR)',
    city: 'Pescara',
    country: 'Italy',
  },
  YHK: {
    label: 'Gjoa Haven Airport (YHK)',
    city: 'Gjoa Haven',
    country: 'Canada',
  },
  SVC: {
    label: 'Grant Cty (SVC)',
    city: 'Silver City',
    country: 'United States',
  },
  SYB: {
    label: 'Seal Bay Airport (SYB)',
    city: 'Seal Bay',
    country: 'United States',
  },
  VVC: {
    label: 'La Vanguardia Airport (VVC)',
    city: 'Villavicencio',
    country: 'Colombia',
  },
  KUM: {
    label: 'Yakushima Airport (KUM)',
    city: 'Yakushima',
    country: 'Japan',
  },
  LWL: {
    label: 'Harriet Field (LWL)',
    city: 'Wells',
    country: 'United States',
  },
  SID: {
    label: 'Amilcar Cabral International Airport (SID)',
    city: 'Sal',
    country: 'Cape Verde',
  },
  DTA: {
    label: 'Delta Mncpl (DTA)',
    city: 'Delta',
    country: 'United States',
  },
};
