import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from './datePicker.module.css';

const Calendar = styled.div`
  position: relative;
`;

const CalendarHeader = styled.div`
  border-bottom: 1px solid #aeaeae;
  width: 240px;
`;

const StyledDatePicker = styled(DatePicker)`
  font-size: 0.9rem;
  width: auto;
`;

const Legend = styled.div`
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 4px;
  margin: 5px;
  padding: 8px;
`;

const LegendSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDatePicker = memo(
  ({ id, minDate, loanMaturity, selectedDueDate, setSelectedDueDate, monthlyDueDay, lastScheduleDate }) => {
    const { i18n, t } = useTranslation();
    const today = useMemo(() => new Date(), []);

    const maxDate = new Date(moment(lastScheduleDate));

    const highlightedDates = useMemo(
      () => [
        {
          [styles.today]: [today],
        },
        {
          [styles.loanMaturityDate]: [loanMaturity],
        },
      ],
      [loanMaturity, today]
    );

    const getDayClassName = useCallback(
      (date) => {
        return date.getDate() === monthlyDueDay && date <= loanMaturity ? styles.monthlyDueDay : undefined;
      },
      [loanMaturity, monthlyDueDay]
    );

    const calendarContainer = useCallback(
      ({ className, children }) => {
        return (
          <CalendarContainer className={className}>
            <CalendarHeader>
              <LegendSection>
                <LegendItem>
                  <Legend borderColor="#0a8771" />
                  <div>{t('calendar.todays_date')}</div>
                </LegendItem>
                <LegendItem>
                  <Legend borderColor="#fca82a" />
                  <div>{t('calendar.monthly_due_date')}</div>
                </LegendItem>
                <LegendItem>
                  <Legend borderColor="#8ab2f7" />
                  <div>{t('calendar.loan_maturity_date')}</div>
                </LegendItem>
              </LegendSection>
            </CalendarHeader>
            <Calendar>{children}</Calendar>
          </CalendarContainer>
        );
      },
      [t]
    );

    return (
      <StyledDatePicker
        id={id}
        className="form-control"
        locale={i18n.language}
        dateFormat="MMM dd yyyy"
        showPopperArrow={false}
        minDate={minDate}
        maxDate={maxDate}
        selected={selectedDueDate}
        onChange={setSelectedDueDate}
        openToDate={minDate}
        highlightDates={highlightedDates}
        dayClassName={getDayClassName}
        calendarContainer={calendarContainer}
        showDisabledMonthNavigation
      />
    );
  }
);

CustomDatePicker.displayName = 'CustomDatePicker';

CustomDatePicker.propTypes = {
  id: PropTypes.string,
  lastScheduleDate: PropTypes.string.isRequired,
  loanMaturity: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  monthlyDueDay: PropTypes.number.isRequired,
  selectedDueDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDueDate: PropTypes.func.isRequired,
};

CustomDatePicker.defaultProps = {
  id: undefined,
};

export default CustomDatePicker;
