import { ACTIONS } from 'state/actionTypes';

export const postAccountUpdateInitialize = (accountId, type, data) => (dispatch) => {
  return dispatch({
    type: ACTIONS.postAccountUpdateInitialize,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/update/${type}/initialize`,
        data,
      },
    },
  });
};

export const postAccountUpdateVerify = (accountId, sessionId, verificationCode) => (dispatch) => {
  return dispatch({
    type: ACTIONS.postAccountUpdateVerify,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/update/verify`,
        data: {
          session_id: sessionId,
          verification_code: verificationCode,
        },
      },
    },
  });
};

export const resetAccountUpdate = () => ({
  type: ACTIONS.resetAccountUpdate,
});

export const resetAccountUpdateInitialize = () => ({
  type: ACTIONS.resetAccountUpdateInitialize,
});

export const resetAccountUpdateVerify = () => ({
  type: ACTIONS.resetAccountUpdateVerify,
});

export const dismissLoggedOutMessage = () => ({
  type: ACTIONS.dismissLoggedOutMessage,
});

export default {
  postAccountUpdateVerify,
  postAccountUpdateInitialize,
};
