import { ACTIONS } from 'state/actionTypes';

export const resetSetLoanPaymentMethod = () => {
  return {
    type: ACTIONS.putLoanPaymentMethodReset,
  };
};

export const setLoanPaymentMethod = (accountId, loanId, paymentMethodId) => async (dispatch) => {
  const responseAction = await dispatch({
    type: ACTIONS.putLoanPaymentMethod,
    payload: {
      request: {
        method: 'put',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/paymentMethod`,
        data: {
          paymentToken: paymentMethodId,
        },
      },
    },
  });
  return responseAction;
};

export const switchPaymentMethodForLoan = (loanId, paymentMethodId) => {
  return {
    type: ACTIONS.updateLoanPaymentMethod,
    loanId,
    paymentMethodId,
  };
};
