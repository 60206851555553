import { ACTIONS } from 'state/actionTypes';

export const getApplications = (accountId) => (dispatch) => {
  dispatch({
    type: ACTIONS.getApplications,
    payload: {
      request: {
        method: 'get',
        url: `/bp/v1/account/${accountId}/applications`,
      },
    },
  });
};

export default getApplications;
