import { ACTIONS } from 'state/actionTypes';

import { accountIdSelector } from 'state/account/selectors';
import { getLoans } from 'state/loans/actions';

import { convertAmountInDollarsToCents } from 'lib/utils';

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const postMakePayment = (loanId, amount, isDownPayment = false) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const paymentData = {
    amount: convertAmountInDollarsToCents(amount),
    isDownPayment,
  };

  const result = await dispatch({
    type: ACTIONS.postMakePayment,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/pay`,
        data: paymentData,
      },
    },
  });

  // Indicate that the loan still has to be refreshed to avoid re-enabling
  // payment section too soon. The refresh is considered complete when the new
  // loan data is retrieved.
  dispatch({ type: ACTIONS.postMakePaymentRefresh });

  sleep(2000).then(() => {
    dispatch(getLoans(accountId));
  });

  return result;
};

export const postMakePaymentReset = () => ({
  type: ACTIONS.postMakePaymentReset,
});
