import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Auth, Cache } from 'aws-amplify';

import configureStore from './configureStore';
import Router from './Router';

import Theme from './styles/Theme';

export const store = configureStore();

Cache.clear();
Auth.configure({ storage: window.sessionStorage });

const AuthApp = memo(() => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  );
});

export default AuthApp;
