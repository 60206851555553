import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from 'components/Loader';

import { applicationsSelector } from 'state/applications/selectors';

import { Panel } from 'styles/CommonStyles';
import ApplicationsDetailsView from './ApplicationsDetailsView';

const ApplicationsDetailsContainer = memo(() => {
  const applicationsDetails = useSelector(applicationsSelector);

  if (!applicationsDetails) {
    return (
      <Panel id="Panel">
        <Loader />
      </Panel>
    );
  }
  if (!applicationsDetails.length) {
    return (
      <Redirect
        to={{
          pathname: '/account',
        }}
      />
    );
  }

  return (
    <>
      {applicationsDetails.map((appDetails) => (
        <ApplicationsDetailsView key={appDetails.applicationId} applicationDetails={appDetails} />
      ))}
    </>
  );
});

export default ApplicationsDetailsContainer;
