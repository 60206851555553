import React, { useState, useCallback, useEffect, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { LOAN_STATUS_CODES } from 'lib/constants/loanStatuses';
import { toKebabCase } from 'lib/utils';

import OverdueMessage from 'views/loans/schedulePayments/OverdueMessage';
import PaymentProgressBar from 'views/loans/components/PaymentProgressBar';
import IfIMissPaymentModal from 'views/loans/payments/modals/IfIMissPaymentModal';

import { MaterialIcon } from 'styles/Icons';
import { Body, ButtonHyperLink, IconColorHyperlinkBlue } from 'styles/CommonStyles';

import Payment from 'views/loans/payments';

const EnableAutoPay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    font-size: 16px;
  }
`;

const NextPaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NextPaymentDue = styled.div`
  font-size: 14px;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    font-size: 1.2rem;
  }
`;

const StyledButtonHyperLink = styled(ButtonHyperLink)`
  margin-right: 0.2rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    margin-right: 0.3rem;
  }
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  cursor: pointer;
  margin-left: 0.2rem;
`;

const iconSize = '1rem';

const LoanPayment = memo(({ loanDetails, nextPaymentOrOverdueDate }) => {
  const { t } = useTranslation();
  const { daysPastDue, industry, monthlyPaymentCount, paymentHistory, servicerName, term, remainingTerm } = loanDetails;

  const overduePayments = paymentHistory.reduce((count, x) => {
    if (x.status === 'Overdue') {
      // eslint-disable-next-line no-param-reassign
      count += 1;
    }
    return count;
  }, 0);

  const [showifIMissPaymentModal, setShowifIMissPaymentModal] = useState(false);

  const isAutoPay = loanDetails?.autopay;

  const isDPD = daysPastDue > 0;

  const isPending = loanDetails.loanStatusCode === LOAN_STATUS_CODES.PENDING;

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27 && showifIMissPaymentModal === true) {
        // google analytics
        const gaEvent = {
          category: 'user',
          action: 'click',
          label: 'close-what-if-miss-payment-modal',
          value: 1,
        };
        trackEvent(gaEvent);

        // cevents
        let metadata = {};
        metadata.uplift_account_id = localStorage.getItem('account_id_hash');
        metadata.isAdmin = localStorage.getItem('adminAuth');
        metadata = toKebabCase(metadata);

        cevents.setLevel('debug');
        cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

        setShowifIMissPaymentModal(false);
      }
    },
    [showifIMissPaymentModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const openWhatIfIMissPaymentModal = useCallback((event) => {
    event.preventDefault();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'open-what-if-miss-payment-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setShowifIMissPaymentModal(true);
  }, []);

  const closeWhatIfIMissPaymentModal = useCallback((event) => {
    event.preventDefault();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'close-what-if-miss-payment-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setShowifIMissPaymentModal(false);
  }, []);

  return (
    <>
      <Body>
        <NextPaymentSection>
          <NextPaymentDue data-testid="next-payment-due-on">
            {!isPending && nextPaymentOrOverdueDate && monthlyPaymentCount !== term && (
              <Trans i18nKey="loans.details.next_payment_due_on" nextPaymentOrOverdueDate={nextPaymentOrOverdueDate}>
                Next payment due on {{ nextPaymentOrOverdueDate }}
              </Trans>
            )}
            {isPending && (
              <Trans i18nKey="loans.details.payment_unavailable_hotel_processing">
                Your payment schedule will be available when the hotel processes the booking charge, closer to your travel dates.
              </Trans>
            )}
          </NextPaymentDue>
        </NextPaymentSection>

        {isDPD && servicerName !== 'FFAM' && servicerName !== 'TrueAccord Recover' && (
          <OverdueMessage
            header={t('loans.payment.past_due.paymentWithDaysPastDue', { count: overduePayments })}
            message={
              <Trans
                i18nKey={`loans.payment.past_due.${overduePayments >= 3 ? '3_payments_past_message' : 'payment_past_message'}`}
              >
                msg
                <ButtonHyperLink onClick={openWhatIfIMissPaymentModal}>Learn More</ButtonHyperLink>
              </Trans>
            }
          />
        )}

        {!isPending && <PaymentProgressBar monthlyPaymentCount={monthlyPaymentCount} term={term} remainingTerm={remainingTerm} />}

        {isAutoPay && !isDPD && !isPending && (
          <EnableAutoPay data-testid="enable-autopay">
            <Trans i18nKey="loans.details.what_happens_if">
              <StyledButtonHyperLink onClick={openWhatIfIMissPaymentModal}>
                {t('account.payment_methods.if_i_miss_payment.what_happens_if_i_miss_a_payment')}
              </StyledButtonHyperLink>
            </Trans>
            <StyledMaterialIcon name="info" size={iconSize} color={IconColorHyperlinkBlue} />
          </EnableAutoPay>
        )}
      </Body>

      {showifIMissPaymentModal && (
        <IfIMissPaymentModal closeWhatIfIMissPaymentModal={closeWhatIfIMissPaymentModal} isDPD={isDPD} industry={industry} />
      )}

      <Payment loanDetails={loanDetails} />
    </>
  );
});

LoanPayment.displayName = 'LoanPayment';

LoanPayment.propTypes = {
  loanDetails: PropTypes.shape({
    autopay: PropTypes.bool,
    daysPastDue: PropTypes.number,
    industry: PropTypes.string,
    loanId: PropTypes.string,
    loanStatusCode: PropTypes.string,
    monthlyPaymentCount: PropTypes.number,
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    paymentToken: PropTypes.string,
    servicerName: PropTypes.string,
    term: PropTypes.number,
    remainingTerm: PropTypes.number,
  }).isRequired,
  nextPaymentOrOverdueDate: PropTypes.string,
};

LoanPayment.defaultProps = {
  nextPaymentOrOverdueDate: null,
};

export default LoanPayment;
