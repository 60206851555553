import React, { useCallback, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import CommonPopover from 'components/popovers/CommonPopover';
import { MaterialIcon } from 'styles/Icons';

const DelayButton = styled.button`
  background: white;
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  border: none;
  outline: none;

  font-size: 0.9rem;

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed !important;
    box-shadow: none;
  }
`;

const DelayPaymentButton = memo(({ delayPaymentState, setDelayPaymentState, isDelayPaymentAllowed, isPaymentDueToday }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const onClick = useCallback(() => {
    if (delayPaymentState === 'initial') {
      setDelayPaymentState('form');
    } else {
      setDelayPaymentState('initial');
    }
  }, [delayPaymentState, setDelayPaymentState]);

  return (
    <>
      {isDelayPaymentAllowed ? (
        <DelayButton data-testid="delay-payment-button" onClick={onClick} className="d-flex align-items-center">
          {delayPaymentState === 'initial' ? <MaterialIcon name="expand_more" /> : <MaterialIcon name="expand_less" />}
          <div>{t('loans.payment.delay.delay_your_next_payment')}</div>
        </DelayButton>
      ) : (
        <>
          <DelayButton data-testid="delay-payment-button" disabled style={{ marginRight: '0.5rem' }}>
            {t('loans.payment.delay.delay_your_next_payment')}
          </DelayButton>
          <CommonPopover
            role="tooltip"
            data-testid="delay-popover"
            type="delay-payment"
            placement="top"
            color={themeContext.colors.hyperlinkBlue}
            icon="info"
            textcontent={
              <>
                <b>{t('loans.payment.delay.why_cant_delay')}</b>
                <br />
                {isPaymentDueToday ? (
                  <div data-testid="day-of-payment">{t('loans.payment.delay.invalid_delay_date')}</div>
                ) : (
                  <div data-testid="15-days-before">{t('loans.payment.delay.why_cant_delay_reason')}</div>
                )}
              </>
            }
          />
        </>
      )}
    </>
  );
});

DelayPaymentButton.displayName = 'DelayPaymentButton';

DelayPaymentButton.propTypes = {
  delayPaymentState: PropTypes.string.isRequired,
  setDelayPaymentState: PropTypes.func.isRequired,
  isDelayPaymentAllowed: PropTypes.bool.isRequired,
  isPaymentDueToday: PropTypes.bool.isRequired,
};

export default DelayPaymentButton;
