import React, { useState, useCallback, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS, es, fr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';

import { postDelayPayment } from 'state/loans/actions';
import { loanNextPaymentDateSelector } from 'state/loans/selectors';
import { delayPaymentSelector } from 'state/loans/delayPayment/selectors';

import { Alert, Button, MutedText } from 'styles/CommonStyles';
import CommonPopover from 'components/popovers/CommonPopover';

registerLocale('en-US', enUS);
registerLocale('fr', fr);
registerLocale('es', es);

const DelayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
`;

const StyledAlert = styled(Alert)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.borderLightGrey};
  padding: 0.7rem;
  margin-bottom: 0;
`;

const StyledDatePicker = styled(DatePicker)`
  font-size: 0.9rem;
  margin-left: 1rem;
  width: auto;
`;

const Label = styled.label`
  color: #555555;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  margin-bottom: 0rem;
`;

const MutedText2 = styled(MutedText)`
  font-size: 0.8rem;
`;

const PositionLeft = styled.div`
  margin-left: -1rem;
  position: absolute;
`;

const DelayPaymentForm = memo(({ loanDetails, setDelayPaymentState }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { handleSubmit, formState } = useForm();

  const { loading } = useSelector(delayPaymentSelector);

  const nextPaymentDate = useSelector((state) => loanNextPaymentDateSelector(state, loanDetails.loanId));
  const originalPaymentDate = loanDetails.originalDueDate || nextPaymentDate;

  const [originalDueDateFormat, originalDueDate, maxDueDate, tomorrowsDate] = useMemo(() => {
    return [
      moment(originalPaymentDate).format('MM/DD/YYYY'),
      moment(originalPaymentDate).toDate(),
      moment(originalPaymentDate)
        .add(15, 'days')
        .toDate(),
      moment()
        .add(1, 'day')
        .toDate(),
    ];
  }, [originalPaymentDate]);

  const minDate = originalDueDate < tomorrowsDate ? tomorrowsDate : originalDueDate;
  const [selectedDueDate, setSelectedDueDate] = useState(minDate);
  const desiredDueDateFormat = moment(selectedDueDate).format('MM/DD/YYYY');

  const submitDelayPayment = useCallback(async () => {
    setDelayPaymentState('result');

    const newPaymentDate = moment(selectedDueDate).format('MM/DD/YYYY');

    // cevents
    let metadata = {};
    metadata.loanId = loanDetails.loanId;
    metadata.selectedDueDate = selectedDueDate;
    metadata.newPaymentDateFormat = 'MM/DD/YYYY';
    metadata.newPaymentDate = newPaymentDate;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw('user', 'click', 'delay-payment', metadata);

    await dispatch(postDelayPayment(loanDetails.loanId, { delayPayment: newPaymentDate }));
  }, [dispatch, loanDetails, selectedDueDate, setDelayPaymentState]);

  return (
    <>
      <form onSubmit={handleSubmit(submitDelayPayment)}>
        <StyledAlert variant="primary">
          <DelayWrapper>
            <Label data-testid="current-due-date">{t('loans.payment.delay.current_due_date')}</Label>
            <StyledDatePicker
              locale={i18n.language}
              className="form-control"
              selected={originalDueDate}
              dateFormat="MMM d yyyy"
              disabled
            />
          </DelayWrapper>

          <DelayWrapper>
            <div className="d-flex align-items-center">
              <PositionLeft>
                <CommonPopover
                  type="delay-payment"
                  placement="auto"
                  color="#383D47"
                  icon="info"
                  textcontent={<>{t('loans.payment.delay.can_delay_payment')}</>}
                />
              </PositionLeft>
              <Label data-testid="desired-due-date">{t('loans.payment.delay.desired_due_date')}</Label>
            </div>

            <StyledDatePicker
              locale={i18n.language}
              className="form-control"
              selected={selectedDueDate}
              onChange={setSelectedDueDate}
              dateFormat="MMM d yyyy"
              minDate={minDate}
              maxDate={maxDueDate}
              showDisabledMonthNavigation
            />
          </DelayWrapper>
          <DelayWrapper>
            <MutedText2 data-testid="payment-delay-auth">
              <Trans
                i18nKey="loans.payment.delay.payment_delay_auth"
                originalDueDateFormat={originalDueDateFormat}
                desiredDueDateFormat={desiredDueDateFormat}
              >
                Your payment will be delayed from your current due date of {{ originalDueDateFormat }} to your desired due date of{' '}
                {{ desiredDueDateFormat }}. By clicking &quot;Agree and Delay,&quot; you are authorizing this scheduled payment and
                understand you will be responsible for any accrued interest.
              </Trans>
            </MutedText2>
          </DelayWrapper>
          <Button
            data-testid="agree-delay-button"
            className="btn-block text-uppercase m-0"
            type="submit"
            disabled={formState.isSubmitting || loading || !selectedDueDate}
          >
            {t('loans.payment.delay.agree_delay')}
          </Button>
        </StyledAlert>
      </form>
    </>
  );
});

DelayPaymentForm.displayName = 'DelayPaymentForm';

DelayPaymentForm.propTypes = {
  loanDetails: PropTypes.shape({
    loanId: PropTypes.string,
    originalDueDate: PropTypes.string,
  }).isRequired,
  setDelayPaymentState: PropTypes.func.isRequired,
};

export default DelayPaymentForm;
