import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';

import useAutoFocus from 'hooks/useAutoFocus';

import { ErrorMessage, FormInput } from 'styles/AuthStyles';

import Input from 'components/Input';

import usFlag from 'lib/assets/images/us-flag.svg';
import caFlag from 'lib/assets/images/ca-flag.svg';

const options = [
  {
    value: 'US',
    lng: 'en-US',
    label: (
      <div>
        <img src={usFlag} alt="USA flag" />
        &nbsp;+1
      </div>
    ),
  },
  {
    value: 'CA',
    lng: 'en',
    label: (
      <div>
        <img src={caFlag} alt="Canada flag" />
        &nbsp;+1
      </div>
    ),
  },
];

const customStyles = {
  indicatorSeparator: () => ({}),
};

const StyledSelect = styled(Select)`
  width: 190px;
  margin-right: 0.5rem;
`;

const SignInPhone = memo((props) => {
  const { t, i18n } = useTranslation();
  const inputRef = useAutoFocus();

  const { username, onUsernameChange, showError, dupError } = props;

  const value = useMemo(() => {
    if (i18n.language === 'fr') {
      return options[1]; // return option CA
    }
    return options.find((option) => option.lng === i18n.language) || options[0];
  }, [i18n.language]);

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('authentication.errors.invalid_mobile')}</ErrorMessage>}
        {dupError && <ErrorMessage>{t('authentication.errors.multiple_accounts')}</ErrorMessage>}

        <div className="d-flex">
          <StyledSelect
            name="countryCode"
            styles={customStyles}
            options={options}
            defaultValue={value}
            menuPosition="fixed"
            isSearchable={false}
          />

          <Input
            ref={inputRef}
            type="tel"
            name="username"
            aria-label="mobile number"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            maxLength={12}
            placeholder={t('authentication.sign_in.placeholders.mobile')}
            onChange={onUsernameChange}
            value={username}
            required
          />
        </div>
      </FormInput>
    </>
  );
});

SignInPhone.displayName = 'SignInPhone';

SignInPhone.propTypes = {
  onUsernameChange: PropTypes.func.isRequired,
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dupError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  username: PropTypes.string,
};

SignInPhone.defaultProps = {
  showError: false,
  dupError: false,
  username: null,
};

export default SignInPhone;
