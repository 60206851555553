import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ErrorMessage, FormInput } from 'styles/AuthStyles';

const customStyles = {
  // eslint-disable-next-line no-unused-vars
  control: (provided, state) => ({
    ...provided,
    // borderRadius: '0',
  }),
  indicatorSeparator: () => ({}),
};

const options = [
  { value: 'US', lng: 'en-US', label: 'United States' },
  { value: 'CA', lng: 'en', label: 'Canada' },
];

const SignInCountry = memo(({ showError, onCountryChange }) => {
  const { t, i18n } = useTranslation();

  const groupedOptions = [
    {
      label: t('authentication.sign_in.placeholders.country'),
      options,
    },
  ];

  const value = useMemo(() => {
    if (i18n.language === 'fr') {
      return options[1]; // return option CA
    }

    return options.find((option) => option.lng === i18n.language) || options[0];
  }, [i18n.language]);

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('authentication.errors.country')}</ErrorMessage>}
        <Select
          name="country"
          styles={customStyles}
          options={groupedOptions}
          defaultValue={value}
          onChange={onCountryChange}
          placeholder={t('authentication.sign_in.placeholders.country')}
          required
          isSearchable={false}
        />
      </FormInput>
    </>
  );
});

SignInCountry.displayName = 'SignInCountry';

SignInCountry.propTypes = {
  onCountryChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
};

SignInCountry.defaultProps = {
  showError: false,
};

export default SignInCountry;
