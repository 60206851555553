import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HARDSHIP_CODES } from 'lib/constants/loanStatuses';
import { postEnrollHardship, postEnrollHardshipReset } from 'state/loans/hardship/actions';
import loanEnrollHardshipSelector from 'state/loans/hardship/selectors';

import { Alert, AlertMessage, Body } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';

import Loader from 'components/Loader';
import HardshipButton from '../hardship/components/HardshipButton';
import HardshipPanel from '../hardship/components/HardshipPanel';

const { CLOSED, INELIGIBLE, INELIGIBLE_SECONDARY_STATUS, FINAL_PAYMENT, NO_BILLINGS, MAX_ENROLLMENTS } = HARDSHIP_CODES;
const INELIGIBLE_STATUSES = [CLOSED, INELIGIBLE, INELIGIBLE_SECONDARY_STATUS, NO_BILLINGS, MAX_ENROLLMENTS];

const LoanHardship = ({ loan }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const enrollHardshipState = useSelector((state) => loanEnrollHardshipSelector(state, loan.loanId));

  const onConfirm = (enrollmentData) => {
    dispatch(postEnrollHardship(loan.loanId, enrollmentData.hardshipPaymentAmount));
  };

  const onCancel = () => {
    setExpanded(false);
  };

  const hardshipStatus = loan.hardshipEligibility?.status;
  const isExceededError = hardshipStatus === MAX_ENROLLMENTS;
  const isEligible = hardshipStatus && INELIGIBLE_STATUSES.indexOf(hardshipStatus) === -1;
  const isSuccess = !!enrollHardshipState?.data;
  const isFail = !!enrollHardshipState?.error;
  const isShowFail = !!isFail && !isExceededError;
  const requiredPayment = hardshipStatus === FINAL_PAYMENT ? loan.hardshipEligibility.minimumPaymentAmount : undefined;
  const isLoading = !!enrollHardshipState?.isLoading;
  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <Alert variant="success">
          <AlertMessage>
            <MaterialIcon className="mr-2" name="check_circle" color={theme.colors.successGreen} />
            {t('loans.hardship.enrollment_success')}
          </AlertMessage>
        </Alert>
      )}
      {isShowFail && (
        <Alert
          dismissible
          onClose={() => {
            setExpanded(false);
            dispatch(postEnrollHardshipReset(loan.loanId));
          }}
          variant="danger"
        >
          <AlertMessage>
            <MaterialIcon className="mr-2" name="error_outline" color={theme.colors.error} />
            {t('account.edit_modal.error_generic')}
          </AlertMessage>
        </Alert>
      )}
      {!isSuccess && !isShowFail && !isLoading && (
        <Body>
          <HardshipButton
            isExpanded={isExpanded}
            isShowExceeded={isExceededError}
            isHardshipAllowed={isEligible && !isExceededError}
            onClick={setExpanded}
          />
          {isExpanded && (
            <HardshipPanel
              loanId={loan.loanId}
              onCancel={onCancel}
              onConfirm={onConfirm}
              requiredPayment={requiredPayment}
              style={{ marginTop: '17px' }}
            />
          )}
        </Body>
      )}
    </>
  );
};

LoanHardship.propTypes = {
  loan: PropTypes.shape({
    currentPayoffBalance: PropTypes.number,
    loanId: PropTypes.string,
    hardshipEligibility: PropTypes.shape({
      minimumPaymentAmount: PropTypes.number,
      status: PropTypes.oneOf(Object.values(HARDSHIP_CODES)),
    }),
  }).isRequired,
};

export default LoanHardship;
