import { ACTIONS } from 'state/actionTypes';

export * from './delayPayment/actions';

export const getLoans = (accountId) => (dispatch) => {
  dispatch({
    type: ACTIONS.getLoans,
    payload: {
      request: {
        method: 'get',
        url: `/bp/v3/account/${accountId}/loans`,
      },
    },
  });
};
