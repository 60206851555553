import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { delayPaymentSelector } from 'state/loans/delayPayment/selectors';

import DelayPaymentButton from 'views/loans/payments/components/DelayPaymentButton';

import Loader from 'components/Loader';

import { Alert, Body } from 'styles/CommonStyles';

const StyledAlert = styled(Alert)`
  margin-bottom: 0;
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: center;

  padding: 1.5rem 0;
`;

const DelayPaymentStatus = memo(({ setDelayPaymentState, isDelayPaymentAllowed }) => {
  const { t } = useTranslation();
  const { error, loading, success } = useSelector(delayPaymentSelector);

  let result = null;
  if (loading) {
    return <Loader />;
  }
  if (success) {
    result = (
      <StyledAlert data-testid="success" variant="success">
        <b>{t('loans.payment.delay.payment_delayed')}</b>
        <br />
        {t('loans.payment.delay.update_reflected_above')}
      </StyledAlert>
    );
  } else if (error) {
    result = (
      <StyledAlert data-testid="error" variant="warning">
        <b>{t('loans.payment.delay.error_delaying_payment')}</b>
      </StyledAlert>
    );
  } else {
    return null;
  }

  return (
    <>
      <Body>
        {result}
        <StyledBody>
          <DelayPaymentButton setDelayPaymentState={setDelayPaymentState} isDelayPaymentAllowed={isDelayPaymentAllowed} />
        </StyledBody>
      </Body>
    </>
  );
});

DelayPaymentStatus.propTypes = {
  setDelayPaymentState: PropTypes.func.isRequired,
  isDelayPaymentAllowed: PropTypes.bool.isRequired,
};

export default DelayPaymentStatus;
