import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useAutoFocus from 'hooks/useAutoFocus';

import { ErrorMessage, FormInput, Input } from 'styles/AuthStyles';

const SignInSsn = memo((props) => {
  const { showError, ssn, onSsnChange } = props;
  const { t } = useTranslation();
  const inputRef = useAutoFocus();

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('authentication.errors.ssn_last4')}</ErrorMessage>}
        <Input
          ref={inputRef}
          type="tel"
          name="ssn"
          aria-label="last 4 digits of ssn"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={t('authentication.sign_in.placeholders.ssn')}
          onChange={onSsnChange}
          value={ssn}
          required
        />
      </FormInput>
    </>
  );
});

SignInSsn.displayName = 'SignInSsn';

SignInSsn.propTypes = {
  ssn: PropTypes.string,
  onSsnChange: PropTypes.func.isRequired,
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

SignInSsn.defaultProps = {
  ssn: null,
  showError: false,
};

export default SignInSsn;
