import { ACTIONS } from 'state/actionTypes';

import { accountIdSelector } from 'state/account/selectors';
import { getPaymentMethods } from 'state/paymentMethods/actions';

export const deletePaymentMethod = (paymentMethodId) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const result = await dispatch({
    type: ACTIONS.deletePaymentMethod,
    payload: {
      request: {
        method: 'delete',
        url: `/bp/v3/account/${accountId}/paymentMethods/${paymentMethodId}`,
      },
    },
  });

  await dispatch(getPaymentMethods(accountId));

  return result;
};

export const deletedPaymentMethod = (paymentId, paymentType, accountMask) => {
  return {
    type: ACTIONS.deletedPaymentMethod,
    payload: { paymentType, paymentId, accountMask },
  };
};
