import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';
import { toKebabCase } from 'lib/utils';

import PanelHeader from 'components/PanelHeader';
import EditAccountModal from 'views/account/details/components/EditAccountModal';
import EditEmailForm from 'views/account/details/components/EditEmailForm';
import EditMobileForm from 'views/account/details/components/EditMobileForm';
import EditAddressForm from 'views/account/details/components/EditAddressForm';

import { formatPhoneNumber } from 'lib/Formatters';

import { MaterialIcon } from 'styles/Icons';
import { Alert, Body, IconDisc, IconColor, IconSize, IconWrapper, Panel } from 'styles/CommonStyles';
import theme from 'styles/Theme';

import { updatePrimaryContact } from 'state/account/actions';
import {
  postAccountUpdateInitialize,
  postAccountUpdateVerify,
  resetAccountUpdate,
  resetAccountUpdateVerify,
} from 'state/updateAccount/actions';
import { accountUpdateInitializeSelector, accountUpdateVerifySelector } from 'state/updateAccount/selectors';
import { ACTIONS } from 'state/actionTypes';
import { logout } from 'state/auth/actions';

const EDIT_TYPES = {
  address: 'address',
  email: 'email',
  mobile: 'mobile',
  phone: 'phone',
};

const UserDetails = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  font-size: 0.9rem;
  font-weight: 400;

  padding: 1rem 0;
`;
const Detail = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

const EditableUserField = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  ${IconWrapper} {
    margin-left: 0.5rem;
  }
`;

const UserData = styled.div`
  /* color: ${(props) => props.theme.colors.upliftTeal}; */
`;

const Address = styled.div`
  text-align: right;
`;

const AccountDetailsView = memo(({ accountDetails }) => {
  const { accountId, primaryContact } = accountDetails;
  const { givenName, lastName, email, mobileNumber, streetAddress, streetAddress2, city, region, postalCode } = primaryContact;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: initializeData } = useSelector(accountUpdateInitializeSelector);
  const { data: verifyData } = useSelector(accountUpdateVerifySelector);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editType, setEditType] = useState('');
  const [show, setShow] = useState(true);

  useEffect(() => {
    dispatch(resetAccountUpdate());
  }, [dispatch]);

  const updateAccountDetails = useCallback(
    async (data) => {
      if (editType === EDIT_TYPES.phone) {
        const normalizedNumber = data.mobile?.replaceAll(/[^\d]/g, '');
        await dispatch(postAccountUpdateInitialize(accountId, 'phone', { phone_number: normalizedNumber }));
        setShow(true);
      }

      if (editType === EDIT_TYPES.address) {
        const sanitizedData = {
          ...data,
          street_address2: data.street_address2 ? data.street_address2 : null,
          postal_code: data.postal_code.toUpperCase().replace(/ /g, ''),
        };
        await dispatch(postAccountUpdateInitialize(accountId, 'address', sanitizedData));
      }

      if (editType === EDIT_TYPES.email) {
        await dispatch(postAccountUpdateInitialize(accountId, 'email', { email_address: data.email }));
        setShow(true);
      }

      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'initialize-edit-account',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata.editType = editType;
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
    },
    [accountId, dispatch, editType]
  );

  const verifyAccountUpdate = useCallback(
    async (data) => {
      dispatch(resetAccountUpdateVerify());
      const resultAction = await dispatch(postAccountUpdateVerify(accountId, initializeData?.sessionId, data.verifyCode));
      setShow(true);
      if (resultAction.type !== ACTIONS.postAccountUpdateVerifyFail) {
        setIsEditModalOpen(false);

        const newContactInfo = resultAction?.payload?.data?.newContact;
        if (newContactInfo) {
          const { cell, ...rest } = newContactInfo;
          const updatedPrimaryContact = { ...rest };
          if (cell) {
            updatedPrimaryContact.mobileNumber = cell;
          }
          dispatch(updatePrimaryContact(updatedPrimaryContact));
        }
        if (editType === EDIT_TYPES.phone) {
          dispatch(logout('mobile_update'));
        }
      }

      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'verify-edit-account',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata.editType = editType;
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
    },
    [dispatch, accountId, initializeData, editType]
  );

  const openEditModal = (type) => {
    setEditType(type);
    setIsEditModalOpen(true);

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'open-edit-account-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata.editType = type;
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'close-edit-account-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  return (
    <Panel>
      {verifyData && show && (
        <Alert variant="success" onClose={() => setShow(false)} dismissible>
          {t('account.edit_modal.account_update_success')}
        </Alert>
      )}

      <PanelHeader
        panelId="account"
        glyph={
          <IconDisc>
            <MaterialIcon name="account_circle" theme="outlined" color={IconColor} size={IconSize} />
          </IconDisc>
        }
        labelLeft={t('account.details.profile')}
        contentLeft={`${givenName} ${lastName}`}
        labelRight={t('account.details.account_id')}
        contentRight={accountId}
      />

      <Body>
        <UserDetails>
          <Detail>
            <div id="email">{t('common.label.email')}</div>
            <EditableUserField>
              <UserData aria-labelledby="email">{email}</UserData>
              <IconWrapper onClick={() => openEditModal(EDIT_TYPES.email)}>
                <MaterialIcon color={theme.colors.upliftTeal} name="edit" />
              </IconWrapper>
            </EditableUserField>
          </Detail>
          <Detail>
            <div id="mobile">{t('account.details.mobile')}</div>
            <EditableUserField>
              <UserData aria-labelledby="mobile">{formatPhoneNumber(mobileNumber)}</UserData>
              <IconWrapper onClick={() => openEditModal(EDIT_TYPES.phone)}>
                <MaterialIcon color={theme.colors.upliftTeal} name="edit" />
              </IconWrapper>
            </EditableUserField>
          </Detail>
          <Detail>
            <div id="address">{t('account.details.address')}</div>
            <Address aria-labelledby="address">
              <EditableUserField>
                <div>
                  <UserData>{streetAddress}</UserData>
                  <UserData>{streetAddress2}</UserData>
                  <UserData>{`${city}, ${region} ${postalCode}`}</UserData>
                </div>
                <IconWrapper onClick={() => openEditModal(EDIT_TYPES.address)}>
                  <MaterialIcon color={theme.colors.upliftTeal} name="edit" />
                </IconWrapper>
              </EditableUserField>
            </Address>
          </Detail>
        </UserDetails>
      </Body>

      {isEditModalOpen && (
        <EditAccountModal
          onClose={() => closeEditModal()}
          onSave={updateAccountDetails}
          onVerify={verifyAccountUpdate}
          show={show}
          setShow={setShow}
          editType={editType}
        >
          {editType === EDIT_TYPES.phone && <EditMobileForm />}
          {editType === EDIT_TYPES.address && <EditAddressForm />}
          {editType === EDIT_TYPES.email && <EditEmailForm />}
        </EditAccountModal>
      )}
    </Panel>
  );
});

AccountDetailsView.displayName = 'AccountDetailsView';

AccountDetailsView.propTypes = {
  accountDetails: PropTypes.shape({
    accountId: PropTypes.string,
    primaryContact: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default AccountDetailsView;
