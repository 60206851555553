/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'styles/CommonStyles';

const Input = styled(Form.Control)`
  /* width: 100%; */
  text-align: center;
`;

export default forwardRef((props, ref) => {
  const onInvalid = useCallback((event) => {
    event.preventDefault();
  }, []);

  return <Input {...props} ref={ref} onInvalid={onInvalid} />;
});
