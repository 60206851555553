import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import CommonModal from 'views/account/paymentMethods/CommonModal';
import CommonPopover from 'components/popovers/CommonPopover';
import OutboundLink from 'analytics/components/OutboundLink';

import { AIRPORTS } from 'lib/constants/airports';

import { MaterialIcon } from 'styles/Icons';
import { StyledTable, StyledTD, StyledTDText, upliftTeal } from 'styles/ModalStyles';

const IfYouMadeChanges = styled.div`
  font-size: 0.8rem;
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  &:hover {
    text-decoration: none !important;
  }
`;

const TravelPartnerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

// prettier-ignore
const PurchaseDetailsModal = memo(({ merchantInfo, closeModal, order, industry }) => { // NOSONAR
  const { t } = useTranslation();

  const { airReservations, cruiseReservations, hotelReservations, travelers, orderLines } = order;

  const formatDate = (date) => {
    return moment(date)
      .utc()
      .format('MM/DD/YYYY');
  };

  const getDetails = () => {
    if (airReservations?.length > 0) {
      const airReservation = airReservations.reduce((a, b) => {
        return a.departureDate < b.departureDate ? a : b;
      });

      const airTravelDate = formatDate(airReservation.departureDate);

      const locationAbbr = airReservation.arrivalAirport;

      const airTripLocation = AIRPORTS[locationAbbr]
        ? `${AIRPORTS[locationAbbr].city}, ${AIRPORTS[locationAbbr].country}`
        : `${t('common.label.na')}`;

      return { travelDate: airTravelDate, travelLocation: airTripLocation };
    }
    if (cruiseReservations?.length > 0) {
      const cruiseReservation = cruiseReservations.reduce((a, b) => {
        return a.embarkDate < b.embarkDate ? a : b;
      });

      const cruiseTravelDate = formatDate(cruiseReservation.embarkDate);

      const cruiseLocation = cruiseReservation.cruiseLine || `${t('common.label.na')}`;

      return { travelDate: cruiseTravelDate, travelLocation: cruiseLocation };
    }
    if (hotelReservations?.length > 0) {
      const hotelReservation = hotelReservations.reduce((a, b) => {
        return a.checkIn < b.checkIn ? a : b;
      });

      const hotelTravelDate = formatDate(hotelReservation.checkIn);

      const hotelLocation = hotelReservation.hotelName || `${t('common.label.na')}`;

      return { travelDate: hotelTravelDate, travelLocation: hotelLocation };
    }
    return { travelDate: `${t('common.label.not_found')}`, travelLocation: `${t('common.label.not_found')}` };
  };

  const { travelDate, travelLocation } = getDetails();

  const today = new Date();
  const todayPlus4Years = moment(today)
    .add(5, 'year')
    .format('MM/DD/YYYY');

  const newTravelDate = moment(new Date(travelDate)).isAfter(new Date(todayPlus4Years)) ? `${t('common.label.na')}` : travelDate;

  const isPackage = (airReservations?.length && 1) + (cruiseReservations?.length && 1) + (hotelReservations?.length && 1) > 1;

  const newTravelLocation = isPackage ? `${merchantInfo.name} ${t('loans.merchant.package')}` : travelLocation;

  const isMultiple = airReservations?.length > 1 || cruiseReservations?.length > 1 || hotelReservations?.length > 1;

  const nonTravelPurchaseDate =
    order?.orderLines && order?.orderLines[0]
      ? moment(new Date(order?.orderLines[0]?.createdDate)).format('L')
      : t('common.label.not_found');
  const nonTravelName = order?.orderLines && order?.orderLines[0] ? orderLines[0].name : null;

  return (
    <>
      {industry === 'travel' ? (
        <CommonModal
          title={t('loans.merchant.purchase_details')}
          body1={
            <>
              <StyledTable responsive bordered>
                <tbody>
                  <tr>
                    <StyledTD>
                      <div className="d-flex">
                        <MaterialIcon name="business" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="partner-name">{t('loans.merchant.partner_name')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText>
                      <TravelPartnerInfo>
                        {!!Object.keys(merchantInfo.contact).length && 
                          <>
                            <OutboundLink
                                eventLabel="merchant-url"
                                to={merchantInfo.url}
                                target="_blank"
                                rel="noopener"
                                style={{ textDecoration: 'none' }}
                              >
                              <div className="d-flex">
                                <div data-testid="merchant-name">{merchantInfo.name}</div>
                                <StyledMaterialIcon name="open_in_new" theme="outlined" style={{ marginRight: '0.2rem' }} />
                              </div>
                            </OutboundLink>
                            <OutboundLink
                              eventLabel="merchant-phone"
                              to={`tel:+${merchantInfo.contact.phone}`}
                              style={{ textDecoration: 'none' }}
                            >
                            {merchantInfo.contact.phone}
                            </OutboundLink>
                          </>
                        }
                        {!Object.keys(merchantInfo.contact).length &&
                          <div className="d-flex align-items-center">
                            <div data-testid="merchant-name">{merchantInfo.name}</div>
                          </div>
                        }
                      </TravelPartnerInfo>
                    </StyledTDText>
                  </tr>
                  <tr>
                    <StyledTD>
                      <div className="d-flex">
                        <MaterialIcon name="today" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="travel-date">{t('loans.merchant.travel_date')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText data-testid="travel-date-value">{newTravelDate}</StyledTDText>
                  </tr>
                  <tr>
                    <StyledTD>
                      <div className="d-flex align-items-center">
                        <MaterialIcon name="location_on" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="trip-location">{t('loans.merchant.trip')}</div>
                        {isMultiple && (
                          <CommonPopover
                            type="trip-details-modal"
                            placement="top"
                            color="#555555"
                            icon="info"
                            textcontent={t('loans.merchant.multiple_flights')}
                            style={{ marginLeft: '0.2rem' }}
                          />
                        )}
                      </div>
                    </StyledTD>
                    <StyledTDText data-testid="trip-location-value">{newTravelLocation}</StyledTDText>
                  </tr>
                  <tr>
                    <StyledTD>
                      <div className="d-flex">
                        <MaterialIcon name="group" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="travelers">{t('loans.merchant.travelers')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText data-testid="travelers-value">
                      {travelers?.length ? (
                        travelers.map((traveler, index) => {
                          return (
                            <div
                              key={`order-traveler-${index.toString()}`}
                            >{`${traveler.firstName} ${traveler.lastName}`}</div>
                          );
                        })
                      ) : (
                        <div>{t('common.label.not_found')}</div>
                      )}
                    </StyledTDText>
                  </tr>
                </tbody>
              </StyledTable>
              <IfYouMadeChanges data-testid="if-you-made-changes">
                {t('loans.merchant.if_you_made_changes_travel')}
              </IfYouMadeChanges>
            </>
          }
          submitEvent2={closeModal}
          button2={t('common.buttons.close')}
          onClose={closeModal}
        />
      ) : (
        <CommonModal
          title={t('loans.merchant.purchase_details')}
          body1={
            <>
              <StyledTable responsive bordered>
                <tbody>
                  <tr>
                    <StyledTD>
                      <div className="d-flex align-items-center">
                        <MaterialIcon name="business" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="partner-name">{t('loans.merchant.partner_name')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText>
                      <TravelPartnerInfo>
                        {!!Object.keys(merchantInfo.contact).length && 
                          <>
                            <OutboundLink
                              eventLabel="merchant-url"
                              to={merchantInfo.url}
                              target="_blank"
                              rel="noopener"
                              style={{ textDecoration: 'none' }}
                            >
                              <div className="d-flex">
                                <div data-testid="merchant-name">{merchantInfo.name}</div>
                                <StyledMaterialIcon name="open_in_new" theme="outlined" style={{ marginRight: '0.2rem' }} />
                              </div>
                            </OutboundLink>
                            <OutboundLink
                              eventLabel="merchant-phone"
                              to={`tel:+${merchantInfo.contact.phone}`}
                              style={{ textDecoration: 'none' }}
                            >
                              {merchantInfo.contact.phone}
                            </OutboundLink>
                          </>
                        }
                        {!Object.keys(merchantInfo.contact).length && 
                          <div className="d-flex align-items-center">
                            <div data-testid="merchant-name">{merchantInfo.name}</div>
                          </div>
                        }
                      </TravelPartnerInfo>
                    </StyledTDText>
                  </tr>
                  <tr>
                    <StyledTD>
                      <div className="d-flex align-items-center">
                        <MaterialIcon name="today" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="purchase-date">{t('loans.merchant.purchase_date')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText data-testid="purchase-date-value">{nonTravelPurchaseDate}</StyledTDText>
                  </tr>
                  { !!nonTravelName && nonTravelName !== merchantInfo.name &&
                    <tr>
                    <StyledTD>
                      <div className="d-flex align-items-center">
                        <MaterialIcon name="shopping_cart" color={upliftTeal} style={{ marginRight: '0.2rem' }} />
                        <div data-testid="purchase-item">{t('loans.merchant.purchased_item')}</div>
                      </div>
                    </StyledTD>
                    <StyledTDText data-testid="purchase-item-value">{nonTravelName}</StyledTDText>
                    </tr>
                  }
                </tbody>
              </StyledTable>
              <IfYouMadeChanges data-testid="if-you-made-changes">
                {t('loans.merchant.if_you_made_changes_non_travel')}
              </IfYouMadeChanges>
            </>
          }
          submitEvent2={closeModal}
          button2={t('common.buttons.close')}
          onClose={closeModal}
        />
      )}
    </>
  );
});

PurchaseDetailsModal.displayName = 'PurchaseDetailsModal';

PurchaseDetailsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  industry: PropTypes.string.isRequired,
  merchantInfo: PropTypes.shape({
    contact: PropTypes.objectOf(PropTypes.string),
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  order: PropTypes.shape({
    airReservations: PropTypes.arrayOf(PropTypes.object),
    cruiseReservations: PropTypes.arrayOf(PropTypes.object),
    hotelReservations: PropTypes.arrayOf(PropTypes.object),
    travelers: PropTypes.arrayOf(PropTypes.object),
    orderLines: PropTypes.arrayOf(PropTypes.object),
  }),
};

PurchaseDetailsModal.defaultProps = {
  order: null,
};

export default PurchaseDetailsModal;
