import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MiniPanel } from 'styles/PaymentMethodStyles';

const StyledPanel = styled(MiniPanel)`
  margin: 0;
  width: 100%;
  ${(props) => props.isModalView && 'border: none'};
  ${(props) => props.isModalView && 'padding: 0'};

  @media screen and (max-width: ${(props) => props.theme.minWidth.micro}) {
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledHeaderItem = styled.div`
  display: inline-block;
  margin-bottom: 1.5rem;

  h6 {
    color: ${(props) => props.theme.colors.upliftLightGrey};
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }
`;

const StyledLoanIdHeadItem = styled(StyledHeaderItem)`
  margin-left: calc(48px + 0.5rem);

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    margin-left: 0;
    text-align: right;
  }

  ${(props) => props.isModalView && 'flex: 1'};
`;

const StyledMerchantInfo = styled.div`
  display: flex;
  ${(props) => props.isModalView && 'flex: 1'};
`;

const MerchantIconFrame = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  min-width: 48px;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.borderGrey};
  background: url(${(props) => props.imageUrl}) #fff center/contain no-repeat;
  background-origin: content-box;
  padding: 3px;
`;

const MerchantName = styled.div`
  font-weight: 600;
`;

const StyledFrame = styled.iframe`
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  border-radius: 8px;
  height: 25rem;
  width: 100%;

  border: 1px solid ${(props) => props.theme.colors.borderGrey};

  @media screen and (max-height: ${(props) => props.theme.minWidth.micro}) {
    height: 16rem;
  }
`;

const AutoPayDocument = ({ documentUrl, loanId, merchantInfo, isModalView }) => {
  const { t } = useTranslation();

  return (
    <StyledPanel showCard isModalView={isModalView}>
      <StyledHeader>
        <StyledMerchantInfo isModalView={isModalView}>
          <MerchantIconFrame imageUrl={merchantInfo?.logoUrl} />
          <StyledHeaderItem>
            <h6>{t('loans.header.purchased_from')}</h6>
            <MerchantName data-testid="auto-pay-document-merchant-name">{merchantInfo?.name ?? '-'}</MerchantName>
          </StyledHeaderItem>
        </StyledMerchantInfo>
        <StyledLoanIdHeadItem isModalView={isModalView}>
          <h6>{t('loans.header.loan_id')}</h6>
          <span data-testid="auto-pay-document-loan-id">{loanId}</span>
        </StyledLoanIdHeadItem>
      </StyledHeader>
      <StyledFrame
        data-testid="auto-pay-document-iframe"
        title={t('documents.autopay')}
        isModalView={isModalView}
        src={documentUrl}
      />
    </StyledPanel>
  );
};

AutoPayDocument.propTypes = {
  documentUrl: PropTypes.string.isRequired,
  loanId: PropTypes.string,
  merchantInfo: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  isModalView: PropTypes.bool,
};

AutoPayDocument.defaultProps = {
  loanId: '-',
  merchantInfo: null,
  isModalView: 'false',
};

export default AutoPayDocument;
