import React from 'react';
import PropTypes from 'prop-types';

import { noOp } from 'lib/utils';
import PaymentMethodOption from 'views/account/wallet/components/PaymentMethodOption';

const PaymentMethodSelect = ({ name, onChange, paymentMethods, value }) => {
  return (
    <div>
      {paymentMethods.map((paymentMethod) => (
        <PaymentMethodOption
          key={paymentMethod.paymentMethodId}
          name={name}
          onClick={onChange}
          paymentMethod={{
            ...paymentMethod,
            defaultPaymentMethod: false,
          }}
          selected={value !== undefined ? value === paymentMethod.paymentMethodId : undefined}
        />
      ))}
    </div>
  );
};

PaymentMethodSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
};

PaymentMethodSelect.defaultProps = {
  onChange: noOp,
  paymentMethods: [],
  value: undefined,
};

export default PaymentMethodSelect;
