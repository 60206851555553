import { useEffect } from 'react';
import ReactGA from 'react-ga';

const options = {};

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options,
  });
  ReactGA.pageview(page);
};

const useTracker = ({ pathname }) => {
  useEffect(() => trackPage(pathname), [pathname]);
};

export default useTracker;
