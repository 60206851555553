/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { IconWrapper } from 'styles/CommonStyles';

import { MaterialIcon } from 'styles/Icons';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const TooltipTrigger = styled.div`
  color: ${(props) => (props.color ? props.color : 'black')};
  text-decoration: underline;
`;

const RenderPopover = forwardRef((props, ref) => {
  const style = {
    ...props.style,
    minWidth: '12%',
    // minWidth: '26%',
    // zIndex: '2',
  };

  return (
    <Popover id="CommonPopover" {...props} ref={ref} show="" style={style}>
      <Popover.Content>{props.textcontent}</Popover.Content>
    </Popover>
  );
});

const iconSize = '1.2rem';

const CommonPopover = memo(({ type, placement, textcontent, color, icon, noIcon, style }) => {
  const renderOverlay = useCallback(
    (props) => {
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'hover',
        label: `${type}-popover`,
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      return <RenderPopover {...props} textcontent={textcontent} />;
    },
    [textcontent, type]
  );

  if (noIcon) {
    return (
      <OverlayTrigger trigger="hover" placement={placement} delay={{ show: 250, hide: 400 }} rootClose overlay={renderOverlay}>
        <TooltipTrigger color={color}>{noIcon}</TooltipTrigger>
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger trigger="hover" placement={placement} delay={{ show: 250, hide: 400 }} rootClose overlay={renderOverlay}>
      <IconWrapper>
        <MaterialIcon name={icon} size={iconSize} color={color} style={style} />
      </IconWrapper>
    </OverlayTrigger>
  );
});

CommonPopover.displayName = 'CommonPopover';

CommonPopover.propTypes = {
  color: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  noIcon: PropTypes.string,
  style: PropTypes.shape({ position: PropTypes.string }),
  textcontent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({ type: PropTypes.symbol })]).isRequired,
};

CommonPopover.defaultProps = {
  style: null,
  noIcon: null,
};

RenderPopover.propTypes = {
  style: PropTypes.shape({ position: PropTypes.string }).isRequired,
  textcontent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({ type: PropTypes.symbol })]).isRequired,
};

export default CommonPopover;
