import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { adminSignIn } from 'state/adminAuth/actions';

import AdminForm from './AdminForm';

const AdminContainer = memo(() => {
  const dispatch = useDispatch();

  const onSignIn = useCallback(
    (event) => {
      event.preventDefault();

      dispatch(adminSignIn());
    },
    [dispatch]
  );

  return <AdminForm signIn={onSignIn} />;
});

export default AdminContainer;
