import React, { memo, useState } from 'react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { Button, Col, Row } from 'styles/CommonStyles';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { MaterialIcon } from 'styles/Icons';

const StyledInputGroup = styled(InputGroup)`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:focus-within {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

const StyledInputGroupText = styled(InputGroup.Text)`
  background: #fff;
  border: none;
`;

const StyledFormControl = styled(FormControl)`
  border: none;
  font-size: 0.88rem;
  &:focus {
    box-shadow: none;
  }
`;

const SearchPreview = styled.div`
  position: absolute;
  background: #fff;
  width: 20rem;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.158381);

  padding: 0.5rem 0;
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin-left: 7rem;
    width: 23rem;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    width: 33rem;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    width: 27rem;
  }
`;

const SearchItem = styled.div`
  padding: 0.25rem 1.5rem;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.borderLightGrey};
  }
`;

const Category = styled.div`
  font-size: 0.8rem;
`;

const ResultCount = styled.div`
  font-size: 0.7rem;
`;

const Search = memo(({ showResults, faqs, toggleSearchView }) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const placeholder = t('faqs.searchpage.search_placeholder');
  const [currentQuery, setCurrentQuery] = useState(placeholder);

  const options = {
    includeScore: true,
    includeMatches: true,
    minMatchCharLength: 2,
    keys: ['category', 'question', 'answers', 'subAnswers'],
  };

  const fuse = new Fuse(faqs, options);

  const makeAPaymentResult = fuse.search('make a payment');

  const [searchResults, setSearchResults] = useState(makeAPaymentResult);

  const searchWithFuse = (query) => {
    if (!query) {
      return makeAPaymentResult;
    }
    return fuse.search(query);
  };

  // multiple item search clicked
  const updateQuery = () => {
    toggleSearchView(currentQuery);
    showResults(searchResults);

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'search-multiple-questions',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata = toKebabCase(metadata);

    metadata.query = currentQuery;

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  const SearchResults = ({ results }) => {
    if (!results) {
      return null;
    }

    // single item search clicked
    const onSearchItemClick = (result) => {
      setCurrentQuery(result.item.question);
      toggleSearchView(result.item.question);
      showResults([result]);

      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'search-single-question',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.value = 1;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata = toKebabCase(metadata);

      metadata.question = result.item.question;

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
    };

    const resultsText =
      results.length === 1
        ? t('faqs.searchpage.result', { count: results.length })
        : t('faqs.searchpage.multiple_results', { count: results.length });

    return (
      <>
        <SearchItem className="d-flex align-items-top" onMouseDown={updateQuery}>
          <MaterialIcon name="search" style={{ marginTop: '3px' }} />
          <div className="d-flex flex-column">
            <div>&quot;{currentQuery || t('faqs.searchpage.search_placeholder')}&quot;</div>
            <ResultCount data-testid="results-count">{resultsText}</ResultCount>
          </div>
        </SearchItem>

        {results.slice(0, 3).map((result) => (
          <SearchItem data-testid="click-search-item" key={result.item.question} onMouseDown={() => onSearchItemClick(result)}>
            <div>{result.item.question}</div>
            <Category>{result.item.category}</Category>
          </SearchItem>
        ))}
      </>
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    const query = event.target.value;
    setCurrentQuery(query);
    setSearchResults(searchWithFuse(query));

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'keypress',
      label: 'user-search-input',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata = toKebabCase(metadata);

    metadata.query = query;

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };
  SearchResults.propTypes = {
    results: PropTypes.arrayOf(PropTypes.shape({ category: PropTypes.string })),
  };

  SearchResults.defaultProps = {
    results: null,
  };

  return (
    <Form>
      <Row className="align-items-center justify-content-center">
        <Col md={8} style={{ zIndex: '1' }}>
          <StyledInputGroup
            data-testid="input-field"
            className="flex"
            onChange={handleChange}
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setShowDropdown(false)}
          >
            <StyledInputGroupText>
              <MaterialIcon name="search" />
            </StyledInputGroupText>
            <StyledFormControl
              data-testid="search-input-box"
              type="search"
              id="query"
              placeholder={t('faqs.searchpage.search_placeholder')}
              autoComplete="off"
            />
            <span className="d-flex">
              <Button
                data-testid="search-button"
                className="text-uppercase"
                style={{ borderRadius: '4px' }}
                type="submit"
                onClick={updateQuery}
              >
                {t('faqs.searchpage.search')}
              </Button>
            </span>
          </StyledInputGroup>
        </Col>
      </Row>

      {showDropdown && (
        <Row data-testid="search-item" className="align-items-center justify-content-center" style={{ marginTop: '1px' }}>
          <Col style={{ zIndex: '2' }}>
            <SearchPreview data-testid="dropdown">
              <SearchResults handleClick={updateQuery} results={searchResults} />
            </SearchPreview>
          </Col>
        </Row>
      )}
    </Form>
  );
});

Search.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({ category: PropTypes.string })),
  toggleSearchView: PropTypes.func.isRequired,
  showResults: PropTypes.func.isRequired,
};

Search.defaultProps = {
  faqs: null,
};

export default Search;
