import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { authSelector } from 'state/auth/selectors';

import Loader from 'components/Loader';

import { AuthForm, FormInputs, Label, SignInButton, TransitionPanel } from 'styles/AuthStyles';

import SignInCountry from './components/SignInCountry';
import SignInDob from './components/SignInDob';
import SignInPhone from './components/SignInPhone';
import SignInSsn from './components/SignInSsn';

const SignInForm = memo((props) => {
  const { country, dob, ssn, onCountryChange, onDobChange, onSsnChange, onUsernameChange, signIn, username } = props;
  const { t } = useTranslation();

  const {
    loading,
    phoneError,
    countryRequired,
    countryError,
    dobRequired,
    dobError,
    dupError,
    ssnRequired,
    ssnError,
  } = useSelector(authSelector);

  return (
    <>
      <Label>{t('authentication.sign_in.sign_in')}</Label>
      <AuthForm autoComplete="on" noValidate onSubmit={signIn}>
        <FormInputs>
          <SignInPhone username={username} onUsernameChange={onUsernameChange} showError={phoneError} dupError={dupError} />

          {dobRequired && <SignInDob dob={dob} onDobChange={onDobChange} showError={dobError} />}

          {ssnRequired && <SignInSsn ssn={ssn} onSsnChange={onSsnChange} showError={ssnError} />}

          {countryRequired && <SignInCountry country={country} onCountryChange={onCountryChange} showError={countryError} />}
        </FormInputs>

        {loading ? (
          <TransitionPanel>
            <Loader />
          </TransitionPanel>
        ) : (
          <div className="d-flex justify-content-center">
            <SignInButton type="submit" className="btn-block">
              {t('authentication.sign_in.sign_in')}
            </SignInButton>
          </div>
        )}
      </AuthForm>
    </>
  );
});

SignInForm.displayName = 'SignInForm';

SignInForm.propTypes = {
  country: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  ssn: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onDobChange: PropTypes.func.isRequired,
  onSsnChange: PropTypes.func.isRequired,
  onUsernameChange: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default SignInForm;
