import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

export const boostReducer = (state = {}, action) => {
  // NOSONAR
  switch (action.type) {
    case ACTIONS.getBoostInfo:
      return {
        loading: true,
      };
    case ACTIONS.getBoostInfoSuccess:
      // eslint-disable-next-line no-case-declarations
      const boostEnabled = action.payload.data.status === 'enabled';
      return {
        loading: false,
        boostEnabled,
        interestFreeAvailable: boostEnabled ? action.payload.data.interest_free_terms.length > 0 : false,
        approvalAmount: action.payload.data.card_limit_major_units,
      };
    case ACTIONS.getBoostInfoFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

export default boostReducer;
