import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useAutoFocus from 'hooks/useAutoFocus';

import { ErrorMessage, FormInput, Input } from 'styles/AuthStyles';

const VerifyCode = memo((props) => {
  const { showError, onAnswerChange } = props;
  const { t } = useTranslation();
  const inputRef = useAutoFocus();

  return (
    <>
      <FormInput>
        {showError && <ErrorMessage>{t('authentication.errors.invalid_code')}</ErrorMessage>}
        <Input
          ref={inputRef}
          type="tel"
          name="answer"
          aria-label="verify code"
          pattern="[0-9]{5,6}"
          maxLength={6}
          placeholder={t('authentication.verify.placeholder')}
          onChange={onAnswerChange}
          required
        />
      </FormInput>
    </>
  );
});

VerifyCode.displayName = 'VerifyCode';

VerifyCode.propTypes = {
  onAnswerChange: PropTypes.func.isRequired,
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

VerifyCode.defaultProps = {
  showError: false,
};

export default VerifyCode;
