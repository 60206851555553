import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { makePaymentSelector } from 'state/loans/selectors';
import { postSchedulePaymentScheduleSelector } from 'state/loans/makeScheduledPayments/selectors';

import MakePaymentOrScheduleButton from 'views/loans/payments/components/MakePaymentOrScheduleButton';

import Loader from 'components/Loader';

import { Alert, Body } from 'styles/CommonStyles';

const AlertMessage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 0;
`;

const StyledBody = styled(Body)`
  display: flex;
  justify-content: center;
`;

const MakeOrSchedulePaymentStatus = memo(({ setPaymentFormState, isSchedulePayment }) => {
  const { t } = useTranslation();

  const { data, loading: makePaymentLoading, error: makePaymentError, success: makePaymentSuccess, refreshing } = useSelector(
    makePaymentSelector
  );
  const { loading: schedulePaymentLoading, error: schedulePaymentError, success: schedulePaymentSuccess } = useSelector(
    postSchedulePaymentScheduleSelector
  );

  const error = makePaymentError || schedulePaymentError;
  const loading = makePaymentLoading || schedulePaymentLoading || refreshing;
  const success = makePaymentSuccess || schedulePaymentSuccess;

  let result = null;
  if (loading) {
    return (
      <Body>
        <Loader />
      </Body>
    );
  }

  // eslint-disable-next-line camelcase
  if (data?.payment?.provider_response_code === 'pending' || (makePaymentError && makePaymentError.status === 504)) {
    result = (
      <StyledAlert data-testid="payment-pending" variant="success">
        <>
          <strong>{t('loans.payment.form.status.payment_received')}</strong>
          <br />
          {t('loans.payment.form.status.payment_processing')}
        </>
      </StyledAlert>
    );
  } else if (success) {
    result = (
      <StyledAlert data-testid="success" variant="success">
        {schedulePaymentSuccess ? (
          <>
            <strong>{t('loans.payment.schedule_payment.status.successfully_scheduled')}</strong>
            <br />
            {t('loans.payment.schedule_payment.status.onetime_scheduled')}
          </>
        ) : (
          <>
            <strong>{t('loans.payment.form.status.payment_received')}</strong>
            <br />
            {t('loans.payment.form.status.one_day_reflect')}
          </>
        )}
      </StyledAlert>
    );
  } else if (error?.data?.status === 'error') {
    result = (
      <StyledAlert data-testid="payment-declined" variant="danger">
        <strong>{t('loans.payment.form.status.payment_declined')}</strong>
      </StyledAlert>
    );
  } else if (error) {
    result = (
      <StyledAlert data-testid="error" variant="danger">
        <strong>{t('loans.payment.form.status.payment_error')}</strong>
      </StyledAlert>
    );
  } else {
    return null;
  }

  return (
    <>
      <Body>
        <AlertMessage>{result}</AlertMessage>
      </Body>
      <StyledBody>
        <MakePaymentOrScheduleButton setPaymentFormState={setPaymentFormState} isSchedulePayment={isSchedulePayment} />
      </StyledBody>
    </>
  );
});

MakeOrSchedulePaymentStatus.displayName = 'MakeOrSchedulePaymentStatus';

MakeOrSchedulePaymentStatus.propTypes = {
  setPaymentFormState: PropTypes.func.isRequired,
  isSchedulePayment: PropTypes.bool,
};

MakeOrSchedulePaymentStatus.defaultProps = {
  isSchedulePayment: null,
};

export default MakeOrSchedulePaymentStatus;
