/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import secureforms from 'secureForms';

import styled from 'styled-components';

import { ErrorMessage } from 'styles/CommonStyles';

export const StyledInputDiv = styled.div`
  display: block;
  width: 100%;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: ${(props) => (props.focus ? (props.error ? '#dc3545' : '#80bdff') : 'none')};
  color: red;

  box-shadow: ${(props) => (props.focus ? props.boxShadowCss : 'none')};

  &:focus {
    outline: 0;
  }
`;

const SecureFormInput = memo((props) => {
  const { register, field, error, errorMessage, triggerValidation } = props;
  const { t } = useTranslation();

  register(
    { name: props.id },
    {
      // eslint-disable-next-line no-unused-vars
      validate: (value) => {
        const isFieldValid = secureforms.isValid('card', field);

        if (isFieldValid) {
          // complete and valid
          return true;
        }
        // fall back error
        return 'secureFormError';
      },
    }
  );

  const [focus, setFocus] = useState(false);
  const onBlur = useCallback(() => {
    // timing issue because if you do triggerValidation in the onBlur, it disturbs the state of the form, preventing the submit handler from firing.
    setTimeout(() => {
      triggerValidation({ name: props.id });
    }, 300);
    setFocus(false);
  }, [props.id, triggerValidation]);
  const onFocus = useCallback(() => {
    triggerValidation({ name: props.id });
    setFocus(true);
  }, [props.id, triggerValidation]);

  const boxShadowCss = `0 0 0 0.2rem ${error ? 'rgba(220, 53, 69, 0.25)' : 'rgba(0, 123, 255, 0.25)'}`;

  return (
    <>
      <StyledInputDiv focus={focus} onBlur={onBlur} onFocus={onFocus} boxShadowCss={boxShadowCss} {...props} />
      {error && <ErrorMessage>{t(errorMessage)}</ErrorMessage>}
    </>
  );
});

SecureFormInput.displayName = 'SecureFormInput';

SecureFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  error: PropTypes.shape({
    cardError: PropTypes.objectOf(PropTypes.string),
  }),
  errorMessage: PropTypes.string,
  triggerValidation: PropTypes.func.isRequired,
};

SecureFormInput.defaultProps = {
  error: null,
  errorMessage: null,
};

export default SecureFormInput;
