/* eslint-disable import/prefer-default-export */
export const FFAM_PHONE_NUMBERS = {
  CA: '888-200-0506',
  US: '800-542-8714',
};

export const UPLIFT_PHONE_NUMBERS = {
  customerService: '(844) 257-5400',
  customerServiceHref: 'tel:+1-844-257-5400',
};

export const UPLIFT_CONTACT_INFO = {
  upliftSignature: 'Uplift',
  mailingAddressUS: '2 N. Central Ave. 10th Floor, Phoenix, AZ 85004',
  mailingAddressCA: '410 Adelaide Street West, Suite 220 Toronto, Ontario M5V1S8.',
  supportEmail: 'customercare@upgrade.com',
};

export const CREDIT_CORP_CONTACT_INFO = {
  company: 'Credit Corp Solutions',
  shortName: 'Credit Corp',
  url: 'creditcorponline.com',
  phoneHref: 'tel:+1-800-483-2361',
  phoneNumber: '(800) 483-2361',
  urlHref: 'https://creditcorponline.com',
};

export const FPVII_LLC_CONTACT_INFO = {
  company: 'First Portfolio Ventures II, LLC',
  shortName: 'First Portfolio Ventures II',
  phoneHref: 'tel:+1-866-867-1133',
  phoneNumber: '(866) 867-1133',
  urlHref: 'mailto:AccountInquiries@1fam.com',
  url: 'AccountInquiries@1fam.com',
};

export const TRUE_ACCORD_INFO = {
  email: 'support@trueaccord.com ',
  phone: '(866) 611-2731',
  phoneHref: 'tel:+1-866-611-2731',
  url: 'https://www.trueaccord.com/app/pages/resolution',
};

// TODO HA: need to get actual contact info, verify this is right contact info
export const JEFFERSON_CAPITAL_CONTACT_INFO = {
  company: 'Jefferson Capital Systems, LLC',
  shortName: 'Jefferson Capital',
  url: 'myjcap.com',
  phoneHref: 'tel:+1-833-851-5552',
  phoneNumber: '833-851-5552',
  urlHref: 'https://myjcap.com/',
};

export const loanSaleCompanies = [
  {
    lpbsServicerName: 'CreditCorp',
    info: CREDIT_CORP_CONTACT_INFO,
  },
  { lpbsServicerName: 'FPVII (Sold Debt)', info: FPVII_LLC_CONTACT_INFO },
  { lpbsServicerName: 'Jefferson Capital', info: JEFFERSON_CAPITAL_CONTACT_INFO },
];
