/* eslint-disable no-console */
import { Auth, Hub } from 'aws-amplify';
import { ACTIONS } from 'state/actionTypes';

import history from '../../History';

import { userSelector } from './selectors';

const MAXIMUM_NUMBER_OF_RETRIES = 5;

const stripDashes = (phoneNumber) => {
  const re = /[-]/g;
  return phoneNumber.replace(re, '');
};

export const loginDismissErrors = (name) => (dispatch, getState) => {
  const state = getState().authenticationReducer;
  if (state[name]) {
    dispatch({
      type: ACTIONS.loginDismissErrors,
      payload: { name },
    });
  }
};

export const signUpStartOver = () => (dispatch) => {
  dispatch({
    type: ACTIONS.signUpStartOver,
  });

  history.replace('/signin');
};

export const signUpFormFail = (payload) => ({
  type: ACTIONS.signUpFail,
  payload,
  error: { code: 'FormValidationError' },
});

// eslint-disable-next-line no-unused-vars
export const signIn = (sanitizedUsername) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.signIn,
    payload: { username: sanitizedUsername },
  });

  try {
    const user = await Auth.signIn(sanitizedUsername);
    if (!user.signInUserSession) {
      // Initalize user in redux
      dispatch({ type: ACTIONS.signInChallenge, payload: user });
      history.push('/signin/verify');
    } else {
      dispatch({ type: ACTIONS.signInSuccess, payload: user });
      history.push('/loans');
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.signInFail,
      error: err,
    });
  }
};

export const signUp = ({ username, country, dob, ssn }) => async (dispatch, getState) => {
  const state = getState().authenticationReducer;
  const phoneNumber = stripDashes(username);
  if (state.challengeAnswerCount >= MAXIMUM_NUMBER_OF_RETRIES) {
    return;
  }

  if (phoneNumber.length !== 10) {
    const error = new Error('Phone number is invalid');
    error.code = 'PhoneValidationError';
    dispatch({
      type: ACTIONS.signUpFail,
      error,
    });
    return;
  }

  const attributes = {
    phone_number: phoneNumber,
  };

  if (state.countryRequired) {
    attributes.country = country;
  }
  if (state.dobRequired) {
    attributes.date_of_birth = dob;
  }
  if (state.ssnRequired) {
    attributes.ssn = ssn;
  }

  await new Promise((resolve) => window.grecaptcha.enterprise.ready(resolve));
  const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'LOGIN' });
  attributes.token = token;

  let userHashDigest;
  try {
    const result = await dispatch({
      type: ACTIONS.ensureUser,
      payload: {
        request: {
          method: 'post',
          url: `/ensure_user`,
          data: attributes,
        },
      },
    });
    if (result.type === ACTIONS.ensureUserSuccess) {
      userHashDigest = result.payload.data.username;
      const missingAttribute = result.payload.data.missing_attribute;
      if (!userHashDigest) {
        dispatch({
          type: ACTIONS.signUpFail,
          error: { code: 'MissingAttributes', message: missingAttribute },
        });
        return;
      }
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.ensureUserFail,
      error: err,
    });
  }
  if (userHashDigest) {
    dispatch(signIn(userHashDigest));
  }
};

// eslint-disable-next-line no-unused-vars
export const logout = (source = null) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.logout, payload: source });

  try {
    await Auth.signOut({ global: true });
    dispatch({ type: ACTIONS.logoutSuccess });
  } catch (err) {
    console.error('logout: Auth.signOut(): Error: ', err);
    dispatch({ type: ACTIONS.logoutFail });
  }
};

// eslint-disable-next-line no-unused-vars
export const initializeUser = () => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.logoutInitial });

  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    dispatch({ type: ACTIONS.signInSuccess, payload: user });
    Hub.dispatch('uplift', { event: 'userInitialized' });
  } catch (err) {
    // 'initializeUser: Auth.currentAuthenticatedUser(): Error: ', err
    dispatch({ type: ACTIONS.logoutInitialSuccess });
  }
};

export const customChallengeReset = () => (dispatch, getState) => {
  const state = getState().authenticationReducer;
  if (state.error) {
    dispatch({
      type: ACTIONS.signInChallengeAnswerReset,
    });
  }
};

export const signInChallengeAnswerFormFail = (payload) => ({
  type: ACTIONS.signInChallengeAnswerFail,
  payload,
  error: { code: 'FormValidationError' },
});

export const customChallengeAnswer = (answer) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.signInChallengeAnswer,
  });

  const user = userSelector(getState());

  try {
    const authenticatedUser = await Auth.sendCustomChallengeAnswer(user, answer);
    if (!authenticatedUser.signInUserSession) {
      console.log('customChallengeAnswer: Verification code error: ', authenticatedUser);
      dispatch({
        type: ACTIONS.signInChallengeAnswerFail,
        error: 'Invalid Verification Code',
      });
    } else {
      console.log('customChallengeAnswer: User authenticated: ', authenticatedUser);
      dispatch({ type: ACTIONS.signInChallengeAnswerSuccess, payload: authenticatedUser });
      history.push('/loans');
    }
  } catch (err) {
    console.error('customChallengeAnswer: Error: ', err);
    dispatch({
      type: ACTIONS.signInChallengeAnswerFail,
      error: err,
    });
  }
};
