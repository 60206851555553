import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MerchantName = styled.div`
  padding-right: 24px;
`;

const LoansList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 24px;

  list-style-type: none;
  margin: 0;
`;

const MerchantLoansGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;

  & > * {
    border-bottom: 1px solid ${(props) => props.theme.colors.borderGrey};
    padding-bottom: 16px;
    padding-top: 16px;
  }

  & > *:nth-last-child(-n + 2) {
    border-bottom: none;
  }
`;

const MerchantRow = memo((props) => {
  const { merchantName, loans } = props;
  return (
    <>
      <MerchantName>{merchantName}</MerchantName>
      <LoansList>
        {loans.map((loanId) => (
          <li key={loanId}>
            <NavLink to={{ pathname: '/loans', hash: `#${loanId}` }} key={loanId}>
              {loanId}
            </NavLink>
          </li>
        ))}
      </LoansList>
    </>
  );
});

MerchantRow.displayName = 'MerchantRow';
MerchantRow.propTypes = {
  merchantName: PropTypes.string,
  loans: PropTypes.arrayOf(PropTypes.string),
};
MerchantRow.defaultProps = {
  merchantName: null,
  loans: [],
};

const MerchantLoans = ({ merchantLoansMap }) => {
  const merchants = Object.keys(merchantLoansMap).sort();

  return (
    <MerchantLoansGrid>
      {merchants.map((merchant) => {
        const merchantLoans = merchantLoansMap[merchant];
        return <MerchantRow key={merchant} merchantName={merchant} loans={merchantLoans} />;
      })}
    </MerchantLoansGrid>
  );
};

MerchantLoans.propTypes = {
  merchantLoansMap: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default MerchantLoans;
