import axiosClient from 'axiosClient';

import { ACTIONS } from 'state/actionTypes';
import { accountIdSelector } from 'state/account/selectors';

/**
 * @param {Array<{ autopayEnabled?: boolean, loanId: string, paymentMethodId: string, sessionId?: string }>} loanAssociations
 * @returns {Promise}
 */

export const setPaymentMethodPrefillLoans = (loanIds) => {
  return {
    type: ACTIONS.setPaymentMethodPrefillLoans,
    payload: loanIds,
  };
};

export const associatePaymentMethodWithLoans = (loanAssociations) => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  dispatch({ type: ACTIONS.associatePaymentMethodsBatch });
  dispatch(setPaymentMethodPrefillLoans([]));
  const successes = [];
  const failures = [];
  return Promise.allSettled(
    loanAssociations.map((pair) => {
      const { autopayEnabled, loanId, paymentMethodId, sessionId } = pair;
      if (sessionId) {
        return axiosClient
          .post(`/bp/v1/account/${accountId}/loan/${loanId}/autopay/persist`, { sessionId })
          .then(() => {
            successes.push(pair);
          })
          .catch(() => {
            failures.push(pair);
          });
      }

      if (!autopayEnabled) {
        return axiosClient
          .put(`/bp/v1/account/${accountId}/loans/${loanId}/paymentMethod`, { paymentToken: paymentMethodId })
          .then(() => {
            successes.push(pair);
          })
          .catch(() => {
            failures.push(pair);
          });
      }

      // In the case where an association had autopay enabled, but failed the initialization. Don't do anything for those.
      return null;
    })
  ).then(() => {
    return dispatch({ type: ACTIONS.associatePaymentMethodsBatchDone, payload: { successes, failures } });
  });
};

export const getPaymentMethods = (accountId, meta = {}) => (dispatch) => {
  return dispatch({
    type: ACTIONS.getPaymentMethods,
    payload: {
      request: {
        method: 'get',
        url: `/bp/v1/account/${accountId}/paymentMethods`,
      },
    },
    meta,
  });
};

export const selectedPaymentMethod = (paymentType) => {
  return {
    type: ACTIONS.selectedPaymentMethod,
    payload: paymentType,
  };
};
