import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { postMakePaymentReset } from 'state/loans/makePayment/actions';
import { postSchedulePaymentReset } from 'state/loans/makeScheduledPayments/actions';

import { Button } from 'styles/CommonStyles';

const MakePaymentOrScheduleButton = memo(({ disabled, loanDetails, setPaymentFormState, isSchedulePayment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'open-make-or-schedule-payment-form',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      dispatch(postMakePaymentReset());
      dispatch(postSchedulePaymentReset());

      setPaymentFormState('form');
    },
    [dispatch, setPaymentFormState]
  );

  if (loanDetails?.isDownPaymentMissing) {
    return (
      <Button data-testid="make-initial-payment-button" disabled={disabled} onClick={onClick} className="btn-block text-uppercase">
        {t('account.initial_payment.make_initial_payment')}
      </Button>
    );
  }
  if (isSchedulePayment) {
    return (
      <Button
        data-testid="make-or-schedule-payment-button"
        disabled={disabled}
        onClick={onClick}
        className="btn-block text-uppercase"
      >
        {t('loans.payment.schedule_payment.make_or_schedule')}
      </Button>
    );
  }
  return (
    <Button
      data-testid="make-or-schedule-payment-button"
      disabled={disabled}
      onClick={onClick}
      className="btn-block text-uppercase"
    >
      {t('loans.payment.make_payment')}
    </Button>
  );
});

MakePaymentOrScheduleButton.displayName = 'MakePaymentOrScheduleButton';

MakePaymentOrScheduleButton.propTypes = {
  disabled: PropTypes.bool,
  setPaymentFormState: PropTypes.func.isRequired,
  isSchedulePayment: PropTypes.bool,
  loanDetails: PropTypes.shape({
    isDownPaymentMissing: PropTypes.bool,
  }),
};

MakePaymentOrScheduleButton.defaultProps = {
  disabled: false,
  isSchedulePayment: null,
  loanDetails: null,
};

export default MakePaymentOrScheduleButton;
