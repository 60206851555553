import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase, printDocument } from 'lib/utils';

import { documentsSelector } from 'state/documents/selectors';

import { MaterialIcon } from 'styles/Icons';
import { ButtonLink, IconColorHyperlinkBlue } from 'styles/CommonStyles';

const SubHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Document = styled.div`
  background: #fff;
  border: 2px inset #eee;
  max-height: 400px;
  min-height: 400px;
  min-width: 330px;
  overflow-y: auto;
`;

const IframeDocument = styled.iframe`
  width: 100%;
  height: 400px;
`;

const iconSize1 = '1.5rem';
const iconSize2 = '1.2rem';

const ModalDocuments = memo((props) => {
  const { isDefaultPayment, toggleShowDocument, postVerifyUrl } = props;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const documents = useSelector(documentsSelector);

  const footerDoc =
    isDefaultPayment === 'ach'
      ? documents.find((document) => document.documentType === 'ach')
      : documents.find((document) => document.documentType === 'dcd');

  const languageExtension = currentLanguage === 'fr' ? '-fr' : '-en';
  const footerDocUrl = footerDoc ? `${footerDoc.url}#dcd${languageExtension}` : '';
  const docUrl = postVerifyUrl ? `${postVerifyUrl}#ach${languageExtension}` : footerDocUrl;

  const onPrint = useCallback(async () => {
    if (docUrl) {
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'print-modal-document',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.event_label = docUrl;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      printDocument(docUrl);
    }
  }, [docUrl]);

  return (
    <>
      <SubHeader>
        <ButtonLink data-testid="toggle-document-button" onClick={toggleShowDocument}>
          <MaterialIcon name="chevron_left" size={iconSize1} color={IconColorHyperlinkBlue} />
          <div>{t('common.buttons.back')}</div>
        </ButtonLink>

        <ButtonLink data-testid="print-button" onClick={onPrint}>
          <div>{t('common.buttons.print')}</div>&nbsp;
          <MaterialIcon name="print" size={iconSize2} color={IconColorHyperlinkBlue} />
        </ButtonLink>
      </SubHeader>

      {docUrl ? (
        <IframeDocument data-testid="iframe-document" title="modalDocument" src={postVerifyUrl || footerDocUrl} />
      ) : (
        <Document>
          <div className="container">{t('common.label.not_found')}</div>
        </Document>
      )}
    </>
  );
});

ModalDocuments.displayName = 'ModalDocuments';

ModalDocuments.propTypes = {
  isDefaultPayment: PropTypes.string.isRequired,
  toggleShowDocument: PropTypes.func.isRequired,
  postVerifyUrl: PropTypes.string.isRequired,
};

export default ModalDocuments;
