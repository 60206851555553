import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import { printStatement, toKebabCase } from 'lib/utils';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { accountSelector } from 'state/account/selectors';
import { loansSelector } from 'state/loans/selectors';

import Loader from 'components/Loader';
import upliftLogo from 'lib/assets/images/uplift-logo.png';

import { formatMoneyDecimalPlainFromCents } from 'lib/Formatters';
import { MaterialIcon } from 'styles/Icons';
import { ButtonLink, Col, IconColorHyperlinkBlue, LoaderContainer, Row, Table } from 'styles/CommonStyles';

const H1 = styled.h1`
  font-weight: 600;
`;

const H5 = styled.h5`
  font-weight: 600;
`;

const BoldTD = styled.td`
  font-weight: 600;

  width: 50%;
`;

const HR = styled.hr`
  padding-bottom: 1rem;
`;

const TD = styled.td`
  width: 50%;
`;

const Section = styled.div`
  font-size: 0.9rem;
`;

const StyledLink = styled(NavLink)`
  display: flex;

  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueActive};
    text-decoration: none;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1rem 3rem;
`;

const UpliftLogo = styled.img`
  width: 84px;
`;

const HideOnScreen = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

const iconSize1 = '1.5rem';
const iconSize2 = '1.2rem';

const StatementDetailsView = memo(() => {
  const { t } = useTranslation();
  const { loanId } = useParams();

  const accountDetails = useSelector(accountSelector);
  const loanDetails = useSelector(loansSelector);
  const loanSelected = loanDetails?.find((loan) => loan.loanId === loanId);

  const onPrint = useCallback(async () => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'print-statement',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    printStatement();
  }, []);

  if (!loanDetails) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <SubHeader>
        <StyledLink to="/loans">
          <MaterialIcon name="chevron_left" size={iconSize1} color={IconColorHyperlinkBlue} />
          <div data-testid="navigation-loans">{t('header.navigation.loans')}</div>
        </StyledLink>
        <ButtonLink onClick={onPrint}>
          <div data-testid="print">{t('common.buttons.print')}</div>&nbsp;
          <MaterialIcon name="print" size={iconSize2} color={IconColorHyperlinkBlue} />
        </ButtonLink>
      </SubHeader>

      <div className="container">
        <HideOnScreen>
          <UpliftLogo src={upliftLogo} alt="Uplift Logo" style={{ width: '120px' }} />
          <HR />
        </HideOnScreen>

        <Section className="mb-4">
          <H1 data-testid="statement">
            {moment().format('MMMM YYYY')} {t('loans.statement.statement')}
          </H1>
        </Section>

        <Section className="mb-4">
          <Row>
            <Col>
              <H5 data-testid="account-information">{t('loans.statement.account_information')}</H5>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <TD data-testid="account-id">{t('account.details.account_id')}</TD>
                    <BoldTD data-testid="account-id-value" className="text-right">
                      {accountDetails?.accountId}
                    </BoldTD>
                  </tr>
                  <tr>
                    <TD data-testid="loan-id">{t('loans.header.loan_id')}</TD>
                    <BoldTD data-testid="loan-id-value" className="text-right">
                      {loanId}
                    </BoldTD>
                  </tr>
                  <tr>
                    <TD data-testid="borrower">{t('loans.statement.borrower')}</TD>
                    <BoldTD
                      data-testid="borrower-value"
                      className="text-right"
                    >{`${accountDetails?.primaryContact.givenName} ${accountDetails?.primaryContact.lastName}`}</BoldTD>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col>
              <H5>{t('loans.statement.loan_information')}</H5>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <TD>{t('loans.statement.date_paid_off_if_applicable')}</TD>
                    <BoldTD className="text-right">
                      {loanSelected?.datePaidOff ? moment(loanSelected?.datePaidOff).format('L') : t('common.label.na')}
                    </BoldTD>
                  </tr>
                  <tr>
                    <TD>{t('loans.statement.date_opened')}</TD>
                    <BoldTD className="text-right">
                      {moment(loanSelected?.loanDate)
                        .utc()
                        .format('L')}
                    </BoldTD>
                  </tr>
                  <tr>
                    <TD>{t('loans.statement.loan_type')}</TD>
                    <BoldTD className="text-right">{t('loans.statement.unsecured')}</BoldTD>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Section>

        <Section className="mb-4">
          <H5>{t('loans.statement.loan_summary')}</H5>
          <Table striped bordered>
            <tbody>
              <tr>
                <TD>{t('loans.statement.loan_term')}</TD>
                <BoldTD className="text-right">{t('loans.statement.month', { count: loanSelected?.term })}</BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.annual_percentage_rate')}</TD>
                <BoldTD className="text-right">{loanSelected?.apr}%</BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.amount_financed')}</TD>
                <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.financedAmount?.amount)}</BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.initial_payment')}</TD>
                <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.downPayment?.amount)}</BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.payoff_balance')}</TD>
                <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.currentPayoffBalance)}</BoldTD>
              </tr>
              <tr>
                {loanSelected?.lastPaymentDate ? (
                  <>
                    <TD>
                      <Trans i18nKey="loans.statement.last_payment_amount_made_on" x={loanSelected?.lastPaymentDate}>
                        Last Payment Amount (made on
                        {{ lastPaymentDate: loanSelected?.lastPaymentDate }})
                      </Trans>
                    </TD>
                    <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.lastPaymentAmount)}</BoldTD>
                  </>
                ) : (
                  <>
                    <TD>{t('loans.statement.last_payment_amount')}</TD>
                    <BoldTD className="text-right">{t('common.label.na')}</BoldTD>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
        </Section>

        <Section className="mb-4">
          <H5>{t('loans.statement.amount_due')}</H5>
          <Table striped bordered>
            <tbody>
              <tr>
                <TD>{t('loans.statement.monthly_payment_amount')}</TD>
                <BoldTD className="text-right">
                  {formatMoneyDecimalPlainFromCents(loanSelected?.monthlyPaymentAmount?.amount)}
                </BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.amount_paid_this_month')}</TD>
                <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.amountPaidThisMonth)}</BoldTD>
              </tr>
              <tr>
                <TD>{t('loans.statement.current_amount_due')}*</TD>
                <BoldTD className="text-right">{formatMoneyDecimalPlainFromCents(loanSelected?.totalPastDueBalance)}</BoldTD>
              </tr>
            </tbody>
          </Table>
          <div>*{t('loans.statement.this_amount_includes_outstanding')}</div>
        </Section>

        <Section className="mb-4">
          <H5>
            {t('loans.statement.date_provided')}: {moment().format('YYYY-MM-DD')}
          </H5>
        </Section>
      </div>
    </>
  );
});

StatementDetailsView.displayName = 'StatementDetailsView';

export default StatementDetailsView;
