import { createSelector } from 'reselect';

export const postSchedulePaymentScheduleSelector = createSelector(
  (state) => state.postSchedulePaymentScheduleReducer,
  (postSchedulePaymentScheduleReducer) => postSchedulePaymentScheduleReducer
);

export const deleteSchedulePaymentSelector = createSelector(
  (state) => state.deleteSchedulePaymentReducer,
  (deleteSchedulePaymentReducer) => deleteSchedulePaymentReducer
);
