import { ACTIONS } from 'state/actionTypes';

import { accountIdSelector } from 'state/account/selectors';

import { getLoans } from 'state/loans/actions';

import { convertAmountInDollarsToCents } from 'lib/utils';

export const postSchedulePaymentSchedule = (loanId, amount, schedulePaymentDate) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const body = {
    amountInCents: convertAmountInDollarsToCents(amount),
    schedulePaymentDate,
  };

  const result = await dispatch({
    type: ACTIONS.postSchedulePaymentSchedule,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/schedulePayment/schedule`,
        data: body,
      },
    },
  });

  dispatch(getLoans(accountId));

  return result;
};

export const deleteSchedulePayment = (loanId, schedulePaymentId) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const result = await dispatch({
    type: ACTIONS.deleteSchedulePayment,
    payload: {
      request: {
        method: 'delete',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/schedulePayment/${schedulePaymentId}`,
      },
    },
  });

  dispatch(getLoans(accountId));

  return result;
};

export const postSchedulePaymentReset = () => ({
  type: ACTIONS.postSchedulePaymentReset,
});
