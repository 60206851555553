import { accountIdSelector } from 'state/account/selectors';
import { ACTIONS } from 'state/actionTypes';

export const postEnrollHardship = (loanId, hardshipPaymentAmount) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const responseAction = await dispatch({
    type: ACTIONS.postEnrollHardship,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/hardship`,
        data: {
          hardshipPaymentAmount,
        },
      },
    },
    meta: {
      loanId,
    },
  });
  return responseAction;
};

export const postEnrollHardshipReset = (loanId) => ({
  type: ACTIONS.postEnrollHardshipReset,
  loanId,
});
