import styled from 'styled-components';

export const StyledFormHeader = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
`;

export default {
  StyledFormHeader,
};
