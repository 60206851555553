import { combineReducers } from 'redux';
import { ACTIONS } from './actionTypes';

import { accountsReducer } from './account/reducer';
import { applicationsReducer } from './applications/reducer';
import { authenticationReducer } from './auth/reducer';
import {
  autoPayAlertsReducer,
  autoPayInitializeReducer,
  autoPayInitializeBatchReducer,
  autoPayPersistReducer,
  setAutoPayModalStateReducer,
} from './autoPay/reducer';
import { postCalculatePayoffReducer } from './loans/calculatePayoff/reducer';
import { deletePaymentReducer } from './deletePayment/reducer';
import { documentsReducer } from './documents/reducer';
import { helpReducer } from './help/reducer';
import { loansReducer, postMakePaymentReducer, postDelayPaymentReducer } from './loans/reducer';
import { postEnrollHardshipReducer } from './loans/hardship/reducer';
import { associatePaymentMethodBatchReducer, paymentMethodsReducer, paymentMethodPrefillReducer } from './paymentMethods/reducer';
import { deleteSchedulePaymentReducer, postSchedulePaymentScheduleReducer } from './loans/makeScheduledPayments/reducer';
import { secureFormsPaymentMethodsReducer } from './secureFormsPaymentMethods/reducer';
import {
  postAccountUpdateInitializeReducer,
  postAccountUpdateVerifyReducer,
  initialStateVerify as postAccountUpdateVerifyReducerInitialState,
} from './updateAccount/reducer';
import {
  postAuthorizeReducer,
  postPersistReducer,
  postPlaidLinkTokenReducer,
  postVerifyReducer,
  tokenizeReducer,
  paymentMethodsAlertsReducer,
} from './updatePaymentMethods/reducer';

import { putLoanPaymentMethodReducer } from './loans/setPaymentMethod/reducer';
import { boostReducer } from './boost/reducer';

const allReducers = combineReducers({
  accountsReducer,
  applicationsReducer,
  associatePaymentMethodBatchReducer,
  authenticationReducer,
  autoPayAlertsReducer,
  autoPayInitializeReducer,
  autoPayInitializeBatchReducer,
  autoPayPersistReducer,
  boostReducer,
  deletePaymentReducer,
  deleteSchedulePaymentReducer,
  documentsReducer,
  helpReducer,
  loansReducer,
  paymentMethodsReducer,
  paymentMethodsAlertsReducer,
  paymentMethodPrefillReducer,
  postAccountUpdateInitializeReducer,
  postAccountUpdateVerifyReducer,
  postAuthorizeReducer,
  postCalculatePayoffReducer,
  postEnrollHardshipReducer,
  postMakePaymentReducer,
  postDelayPaymentReducer,
  postPersistReducer,
  postPlaidLinkTokenReducer,
  postSchedulePaymentScheduleReducer,
  postVerifyReducer,
  putLoanPaymentMethodReducer,
  secureFormsPaymentMethodsReducer,
  setAutoPayModalStateReducer,
  tokenizeReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === ACTIONS.logoutSuccess) {
    // persist logoutSource key in postAccountUpdateVerifyReducer on logout
    const { postAccountUpdateVerifyReducer: oldPostAccountUpdateVerifyReducer } = state;
    const { logoutSource } = oldPostAccountUpdateVerifyReducer;

    return allReducers({ postAccountUpdateVerifyReducer: { ...postAccountUpdateVerifyReducerInitialState, logoutSource } }, action);
  }

  return allReducers(state, action);
};
export default rootReducer;
