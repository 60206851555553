import { createSelector } from 'reselect';

export const boostEnabledSelector = createSelector(
  (state) => state.boostReducer,
  (boostReducer) => boostReducer.boostEnabled
);

export const boostInfoSelector = createSelector(
  (state) => state.boostReducer,
  (boostReducer) => ({
    approvalAmount: boostReducer.approvalAmount,
    interestFreeAvailable: boostReducer.interestFreeAvailable,
  })
);
