import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import 'lib/assets/css/styles-fonts.css';
import { Button } from 'styles/CommonStyles';
import FlexPayLogo from 'lib/assets/images/flexpay-logo.svg';

const BannerContainer = styled.div`
  display: flex;

  background-color: ${(props) => props.theme.colors.upgradeGreen};

  flex-flow: column nowrap;
  white-space: nowrap;

  border-radius: 8px;
  min-height: 91px;

  padding-top: 16px;
  padding-bottom: 16px;

  margin-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  align-items: center;
  align-self: center;

  width: 90%;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    width: 448px;
    margin: 0 auto 20px;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    width: 604px;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    width: 70%;
    text-space: nowrap;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 16px 48px;
    width: 732px;
  }
`;

const BannerHeader = styled.div`
  color: ${(props) => props.theme.colors.upliftWhite};
  flex-direction: column;
  font-size: 31.47px;
  line-height: 24px;
  align-self: stretch;
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    line-height: 8px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  color: ${(props) => props.theme.colors.borderBlack};
  gap: 8px;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const Header = styled.span`
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: pre-line;
`;

const SubHeader = styled.span`
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const StyledFlexpayLogo = styled.img`
  height: 34.62px;
  margin-left: 8px;
  margin-bottom: 8px;
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 130.957px;
    height: 32px;
  }
`;

const FlexPayBanner = () => {
  const { t } = useTranslation();
  return (
    <BannerContainer>
      <BannerHeader>
        <Header>
          {t('authentication.sign_in.uplift_is_becoming')}
          <StyledFlexpayLogo src={FlexPayLogo} />
        </Header>
        <div />
        <SubHeader>{t('authentication.sign_in.new_look_same_product')}</SubHeader>
      </BannerHeader>
      <a href="https://www.upgrade.com/flex-pay/frequently-asked-questions/" target="_blank" rel="noopener noreferrer">
        <StyledButton>{t('authentication.sign_in.see_faq')}</StyledButton>
      </a>
    </BannerContainer>
  );
};

export default FlexPayBanner;
