import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import { BigButtonStyle } from 'styles/PaymentMethodStyles';
import { MaterialIcon } from 'styles/Icons';
import Theme from 'styles/Theme';
import { Badge } from 'styles/CommonStyles';

const MiniPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

const WalletTitle = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
`;

const WalletMessage = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.43;
`;

const BigButton = memo((props) => {
  const { iconName, message, onClick, recommended, title, disabled } = props;
  const { t } = useTranslation();

  return (
    <BigButtonStyle onClick={onClick} disabled={disabled}>
      <MiniPanelBody>
        <MaterialIcon name={iconName} color={Theme.colors.upliftTeal} size="2rem" />
        <WalletTitle>{title}</WalletTitle>
        {message && <WalletMessage>{message}</WalletMessage>}
        {recommended && (
          <Badge pill variant="primary">
            <MaterialIcon name="stars" color={Theme.colors.upliftTeal} size="16px" />
            {t('common.label.recommended')}
          </Badge>
        )}
      </MiniPanelBody>
    </BigButtonStyle>
  );
});

BigButton.displayName = 'BigButton';

BigButton.propTypes = {
  disabled: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func,
  recommended: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

BigButton.defaultProps = {
  disabled: false,
  message: null,
  onClick: null,
  recommended: false,
};

export default BigButton;
