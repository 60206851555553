import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Wrapper from 'styles/Wrapper';
import StyledContainer from 'styles/Container';
import FlexGrow from 'styles/FlexGrow';

import Banner from 'components/banners/Banner';
import BigUpliftBanner from 'components/banners/BigUpliftBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MissingPaymentMethodBanner from './banners/MissingPaymentMethodBanner';
import FlexPayBanner from './banners/FlexpayBanner';

const GlobalContainer = memo((props) => {
  const content = props.children || props.content;

  const displayBigUpliftBanner = process.env.REACT_APP_DISPLAY_BIG_UPLIFT_CAMPAIGN_BANNER === 'true';

  return (
    <main role="main">
      <Wrapper id="IE Wrapper">
        <StyledContainer id="Container" isWhiteBackground={props.isWhiteBackground}>
          {displayBigUpliftBanner && <BigUpliftBanner />}
          <Banner />
          <MissingPaymentMethodBanner />

          <Header />
          <FlexPayBanner />

          {content}

          <FlexGrow id="FlexGrow" />
          <Footer />
        </StyledContainer>
      </Wrapper>
    </main>
  );
});

GlobalContainer.displayName = 'GlobalContainer';

GlobalContainer.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  isWhiteBackground: PropTypes.bool,
};

GlobalContainer.defaultProps = {
  children: null,
  content: null,
  isWhiteBackground: false,
};

export default GlobalContainer;
