import { ACTIONS } from 'state/actionTypes';

export const getAccount = (accountId) => (dispatch) => {
  dispatch({
    type: ACTIONS.getAccount,
    payload: {
      request: {
        method: 'get',
        url: accountId ? `/bp/v1/account/${accountId}` : '/bp/v1/account',
      },
    },
  });
};

export const updatePrimaryContact = (primaryContact) => ({
  type: ACTIONS.updatePrimaryContact,
  payload: {
    primaryContact,
  },
});

export default getAccount;
