const round = (number) => Math.round(number * 100) / 100;

// https://redux-docs.netlify.com/recipes/configuring-your-store

const monitorReducerEnhancer = (createStore) => (reducer, initialState, enhancer) => {
  const monitoredReducer = (state, action) => {
    const start = performance.now();
    const newState = reducer(state, action);
    const end = performance.now();
    const diff = round(end - start);

    // eslint-disable-next-line no-console
    console.log('action: ', action.type, 'reducer process time: ', diff, 'ms');

    return newState;
  };

  return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
