import { createSelector } from 'reselect';

export const applicationsSelector = createSelector(
  (state) => state.applicationsReducer,
  (applicationsReducer) => applicationsReducer.data
);

export const hasApplicationsSelector = createSelector(
  (state) => state.applicationsReducer,
  (applicationsReducer) => applicationsReducer.count > 0
);

export const applicationsLoadingSelector = createSelector(
  (state) => state.applicationsReducer,
  (applicationsReducer) => applicationsReducer.loading
);

export const applicationDocumentsSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, applicationId) => state.applicationsReducer && state.applicationsReducer.data,
  (state, applicationId) => applicationId,
  (data, applicationId) => data && data.find((value) => value.applicationId === applicationId).applicationDocuments
);
