import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AuthForm, Label, SignInButton } from 'styles/AuthStyles';

const AdminForm = memo((props) => {
  const { signIn } = props;
  const { t } = useTranslation();

  return (
    <>
      <Label>{t('admin.sign_in.sign_in')}</Label>

      <AuthForm>
        <SignInButton onClick={signIn} className="btn-block">
          {t('authentication.sign_in.sign_in')}
        </SignInButton>
      </AuthForm>
    </>
  );
});

AdminForm.displayName = 'AdminForm';

AdminForm.propTypes = {
  signIn: PropTypes.func.isRequired,
};

export default AdminForm;
