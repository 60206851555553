import React, { useState, useEffect } from 'react';
import { MaterialIcon } from 'styles/Icons';
import styled from 'styled-components';
import Theme from 'styles/Theme';
import { Accordion, Card } from 'styles/CommonStyles';
import PropTypes from 'prop-types';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const HeaderStyle = {
  backgroundColor: Theme.colors.upliftWhite,
  color: Theme.colors.headerGrey,
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '0.75rem',
  border: 'none',
  lineHeight: '1.5rem',
};

const ItemStyle = {
  border: 'none',
};

const StyledChevron = styled(MaterialIcon)`
  cursor: ${(props) => (props.isSingleResult ? 'text' : 'pointer')};
  color: ${Theme.colors.backgroundGrey};
  float: right;
  margin-left: auto;
`;

const StyledCardBody = styled(Card.Body)`
  font-size: 0.75rem;
  padding: 0 1.25rem 1rem 1.25rem;
`;

const PreviewAnswer = styled.div`
  cursor: ${(props) => (props.isSingleResult ? 'text' : 'pointer')};
  font-weight: 300;
  line-height: 1rem;
  padding-right: 1rem;
  font-size: 12px;
`;

const FaqQuestion = styled.div`
  display: flex;
`;

const toggleIconSize = '1.2rem';
const mobileBreakpoint = 640;

const AccordionToggle = ({ faqItem, index, handleToggle, isResultsPage, isSingleResult, searchQuery }) => {
  const [isChevronShowing, setChevronShowing] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeRow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', resizeRow);
    return () => {
      window.removeEventListener('resize', resizeRow);
    };
  }, []);

  const createFaqPreview = (answer) => {
    let trimmedPreview = answer.length >= 88 ? `${answer.substring(0, 88)}...` : answer;

    // Check that HTML tags were not broken
    if (trimmedPreview.match(/<(?!.*?>)/g)) {
      trimmedPreview = `${trimmedPreview.substring(0, trimmedPreview.lastIndexOf('<'))}...`;
    }
    return trimmedPreview;
  };

  const faqPreview = createFaqPreview(faqItem.answers[0]);
  const isNotExpandable = faqItem.answers.length === 1 && faqItem.answers[0].length <= 88;
  const hasSubAnswers = faqItem.subAnswers?.length >= 0;

  const [showFullAnswer, setShowFullAnswer] = useState(false);

  const toggleQuestionDetails = () => {
    if (isResultsPage) {
      // handleEllipsesToggle
      setChevronShowing(!isChevronShowing);
      setShowFullAnswer(!showFullAnswer);
    } else {
      // onSwitch;
      setChevronShowing(!isChevronShowing);
      handleToggle();
    }

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'toggle',
      label: 'expand-question',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata = toKebabCase(metadata);

    metadata.question = faqItem.question;

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  // highlight words
  const getTextWithHighlights = (text) => {
    if (searchQuery) {
      // Strip special characters from search result for highlight markdown replacements
      const res = searchQuery
        .replace(/[.*+?^${}()|[\]\\<]/g, '')
        .split(' ')
        .filter((x) => x.length > 3);
      res.unshift(searchQuery.replace(/[.*+?^${}()|[\]\\<]/g, ''));

      let newText = text;

      for (let i = 0; i < res.length; i += 1) {
        // Prevent breaking tags with search queries (avoids matches between angle brackets)
        const regex = new RegExp(`${res[i]}(?!.*?>)`, 'gi');
        // Reverse to emulate regex lookbehinds (checking for matches before angle brackets)
        const reversedRegex = new RegExp(
          `${res[i]
            .split('')
            .reverse()
            .join('')}(?!.*?<)`,
          'gi'
        );
        const reversedHighlight = `<mark class="highlight">&$</mark>`
          .split('')
          .reverse()
          .join('');

        newText = newText.replace(regex, `<mark class="highlight">$&</mark>`);
        newText = newText
          .split('')
          .reverse()
          .join('')
          .replace(reversedRegex, reversedHighlight);

        newText = newText
          .split('')
          .reverse()
          .join('');
      }

      // eslint-disable-next-line react/no-danger
      return <span dangerouslySetInnerHTML={{ __html: newText }} />;
    }
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const RenderAnswers = () => {
    return (
      <>
        {faqItem.answers.map((answer) => (
          <div key={answer}>
            {faqItem.answers.length > 1 ? <div>- {getTextWithHighlights(answer)}</div> : <div>{getTextWithHighlights(answer)}</div>}
          </div>
        ))}

        {faqItem.subAnswers && (
          <>
            {faqItem.subAnswers.map((subAnswer) => (
              <div key={subAnswer}>
                {faqItem.subAnswers.length > 1 ? (
                  <div>- {getTextWithHighlights(subAnswer)}</div>
                ) : (
                  <div>{getTextWithHighlights(subAnswer)}</div>
                )}
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <Card style={ItemStyle}>
      <Accordion.Toggle as={Card.Header} eventKey={index} style={HeaderStyle} onClick={toggleQuestionDetails}>
        <FaqQuestion data-testid="question">
          {getTextWithHighlights(faqItem.question)}
          {!(width <= mobileBreakpoint) && !isSingleResult && !(isNotExpandable && isResultsPage && !hasSubAnswers) ? (
            <StyledChevron name={`${isChevronShowing ? 'remove' : 'add'}`} size={toggleIconSize} />
          ) : null}
        </FaqQuestion>
        {isResultsPage ? (
          <PreviewAnswer>{showFullAnswer || isSingleResult ? <RenderAnswers /> : getTextWithHighlights(faqPreview)}</PreviewAnswer>
        ) : null}
      </Accordion.Toggle>
      {!isResultsPage ? (
        <Accordion.Collapse eventKey={index}>
          <StyledCardBody>
            <RenderAnswers />
          </StyledCardBody>
        </Accordion.Collapse>
      ) : null}
    </Card>
  );
};

AccordionToggle.propTypes = {
  faqItem: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isResultsPage: PropTypes.bool.isRequired,
  isSingleResult: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
};

AccordionToggle.defaultProps = {
  searchQuery: null,
};

export default AccordionToggle;
