import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, Container, Row } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const TabText = styled.p`
  width: 6.25rem;
  height: 1.75rem;

  left: 0.63rem;
  top: 2.94rem;
  padding: 0.25rem;
  padding: inherit;
  font-size: 0.75rem;
  line-height: 0.88rem;
  text-align: center;
  color: ${(props) => props.theme.colors.textBlack};
`;

const boxShadowOffset = '0rem 0rem 0.75rem';

const StyledCard = styled(Card)`
  background: ${(props) => props.theme.colors.upliftWhite};
  color: ${(props) => (props.isselected ? props.theme.colors.upliftTeal : props.theme.colors.backgroundGrey)};
  border-color: ${(props) => (props.isselected ? props.theme.colors.upliftTeal : props.theme.colors.backgroundGrey)};
  box-shadow: ${(props) => (props.isselected ? `${boxShadowOffset + props.theme.colors.boxShadowLightOpacity}` : null)};

  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 7.5rem;
  height: 5.25rem;
  top: 0.75rem;
  margin: 0.75rem;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.upliftTeal};
    border-color: ${(props) => props.theme.colors.upliftTeal};
  }
`;

const StyledGroupCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.75rem;
`;

const iconSize = '2rem';

const CardStyle = {
  marginTop: '0.85rem',
  marginBottom: '0.4rem',
};

const HelpCards = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const changeTab = (tab) => {
    setActiveTab(tab);

    // google analytics
    const gaEvent = {
      category: 'help',
      action: 'click',
      label: `section-${tab}`,
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  return (
    <Container fluid>
      <StyledGroupCards>
        <Row noGutters>
          <StyledCard
            value="payment-information"
            onClick={() => changeTab('payment-information')}
            isselected={activeTab === 'payment-information' ? 'true' : ''}
          >
            <MaterialIcon name="credit_card" size={iconSize} style={CardStyle} />
            <TabText data-testid="payment-information-tab">{t('faqs.payment_information.payment_information')}</TabText>
          </StyledCard>
          <StyledCard
            value="adding-bank-account"
            onClick={() => changeTab('adding-bank-account')}
            isselected={activeTab === 'adding-bank-account' ? 'true' : ''}
          >
            <MaterialIcon name="account_balance" size={iconSize} style={CardStyle} />
            <TabText data-testid="adding-bank-account-tab">{t('faqs.adding_bank_account.adding_bank_account')}</TabText>
          </StyledCard>
        </Row>
        <Row noGutters>
          <StyledCard
            value="account-information"
            onClick={() => changeTab('account-information')}
            isselected={activeTab === 'account-information' ? 'true' : ''}
          >
            <MaterialIcon name="manage_accounts" size={iconSize} style={CardStyle} />
            <TabText data-testid="account-information-tab">{t('faqs.account_information.account_information')}</TabText>
          </StyledCard>
          <StyledCard
            value="customer-support"
            onClick={() => changeTab('customer-support')}
            isselected={activeTab === 'customer-support' ? 'true' : ''}
          >
            <MaterialIcon name="support_agent" size={iconSize} style={CardStyle} />
            <TabText data-testid="customer-support-tab">{t('authentication.footer.customer_support')}</TabText>
          </StyledCard>
        </Row>
      </StyledGroupCards>
    </Container>
  );
};

HelpCards.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default HelpCards;
