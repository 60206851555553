import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MaterialIcon } from 'styles/Icons';

import { Alert } from 'styles/CommonStyles';

const Header = styled.div`
  color: #ee0606;
  font-weight: bold;

  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
`;

const Message = styled.div`
  color: #555555;
`;

const StyledAlert = styled(Alert)`
  background-color: #ffebeb;
  border: none;

  margin-bottom: 1rem;
`;

const OverdueMessage = memo(({ header, message }) => {
  return (
    <StyledAlert variant="danger">
      <Header data-testid="overdue-header">
        <MaterialIcon name="error_outline" style={{ marginRight: '0.2rem' }} />
        {header}
      </Header>

      <Message data-testid="overdue-message">{message}</Message>
    </StyledAlert>
  );
});

OverdueMessage.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.shape({ type: PropTypes.oneOfType([PropTypes.func, PropTypes.symbol]) }).isRequired,
};

export default OverdueMessage;
