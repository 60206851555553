import React, { memo } from 'react';

import Container from 'components/GlobalContainer';
import PayoffLetterView from './PayoffLetterView';

const PayoffLetter = memo(() => {
  return (
    <Container isWhiteBackground>
      <PayoffLetterView />
    </Container>
  );
});

PayoffLetter.displayName = 'PayoffLetter';

export default PayoffLetter;
