import { createSelector } from 'reselect';

export const accountUpdateInitializeSelector = createSelector(
  (fullState) => fullState.postAccountUpdateInitializeReducer,
  (mobileInitializeState) => mobileInitializeState
);

export const accountUpdateVerifySelector = createSelector(
  (fullState) => fullState.postAccountUpdateVerifyReducer,
  (verifyState) => verifyState
);

export const accountUpdateLogoutInfoSelector = createSelector(
  (fullState) => fullState.postAccountUpdateVerifyReducer,
  (logoutState) => logoutState.logoutSource
);
