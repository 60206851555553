import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, FormCheck } from 'styles/CommonStyles';
import { ErrorMessage, InputGroupAddon, PaymentAmountInput } from 'views/loans/payments/MakePaymentForm';
import { convertAmountInDollarsToCents, convertAmountInCentsToDollarsString } from 'lib/utils';
import { StyledFormHeader } from '../styles/HardshipStyles';

const StyledField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  margin-bottom: 0;
`;

const StyledErrorContainer = styled.div`
  text-align: right;
`;

const HardshipPaymentForm = ({ loanId, onSubmit, requiredPayment }) => {
  const { t } = useTranslation();
  const { errors, handleSubmit, register } = useForm();

  const amountInputId = `${loanId}-hardship-payment-amount`;

  const validateAmount = (amount) => amount && parseFloat(amount) >= 25.0;

  const doSubmit = (data) => {
    const numericAmount = requiredPayment
      ? parseInt(requiredPayment, 10)
      : convertAmountInDollarsToCents(data.hardshipPaymentAmount);
    onSubmit({
      ...data,
      hardshipPaymentAmount: numericAmount,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(doSubmit)}>
        <StyledFormHeader>{t('loans.hardship.enrollment_payment_instructions')}</StyledFormHeader>
        {requiredPayment ? (
          <FormCheck
            type="radio"
            id={amountInputId}
            label={`$${convertAmountInCentsToDollarsString(requiredPayment)} (50%)`}
            name="hardshipPaymentAmount"
            ref={register({ required: 'loans.payment.form.validation.invalid_amount' })}
            value={requiredPayment}
          />
        ) : (
          <>
            <StyledField>
              <StyledLabel htmlFor={amountInputId}>{t('loans.payment.form.payment_amount')}</StyledLabel>
              <div>
                <InputGroupAddon error={errors.hardshipPaymentAmount}>$</InputGroupAddon>
                <PaymentAmountInput
                  error={errors.hardshipPaymentAmount}
                  id={amountInputId}
                  name="hardshipPaymentAmount"
                  placeholder="0.00"
                  ref={register({
                    required: 'loans.payment.form.validation.invalid_amount',
                    pattern: { value: /^[0-9]*(\.[0-9]{1,2})?$/i, message: 'loans.payment.form.validation.invalid_amount' },
                    validate: {
                      min: (value) => validateAmount(value) || 'loans.hardship.min_amount_error',
                    },
                  })}
                  step="0.01"
                  type="number"
                />
              </div>
            </StyledField>
            {errors.hardshipPaymentAmount ? (
              <StyledErrorContainer>
                <ErrorMessage>{t(errors.hardshipPaymentAmount.message, { amount: 25 })}</ErrorMessage>
              </StyledErrorContainer>
            ) : null}
          </>
        )}

        <Button className="btn-block text-uppercase mt-4" disabled={errors.hardshipPaymentAmount} type="submit">
          {t('common.buttons.pay_now')}
        </Button>
      </form>
    </div>
  );
};

HardshipPaymentForm.propTypes = {
  loanId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  requiredPayment: PropTypes.number,
};

HardshipPaymentForm.defaultProps = {
  onSubmit: () => {},
  requiredPayment: undefined,
};

export default HardshipPaymentForm;
