import CryptoJS from 'crypto-js';

import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

/* SECURE FORMS CALLS */
// prettier-ignore
export const tokenizeReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postTokenize:
      return {
        loading: true,
      };
    case ACTIONS.postTokenizeSuccess:
      return {
        loading: undefined,
        tokenize: action.payload.data,
      };
    case ACTIONS.postTokenizeFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

// prettier-ignore
export const postAuthorizeReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postAuthorize:
      return {
        authorizing: false,
      };
    case ACTIONS.postAuthorizeSuccess:
      return {
        authorizing: true,
        data: action.payload.data,
      };
    case ACTIONS.postAuthorizeFail:
      return {
        ...state,
        data: action.error.data,
        error: httpError(action),
      };
    default:
      return state;
  }
};

/* PLAID CALLS */
// prettier-ignore
export const postPlaidLinkTokenReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postPlaidLinkToken:
      return {
        loading: true,
      };
    case ACTIONS.postPlaidLinkTokenSuccess:
      // Stack Overflow: https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs
      localStorage.setItem("plaidLinkToken", CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(action.payload.data.linkToken)));

      return {
        loading: false,
        data: action.payload.data,
      };
    case ACTIONS.postPlaidLinkTokenStore:
        return {
          loading: false,
          data: { linkToken: action.payload },
        };
    case ACTIONS.postPlaidLinkTokenFail:
      return {
        data: action.error,
        error: httpError(action),
      };
    case ACTIONS.postPlaidLinkTokenReset:
        delete localStorage.plaidLinkToken;
        return state;
    default:
      return state;
  }
};

// prettier-ignore
export const postVerifyReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postVerify:
      return {
        loading: true,
        verifying: false,
      };
    case ACTIONS.postVerifySuccess:
      return {
        loading: undefined,
        verifying: true,
        data: action.payload.data,
      };
    case ACTIONS.postVerifyFail:
      return {
        ...state,
        loading: false,
        data: action.error,
        error: httpError(action),
      };
    case ACTIONS.postVerifyReset:
    case ACTIONS.postPersistSuccess:
      return {};
    default:
      return state;
  }
};

// prettier-ignore
export const postPersistReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postPersist:
      return {
        loading: true,
        persisting: false,
      };
    case ACTIONS.postPersistSuccess:
      return {
        loading: undefined,
        persisting: true,
        data: action.payload.data,
      };
    case ACTIONS.postPersistFail:
      return {
        ...state,
        loading: false,
        data: action.error.data,
        error: httpError(action),
      };
    case ACTIONS.postPersistReset:
      return {};
    default:
      return state;
  }
};

// prettier-ignore
export const paymentMethodsAlertsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.setShowPaymentMethodSuccess:
      return {
        ...state,
        isShowSuccess: action.isShowSuccess,
      };
    case ACTIONS.setShowPaymentMethodFail:
      return {
        ...state,
        isShowFail: action.isShowFail,
      };
    case ACTIONS.setShowPaymentMethodAssociationSuccess:
      return {
        ...state,
        isShowAssociationSuccess: action.isShow,
      };
    case ACTIONS.setShowPaymentMethodAssociationFailure:
      return {
        ...state,
        isShowAssociationFailure: action.isShow,
      };
    default:
      return state;
  }
};
