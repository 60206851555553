import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Container from 'components/GlobalContainer';

const Message = styled.div`
  color: red;
  align-self: center;

  margin: 2rem auto;
`;

const Maintenance = memo(() => {
  const { t } = useTranslation();
  return (
    <Container>
      <Message>{t('banner.maintenance')}</Message>
    </Container>
  );
});

Maintenance.displayName = 'Maintenance';

export default Maintenance;
