import { useEffect } from 'react';

import { getDocuments } from 'state/documents/actions';

export const useGetDocuments = (accountId, dispatch) => {
  useEffect(() => {
    if (accountId) {
      dispatch(getDocuments(accountId));
    }
  }, [accountId, dispatch]);
};

export default useGetDocuments;
