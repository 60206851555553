import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const postMakePaymentReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postMakePaymentReset:
      return {};
    case ACTIONS.postMakePayment:
      return {
        loading: true,
      };
    case ACTIONS.postMakePaymentSuccess:
      return {
        success: true,
        makePayment: Date.now(),
        data: action.payload.data,
      };
    case ACTIONS.postMakePaymentFail:
      return {
        loading: false,
        makePayment: false,
        error: httpError(action),
      };
    case ACTIONS.postMakePaymentRefresh:
      return {
        ...state,
        refreshing: true,
      };
    case ACTIONS.getLoansSuccess:
    case ACTIONS.getLoansFail:
      return {
        ...state,
        refreshing: false,
      };
    default:
      return state;
  }
};

export default postMakePaymentReducer;
