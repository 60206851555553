/* eslint-disable react/prop-types */
import React, { useCallback, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTimeoutState } from 'hooks/useTimeout';

import { authVerifySelector } from 'state/auth/selectors';

import Loader from 'components/Loader';

import { AuthForm, Label, SignInButton, TransitionPanel } from 'styles/AuthStyles';

import VerifyCode from './components/VerifyCode';

const SpecialErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.errorMessage};
  font-size: 0.75rem;

  margin: 5px auto;
  text-align: center;
`;

const Retry = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  font-size: 0.9rem;

  margin-bottom: 25px;
`;

const Resend = styled.div`
  color: #777;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Message = styled.div`
  text-align: center;
  width: 100%;
`;

const ResendCode = ({ delay, onResend, children }) => {
  // timeout state
  // function to reset timeout
  const [showChildren, restartShowChildren] = useTimeoutState(delay);

  const onClick = useCallback(
    (event) => {
      restartShowChildren();
      return onResend(event);
    },
    [onResend, restartShowChildren]
  );

  if (!showChildren) {
    return null;
  }

  return React.cloneElement(children, { onClick });
};

const VerifyForm = memo((props) => {
  const { customChallengeAnswer, onAnswerChange, resendCode, startOver } = props;
  const { t } = useTranslation();

  const { loading, error, user = {}, challengeAnswerCountExceeded } = useSelector(authVerifySelector);

  const onResend = useCallback((event) => resendCode(event, user.username), [resendCode, user.username]);

  const onSubmit = useCallback(
    (event) => {
      customChallengeAnswer(event, props);
    },
    [customChallengeAnswer, props]
  );

  if (!user.username) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Label>{t('authentication.verify.verify')}</Label>
      <Message>{t('authentication.verify.sent_code')}</Message>
      <AuthForm noValidate onSubmit={onSubmit}>
        <VerifyCode onAnswerChange={onAnswerChange} showError={error} />

        {challengeAnswerCountExceeded ? (
          <SpecialErrorMessage>
            <div>{t('authentication.errors.max_tries')}</div>
            <Trans i18nKey="authentication.errors.contact">
              Contact <a href="mailto:customercare@upgrade.com">customercare@upgrade.com</a> for help.
            </Trans>
          </SpecialErrorMessage>
        ) : (
          <>
            <Retry>
              <ResendCode delay={5000} onResend={onResend}>
                <Resend>{t('authentication.verify.resend_code')}</Resend>
              </ResendCode>
              <div />
              <div>
                <a href="/signin" onClick={startOver}>
                  {t('authentication.verify.start_over')}
                </a>
              </div>
            </Retry>

            {loading ? (
              <TransitionPanel>
                <Loader />
              </TransitionPanel>
            ) : (
              <div className="d-flex justify-content-center">
                <SignInButton type="submit" className="btn-block">
                  {t('authentication.sign_in.sign_in')}
                </SignInButton>
              </div>
            )}
          </>
        )}
      </AuthForm>
    </>
  );
});

VerifyForm.displayName = 'VerifyForm';

VerifyForm.propTypes = {
  customChallengeAnswer: PropTypes.func.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  startOver: PropTypes.func.isRequired,
};

export default VerifyForm;
