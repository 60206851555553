import { useEffect } from 'react';

import { getPaymentMethods } from 'state/paymentMethods/actions';

export const useGetPaymentMethods = (accountId, dispatch) => {
  useEffect(() => {
    if (accountId) {
      dispatch(getPaymentMethods(accountId));
    }
  }, [accountId, dispatch]);
};

export default useGetPaymentMethods;
