import React, { memo, useCallback } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const OutboundLink = memo(({ eventLabel, to, target, rel, children, style }) => {
  const onOutboundLink = useCallback(() => {
    // cevents
    let metadata = {};
    metadata.to = to;
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw('user', 'click', eventLabel, metadata);
  }, [eventLabel, to]);

  return (
    <ReactGA.OutboundLink onClick={onOutboundLink} eventLabel={eventLabel} to={to} target={target} rel={rel} style={{ ...style }}>
      {children}
    </ReactGA.OutboundLink>
  );
});

OutboundLink.displayName = 'OutboundLink';

OutboundLink.propTypes = {
  // children: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  eventLabel: PropTypes.string.isRequired,
  rel: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
};

OutboundLink.defaultProps = {
  rel: '',
  style: null,
  target: '',
};

export default OutboundLink;
