import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import styled from 'styled-components';

import { Button, ButtonOutline, Form, IconWrapper } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import { Card, Overlay, ModalBody, ModalFooter, ModalHeader } from 'styles/ModalStyles';
import theme from 'styles/Theme';

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledModalFooter = styled(ModalFooter)`
  flex-direction: column;

  button + button {
    margin-top: 1rem;
  }
`;

const StyledCheckbox = styled(Form.Check)`
  & + & {
    margin-top: 1rem;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledTitle = styled.h5`
  color: ${(props) => props.theme.colors.upliftLightBlack};
  font-size: 1rem;
  font-weight: bold;
`;

const PayoffLetterModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { formState, handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    if (typeof onConfirm === 'function') {
      onConfirm(data);
    }
  };

  return (
    <Overlay>
      <Card>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <StyledHeaderContent>
              <StyledTitle>{t('documents.payoff_letter.select_payoff_letter_type')}</StyledTitle>
              <IconWrapper onClick={onClose}>
                <MaterialIcon color={theme.colors.upliftLightBlack} name="close" />
              </IconWrapper>
            </StyledHeaderContent>
          </ModalHeader>
          <ModalBody>
            <StyledCheckbox
              id="10-days-radio"
              label={t('documents.payoff_letter.payoff_letter_type', { numDays: 10 })}
              name="payoffLetterType"
              ref={register({ required: true })}
              type="radio"
              value="10"
            />
            <StyledCheckbox
              id="20-days-radio"
              label={t('documents.payoff_letter.payoff_letter_type', { numDays: 20 })}
              name="payoffLetterType"
              ref={register({ required: true })}
              type="radio"
              value="20"
            />
            <StyledCheckbox
              id="30-days-radio"
              label={t('documents.payoff_letter.payoff_letter_type', { numDays: 30 })}
              name="payoffLetterType"
              ref={register({ required: true })}
              type="radio"
              value="30"
            />
          </ModalBody>
          <StyledModalFooter>
            <Button disabled={!formState.dirty} type="submit">
              {t('common.buttons.request')}
            </Button>
            <ButtonOutline onClick={onClose} type="button">
              {t('common.buttons.cancel')}
            </ButtonOutline>
          </StyledModalFooter>
        </StyledForm>
      </Card>
    </Overlay>
  );
};

PayoffLetterModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

PayoffLetterModal.defaultProps = {
  onClose: undefined,
  onConfirm: undefined,
};

export default PayoffLetterModal;
