import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { accountIdSelector } from 'state/account/selectors';
import { autopayDisabledLoansSelector } from 'state/loans/selectors';

import { useStorage } from 'hooks/useStorage';

import { MaterialIcon } from 'styles/Icons';

const StyledBanner = styled.div`
  background-color: ${(props) => props.theme.colors.upliftTeal};
  color: ${(props) => props.theme.colors.upliftWhite};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  min-height: 50px;
  padding: 1rem;
`;

const Message = styled.div`
  font-size: 0.85rem;
  text-align: center;

  padding: 0 2rem;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.upliftTeal};
  color: ${(props) => props.theme.colors.upliftWhite};

  outline: none;
  border: none;
  cursor: pointer;

  padding-top: 5px;

  margin-left: auto;
`;

const HiddenButton = styled.button`
  background-color: ${(props) => props.theme.colors.upliftOrange};
  color: ${(props) => props.theme.colors.upliftWhite};

  outline: none;
  border: none;
  cursor: pointer;

  padding-top: 5px;

  margin-right: auto;
  visibility: hidden;
`;

const Banner = memo(() => {
  const { t } = useTranslation();
  const accountId = useSelector(accountIdSelector);
  const autoPayDisabledLoans = useSelector(autopayDisabledLoansSelector);
  const [display, setDisplay] = useStorage(sessionStorage, 'dismissAutoPayBanner');

  const onClose = useCallback(
    (event) => {
      event.preventDefault();

      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'close-autopay-banner',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      setDisplay(accountId);
    },
    [accountId, setDisplay]
  );

  return autoPayDisabledLoans && display !== accountId ? (
    <StyledBanner>
      <HiddenButton>
        <MaterialIcon name="close" />
      </HiddenButton>
      <Message>{t('account.payment_methods.autopay.enable_autopay_avoid')}</Message>
      <Button onClick={onClose}>
        <MaterialIcon name="close" />
      </Button>
    </StyledBanner>
  ) : null;
});

Banner.displayName = 'Banner';

export default Banner;
