import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Panel, ButtonHyperLink } from 'styles/CommonStyles';
import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';
import AccordionCards from './AccordionCards';

const HeaderStyle = styled.div`
  font-weight: 600;
  margin-left: 1.25rem;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.textBlack};
`;

const ResultsText = styled.div`
  display: inline-flex;
  margin-left: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.textBlack};
`;

const StyledSortText = styled.div`
  padding-left: 1.5rem;
`;

const StyledBackButton = styled(ButtonHyperLink)`
  margin-left: 1.25rem;
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.colors.hyperlinkBlue};
  }
`;

const SearchDetails = memo(({ searchQuery, toggleSearchView, loadedResults }) => {
  const { t } = useTranslation();
  const resultsText =
    loadedResults.length === 1
      ? t('faqs.searchpage.result', { count: loadedResults.length })
      : t('faqs.searchpage.multiple_results', { count: loadedResults.length });
  const sortedText = loadedResults.length > 1 ? t('faqs.searchpage.sorted') : null;

  const backToMainMenu = () => {
    toggleSearchView();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'back-to-help-page',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  return (
    <Panel>
      <StyledBackButton data-testid="back-to-menu-button" onClick={backToMainMenu}>
        {t('faqs.searchpage.go_back')}
      </StyledBackButton>
      {searchQuery ? (
        <HeaderStyle>
          {t('faqs.searchpage.search_results_for')} &quot;{searchQuery}&quot;
        </HeaderStyle>
      ) : (
        <HeaderStyle>
          {t('faqs.searchpage.search_results_for')} &quot;{t('faqs.searchpage.search_placeholder')}t&quot;
        </HeaderStyle>
      )}
      <ResultsText>
        <div data-testid="number-of-results">{resultsText}</div>{' '}
        <StyledSortText data-testid="sorted-by-relevance">{sortedText}</StyledSortText>
      </ResultsText>
      {loadedResults ? <AccordionCards loadedResults={loadedResults} isResultsPage searchQuery={searchQuery} /> : <div />}
    </Panel>
  );
});

SearchDetails.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  toggleSearchView: PropTypes.func.isRequired,
  loadedResults: PropTypes.arrayOf(PropTypes.shape({ category: PropTypes.string })).isRequired,
};

export default SearchDetails;
