import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import ResponsiveCol from 'views/loans/components/ResponsiveCol';
import {
  formatDate,
  formatStyledMoneyFromCents,
  formatMoneyFromCents,
  formatMaskedCreditCardNumberWithBullets,
} from 'lib/Formatters';
import { MaterialIcon } from 'styles/Icons';
import { Col, Row, Data } from 'styles/CommonStyles';
import { PaymentHistoryBody } from 'styles/PaymentHistoryStyles';

export const REFUND_THRESHOLD = 50;

const History = styled.div`
  color: #555555;

  font-size: 0.9rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.minWidth.nano}) {
    & + & {
      margin-top: 1rem;
    }
  }
`;

const StyledDetails = styled.div`
  font-size: 0.9rem;
  padding: 1rem 0;
`;

const StyledRow = styled.div`
  cursor: ${(props) => (props.hideDropdownStyles ? 'default' : 'pointer')};
  display: flex;
  flex-wrap: nowrap;

  margin-right: -15px;
  margin-left: -15px;
`;

const Date = styled.div`
  color: ${(props) => (props.status === 'next-payment' ? props.theme.colors.upliftTeal : '')};
  color: ${(props) => (props.status === 'refunded' || props.status === 'suspense' ? props.theme.colors.secondaryMediumBlue : '')};
  color: ${(props) => (props.isError ? props.theme.colors.error : '')};
  color: ${(props) => (props.isHardshipPayment ? props.theme.colors.hardshipStatusOrange : '')};
`;

const SeeMore = styled.span`
  color: #6d7075;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    color: #8e9093;
  }
`;

const SmallData = styled(Data)`
  text-align: right;
  font-weight: ${(props) => (props.status === 'next-payment' || props.isHardshipPayment ? '700' : '')} !important;
  font-color: ${(props) =>
    props.status === 'overdue' || props.status === 'charged-off' ? props.theme.colors.error : ''} !important;
  font-size: 0.9rem;
  /* min-width: 75px; */
  color: ${(props) => (props.status === 'next-payment' ? props.theme.colors.upliftTeal : '')} !important;
  color: ${(props) => (props.isError ? props.theme.colors.error : '')} !important;
  color: ${(props) =>
    props.status === 'refunded' || props.status === 'suspense' ? props.theme.colors.secondaryMediumBlue : ''} !important;
  color: ${(props) => (props.isFailOrCanceled ? '#a9acb2' : '')};
  color: ${(props) => (props.isHardshipPayment ? props.theme.colors.hardshipStatusOrange : '')} !important;
`;

const Status = styled.div`
  color: ${(props) => (props.isHardshipPayment ? props.theme.colors.hardshipStatusOrange : '')} !important;
  color: ${(props) => (props.isError ? props.theme.colors.error : '')} !important;
  color: ${(props) => (props.isNextPayment ? props.theme.colors.upliftTeal : '')};
  color: ${(props) => (props.status === 'refunded' || props.status === 'suspense' ? props.theme.colors.secondaryMediumBlue : '')};
  font-weight: ${(props) => (props.status === 'overdue' || props.status === 'charged-off' ? '700' : '')};
  font-weight: ${(props) => (props.isNextPayment ? '700' : '')};
  font-weight: ${(props) => (props.isHardshipPayment ? '700' : '')};
`;

// prettier-ignore
const LoanHistoryDropdown = memo(
  ({
    uniqueKey,
    status,
    headerDate,
    headerStatus,
    headerAmount,
    headerMessage,
    payment,
    prefix1,
    prefix2,
    prefix3,
    prefix4,
    field1,
    field2,
    field3,
    field4,
    amount1,
    amount2,
    amount3,
    amount4,
    isHardshipPayment,
  }) => {
    // NOSONAR
    const [showPaymentHistory, setShowPaymentHistory] = useState({});

    const toggleSeeMore = useCallback(
      (paymentId) => {
        // google analytics
        const gaEvent = {
          category: 'user',
          action: 'click',
          label: `transaction-type-${status}`,
          value: 1,
        };
        trackEvent(gaEvent);

        // cevents
        let metadata = {};
        metadata.uplift_account_id = localStorage.getItem('account_id_hash');
        metadata.isAdmin = localStorage.getItem('adminAuth');
        metadata = toKebabCase(metadata);

        cevents.setLevel('debug');
        cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

        if (!showPaymentHistory[paymentId]) {
          setShowPaymentHistory({ ...showPaymentHistory, [paymentId]: true });
        } else {
          setShowPaymentHistory({ ...showPaymentHistory, [paymentId]: false });
        }
      },
      [showPaymentHistory, status]
    );

    const hideHistory =
      status === 'canceled' ||
      status === 'overdue' ||
      status === 'next-payment' ||
      status === 'charged-off' ||
      (status === 'paid' && headerAmount < REFUND_THRESHOLD);
    const errorStatus = status === 'overdue' || status === 'charged-off' || status === 'refund-failed';

    return (
      <History data-testid="history-dropdown">
        <PaymentHistoryBody
          isBorder={showPaymentHistory[uniqueKey] && status !== 'canceled'}
          hideDropdownStyles={hideHistory}
          key={uniqueKey}
        >
          <StyledRow hideDropdownStyles={hideHistory} onClick={() => toggleSeeMore(uniqueKey)}>
            <ResponsiveCol xs={6}>
              <Col xs={5}>
                <Date status={status} isHardshipPayment={isHardshipPayment} isError={errorStatus} data-testid={`${status}-date`}>
                  {formatDate(headerDate)}
                </Date>
              </Col>
              <Col xs={7} data-testid={status}>
                <Status
                  status={status}
                  isError={errorStatus}
                  isNextPayment={status === 'next-payment'}
                  isHardshipPayment={isHardshipPayment}
                >
                  {headerStatus}
                </Status>
              </Col>
            </ResponsiveCol>
            <ResponsiveCol xs={5}>
              <Col xs={6}>
                <Status
                  status={status}
                  isError={errorStatus}
                  isNextPayment={status === 'next-payment'}
                  isHardshipPayment={isHardshipPayment}
                >
                  {payment?.paymentMethodMask ? formatMaskedCreditCardNumberWithBullets(payment.paymentMethodMask) : ''}
                </Status>
              </Col>
              <Col xs={6} className="d-flex justify-content-end align-items-center">
                <SmallData
                  data-testid={`${status}-value`}
                  status={status}
                  isError={errorStatus}
                  isFailOrCanceled={status === 'failed' || status === 'canceled'}
                  isHardshipPayment={isHardshipPayment}
                >
                  {status === 'refunded' || status === 'refund-failed' || status === 'suspense' || status === 'adjustment'
                    ? '-'
                    : ''}
                  {formatStyledMoneyFromCents(headerAmount, status)}
                </SmallData>
              </Col>
            </ResponsiveCol>
            {(headerMessage || field1) && status !== 'canceled' && headerAmount >= REFUND_THRESHOLD && (
              <Col xs={1} className="d-flex justify-content-end">
                <SeeMore data-testid="see-more">
                  {showPaymentHistory[uniqueKey] ? <MaterialIcon name="expand_less" /> : <MaterialIcon name="expand_more" />}
                </SeeMore>
              </Col>
            )}
          </StyledRow>

          {showPaymentHistory[uniqueKey] && !hideHistory && (
            <StyledDetails data-testid="history-dropdown-details">
              {headerMessage && (
                <Row style={{ paddingBottom: `${headerMessage && field1 ? '1rem' : '0'}` }}>
                  <Col>{headerMessage}</Col>
                </Row>
              )}

              {field1 && (
                <Row>
                  {prefix1 && <Col xs={1}>{prefix1}</Col>}
                  <Col>{field1}</Col>
                  <Col xs={3} data-testid="history-field-1-amount">
                    {formatMoneyFromCents(amount1)}
                  </Col>
                </Row>
              )}

              {field2 && (
                <Row>
                  {prefix2 && <Col xs={1}>{prefix2}</Col>}
                  <Col>{field2}</Col>
                  <Col xs={3} data-testid="history-field-2-amount">
                    {formatMoneyFromCents(amount2)}
                  </Col>
                </Row>
              )}

              {field3 && (
                <Row>
                  {prefix3 && <Col xs={1}>{prefix3}</Col>}
                  <Col>{field3}</Col>
                  <Col xs={3} data-testid="history-field-3-amount">
                    {formatMoneyFromCents(amount3)}
                  </Col>
                </Row>
              )}

              {field4 && (
                <Row>
                  {prefix4 && <Col xs={1}>{prefix4}</Col>}
                  <Col>{field4}</Col>
                  <Col xs={3} data-testid="history-field-4-amount">
                    {formatMoneyFromCents(amount4)}
                  </Col>
                </Row>
              )}
            </StyledDetails>
          )}
        </PaymentHistoryBody>
      </History>
    );
  }
);

LoanHistoryDropdown.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  payment: PropTypes.shape({ paymentMethodMask: PropTypes.string }).isRequired,
  headerDate: PropTypes.string.isRequired,
  headerAmount: PropTypes.number.isRequired,
  headerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ type: PropTypes.symbol })]),
  headerStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ type: PropTypes.symbol })]),
  prefix1: PropTypes.string,
  prefix2: PropTypes.string,
  prefix3: PropTypes.string,
  prefix4: PropTypes.string,
  field1: PropTypes.string,
  field2: PropTypes.string,
  field3: PropTypes.string,
  field4: PropTypes.string,
  amount1: PropTypes.number,
  amount2: PropTypes.number,
  amount3: PropTypes.number,
  amount4: PropTypes.number,
  status: PropTypes.string,
  isHardshipPayment: PropTypes.bool,
};

LoanHistoryDropdown.defaultProps = {
  headerMessage: null,
  headerStatus: null,
  prefix1: null,
  prefix2: null,
  prefix3: null,
  prefix4: null,
  field1: null,
  field2: null,
  field3: null,
  field4: null,
  amount1: null,
  amount2: null,
  amount3: null,
  amount4: null,
  status: null,
  isHardshipPayment: false,
};

export default LoanHistoryDropdown;
