import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, ButtonOutline } from 'styles/CommonStyles';
import { StyledFormHeader } from '../styles/HardshipStyles';

const HardshipConfirmation = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <div>
      <StyledFormHeader>{t('loans.hardship.enroll_hardship_prompt')}</StyledFormHeader>
      <Button className="btn-block text-uppercase mt-4" onClick={onConfirm} type="button">
        {t('common.buttons.enroll_now')}
      </Button>
      <ButtonOutline className="btn-block text-uppercase" onClick={onCancel} type="button">
        {t('common.buttons.cancel')}
      </ButtonOutline>
    </div>
  );
};

HardshipConfirmation.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

HardshipConfirmation.defaultProps = {
  onCancel: undefined,
  onConfirm: undefined,
};

export default HardshipConfirmation;
