import ReactGA from 'react-ga';

const trackEvent = (event) => {
  const { category, action, label, value, nonInteraction = false } = event;

  // Add custom dimension 'account' to all events
  ReactGA.set({ dimension1: localStorage.getItem('account_id_hash') });

  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  });
};

export default trackEvent;
