import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card } from 'styles/CommonStyles';
import HardshipConfirmation from './HardshipConfirmation';
import HardshipPaymentForm from './HardshipPaymentForm';

const StyledHardshipCard = styled(Card)`
  border-radius: 5px;
  padding: 18px 12px;
`;

const PAYMENT_STEP = 'payment';
const CONFIRMATION_STEP = 'confirmation';

const HardshipPanel = ({ className, loanId, onCancel, onConfirm, requiredPayment, style, isLoading }) => {
  const [step, setStep] = useState(PAYMENT_STEP);
  const [amount, setAmount] = useState(0);

  const onPaymentSubmit = ({ hardshipPaymentAmount }) => {
    setAmount(hardshipPaymentAmount);
    setStep(CONFIRMATION_STEP);
  };

  const handleConfirm = async () => {
    if (typeof onConfirm === 'function') {
      onConfirm({ hardshipPaymentAmount: amount });
    }
  };

  return (
    <StyledHardshipCard className={className} style={style}>
      {step === PAYMENT_STEP && (
        <HardshipPaymentForm loanId={loanId} onSubmit={onPaymentSubmit} requiredPayment={requiredPayment} isLoading={isLoading} />
      )}
      {step === CONFIRMATION_STEP && <HardshipConfirmation onCancel={onCancel} onConfirm={handleConfirm} />}
    </StyledHardshipCard>
  );
};

HardshipPanel.propTypes = {
  className: PropTypes.string,
  loanId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  requiredPayment: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

HardshipPanel.defaultProps = {
  className: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  isLoading: false,
  requiredPayment: undefined,
  style: undefined,
};

export default HardshipPanel;
