import { useLayoutEffect, useRef } from 'react';

const useAutoFocus = () => {
  const inputRef = useRef();

  useLayoutEffect(() => {
    inputRef.current.focus();
  }, []);

  return inputRef;
};

export default useAutoFocus;
