import { httpError } from 'lib/utils';
import { ACTIONS } from 'state/actionTypes';

export const postEnrollHardshipReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.postEnrollHardship:
      return {
        ...state,
        [action.meta.loanId]: {
          isLoading: true,
        },
      };
    case ACTIONS.postEnrollHardshipSuccess:
      return {
        ...state,
        [action.meta.previousAction.meta.loanId]: {
          isLoading: false,
          data: action.payload.data,
        },
      };
    case ACTIONS.postEnrollHardshipFail:
      return {
        ...state,
        [action.meta.previousAction.meta.loanId]: {
          isLoading: false,
          error: httpError(action),
        },
      };
    case ACTIONS.postEnrollHardshipReset:
      return {
        ...state,
        [action.loanId]: {},
      };
    default:
      return state;
  }
};

export default postEnrollHardshipReducer;
