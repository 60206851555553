import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import flexPayLogo from 'lib/assets/images/flexpay.svg';
import Container from 'styles/Container';
import OutboundLink from 'analytics/components/OutboundLink';
import { MaterialIcon } from 'styles/Icons';

const FooterItem = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomerSupportContainer = styled.div`
  display: flex;
  padding-top: 32px;
`;

const CenteredIcons = styled(MaterialIcon)`
  line-height: 24px;
`;

const StyledContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.upliftLightestTeal};

  padding-bottom: 2rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    padding: 32px;
  }
`;

const Section = styled.div`
  margin: 0.5rem 0;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin: 2rem;
  }
`;

const PayMonthlyLogo = styled.img`
  height: 49.5px;
  width: 256px;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 256px;
  }
`;

const BrandMessage = styled.div`
  color: ${(props) => (props.isCaption ? props.theme.colors.upgradeGrey : props.theme.colors.upgradeGreen)};
  font-weight: ${(props) => (props.isCaption ? 400 : 600)};

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    font-size: 21px;
    line-height: 26px;
  }
`;

const FAQLink = styled.a`
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  font-weight: 400;
  font-size: 1.25rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    width: 11.25rem;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 11.25rem;
  }
`;

const Slogan = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const iconSize = '1.2rem';
const iconColor = '#7F7F7F';

const Authenticator = memo(({ content }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer className="d-flex flex-md-row flex-sm-column justify-content-center align-items-center">
      <Section>
        <PayMonthlyLogo src={flexPayLogo} alt="Uplift Logo" />

        <Slogan>
          <BrandMessage>{t('authentication.sign_in.uplift_is_becoming_flexpay')}</BrandMessage>
          <BrandMessage isCaption>{t('authentication.sign_in.new_look_same_product')}</BrandMessage>
        </Slogan>

        <FAQLink target="_blank" href="https://www.upgrade.com/flex-pay/frequently-asked-questions/">
          {t('authentication.sign_in.see_faq')}
        </FAQLink>
      </Section>

      <Section className="d-flex">
        <div className="d-flex flex-column align-items-center">
          {content}
          <CustomerSupportContainer>
            <FooterItem className="mr-3" style={{ whiteSpace: 'nowrap' }}>
              {t('authentication.footer.customer_support')}
            </FooterItem>

            <FooterItem>
              <CenteredIcons name="mail_outline" size={iconSize} color={iconColor} style={{ marginRight: '0.2rem' }} />
              <Trans i18nKey="authentication.footer.email">
                a
                <OutboundLink eventLabel="uplift-support-email" to="mailto:customercare@upgrade.com">
                  customercare@upgrade.com
                </OutboundLink>
              </Trans>

              <div className="mx-2">|</div>

              <CenteredIcons
                name="phone"
                theme="outlined"
                size={iconSize}
                color={iconColor}
                style={{ marginRight: '0.2rem', rotate: '270deg' }}
              />
              <OutboundLink
                eventLabel="uplift-support-phone"
                to={t('authentication.footer.tel_link')}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('authentication.footer.phone')}
              </OutboundLink>
            </FooterItem>
          </CustomerSupportContainer>
        </div>
      </Section>
    </StyledContainer>
  );
});

Authenticator.displayName = 'Authenticator';

Authenticator.propTypes = {
  content: PropTypes.shape({ typeof: PropTypes.symbol }).isRequired,
};

export default Authenticator;
