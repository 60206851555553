/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import axios from 'axios';
import { isAdminUserSelector } from 'state/auth/selectors';
import { awsRequestInterceptor, awsResponseInterceptor } from 'AwsInterceptors';
import { ACTIONS } from 'state/actionTypes';

const axiosClient = axios.create({
  // all axios can be used, shown in axios documentation
  // baseURL: process.env.REACT_APP_BORROWER_PORTAL_API_BASE_URL,
  responseType: 'json',
  headers: {
    common: {
      'x-up-requested-from': 'BORROWER_PORTAL',
    },
  },
  // Pass Authorization header to OPTIONS call
  // https://github.com/axios/axios/issues/1777
  // withCredentials: true,
});

export const middlewareConfig = {
  interceptors: {
    request: [
      function({ getState, dispatch, getSourceAction }, req) {
        if (getSourceAction(req)?.type === ACTIONS.ensureUser) {
          req.baseURL = process.env.REACT_APP_ENSURE_USER_API_URL;
          return req;
        }
        // 'axiosClient: request interceptor: config: ', req
        const state = getState();
        const isAdminUser = isAdminUserSelector(state);

        req.baseURL = isAdminUser
          ? process.env.REACT_APP_ADMIN_BORROWER_PORTAL_API_BASE_URL
          : process.env.REACT_APP_BORROWER_PORTAL_API_BASE_URL;

        return req;
      },
      function({ getState, dispatch, getSourceAction }, req) {
        // ensureUser is a public endpoint -- do not verify auth
        if (getSourceAction(req)?.type === ACTIONS.ensureUser) {
          return req;
        }
        return awsRequestInterceptor(req);
      },
    ],
    response: [
      function({ getState, dispatch, getSourceAction }, res) {
        // 'axiosClient: response interceptor: config: ', res
        return res;
      },
      {
        error: ({ dispatch }, error) => {
          return awsResponseInterceptor(error, dispatch);
        },
      },
      // function ({getState, dispatch, getSourceAction}, req) {
      //   //...
      // }
    ],
  },
};

export default axiosClient;
