/* eslint-disable no-shadow */
import { useEffect, useRef, useCallback, useState } from 'react';

export const useTimeoutState = (delay, initialValue) => {
  // save id in a ref
  const timeoutId = useRef('');
  const [value, setValue] = useState(initialValue);

  // clear the timeout and start a new one, updating the timeoutId ref
  const reset = useCallback(
    // prettier-ignore
    (value) => { // NOSONAR
      clearTimeout(timeoutId.current);
      setValue(value);

      const id = setTimeout(() => setValue((value) => !value), delay); // NOSONAR
      timeoutId.current = id;
    },
    [delay]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay !== null) {
      reset();

      return () => clearTimeout(timeoutId.current);
    }
  }, [delay, reset]);

  return [value, reset];
};

export default useTimeoutState;

// https://stackoverflow.com/questions/30803440/delayed-rendering-of-react-components
