import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import OverdueMessage from 'views/loans/schedulePayments/OverdueMessage';

import { Body } from 'styles/CommonStyles';

const SoldDebt = memo((props) => {
  const { company, phoneHref, phoneNumber, shortName, url, urlHref } = props;

  return (
    <>
      <Body>
        <OverdueMessage
          header={
            <>
              <Trans i18nKey="loans.history.sold_debt_p1">Your Uplift loan was transferred to {{ companyName: company }}.</Trans>
            </>
          }
          message={
            <>
              <Trans i18nKey="loans.history.sold_debt_p2">
                {{ shortCompanyName: shortName }} purchased your past due debt from Uplift.
              </Trans>
              <br />
              <br />
              <Trans i18nKey="loans.history.sold_debt_p3">
                This means that you now owe the outstanding balance to {{ shortCompanyName: shortName }}
                and they will collect your payments. You can reach {{ shortCompanyName: shortName }}
              </Trans>
              <Trans i18nKey="loans.history.sold_debt_p4">
                by phone at
                <a href={phoneHref}>{{ phoneNumber }}</a>
                or online at
                <a href={urlHref}>{{ url }}</a>.
              </Trans>
            </>
          }
        />
      </Body>
    </>
  );
});

SoldDebt.displayName = 'SoldDebt';

SoldDebt.propTypes = {
  company: PropTypes.string.isRequired,
  phoneHref: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlHref: PropTypes.string.isRequired,
};

export default SoldDebt;
