import { httpError } from 'lib/utils';
import { ACTIONS } from 'state/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const initialStateVerify = {
  ...initialState,
  logoutSource: null,
};

export const postAccountUpdateInitializeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.postAccountUpdateInitialize:
      return {
        loading: true,
        error: null,
        data: null,
      };
    case ACTIONS.postAccountUpdateInitializeSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ACTIONS.postAccountUpdateInitializeFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.resetAccountUpdate:
      return initialState;
    case ACTIONS.resetAccountUpdateInitialize:
      return initialState;
    default:
      return state;
  }
};

export const postAccountUpdateVerifyReducer = (state = initialStateVerify, action) => {
  switch (action.type) {
    case ACTIONS.postAccountUpdateVerify:
      return {
        loading: true,
        error: null,
        data: null,
        logoutSource: null,
      };
    case ACTIONS.postAccountUpdateVerifySuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        logoutSource: null,
      };
    case ACTIONS.postAccountUpdateVerifyFail:
      return {
        ...state,
        loading: false,
        logoutSource: null,
        error: httpError(action),
      };
    case ACTIONS.resetAccountUpdate:
      return initialStateVerify;
    case ACTIONS.resetAccountUpdateVerify:
      return initialStateVerify;
    case ACTIONS.logout:
      return {
        ...state,
        logoutSource: action.payload,
      };
    case ACTIONS.logoutFail:
      return {
        ...state,
        logoutSource: null,
      };
    case ACTIONS.dismissLoggedOutMessage:
      return {
        ...state,
        logoutSource: null,
      };
    default:
      return state;
  }
};
