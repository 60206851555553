import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MaterialIcon } from 'styles/Icons';
import { IconDisc, IconColor } from 'styles/CommonStyles';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

const HR = styled.hr`
  margin-bottom: 0;
  width: 90%;
`;

export const PanelHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  margin: 2rem 0 0 0;
`;

const SectionDivider = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
`;

const HeaderName = styled.div`
  color: #555555;
  font-weight: bold;
  padding-top: 0.5rem;
  text-transform: uppercase;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    margin-bottom: 1rem;
  }
`;

const ViewStatement = styled.div`
  display: flex;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.upliftTeal};
  font-size: 0.8rem;
  padding: 0.5rem 1.2rem 0 0;
`;

const iconColor2 = '#156CED';
const iconSize = '1.5rem';
const iconSize2 = '1.1rem';

const LoanSpacer = memo(({ iconName, headerName, dataTestId, viewStatement, loanDetails, iconTheme }) => {
  const { t } = useTranslation();

  const onViewStatement = () => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'view-statement',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
  };

  return (
    <PanelHeader>
      <IconDisc>
        <MaterialIcon name={iconName} theme={iconTheme} size={iconSize} color={IconColor} />
      </IconDisc>
      <SectionDivider>
        <HR />
        <HeaderSection>
          <HeaderName data-testid={dataTestId}>{headerName}</HeaderName>
          {viewStatement && (
            <ViewStatement data-testid="view-statement" onClick={onViewStatement}>
              <MaterialIcon name="insert_drive_file" size={iconSize2} color={iconColor2} />
              <NavLink to={{ pathname: `loans/${loanDetails.loanId}/statement` }}>{t('loans.history.view_statement')}</NavLink>
            </ViewStatement>
          )}
        </HeaderSection>
      </SectionDivider>
    </PanelHeader>
  );
});

LoanSpacer.displayName = 'LoanSpacer';

LoanSpacer.propTypes = {
  iconName: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  viewStatement: PropTypes.bool,
  loanDetails: PropTypes.shape({
    loanId: PropTypes.string,
  }),
  iconTheme: PropTypes.string,
};

LoanSpacer.defaultProps = {
  viewStatement: null,
  loanDetails: null,
  iconTheme: 'outlined',
};

export default LoanSpacer;
