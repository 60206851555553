import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { CCHeader } from 'styles/PaymentMethodStyles';
import Theme from 'styles/Theme';

import MerchantLoans from './MerchantLoans';
import MerchantLoansMobile from './MerchantLoansMobile';

const WIDTH_QUERY = `(max-width: ${Theme.minWidth.nano})`;

const PaymentMethodLoans = memo((props) => {
  const { t } = useTranslation();
  const [isMobile, setMobile] = useState(window.matchMedia(WIDTH_QUERY).matches);

  useEffect(() => {
    const screenWidthQuery = window.matchMedia(WIDTH_QUERY);
    const handler = (mediaQueryListEvent) => {
      setMobile(mediaQueryListEvent.matches);
    };
    screenWidthQuery.addEventListener('change', handler);
    return () => screenWidthQuery.removeEventListener('change', handler);
  }, []);

  const { merchantLoansMap } = props;
  if (!merchantLoansMap) {
    return null;
  }

  return (
    <>
      <CCHeader>{t('account.payment_methods.details.loans_associated')}</CCHeader>
      {isMobile ? (
        <MerchantLoansMobile merchantLoansMap={merchantLoansMap} />
      ) : (
        <MerchantLoans merchantLoansMap={merchantLoansMap} />
      )}
    </>
  );
});

PaymentMethodLoans.displayName = 'PaymentMethodLoans';
PaymentMethodLoans.propTypes = {
  merchantLoansMap: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default PaymentMethodLoans;
