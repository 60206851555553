import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { deleteSchedulePaymentSelector } from 'state/loans/makeScheduledPayments/selectors';

import Loader from 'components/Loader';

import { Alert } from 'styles/CommonStyles';
import { Body } from 'styles/PaymentHistoryStyles';

const SchedulePaymentStatus = memo(() => {
  const { t } = useTranslation();

  const { loading, error, data } = useSelector(deleteSchedulePaymentSelector);

  let result = null;
  if (loading) {
    return (
      <Body>
        <Loader />
      </Body>
    );
  }
  if (data) {
    result = (
      <Body>
        <Alert variant="success">
          <strong>{t('loans.payment.schedule_payment.status.schedule_payment_canceled_p1')}</strong>
          <br />
          {t('loans.payment.schedule_payment.status.schedule_payment_canceled_p2')}
        </Alert>
      </Body>
    );
  } else if (error) {
    result = (
      <Body>
        <Alert variant="warning">
          <strong>{t('loans.payment.schedule_payment.status.error_processing')}</strong>
        </Alert>
      </Body>
    );
  }

  return <>{result}</>;
});

export default SchedulePaymentStatus;
