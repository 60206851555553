import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { noOp } from 'lib/utils';
import { autoPayPersistLoadingSelector } from 'state/autoPay/selectors';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import AutoPayDocument from './AutoPayDocument';

const AutoPayAuthorizationModal = ({ documentUrl, loanId, merchantInfo, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const autoPayPersistLoading = useSelector(autoPayPersistLoadingSelector);

  return (
    <CommonModal
      title={t('documents.dcd')}
      body1={<AutoPayDocument documentUrl={documentUrl} loanId={loanId} merchantInfo={merchantInfo} />}
      button1={t('account.payment_methods.modal.agree_and_continue')}
      button2={t('common.buttons.cancel')}
      isLoading={autoPayPersistLoading}
      submitEvent1={onConfirm}
      submitEvent2={onClose}
      onClose={onClose}
      width="600px"
    />
  );
};

AutoPayAuthorizationModal.propTypes = {
  documentUrl: PropTypes.string.isRequired,
  loanId: PropTypes.string,
  merchantInfo: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

AutoPayAuthorizationModal.defaultProps = {
  loanId: '-',
  merchantInfo: null,
  onClose: noOp,
  onConfirm: noOp,
};

export default AutoPayAuthorizationModal;
