import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const paymentMethodsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.getPaymentMethods:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIONS.getPaymentMethodsSuccess:
      return {
        data: action.payload.data.paymentMethods,
      };
    case ACTIONS.getPaymentMethodsFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.selectedPaymentMethod:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};

export const associatePaymentMethodBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.associatePaymentMethodsBatch:
      return { isLoading: true };
    case ACTIONS.associatePaymentMethodsBatchDone:
      return {
        ...action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const paymentMethodPrefillReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.setPaymentMethodPrefillLoans:
      return { loanIds: action.payload };
    default:
      return state;
  }
};

export default paymentMethodsReducer;
