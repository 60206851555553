import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonOutlineNavLink } from 'styles/CommonStyles';
import CommonModal from 'views/account/paymentMethods/CommonModal';

const StyledButtonLink = styled(ButtonOutlineNavLink)`
  width: 100%;
`;

const AutoPayEnabledModal = ({ loanId, onClose, onViewDocumentClick }) => {
  const { t } = useTranslation();

  return (
    <CommonModal
      body1={
        <>
          <p>{t('account.payment_methods.autopay.autopay_ensures')}</p>
          <p>{t('account.payment_methods.autopay.if_disable_autopay')}</p>
          <p>
            <Trans i18nKey="account.payment_methods.autopay.support_hours">
              a<a href="tel:+1 844-257-5400">1 844-257-5400</a>
            </Trans>
          </p>
        </>
      }
      customButton={
        <StyledButtonLink className="btn" to={`loans/${loanId}/document/autopay#autopay`} onClick={onViewDocumentClick}>
          {t('loans.payment.autopay_enabled_modal.view_authorization_document')}
        </StyledButtonLink>
      }
      onClose={onClose}
      title={t('loans.payment.autopay_enabled_modal.title')}
      titleIcon="autorenew"
    />
  );
};

AutoPayEnabledModal.propTypes = {
  loanId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onViewDocumentClick: PropTypes.func,
};

AutoPayEnabledModal.defaultProps = {
  onClose: undefined,
  onViewDocumentClick: undefined,
};

export default AutoPayEnabledModal;
