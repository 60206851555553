import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';
import { paymentMethodsSelector } from 'state/paymentMethods/selectors';

import { deleteSchedulePayment } from 'state/loans/makeScheduledPayments/actions';

import SchedulePaymentStatus from 'views/loans/schedulePayments/SchedulePaymentStatus';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import FormattedTrans from 'views/loans/components/FormattedTrans';
import LoanHistoryDropdown from 'views/loans/components/LoanHistoryDropdown';

import { formatMaskedCreditCardNumber } from 'lib/Formatters';

const CancelPayment = styled.div`
  /* font-size: 0.8rem; */
`;

const CancelPaymentButton = styled.div`
  color: ${(props) => props.theme.colors.upliftTeal};
  cursor: pointer;

  &:hover {
    color: #1c8f84;
    text-decoration: underline;
  }
`;

const DisabledCancelPaymentButton = styled.div`
  color: #999;
  cursor: not-allowed !important;
  text-decoration: underline;

  &:disabled {
    opacity: 0.65;
    box-shadow: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

// prettier-ignore
const ScheduledPaymentsHistory = memo(
  ({
    payment,
    paymentToken,
    showSchedulePaymentStatus,
    setShowSchedulePaymentStatus,
    showCancelPayment,
    setShowCancelPayment,
    showCancelPaymentModal,
    setShowCancelPaymentModal,
  }) => { // NOSONAR
    const { t } = useTranslation();

    const dispatch = useDispatch();
  const paymentMethods = useSelector(paymentMethodsSelector);
  const selectedPaymentMethod = paymentMethods?.find((method) => method.paymentMethodId === paymentToken);
  const maskedAccountNumber = formatMaskedCreditCardNumber(
    selectedPaymentMethod?.accountNumberMask || selectedPaymentMethod?.cardNumberMask
  );

    const toggleSeeMore = useCallback(
      (paymentId) => {
        if (!showCancelPayment[paymentId]) {
          setShowCancelPayment({ ...showCancelPayment, [paymentId]: true });
        } else {
          setShowCancelPayment({ ...showCancelPayment, [paymentId]: false });
        }
      },
      [setShowCancelPayment, showCancelPayment]
    );

    const toggleCancelPaymentModal = useCallback(
      (event, paymentId) => {
        // google analytics
        const gaEvent = {
          category: 'user',
          action: 'click',
          label: `${paymentId ? 'open' : 'close'}-cancel-schedule-payment-modal`,
          value: 1,
        };
        trackEvent(gaEvent);

        // cevents
        let metadata = {};
        metadata.value = paymentId ? 1 : 0;
        metadata.uplift_account_id = localStorage.getItem('account_id_hash');
        metadata.isAdmin = localStorage.getItem('adminAuth');
        metadata = toKebabCase(metadata);

        cevents.setLevel('debug');
        cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

        if (paymentId) {
          setShowCancelPaymentModal({ [paymentId]: true });
        } else {
          setShowCancelPaymentModal({});
        }
      },
      [setShowCancelPaymentModal]
    );

    const cancelPayment = useCallback(
      (event, loanId, paymentId) => {
        dispatch(deleteSchedulePayment(loanId, paymentId));
        toggleCancelPaymentModal();
        toggleSeeMore(paymentId);
        setShowSchedulePaymentStatus({ [paymentId]: true });
      },
      [dispatch, setShowSchedulePaymentStatus, toggleCancelPaymentModal, toggleSeeMore]
    );

    return (
      <>
        <div key={payment.paymentId}>
          {showSchedulePaymentStatus[payment.paymentId] && <SchedulePaymentStatus />}

          <LoanHistoryDropdown
            key={payment.paymentId}
            uniqueKey={payment.paymentId.toString()}
            status={payment.paymentStatus.toLowerCase()}
            headerDate={payment.date}
            headerStatus={
              <>
                <div>{t(`loans.payment.schedule_payment.scheduled_payment`)}</div>
                {(payment.paymentStatus === 'canceled' || payment.paymentStatus === 'pull/failed') && (
                  <div>({t(`loans.payment.schedule_payment.status_code.${payment.paymentStatus}`)})</div>
                )}
              </>
            }
            headerAmount={payment?.amount}
            headerMessage={
              <>
                <CancelPayment data-testid="cancel-payment">
                  <Trans
                    i18nKey="loans.payment.schedule_payment.scheduled_payment_expand_p1"
                    maskedAccountNumber={maskedAccountNumber}
                  >
                    This payment will be debited from your card ending in {{ maskedAccountNumber }}.
                  </Trans>
                  <br />
                  <br />
                  <div>{t('loans.payment.schedule_payment.scheduled_payment_expand_p2')}</div>
                  <br />
                  <Wrapper>
                    {payment.isCancellable ? (
                      <CancelPaymentButton
                        data-testid="cancel-payment-button"
                        onClick={(event) => toggleCancelPaymentModal(event, payment.paymentId)}
                      >
                        {t('loans.payment.schedule_payment.cancel_payment')}
                      </CancelPaymentButton>
                    ) : (
                      <DisabledCancelPaymentButton data-testid="cancel-payment-button" disabled>
                        {t('loans.payment.schedule_payment.cancel_payment')}
                      </DisabledCancelPaymentButton>
                    )}
                  </Wrapper>
                </CancelPayment>
              </>
            }
          />

          {showCancelPaymentModal[payment.paymentId] && (
            <CommonModal
              title={t('loans.payment.schedule_payment.modal.cancel_scheduled_payment')}
              body1={<FormattedTrans paymentAmount={payment.amount} paymentDate={payment.date} />}
              submitEvent1={(event) => cancelPayment(event, payment.loanId, payment.paymentId)}
              submitEvent2={toggleCancelPaymentModal}
              button1={t('loans.payment.schedule_payment.modal.yes_cancel_payment')}
              button2={t('loans.payment.schedule_payment.modal.no_dont_cancel')}
              onClose={toggleCancelPaymentModal}
            />
          )}
        </div>
      </>
    );
  }
);

ScheduledPaymentsHistory.displayName = 'ScheduledPaymentsHistory';

ScheduledPaymentsHistory.propTypes = {
  payment: PropTypes.shape({
    amount: PropTypes.number,
    date: PropTypes.string,
    isCancellable: PropTypes.bool,
    loanId: PropTypes.string,
    paymentId: PropTypes.number,
    paymentStatus: PropTypes.string,
  }).isRequired,
  showSchedulePaymentStatus: PropTypes.objectOf(PropTypes.bool).isRequired,
  setShowSchedulePaymentStatus: PropTypes.func.isRequired,
  showCancelPayment: PropTypes.objectOf(PropTypes.bool).isRequired,
  setShowCancelPayment: PropTypes.func.isRequired,
  showCancelPaymentModal: PropTypes.objectOf(PropTypes.bool).isRequired,
  setShowCancelPaymentModal: PropTypes.func.isRequired,
  paymentToken: PropTypes.string.isRequired,
};

export default ScheduledPaymentsHistory;
