import { useEffect } from 'react';

import { getAccount } from 'state/account/actions';

export const useGetAccount = (assumedAccountId, dispatch) => {
  useEffect(() => {
    dispatch(getAccount(assumedAccountId));
  }, [assumedAccountId, dispatch]);
};

export default useGetAccount;
