import React, { useCallback, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import Container from 'components/GlobalContainer';
import { failedDownpaymentLoansSelector } from 'state/loans/selectors';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import paymentFailed from 'lib/assets/images/payment-failed.svg';
import { MaterialIcon } from 'styles/Icons';
import { useStorage } from 'hooks/useStorage';
import AccountDetails from './AccountDetailsContainer';
// import PaymentMethods from './paymentMethods';
import Wallet from './wallet';

const DownPaymentFailed = styled.div`
  padding-bottom: 1rem;

  font-size: 1.1rem;
  font-weight: bold;
`;

const DownPaymentFailedForLoans = styled.div`
  padding-bottom: 1rem;
`;

const DownPaymentLoanInfo = styled.div`
  display: flex;

  margin-bottom: 0.4rem;
`;

const LoanID = styled.b`
  font-size: 0.9rem;
`;

const StyledButton = styled.button`
  background: #f3f3f3;
  border: none;
  color: #777;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #555555;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 0.9rem;
`;

const Account = memo(() => {
  const { t } = useTranslation();

  const failedDownpaymentLoans = useSelector(failedDownpaymentLoansSelector);

  const [display, setDisplay] = useStorage(sessionStorage, 'dismissDownPaymentModal');

  const closeDownpayModal = useCallback(() => {
    setDisplay(true);
  }, [setDisplay]);

  return (
    <Container>
      {failedDownpaymentLoans?.length > 0 && display !== true ? (
        <CommonModal
          titleBody={
            <>
              <div />
              <img src={paymentFailed} alt="payment failed" />
              <div className="d-flex align-items-start">
                <StyledButton data-testid="close-button" onClick={closeDownpayModal}>
                  <MaterialIcon name="close" />
                </StyledButton>
              </div>
            </>
          }
          body1={
            <>
              <DownPaymentFailed>{t('account.initial_payment.initial_payment_failed')}</DownPaymentFailed>
              <DownPaymentFailedForLoans>{t('account.initial_payment.initial_payment_failed_for_loans')}</DownPaymentFailedForLoans>
              {failedDownpaymentLoans?.map((loan) => {
                return (
                  <DownPaymentLoanInfo key={loan.loanId}>
                    <LoanID className="ml-3 mr-3">{`${t('loans.header.loan_id')} ${loan.loanId}`}</LoanID>
                    <Trans i18nKey="account.initial_payment.make_a_initial_payment">
                      <StyledNavLink to={{ pathname: '/loans', hash: `#${loan.loanId}` }} onClick={closeDownpayModal}>
                        {t('account.initial_payment.make_a_initial_payment')}
                      </StyledNavLink>
                    </Trans>
                  </DownPaymentLoanInfo>
                );
              })}
            </>
          }
          submitEvent1={closeDownpayModal}
          button1={t('common.buttons.close')}
          onClose={closeDownpayModal}
        />
      ) : null}
      <AccountDetails />
      <Wallet />
    </Container>
  );
});

Account.displayName = 'Account';

export default Account;
