import { useEffect } from 'react';

import { getLoans } from 'state/loans/actions';

export const useGetLoans = (accountId, dispatch) => {
  useEffect(() => {
    if (accountId) {
      dispatch(getLoans(accountId));
    }
  }, [accountId, dispatch]);
};

export default useGetLoans;
