import React, { memo, useState } from 'react';
import Container from 'components/GlobalContainer';
import HelpDetails from './HelpDetailsView';
import SearchDetails from './SearchDetails';

const Help = memo(() => {
  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadedResults, setloadedResults] = useState(null);

  const toggleSearchView = (query) => {
    setSearch(!search);
    setSearchQuery(query);
  };

  const showResults = (res) => {
    setloadedResults(res);
  };

  return (
    <Container>
      {search ? (
        <SearchDetails
          to="/help/search"
          toggleSearchView={toggleSearchView}
          searchQuery={searchQuery}
          loadedResults={loadedResults}
        />
      ) : (
        <HelpDetails to="/help" toggleSearchView={toggleSearchView} showResults={showResults} />
      )}
    </Container>
  );
});

export default Help;
