import { createSelector } from 'reselect';

export const autoPayInitializeSelector = createSelector(
  (state) => state.autoPayInitializeReducer,
  (autoPayInitializeReducer) => autoPayInitializeReducer.data
);

export const autoPayPersistSelector = createSelector(
  (state) => state.autoPayPersistReducer,
  (autoPayPersistReducer) => autoPayPersistReducer.data
);

export const autoPayInitializeLoadingSelector = createSelector(
  (state) => state.autoPayInitializeReducer,
  (autoPayInitializeReducer) => autoPayInitializeReducer.isLoading
);

export const autoPayInitializeErrorSelector = createSelector(
  (state) => state.autoPayInitializeReducer,
  (autoPayInitializeReducer) => autoPayInitializeReducer.error
);

export const autoPayInitializeBatchLoadingSelector = createSelector(
  (state) => state.autoPayInitializeBatchReducer,
  (autoPayInitializeBatchReducer) => autoPayInitializeBatchReducer.isLoading
);

export const autoPayInitializeBatchSuccessesSelector = createSelector(
  (state) => state.autoPayInitializeBatchReducer,
  (autoPayInitializeBatchReducer) => autoPayInitializeBatchReducer.successes
);

export const autoPayInitializeBatchFailuresSelector = createSelector(
  (state) => state.autoPayInitializeBatchReducer,
  (autoPayInitializeBatchReducer) => autoPayInitializeBatchReducer.failures
);

export const autoPayPersistLoadingSelector = createSelector(
  (state) => state.autoPayPersistReducer,
  (autoPayPersistReducer) => autoPayPersistReducer.isLoading
);

export const autoPayPersistErrorSelector = createSelector(
  (state) => state.autoPayPersistReducer,
  (autoPayPersistReducer) => autoPayPersistReducer.error
);

export const autoPaySessionIdSelector = createSelector(
  (state) => state.autoPayInitializeReducer && state.autoPayInitializeReducer.data && state.autoPayInitializeReducer.data.sessionId,
  (sessionId) => sessionId
);

export const setAutoPayModalStateSelector = createSelector(
  (state) => state.setAutoPayModalStateReducer,
  (setAutoPayModalStateReducer) => setAutoPayModalStateReducer.autoPayModalState
);

export const autoPayModalLoanSelector = createSelector(
  (state) => state.setAutoPayModalStateReducer,
  (setAutoPayModalStateReducer) => setAutoPayModalStateReducer.autoPayModalLoan
);

export const showAutoPayFailSelector = createSelector(
  (state) => state.autoPayAlertsReducer,
  (autoPayAlertsReducer) => autoPayAlertsReducer.isShowFail
);

export const showAutoPaySuccessSelector = createSelector(
  (state) => state.autoPayAlertsReducer,
  (autoPayAlertsReducer) => autoPayAlertsReducer.isShowSuccess
);

export const autoPayAlertLoanIdSelector = createSelector(
  (state) => state.autoPayAlertsReducer,
  (autoPayAlertsReducer) => autoPayAlertsReducer.loanId
);
