import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';

import { stripNonDigits } from 'lib/utils';

import {
  signIn,
  customChallengeAnswer,
  customChallengeReset,
  signUpStartOver,
  signInChallengeAnswerFormFail,
} from 'state/auth/actions';

import VerifyForm from './VerifyForm';

const VerifyContainer = memo(() => {
  const dispatch = useDispatch();

  const onAnswerChange = useCallback(
    (event) => {
      dispatch(customChallengeReset());
      // eslint-disable-next-line no-param-reassign
      event.target.value = stripNonDigits(event.target.value);
    },
    [dispatch]
  );

  const onCustomChallengeAnswer = useCallback(
    async (event) => {
      event.preventDefault();

      const { elements } = event.target;
      const answer = elements.answer.value;
      const answerElement = elements.namedItem('answer');

      if (event.target.checkValidity()) {
        dispatch(customChallengeAnswer(answer));
      } else if (answerElement && !answerElement.validity.valid) {
        dispatch(signInChallengeAnswerFormFail(answerElement.validationMessage));
      }
    },
    [dispatch]
  );

  const resendCode = useCallback(
    async (event, username) => {
      event.preventDefault();
      dispatch(signIn(username));
    },
    [dispatch]
  );

  const startOver = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(signUpStartOver());
    },
    [dispatch]
  );

  return (
    <VerifyForm
      customChallengeAnswer={onCustomChallengeAnswer}
      startOver={startOver}
      resendCode={resendCode}
      onAnswerChange={onAnswerChange}
    />
  );
});

export default VerifyContainer;
