/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import * as cevents from 'up.lib.js.cevents';

import { clean, rename, toKebabCase } from 'lib/utils';

import { ACTIONS } from 'state/actionTypes';

import { postPlaidLinkToken } from 'state/updatePaymentMethods/actions';

import { countrySelector } from 'state/account/selectors';
import { postPlaidLinkTokenSelector } from 'state/updatePaymentMethods/selectors';

import trackEvent from 'analytics/trackEvent';

const plaidOptions = {
  apiVersion: 'v2',
  clientName: 'This application',
  env: process.env.REACT_APP_PLAID_ENV,
  // key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
  product: ['auth'],
};

// prettier-ignore
export default ({ onSuccess, onFailure }) => { // NOSONAR
  const { i18n } = useTranslation();
  const country = useSelector(countrySelector);
  const linkToken = useSelector(postPlaidLinkTokenSelector);
  const linkHandler = useRef();
  const [plaidLoaded, setPlaidLoaded] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // console.log('usePlaidLink: PlaidLinkToken: ', linkToken);
  // console.log('usePlaidLink: location: ', location, window.location.href);

  const { pathname, search } = location;

  let language;

  if (i18n.language.toLowerCase() === 'fr-ca') {
    language = 'fr';
  } else if (['en', 'fr', 'es', 'nl'].includes(i18n.language)) {
    language = i18n.language;
  } else {
    language = 'en';
  }

  linkHandler.current = useMemo(() => {
    const isPlaidOathAccount = pathname === '/account/plaid' && window.location.search;
    return linkToken
      ? window.Plaid.create({
          ...plaidOptions,
          token: linkToken, // same link token from before
          receivedRedirectUri: isPlaidOathAccount ? window.location.href : null, // conditional (is the url /account/plaid or not)
          countryCodes: [country],
          language,
          onEvent: (eventName, metadata) => {
            // console.log('Plaid: handleOnEvent: link: user event: ', eventName, metadata);

            // cevents
            clean(metadata);
            metadata.eventName = eventName;
            metadata.target = 'bank-account-modal';
            metadata.uplift_account_id = localStorage.getItem('account_id_hash');
            rename(metadata, 'exit_status', 'status');
            metadata = toKebabCase(metadata);

            cevents.setLevel('debug');
            cevents.debugRaw('sys', 'handler', 'plaid', metadata);

            // google analytics
            const event = {
              category: 'plaid',
              action: 'event',
              label: eventName,
              value: 1,
            };

            trackEvent(event);
          },
          onExit: (error, metadata) => {
            // console.log('Plaid: handleOnExit: link: user exited: ', error, metadata);

            if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
              linkHandler.current.destroy();

              // Fetch a new plaidLinkToken
              dispatch(postPlaidLinkToken(true));
            } else if (isPlaidOathAccount) {
              linkHandler.current.destroy();

              // Fetch a new plaidLinkToken
              dispatch(postPlaidLinkToken(true));
              history.replace('/account');
            } else {
              // call an action that deletes plaidLinkToken from local storage
              dispatch({ type: ACTIONS.postPlaidLinkTokenReset });
            }

            // google analytics
            const event = {
              category: 'plaid',
              action: 'exit',
              label: 'exit',
              value: 1,
            };
            trackEvent(event);

            onFailure(error, metadata);
          },
          onLoad: () => {
            if (linkHandler.current !== null) {
              // console.log('Plaid: handleOnLoad: link: loaded');
              // google analytics
              const event = {
                category: 'plaid',
                action: 'load',
                label: 'loading-plaid',
                value: 1,
              };
              trackEvent(event);

              setPlaidLoaded(true);
            }
          },
          onSuccess: (token, metadata) => {
            // console.log('Plaid: handleOnSuccess: SUCCESS: ', token, metadata);
            // google analytics
            const event = {
              category: 'plaid',
              action: 'success',
              label: (metadata && metadata.institution && metadata.institution.name) || 'success',
              value: 1,
            };
            trackEvent(event);

            onSuccess(token, metadata);

            // Redirect user to account tab
            if (isPlaidOathAccount) {
              history.replace('/account');
            }
          },
        })
      : null;
  }, [country, dispatch, history, language, linkToken, onFailure, onSuccess, pathname]);

  useEffect(() => {
    dispatch(postPlaidLinkToken());

    return () => {
      const { current } = linkHandler;
      current && current.isOpen && current.exit();
      linkHandler.current = null;
    };
  }, [dispatch]);

  const open = useCallback(() => {
    if (linkHandler.current) {
      linkHandler.current.open();
      linkHandler.current.isOpen = true;

      localStorage.setItem("plaidLinkToken", CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(linkToken)));

      // cevents ( event is here to replicate what was in legacy BP)
      let metadata = {};
      metadata.target = 'button';
      metadata.label = 'Add a Bank Account';
      metadata.payment_method = 'bank-account';
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');

      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw('user', 'mouseup', 'open-bank-account-button', metadata);

      // google analytics
      const event = {
        category: 'plaid',
        action: 'open',
        label: 'opening-plaid-modal',
        value: 1,
      };
      trackEvent(event);
    }
  }, [linkToken]);

  const lh = linkHandler.current;

  useEffect(() => {
    if (search && lh) {
      history.replace('/account/plaid');

      open();
    }
  }, [history, lh, open, search]);

  return [plaidLoaded, open];
};
