import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const helpReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postHelpMessage:
      return {
        loading: true,
      };
    case ACTIONS.postHelpMessageSuccess:
      return {
        data: action.payload.data,
      };
    case ACTIONS.postHelpMessageFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.saveHelpDraft:
      return {
        ...state,
        draft: action.payload,
      };
    default:
      return state;
  }
};

export default helpReducer;
