import { useEffect } from 'react';

import { getApplications } from 'state/applications/actions';

export const useGetApplications = (accountId, dispatch) => {
  useEffect(() => {
    if (accountId) {
      dispatch(getApplications(accountId));
    }
  }, [accountId, dispatch]);
};

export default useGetApplications;
