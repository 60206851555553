import React, { memo, useCallback } from 'react';

import AccountForm from './AccountForm';

const AccountContainer = memo(() => {
  const onAccountChange = useCallback((event) => {
    event.preventDefault();
  }, []);

  return <AccountForm onAccountChange={onAccountChange} />;
});

export default AccountContainer;
