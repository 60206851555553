import { createSelector } from 'reselect';

const loanEnrollHardshipSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.postEnrollHardshipReducer,
  (state, loanId) => loanId,
  (data, loanId) => data && data[loanId]
);

export default loanEnrollHardshipSelector;
