import React, { memo } from 'react';

import Container from 'components/GlobalContainer';
import LoansDetails from './LoansDetailsContainer';

const Loans = memo(() => {
  return (
    <Container>
      <LoansDetails />
    </Container>
  );
});

Loans.displayName = 'Loans';

export default Loans;
