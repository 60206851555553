import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const deletePaymentReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.deletePaymentMethod:
      return {
        ...state,
        loading: true,
        data: null,
      };
    case ACTIONS.deletePaymentMethodSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ACTIONS.deletePaymentMethodFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.deletedPaymentMethod:
        return {
          ...state,
          deletedPaymentMethod: action.payload,
      };
    default:
      return state;
  }
};

export default deletePaymentReducer;
