/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Workbox } from 'workbox-window';

let Register;
let Unregister;

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const serviceWokerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  Register = async () => {
    const wb = new Workbox(serviceWokerUrl);

    const forceUpdate = (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      // prettier-ignore
      wb.addEventListener('controlling', (event) => { // NOSONAR
        window.location.reload();
      });

      // Send a message to the waiting service worker instructing
      // it to skip waiting, which will trigger the `controlling`
      // event listener above.
      // Note: for this to work, you have to add a message
      // listener in your service worker. See below.
      wb.messageSW({ type: 'SKIP_WAITING' });
    };

    wb.addEventListener('activated', (event) => {
      // `event.isUpdate` will be true if another version of the service
      // worker was controlling the page when this version was registered.
      if (!event.isUpdate) {
        console.log('Service-worker: Service worker activated for the first time!');

        // If your service worker is configured to precache assets, those
        // assets should all be available now.
      } else {
        console.log('Service-worker: Service worker activated as an update.');
      }
    });

    wb.addEventListener('waiting', (event) => {
      console.log(
        `Service-worker: A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`
      );
      // setTimeout(forceUpdate, 1000, event)
      forceUpdate(event);
    });
    // Only reload if the service worker update came from this tab
    // uncomment to update from any tab
    // wb.addEventListener('externalwaiting', forceUpdate)

    wb.addEventListener('message', (event) => {
      if (event.data.type === 'CACHE_UPDATED') {
        const { updatedURL } = event.data.payload;

        console.log(`Service-worker: A newer version of ${updatedURL} is available!`);
      }
    });

    await wb.register();

    wb.messageSW({ type: 'GET_VERSION' }).then((version) => console.log('Service-worker: has version: ', version));
  };
  Unregister = async () => {
    const wb = new Workbox(serviceWokerUrl);
    await wb.unregister();
    console.log('Service-worker: unregistered!');
  };
}

export const register = Register || (() => {});

export const unregister = Unregister || (() => {});
