import { createSelector } from 'reselect';

const MAXIMUM_NUMBER_OF_RETRIES = 5;

export const userSelector = createSelector(
  (state) => state.authenticationReducer.user,
  (user) => user
);

export const isAdminUserSelector = createSelector(
  (state) => state.authenticationReducer.user,
  (user) => {
    return (
      user &&
      user.signInUserSession &&
      user.signInUserSession.idToken &&
      user.signInUserSession.idToken.payload &&
      user.signInUserSession.idToken.payload.isAdmin === 'true'
    );
  }
);

export const assumedAccountIdSelector = createSelector(isAdminUserSelector, (isAdmin) => {
  return isAdmin ? localStorage.getItem('assumedAccountId') : undefined;
});

export const authSelector = createSelector(
  (state) => state.authenticationReducer,
  (auth) => auth
);

export const authVerifySelector = createSelector(
  (state) => state.authenticationReducer,
  ({ loading, error, user, challengeAnswerCount }) => ({
    loading,
    error,
    user,
    challengeAnswerCountExceeded: challengeAnswerCount >= MAXIMUM_NUMBER_OF_RETRIES,
  })
);

export const authShowVerifySelector = createSelector(
  (state) => state.authenticationReducer,
  ({ challenge }) => challenge
);
