import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

export const postCalculatePayoffReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.postCalculatePayoff:
      return { loading: true };
    case ACTIONS.postCalculatePayoffSuccess:
      return {
        loading: false,
        data: action.payload.data,
      };
    case ACTIONS.postCalculatePayoffFail:
      return {
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.postCalculatePayoffReset:
      return {};
    default:
      return state;
  }
};

export default postCalculatePayoffReducer;
