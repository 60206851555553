import { accountIdSelector } from 'state/account/selectors';
import { ACTIONS } from 'state/actionTypes';

const calculatePayoff = (loanId, numDays) => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  return dispatch({
    type: ACTIONS.postCalculatePayoff,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/payoff`,
        data: { numDays },
      },
    },
  });
};

export const resetCalculatePayoff = () => ({
  type: ACTIONS.postCalculatePayoffReset,
});

export default calculatePayoff;
