import moment from 'moment';

import { ACTIONS } from 'state/actionTypes';

import { accountIdSelector } from 'state/account/selectors';

export const postDelayPayment = (loanId, newPaymentDate) => (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  return dispatch({
    type: ACTIONS.postDelayPayment,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loans/${loanId}/delayPayment`,
        data: newPaymentDate,
      },
    },
    meta: { loanId, newPaymentDate: moment(newPaymentDate.delayPayment, 'MM/DD/YYYY').format('YYYY-MM-DD') },
  });
};

export const postDelayPaymentReset = () => ({ type: ACTIONS.postDelayPaymentReset });
