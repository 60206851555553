import styled from 'styled-components';
import { MaterialIcon } from 'styles/Icons';

export const BigButtonStyle = styled.div`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  box-shadow: 0px 4px 10px 0px rgba(0, 8, 22, 0.08), 0px 0px 2px 0px rgba(0, 8, 22, 0.16);

  padding: 1rem;

  border-radius: 16px;

  cursor: not-allowed;

  ${(props) =>
    !props.disabled &&
    `
      cursor: pointer;

      &:hover {
        border-bottom: 0.5rem solid ${props.theme.colors.upliftLightTeal};
        padding-bottom: 0.5rem;
      }
    
      &:active {
        border-bottom: 0.5rem solid ${props.theme.colors.upliftTeal};
        padding-bottom: 0.5rem;
      }
  `}
`;

export const MiniPanel = styled.div`
  display: ${(props) => (props.showCard ? 'block' : 'none')};
  background-color: ${(props) => (props.default ? props.theme.colors.upliftLightestTeal : '')};
  border: 1px solid ${(props) => (props.default ? props.theme.colors.upliftTealBorder : '#d1d3d7')};
  border-radius: 8px;

  margin: 2rem 0;
  padding: ${(props) => (props.padding ? props.padding : '2.5rem 1.5rem')};
`;

export const CCInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const CCHeader = styled.div`
  font-weight: 600;
  padding-bottom: 1rem;
`;

export const Error = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.error};
  border-radius: 16px;
  color: white;

  font-size: 0.65rem;
  font-weight: 600;
  padding: 5px 10px 5px 10px;
  margin: 5px;

  text-align: center;

  @media screen and (min-width: ${(props) => props.theme.minWidth.femto}) {
    padding: 5px 1rem;
    margin-right: 5px;
    font-size: 0.85rem;
  }
`;

export const HiddenDiv = styled.div`
  display: none;
  /* margin: 0 auto; */

  @media screen and (min-width: ${(props) => props.theme.minWidth[props.minWidth || 'micro']}) {
    display: ${(props) => (props.as === 'span' ? 'inline' : 'block')};
  }
`;

export const NanoPanel = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundBlue};
  color: ${(props) => props.theme.colors.upliftWhite};
  border: 1px solid ${(props) => props.theme.colors.backgroundBlue};
  border-radius: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;

  margin: 20px 5px 0px 10px;
  padding: 10px;
`;

export const ButtonGroup = styled.div`
  flex-shrink: 2;
  text-align: right;
`;

export const StatusRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 2;
  text-align: right;
`;

export const StyledMaterialIcon = styled(MaterialIcon)`
  &:hover {
    color: #fff;
  }
`;

export const StyledPaymentMethod = styled.div``;

export const iconColor = 'white';
export const iconSize = '1.2rem';
export const iconStyle = {
  marginRight: '4px',
};
