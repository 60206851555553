import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import CountrySelector from 'components/CountrySelector';
import SignInFooter from 'views/authenticator/components/SignInFooter';

import SignInSharedContainer from 'views/authenticator/SignInSharedContainer';
import { Panel } from 'styles/AuthStyles';
import AdminContainer from './AdminContainer';
import AccountContainer from './AccountContainer';
import { configure } from '../../AwsAmplify';

const renderAccountView = () => <AccountContainer />;

const renderAdminSignInView = () => <AdminContainer />;

const AdminAuthenticator = memo(() => {
  configure('beta');

  return (
    <SignInSharedContainer
      content={
        <Panel admin="true">
          <Switch>
            <Route exact path="/ops/account" render={renderAccountView} />
            <Route render={renderAdminSignInView} />
          </Switch>
          <SignInFooter />
          <CountrySelector />
        </Panel>
      }
    />
  );
});

AdminAuthenticator.displayName = 'AdminAuthenticator';

export default AdminAuthenticator;
