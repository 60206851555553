import { ACTIONS } from 'state/actionTypes';
import axiosClient from 'axiosClient';

import { accountIdSelector } from 'state/account/selectors';

export const postLoanAutoPayInitializeReset = () => ({ type: ACTIONS.postLoanAutoPayInitializeReset });

export const postLoanAutoPayInitialize = (loanId, paymentToken) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const responseAction = await dispatch({
    type: ACTIONS.postLoanAutoPayInitialize,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loan/${loanId}/autopay/initialize`,
        data: {
          paymentToken,
        },
      },
    },
  });
  return responseAction;
};

export const postLoanAutoPayInitializeBatch = (loanIds, paymentToken) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  dispatch({ type: ACTIONS.postLoanAutoPayInitializeBatch });
  const successes = [];
  const failures = [];
  return Promise.allSettled(
    loanIds.map((loanId) =>
      axiosClient
        .post(`/bp/v1/account/${accountId}/loan/${loanId}/autopay/initialize`, { paymentToken })
        .then((autopayResponse) => {
          successes.push({
            loanId,
            documentUrl: autopayResponse.data.url,
            sessionId: autopayResponse.data.sessionId,
            paymentMethodId: paymentToken,
          });
        })
        .catch(() => {
          failures.push({ loanId, paymentMethodId: paymentToken });
        })
    )
  ).then(() => {
    return dispatch({ type: ACTIONS.postLoanAutoPayInitializeBatchDone, payload: { successes, failures } });
  });
};

export const postLoanAutoPayPersistReset = () => ({ type: ACTIONS.postLoanAutoPayPersistReset });

export const postLoanAutoPayPersist = (loanId, sessionId) => async (dispatch, getState) => {
  const state = getState();
  const accountId = accountIdSelector(state);

  const responseAction = await dispatch({
    type: ACTIONS.postLoanAutoPayPersist,
    payload: {
      request: {
        method: 'post',
        url: `/bp/v1/account/${accountId}/loan/${loanId}/autopay/persist`,
        data: {
          sessionId,
        },
      },
    },
  });
  return responseAction;
};

export const setAutoPayModalState = (modalState, loan) => (dispatch) => {
  dispatch({
    type: ACTIONS.setAutoPayModalState,
    payload: {
      data: {
        modalState,
        loan,
      },
    },
  });
};

export const setShowAutoPayFail = (loanId, isShowFail) => {
  return {
    type: ACTIONS.setShowAutoPayFail,
    loanId,
    isShowFail,
  };
};

export const setShowAutoPaySuccess = (loanId, isShowSuccess) => {
  return {
    type: ACTIONS.setShowAutoPaySuccess,
    loanId,
    isShowSuccess,
  };
};

export const disableLoanAutoPay = (loanId) => {
  return {
    type: ACTIONS.disableLoanAutoPay,
    loanId,
  };
};

export const enableLoanAutoPay = (loanId) => {
  return {
    type: ACTIONS.enableLoanAutoPay,
    loanId,
  };
};

export const addLoanAutoPayDocument = (loanId, document) => {
  return {
    type: ACTIONS.addLoanAutoPayDocument,
    document,
    loanId,
  };
};
