import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatDecimalPlainFromCents } from 'lib/Formatters';

const FormattedTrans = memo(({ paymentAmount, paymentDate }) => {
  const formattedPaymentAmount = formatDecimalPlainFromCents(paymentAmount);
  const formattedPaymentDate = moment(paymentDate).format('ll');

  return (
    <>
      <Trans
        i18nKey="loans.payment.schedule_payment.modal.are_you_sure_cancel"
        formattedPaymentAmount={formattedPaymentAmount}
        formattedPaymentDate={formattedPaymentDate}
      >
        Are you sure you want to cancel your <b>{{ formattedPaymentAmount }} </b> payment for
        <b>{{ formattedPaymentDate }}</b>?
      </Trans>
      <br />
      <br />
    </>
  );
});

FormattedTrans.displayName = 'FormattedTrans';

FormattedTrans.propTypes = {
  paymentAmount: PropTypes.number.isRequired,
  paymentDate: PropTypes.string.isRequired,
};

export default FormattedTrans;
