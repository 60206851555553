import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { trackAll } from 'analytics/tracking';

// State Actions
import { ACTIONS } from 'state/actionTypes';
import { deletePaymentMethod, deletedPaymentMethod } from 'state/deletePayment/actions';
import { deletePaymentLoadingSelector } from 'state/deletePayment/selectors';
import { associatedPaymentMethodLoanSelector } from 'state/loans/selectors';

// Styles
import { MaterialIcon } from 'styles/Icons';
import { IconWrapper } from 'styles/CommonStyles';

import RemovePaymentMethodModal from './RemovePaymentMethodModal';

const iconColor = '#2ea59a';
const iconSize = '1.7rem';

const Menu = memo((props) => {
  const { method, dismissAllAlerts, setShowRemoveAccountAlertFail, setShowRemoveAccountAlertSuccess } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showRemovePaymentMethodModal, setShowRemovePaymentMethodModal] = useState(false);

  const associatedPaymentMethodLoanList = useSelector((state) =>
    associatedPaymentMethodLoanSelector(state, method.paymentMethodId)
  );

  const deletePaymentLoadingState = useSelector(deletePaymentLoadingSelector);

  const maskedNumber = method.paymentMethodType === 'ach' ? method.accountNumberMask : method.cardNumberMask;

  const remove = useCallback((event) => {
    event.preventDefault();
    setShowRemovePaymentMethodModal(true);
  }, []);

  const removePaymentMethod = useCallback(
    async (event) => {
      event.preventDefault();

      dismissAllAlerts();

      dispatch(deletedPaymentMethod(method.paymentMethodId, method.paymentMethodType, maskedNumber));

      const response = await dispatch(deletePaymentMethod(method.paymentMethodId));
      if (response.type === ACTIONS.deletePaymentMethodSuccess) {
        setShowRemoveAccountAlertSuccess(true);
      } else {
        setShowRemoveAccountAlertFail(true);
      }

      trackAll(
        {
          category: 'user',
          action: 'click',
          label: 'remove-payment-method',
          value: 1,
        },
        {
          paymentMethodId: method.paymentMethodId,
          paymentMethodType: method.paymentMethodType,
        }
      );
    },
    [
      dismissAllAlerts,
      dispatch,
      maskedNumber,
      method.paymentMethodId,
      method.paymentMethodType,
      setShowRemoveAccountAlertFail,
      setShowRemoveAccountAlertSuccess,
    ]
  );

  return (
    <>
      <IconWrapper onClick={remove} title={t('account.payment_methods.wallet.menu.remove')}>
        <MaterialIcon name="delete" size={iconSize} color={iconColor} />
      </IconWrapper>

      {showRemovePaymentMethodModal && (
        <RemovePaymentMethodModal
          associatedLoans={associatedPaymentMethodLoanList}
          method={method}
          onRemove={removePaymentMethod}
          setShowModal={setShowRemovePaymentMethodModal}
          isLoading={deletePaymentLoadingState}
        />
      )}
    </>
  );
});

Menu.displayName = 'Menu';

Menu.propTypes = {
  method: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardMonth: PropTypes.string,
    cardNumberMask: PropTypes.string,
    cardYear: PropTypes.string,
    defaultPaymentMethod: PropTypes.bool.isRequired,
    paymentMethodId: PropTypes.string.isRequired,
    paymentMethodType: PropTypes.string.isRequired,
  }).isRequired,
  dismissAllAlerts: PropTypes.func,
  setShowRemoveAccountAlertFail: PropTypes.func,
  setShowRemoveAccountAlertSuccess: PropTypes.func,
};

Menu.defaultProps = {
  dismissAllAlerts: null,
  setShowRemoveAccountAlertFail: null,
  setShowRemoveAccountAlertSuccess: null,
};

export default Menu;
