import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { trackAll } from 'analytics/tracking';
import { noOp } from 'lib/utils';
import { ACTIONS } from 'state/actionTypes';
import { accountIdSelector } from 'state/account/selectors';
import { postLoanAutoPayInitialize } from 'state/autoPay/actions';
import { autoPayInitializeLoadingSelector } from 'state/autoPay/selectors';
import { setLoanPaymentMethod } from 'state/loans/setPaymentMethod/actions';
import { putLoanPaymentMethodLoadingSelector } from 'state/loans/setPaymentMethod/selectors';
import CommonModal from 'views/account/paymentMethods/CommonModal';
import PaymentMethodSelect from 'views/account/wallet/components/PaymentMethodSelect';

const SelectPaymentMethodModal = ({
  onAddNewPaymentMethod,
  loan,
  onClose,
  onAutoPayInitializeFail,
  onAutoPayInitializeSuccess,
  onPaymentMethodUpdateFail,
  onPaymentMethodUpdateSuccess,
  onSelectPaymentMethod,
  paymentMethods,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountId = useSelector(accountIdSelector);
  const putPaymentMethodLoading = useSelector(putLoanPaymentMethodLoadingSelector);
  const autoPayInitializeLoading = useSelector(autoPayInitializeLoadingSelector);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const closeModal = useCallback(
    (event) => {
      setSelectedPaymentMethod(null);
      onClose(event);
    },
    [onClose]
  );

  const confirmPaymentMethod = useCallback(async () => {
    const paymentMethod = paymentMethods.find((method) => method.paymentMethodId === selectedPaymentMethod);
    onSelectPaymentMethod(paymentMethod);

    if (!loan) {
      return;
    }

    trackAll(
      {
        category: 'user',
        action: 'click',
        label: 'set-payment-method-for-loan',
        value: 1,
      },
      {
        loanId: loan.loanId,
        paymentMethodId: selectedPaymentMethod,
      }
    );

    // When there is already a payment method with AutoPay enabled,
    // then we need to keep AutoPay going, so just do a new AutoPay initialize
    // call with the new payment method ID.
    if (!loan.paymentToken || !loan.autopay) {
      const responseAction = await dispatch(setLoanPaymentMethod(accountId, loan.loanId, paymentMethod.paymentMethodId));
      if (responseAction.type === ACTIONS.putLoanPaymentMethodSuccess) {
        onPaymentMethodUpdateSuccess(paymentMethod);
      } else {
        onPaymentMethodUpdateFail(paymentMethod);
      }
    } else {
      const responseAction = await dispatch(postLoanAutoPayInitialize(loan.loanId, paymentMethod.paymentMethodId));
      if (responseAction.type === ACTIONS.postLoanAutoPayInitializeSuccess) {
        onAutoPayInitializeSuccess(paymentMethod);
      } else {
        onAutoPayInitializeFail(paymentMethod);
      }
    }
  }, [
    accountId,
    dispatch,
    loan,
    onAutoPayInitializeFail,
    onAutoPayInitializeSuccess,
    onPaymentMethodUpdateFail,
    onPaymentMethodUpdateSuccess,
    onSelectPaymentMethod,
    paymentMethods,
    selectedPaymentMethod,
  ]);

  return (
    <CommonModal
      title={t('account.payment_methods.select_modal.select_payment_method')}
      button1={t('common.buttons.continue')}
      button2={t('common.buttons.add_new')}
      button1Disabled={!selectedPaymentMethod}
      body1={
        <PaymentMethodSelect
          name="paymentMethod"
          onChange={setSelectedPaymentMethod}
          paymentMethods={paymentMethods}
          value={selectedPaymentMethod}
        />
      }
      bodyBreak
      onClose={closeModal}
      isLoading={putPaymentMethodLoading || autoPayInitializeLoading}
      submitEvent1={confirmPaymentMethod}
      submitEvent2={onAddNewPaymentMethod}
    />
  );
};

SelectPaymentMethodModal.propTypes = {
  loan: PropTypes.shape({
    autopay: PropTypes.bool,
    loanId: PropTypes.string,
    merchantInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
    paymentToken: PropTypes.string,
  }),
  onAddNewPaymentMethod: PropTypes.func,
  onAutoPayInitializeFail: PropTypes.func,
  onAutoPayInitializeSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onPaymentMethodUpdateFail: PropTypes.func,
  onPaymentMethodUpdateSuccess: PropTypes.func,
  onSelectPaymentMethod: PropTypes.func,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
};

SelectPaymentMethodModal.defaultProps = {
  loan: null,
  onAddNewPaymentMethod: noOp,
  onAutoPayInitializeFail: noOp,
  onAutoPayInitializeSuccess: noOp,
  onClose: noOp,
  onPaymentMethodUpdateFail: noOp,
  onPaymentMethodUpdateSuccess: noOp,
  onSelectPaymentMethod: noOp,
  paymentMethods: [],
};

export default SelectPaymentMethodModal;
