/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { cloneElement, useCallback, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { clean } from 'lib/utils';
import { PLAID } from 'lib/constants/alertTypes';

import { postPersist, postVerify } from 'state/updatePaymentMethods/actions';
import { ACTIONS } from 'state/actionTypes';
import { loansSelector } from 'state/loans/selectors';

import usePlaidLink from 'hooks/usePlaidLink';
import { trackAll } from 'analytics/tracking';

import Loader from 'components/Loader';

import { BigButtonStyle } from 'styles/PaymentMethodStyles';

const PlaidLink = styled.button`
  all: unset;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  &:active {
    border: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed !important;
  }
`;

// Plaid: French does not allow user to choose account in flow.
const selectBankAccount = (metadata) => {
  // if one bank account exists, only select 0 index
  if (metadata.accounts.length === 1) {
    metadata.auto_select_account = false;
    metadata.selected_bank_account = metadata.accounts[0].id;
    return metadata.accounts[0].id;
  }

  // otherwise, look to find first checking account
  const checkingAccount = metadata.accounts.find((account) => account.subtype === 'checking');
  if (checkingAccount) {
    metadata.auto_select_account = true;
    metadata.selected_bank_account = checkingAccount.id;
    return checkingAccount.id;
  }

  // else look to find the first savings account
  const savingsAccount = metadata.accounts.find((account) => account.subtype === 'savings');
  metadata.auto_select_account = true;
  metadata.selected_bank_account = savingsAccount.id;
  return savingsAccount.id;
};

const Plaid = memo((props) => {
  const {
    children,
    dismissAllAlerts,
    setShowAddPaymentMethodModal,
    setShowAlertFail,
    setShowAlertSuccess,
    setShowManageLoansModal,
    ...rest
  } = props;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const loans = useSelector(loansSelector);
  const [disableButton, setDisableButton] = useState(false);

  const handleOnSuccess = useCallback(
    async (token, metadata) => {
      dismissAllAlerts();

      const accountReference = selectBankAccount(metadata);

      const postVerifyResponse = await dispatch(postVerify(token, accountReference));

      // cevents
      clean(metadata);
      metadata.eventName = 'success';
      metadata.target = 'bank-account-modal';
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata.account_type = metadata.account.subtype;
      metadata.language = i18n.language === 'en-US' ? 'en' : i18n.language;

      // Do not send PII
      delete metadata.account_id;
      delete metadata.account;
      delete metadata.accounts;

      trackAll(
        {
          category: 'sys',
          action: 'handler',
          label: 'plaid',
          value: 1,
        },
        metadata
      );

      // if postVerify is successful call postPersist else display error message
      if (postVerifyResponse.type === ACTIONS.postVerifySuccess) {
        const postPersistResponse = await dispatch(postPersist(postVerifyResponse.payload.data.verifyId));
        setShowAddPaymentMethodModal(false);

        if (postPersistResponse.type === ACTIONS.postPersistSuccess) {
          if (loans?.filter((loan) => loan.state?.toUpperCase() === 'ACTIVE').length > 0) {
            setShowManageLoansModal(true);
          }
          setShowAlertSuccess(true, PLAID);
        } else {
          setShowAlertFail(true, PLAID);
        }
      } else {
        // PostVerify Failed  // Maybe this should trigger the other error message ?
        setShowAddPaymentMethodModal(false);
        setShowAlertFail(true, PLAID);
      }
    },
    [
      dismissAllAlerts,
      dispatch,
      i18n.language,
      loans,
      setShowAddPaymentMethodModal,
      setShowAlertFail,
      setShowAlertSuccess,
      setShowManageLoansModal,
    ]
  );

  const handleOnExit = useCallback(
    // eslint-disable-next-line no-unused-vars
    (error, metadata) => {
      setDisableButton(false);
      setShowAddPaymentMethodModal(false);
      // cevents not tracked here ( already tracked in onEvent )
      if (error && error.error_code !== 'INVALID_LINK_TOKEN') {
        setShowAlertFail(error && error.error_code, PLAID);
      }
    },
    [setShowAddPaymentMethodModal, setShowAlertFail]
  );

  const [loaded, openPlaidLink] = usePlaidLink({ onSuccess: handleOnSuccess, onFailure: handleOnExit });

  const handleClick = useCallback(() => {
    setDisableButton(true);
    openPlaidLink();
  }, [openPlaidLink]);

  const disabled = !loaded || disableButton;

  if (!loaded) {
    return (
      <BigButtonStyle disabled={disabled}>
        <Loader />
      </BigButtonStyle>
    );
  }

  return (
    <PlaidLink onClick={handleClick} disabled={disabled} {...rest}>
      {cloneElement(children, { disabled })}
    </PlaidLink>
  );
});

Plaid.displayName = 'Plaid';

Plaid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  dismissAllAlerts: PropTypes.func.isRequired,
  setShowAddPaymentMethodModal: PropTypes.func.isRequired,
  setShowAlertFail: PropTypes.func,
  setShowAlertSuccess: PropTypes.func,
  setShowManageLoansModal: PropTypes.func,
};

Plaid.defaultProps = {
  setShowAlertFail: () => {
    return false;
  },
  setShowAlertSuccess: () => {
    return false;
  },
  setShowManageLoansModal: () => {
    return false;
  },
};

export default Plaid;
