import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Theme from 'styles/Theme';
import { Accordion } from 'styles/CommonStyles';
import AccordionToggle from './AccordionToggle';

const TabHeader = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 85%;
`;

const ResultsHeader = styled.div`
  width: 100%;
`;

const LineDivider = styled.div`
  border-bottom: 1px solid ${Theme.colors.borderLightGrey};
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  width: 93%;
`;

const AccordionCards = memo(({ loadedResults, isResultsPage, searchQuery }) => {
  const [activeIndex, setActiveIndex] = useState('');

  const renderedQuestions = loadedResults.map((result, index) => {
    const showDescription = index === activeIndex;
    const uniqueID = `${result.item} + '-' + ${index}`;

    return (
      <Accordion defaultActiveKey={activeIndex} key={uniqueID}>
        <AccordionToggle
          faqItem={result.item}
          index={index + 1}
          showDescription={showDescription}
          isResultsPage={isResultsPage}
          handleToggle={() => {
            setActiveIndex(index);
          }}
          eventKey={index}
          isSingleResult={loadedResults.length === 1}
          searchQuery={searchQuery}
        />
        <LineDivider />
      </Accordion>
    );
  });

  return (
    <div>{isResultsPage ? <ResultsHeader>{renderedQuestions}</ResultsHeader> : <TabHeader>{renderedQuestions}</TabHeader>}</div>
  );
});

AccordionCards.propTypes = {
  loadedResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResultsPage: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
};

AccordionCards.defaultProps = {
  searchQuery: null,
};

export default AccordionCards;
