import React, { useState, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ScheduledPaymentsHistory from 'views/loans/components/ScheduledPaymentsHistory';
import LoanHistoryDropdown from 'views/loans/components/LoanHistoryDropdown';
import ResponsiveCol from 'views/loans/components/ResponsiveCol';
import {
  formatDate,
  formatStyledMoneyFromCents,
  formatMoneyFromCents,
  formatMaskedCreditCardNumberWithBullets,
} from 'lib/Formatters';
import { Col, Row, Data } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import Theme from 'styles/Theme';
import { Body } from 'styles/PaymentHistoryStyles';

const History = styled.div`
  color: #555555;

  font-size: 0.9rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const RefundDropdownText = styled(Data)`
  padding-top: 1.5rem;
  font-size: 0.9rem;
  color: ${(props) => (props.isFailed ? props.theme.colors.error : '')};
`;

const RefundFailed = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Date = styled.div`
  color: #636c8c;
`;

const SmallData = styled(Data)`
  text-align: right;

  font-size: 0.9rem;
  min-width: 75px;

  color: ${(props) => (props.isOverdue ? props.theme.colors.error : '')};
`;

const Status = styled.div`
  color: ${(props) => (props.isOverdueOrChargedOff ? props.theme.colors.error : '')};
  color: ${(props) => (props.isNextPayment ? props.theme.colors.upliftTeal : '')};
  color: ${(props) => (props.isHardshipPayment ? props.theme.colors.hardshipStatusOrange : '')};
  font-weight: ${(props) => (props.isOverdueOrChargedOff ? '700' : '')};
  font-weight: ${(props) => (props.isNextPayment ? '700' : '')};
`;

const StyledRow = styled(Row)`
  color: ${(props) => (props.isHardshipPayment ? props.theme.colors.hardshipStatusOrange : '')} !important;
  color: ${(props) => (props.isOverdue ? props.theme.colors.error : '')} !important;
  color: ${(props) => (props.isNextPayment ? props.theme.colors.upliftTeal : '')} !important;
  font-weight: ${(props) => (props.isOverdue ? '700' : '')} !important;
  font-weight: ${(props) => (props.isNextPayment ? '700' : '')} !important;
`;

const iconColor = Theme.colors.errorMessage;
const iconSize = '1.2rem';
const iconStyle = {
  marginRight: '0.5rem',
};

const LoanHistoryTransactions = memo(({ combinedPayments, loanDetails }) => {
  const { t } = useTranslation();

  const {
    downPaymentMinusOriginationFee,
    financedAmount,
    loanDate,
    monthlyPaymentCount,
    orderAmount,
    originationFee,
    paymentHistory,
    term,
    paymentToken,
  } = loanDetails;

  const [showCancelPayment, setShowCancelPayment] = useState({});
  const [showCancelPaymentModal, setShowCancelPaymentModal] = useState({});
  const [showSchedulePaymentStatus, setShowSchedulePaymentStatus] = useState({});

  // revisit: next iteration - 2 adjustment cases
  const isAdjustmentTP = true;

  // revisit: next iteration - failed cases
  // const errorCode = 'card-error';
  // const errorCode = 'insufficient-funds';
  // const errorCode = 'payment-failure-ach-other';

  // prettier-ignore
  return (
    <History>
      {combinedPayments.map((payment, index) => { // NOSONAR
        if (payment.paymentStatus) {
          return (
            <ScheduledPaymentsHistory
              key={payment.paymentId}
              payment={payment}
              showCancelPayment={showCancelPayment}
              setShowCancelPayment={setShowCancelPayment}
              showCancelPaymentModal={showCancelPaymentModal}
              setShowCancelPaymentModal={setShowCancelPaymentModal}
              showSchedulePaymentStatus={showSchedulePaymentStatus}
              setShowSchedulePaymentStatus={setShowSchedulePaymentStatus}
              paymentToken={paymentToken}
            />
          );
        }

        // Status: Overdue
        if (payment.status === 'Overdue') {
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status={payment.status.toLowerCase()}
              headerDate={payment.date}
              headerStatus={t(`loans.history.overdue`)}
              headerAmount={payment?.amount}
            />
          );
        }

        // Status: Charged Off
        if (payment.status === 'Charged Off') {
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status="charged-off"
              headerDate={payment.date}
              headerStatus={t(`loans.history.charged_off`)}
              headerAmount={payment?.amount}
            />
          );
        }

        // Status: Next Payment
        if (payment.status === 'Next Payment') {
          if (monthlyPaymentCount === term) {
            // No more montly payments required. Hide next payment row
            return null;
          }
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-next-payment`}
              uniqueKey={`${index.toString()}-next-payment`}
              payment={payment}
              status="next-payment"
              headerDate={payment.date}
              headerStatus={t(`loans.history.next_payment`)}
              headerAmount={payment?.amount}
            />
          );
        }

        // Status: Payment
        if (payment.status === 'Paid') {
          const paymentLabelKey = payment.paymentType === 'check' ? 'loans.history.cheque_payment' : 'loans.history.payment'
          return (
            <LoanHistoryDropdown
              key={payment.invoiceId}
              uniqueKey={payment.invoiceId}
              payment={payment}
              status={payment.status.toLowerCase()}
              headerDate={payment.date}
              headerStatus={payment.isHardshipPayment?  t('loans.hardship.hardship_payment') :t(paymentLabelKey)}
              headerAmount={payment?.amount}
              prefix1=" "
              field1={t('loans.history.payment_to_principal')}
              amount1={payment?.principalPayment}
              prefix2="+"
              field2={t('loans.history.payment_to_interest')}
              amount2={payment?.interestPayment}
              prefix3="+"
              field3={payment?.suspense ? t('loans.history.refunded_amount') : null}
              amount3={payment?.suspense}
              prefix4="="
              field4={t('loans.history.payment')}
              amount4={payment?.amount}
              isHardshipPayment={payment?.isHardshipPayment}
            />
          );
        }

        // Status: Refunded
        if (payment.status === 'Refunded' && payment.successful) {
          const transactionRefunded = paymentHistory.find((p) => p.chargeId === payment.chargeId && p.status !== 'Refunded' && p.status !== 'Suspense');
          const paymentMask = payment?.paymentMethodMask ? payment.paymentMethodMask.slice(-4) : '';

          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status={payment.status.toLowerCase()}
              headerDate={payment.date}
              headerStatus={t(`loans.history.refunded`)}
              headerAmount={payment?.amount}
              headerMessage={
                <>
                  <StyledRow>
                    <ResponsiveCol xs={6}>
                      <Col xs={6}>
                        <Date status={transactionRefunded.status} data-testid={`${transactionRefunded.status}-date`}>
                          {formatDate(transactionRefunded?.date)}
                        </Date>
                      </Col>
                      <Col xs={6} data-testid={transactionRefunded.status}>
                        <Status isNextPayment={transactionRefunded.status === 'next-payment'} isOverdueOrChargedOff={transactionRefunded.status === 'overdue' || transactionRefunded.status === 'charged-off'}>
                          {t(`loans.history.payment`)}
                        </Status>
                      </Col>
                    </ResponsiveCol>
                    <ResponsiveCol xs={5}>
                      <Col xs={6}>
                        {payment?.paymentMethodMask ? formatMaskedCreditCardNumberWithBullets(payment.paymentMethodMask) : ''}
                      </Col>
                      <Col xs={6} className="d-flex justify-content-end align-items-center">
                        <SmallData
                          data-testid={`${transactionRefunded.status}-value`}
                          isFailOrCanceled={transactionRefunded.status === 'failed' || transactionRefunded.status === 'canceled'}
                          isOverdueOrChargedOff={transactionRefunded.status === 'overdue' || transactionRefunded.status === 'charged-off'}
                        >
                          {transactionRefunded.status === 'refunded' || transactionRefunded.status === 'adjustment' ? '-' : ''}
                          {formatStyledMoneyFromCents(transactionRefunded?.amount, transactionRefunded.status)}
                        </SmallData>
                      </Col>
                    </ResponsiveCol>
                  </StyledRow>
                  <RefundDropdownText data-testid='refund-dropdown-text'>
                    <Trans i18nKey='loans.history.refunded_dropdown' paymentMask={paymentMask}>
                      This payment was successfully refunded to your account ending in {{paymentMask}}.
                    </Trans>
                  </RefundDropdownText>
                </>
              }
            />
          );
        }

        // Status: Refund Failed
        if (payment.status === 'Refunded') {
          const transactionRefunded = paymentHistory.find((p) => p.chargeId === payment.chargeId && p.status !== 'Refunded' && p.status !== 'Suspense');

          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status='refund-failed'
              headerDate={payment.date}
              headerStatus={(
                <RefundFailed>
                  <MaterialIcon name="error_outline" size={iconSize} background={iconColor} style={iconStyle} />
                  <div>{t(`loans.history.refund_failed`)}</div>
                </RefundFailed>
              )}
              headerAmount={payment?.amount}
              headerMessage={
                <>
                  <StyledRow>
                    <ResponsiveCol xs={6}>
                      <Col xs={6}>
                        <Date status={transactionRefunded.status} data-testid={`${transactionRefunded.status}-date`}>
                          {formatDate(transactionRefunded?.date)}
                        </Date>
                      </Col>
                      <Col xs={6} data-testid={transactionRefunded.status}>
                        <Status isNextPayment={transactionRefunded.status === 'next-payment'} isOverdueOrChargedOff={transactionRefunded.status === 'overdue' || transactionRefunded.status === 'charged-off'}>
                          {t(`loans.history.payment`)}
                        </Status>
                      </Col>
                    </ResponsiveCol>
                    <ResponsiveCol xs={5}>
                      <Col xs={6}>
                      {payment?.paymentMethodMask ? formatMaskedCreditCardNumberWithBullets(payment.paymentMethodMask) : ''}
                      </Col>
                      <Col xs={6} className="d-flex justify-content-end align-items-center">
                        <SmallData
                          data-testid={`${transactionRefunded.status}-value`}
                          isFailOrCanceled={transactionRefunded.status === 'failed' || transactionRefunded.status === 'canceled'}
                          isOverdueOrChargedOff={transactionRefunded.status === 'overdue' || transactionRefunded.status === 'charged-off'}
                        >
                          {transactionRefunded.status === 'refunded' || transactionRefunded.status === 'adjustment' ? '-' : ''}
                          {formatStyledMoneyFromCents(transactionRefunded?.amount, transactionRefunded.status)}
                        </SmallData>
                      </Col>
                    </ResponsiveCol>
                  </StyledRow>
                  <RefundDropdownText isFailed data-testid='refund-failed-dropdown-text'>
                    {t(`loans.history.refund_failed_dropdown`)}
                  </RefundDropdownText>
                </>
              }
            />
          );
        }

        // Status: Suspense (Zelle refunds)
        if (payment.status === 'Suspense') {
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status='suspense'
              headerDate={payment.date}
              headerStatus={t(`loans.history.refund_zelle`)}
              headerAmount={payment?.amount}
              headerMessage={
                <RefundDropdownText data-testid='suspense-dropdown-text'>
                  {t(`loans.history.refund_zelle_dropdown`)}
                </RefundDropdownText>
              }
            />
          );
        }

        // Status: Adjustment
        if (payment.status === 'Adjustment') {
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
              payment={payment}
              status={payment.status.toLowerCase()}
              headerDate={payment.date}
              headerStatus={t(`loans.history.adjustment`)}
              headerAmount={payment?.amount}
              // revisit: next iteration - handle adjustment_tp and adjustment_uplift instances
              headerMessage={t(`loans.history.adjustment${isAdjustmentTP ? '_uplift' : '_tp'}`)}
            />
          );
        }

        // revisit: next iteration - this should be an item on the payments object
        // Status: Loan Issued
        if (payment.status === 'Loan Issued') {
          return (
            <LoanHistoryDropdown
              key={`${index.toString()}-${payment.status.toLowerCase()}`}
              uniqueKey="loanIssued"
              payment={payment}
              status="loan-issued"
              headerDate={loanDate}
              headerStatus={t('loans.history.loan_issued')}
              headerAmount={financedAmount?.amount}
              prefix1=" "
              field1={t('loans.history.trip_price')}
              amount1={orderAmount?.amount}
              prefix2="-"
              field2={`${t('loans.details.origination_fee')} (${t('loans.history.pay_today')})`}
              amount2={originationFee?.amount}
              prefix3="-"
              field3={`${t('loans.details.initial_payment')} (${t('loans.history.pay_today')})`}
              amount3={downPaymentMinusOriginationFee?.amount}
              prefix4="="
              field4={t('loans.details.financed_amount')}
              amount4={financedAmount?.amount}
            />
          );
        }

        // Status: Failed (figure out what the status is actually called)
        // revisit: next iteration
        // if (payment.status === 'Failed') {
        //   return (
        //     <LoanHistoryDropdown
        //       key={`${index.toString()}-${payment.status.toLowerCase()}`}
        //       uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
        //       status={payment.status.toLowerCase()}
        //       headerDate={payment.date}
        //       headerStatus={`${t('loans.history.payment')} (${t('loans.history.failed')})`}
        //       headerAmount={payment?.amount}
        //       headerMessage={
        //         <>
        //           {errorCode === 'card-error' && (
        //             <Trans i18nKey="loans.history.payment_failed_card">
        //               Something went wrong with your card. Your payment with card ending in {{ maskedAccountNumber }} failed. Please{' '}
        //               <NavLink to="/account">update</NavLink> your payment method.
        //             </Trans>
        //           )}
        //           {errorCode === 'insufficient-funds' && (
        //             <Trans i18nKey="loans.history.payment_failed_card_insufficient_funds">
        //               Your payment with card ending in {{ maskedAccountNumber }} failed due to insufficient funds. Please verify
        //               your payment method and resubmit your payment.
        //             </Trans>
        //           )}
        //           {errorCode === 'payment-failure-ach-other' && (
        //             <Trans i18nKey="loans.history.payment_failed_card_verify_payment">
        //               Something went wrong. Your payment with card ending in {{ maskedAccountNumber }} failed. Please verify your
        //               payment method and resubmit your payment.
        //             </Trans>
        //           )}
        //         </>
        //       }
        //     />
        //   );
        // }

        // Status: Chargeback (figure out what the status is actually called)
        // revisit: next iteration
        // if (payment.status === 'Chargeback') {
        //   const paymentAmount = formatDecimalPlainFromCents(payment?.amount);

        //   return (
        //     <LoanHistoryDropdown
        //       key={`${index.toString()}-${payment.status.toLowerCase()}`}
        //       uniqueKey={`${index.toString()}-${payment.status.toLowerCase()}`}
        //       status={payment.status.toLowerCase()}
        //       headerDate={payment.date}
        //       headerStatus={
        //         <div className="d-flex align-items-center">
        //           <div>{t('loans.history.payment')}&nbsp;</div>
        //           <div>({t('loans.history.chargeback')})</div>
        //           <MaterialIcon name="error_outline" size={iconSize} color={iconColor} style={{ marginLeft: '0.2rem' }} />
        //         </div>
        //       }
        //       headerAmount={0}
        //       headerMessage={
        //         // revisit: next iteration - show card or account translation
        //         <Trans i18nKey="loans.history.chargeback_card">
        //           This payment of ${{ paymentAmount }} was charged back to the card ending in {{ maskedAccountNumber }}. Please
        //           contact your financial institution to withdraw your dispute. Interest will continue to accrue, and your payment
        //           will be reported as late to the credit bureau if it becomes more than 30 days past due.
        //         </Trans>
        //       }
        //     />
        //   );
        // }

        return (
          // Status: processing
          <Body key={payment.invoiceId ? payment.invoiceId : index.toString()}>
            <StyledRow
              isOverdue={payment.status.toLowerCase() === 'overdue'}
              isNextPayment={payment.status.toLowerCase() === 'next-payment'}
            >
              <Col xs={4}>
                <Date data-testid="loan-history-date">{formatDate(payment.date)}</Date>
              </Col>
              <Col xs={4} data-testid="loan-history">
                {payment.isHardshipPayment
                  ? t(`loans.hardship.hardship_payment`)
                  : t(`loans.history.${payment.status.toLowerCase()}`)}
              </Col>
              <Col xs={3} className="d-flex justify-content-end">
                <SmallData data-testid="loan-history-value">{formatMoneyFromCents(payment?.amount)}</SmallData>
              </Col>
              <Col xs={1}>
                <div />
              </Col>
            </StyledRow>
          </Body>
        );
      })}
    </History>
  );
});

LoanHistoryTransactions.displayName = 'LoanHistory';

LoanHistoryTransactions.propTypes = {
  combinedPayments: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })).isRequired,
  loanDetails: PropTypes.shape({
    autopay: PropTypes.bool,
    downPaymentMinusOriginationFee: PropTypes.shape({ amount: PropTypes.number }),
    financedAmount: PropTypes.shape({ amount: PropTypes.number }),
    loanDate: PropTypes.string,
    loanId: PropTypes.loanId,
    monthlyPaymentCount: PropTypes.number,
    orderAmount: PropTypes.shape({ amount: PropTypes.number }),
    originationFee: PropTypes.shape({ amount: PropTypes.number }),
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    paymentToken: PropTypes.string,
    isHardshipPayment: PropTypes.bool,
    term: PropTypes.number,
  }).isRequired,
};

export default LoanHistoryTransactions;
