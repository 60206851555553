/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Label2, StyledInput } from 'styles/CommonStyles';
// import CountryCodeSelector from 'components/CountryCodeSelector';
// import ReactHookFormAdapter from 'components/ReactHookFormAdapter';

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledInputField = styled.div`
  flex: 1;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const EditMobileForm = ({ disabled }) => {
  const formMethods = useFormContext();
  const { errors, register } = formMethods;
  const { t } = useTranslation();
  return (
    <StyledFormGroup>
      <Label2 htmlFor="accountMobile">{t('account.edit_modal.new_mobile_number')}</Label2>
      <StyledRow>
        {/* revisit: country code dropdown */}
        {/* <ReactHookFormAdapter
          adaptee={CountryCodeSelector}
          defaultValue={defaultCountry}
          disabled={disabled}
          name="countryCode"
          {...formMethods}
        /> */}
        <StyledInputField>
          <StyledInput
            disabled={disabled}
            error={!!errors.mobile}
            id="accountMobile"
            maxLength={12}
            name="mobile"
            placeholder={t('authentication.sign_in.placeholders.mobile_number')}
            ref={register({
              required: true,
              pattern: /^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$|^[0-9]{2}-?[0-9]{4}-?[0-9]{4}$/,
            })}
            type="tel"
          />
          {errors.mobile && <ErrorMessage>{t('authentication.errors.invalid_mobile')}</ErrorMessage>}
        </StyledInputField>
      </StyledRow>
    </StyledFormGroup>
  );
};

EditMobileForm.propTypes = {
  disabled: PropTypes.bool,
};

EditMobileForm.defaultProps = {
  disabled: false,
};

export default EditMobileForm;
