/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${(props) => (props.error ? '#dc3545' : '#80bdff')};
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${(props) => (props.error ? 'rgba(220, 53, 69, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
  }
  @media (max-width: 350px) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;
