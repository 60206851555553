// Save current version of window.up ( used for cevents )
const { up } = window;

// Require / Load Secure Forms library ( overwrites window.up )
// eslint-disable-next-line import/extensions
require('external/lib/checkout.min.js');

// Save secureforms into a variable for export later
const secureforms = window.up.lib.cljs.secure_forms.checkout;
// console.log('SECURE FORMS     ', secureforms)

// Restore current version of window.up ( used for cevents )
window.up = up;

// Make secureforms available for other files
export default secureforms;
