import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const postSchedulePaymentScheduleReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postSchedulePaymentReset:
      return {};
    case ACTIONS.postSchedulePaymentSchedule:
      return {
        loading: true,
      };
    case ACTIONS.postSchedulePaymentScheduleSuccess:
      return {
        success: true,
        data: action.payload.data,
      };
    case ACTIONS.postSchedulePaymentScheduleFail:
      return {
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

// prettier-ignore
export const deleteSchedulePaymentReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.deleteSchedulePayment:
      return {
        loading: true,
      };
    case ACTIONS.deleteSchedulePaymentSuccess:
      return {
        success: true,
        data: action.payload.data,
      };
    case ACTIONS.deleteSchedulePaymentFail:
      return {
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};
