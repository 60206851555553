import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

const initialState = {
  count: localStorage.applicationsCount ? JSON.parse(localStorage.applicationsCount) : 0,
};

// prettier-ignore
export const applicationsReducer = (state = initialState, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.getApplications:
      return {
        loading: true,
        count: state.count,
      };
    case ACTIONS.getApplicationsSuccess:
      localStorage.applicationsCount = JSON.stringify(action.payload.data.applications.length);
      return {
        data: action.payload.data.applications,
        count: action.payload.data.applications.length,
      };
    case ACTIONS.getApplicationsFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.logoutSuccess:
      localStorage.removeItem('applicationsCount');
      return {
        ...state,
        count: 0,
      };
    default:
      return state;
  }
};

export default applicationsReducer;
