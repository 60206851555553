import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const postDelayPaymentReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.postDelayPaymentReset:
      return {};
    case ACTIONS.postDelayPayment:
      return {
        loading: true,
      };
    case ACTIONS.postDelayPaymentSuccess:
      // eslint-disable-next-line no-case-declarations
      const success = action.payload.data.status === "delay/success";
      return {
        success,
        data: action.payload.data,
        error: success ? undefined : action.payload.data.status
      };
    case ACTIONS.postDelayPaymentFail:
      return {
        success: false,
        loading: false,
        error: httpError(action),
      };
    default:
      return state;
  }
};

export default postDelayPaymentReducer;
