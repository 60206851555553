import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ProgressBar } from 'styles/CommonStyles';

const ProgressBarText = styled.div`
  display: flex;
  justify-content: space-between;

  color: #555555;
`;

const PaymentProgressBar = memo(({ monthlyPaymentCount, term, remainingTerm }) => {
  const { t } = useTranslation();

  const progressPercentage = Math.round((monthlyPaymentCount / term) * 100);

  return (
    <>
      <ProgressBarText>
        <div data-testid="payments-paid">{t('loans.details.paymentPaid', { count: monthlyPaymentCount })}</div>
        <b data-testid="payments-left">
          {t('loans.details.paymentLeft', {
            count:
              typeof remainingTerm === 'number' && remainingTerm + monthlyPaymentCount <= term
                ? remainingTerm
                : term - monthlyPaymentCount,
          })}
        </b>
      </ProgressBarText>

      <ProgressBar now={progressPercentage} />
    </>
  );
});

PaymentProgressBar.displayName = 'PaymentProgressBar';

PaymentProgressBar.propTypes = {
  monthlyPaymentCount: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  remainingTerm: PropTypes.number.isRequired,
};

export default PaymentProgressBar;
