import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';

import { isCanadianSelector } from 'state/account/selectors';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { onLanguageChange, toKebabCase } from 'lib/utils';

const LangSelector = styled.div``;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.black};
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px 5px;
  text-decoration: underline;

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  moment.locale(i18n.language);

  const changeLanguage = (lng) => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'toggle',
      label: `change-language-to-${lng}`,
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    i18n.changeLanguage(lng, onLanguageChange);
    moment.locale(lng);
  };

  const isCanadian = useSelector(isCanadianSelector);

  return (
    <>
      {isCanadian ? (
        <LangSelector id="LangSelector">
          <Button name="en" onClick={() => changeLanguage('en')}>
            English
          </Button>
          |
          <Button name="fr" onClick={() => changeLanguage('fr')}>
            Français
          </Button>
        </LangSelector>
      ) : (
        <LangSelector id="LangSelector">
          <Button name="en-US" onClick={() => changeLanguage('en-US')}>
            English
          </Button>
        </LangSelector>
      )}
    </>
  );
};

LanguageSelector.displayName = 'LanguageSelector';

export default LanguageSelector;
