import * as cevents from 'up.lib.js.cevents';

import trackEvent from 'analytics/trackEvent';
import { toKebabCase } from 'lib/utils';

/**
 * Sends request to record the event to c-events
 * @param {{category: string, action: string, label: string}} event
 * @param {any} eventMetadata
 * @param {string} level
 */
export const trackCevent = (event, eventMetadata = {}, level = 'debug') => {
  let metadata = { ...eventMetadata };
  metadata.uplift_account_id = localStorage.getItem('account_id_hash');
  metadata.isAdmin = localStorage.getItem('adminAuth');
  metadata = toKebabCase(metadata);

  cevents.setLevel(level);
  cevents.debugRaw(event.category, event.action, event.label, metadata);
};

/**
 * Sends requests to record the event in Google Analytics and c-events
 * @param {{category: string, action: string, label: string, value: any}} event
 * @param {any} eventMetadata
 * @param {string} level
 */
export const trackAll = (event, eventMetadata = {}, ceventLevel = 'debug') => {
  try {
    // google analytics
    trackEvent(event);

    // cevents
    trackCevent(event, eventMetadata, ceventLevel);
  } catch (error) {
    // Ignore the error and continue on
  }
};
