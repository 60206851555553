import { createSelector } from 'reselect';

const selectState = (state) => state.secureFormsPaymentMethodsReducer;

export const secureFormsPaymentMethodsIdSelector = createSelector(selectState, (state) => state.id);

export const secureFormsPaymentMethodsBlsSelector = createSelector(selectState, (state) => {
  return {
    ...state.bls,
    cardType: state.cardType,
    cardValid: state.cardValid,
  };
});

export const secureFormsPaymentMethodsErrorSelector = createSelector(selectState, (state) => state.error);

export const secureFormsPaymentMethodsSuccessIdSelector = createSelector(selectState, (state) => state.successId);

export const secureFormsPaymentMethodsSubmittingSelector = createSelector(selectState, (state) => state.submitting);
