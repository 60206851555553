/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { STATES, PROVINCES } from 'lib/constants/locations';

import { isCanadianSelector } from 'state/account/selectors';

import { Col, ErrorMessage, Label2, Row, StyledInput, StyledSelect } from 'styles/CommonStyles';

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
`;
const EditAddressForm = () => {
  const formMethods = useFormContext();
  const { errors, register } = formMethods;
  const { t } = useTranslation();

  const isCanada = useSelector(isCanadianSelector);

  const regionDropdown = isCanada ? PROVINCES : STATES;

  return (
    <StyledFormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Label2 data-testid="street-address" htmlFor="streetAddress">
              {t('account.payment_methods.form.street')}
            </Label2>
            <StyledInput
              data-testid="street-address-input"
              error={errors.street_address}
              id="streetAddress"
              maxLength={255}
              name="street_address"
              type="text"
              placeholder={t('account.payment_methods.form.street')}
              ref={register({ required: 'account.payment_methods.errors.street_address_required' })}
            />
            {errors.street_address && <ErrorMessage>{t(errors.street_address.message)}</ErrorMessage>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label2 data-testid="street-address-2" htmlFor="streetAddress2">
              {t('account.payment_methods.form.street')} 2
            </Label2>
            <StyledInput
              data-testid="street-address-2-input"
              error={errors.street_address2}
              id="streetAddress2"
              maxLength={40}
              name="street_address2"
              type="text"
              placeholder={`${t('account.payment_methods.form.street')} 2`}
              ref={register({
                required: false,
              })}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label2 data-testid="city" htmlFor="city">
              {t('account.payment_methods.form.city')}
            </Label2>
            <StyledInput
              data-testid="city-input"
              error={errors.city}
              id="city"
              name="city"
              type="text"
              placeholder={t('account.payment_methods.form.city')}
              ref={register({ required: 'account.payment_methods.errors.city_required' })}
            />
            {errors.city && <ErrorMessage>{t(errors.city.message)}</ErrorMessage>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label2 data-testid={`${isCanada ? 'province' : 'region'}`} htmlFor="region">
              {t(`account.payment_methods.form.${isCanada ? 'province' : 'region'}`)}
            </Label2>
            <StyledSelect
              data-testid={`${isCanada ? 'province' : 'region'}-input`}
              error={errors.region}
              id="region"
              name="region"
              // defaultValue=""
              ref={register({
                required: `account.payment_methods.errors.${isCanada ? 'province' : 'region'}_required`,
              })}
            >
              <option value="" disabled>
                {t(`account.payment_methods.form.${isCanada ? 'province' : 'region'}`)}
              </option>
              {Object.keys(regionDropdown).map((key) => {
                return (
                  <option value={key} key={key}>
                    {regionDropdown[key]}
                  </option>
                );
              })}
            </StyledSelect>
            {errors.region && <ErrorMessage>{t(errors.region.message)}</ErrorMessage>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label2 data-testid={`${isCanada ? 'postal-code' : 'zip'}`} htmlFor="postalCode">
              {t(`account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}`)}
            </Label2>
            <StyledInput
              data-testid="zip-input"
              error={errors.postal_code}
              id="postalCode"
              name="postal_code"
              type="text"
              placeholder={t(`account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}`)}
              ref={register({
                required: `account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}_invalid`,
                pattern: /\d{5}|[a-zA-Z]\d[a-zA-Z][ ]?\d[a-zA-Z]\d/,
              })}
              maxLength={7}
            />
            {errors.postal_code && (
              <ErrorMessage>{t(`account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}_invalid`)}</ErrorMessage>
            )}
          </FormGroup>
        </Col>
      </Row>
    </StyledFormGroup>
  );
};

export default EditAddressForm;
