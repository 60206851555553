/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { mapKeys, kebabCase } from 'lodash';

/* =============== Authenticator Helper functions =============== */

export const addDobDashes = (dob) => {
  const re = /[^0-9]/g;
  let sanitizedDob = dob.replace(re, '');

  const mm = sanitizedDob.substr(0, 2);
  const day = sanitizedDob.substr(2, 2);
  const year = sanitizedDob.substr(4, 4);

  sanitizedDob = mm + (day.length > 0 ? '-' : '') + day + (year.length > 0 ? '-' : '') + year;

  return sanitizedDob;
};

export const addPhoneDashes = (phoneNumber) => {
  const re = /[^0-9]/g;
  let sanitizedPhoneNumber = phoneNumber.replace(re, '');

  const npa = sanitizedPhoneNumber.substr(0, 3);
  const nxx = sanitizedPhoneNumber.substr(3, 3);
  const xxxx = sanitizedPhoneNumber.substr(6, 4);

  sanitizedPhoneNumber = npa + (nxx.length > 0 ? '-' : '') + nxx + (xxxx.length > 0 ? '-' : '') + xxxx;

  // console.log('addPhoneDashes: ', sanitizedPhoneNumber)
  return sanitizedPhoneNumber;
};

export const isCanadian = (lng) => {
  // console.log('Current Language: ', lng)
  return lng === 'en' || lng === 'fr' || lng === 'en-CA';
};

export const stripNonDigits = (code) => {
  const re = /[^0-9]/g;
  return code.replace(re, '');
};

/* =============== cevents helper fucntions =============== */

// remove null keys in cevents
export const clean = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete object[key];
    }
  });
};

// rename key in cevent object
export const rename = (object, oldKey, newKey) => {
  const temp = object[oldKey];
  if (temp != null) {
    object[newKey] = temp;
    delete object[oldKey];
  }
};

// rename object keys from snake case to kebab case
export const toKebabCase = (object) => {
  return mapKeys(object, (value, key) => {
    return kebabCase(key);
  });
};

/* =============== Documents Helper functions =============== */

export const printDocument = (url) => {
  const data = null;
  const myWindow = window.open('', 'MsgWindow', 'width=1000, height=1000');

  const xhr = new XMLHttpRequest();
  myWindow.document.getElementsByTagName('body')[0].innerText = '';

  xhr.addEventListener('readystatechange', function() {
    if (this.readyState === 4) {
      myWindow.document.write(xhr.response);
      myWindow.focus();
      setTimeout(function() {
        myWindow.print();
      }, 1500);
    }
  });

  xhr.open('GET', url, true);
  xhr.send(data);
};

export const printStatement = () => {
  const data = window.document.querySelector('.container').innerHTML;
  const css = '<style type="text/css">td{width:50%;}</style>';

  const myWindow = window.open('', 'MsgWindow', 'width=1000, height=1000');

  myWindow.document.getElementsByTagName('body')[0].innerText = '';

  setTimeout(function() {
    myWindow.document.write(
      '<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">'
    );
    myWindow.document.write(data + css);
  }, 500);

  setTimeout(function() {
    myWindow.print();
  }, 1500);
};

/* =============== Loans (state) Helper functions =============== */

export const convertAmountInDollarsToCents = (initialAmount) => {
  const pieces = initialAmount.split('.');

  const dollars = pieces[0];
  let cents = '';

  if (pieces.length > 1) {
    cents = pieces[1].slice(0, 2);

    if (cents.length === 1) {
      cents += '0';
    } else if (cents.length === 0) {
      cents += '00';
    }
  } else {
    cents = '00';
  }

  return parseInt(dollars + cents, 10);
};

export const convertAmountInCentsToDollars = (initialAmount) => {
  let initialAmountString = initialAmount.toString();

  let separator = '.';

  if (initialAmountString.length < 2) {
    // 'Case: intialAmount [0-9]'
    separator = '.0';
  }

  if (initialAmountString.length === 2 && initialAmountString[0] === '-') {
    // 'Case: initialAmount -[0-9]'
    separator = '-.0';
    initialAmountString = initialAmountString.substring(1);
  }

  const position = initialAmountString.length - 2;

  const amountString = `${initialAmountString.slice(0, position)}${separator}${initialAmountString.slice(position)}`;

  return parseFloat(amountString);
};

export const getDollarAndCentsFromDollars = createSelector(
  (amountInDollars) => amountInDollars,
  (amountInDollars) => {
    const pieces = amountInDollars.toString().split('.');

    const dollars = pieces[0];
    let cents = '';

    if (pieces.length > 1) {
      cents = pieces[1].slice(0, 2);

      if (cents.length === 1) {
        cents += '0';
      } else if (cents.length === 0) {
        cents += '00';
      }
    } else {
      cents = '00';
    }

    return [dollars, cents];
  }
);

export const getDollarAndCentsFromCents = createSelector(
  (amountInCents) => amountInCents,
  (amountInCents) => {
    const amountInCentsString = amountInCents.toString();

    let dollars = '0';
    let cents;

    if (amountInCentsString.length < 2) {
      // 'Case: intialAmount [0-9] (single digit)'
      cents = `0${amountInCentsString}`;
    } else if (amountInCentsString.length === 2 && amountInCentsString[0] === '-') {
      // 'Case: initialAmount -[0-9] (single digit)'
      dollars = '-0';
      cents = `0${amountInCentsString.substring(1)}`;
    } else if (amountInCentsString.length === 2) {
      // 'Case: intialAmount [0-9][0-9] (two digits)'
      cents = amountInCentsString;
    } else if (amountInCentsString.length === 3 && amountInCentsString[0] === '-') {
      // 'Case: intialAmount -[0-9][0-9] (two digits)'
      dollars = '-0';
      cents = amountInCentsString.substring(1);
    } else {
      const position = amountInCentsString.length - 2;

      dollars = amountInCentsString.substring(0, position);
      cents = amountInCentsString.substring(position);
    }

    return [dollars, cents];
  }
);

export const convertAmountInCentsToDollarsString = (initialAmount) => {
  let initialAmountString = initialAmount.toString();

  let separator = '.';

  if (initialAmountString.length < 2) {
    // 'Case: intialAmount [0-9]'
    separator = '.0';
  }

  if (initialAmountString.length === 2 && initialAmountString[0] === '-') {
    // 'Case: initialAmount -[0-9]'
    separator = '-.0';
    initialAmountString = initialAmountString.substring(1);
  }

  const position = initialAmountString.length - 2;

  const amountString = `${initialAmountString.slice(0, position)}${separator}${initialAmountString.slice(position)}`;

  const [dollars, cents] = getDollarAndCentsFromDollars(parseFloat(amountString));

  return `${dollars}.${cents}`;
};

/* =============== State Helper functions =============== */

export const httpError = (action) => {
  return {
    error: action.error,
    data: action.error && action.error.response && action.error.response.data,
    status: action.error && action.error.response && action.error.response.status,
    statusText: action.error && action.error.response && action.error.response.statusText,
  };
};

/* =============== Convenience functions =============== */

export const noOp = () => {};

/* =============== Localization helpers =============== */
export const onLanguageChange = (err, t) => {
  if (!err) {
    document.title = t('page.title');
  }
};

/* =============== HTML Elements helpers =============== */

/**
 * Scroll to the given element so it is in view.
 * @param {Element} element The HTML element to scroll to.
 * @returns void
 */
export const scrollToElement = (element) => element?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
