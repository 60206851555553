/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';
import { LOAN_STATUS_CODES } from 'lib/constants/loanStatuses';

import { formatDecimalPlainFromCents } from 'lib/Formatters';
import { toKebabCase } from 'lib/utils';

import { REFUND_THRESHOLD } from 'views/loans/components/LoanHistoryDropdown';
import LoanHistoryTransactions from 'views/loans/components/LoanHistoryTransactions';
import { ButtonHyperLink, Alert } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import { Body } from 'styles/PaymentHistoryStyles';
import Theme from 'styles/Theme';

const History = styled.div`
  color: ${(props) => props.theme.colors.upliftLightBlack};

  font-size: 0.9rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export const RefundAlert = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: ${(props) => props.theme.colors.backgroundLightBlue};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.iconGrey};

  font-weight: 500;
  padding: 5px;
  margin: 5px;

  text-align: left;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    padding: 1rem 1.5rem;
    margin: 0.5rem 3rem 0 4rem;
    font-size: 0.85rem;
  }
`;

const SuspenseRefundText = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const iconStyle = {
  marginRight: '4px',
};
const refundIconColor = Theme.colors.secondaryMediumBlue;
const refundIconSize = '20px';

export const combinePayments = (payments, scheduledPayments) => {
  if (!payments || !payments.length) {
    return !scheduledPayments || !scheduledPayments.length ? [] : [...scheduledPayments];
  }
  if (!scheduledPayments || !scheduledPayments.length) {
    return [...payments];
  }

  const combined = [...payments];
  scheduledPayments.forEach((scheduled) => {
    const targetIndex = combined.findIndex((payment) => payment.date > scheduled.date);
    const insertionPoint = targetIndex === -1 ? combined.length : targetIndex;
    combined.splice(insertionPoint, 0, { ...scheduled });
  });
  return combined;
};

// prettier-ignore
const LoanHistory = memo(({ loanDetails, loanState }) => { // NOSONAR

  const { t } = useTranslation();

  const { currentPayoffBalance, currentSuspenseBalance, loanDate, loanId, paymentHistory, scheduledPayments } = loanDetails;

  const [showAllHistory, setshowAllHistory] = useState(false);
  const [showFailedTransaction, setShowFailedTransaction] = useState(false);

  // revisit: next iteration - temporary object to throw into combinedPayments
  const loanIssued = {
    date: loanDate,
    status: 'Loan Issued',
  };

  const newScheduledPayments = scheduledPayments
    .filter((x) => x.paymentStatus === 'scheduled' || x.paymentStatus === 'canceled')
    .map((x) => {
      const { paymentDate: date, ...rest } = x;
      return { date, ...rest };
    });

  // allPayments
  const combinedPayments = combinePayments(paymentHistory, newScheduledPayments)
    .filter((payment) => payment.status !== 'Refunded' || payment.amount >= REFUND_THRESHOLD)
    .reverse();
  if (loanDetails.chargeoffDate) {
    // Insert chargedoff without re-sorting other payments
    const chargedOffObj = {date: loanDetails.chargeoffDate, amount: loanDetails.chargeoffAmount, status: "Charged Off"};
    const targetIndex = combinedPayments.findIndex((payment) => payment.date < chargedOffObj.date);
    const insertionPoint = targetIndex === -1 ? combinedPayments.length : targetIndex;
    combinedPayments.splice(insertionPoint, 0, chargedOffObj);
  }
  combinedPayments.push(loanIssued);

  // revisit: next iteration - failed statuses
  const first5PaymentsWithoutFailed = combinedPayments.filter((x) => x.status !== 'Failed').slice(0, 5);
  const first5PaymentsWithFailed = combinedPayments.slice(0, 5);

  const allPaymentsWithoutFailed = combinedPayments.filter((x) => x.status !== 'Failed');
  const allPaymentsWithFailed = combinedPayments;

  // Show All
  const showAllButton = combinedPayments.length > 5;

  // revisit: next iteration - failed statuses
  // const showFailedButton = combinedPayments.filter((x) => x.status === 'Failed').length > 0;
  const showFailedButton = false;
  const cancelled = loanDetails.loanStatusCode === LOAN_STATUS_CODES.CANCELLED_REFUND || loanDetails.loanStatusCode === LOAN_STATUS_CODES.CANCELLED_UNWIND|| loanDetails.loanStatusCode === LOAN_STATUS_CODES.CANCELLED;

  // Display warning message if there is no paymentHistory
  if (loanState !== 'processing' && !cancelled && (paymentHistory === undefined || paymentHistory === null || paymentHistory.length === 0)) {
    return (
      <Body>
        <Alert variant="warning">
          <strong>{t('loans.history.error_loading')}</strong>
        </Alert>
      </Body>
    );
  }

  if (cancelled && (paymentHistory === undefined || paymentHistory === null || paymentHistory.length === 0)) {
    return (
      <Body>
        <Alert variant="warning">
          <strong>{t('loans.history.no_payment_history')}</strong>
        </Alert>
      </Body>
    );
  }

  const toggleShowAllHistory = () => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: `${showAllHistory ? 'show-less' : 'show-all'}-loan-history`,
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.value = showAllHistory ? 0 : 1;
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setshowAllHistory(!showAllHistory);
  };

  const toggleFailedTransaction = () => {
    setShowFailedTransaction(!showFailedTransaction);
  };

  const renderRefundAlert = (payments) => {
    const refunds = payments.filter((payment) => payment.status === 'Refunded');
    const successfulRefunds = refunds.filter((refund) => refund.successful);

    let retValue = <></>;
    if (currentSuspenseBalance && currentSuspenseBalance !== 0) {
      // Pending refund balance
      const pendingBalance = formatDecimalPlainFromCents(currentSuspenseBalance);

      retValue = (
        <RefundAlert data-testid="refund-alert-box">
          <MaterialIcon name="info_outline" size={refundIconSize} color={refundIconColor} style={iconStyle} />
          <SuspenseRefundText data-testid="refund-alert-text">
            {t('loans.history.refund_processing_p1')}
            <Trans i18nKey='loans.history.refund_processing_p2' pendingBalance={pendingBalance}>
              <strong>Pending Balance: ${{pendingBalance}}</strong>.
            </Trans>
          </SuspenseRefundText>
        </RefundAlert>
      );
    } else if (refunds.length && successfulRefunds.length === refunds.length && currentPayoffBalance) {
      // All refunds successful
      const loanBalance = formatDecimalPlainFromCents(currentPayoffBalance);

      retValue = (
        <RefundAlert data-testid="refund-alert-box">
          <MaterialIcon name="info_outline" size={refundIconSize} color={refundIconColor} style={iconStyle} />
          <div data-testid="refund-alert-text">
            <Trans i18nKey='loans.history.refunded_balance_outstanding' loanBalance={loanBalance}>
              <strong>We&apos;ve processed your refund.</strong> Keep in mind: there&apos;s still an outstanding balance of ${{loanBalance}} and you must continue to make your monthly payments until the balance is paid in full.
            </Trans>
          </div>
        </RefundAlert>
      );
    }

    return retValue;
  };

  return (
    <History>
      {renderRefundAlert(combinedPayments)}
      {showAllHistory ? (
        <>
          {/* showAll */}
          {showFailedTransaction ? (
            <LoanHistoryTransactions
              combinedPayments={allPaymentsWithFailed}
              loanDetails={loanDetails}
              loanState={loanState}
            />
          ) : (
            <LoanHistoryTransactions
              combinedPayments={allPaymentsWithoutFailed}
              loanDetails={loanDetails}
              loanState={loanState}
            />
          )}
        </>
      ) : (
        <>
          {/* show5 */}
          {showFailedTransaction ? (
            <LoanHistoryTransactions
              combinedPayments={first5PaymentsWithFailed}
              loanDetails={loanDetails}
              loanState={loanState}
            />
          ) : (
            <LoanHistoryTransactions
              combinedPayments={first5PaymentsWithoutFailed} // Default
              loanDetails={loanDetails}
              loanState={loanState}
            />
          )}
        </>
      )}

      <Body className="d-flex justify-content-between">
        {showFailedButton ? (
          <div className="d-flex">
            <label className="switch mr-3" htmlFor={loanId}>
              <input id={loanId} type="checkbox" onChange={toggleFailedTransaction} />
              <span className="slider" />
            </label>
            <div>{t('loans.history.show_failed_transactions')}</div>
          </div>
        ) : (
          <div />
        )}
        {showAllButton && (
          <ButtonHyperLink data-testid="history-show-all-button" onClick={toggleShowAllHistory}>
            {t(`loans.history.${showAllHistory ? 'show_less' : 'show_all'}`)}
          </ButtonHyperLink>
        )}
      </Body>
    </History>
  );
});

LoanHistory.displayName = 'LoanHistory';

LoanHistory.propTypes = {
  loanDetails: PropTypes.shape({
    chargeoffDate: PropTypes.string,
    chargeoffAmount: PropTypes.number,
    currentPayoffBalance: PropTypes.number,
    currentSuspenseBalance: PropTypes.number,
    loanDate: PropTypes.string,
    loanId: PropTypes.string,
    loanStatusCode: PropTypes.string,
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    scheduledPayments: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
  }).isRequired,
  loanState: PropTypes.string.isRequired,
};

export default LoanHistory;
