/* eslint-disable no-shadow */
import React, { useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountUpdateLogoutInfoSelector } from 'state/updateAccount/selectors';
import { addDobDashes, addPhoneDashes, stripNonDigits } from 'lib/utils';

import { signUp, loginDismissErrors, signUpFormFail } from 'state/auth/actions';

import { dismissLoggedOutMessage } from 'state/updateAccount/actions';
import SignInForm from './SignInForm';
import ResetAccountSignInModal from './components/ResetAccountSignInModal';

const checkFieldValidity = (elements, dispatch, name, errorName) => {
  const usernameElement = elements.namedItem(name);
  if (usernameElement && !usernameElement.validity.valid) {
    dispatch(signUpFormFail({ [errorName]: usernameElement.validationMessage }));
  }
};

const SignInContainer = memo(() => {
  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [dob, setDob] = useState('');
  const [ssn, setSsn] = useState('');

  const dispatch = useDispatch();

  const dismissPopup = useCallback(() => {
    dispatch(dismissLoggedOutMessage());
  }, [dispatch]);
  const onDobChange = useCallback(
    (event) => {
      dispatch(loginDismissErrors('dobError'));
      setDob(addDobDashes(event.target.value));
    },
    [dispatch]
  );

  const onCountryChange = useCallback(
    // prettier-ignore
    (country) => { // NOSONAR
      dispatch(loginDismissErrors('countryError'));
      setCountry(country.value);
    },
    [dispatch]
  );

  const onSsnChange = useCallback(
    (event) => {
      dispatch(loginDismissErrors('ssnError'));
      setSsn(stripNonDigits(event.target.value));
    },
    [dispatch]
  );

  const onUsernameChange = useCallback(
    (event) => {
      dispatch(loginDismissErrors('phoneError'));
      setUsername(addPhoneDashes(event.target.value));
    },
    [dispatch]
  );

  const onSignIn = useCallback(
    async (event) => {
      event.preventDefault();

      const { elements } = event.target;

      const formValues = Array.from(elements)
        .filter((item) => item.name)
        .reduce((agg, item) => {
          // eslint-disable-next-line no-param-reassign
          agg[item.name] = item.value;
          return agg;
        }, {});

      if ('country' in formValues && !formValues.country) {
        dispatch(signUpFormFail({ countryError: true }));
      } else if (event.target.checkValidity()) {
        dispatch(signUp(formValues));
        return;
      }

      checkFieldValidity(elements, dispatch, 'username', 'phoneError');
      // checkFieldValidity(elements, dispatch, 'country', 'countryError')
      checkFieldValidity(elements, dispatch, 'dob', 'dobError');
      checkFieldValidity(elements, dispatch, 'ssn', 'ssnError');
    },
    [dispatch]
  );

  // TODO: make conditional below be if this is equal to something specifically
  const logoutReason = useSelector(accountUpdateLogoutInfoSelector);
  const showResetAccountModal = logoutReason === 'mobile_update';
  return (
    <>
      {showResetAccountModal && <ResetAccountSignInModal onClose={dismissPopup} />}
      <SignInForm
        country={country}
        dob={dob}
        ssn={ssn}
        signIn={onSignIn}
        username={username}
        onCountryChange={onCountryChange}
        onDobChange={onDobChange}
        onSsnChange={onSsnChange}
        onUsernameChange={onUsernameChange}
      />
    </>
  );
});

export default SignInContainer;
