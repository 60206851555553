import { applyMiddleware, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import googleAnalytics from 'analytics/googleAnalyticsMiddleware';

import { rootReducer } from 'state/rootReducer';
import monitorReducerEnhancer from 'lib/middleware/monitorReducerEnhancer';
import axiosClient, { middlewareConfig } from './axiosClient';

export default function configureStore(preloadedState) {
  const reduxLogger = createLogger();

  let middlewares;
  if (process.env.NODE_ENV === 'development') {
    middlewares = [axiosMiddleware(axiosClient, middlewareConfig), thunkMiddleware, googleAnalytics, reduxLogger];
  } else {
    middlewares = [axiosMiddleware(axiosClient, middlewareConfig), thunkMiddleware, googleAnalytics];
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}
