import { createSelector } from 'reselect';

export const deletedPaymentMethodSelector = createSelector(
  (state) => state.deletePaymentReducer,
  (deletePaymentReducer) => deletePaymentReducer.deletedPaymentMethod
);

export const deletePaymentLoadingSelector = createSelector(
  (state) => state.deletePaymentReducer,
  (deletePaymentReducer) => deletePaymentReducer.loading
);

export default deletedPaymentMethodSelector;
