import React, { useState, useCallback, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CommonPopover from 'components/popovers/CommonPopover';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { formatMoneyFromCents } from 'lib/Formatters';

import { MaterialIcon } from 'styles/Icons';
import { Details } from 'styles/CommonStyles';

const Data = styled.div`
  font-size: 0.9rem;
`;

const Detail = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  font-weight: bold;
  padding: 0.1rem 0;
`;

const ExpandedDetails = styled.div`
  background-color: ${(props) => props.theme.colors.upliftLightestTeal};
  margin-top: 1rem;
  align-self: center;
  width: 294px;
  padding: 0.25rem 0;
  @media screen and (min-width: ${(props) => props.theme.minWidth.femto}) {
    padding: 2.3rem;
    width: 380px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    padding: 2.3rem;
    width: 478px;
  }
`;

const NormalText = styled.span`
  font-weight: normal;
`;

const NestedDetail1 = styled(Detail)`
  font-weight: normal;
  margin-left: 1.5rem;
`;

const HR = styled.hr`
  border-top: 2.5px solid rgba(0, 0, 0, 0.2);
`;

const StyledHR = styled(HR)`
  margin: 0.5rem 0 0.2rem 0;
`;

const ShowDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #6d7075;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    color: #8e9093;
  }
`;

const LoanInfo = memo(({ loanDetails }) => {
  const {
    currentInterestBalance,
    currentPayoffBalance,
    currentPrincipalBalance,
    downPayment,
    downPaymentMinusOriginationFee,
    financedAmount,
    interest,
    orderAmount,
    originationFee,
    totalPaid,
    totalPayments,
  } = loanDetails;
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = useCallback(() => {
    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: `${showDetails ? 'hide' : 'show'}-details`,
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

    setShowDetails(!showDetails);
  }, [showDetails]);

  return (
    <>
      <Details showDetails={showDetails}>
        {!showDetails ? (
          <>
            <Detail>
              <div data-testid="payoff-balance">{t('loans.details.payoff_balance')}</div>
              <Data data-testid="payoff-balance-value">{formatMoneyFromCents(currentPayoffBalance)}</Data>
            </Detail>
            <Detail>
              <div data-testid="total-paid-to-date">
                <Trans i18nKey="loans.details.total_paid_to_date">
                  Total paid <NormalText>(to date)</NormalText>
                </Trans>
              </div>
              <Data data-testid="total-paid-to-date-value">{formatMoneyFromCents(totalPaid)}</Data>
            </Detail>
            <StyledHR />
          </>
        ) : (
          <>
            <Detail>
              <div data-testid="payoff-balance">{t('loans.details.payoff_balance')}</div>
              <Data data-testid="payoff-balance-value">{formatMoneyFromCents(currentPayoffBalance)}</Data>
            </Detail>
            <NestedDetail1>
              <div data-testid="principal-balance">{t('loans.details.principal_balance')}</div>
              <Data data-testid="principal-balance-value">{formatMoneyFromCents(currentPrincipalBalance)}</Data>
            </NestedDetail1>
            <NestedDetail1>
              <div data-testid="interest-balance">{t('loans.details.interest_balance')}</div>
              <Data data-testid="interest-balance-value">{formatMoneyFromCents(currentInterestBalance)}</Data>
            </NestedDetail1>
            <Detail>
              <div data-testid="total-paid-to-date">
                <Trans i18nKey="loans.details.total_paid_to_date">
                  Total paid <NormalText>(to date)</NormalText>
                </Trans>
              </div>
              <Data data-testid="total-paid-to-date-value">{formatMoneyFromCents(totalPaid)}</Data>
            </Detail>
            <ExpandedDetails>
              <Detail>
                <div data-testid="purchase-total">{t('loans.details.purchase_total')}</div>
                <Data data-testid="purchase-total-value">{formatMoneyFromCents(orderAmount.amount)}</Data>
              </Detail>
              <Detail>
                <div data-testid="initial-payment" className="d-flex">
                  {originationFee?.amount > 0 ? t('loans.details.initial_payment') : t('loans.details.down_payment')}
                </div>
                <Data data-testid="initial-payment-value">-&nbsp;{formatMoneyFromCents(downPayment?.amount)}</Data>
              </Detail>
              {originationFee?.amount > 0 && (
                <>
                  <NestedDetail1>
                    <div data-testi="down-payment">{t('loans.details.down_payment')}</div>
                    <Data data-testid="down-payment">{formatMoneyFromCents(downPaymentMinusOriginationFee?.amount)}</Data>
                  </NestedDetail1>
                  <NestedDetail1>
                    <div data-testid="origination-fee">{t('loans.details.origination_fee')}</div>
                    <Data data-testid="origination-fee-value">{formatMoneyFromCents(originationFee.amount)}</Data>
                  </NestedDetail1>
                </>
              )}

              <hr />

              <Detail>
                <div data-testid="financed-amount" className="d-flex">
                  {t('loans.details.financed_amount')}&nbsp;
                  <CommonPopover
                    type="loanAmount"
                    icon="info_outline"
                    textColor="#586272"
                    placement="top"
                    color="#252930"
                    textcontent={t('loans.details.loan_amount_popover')}
                  />
                </div>

                <Data data-testid="financed-amount-value">=&nbsp;{formatMoneyFromCents(financedAmount?.amount)}</Data>
              </Detail>
              <Detail>
                <div data-testid="interest-charged" className="d-flex">
                  {t('loans.details.interest_charged')}&nbsp;
                  <CommonPopover
                    type="loanAmount"
                    icon="info_outline"
                    textColor="#586272"
                    placement="top"
                    color="#252930"
                    textcontent={t('loans.details.interest_charged_popover')}
                  />
                </div>
                <Data data-testid="interest-charged-value">+&nbsp;{formatMoneyFromCents(interest?.amount)}</Data>
              </Detail>

              <hr />
              <Detail>
                <div data-testid="total-loan-payments" className="d-flex">
                  {t('loans.details.total_loan_payments')}
                </div>
                <Data data-testid="total-loan-payments-value">=&nbsp;&nbsp;{formatMoneyFromCents(totalPayments?.amount)}</Data>
              </Detail>
            </ExpandedDetails>
          </>
        )}

        <ShowDetails data-testid="show-or-hide-details" onClick={toggleShowDetails}>
          {showDetails ? (
            <>
              {t('loans.details.hide_details')} <MaterialIcon name="expand_less" />
            </>
          ) : (
            <>
              {t('loans.details.show_details')} <MaterialIcon name="expand_more" />
            </>
          )}
        </ShowDetails>
      </Details>
    </>
  );
});

LoanInfo.displayName = 'LoanInfo';

LoanInfo.propTypes = {
  loanDetails: PropTypes.shape({
    apr: PropTypes.number,
    currentInterestBalance: PropTypes.number,
    currentPayoffBalance: PropTypes.number,
    currentPrincipalBalance: PropTypes.number,
    term: PropTypes.number,
    totalPaid: PropTypes.number,
    downPayment: PropTypes.shape({
      amount: PropTypes.number,
    }),
    downPaymentMinusOriginationFee: PropTypes.shape({
      amount: PropTypes.number,
    }),
    financedAmount: PropTypes.shape({
      amount: PropTypes.number,
    }),
    financeCharges: PropTypes.shape({
      amount: PropTypes.number,
    }),
    interest: PropTypes.shape({
      amount: PropTypes.number,
    }),
    orderAmount: PropTypes.shape({
      amount: PropTypes.number,
    }),
    originationFee: PropTypes.shape({
      amount: PropTypes.number,
    }),
    totalPayments: PropTypes.shape({
      amount: PropTypes.number,
    }),
  }).isRequired,
};

export default LoanInfo;
