import styled from 'styled-components';
import { Col } from 'styles/CommonStyles';

const ResponsiveCol = styled(Col)`
  display: flex;
  padding: 0;

  @media (max-width: ${(props) => props.theme.minWidth.nano}) {
    flex-direction: column;

    ${Col} {
      max-width: 100%;
    }

    & + & {
      align-items: flex-end;
      text-align: right;
    }
  }
`;

export default ResponsiveCol;
