import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// lib
import { formatMaskedCreditCardNumber } from 'lib/Formatters';
import { scrollToElement } from 'lib/utils';

// Components
import PanelHeader from 'components/PanelHeader';

// State
import { ACTIONS } from 'state/actionTypes';
import { autoPayInitializeBatchFailuresSelector } from 'state/autoPay/selectors';
import { deletedPaymentMethodSelector } from 'state/deletePayment/selectors';
import {
  associatePaymentMethodBatchFailuresSelector,
  associatePaymentMethodBatchSuccessesSelector,
  defaultPaymentMethodSelector,
  defaultPaymentMethodTypeSelector,
  getDefaultPaymentMethodMaskedAccountNumberSelector,
  postVerifySelector,
} from 'state/paymentMethods/selectors';
import { secureFormsPaymentMethodsIdSelector } from 'state/secureFormsPaymentMethods/selectors';
import {
  showPaymentMethodAssociationFailure,
  showPaymentMethodAssociationSuccess,
  showPaymentMethodFail,
  showPaymentMethodSuccess,
} from 'state/updatePaymentMethods/actions';
import {
  postAuthorizeDataSelector,
  postPersistErrorSelector,
  showPaymentMethodAssociationFailureAlertSelector,
  showPaymentMethodAssociationSuccessAlertSelector,
  showPaymentMethodFailAlertSelector,
  showPaymentMethodSuccessAlertSelector,
} from 'state/updatePaymentMethods/selectors';

// Styles
import { BodyBordered, IconColor, IconDisc, IconSize, Panel } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

// Wallet Components
import theme from 'styles/Theme';
import PaymentMethodsForm from 'views/account/paymentMethods/PaymentMethodsForm';
import AddPaymentMethodButton from 'views/account/wallet/components/AddPaymentMethodButton';
import StyledAlert, {
  AlertMessage,
  PaymentMethodAssociationsAlert,
  PaymentMethodFailureAlert,
  PaymentMethodSuccessAlert,
  iconSize,
} from 'views/account/wallet/components/Alert';
import EmptyWallet from 'views/account/wallet/components/EmptyWallet';
import PaymentMethod from 'views/account/wallet/components/PaymentMethod';
import { setPaymentMethodPrefillLoans } from 'state/paymentMethods/actions';
import PaymentMethodAssociationFlow, { STEPS } from '../paymentMethods/PaymentMethodAssociationFlow';
import PaymentMethodAssociationFlowModal from '../paymentMethods/PaymentMethodAssociationFlowModal';

const AddPaymentMethod = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

const WalletView = memo((props) => {
  const { addCardPayment, cancelCardPayment, paymentMethods } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const cardFormRef = useRef();
  const panelRef = useRef();
  const [showRemoveAccountAlertFail, setShowRemoveAccountAlertFail] = useState(false);
  const [showRemoveAccountAlertSuccess, setShowRemoveAccountAlertSuccess] = useState(false);
  const [showPaymentAssociationModal, setShowPaymentAssociationModal] = useState(false);

  const showPayment = useSelector(secureFormsPaymentMethodsIdSelector);
  const showAlertSuccess = useSelector(showPaymentMethodSuccessAlertSelector);
  const showAlertFail = useSelector(showPaymentMethodFailAlertSelector);

  const showAssociationsFailureAlert = useSelector(showPaymentMethodAssociationFailureAlertSelector);
  const showAssociationsSuccessAlert = useSelector(showPaymentMethodAssociationSuccessAlertSelector);
  const associationFailures = useSelector(associatePaymentMethodBatchFailuresSelector);
  const associationSuccesses = useSelector(associatePaymentMethodBatchSuccessesSelector);
  const autopayInitializeBatchFailures = useSelector(autoPayInitializeBatchFailuresSelector);
  const autopayInitializeBatchFailuresRef = useRef();

  // Use Default payment method for 'newly' added payment method success message
  const defaultPaymentMethodType = useSelector(defaultPaymentMethodTypeSelector);
  const defaultPaymentMethod = useSelector(defaultPaymentMethodSelector);

  const maskedAccountNumber = formatMaskedCreditCardNumber(useSelector(getDefaultPaymentMethodMaskedAccountNumberSelector));
  const newPaymentMethodData = useSelector(postAuthorizeDataSelector);

  // Use deleted payment method for current actions
  const deletedPaymentMethod = useSelector(deletedPaymentMethodSelector);
  const deletedPaymentMethodType = deletedPaymentMethod?.paymentType;
  const deletedMaskedAccountNumber = formatMaskedCreditCardNumber(deletedPaymentMethod?.accountMask);

  // Show Insufficient balance / no account errors if applicable
  const postVerify = useSelector(postVerifySelector);
  const postPersistErrors = useSelector(postPersistErrorSelector);

  const postVerifyErrorTranslationKey = postVerify.error
    ? `account.payment_methods.backend_errors.${postVerify.error?.data?.message}`
    : undefined;

  useEffect(() => {
    if (cardFormRef.current && showPayment === 'id') {
      scrollToElement(cardFormRef.current);
    }
  }, [showPayment]);

  const scrollToPaymentMethod = () => scrollToElement(panelRef.current);

  useEffect(() => {
    if (panelRef.current && (showAssociationsFailureAlert || showAssociationsSuccessAlert)) {
      scrollToPaymentMethod();
    }
  }, [showAssociationsFailureAlert, showAssociationsSuccessAlert]);

  const dismissAllAlerts = useCallback(() => {
    // close all alerts!
    dispatch(showPaymentMethodFail(false));
    dispatch(showPaymentMethodSuccess(false));
    dispatch(showPaymentMethodAssociationFailure(false));
    dispatch(showPaymentMethodAssociationSuccess(false));
    setShowRemoveAccountAlertFail(false);
    setShowRemoveAccountAlertSuccess(false);
  }, [dispatch]);

  const setShowAlertFail = useCallback(
    (isShow) => {
      dispatch(showPaymentMethodFail(isShow));
    },
    [dispatch]
  );

  const setShowAlertSuccess = useCallback(
    (isShow) => {
      dispatch(showPaymentMethodSuccess(isShow));
    },
    [dispatch]
  );

  const setShowAssociationsAlertSuccess = (isShow) => {
    dispatch(showPaymentMethodAssociationSuccess(isShow));
  };

  const setShowAssociationsAlertFailure = (isShow) => {
    dispatch(showPaymentMethodAssociationFailure(isShow));
  };

  const postVerfiyErrorReset = useCallback(() => {
    dispatch({ type: ACTIONS.postVerifyReset });
  }, [dispatch]);

  const postPersistErrorReset = useCallback(() => {
    dispatch({ type: ACTIONS.postPersistReset });
  }, [dispatch]);

  // Set the value in the ref so it can be referenced in callbacks
  autopayInitializeBatchFailuresRef.current = autopayInitializeBatchFailures;

  const handleAssociationsComplete = (successes, failures) => {
    if (successes?.length) {
      dispatch(showPaymentMethodAssociationSuccess(true));
    }

    if (failures?.length || autopayInitializeBatchFailuresRef.current?.length) {
      dispatch(showPaymentMethodAssociationFailure(true));
    }
  };

  let allAssociationFailures = associationFailures || [];
  if (autopayInitializeBatchFailures?.length) {
    allAssociationFailures = allAssociationFailures.concat(autopayInitializeBatchFailures);
  }

  return (
    <>
      <Panel ref={panelRef}>
        <PanelHeader
          panelId="wallet"
          glyph={
            <IconDisc>
              <MaterialIcon name="account_balance_wallet" color={IconColor} size={IconSize} />
            </IconDisc>
          }
          labelLeft={t('account.payment_methods.header.manage')}
          contentLeft={t('account.payment_methods.header.payment_methods')}
        />

        {showAlertFail && showPayment !== 'manage-payments' && (
          <PaymentMethodFailureAlert showAlert={showAlertFail} setShowAlert={setShowAlertFail} />
        )}

        {postVerify.error && (
          <StyledAlert variant="danger" showAlert={!!postVerify.error} setShowAlert={postVerfiyErrorReset}>
            <AlertMessage>
              <MaterialIcon name="error_outline" size={iconSize} color={theme.colors.error} />
              &nbsp;
              {i18n.exists(postVerifyErrorTranslationKey)
                ? t(postVerifyErrorTranslationKey)
                : t(`account.payment_methods.details.results.ach_error`)}
            </AlertMessage>
          </StyledAlert>
        )}

        {postPersistErrors && (
          <StyledAlert variant="danger" showAlert={!!postPersistErrors} setShowAlert={postPersistErrorReset}>
            <AlertMessage>
              <MaterialIcon name="error_outline" size={iconSize} color={theme.colors.error} />
              &nbsp;{t('account.payment_methods.details.results.ach_error')}
            </AlertMessage>
          </StyledAlert>
        )}

        {showAlertSuccess && showPayment !== 'manage-payments' && !showPaymentAssociationModal && (
          <PaymentMethodSuccessAlert
            showAlert={showAlertSuccess}
            setShowAlert={setShowAlertSuccess}
            maskedAccountNumber={maskedAccountNumber}
            defaultPaymentMethodType={defaultPaymentMethodType}
          />
        )}

        {showAssociationsSuccessAlert && (
          <PaymentMethodAssociationsAlert
            associations={associationSuccesses}
            showAlert={showAssociationsSuccessAlert}
            setShowAlert={setShowAssociationsAlertSuccess}
            variant="success"
          />
        )}

        {showAssociationsFailureAlert && (
          <PaymentMethodAssociationsAlert
            associations={allAssociationFailures}
            showAlert={showAssociationsFailureAlert}
            setShowAlert={setShowAssociationsAlertFailure}
            variant="danger"
          />
        )}

        {showRemoveAccountAlertFail && (
          <StyledAlert variant="danger" showAlert={showRemoveAccountAlertFail} setShowAlert={setShowRemoveAccountAlertFail}>
            <AlertMessage>
              <MaterialIcon name="error_outline" size={iconSize} color={theme.colors.error} />
              &nbsp;{t('account.payment_methods.details.results.remove_payment_error')}
            </AlertMessage>
          </StyledAlert>
        )}

        {showRemoveAccountAlertSuccess && (
          <StyledAlert variant="success" showAlert={showRemoveAccountAlertSuccess} setShowAlert={setShowRemoveAccountAlertSuccess}>
            <AlertMessage>
              <MaterialIcon name="check_circle_outline" size={iconSize} color={theme.colors.successGreen} />
              &nbsp;
              {deletedPaymentMethodType === 'ach' ? (
                <Trans i18nKey="account.payment_methods.details.results.remove_payment_ach_success">
                  Bank Account {{ deletedMaskedAccountNumber }} has successfully been removed to your payment wallet.
                </Trans>
              ) : (
                <Trans i18nKey="account.payment_methods.details.results.remove_payment_card_success">
                  Card {{ deletedMaskedAccountNumber }} has successfully been removed to your payment wallet.
                </Trans>
              )}
            </AlertMessage>
          </StyledAlert>
        )}

        {paymentMethods
          .sort((a, b) => (a.defaultPaymentMethod < b.defaultPaymentMethod ? 1 : -1))
          .map((method) => {
            return (
              <PaymentMethod
                hideAutoPay
                key={method.paymentMethodId}
                method={method}
                dismissAllAlerts={dismissAllAlerts}
                setShowRemoveAccountAlertSuccess={setShowRemoveAccountAlertSuccess}
                setShowRemoveAccountAlertFail={setShowRemoveAccountAlertFail}
              />
            );
          })}

        {showPayment !== 'id' && showPayment !== 'manage-payments' && paymentMethods.length > 0 ? (
          <AddPaymentMethod>
            <AddPaymentMethodButton
              addCardPayment={addCardPayment}
              buttonText={t('account.payment_methods.modal.add_new_payment_method')}
              dismissAllAlerts={dismissAllAlerts}
              setShowAlertFail={setShowAlertFail}
              setShowAlertSuccess={setShowAlertSuccess}
              setShowPaymentAssociationModal={setShowPaymentAssociationModal}
            />
          </AddPaymentMethod>
        ) : null}

        {showPayment !== 'id' && paymentMethods.length === 0 ? (
          <EmptyWallet
            addCardPayment={addCardPayment}
            dismissAllAlerts={dismissAllAlerts}
            setShowAlertFail={setShowAlertFail}
            setShowAlertSuccess={setShowAlertSuccess}
          />
        ) : null}

        {(showPayment === 'id' || showPayment === 'manage-payments') && (
          <BodyBordered>
            {showPayment === 'id' ? (
              <PaymentMethodsForm
                key="key"
                id="id"
                ref={cardFormRef}
                cancelPaymentForm={cancelCardPayment}
                paymentMethodType="card"
                setShowAlertSuccess={setShowAlertSuccess}
              />
            ) : (
              <PaymentMethodAssociationFlow
                onComplete={handleAssociationsComplete}
                onError={(successes, failures, selection, step) => {
                  // If all selections are autopay enabled and all of the autopay initializations fail, treat this as the end of the flow.
                  if (step === STEPS.LOAN_SELECTION && selection?.length === failures?.length) {
                    dispatch(showPaymentMethodAssociationFailure(!!failures?.length));
                  }
                }}
                paymentMethod={newPaymentMethodData.paymentMethod}
                onCancel={scrollToPaymentMethod}
              />
            )}
          </BodyBordered>
        )}

        {showPaymentAssociationModal && (
          <PaymentMethodAssociationFlowModal
            paymentMethod={defaultPaymentMethod}
            onComplete={handleAssociationsComplete}
            onClose={() => {
              setShowPaymentAssociationModal(false);
              scrollToPaymentMethod();
              dispatch(setPaymentMethodPrefillLoans([]));
            }}
            onError={(successes, failures, selection, step) => {
              // If all selections are autopay enabled and all of the autopay initializations fail, treat this as the end of the flow.
              if (step === STEPS.LOAN_SELECTION && selection?.length === failures?.length) {
                setShowPaymentAssociationModal(false);
                dispatch(showPaymentMethodAssociationFailure(!!failures?.length));
                dispatch(setPaymentMethodPrefillLoans([]));
              }
            }}
          />
        )}
      </Panel>
    </>
  );
});

WalletView.displayName = 'WalletView';

WalletView.propTypes = {
  addCardPayment: PropTypes.func,
  cancelCardPayment: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumberMask: PropTypes.string,
      cardNumberMask: PropTypes.string,
      defaultPaymentMethod: PropTypes.bool.isRequired,
      paymentMethodId: PropTypes.string.isRequired,
      paymentMethodType: PropTypes.string.isRequired,
    })
  ),
};

WalletView.defaultProps = {
  addCardPayment: null,
  paymentMethods: null,
};

export default WalletView;
