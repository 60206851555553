import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Badge, Button, ButtonOutline } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

import Loader from 'components/Loader';
import AutoPayDocument from './AutoPayDocument';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
`;

const StyledHeader = styled.h3`
  color: ${(props) => props.theme.colors.grey};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;

  display: flex;
  gap: 8px;
  align-items: center;
  align-self: flex-start;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-self: center;
  justify-content: space-between;
`;

const StyledBadgeText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const AutoPayAgreements = ({
  autopayDocuments,
  cancelLabel,
  disabled,
  loading,
  nextLabel,
  onCancel,
  onComplete,
  onNext,
  isModalView,
}) => {
  const [viewIndex, setViewIndex] = useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    const updatedIndex = Math.min(viewIndex + 1, autopayDocuments.length - 1);
    if (onNext) {
      onNext(updatedIndex);
    }
    if (viewIndex === autopayDocuments.length - 1 && onComplete) {
      onComplete(autopayDocuments);
    }
    setViewIndex(updatedIndex);
  };

  const currentDocument = autopayDocuments[viewIndex];
  const documentIndex = viewIndex + 1;
  const documentsLength = autopayDocuments?.length;
  return (
    <StyledRoot>
      <StyledHeader>
        {!isModalView && <MaterialIcon name="autorenew" size="24px" />}
        {t('documents.autopay')}
      </StyledHeader>
      <Badge pill variant="info">
        <StyledBadgeText>
          <Trans i18nKey="account.payment_methods.manage_payment.document">
            Document {{ documentIndex }} of {{ documentsLength }}
          </Trans>
        </StyledBadgeText>
      </Badge>
      <AutoPayDocument
        documentUrl={currentDocument.documentUrl}
        loanId={currentDocument.loanId}
        merchantInfo={currentDocument.merchantInfo}
        isModalView={isModalView}
      />
      <StyledActionsContainer>
        {loading ? (
          <Loader />
        ) : (
          <>
            <ButtonOutline disabled={disabled} onClick={onCancel}>
              {cancelLabel}
            </ButtonOutline>
            <Button disabled={disabled} onClick={handleNext}>
              {nextLabel}
            </Button>
          </>
        )}
      </StyledActionsContainer>
    </StyledRoot>
  );
};

AutoPayAgreements.propTypes = {
  autopayDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      loanId: PropTypes.string.isRequired,
      documentUrl: PropTypes.string.isRequired,
      merchantInfo: PropTypes.shape({
        name: PropTypes.string,
        logoUrl: PropTypes.string,
      }).isRequired,
      sessionId: PropTypes.string.isRequired,
    })
  ),
  cancelLabel: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  nextLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onNext: PropTypes.func,
  isModalView: PropTypes.bool,
};

AutoPayAgreements.defaultProps = {
  autopayDocuments: [],
  cancelLabel: 'Cancel',
  disabled: false,
  loading: false,
  nextLabel: 'Agree and Continue',
  onCancel: undefined,
  onComplete: undefined,
  onNext: undefined,
  isModalView: false,
};

export default AutoPayAgreements;
