// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// AWS AMPLIFY CONFIGURATION
const AmplifyConfigurationAlpha = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
};
export default AmplifyConfigurationAlpha;
