import { createSelector } from 'reselect';

export const putLoanPaymentMethodLoadingSelector = createSelector(
  (state) => state.putLoanPaymentMethodReducer,
  (state) => state.loading
);

export const putLoanPaymentMethodSuccessSelector = createSelector(
  (state) => state.putLoanPaymentMethodReducer,
  (state) => state.data?.message === 'Success'
);

export const putLoanPaymentMethodFailSelector = createSelector(
  (state) => state.putLoanPaymentMethodReducer,
  (state) => state.status !== 200
);
