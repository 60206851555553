import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isAutoPaySelector } from 'state/account/selectors';

import CommonModal from 'views/account/paymentMethods/CommonModal';

import { ButtonHyperLink } from 'styles/CommonStyles';

const Content = styled.div`
  font-size: 0.9rem;
`;

const Header = styled.div`
  padding-bottom: 0.5rem;
`;

const Bullets = styled.div`
  padding-bottom: 0.5rem;
`;

const Footer = styled.div`
  padding-bottom: 0.5rem;
`;

const UL = styled.ul`
  padding-left: 1.5rem;
  margin-bottom: 0 !important;
`;

const StyledButtonHyperLink = styled(ButtonHyperLink)`
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledNavLink = styled(NavLink)`
  font-weight: bold;
  text-transform: uppercase;
`;

const IfIMissPaymentModal = memo(({ closeWhatIfIMissPaymentModal, isDPD, industry }) => {
  const { t } = useTranslation();

  const isAutoPay = useSelector(isAutoPaySelector);

  return (
    <>
      {isDPD ? (
        <CommonModal
          title={t('account.payment_methods.if_i_miss_payment.what_happens_if_i_miss_a_payment')}
          body1={
            <Content>
              <Header>{t('account.payment_methods.if_i_miss_payment.p1')}</Header>
              <Header>{t('account.payment_methods.if_i_miss_payment.if_30DPD_delinquent')}</Header>
              <Bullets>
                <UL>
                  <li>{t('account.payment_methods.if_i_miss_payment.b1_delinquent')}</li>
                  <li>{t('account.payment_methods.if_i_miss_payment.b2_delinquent')}</li>
                  <li>{t('account.payment_methods.if_i_miss_payment.b3_delinquent')}</li>

                  {industry === 'travel' && <li>{t('account.payment_methods.if_i_miss_payment.b4_delinquent')}</li>}
                </UL>
              </Bullets>
              <Footer>
                {!isAutoPay && (
                  <Trans i18nKey="account.payment_methods.if_i_miss_payment.f1_turn_on_autopay">
                    We encourage you to turn on AutoPay so you never have to worry about missing a payment. Click on the
                    <StyledNavLink to="/account">{t('header.navigation.account')}</StyledNavLink> tab and turn AutoPay on.
                  </Trans>
                )}
              </Footer>
            </Content>
          }
          submitEvent2={closeWhatIfIMissPaymentModal}
          button2={t('common.buttons.close')}
          onClose={closeWhatIfIMissPaymentModal}
        />
      ) : (
        <CommonModal
          title={t('account.payment_methods.if_i_miss_payment.what_happens_if_i_miss_a_payment')}
          body1={
            <Content>
              <Header>{t('account.payment_methods.if_i_miss_payment.p1')}</Header>
              {isAutoPay && (
                <Header>
                  <Trans i18nKey="account.payment_methods.if_i_miss_payment.f2_delay_payment">
                    You can delay a payment up to 15 days after your original payment date by visiting the
                    <StyledButtonHyperLink onClick={closeWhatIfIMissPaymentModal}>
                      {t('header.navigation.loans')}
                    </StyledButtonHyperLink>
                    tab. You will be responsible for any additional interest that accrues.
                  </Trans>
                </Header>
              )}
              <Header>{t('account.payment_methods.if_i_miss_payment.if_30DPD_current')}</Header>
              <Bullets>
                <UL>
                  <li>{t('account.payment_methods.if_i_miss_payment.b1_current')}</li>
                  <li>{t('account.payment_methods.if_i_miss_payment.b2_current')}</li>
                  {!isAutoPay && <li>{t('account.payment_methods.if_i_miss_payment.b3_current')}</li>}
                </UL>
              </Bullets>
              <Footer>
                {!isAutoPay && (
                  <Trans i18nKey="account.payment_methods.if_i_miss_payment.f1_turn_on_autopay">
                    We encourage you to turn on AutoPay so you never have to worry about missing a payment. Click on the
                    <StyledNavLink to="/account">{t('header.navigation.account')}</StyledNavLink> tab and turn AutoPay on.
                  </Trans>
                )}
              </Footer>
            </Content>
          }
          submitEvent2={closeWhatIfIMissPaymentModal}
          button2={t('common.buttons.close')}
          onClose={closeWhatIfIMissPaymentModal}
        />
      )}
    </>
  );
});

IfIMissPaymentModal.displayName = 'IfIMissPaymentModal';

IfIMissPaymentModal.propTypes = {
  closeWhatIfIMissPaymentModal: PropTypes.func.isRequired,
  isDPD: PropTypes.bool.isRequired,
  industry: PropTypes.string.isRequired,
};

export default IfIMissPaymentModal;
