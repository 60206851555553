import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';

import { accountSelector } from 'state/account/selectors';

import { Panel } from 'styles/CommonStyles';
import AccountDetailsView from './AccountDetailsView';

const AccountDetailsContainer = memo(() => {
  const accountDetails = useSelector(accountSelector);
  // console.log('AccountDetailsPanel: accountDetails: ', accountDetails)

  if (!accountDetails) {
    return (
      <Panel id="Panel" role="region" aria-labelledby="profile">
        <Loader />
      </Panel>
    );
  }

  return <AccountDetailsView accountDetails={accountDetails} />;
});

export default AccountDetailsContainer;
