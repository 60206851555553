import React, { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// lib
import { formatMaskedCreditCardNumber, formatMoneyDecimalPlainFromCents } from 'lib/Formatters';

// Components
import CommonModal from 'views/account/paymentMethods/CommonModal';

// Styles
import { MaterialIcon } from 'styles/Icons';
import { IconDisc, IconSize } from 'styles/CommonStyles';
import Theme from 'styles/Theme';

const ModalTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
`;

const WarningIconDisc = styled(IconDisc)`
  background-color: ${(props) => props.theme.colors.warningYellow};
  padding-top: 6px;
  align-self: center;
`;

const UL = styled.ul`
  padding-left: 1.5rem;
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  vertical-align: middle;
  font-size: 0.625rem;
`;

const StyledLoanText = styled.span`
  color: ${(props) => props.theme.colors.labelGrey};
  font-size: 0.625rem;
  text-transform: uppercase;
`;

const StyledNavLink = styled(NavLink)`
  font-size: 0.625rem;
`;

const iconColor = Theme.colors.warning;

// Components
const Title = memo((props) => {
  const { paymentMethodType, maskedAccountNumber } = props;

  const heading =
    paymentMethodType === 'ach' ? (
      <Trans i18nKey="account.payment_methods.modal.remove_account.remove_ach_confimation">
        Are you sure you want to remove Bank Account {{ maskedAccountNumber }}
      </Trans>
    ) : (
      <Trans i18nKey="account.payment_methods.modal.remove_account.remove_card_confimation">
        Are you sure you want to remove Card {{ maskedAccountNumber }}
      </Trans>
    );

  return (
    <ModalTitle>
      <WarningIconDisc>
        <MaterialIcon name="warning" color={iconColor} size={IconSize} />
      </WarningIconDisc>
      {heading}
    </ModalTitle>
  );
});

Title.propTypes = {
  paymentMethodType: PropTypes.string.isRequired,
  maskedAccountNumber: PropTypes.string.isRequired,
};

const Message = styled.div`
  margin-bottom: 1rem;
`;

const Body = memo((props) => {
  const { onClose, isLast, associatedLoans } = props;
  const { t } = useTranslation();

  const hasAutopayAssociations = !!associatedLoans?.filter((loan) => loan.autopay).length;

  return (
    <>
      {associatedLoans?.length ? (
        <>
          <Message>{t('account.payment_methods.modal.remove_account.loan_association_top')}</Message>
          <Message>
            {associatedLoans
              ?.filter((loan) => loan.state?.toUpperCase() === 'ACTIVE')
              ?.map((loan) => {
                return (
                  <UL key={loan.loanId}>
                    <li>
                      <b>{loan.merchantInfo.name}</b>
                      <br />
                      {formatMoneyDecimalPlainFromCents(loan.monthlyPaymentAmount?.amount)}/{t('loans.details.month')}
                      <br />
                      <StyledLoanText> {t('loans.header.loan_id')}</StyledLoanText>
                      &nbsp;
                      <StyledNavLink to={{ pathname: '/loans', hash: `#${loan.loanId}` }} onClick={onClose}>
                        {loan.loanId} <StyledMaterialIcon name="open_in_new" />
                      </StyledNavLink>
                    </li>
                  </UL>
                );
              })}
          </Message>
          <Message>
            {hasAutopayAssociations ? t('account.payment_methods.modal.remove_account.loan_association_bottom') : null}
          </Message>
        </>
      ) : (
        <Message>{t('account.payment_methods.modal.remove_account.action_not_undone')}</Message>
      )}
      {isLast && <Message>{t('account.payment_methods.modal.remove_account.last_payment')}</Message>}
    </>
  );
});

Body.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  associatedLoans: PropTypes.arrayOf(
    PropTypes.shape({
      loanId: PropTypes.string,
      paymentToken: PropTypes.string,
    })
  ),
};

Body.defaultProps = {
  isLast: false,
  associatedLoans: [],
};

// Modal
const RemovePaymentMethodModal = memo((props) => {
  const { method, onRemove, setShowModal, isLast, hasAssociatedLoans, associatedLoans, isLoading } = props;
  const { t } = useTranslation();

  const maskedNumber = method.paymentMethodType === 'ach' ? method.accountNumberMask : method.cardNumberMask;
  const maskedAccountNumber = formatMaskedCreditCardNumber(maskedNumber);

  const onClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <>
      <CommonModal
        title={<Title paymentMethodType={method.paymentMethodType} maskedAccountNumber={maskedAccountNumber} />}
        body1={<Body isLast={isLast} hasAssociatedLoans={hasAssociatedLoans} associatedLoans={associatedLoans} onClose={onClose} />}
        button1={t('common.buttons.keep')}
        button2={t('account.payment_methods.wallet.menu.remove')}
        isLoading={isLoading}
        submitEvent1={onClose}
        submitEvent2={onRemove}
        onClose={onClose}
      />
    </>
  );
});

RemovePaymentMethodModal.displayName = 'RemovePaymentMethodModal';

RemovePaymentMethodModal.propTypes = {
  method: PropTypes.shape({
    accountNumberMask: PropTypes.string,
    cardMonth: PropTypes.string,
    cardNumberMask: PropTypes.string,
    cardYear: PropTypes.string,
    paymentMethodId: PropTypes.string.isRequired,
    paymentMethodType: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func,
  setShowModal: PropTypes.func,
  isLast: PropTypes.bool,
  hasAssociatedLoans: PropTypes.bool,
  associatedLoans: PropTypes.arrayOf(
    PropTypes.shape({
      loanId: PropTypes.string,
      paymentToken: PropTypes.string,
      merchantInfo: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  isLoading: PropTypes.bool,
};

RemovePaymentMethodModal.defaultProps = {
  isLast: false,
  onRemove: null,
  setShowModal: null,
  hasAssociatedLoans: false,
  associatedLoans: [],
  isLoading: false,
};

export default RemovePaymentMethodModal;
