import React, { memo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import CountrySelector from 'components/CountrySelector';

import SignInSharedContainer from 'views/authenticator/SignInSharedContainer';
import { Panel } from 'styles/AuthStyles';
import { configure } from '../../AwsAmplify';

import SignInContainer from './SignInContainer';
import VerifyContainer from './VerifyContainer';

import SignInFooter from './components/SignInFooter';

const renderVerifyView = () => <VerifyContainer />;

const renderSignInView = () => <SignInContainer />;

const Authenticator = memo(() => {
  configure('alpha');

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${process.env.REACT_APP_GOOGLE_RECAPTCHA_API_URL}?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);

      // Remove the captcha iframe, no longer needed.
      const grecaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
      if (grecaptchaBadge && grecaptchaBadge.length) {
        [].forEach.call(grecaptchaBadge, (elem) => {
          const grecaptchaContainer = elem.parentNode;
          if (grecaptchaContainer && grecaptchaContainer.nodeName.toLowerCase() === 'div') {
            document.body.removeChild(grecaptchaContainer);
          }
        });
      }
    };
  }, []);

  return (
    <SignInSharedContainer
      content={
        <Panel>
          <Switch>
            <Route exact path="/signin/verify" render={renderVerifyView} />
            <Route render={renderSignInView} />
          </Switch>
          <SignInFooter />
          <CountrySelector />
        </Panel>
      }
    />
  );
});

Authenticator.displayName = 'Authenticator';

export default Authenticator;
