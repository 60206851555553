import { createSelector } from 'reselect';

export const paymentMethodsSelector = createSelector(
  (state) => state.paymentMethodsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.data
);

export const paymentMethodsLoadingSelector = createSelector(
  (state) => state.paymentMethodsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.loading
);

export const postVerifySelector = createSelector(
  (state) => state.postVerifyReducer,
  (postVerifyReducer) => postVerifyReducer
);

export const tokenizeSelector = createSelector(
  (state) => state.tokenizeReducer,
  (tokenizeReducer) => tokenizeReducer
);

export const getDefaultPaymentMethodMaskedAccountNumberSelector = createSelector(
  (state) => state.paymentMethodsReducer && state.paymentMethodsReducer.data,
  (data) => {
    const defaultPaymentMethod = data.filter((method) => method.defaultPaymentMethod === true);

    return defaultPaymentMethod && defaultPaymentMethod[0] && defaultPaymentMethod[0].paymentMethodType === 'card'
      ? defaultPaymentMethod[0] && defaultPaymentMethod[0].cardNumberMask
      : defaultPaymentMethod[0] && defaultPaymentMethod[0].accountNumberMask;
  }
);

export const defaultPaymentMethodTypeSelector = createSelector(
  (state) => state.paymentMethodsReducer && state.paymentMethodsReducer.data,
  (data) => {
    const defaultPaymentMethod = data && data.filter((method) => method.defaultPaymentMethod === true);
    return defaultPaymentMethod && defaultPaymentMethod[0] && defaultPaymentMethod[0].paymentMethodType;
  }
);

export const defaultPaymentMethodSelector = createSelector(
  (state) => state.paymentMethodsReducer && state.paymentMethodsReducer.data,
  (data) => {
    return data ? data.find((method) => method.defaultPaymentMethod === true) || data[0] : undefined;
  }
);

export const associatePaymentMethodBatchLoadingSelector = createSelector(
  (state) => state.associatePaymentMethodBatchReducer,
  (associatePaymentMethodBatchReducer) => associatePaymentMethodBatchReducer.isLoading
);

export const associatePaymentMethodBatchSuccessesSelector = createSelector(
  (state) => state.associatePaymentMethodBatchReducer,
  (associatePaymentMethodBatchReducer) => associatePaymentMethodBatchReducer.successes
);

export const associatePaymentMethodBatchFailuresSelector = createSelector(
  (state) => state.associatePaymentMethodBatchReducer,
  (associatePaymentMethodBatchReducer) => associatePaymentMethodBatchReducer.failures
);

export const selectedPaymentMethodSelector = createSelector(
  (state) => state.paymentMethodsReducer,
  (paymentMethodsReducer) => paymentMethodsReducer.selected
);

export const paymentMethodsWithLoansSelector = createSelector(
  (state) => {
    const loansData = state.loansReducer && state.loansReducer.data;
    return loansData
      ?.filter((loan) => loan.state?.toUpperCase() === 'ACTIVE')
      ?.map((loan) => ({ loanId: loan.loanId, paymentToken: loan.paymentToken, merchantInfo: loan.merchantInfo }));
  },
  (state) => state.paymentMethodsReducer && state.paymentMethodsReducer.data,
  (loansData, paymentMethods) => {
    if (!loansData) {
      return paymentMethods;
    }

    return paymentMethods?.map((method) => {
      const loansWithPaymentMethod = loansData?.filter((loan) => loan.paymentToken === method.paymentMethodId);
      const merchantLoansMap = {};
      loansWithPaymentMethod.forEach((loan) => {
        if (!(loan.merchantInfo.name in merchantLoansMap)) {
          merchantLoansMap[loan.merchantInfo.name] = [];
        }
        merchantLoansMap[loan.merchantInfo.name].push(loan.loanId);
      });
      return {
        ...method,
        merchantLoansMap,
      };
    });
  }
);

export const paymentMethodPrefillLoansSelector = createSelector(
  (state) => state.paymentMethodPrefillReducer && state.paymentMethodPrefillReducer.loanIds,
  (loanIds) => loanIds || []
);
