import React, { useCallback, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getAccount } from 'state/account/actions';

import { accountStateSelector } from 'state/account/selectors';
import { userSelector } from 'state/auth/selectors';

import Loader from 'components/Loader';

import { AuthForm, Label, SignInButton, TransitionPanel } from 'styles/AuthStyles';

import AccountField from './components/AccountField';

const AccountForm = memo((props) => {
  const { onAccountChange } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, error, data } = useSelector(accountStateSelector);
  const user = useSelector(userSelector);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const accountId = event.target.elements.accound_id.value;

      dispatch(getAccount(accountId));
    },
    [dispatch]
  );

  if (!loading && !error && data) {
    localStorage.setItem('assumedAccountId', data.accountId);
    return <Redirect to="/" />;
  }

  // If user object is set but without the username, then user is not authenticated so kick them out.
  // Otherwise, if user is `undefined`, then we are still initializing them so wait for it to come back.
  if (user && !user.username) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Label>{t('admin.account_id.account_id')}</Label>
      <AuthForm noValidate onSubmit={onSubmit}>
        <AccountField onAccountChange={onAccountChange} showError={!!error} />

        {loading ? (
          <TransitionPanel>
            <Loader />
          </TransitionPanel>
        ) : (
          <div className="d-flex justify-content-center">
            <SignInButton type="submit" className="btn-block">
              {t('common.buttons.submit')}
            </SignInButton>
          </div>
        )}
      </AuthForm>
    </>
  );
});

AccountForm.displayName = 'AccountForm';

AccountForm.propTypes = {
  onAccountChange: PropTypes.func.isRequired,
};

export default AccountForm;
