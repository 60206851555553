import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Card, Overlay, ModalBody, ModalFooter, ModalHeader } from 'styles/ModalStyles';
import { Alert, AlertMessage, IconSize, IconWrapper } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';
import { SignInButton } from 'styles/AuthStyles';

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.h5`
  color: ${(props) => props.theme.colors.upliftLightBlack};
  font-weight: bold;
`;

const StyledAlertMessage = styled(AlertMessage)`
  font-size: 0.82rem;
  text-align: center;
  font-color: #155824;
`;

const StyledModalHeader = styled(ModalHeader)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const StyledSignInButton = styled(SignInButton)`
  box-shadow: none;
`;

const StyledText = styled.div`
  font-weight: bold;
`;

const ResetAccountSignInModal = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  return (
    <Overlay>
      <Card width="456px">
        <StyledModalHeader>
          <StyledHeaderContent>
            <StyledTitle />
            <IconWrapper onClick={onClose}>
              <MaterialIcon color={theme.colors.upliftLightGrey} name="close" />
            </IconWrapper>
          </StyledHeaderContent>
        </StyledModalHeader>
        <ModalBody>
          <Alert variant="success" style={{ width: '95%' }} color={theme.colors.successGreen} data-testid="success-alert">
            <StyledAlertMessage style={{ fontSize: '0.875rem' }}>
              <MaterialIcon name="check_circle_outline" theme="outlined" size={IconSize} color={theme.colors.successGreen} />
              {t('account.edit_modal.account_update_success')}
            </StyledAlertMessage>
          </Alert>
          <StyledText>{t('account.edit_modal.signed_out_mobile_number')}</StyledText>
        </ModalBody>
        <ModalFooter>
          <StyledSignInButton className="btn-block" onClick={onClose} data-testid="sign-in-button">
            {t('authentication.sign_in.sign_in')}
          </StyledSignInButton>
        </ModalFooter>
      </Card>
    </Overlay>
  );
};

ResetAccountSignInModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ResetAccountSignInModal.displayName = 'ResetAccountSignInModal';

export default ResetAccountSignInModal;
