import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled, { ThemeContext } from 'styled-components';

import PulseLoader from 'react-spinners/PulseLoader';

const StyledLoader = styled.div`
  display: flex;
`;

const override = css`
  display: block;
  margin: 0 auto;
`;

// loading component for suspense fallback
const Loader = ({ style }) => {
  const themeContext = useContext(ThemeContext);
  const loading = true;

  return (
    <StyledLoader id="Loader" aria-label="Loading" data-testid="loading" style={style}>
      <PulseLoader
        css={override}
        sizeUnit="px"
        size={12}
        color={themeContext.colors.upliftTeal}
        margin="0.5rem"
        loading={loading}
      />
    </StyledLoader>
  );
};

Loader.displayName = 'Loader';

Loader.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
};

Loader.defaultProps = {
  style: null,
};

export default Loader;
