import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { noOp } from 'lib/utils';
import { autoPayInitializeLoadingSelector } from 'state/autoPay/selectors';
import CommonModal from 'views/account/paymentMethods/CommonModal';

const AutoPayModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();

  const autoPayInitializeLoading = useSelector(autoPayInitializeLoadingSelector);

  return (
    <CommonModal
      title={t('account.payment_methods.autopay_modal.enable_autopay')}
      body1={t('account.payment_methods.autopay_modal.autopay_description')}
      button1={t('common.buttons.enable')}
      button2={t('common.buttons.not_now')}
      isLoading={autoPayInitializeLoading}
      submitEvent1={onConfirm}
      submitEvent2={onClose}
      onClose={onClose}
    />
  );
};

AutoPayModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

AutoPayModal.defaultProps = {
  onClose: noOp,
  onConfirm: noOp,
};

export default AutoPayModal;
