import { createSelector } from 'reselect';

// Map State to props
export const accountSelector = createSelector(
  (state) => state.accountsReducer,
  (accountsReducer) => accountsReducer.data
);

export const accountStateSelector = createSelector(
  (state) => state.accountsReducer,
  (accountsReducer) => ({
    loading: accountsReducer.loading,
    error: accountsReducer.error,
    data: accountsReducer.data,
  })
);

export const accountIdSelector = createSelector(
  (state) => state.accountsReducer && state.accountsReducer.data && state.accountsReducer.data.accountId,
  (accountId) => accountId
);

export const isAutoPaySelector = createSelector(
  (state) => state.accountsReducer && state.accountsReducer.data && state.accountsReducer.data.autoPay,
  (autoPay) => autoPay
);

export const countrySelector = createSelector(
  // prettier-ignore
  (state) => // NOSONAR
    state.accountsReducer &&
    state.accountsReducer.data &&
    state.accountsReducer.data.primaryContact &&
    state.accountsReducer.data.primaryContact.country,
  (country) => country
);

export const isCanadianSelector = createSelector(
  // prettier-ignore
  (state) => // NOSONAR
    state.accountsReducer &&
    state.accountsReducer.data &&
    state.accountsReducer.data.primaryContact &&
    state.accountsReducer.data.primaryContact.country,
  (country) => {
    return country === 'CA';
  }
);

export const getRegion = createSelector(
  (state) => state.accountsReducer?.data?.primaryContact?.country && state.accountsReducer.data.primaryContact.region,
  (region) => {
    return region;
  }
);
