/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import styled from 'styled-components';

import Theme from '../styles/Theme';
import { onLanguageChange } from '../lib/utils';

const StyledCountrySelector = styled.div`
  min-width: 200px;
  padding: 2rem 0;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // backgroundColor: state.isFocused ? '#15284b' : state.isSelected ? '#2c559f' : '#fff',
    // backgroundColor: state.isFocused ? '#dff3f1' : state.isSelected ? '#2ea59a' : '#fff',

    // revisit: uncomment the 2 below
    // backgroundColor: state.isFocused ? Theme.colors.upliftTeal : '',
    // color: state.isFocused ? '#fff' : Theme.colors.upliftLightBlack,
    cursor: 'pointer',
    // textAlign: 'center',
  }),
  control: (provided, state) => ({
    ...provided,
    // backgroundColor: 'pink',
    // backgroundColor: '#fff',
    // color: '#fff',
    // borderRadius: '0',
    // border: 'none',
    // borderBottom: '1px solid #4A5360',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#fff',
    // color: '#fff',
  }),
  menuList: (provided, state) => ({
    ...provided,
    // paddingTop: '0',
    // paddingBottom: '0',
  }),
  indicatorSeparator: () => ({}),
};

const options = [
  { value: 'en-US', label: 'USA / English' },
  { value: 'en', label: 'Canada / English' },
  { value: 'fr', label: 'Canada / French' },
];

const supportedLanguages = options.map((option) => option.value);

const CountrySelector = memo(() => {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (event) => {
      i18n.changeLanguage(event.value, onLanguageChange);
    },
    [i18n]
  );

  const value = useMemo(() => {
    const language = i18n.languages.find((lng) => supportedLanguages.includes(lng));

    return options.find((option) => option.value === language) || options[0];
  }, [i18n.languages]);

  return (
    <StyledCountrySelector>
      <Select
        name="countrySelect"
        styles={customStyles}
        options={options}
        onChange={changeLanguage}
        value={value}
        menuPosition="fixed"
        isSearchable={false}
        menuColor="green"
      />
    </StyledCountrySelector>
  );
});

export default CountrySelector;
