import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// State Actions
import { paymentMethodEdit, paymentMethodCancel } from 'state/secureFormsPaymentMethods/actions';

// State Selectors
import { accountIdSelector } from 'state/account/selectors';
import { paymentMethodsWithLoansSelector } from 'state/paymentMethods/selectors';

// Styles
import { Panel } from 'styles/CommonStyles';

// Components
import Loader from 'components/Loader';
import WalletView from './WalletView';

const Wallet = memo(() => {
  const accountId = useSelector(accountIdSelector);
  const paymentMethods = useSelector(paymentMethodsWithLoansSelector);
  const dispatch = useDispatch();

  const addCardPayment = useCallback(
    (event, id) => {
      event.preventDefault();
      // THE DISPATCHED FUNCTION SHOULD BE RENAMED !!!!!!TO ADD NOT EDIT !!!!
      dispatch(paymentMethodEdit(id));
    },
    [dispatch]
  );

  const cancelCardPayment = useCallback(() => {
    dispatch(paymentMethodCancel());
  }, [dispatch]);

  if (!accountId || !paymentMethods) {
    return (
      <Panel id="Panel">
        <Loader />
      </Panel>
    );
  }

  return <WalletView addCardPayment={addCardPayment} cancelCardPayment={cancelCardPayment} paymentMethods={paymentMethods} />;
});

export default Wallet;
